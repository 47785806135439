import React, { Component } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { format } from "date-fns";
import SliderCustom from "../slider-card-custom";
import {isTablet} from "react-device-detect";
import SliderTynery from "../slider-card-custom/sliderTynery";

class DayView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialSlide: 0,
      settings: {
        centerMode: false,
        responsive: [
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          }
        ],
      },
    };
    if (isTablet) {
      this.state.settings = {
        slidesToShow: 4,
        slidesToScroll: 4,
        ...this.state.settings
      };
    }
  }

  componentDidMount(): void {
    this.updateIntSlide();
  }

  updateIntSlide = () => {
    let index = this.props.listDates.findIndex((item) => {
      return item._id === this.props.match.params.idItineraryDetail;
    });
    this.setState({
      initialSlide: index,
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    this.updateIntSlide();
  };

  getDataCalendar = (data) => {
    if (data && !!data.length) {
      let dataHtml = [];
      let countIndex = 1
      for (var i = 0; i < data.length; i++) {
        if (i === data.length - 1) {
          dataHtml.push(this.renderCardCalendar(data[i], countIndex));
          break;
        }
        dataHtml.push(this.renderCardCalendar(data[i], countIndex));
        if (data[i].month_day_year !== data[i + 1].month_day_year) {
          countIndex++;
        }
      }
      // data.forEach((item, index) => {
      //   dataHtml.push(this.renderCardCalendar(item, index));
      // });
      return dataHtml;
    }
    return null;
  };

  renderCardCalendar = (item, index) => {
    return (
      <div className="each-date-in-calendar" key={index}>
        <Link
          key={index}
          to={`/itinerary/${this.props.match.params.idItinerary}/${item._id}`}
        >
          <div
            className={
              this.props.match.params.idItineraryDetail === item._id
                ? "content selected"
                : "content"
            }
          >
            <div className="date">Day {index}</div>
            {item.month_day_year && (
                <div className="date-of-week">
                  {format(new Date(item.month_day_year * 1000), "dd")}{" "}
                  {format(new Date(item.month_day_year * 1000), "EEE")}
                </div>
            )}
          </div>
        </Link>
      </div>
    );
  };

  render() {
    return (
      <div className="row-calendar-slider">
        <SliderCustom
          slidesToShow={7}
          initialSlide={this.state.initialSlide}
          settings={this.state.settings}
          list={this.getDataCalendar(this.props.listDates)}
        />
        {/*<SliderTynery
            slidesToShow={7}
            initialSlide={this.state.initialSlide}
            settings={this.state.settings}
            list={this.getDataCalendar(this.props.listDates)}
        />*/}
      </div>
    );
  }
}

export default withRouter(DayView);
