import {formatMoney} from "accounting-js";

interface Settings {
    symbol: string,
    precision: number,
    thousand: string,
    decimal: string,
}

class MoneyHelper {
    settings: {
        symbol: '$',
        precision: 2,
        thousand: ".",
        decimal: ",",
    };
    money;
    constructor(money) {
        this.money = money;
    }

    format = (settings: Settings) => {
        return formatMoney(this.money, {...this.settings, ...settings});
    }

    formatCurrency = (currency) => {
        return new Intl.NumberFormat('en-VI', {
            style: 'currency',
            currency: currency
        }).format(this.money);
    }
}

export default (money) => {
    return new MoneyHelper(money);
};
