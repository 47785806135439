import React, { useState } from "react";
import { Empty, Icon, Modal } from "antd";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import moment from "moment";

const TableCustomer = ({ data, reportName }) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState({});

  function ModalContact() {
    if (!item) return null;

    return (
      <Modal
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
        className="modal-contact-view"
      >
        <p>
          <span>
            <strong>First name:</strong> {item.firstName}
          </span>
        </p>
        {item.lastName && (
          <p>
            <span>
              <strong>Last name:</strong> {item.lastName}
            </span>
          </p>
        )}
        <p>
          <strong>Email: </strong>
          <span>{item.email}</span>
        </p>
        <p>
          <strong>Phone:</strong>
          <span>{item.phone}</span>
        </p>
        {item.country && (
          <p>
            <strong>Country</strong>
            <span>{item.country.name}</span>
          </p>
        )}
        {item.group && (
          <p>
            <strong>Group</strong>
            <span>{item.group.name}</span>
          </p>
        )}
      </Modal>
    );
  }

  function openContactModal(item) {
    setOpen(true);
    setItem(item);
  }

  return (
    <>
      <ModalContact />
      <div className="table-children">
        <span className="recent-customers-text">{reportName}</span>
        {data.length === 0 ? (
          <Empty />
        ) : (
          <div className="content">
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <strong>Customer Email</strong>
                  </Th>
                  <Th>
                    <strong>Joined</strong>
                  </Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data &&
                  data.map((item, index) => (
                    <Tr className="item" key={index}>
                      <Td>
                        <a href="#" onClick={() => openContactModal(item)} >
                          {item.email}
                        </a>
                      </Td>
                      <Td className="">
                        {moment(item.created_at).format("LL")}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
};

export default TableCustomer;
