import React, { Component } from "react";
import { connect } from "react-redux";
import {
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout,
  updateInforCurrency,
} from "../../store/actions";
import { withRouter } from "react-router";
import { Divider, Typography, List, Row, Col } from "antd";
import "./index.scss";
import _ from "lodash";
import { getImageFromUrl, isMyResource } from "../../services/utils.service";
import { configBaseUrl, config } from "../../config";
import ImageHelper from "../../functions/image";
import DefaultBackground from "../../assets/media/images/generic-activities-img.jpg";
import StringHelper from "../../helper/stringHelper";
import { Link } from "react-router-dom";

const { Title } = Typography;

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
    ip: state.ipReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInforCurrency: (currency) => {
      dispatch(updateInforCurrency(currency));
    },
    saveCurrentItinerary: (itinerary) => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

class ListSameAuthor extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderAuthorName = (user, company) => {
    return company ? (
      company.url ? (
        <a
          href={config.http + "//" + company.url + "." + config.domain}
          target="blank"
        >
          {company.name
            ? company.name
              ? company.name
              : company.short_name
              ? company.short_name
              : null
            : `${user.firstName} ${user.lastName}`}
        </a>
      ) : company.name ? (
        company.name ? (
          company.name
        ) : company.short_name ? (
          company.short_name
        ) : null
      ) : (
        `${user.firstName} ${user.lastName}`
      )
    ) : null;
  };

  renderOtherListFromSameAuthor = (items) => {
    if (!_.isEmpty(items)) {
      return (
        <>
          <Title level={4}>
            Other list by{" "}
            {this.renderAuthorName(items[0].user, items[0].company)}
          </Title>
          <Divider />

          <Row gutter={[16, 16]} className="same-author">
            <List
              itemLayout="vertical"
              size="large"
              dataSource={items}
              renderItem={(item) => (
                <Col xs={24} md={24} lg={12}>
                  <List.Item key={item.title} style={{ borderBottom: 0 }}>
                    <div className="item-list">
                      <div className="item-img-block">
                        <Link to={"/view-top-list/" + item._id}>
                          <ImageHelper
                            className="img-fluid"
                            notFound={DefaultBackground}
                            src={getImageFromUrl(
                              isMyResource(item.cover)
                                ? configBaseUrl.baseURL + item.cover
                                : "",
                              "small"
                            )}
                          />
                        </Link>
                      </div>
                      <List.Item.Meta
                        title={
                          <Link to={"/view-top-list/" + item._id}>
                            {item.title}
                          </Link>
                        }
                        description={StringHelper().stripeHtml(
                          item.description,
                          200
                        )}
                      />
                    </div>
                  </List.Item>
                </Col>
              )}
            />
          </Row>
        </>
      );
    }
    return null
  };

  render() {
    return this.renderOtherListFromSameAuthor(this.props.listSameAuthor);
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListSameAuthor)
);
