import React from "react";
import {Affix, Button, Drawer} from "antd";
import './index.scss';
import {ItineraryDetails} from "../../components";

const DayList = ({params, onClose, visible, ...props}) => {
    return (
        <Drawer
            className="drawer-select"
            placement="bottom"
            title="Jump to day"
            visible={visible}
            onClose={onClose}
            width="100%"
        >
            <ItineraryDetails
                onCloseMobile={onClose}
                preview={true}
                params={params}
                itinerary_detail={props.itinerary_detail}
            />
        </Drawer>
    );
};

class MenuMobile extends React.Component{
    state = {
        visible: false
    };
    onClose = () => {
        this.setState({visible: false});
    };
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return this.props.itinerary && (
            <Affix className="btn-pos" offsetBottom={20}>
                <DayList itinerary_detail={this.props.itinerary.itinerary_detail} onClose={this.onClose} visible={this.state.visible} params={this.props.params}/>
                <Button size="large" onClick={() => this.setState({visible: true})} icon="menu" type="danger" />
            </Affix>
        );
    }
}

export default MenuMobile;
