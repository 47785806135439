import React, { Component } from "react";
import moment from "moment";

import { config } from "../../config";
import moneyHelper from "../../helper/moneyHelper";
import Image from "../../functions/image";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import styles from "./index.scss";

import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import html2pdf from "html2pdf.js";
import { Button } from "antd";
import { getNameItinerary } from "../../services/utils.service";

const StopItem = ({ stop, quotation }) => {
  return (
    <Tr className="body-row stop-item">
      <Td className="time">
        <strong>
          {stop.start_time &&
            moment(stop.start_time, ["HH.mm"]).format("hh:mm a")}
        </strong>
      </Td>
      <Td className="activity">
        <div className="content">
          <strong className="name">{stop.title}</strong>
          <div className="body">
            {stop.description ? (
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: stop.description,
                }}
              ></p>
            ) : null}
          </div>
        </div>
      </Td>
      <Td className="budget">
        {stop.budget &&
          moneyHelper(stop.budget).format({
            currency: quotation.currency,
            format: "%s%v " + quotation.currency,
          })}
        {stop.rejected && <div className="reject-text"> Rejected </div>}
      </Td>
    </Tr>
  );
};

const DayItem = ({ index, day, quotation }) => {
  return (
    <div className="day-item" key={index}>
      <div className="day-header">
        <span className="day-index">
          Day {index + 1}: {day.name}
          <br />
          <span className="address">{day.address}</span>
        </span>
        <div className="day-info">
          <div className="advance">
            <strong>Lodging: </strong>
            <span>
              {day.lodgingCheck ? day.lodging[0].title : " not included"}
            </span>
          </div>
          <div className="advance">
            <strong>Meals: </strong>
            <span>
              {day.mealCheck
                ? day.meal.map((meal) => meal.name + " ")
                : " not included"}
            </span>
          </div>
          {day.guideCheck ? (
            <div className="advance">
              <strong>Guide: </strong>
              <span>
                {moneyHelper(day.guide[0].budget).format({
                  currency: quotation.currency,
                  format: "%s%v " + quotation.currency,
                })}
              </span>
            </div>
          ) : null}
        </div>
      </div>

      {day.stops.length > 0 && (
        <div className="stops">
          <Table>
            <Thead>
              <Tr>
                <Th className="time" width="10%">
                  Time
                </Th>
                <Th width="70%">Activity</Th>
                <Th width="20%" className="cost">
                  Cost
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {day.stops.map((stop, is) => (
                <StopItem key={is} stop={stop} quotation={quotation} />
              ))}
            </Tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

const DayOverView = ({ quotation }) => {
  let days = quotation.days;

  let result = [];

  days.forEach((item, index) => {
    if (index % 2) {
      result.push([days[index - 1], days[index]]);
    } else if (index + 1 === days.length) result.push(days[index]);
  });

  const DayItem = ({ day, index }) => {
    return (
      <div className="day-item">
        <div className="day-header">
          <span className="day-index">Day {index + 1}</span>
          <span className="start_date">
            {day.date && moment(day.date).format("ll")}
          </span>
        </div>
        <div className="day-description">{day.name}</div>
      </div>
    );
  };

  let dayIndex = 0;
  return result.map((day, index) => {
    if (index !== 0) dayIndex++;
    if (result[index].length === 2)
      return (
        <div className="day-item-rows" key={index}>
          <DayItem day={day[0]} index={dayIndex + index} />
          <DayItem day={day[1]} index={dayIndex + index + 1} />
        </div>
      );
    return (
      <div className="day-item-rows" key={index}>
        <DayItem day={day} index={dayIndex + index} />
      </div>
    );
  });
};

class ItineraryPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingExport: false,
    };
  }
  exportPdf = () => {
    this.setState(
      {
        loadingExport: true,
      },
      async () => {
        const pdfName = getNameItinerary({
          ...this.props.quotation,
          estimated_budget: this.props.quotation.budget,
          a_number_of_paxs: this.props.quotation.people,
        });
        const element = document.getElementById("body-content");
        await html2pdf()
          .set({
            margin: [5, 5, 10, 5],
            filename: pdfName + ".pdf",
            html2canvas: {
              letterRendering: true,
              useCORS: true,
              scale: 2,
            },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            pagebreak: {
              mode: "avoid-all",
              after: [".itinerary-description", ".pricing-container"],
            },
          })
          .from(element)
          .save();
        this.setState({
          loadingExport: false,
        });
      }
    );
  };

  render() {
    const { quotation } = this.props;
    return quotation ? (
      <Dialog
        open={this.props.showPDF}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="form-load"
      >
        <MuiDialogTitle disableTypography className="popup-title">
          <Typography variant="h6">My Library</Typography>
          <IconButton
            aria-label="close"
            className="btn-close"
            onClick={() => this.props.closeViewPDF()}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent>
          <div className={styles.itineraryPDF + " view"} id="pdf-quotation">
            <div id="body-content">
              <div className="image-container">
                <Image
                  src={config.baseURL + quotation.cover}
                  className="companyLogo"
                  alt="company-logo"
                />
              </div>
              <div className="itinerary-description">
                <h3>{quotation.name}</h3>
                <p>{quotation.description}</p>
              </div>
              <div className="itinerary-over-view">
                <h3>Overview</h3>
                <div className="days">
                  <DayOverView quotation={quotation} />
                </div>
              </div>
              <div className="pricing-container">
                <h3>Pricing</h3>
                <div className="info-pricing">
                  <div className="item">
                    <span className="text"> People </span>
                    <span className="value">{quotation.people} </span>
                  </div>
                  <div className="item">
                    <span className="text">Duration</span>
                    <span className="value">
                      {quotation.days.length} day
                      {quotation.days.length > 1 ? "s" : ""}
                    </span>
                  </div>
                  {quotation.budget !== null && (
                    <div className="item">
                      <span className="text">Budget</span>
                      <span className="value">
                        {moneyHelper(quotation.budget).format({
                          currency: quotation.currency,
                          format: "%s%v " + quotation.currency,
                        })}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="detail-container">
                <h3>itinerary</h3>
                <div className="day-item-container">
                  {quotation.days.map((day, index) => (
                    <DayItem
                      key={index}
                      index={index}
                      day={day}
                      quotation={quotation}
                    />
                  ))}
                </div>
              </div>
              <div className="other-information">
                <h3 className="text-information">Information</h3>
                <div className="summary">
                  <h3 className="text-summary">Pricing Summary</h3>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Title</Th>
                        <Th>People</Th>
                        <Th>Duration</Th>
                        <Th>Budget</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr className="body-row">
                        <Td>
                          <strong>{quotation.title}</strong>
                        </Td>
                        <Td>{quotation.people}</Td>
                        <Td>
                          {quotation.days.length} day
                          {quotation.days.length > 1 && "s"}
                        </Td>
                        <Td>
                          {moneyHelper(quotation.budget).format({
                            currency: quotation.currency,
                            format: "%s%v " + quotation.currency,
                          })}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            loading={this.state.loadingExport}
            onClick={this.exportPdf.bind(this)}
            type="primary"
          >
            Download PDF
          </Button>
        </DialogActions>
      </Dialog>
    ) : null;
  }
}

export default ItineraryPdf;
