import { combineReducers } from 'redux';
import formViewEditPlaceReducer from './form-view-edit-place';
import formViewEditTransportationReducer from './form-view-edit-transportation';
import itineraryDetailReducer from './itinerary-detail';
import listPlacesExploreReducer from './list-places-explore';
import formShareByEmailReducer from './form-share-by-email';
import filePdfReducer from './file-pdf';
import itineraryReducer from './itinerary';
import profileReducer from './profile';
import formAddNewStopReducer from './form-add-new-stop';
import loadingReducer from './loading';
import formLoadReducer from './form-load';
import formQuoteReducer from './form-quote';
import slickGoToReducer from './slick-go-to';
import saveAfterLoginReducer from './save-after-login';
import listLoadReducer from './list-load';
import formReportPlaceReducer from './form-report-place';
import redirectToBillingAfterLoginReducer from './redirect-to-billing-after-login';
import ipReducer from './ip';
import TabsReducer from './utility/tab';

const rootReducer = combineReducers({
  formViewEditPlaceReducer,
  formViewEditTransportationReducer,
  itineraryDetailReducer,
  listPlacesExploreReducer,
  listLoadReducer,
  formAddNewStopReducer,
  formShareByEmailReducer,
  filePdfReducer,
  itineraryReducer,
  profileReducer,
  loadingReducer,
  formLoadReducer,
  formQuoteReducer,
  slickGoToReducer,
  saveAfterLoginReducer,
  formReportPlaceReducer,
  redirectToBillingAfterLoginReducer,
  ipReducer,
  TabsReducer,
});

export default rootReducer;
