import React from "react";
import {Typography} from "antd";
import './index.scss';
import line from "../../../assets/media/images/line-bottom.svg";

const {Title} = Typography;

export const BottomLine = () => {
    return (
        <div className="bottom-line"><img className="img-fluid" src={line} alt=""/></div>
    );
}

const TitleTyneri = ({text, seeMore = null, lineBot = true, className, ...props}) => {
    return (
        <div className="title-tyneri mb-4">
            <Title className={"text-center position-static bottom-line m-0 "+ (className === 'normal-font' ? className : 'title-font huge')} level={3}>{text}<small>{seeMore}</small></Title>
            {lineBot ? <BottomLine/> : null}
        </div>
    );
}

export default TitleTyneri;
