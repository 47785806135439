import React, { useEffect, useState } from "react";
import {Button, List, Skeleton/*, Card, Rate, Tag*/} from "antd";

import CompanyApi from "../../../../../services/companyApi.service";
import { config } from "../../../../../config";
/*import { isMyResource } from "../../../../../services/utils.service";

import DefaultImage from "../../../../../assets/media/images/generic-activities-img.jpg";
import Inquires from "../../../../../components/inquiries";*/
import "./index.scss";
import {CardCustom} from "../../../../../components";
import DefaultBackground from "../../../../../assets/media/images/generic-activities-img.jpg";

/*const Tyneri = ({ item: Item }) => {
  const optionsGroup = Item.options.reduce((r, a) => {
    r[a.group_option] = [...(r[a.group_option] || []), a];
    return r;
  }, {});
  const optionHtml = (optionData) => {
    if (!optionData) return null;
    return optionData.map((option) => (
      <span className={option.group_option} key={option._id}>
        #{option.name.toLowerCase()}
      </span>
    ));
  };
  return (
    <Card
      cover={
        <aside
          style={{
            backgroundImage:
              "url('" +
              (isMyResource(config.baseURL + (Item.cover ? Item.cover : null))
                ? config.baseURL + Item.cover
                : DefaultImage) +
              "')",
          }}
          className="card-image"
        >
          {Item.country ? (
            <Tag color="#cd201f" className="tag-country">
              {Item.country}
            </Tag>
          ) : null}
          <Inquires itinerary={ Item }
                    noShowCompany={ true } />
        </aside>
      }>
      <Card.Meta
        title={
          <div className="review-title">
            <div className="review">
              <Rate allowHalf defaultValue={2.5} /> 100 reviews
            </div>
            <a href={config.clientURL + "view-itinerary/" + Item._id}
               target="blank"
               className="title">
              {Item.name}
             </a>
          </div>
        }
        description={
          Item.options.length > 0 ? (
            <div className="description">
              {optionHtml(optionsGroup.age)}
              {optionHtml(optionsGroup.plan)}
              {optionHtml(optionsGroup.pref)}
            </div>
          ) : null
        }
      />
    </Card>
  );
};*/
function TyneryList(props) {
    const [loading, setLoading] = useState(true);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [listData, setListData] = useState([]);
    const limitPage = 8;

    useEffect(() => {
        loadTyneri(page - 1, limitPage);
    }, [page]);

    function loadTyneri(pageNumber, limit) {
        const companyApi = CompanyApi();
        const old = listData;
        setListData(listData.concat([...new Array(limit)].map(() => ({ loading: true, name: {} }))));
        companyApi.getTyneries(pageNumber, limit).then((res) => {
            const data = old.concat(res.data);
            setListData(data);
            setTotalPage(res.total);
            setLoading(false);
            props.fetch(res.total);
        });
    }

    const renderCardItem = (item, index) => {
        return (
            <Skeleton title={true} loading={item.loading} active>
                {item.loading ? null : (
                    <div key={index} className="custom-card-tyneri">
                        <a href={config.clientURL +"view-itinerary/" + item._id}>
                            <CardCustom
                                title={item.name}
                                estimated_budget={item.estimated_budget}
                                a_number_of_paxs={item.a_number_of_paxs}
                                currency_infor={item.currency_infor}
                                tag={item.city}
                                centerIconName="fas fa-play-circle"
                                bottomIconName="fas fa-ellipsis-h"
                                bgPhoto={item.cover ? item.cover : DefaultBackground}
                                totalReviews={30}
                                ratingAverage={4.5}
                                options={item.options ? item.options : []}
                                list_optionals={props.list_optionals}
                            />
                        </a>
                    </div>
                )}
            </Skeleton>
        );
    }

    function getListTyneries() {
        if (loading) return null;
        const loadMore = page < (totalPage / limitPage) ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button type="primary" shape="round" onClick={()=> setPage(page + 1)}>Show more</Button>
            </div>
        ) : null;
        return (
            <List
                grid={{ gutter: 16, column: 2, xxl: 3, xl: 2, lg: 2, md: 2, xs: 1, sm: 1}}
                itemLayout="vertical"
                className="my-list-tyneri"
                size="large"
                loadMore={loadMore}
                dataSource={listData}
                renderItem={(item, i) => (
                    // <li className="item"> {Tyneri({ item: item })} </li>
                    <List.Item>
                        {renderCardItem(item, i)}
                    </List.Item>
                )}
            />
        );
    }

    return <div className="list-tyneries">{getListTyneries()}</div>;
}

export default TyneryList;
