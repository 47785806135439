/*global google*/
import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { TimelineEvent } from "react-event-timeline";
import defaultImg from "../../assets/media/images/generic-activities-img.jpg";
import { Modal } from "antd";
import {
  MdSettings,
  MdDirectionsCar,
  MdDirectionsWalk,
  MdDirectionsBike,
  MdDirectionsBus,
  MdTrain,
  MdDirectionsBoat,
  MdFlight,
  MdAccessTime,
  MdEventNote,
  MdAttachMoney,
  MdStraighten,
} from "react-icons/md";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import styles from "./index.scss";
import {
  openFormViewEditPlace,
  openFormViewEditTransportation,
  updateTotalAmount,
  openFormQuote,
} from "../../store/actions";
import { connect } from "react-redux";
import ApiService from "../../services/api.service";
import { loadItem, saveItem } from "../../services/storage.service";
import {
  format24hTo12h,
  formatSecondsToDuration,
  calcEndTime,
  getImageFromUrl,
  isMyResource,
  searchTree,
  calcTotalAmount,
} from "../../services/utils.service";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { FormSignInUp } from "../../components";
import Gallery from "react-grid-gallery";
import IconQuote from "../../assets/media/images/quote.jpg";
import Collapse from "@material-ui/core/Collapse";
import { configBaseUrl } from "../../config";
import formatMoney from "accounting-js/lib/formatMoney.js";
import FormSaveToLibrary from "../form-save-to-library";
import _ from "lodash"
import { categoriesDefined } from "../../constants/categories"

const { confirm } = Modal;

const mapDispatchToProps = (dispatch) => {
  return {
    openFormViewEditPlace: (item) => {
      dispatch(openFormViewEditPlace(item));
    },
    openFormViewEditTransportation: (transportation) => {
      dispatch(openFormViewEditTransportation(transportation));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    openFormQuote: (item) => {
      dispatch(openFormQuote(item));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    currency: state.itineraryReducer.currency,
    currency_infor: state.itineraryReducer.currency_infor,
    profile: state.profileReducer.info,
  };
};

const hiddenTransportationRow = {
  display: "none",
};

class PlacesInListSelected extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      openFormSaveToLibrary: false,

      current_itinerary: {},
      btnDropleft: false,
      openPopupSetting: false,
      categoryTab: 1,
      name: "",
      price: "",
      distance: "",
      time: "",
      duration: "",
      note: "",
      expanded: false,
      showForm: false,
      isLoadingSaveFavorite: false,
      images: [],

      isMarked: false,

      belong_to_library_place: "",
      belong_to_library: "",
    };
  }

  handleOpenPopupViewTransport = () => {
    var transportation = {
      name: this.state.name,
      price: this.state.price,
      distance: this.state.distance,
      time: this.state.time,
      duration: this.state.duration,
      note: this.state.note,
    };
    this.props.openFormViewEditTransportation(transportation);
  };

  // handleAddInfoTransportation = async (typeTransportation, editInfor) => {
  //   this.setState({
  //     name: typeTransportation,
  //   });
  //   var tempPlace = this.props.item;
  //   tempPlace.transportation.name = typeTransportation;

  //   var tranportation = {
  //     name: typeTransportation,
  //     price: this.state.price,
  //     distance: this.state.distance,
  //     time: this.state.time,
  //     duration: this.state.duration,
  //     note: this.state.note,
  //   };
  //   if (editInfor) {
  //     await this.apiService
  //       .addTransportation(
  //         this.props.params.idItineraryDetail,
  //         this.props.item._id,
  //         tranportation
  //       )
  //       .then((res) => {
  //         if (res.status === "success") {
  //           tempPlace.transportation.distance = this.state.distance;
  //           tempPlace.transportation.duration = this.state.duration;
  //           tempPlace.transportation.price = this.state.price;
  //           tempPlace.transportation.time = this.state.time;
  //           tempPlace.transportation.note = this.state.note;
  //           var tempNewList = this.props.items;
  //           tempNewList[this.props.index] = tempPlace;
  //           this.props.newList(2);
  //           this.props.newList(6, tempNewList);
  //           this.setState({
  //             isOpen: false,
  //             openPopupSetting: false,
  //             name: typeTransportation,
  //           });
  //         }
  //       });
  //   } else {
  //     if (this.props.item.transportation) {
  //       if (this.props.index < this.props.length - 1) {
  //         var transportation = typeTransportation;
  //         if (transportation === "Car") transportation = "DRIVING";
  //         if (transportation === "Walk") transportation = "WALKING";
  //         if (transportation === "Bike") transportation = "BICYCLING";
  //         if (transportation === "Train") transportation = "TRANSIT";
  //         if (
  //           transportation === "DRIVING" ||
  //           transportation === "WALKING" ||
  //           transportation === "BICYCLING" ||
  //           transportation === "TRANSIT"
  //         ) {
  //           var origin = new google.maps.LatLng(
  //             this.props.item.data.actual_data.lat,
  //             this.props.item.data.actual_data.lng
  //           );
  //           var destination = new google.maps.LatLng(
  //             this.props.items[this.props.index + 1].data.actual_data.lat,
  //             this.props.items[this.props.index + 1].data.actual_data.lng
  //           );
  //           var service = new google.maps.DistanceMatrixService();
  //           service.getDistanceMatrix(
  //             {
  //               origins: [origin],
  //               destinations: [destination],
  //               travelMode: transportation,
  //               unitSystem: google.maps.UnitSystem.METRIC,
  //               avoidHighways: false,
  //               avoidTolls: false,
  //             },
  //             (response, status) => {
  //               if (status === "OK") {
  //                 if (response.rows[0].elements[0].status === "OK") {
  //                   this.setState(
  //                     {
  //                       duration: response.rows[0].elements[0].duration.text,
  //                       distance: response.rows[0].elements[0].distance.text,
  //                     },
  //                     () => {
  //                       tranportation = {
  //                         ...tranportation,
  //                         distance: this.state.distance,
  //                         duration: this.state.duration,
  //                       };
  //                       tempPlace.transportation.distance = this.state.distance;
  //                       tempPlace.transportation.duration = this.state.duration;
  //                       var tempNewList = this.props.items;
  //                       tempNewList[this.props.index] = tempPlace;
  //                       let itineraries;
  //                       itineraries = loadItem("itineraries");
  //                       let current = itineraries.findIndex((item) => {
  //                         return item._id === this.props.params.idItinerary;
  //                       });
  //                       let index = itineraries[
  //                         current
  //                       ].itinerary_detail.findIndex((item) => {
  //                         return (
  //                           item._id === this.props.params.idItineraryDetail
  //                         );
  //                       });
  //                       itineraries[current].itinerary_detail[
  //                         index
  //                       ].place_detail = tempNewList;
  //                       saveItem("itineraries", itineraries);
  //                       this.props.newList(2);
  //                       this.props.newList(6, tempNewList);
  //                       // this.apiService.addTransportation(this.props.params.idItineraryDetail, this.props.item._id, tranportation).then(res => {
  //                       //     if (res.status === 'success') {
  //                       //         this.props.newList(2)
  //                       //         this.props.newList(6, tempNewList)
  //                       //     }
  //                       // })
  //                     }
  //                   );
  //                 } else {
  //                   this.setState(
  //                     {
  //                       duration: "",
  //                       distance: "",
  //                     },
  //                     () => {
  //                       tranportation = {
  //                         ...tranportation,
  //                         distance: this.state.distance,
  //                         duration: this.state.duration,
  //                       };
  //                       tempPlace.transportation.distance = this.state.distance;
  //                       tempPlace.transportation.duration = this.state.duration;
  //                       var tempNewList = this.props.items;
  //                       tempNewList[this.props.index] = tempPlace;
  //                       let itineraries;
  //                       itineraries = loadItem("itineraries");
  //                       let current = itineraries.findIndex((item) => {
  //                         return item._id === this.props.params.idItinerary;
  //                       });
  //                       let index = itineraries[
  //                         current
  //                       ].itinerary_detail.findIndex((item) => {
  //                         return (
  //                           item._id === this.props.params.idItineraryDetail
  //                         );
  //                       });
  //                       itineraries[current].itinerary_detail[
  //                         index
  //                       ].place_detail = tempNewList;
  //                       saveItem("itineraries", itineraries);
  //                       this.props.newList(2);
  //                       this.props.newList(6, tempNewList);
  //                       // this.apiService.addTransportation(this.props.params.idItineraryDetail, this.props.item._id, tranportation).then(res => {
  //                       //     if (res.status === 'success') {
  //                       //         this.props.newList(2)
  //                       //         this.props.newList(6, tempNewList)
  //                       //     }
  //                       // })
  //                     }
  //                   );
  //                 }
  //               }
  //             }
  //           );
  //         } else {
  //           this.setState(
  //             {
  //               duration: "",
  //               distance: "",
  //             },
  //             () => {
  //               tranportation = {
  //                 ...tranportation,
  //                 distance: this.state.distance,
  //                 duration: this.state.duration,
  //               };
  //               tempPlace.transportation.distance = this.state.distance;
  //               tempPlace.transportation.duration = this.state.duration;
  //               var tempNewList = this.props.items;
  //               tempNewList[this.props.index] = tempPlace;
  //               let itineraries;
  //               itineraries = loadItem("itineraries");
  //               let current = itineraries.findIndex((item) => {
  //                 return item._id === this.props.params.idItinerary;
  //               });
  //               let index = itineraries[current].itinerary_detail.findIndex(
  //                 (item) => {
  //                   return item._id === this.props.params.idItineraryDetail;
  //                 }
  //               );
  //               itineraries[current].itinerary_detail[
  //                 index
  //               ].place_detail = tempNewList;
  //               saveItem("itineraries", itineraries);
  //               this.props.newList(2);
  //               this.props.newList(6, tempNewList);
  //               // this.apiService.addTransportation(this.props.params.idItineraryDetail, this.props.item._id, tranportation).then(res => {
  //               //     if (res.status === 'success') {
  //               //         this.props.newList(2)
  //               //         this.props.newList(6, tempNewList)
  //               //     }
  //               // })
  //             }
  //           );
  //         }
  //       }
  //     }
  //   }

  //   this.setState({
  //     isOpen: false,
  //     openPopupSetting: false,
  //     name: typeTransportation,
  //   });
  // };

  handleEditSetting = () => {
    this.setState({
      openPopupSetting: true,
    });
  };

  handleCloseEditSetting = () => {
    this.setState({
      openPopupSetting: false,
    });
  };

  handleOpenFormViewEditPlace = () => {
    this.setState({
      expanded: this.state.expanded !== true ? false : true,
    });
    this.props.openFormViewEditPlace(this.props.item);
    this.props.updateItem(this.props.item);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    this.setState({
      expanded: nextProps.expand,
    });

    if (nextProps.item.belong_to_library) {
      this.setState({
        isMarked: true,
        belong_to_library_place: nextProps.item.belong_to_library_place,
        belong_to_library: nextProps.item.belong_to_library,
      });
    }
    if (nextProps.profile) {
      this.setState({
        showForm: false,
      });
    }
    var temp = loadItem("current_itinerary");
    this.setState({
      current_itinerary: temp,
    });
    if (
      nextProps.item.data.actual_data.images &&
      !!nextProps.item.data.actual_data.images.length
    ) {
      let tempImages = [];
      let tempImgs = [...nextProps.item.data.actual_data.images];
      tempImgs.forEach((item, index) => {
        if (index > 0) {
          if (isMyResource(item.preview)) {
            tempImages.push({
              src: configBaseUrl.baseURL + item.preview,
              thumbnail: configBaseUrl.baseURL + item.preview,
            });
          } else {
            tempImages.push({
              src: item.preview,
              thumbnail: item.preview,
            });
          }
        }
      });
      this.setState({
        images: tempImages,
      });
    } else {
      this.setState({
        images: [],
      });
    }

    if (nextProps.item.quote) {
      this.setState({
        quote: nextProps.item.quote,
      });
    }
    // if (nextProps.item.transportation) {
    //   if (nextProps.index < nextProps.length - 1) {
    //     var transportation = nextProps.item.transportation.name;
    //     if (nextProps.item.transportation.name === "Car")
    //       transportation = "DRIVING";
    //     if (nextProps.item.transportation.name === "Walk")
    //       transportation = "WALKING";
    //     if (nextProps.item.transportation.name === "Bike")
    //       transportation = "BICYCLING";
    //     if (nextProps.item.transportation.name === "Train")
    //       transportation = "TRANSIT";
    //     if (
    //       transportation === "DRIVING" ||
    //       transportation === "WALKING" ||
    //       transportation === "BICYCLING" ||
    //       transportation === "TRANSIT"
    //     ) {
    //       var origin = new google.maps.LatLng(
    //         nextProps.item.data.actual_data.lat,
    //         nextProps.item.data.actual_data.lng
    //       );
    //       var destination = new google.maps.LatLng(
    //         nextProps.items[nextProps.index + 1].data.actual_data.lat,
    //         nextProps.items[nextProps.index + 1].data.actual_data.lng
    //       );
    //       var service = new google.maps.DistanceMatrixService();
    //       service.getDistanceMatrix(
    //         {
    //           origins: [origin],
    //           destinations: [destination],
    //           travelMode: transportation,
    //           unitSystem: google.maps.UnitSystem.METRIC,
    //           avoidHighways: false,
    //           avoidTolls: false,
    //         },
    //         (response, status) => {
    //           if (status === "OK") {
    //             if (response.rows[0].elements[0].status === "OK") {
    //               this.setState({
    //                 duration: response.rows[0].elements[0].duration.text,
    //                 distance: response.rows[0].elements[0].distance.text,
    //               });
    //             } else {
    //               this.setState({
    //                 duration: "",
    //                 distance: "",
    //               });
    //             }
    //           }
    //         }
    //       );
    //     } else {
    //       this.setState({
    //         duration: "",
    //         distance: "",
    //       });
    //     }
    //   }
    //   this.setState({
    //     name: nextProps.item.transportation.name
    //       ? nextProps.item.transportation.name
    //       : this.state.current_itinerary.transportation,
    //     price: nextProps.item.transportation.price
    //       ? nextProps.item.transportation.price
    //       : "",
    //     distance: nextProps.item.transportation.distance
    //       ? nextProps.item.transportation.distance
    //       : "",
    //     time: nextProps.item.transportation.time
    //       ? nextProps.item.transportation.time
    //       : "",
    //     duration: nextProps.item.transportation.duration
    //       ? nextProps.item.transportation.duration
    //       : "",
    //     note: nextProps.item.transportation.note
    //       ? nextProps.item.transportation.note
    //       : "",
    //   });
    // } else {
    //   this.setState({
    //     name: temp.transportation,
    //   });
    // }
  };

  componentDidMount = () => {
    this._isMounted = true;
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });

    if (this.props.item.belong_to_library) {
      this.setState({
        belong_to_library_place: this.props.item.belong_to_library_place,
        belong_to_library: this.props.item.belong_to_library,
      });
      this.apiService
        .getLibraryForPlace(this.props.item.belong_to_library)
        .then((res) => {
          if (res.status === "success") {
            if (res.libraryPlace) {
              this.setState({
                isMarked: true,
              });
            }
          }
        });
    } else {
      this.apiService.getLibraryForPlace(this.props.item._id).then((res) => {
        if (res.status === "success") {
          if (res.libraryPlace) {
            this.setState({
              isMarked: true,
            });
          }
        }
      });
    }

    if (
      this.props.item.data.actual_data.images &&
      !!this.props.item.data.actual_data.images.length
    ) {
      let tempImages = [];
      let tempImgs = [...this.props.item.data.actual_data.images];
      tempImgs.forEach((item, index) => {
        if (index > 0) {
          if (isMyResource(item.preview)) {
            tempImages.push({
              src: configBaseUrl.baseURL + item.preview,
              thumbnail: configBaseUrl.baseURL + item.preview,
            });
          } else {
            tempImages.push({
              src: item.preview,
              thumbnail: item.preview,
            });
          }
        }
      });
      this.setState({
        images: tempImages,
      });
    }

    if (this.props.item.quote) {
      this.setState({
        quote: this.props.item.quote,
      });
    }
    // if (this.props.item.transportation) {
    //   if (this.props.index < this.props.length - 1) {
    //     var transportation = this.props.item.transportation.name;
    //     if (this.props.item.transportation.name === "Car")
    //       transportation = "DRIVING";
    //     if (this.props.item.transportation.name === "Walk")
    //       transportation = "WALKING";
    //     if (this.props.item.transportation.name === "Bike")
    //       transportation = "BICYCLING";
    //     if (this.props.item.transportation.name === "Train")
    //       transportation = "TRANSIT";
    //     if (
    //       transportation === "DRIVING" ||
    //       transportation === "WALKING" ||
    //       transportation === "BICYCLING" ||
    //       transportation === "TRANSIT"
    //     ) {
    //       var origin = new google.maps.LatLng(
    //         this.props.item.data.actual_data.lat,
    //         this.props.item.data.actual_data.lng
    //       );
    //       var destination = new google.maps.LatLng(
    //         this.props.items[this.props.index + 1].data.actual_data.lat,
    //         this.props.items[this.props.index + 1].data.actual_data.lng
    //       );
    //       var service = new google.maps.DistanceMatrixService();
    //       service.getDistanceMatrix(
    //         {
    //           origins: [origin],
    //           destinations: [destination],
    //           travelMode: transportation,
    //           unitSystem: google.maps.UnitSystem.METRIC,
    //           avoidHighways: false,
    //           avoidTolls: false,
    //         },
    //         (response, status) => {
    //           if (status === "OK") {
    //             if (response.rows[0].elements[0].status === "OK") {
    //               this.setState({
    //                 duration: response.rows[0].elements[0].duration.text,
    //                 distance: response.rows[0].elements[0].distance.text,
    //               });
    //             } else {
    //               this.setState({
    //                 duration: "",
    //                 distance: "",
    //               });
    //             }
    //           }
    //         }
    //       );
    //     } else {
    //       this.setState({
    //         ...this.state,
    //         duration: "",
    //         distance: "",
    //       });
    //     }
    //   }
    //   this.setState({
    //     name: this.props.item.transportation.name
    //       ? this.props.item.transportation.name
    //       : this.state.current_itinerary.transportation,
    //     price: this.props.item.transportation.price
    //       ? this.props.item.transportation.price
    //       : "",
    //     distance: this.props.item.transportation.distance
    //       ? this.props.item.transportation.distance
    //       : "",
    //     time: this.props.item.transportation.time
    //       ? this.props.item.transportation.time
    //       : "",
    //     duration: this.props.item.transportation.duration
    //       ? this.props.item.transportation.duration
    //       : "",
    //     note: this.props.item.transportation.note
    //       ? this.props.item.transportation.note
    //       : "",
    //   });
    // } else {
    //   this.setState({
    //     name: temp.transportation,
    //   });
    // }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  defaultTransportation = () => {
    if (this.state.current_itinerary) {
      if (this.state.current_itinerary.transportation === "Car")
        return (
          <MdDirectionsCar
            onClick={this.handleOpenPopupViewTransport.bind(this)}
          />
        );
      if (this.state.current_itinerary.transportation === "Walk")
        return (
          <MdDirectionsWalk
            onClick={this.handleOpenPopupViewTransport.bind(this)}
          />
        );
      if (this.state.current_itinerary.transportation === "Bike")
        return (
          <MdDirectionsBike
            onClick={this.handleOpenPopupViewTransport.bind(this)}
          />
        );
      if (this.state.current_itinerary.transportation === "Bus")
        return (
          <MdDirectionsBus
            onClick={this.handleOpenPopupViewTransport.bind(this)}
          />
        );
      if (this.state.current_itinerary.transportation === "Train")
        return (
          <MdTrain onClick={this.handleOpenPopupViewTransport.bind(this)} />
        );
      if (this.state.current_itinerary.transportation === "Boat")
        return (
          <MdDirectionsBoat
            onClick={this.handleOpenPopupViewTransport.bind(this)}
          />
        );
      if (this.state.current_itinerary.transportation === "Flight")
        return (
          <MdFlight onClick={this.handleOpenPopupViewTransport.bind(this)} />
        );
    }
  };

  handleRemovePlaceDetail = () => {
    confirm({
      title: "Are you sure delete this item?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        let itineraries;
        itineraries = loadItem("itineraries");
        let current = itineraries.findIndex((item) => {
          return item._id === this.props.params.idItinerary;
        });

        let tempItem = searchTree(
          itineraries[current].itinerary_detail,
          this.props.params.idItineraryDetail
        )[0];

        let j = tempItem.place_detail.findIndex((item) => {
          return item._id === this.props.item._id;
        });
        tempItem.place_detail.splice(j, 1);
        tempItem.place_detail.forEach((item, index) => {
          item.order = index + 1;
        });
        saveItem("itineraries", itineraries);
        let total_amount = calcTotalAmount(this.props.params.idItinerary);
        this.props.updateTotalAmount(total_amount);
        this.props.newList(1);
      },
      onCancel: () => {},
    });
  };

  closeFormLogin = () => {
    this.setState({
      showForm: false,
    });
  };

  handleExpand = () => {
    this.setState({
      expanded: this.state.expanded !== true ? true : false,
    });
  };

  handleShowQuote = () => {
    this.props.openFormQuote(this.props.item);
  };

  handleOpenFormSaveToLibrary = () => {
    this.setState({
      openFormSaveToLibrary: true,
    });
  };

  closeFormSaveToLibrary = () => {
    this.setState({
      openFormSaveToLibrary: false,
    });
  };

  setMarked = () => {
    this.setState({
      isMarked: true,
    });
  };

  setBelongLibrary = (library) => {
    this.setState({
      belong_to_library_place: library.belong_to_library_place,
      belong_to_library: library.belong_to_library,
    });
  };

  renderItemInfor = (expanded, newText) => {
    return (
      <Col
        className="main-content main-content-infor"
        xs="8"
        md="12"
        lg="12"
        xl="12"
      >
        <Accordion expanded={expanded} className="card">
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <div style={{ width: "100%" }}>
              <div
                className={
                  this.props.item.data.actual_data.time_start ||
                  this.props.item.data.actual_data.duration ||
                  this.props.item.data.actual_data.type
                    ? "title-action"
                    : "title-action float-right"
                }
              >
                <div onClick={this.handleExpand.bind(this)}>
                  {this.props.item.data.actual_data.time_start && (
                    <span className="time">
                      <AccessTimeIcon />
                      <span>
                        {format24hTo12h(
                          this.props.item.data.actual_data.time_start
                        )}
                      </span>
                    </span>
                  )}
                  {!this.props.item.data.actual_data.time_start &&
                  this.props.item.data.actual_data.duration ? (
                    <span className="time">
                      <AccessTimeIcon />
                      <span>
                        Duration:{" "}
                        {formatSecondsToDuration(
                          this.props.item.data.actual_data.duration
                        )}
                      </span>
                    </span>
                  ) : (
                    this.props.item.data.actual_data.time_start &&
                    this.props.item.data.actual_data.duration > 0 && (
                      <span className="time">
                        <span>
                          -{" "}
                          {calcEndTime(
                            this.props.item.data.actual_data.time_start,
                            this.props.item.data.actual_data.duration
                          )}
                        </span>
                      </span>
                    )
                  )}
                </div>
                <div className="action">
                  <Tooltip
                    title={
                      this.props.profile
                        ? "Favorite"
                        : "You need sign in to save this stop to library"
                    }
                    onClick={
                      this.props.profile
                        ? this.handleOpenFormSaveToLibrary.bind(this)
                        : null
                    }
                  >
                    <div className="favorite-icon">
                      <i
                        className={
                          this.state.isMarked
                            ? "fas fa-bookmark"
                            : "far fa-bookmark"
                        }
                        style={{ fontSize: "18px", color: "#ff8ae6" }}
                      />
                    </div>
                  </Tooltip>

                  <Tooltip
                    onClick={this.handleOpenFormViewEditPlace.bind(this)}
                    title="Edit"
                  >
                    <div className="edit-icon">
                      <i
                        className="fas fa-pencil-alt"
                        style={{ fontSize: `18px`, color: `#0f95de` }}
                      ></i>
                    </div>
                  </Tooltip>
                  <Tooltip
                    onClick={this.handleRemovePlaceDetail.bind(this)}
                    title="Remove"
                  >
                    <div className="remove-icon">
                      <i
                        className="far fa-trash-alt"
                        style={{ fontSize: "18px", color: "red" }}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div onClick={this.handleExpand.bind(this)}>
                <p className="place-title">
                  {this.props.item.data &&
                    this.props.item.data.actual_data.title}
                </p>
                <p className="place-address">
                  {this.props.item.data &&
                    this.props.item.data.actual_data.address}
                </p>
              </div>
              <div onClick={this.handleExpand.bind(this)}>
                {(this.props.item.data.actual_data.booked_through ||
                  this.props.item.data.actual_data.confirmation ||
                  this.props.item.data.actual_data.provider ||
                  this.props.item.data.actual_data.amount) && (
                  <div
                    className="content-event-detail"
                    style={{
                      background: `#f6f6f6`,
                      padding: `10px`,
                      marginTop: "20px",
                    }}
                  >
                    {this.props.item.data.actual_data.booked_through && (
                      <div className="content">
                        Booked through:{" "}
                        <span className="value">
                          {this.props.item.data.actual_data.booked_through}
                        </span>
                      </div>
                    )}
                    {this.props.item.data.actual_data.confirmation && (
                      <div className="content">
                        Reservation code:{" "}
                        <span className="value">
                          {this.props.item.data.actual_data.confirmation}
                        </span>
                      </div>
                    )}
                    {this.props.item.data.actual_data.amount && (
                      <div className="content">
                        Price:{" "}
                        <span className="value">
                          {formatMoney(
                            this.props.item.data.actual_data.amount,
                            {
                              symbol: this.props.currency_infor.symbol,
                              precision: this.props.currency_infor
                                .decimal_digits,
                              thousand: ",",
                              decimal: ".",
                            }
                          )}{" "}
                          {this.props.currency_infor.code}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {(this.props.item.data.actual_data.descriptions ||
                !!this.state.images.length) && (
                <Collapse in={this.state.expanded} timeout="auto">
                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      marginTop: "1rem",
                    }}
                    onClick={this.handleExpand.bind(this)}
                  >
                    <div className="content1-event">
                      {this.props.item.data.actual_data.descriptions && (
                        <div className="content trix-content-temp">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: newText[0].props.children,
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                    {!!this.state.images.length && (
                      <Gallery
                        images={this.state.images}
                        enableImageSelection={false}
                      />
                    )}
                  </div>
                </Collapse>
              )}
              <Tooltip title="Quote">
                <img
                  className="icon-quote"
                  src={IconQuote}
                  onClick={this.handleShowQuote.bind(this)}
                />
              </Tooltip>
            </div>
          </AccordionSummary>
        </Accordion>
      </Col>
    );
  };

  renderNotItemInfor = (expanded, newText, nameCategory) => {
    return (
      <React.Fragment>
        <Col className="thumbnail-places" xs="4" md="2" lg="2" xl="2">
          <div
            className="img-fluid"
            style={{
              backgroundImage: `url(${
                !!this.props.item.data.actual_data.images.length
                  ? getImageFromUrl(
                      isMyResource(
                        this.props.item.data.actual_data.images[0].preview
                      )
                        ? `${
                            configBaseUrl.baseURL +
                            this.props.item.data.actual_data.images[0].preview
                          }`
                        : `${this.props.item.data.actual_data.images[0].preview}`,
                      "large"
                    )
                  : defaultImg
              })`,
            }}
          ></div>
        </Col>
        <Col className="main-content" xs="8" md="10" lg="10" xl="10">
          <Accordion expanded={expanded} className="card">
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ width: "100%" }}>
                <div
                  className={
                    this.props.item.data.actual_data.time_start ||
                    this.props.item.data.actual_data.duration ||
                    this.props.item.data.actual_data.type
                      ? "title-action"
                      : "title-action float-right"
                  }
                >
                  <div onClick={this.handleExpand.bind(this)}>
                    {this.props.item.data.actual_data.time_start && (
                      <span className="time">
                        <AccessTimeIcon />
                        <span>
                          {format24hTo12h(
                            this.props.item.data.actual_data.time_start
                          )}
                        </span>
                      </span>
                    )}
                    {!this.props.item.data.actual_data.time_start &&
                    this.props.item.data.actual_data.duration ? (
                      <span className="time">
                        <AccessTimeIcon />
                        <span>
                          Duration:{" "}
                          {formatSecondsToDuration(
                            this.props.item.data.actual_data.duration
                          )}
                        </span>
                      </span>
                    ) : (
                      this.props.item.data.actual_data.time_start &&
                      this.props.item.data.actual_data.duration > 0 && (
                        <span className="time">
                          <span>
                            -{" "}
                            {calcEndTime(
                              this.props.item.data.actual_data.time_start,
                              this.props.item.data.actual_data.duration
                            )}
                          </span>
                        </span>
                      )
                    )}
                    {nameCategory === categoriesDefined.ACCOMMODATION ||
                    nameCategory === categoriesDefined.TRANSPORTATION ? (
                      <span className="status">
                        <span>
                          {this.props.item.data.actual_data.type.toUpperCase()}
                        </span>
                      </span>
                    ) : null}
                  </div>
                  <div className="action">
                    <Tooltip
                      title={
                        this.props.profile
                          ? "Favorite"
                          : "You need sign in to save this stop to library"
                      }
                      onClick={
                        this.props.profile
                          ? this.handleOpenFormSaveToLibrary.bind(this)
                          : null
                      }
                    >
                      <div className="favorite-icon">
                        <i
                          className={
                            this.state.isMarked
                              ? "fas fa-bookmark"
                              : "far fa-bookmark"
                          }
                          style={{ fontSize: "18px", color: "#ff8ae6" }}
                        />
                      </div>
                    </Tooltip>

                    <Tooltip
                      onClick={this.handleOpenFormViewEditPlace.bind(this)}
                      title="Edit"
                    >
                      <div className="edit-icon">
                        <i
                          className="fas fa-pencil-alt"
                          style={{ fontSize: `18px`, color: `#0f95de` }}
                        ></i>
                      </div>
                    </Tooltip>
                    <Tooltip
                      onClick={this.handleRemovePlaceDetail.bind(this)}
                      title="Remove"
                    >
                      <div className="remove-icon">
                        <i
                          className="far fa-trash-alt"
                          style={{ fontSize: "18px", color: "red" }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div onClick={this.handleExpand.bind(this)}>
                  <p className="place-title">
                    {this.props.item.data &&
                      this.props.item.data.actual_data.title}
                  </p>
                  <p className="place-address">
                    {this.props.item.data &&
                      this.props.item.data.actual_data.address}
                  </p>
                </div>
                <div onClick={this.handleExpand.bind(this)}>
                  {(this.props.item.data.actual_data.booked_through ||
                    this.props.item.data.actual_data.confirmation ||
                    this.props.item.data.actual_data.provider ||
                    this.props.item.data.actual_data.amount) && (
                    <div
                      className="content-event-detail"
                      style={{
                        background: `#f6f6f6`,
                        padding: `10px`,
                        marginTop: "20px",
                      }}
                    >
                      {this.props.item.data.actual_data.booked_through && (
                        <div className="content">
                          Booked through:{" "}
                          <span className="value">
                            {this.props.item.data.actual_data.booked_through}
                          </span>
                        </div>
                      )}
                      {this.props.item.data.actual_data.confirmation && (
                        <div className="content">
                          Reservation code:{" "}
                          <span className="value">
                            {this.props.item.data.actual_data.confirmation}
                          </span>
                        </div>
                      )}
                      {this.props.item.data.actual_data.amount && (
                        <div className="content">
                          Price:{" "}
                          <span className="value">
                            {formatMoney(
                              this.props.item.data.actual_data.amount,
                              {
                                symbol: this.props.currency_infor.symbol,
                                precision: this.props.currency_infor
                                  .decimal_digits,
                                thousand: ",",
                                decimal: ".",
                              }
                            )}{" "}
                            {this.props.currency_infor.code}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {(this.props.item.data.actual_data.descriptions ||
                  !!this.state.images.length) && (
                  <Collapse in={this.state.expanded} timeout="auto">
                    <div
                      style={{
                        display: "grid",
                        width: "100%",
                        marginTop: "1rem",
                      }}
                      onClick={this.handleExpand.bind(this)}
                    >
                      <div className="content1-event">
                        {this.props.item.data.actual_data.descriptions && (
                          <div className="content trix-content-temp">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: newText[0].props.children,
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                      {!!this.state.images.length && (
                        <Gallery
                          images={this.state.images}
                          enableImageSelection={false}
                        />
                      )}
                    </div>
                  </Collapse>
                )}
                <Tooltip title="Quote">
                  <img
                    className="icon-quote"
                    src={IconQuote}
                    onClick={this.handleShowQuote.bind(this)}
                  />
                </Tooltip>
              </div>
            </AccordionSummary>
          </Accordion>
        </Col>
      </React.Fragment>
    );
  };

  checkRenderItemInforOrNot = (expanded, newText) => {
    var temp = _.find(this.props.categories, (category) => {
      return category._id === this.props.item.category;
    });
    return temp.name === categoriesDefined.INFORMATION ? this.renderItemInfor(expanded, newText) : this.renderNotItemInfor(expanded, newText, temp.name);
  };

  render() {
    var { expanded } = this.state;
    var newText = this.props.item.data.actual_data.descriptions
      .split("\n")
      .map((line, i, arr) => {
        const newLine = <span key={i}>{line}</span>;

        if (i === arr.length - 1) {
          return newLine;
        } else {
          return [newLine, <br key={i + "br"} />];
        }
      });
    var newPlaceDetail = null;
    if (this.props.item) {
      newPlaceDetail = {
        ...this.props.item,
        belong_to_library_place: this.state.belong_to_library_place,
        belong_to_library: this.state.belong_to_library,
      };
    }

    return (
      <div className={styles.placesInListSelected}>
        {this.state.openFormSaveToLibrary ? (
          <FormSaveToLibrary
            changeStatusSaved={this.props.changeStatusSaved}
            item={newPlaceDetail}
            closeFormSaveToLibrary={this.closeFormSaveToLibrary}
            setMarked={this.setMarked}
            setBelongLibrary={this.setBelongLibrary}
            open={this.state.openFormSaveToLibrary}
            newList={this.props.newList}
          />
        ) : null}
        <Dialog
          open={this.state.showForm ? true : false}
          onClose={this.closeFormLogin.bind(this)}
          fullWidth={true}
          maxWidth={"xs"}
          aria-labelledby="form-login-social-media selected"
        >
          <DialogContent style={{ minHeight: "569.281px" }}>
            <FormSignInUp closeForm={this.closeFormLogin}/>
          </DialogContent>
        </Dialog>

        <TimelineEvent
          icon={null}
          bubbleStyle={{ display: "none" }}
          title=""
          className="border-timeline1"
        ></TimelineEvent>
        <Row className="zoom-place">
          {!!this.props.categories.length && this.checkRenderItemInforOrNot(expanded, newText)}
        </Row>
        <Row className="setting-transport">
          <Col
            xs={4}
            className="float-left left-distant"
            style={
              this.props.index === this.props.length - 1 ||
              this.props.item.category === "Infor"
                ? hiddenTransportationRow
                : null
            }
          >
            {this.props.index < this.props.length - 1 && (
              <div className="icon-transportation">
                <span>
                  {{
                    Car: (
                      <MdDirectionsCar
                        onClick={this.handleOpenPopupViewTransport.bind(this)}
                      />
                    ),
                    Walk: (
                      <MdDirectionsWalk
                        onClick={this.handleOpenPopupViewTransport.bind(this)}
                      />
                    ),
                    Bike: (
                      <MdDirectionsBike
                        onClick={this.handleOpenPopupViewTransport.bind(this)}
                      />
                    ),
                    Bus: (
                      <MdDirectionsBus
                        onClick={this.handleOpenPopupViewTransport.bind(this)}
                      />
                    ),
                    Train: (
                      <MdTrain
                        onClick={this.handleOpenPopupViewTransport.bind(this)}
                      />
                    ),
                    Boat: (
                      <MdDirectionsBoat
                        onClick={this.handleOpenPopupViewTransport.bind(this)}
                      />
                    ),
                    Flight: (
                      <MdFlight
                        onClick={this.handleOpenPopupViewTransport.bind(this)}
                      />
                    ),
                  }[this.state.name] || this.defaultTransportation()}
                </span>
              </div>
            )}
            <div className="distance-detail">
              <span>
                {this.state.duration && this.state.distance
                  ? this.state.duration + " (" + this.state.distance + ")"
                  : null}
              </span>
              <Dropdown
                direction="right"
                isOpen={this.state.btnDropleft}
                toggle={() => {
                  this.setState({ btnDropleft: !this.state.btnDropleft });
                }}
              >
                <DropdownToggle className="setting-icon">
                  <MdSettings
                    onClick={() => this.setState({ btnDropleft: true })}
                    size={`1em`}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  <Row className="dropdown-width">
                    <Col xs="6" className="vertical-divider">
                      <DropdownItem
                        className={
                          this.state.name === "Car" ? "selectedTransport" : ""
                        }
                        /*onClick={this.handleAddInfoTransportation.bind(
                          this,
                          "Car",
                          false
                        )}*/
                      >
                        <Row>
                          <Col xs="3">
                            <MdDirectionsCar />
                          </Col>
                          <Col xs="9">
                            &nbsp;Car
                            {this.state.name === "Car" ? (
                              <p className="distance-duration">
                                {this.state.duration && this.state.distance
                                  ? this.state.duration +
                                    ", " +
                                    this.state.distance
                                  : null}
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                      </DropdownItem>
                      <DropdownItem
                        className={
                          this.state.name === "Walk" ? "selectedTransport" : ""
                        }
                        /*onClick={this.handleAddInfoTransportation.bind(
                          this,
                          "Walk",
                          false
                        )}*/
                      >
                        <Row>
                          <Col xs="3">
                            <MdDirectionsWalk />
                          </Col>
                          <Col xs="9">
                            &nbsp;Walk
                            {this.state.name === "Walk" ? (
                              <p className="distance-duration">
                                {this.state.duration && this.state.distance
                                  ? this.state.duration +
                                    ", " +
                                    this.state.distance
                                  : null}
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                      </DropdownItem>
                      <DropdownItem
                        className={
                          this.state.name === "Bike" ? "selectedTransport" : ""
                        }
                        /*onClick={this.handleAddInfoTransportation.bind(
                          this,
                          "Bike",
                          false
                        )}*/
                      >
                        <Row>
                          <Col xs="3">
                            <MdDirectionsBike />
                          </Col>
                          <Col xs="9">
                            &nbsp;Bike
                            {this.state.name === "Bike" ? (
                              <p className="distance-duration">
                                {this.state.duration && this.state.distance
                                  ? this.state.duration +
                                    ", " +
                                    this.state.distance
                                  : null}
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                      </DropdownItem>
                      <DropdownItem
                        className={
                          this.state.name === "Bus" ? "selectedTransport" : ""
                        }
                        /*onClick={this.handleAddInfoTransportation.bind(
                          this,
                          "Bus",
                          false
                        )}*/
                      >
                        <Row>
                          <Col xs="3">
                            <MdDirectionsBus />
                          </Col>
                          <Col xs="9">
                            &nbsp;Bus
                            {this.state.name === "Bus" ? (
                              <p className="distance-duration">
                                {this.state.duration && this.state.distance
                                  ? this.state.duration +
                                    ", " +
                                    this.state.distance
                                  : null}
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                      </DropdownItem>
                      <DropdownItem
                        className={
                          this.state.name === "Train" ? "selectedTransport" : ""
                        }
                        /*onClick={this.handleAddInfoTransportation.bind(
                          this,
                          "Train",
                          false
                        )}*/
                      >
                        <Row>
                          <Col xs="3">
                            <MdTrain />
                          </Col>
                          <Col xs="9">
                            &nbsp;Train
                            {this.state.name === "Train" ? (
                              <p className="distance-duration">
                                {this.state.duration && this.state.distance
                                  ? this.state.duration +
                                    ", " +
                                    this.state.distance
                                  : null}
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                      </DropdownItem>
                      <DropdownItem
                        className={
                          this.state.name === "Boat" ? "selectedTransport" : ""
                        }
                        /*onClick={this.handleAddInfoTransportation.bind(
                          this,
                          "Boat",
                          false
                        )}*/
                      >
                        <Row>
                          <Col xs="3">
                            <MdDirectionsBoat />
                          </Col>
                          <Col xs="9">
                            &nbsp;Boat
                            {this.state.name === "Boat" ? (
                              <p className="distance-duration">
                                {this.state.duration && this.state.distance
                                  ? this.state.duration +
                                    ", " +
                                    this.state.distance
                                  : null}
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                      </DropdownItem>
                      <DropdownItem
                        className={
                          this.state.name === "Flight"
                            ? "selectedTransport"
                            : ""
                        }
                        /*onClick={this.handleAddInfoTransportation.bind(
                          this,
                          "Flight",
                          false
                        )}*/
                      >
                        <Row>
                          <Col xs="3">
                            <MdFlight />
                          </Col>
                          <Col xs="9">
                            &nbsp;Plane
                            {this.state.name === "Flight" ? (
                              <p className="distance-duration">
                                {this.state.duration && this.state.distance
                                  ? this.state.duration +
                                    ", " +
                                    this.state.distance
                                  : null}
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                      </DropdownItem>
                    </Col>
                    <Col xs="6" className="right-setting-column">
                      <DropdownItem>
                        <Row onClick={this.handleEditSetting.bind(this)}>
                          <Col xs="3">
                            <MdAttachMoney />
                          </Col>
                          <Col xs="9">&nbsp;Add price</Col>
                        </Row>
                      </DropdownItem>
                      <DropdownItem>
                        <Row onClick={this.handleEditSetting.bind(this)}>
                          <Col xs="3">
                            <MdStraighten />
                          </Col>
                          <Col xs="9">&nbsp;Add distance</Col>
                        </Row>
                      </DropdownItem>
                      <DropdownItem>
                        <Row onClick={this.handleEditSetting.bind(this)}>
                          <Col xs="3">
                            <MdAccessTime />
                          </Col>
                          <Col xs="9">&nbsp;Set time</Col>
                        </Row>
                      </DropdownItem>
                      <DropdownItem>
                        <Row onClick={this.handleEditSetting.bind(this)}>
                          <Col xs="3">
                            <MdAccessTime />
                          </Col>
                          <Col xs="9">&nbsp;Set duration</Col>
                        </Row>
                      </DropdownItem>
                      <DropdownItem>
                        <Row onClick={this.handleEditSetting.bind(this)}>
                          <Col xs="3">
                            <MdEventNote />
                          </Col>
                          <Col xs="9">&nbsp;Add note</Col>
                        </Row>
                      </DropdownItem>
                    </Col>
                  </Row>
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>
          <Col xs={8}>
            {this.props.item.quote && (
              <blockquote onClick={this.handleShowQuote.bind(this)}>
                <div className="quote">{this.props.item.quote}</div>
              </blockquote>
            )}
          </Col>
        </Row>
        <Dialog
          open={this.state.openPopupSetting ? true : false}
          onClose={this.handleCloseEditSetting.bind(this)}
          maxWidth={"xs"}
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">
            Customize your transportation{" "}
          </DialogTitle>
          <DialogContent>
            <Row>
              <Col>
                <TextField
                  id="standard-name"
                  label="Price"
                  margin="none"
                  type="number"
                  fullWidth
                  value={this.state.price}
                  onChange={this.handleChange("price")}
                />
              </Col>
              <Col>
                <TextField
                  id="standard-name"
                  label="Distance (km)"
                  margin="none"
                  fullWidth
                  value={this.state.distance}
                  onChange={this.handleChange("distance")}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  id="time"
                  label="Time"
                  type="time"
                  value={this.state.time}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                  }}
                  margin="none"
                  fullWidth
                  onChange={this.handleChange("time")}
                />
              </Col>
              <Col>
                <TextField
                  id="standard-name"
                  label="Duration (mins)"
                  margin="none"
                  fullWidth
                  value={this.state.duration}
                  onChange={this.handleChange("duration")}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  id="standard-name"
                  label="Note"
                  margin="none"
                  fullWidth
                  multiline
                  rowsMax="3"
                  value={this.state.note}
                  onChange={this.handleChange("note")}
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions className={styles.placesInListSelected}>
            <Button
              type="submit"
              className="btn-primary"
              /*onClick={this.handleAddInfoTransportation.bind(
                this,
                this.state.name,
                true
              )}*/
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={this.handleCloseEditSetting.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlacesInListSelected);
