import React from 'react';
import { Avatar, Menu, Dropdown, Popover, Button, Row, Icon } from 'antd';
import { isMyResource } from '../../services/utils.service';
import { getToken } from '../../services/authService';
import { config, configBaseUrl } from '../../config';
import { connect } from 'react-redux';
import { saveProfile, logout } from '../../store/actions';
import './index.scss';
import { ButtonCreateTyneri, FormSignInUp } from '../../components';
import {
  UserOutlined,
  ExportOutlined,
  DashboardFilled,
  UserAddOutlined,
} from '@ant-design/icons';
import ApiService from '../../services/api.service';
import Image from '../../functions/image';
import { isBrowser, isMobileOnly, isTablet } from 'react-device-detect';
import { Link, withRouter } from 'react-router-dom';
import AvatarNotFound from '../../assets/media/images/default-avatar.png';
import Dialog from '@material-ui/core/Dialog';

export const MenuObject = (props = {}) => {
  class MenuClass {
    constructor({
      name,
      icon,
      link = '#',
      proAccount = false,
      hideOnNoLogin = false,
      target = null,
    }) {
      this.name = name;
      this.icon = icon;
      this.link = link;
      this.proAccount = proAccount;
      this.hideOnNoLogin = hideOnNoLogin;
      this.target = target;
    }
  }
  return new MenuClass(props);
};

const menuData = [
  MenuObject({
    name: 'Dashboard',
    icon: <DashboardFilled />,
    link: '/dashboard',
  }),
  MenuObject({
    name: 'Profile',
    icon: <UserOutlined />,
    link: '/my-profile',
  }),
];

const menuHorizon = [
  MenuObject({
    name: 'Trending',
    link: '/',
  }),
  MenuObject({
    name: 'Top lists',
    link: '/top-lists',
  }),
  MenuObject({
    name: 'Top Attractions',
    link: '/top-attractions',
  }),
  MenuObject({
    name: (
      <>
        <Icon style={{ marginRight: 5 }} type="folder" />
        My Library
      </>
    ),
    link: '/my-library',
    hideOnNoLogin: true,
  }),
];

function MenuLogin({ pageCompany = false, ...props }) {
  const apiService = ApiService();
  const [loading, setLoading] = React.useState(true);
  const [showFormLogin, setShowFormLogin] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const company = props.company;

  function handleLogout() {
    apiService.logout().then((res) => {
      if (res.data.status === 'success') {
        setIsAuthenticated(false);
        props.logout();
      }
    });
  }

  React.useEffect(() => {
    if (props.profile) {
      setUser(props.profile);
      setIsAuthenticated(true);
      setShowFormLogin(false);
    }
    setLoading(false);
    if (!getToken()) {
      setIsAuthenticated(false);
      setUser(null);
    }
  }, [props.profile]);

  function closeForm() {
    setShowFormLogin(false);
  }

  function LoginButton() {
    return (
      <>
        <Dialog
          open={showFormLogin ? true : false}
          onClose={() => {
            setShowFormLogin(false);
            setShowLogin(true);
          }}
          fullWidth={true}
          maxWidth={'xs'}
          aria-labelledby="form-login-social-media content"
        >
          <FormSignInUp closeForm={closeForm} />
        </Dialog>
        {isBrowser || isTablet ? (
          <MenuProfile mode="horizontal" showName={false} />
        ) : null}
        {pageCompany ? null : (
          <Button
            type="text"
            size={isBrowser ? 'default' : isMobileOnly ? 'small' : 'default'}
            className="button-sign-in hover-effect"
            onClick={() => setShowFormLogin(true)}
          >
            <UserAddOutlined /> Sign in
          </Button>
        )}
      </>
    );
  }

  function AvatarUser() {
    const avatarUser = () => {
      if (company && isMyResource(company.logo))
        return configBaseUrl.baseURL + company.logo;

      if (user.avatar && isMyResource(user.avatar))
        return configBaseUrl.baseURL + user.avatar;

      return AvatarNotFound;
    };

    return (
      <Popover
        placement="bottomRight"
        className="account"
        content={
          <div className="popover-account">
            <span className="name">{user.firstName}</span>
            <br />
            <span className="email">{user.email}</span>
          </div>
        }
      >
        <Avatar
          style={{ marginLeft: 10, cursor: 'pointer' }}
          alt="avatar"
          size={40}
          icon={<Image src={avatarUser()} alt="avatar-user" decode={false} />}
        />
      </Popover>
    );
  }

  function MenuProfile({ mode = null, ...props }) {
    return (
      <Menu mode={mode || 'inline'}>
        {mode !== 'horizontal' ? (
          <>
            <li>
              <span className="name">
                {user && `${user.firstName} ${user.lastName}`}
              </span>
            </li>
            <li>
              <span className="email">{user && `${user.email}`}</span>
              <span className="divider">
                <div className="dash" />
              </span>
            </li>
          </>
        ) : null}

        {mode !== 'horizontal'
          ? menuData.map((menu, index) => {
              return (
                <Menu.Item key={index}>
                  {pageCompany ? (
                    <a href={config.http + '//' + config.domain + menu.link}>
                      {menu.icon}
                      {menu.name}
                    </a>
                  ) : (
                    <Link to={menu.link}>
                      {menu.icon}
                      {menu.name}
                    </Link>
                  )}
                </Menu.Item>
              );
            })
          : menuHorizon.map((menu, index) => {
              if (menu.hideOnNoLogin) {
                if (!isAuthenticated) return;
              }
              return (
                <Menu.Item key={index}>
                  {pageCompany ? (
                    <a
                      target={menu.target}
                      href={config.http + '//' + config.domain + menu.link}
                    >
                      {menu.name}
                    </a>
                  ) : (
                    <Link target={menu.target} to={menu.link}>
                      {menu.name}
                    </Link>
                  )}
                </Menu.Item>
              );
            })}

        {mode !== 'horizontal' ? (
          <Menu.Item key="3" onClick={handleLogout}>
            <span className="sign-out">
              <span className="divider">
                <div className="dash"></div>
              </span>
              <span className="sign-out-icon">
                <ExportOutlined /> Sign out
              </span>
            </span>
          </Menu.Item>
        ) : null}
      </Menu>
    );
  }

  function UserMenu() {
    if (props.noShowMenu) return <AvatarUser />;
    return (
      <React.Fragment>
        <Row type="flex" align="middle" style={{ flexFlow: 'row' }}>
          {props.adminLayout ? (
            <Dropdown
              overlay={
                <div className="ant-dropdown-custom">
                  <MenuProfile />
                </div>
              }
              placement={props.placement ? props.placement : 'bottomRight'}
              arrow={true}
              trigger={['click']}
            >
              <div
                className="dropdown-hover"
                onClick={(e) => e.preventDefault()}
              >
                <AvatarUser />
              </div>
            </Dropdown>
          ) : (
            <>
              {isBrowser || isTablet ? (
                <MenuProfile mode="horizontal" showName={false} />
              ) : null}
              <Dropdown
                overlay={
                  <div className="ant-dropdown-custom">
                    <MenuProfile />
                  </div>
                }
                placement={props.placement ? props.placement : 'bottomRight'}
                arrow={true}
                trigger={['click']}
              >
                <div
                  className="dropdown-hover"
                  onClick={(e) => e.preventDefault()}
                >
                  <AvatarUser />
                </div>
              </Dropdown>
            </>
          )}
        </Row>
      </React.Fragment>
    );
  }
  if (loading) return null;
  const {
    match: { path },
  } = props;
  return (
    <div className={'menu-login'}>
      {(isBrowser || isTablet) && !props.adminLayout && !pageCompany ? (
        <div className="menu-and-button">
          <ButtonCreateTyneri
            pageCompany={pageCompany}
            className="create-tyneri"
          />
        </div>
      ) : null}
      {isAuthenticated ? <UserMenu /> : <LoginButton />}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
    company: state.profileReducer.company,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MenuLogin));
