import {config} from "../config";
import axios from '../axios-config';

export default () => {
    let { baseURL } = config;
    return {
        stripeCancelSubscript: () => {
            return axios.post ('users/cancel-subscript').then(res => res.data).catch(error => error.response.data);
        },
        stripCreateCustomer: (paymentMethod) => {
            return axios.post ('payment/register', paymentMethod).then(res => res.data);
        },
        stripeDeleteCustomer: () => {
            return axios.delete('payment/delete').then(res => res.data);
        },
        getPackages: () => {
            return axios.get('payment/packages').then(res => res.data);
        },
        getInvoices: () => {
            return axios.get('payment/invoices').then(res => res.data).catch(error => error.response.data);
        },
        getUpcomingInvoice: () => {
            return axios.get('payment/invoices-upcoming').then(res => res.data).catch(error => error.response.data);
        },
        getInvoicesOpen: () => {
            return axios.get('payment/invoices-open').then(res => res.data).catch(error => error.response.data);
        },
        getPaymentIntent: (id) => {
            return axios.get('payment/pi/'+id).then(res => res.data);
        }
    }
};
