import React from "react";
import { Col, Row } from "antd";
import styles from "../index.scss";
import { CardTopNew } from "../..";
import DefaultBackground from "../../../assets/media/images/generic-activities-img.jpg";
import { Link } from "react-router-dom";
const MyPlans = (props) => {
  if (props.list && !!props.list.length)
    return (
      <div className="list-tinerary">
        <Row id="breadcrumb" className={styles.breadcrumb}>
          <Col span={12}>
            <h3>{props.title}</h3>
          </Col>
          <Col span={12}>
            <div className="text-right">
              <Link to="/my-tyneri">See more</Link>
            </div>
          </Col>
        </Row>
        <Row id="top-new" className="list-data-item">
          <Col>
            {props.list.map((item, key) => (
              <Link key={key} to={`/view-itinerary/${item._id}`}>
                <div className="each-card-my-plans">
                  {/* <CardCustom
                    title={item.name}
                    estimated_budget={item.estimated_budget}
                    a_number_of_paxs={item.a_number_of_paxs}
                    tag={item.city}
                    centerIconName="fas fa-play-circle"
                    bottomIconName="fas fa-ellipsis-h"
                    bgPhoto={item.cover ? item.cover : DefaultBackground}
                    totalReviews={30}
                    ratingAverage={4.5}
                    options={item.options ? item.options : []}
                    list_optionals={props.list_optionals}
                  /> */}
                  <CardTopNew
                    _id={item._id}
                    idItineraryDetail={
                      item.itinerary_detail[0].children[0].children[0]._id
                    }
                    isMyPlan={true}
                    key={key}
                    country={item.country.nativeName}
                    city={item.city}
                    name={item.name}
                    currency_infor={item.currency_infor}
                    estimated_budget={item.estimated_budget}
                    a_number_of_paxs={item.a_number_of_paxs}
                    cover={item.cover ? item.cover : DefaultBackground}
                    setNewListMyPlans={props.setNewListMyPlans}
                    list={props.list}
                  />
                </div>
              </Link>
            ))}
          </Col>
        </Row>
      </div>
    );
  return null;
};

export default MyPlans;
