import React, {useEffect, useState} from "react";
import TopListService from "../../../../../services/topList.service";
import {Button, Col, List, Row, Skeleton, Typography} from "antd";
import "./index.scss";
import ImageHelper from "../../../../../functions/image";
import DefaultBackground from "../../../../../assets/media/images/generic-activities-img.jpg";
import {getImageFromUrl, getNameAuthor, isMyResource} from "../../../../../services/utils.service";
import {config, configBaseUrl} from "../../../../../config";
import StringHelper from "../../../../../helper/stringHelper";
import moment from "moment";

const {Text} = Typography;

const TopListCompany = (props) => {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const defaultLimit = 8;

    useEffect(() => {
        async function fetchData () {
            const old = data;
            setData(data.concat([...new Array(defaultLimit)].map(() => ({ loading: true, name: {} }))));
            let val = await TopListService().getUserTopList({
                page,
                limit: defaultLimit
            });
            if (val.status === 'success') {
                setData(old.concat(val.data));
                setTotal(val.total);
                props.fetch(val.total);
            }
        }
        fetchData();
    }, [page]);

    const loadMore = page < (total / defaultLimit) ? (
        <div
            style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,
                lineHeight: '32px',
            }}
        >
            <Button type="primary" shape="round" onClick={()=> setPage(page + 1)}>Show more</Button>
        </div>
    ) : null;

    return (
        <List
            itemLayout="vertical"
            className="my-top-list"
            loadMore={loadMore}
            dataSource={data}
            size="large"
            renderItem={(item, index) => {
                return <List.Item key={index}>
                    <Skeleton title={true} loading={item.loading} active>
                        <Row type="flex" align="top" gutter={24}>
                            <Col className="left-article" xs={24} md={12} lg={6} xl={6} xxl={6}>
                                <a href={config.clientURL +'view-top-list/'+item._id}><ImageHelper className='img-fluid' notFound={DefaultBackground} src={getImageFromUrl(isMyResource(item.cover) ? configBaseUrl.baseURL + item.cover : '', "small")}/></a>
                            </Col>
                            <Col className="right-article" xs={24} md={12} lg={18} xl={18} xxl={18}>
                                <List.Item.Meta
                                    title={<a href={config.clientURL +'view-top-list/'+item._id}>{item.title}</a>}
                                    description={<Text>
                                        <div className="author">
                                            By {getNameAuthor(item.belong_to_user, item.company)} - {moment(new Date(item.created_at)).fromNow()}
                                        </div>
                                    </Text>}
                                />
                                {StringHelper().stripeHtml(item.description, 200)}
                            </Col>
                        </Row>
                    </Skeleton>
                </List.Item>;
            }}
        />
    );
}

export default TopListCompany;
