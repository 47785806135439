import React, { Component } from "react";
import { Card, Icon, Tag, Row, Col } from "antd";
import "./index.scss";
import { Link } from "react-router-dom";
import {
  isMyResource,
  getImageFromUrl,
  getNameItinerary,
  formatViews,
} from "../../services/utils.service";
import { configBaseUrl } from "../../config";
import defaultImg from "../../assets/media/images/generic-activities-img.jpg";

class TyneriRelated extends Component {
  handleView = (_id) => {
    window.location = `${process.env.PUBLIC_URL}/view-itinerary/${_id}`;
  };

  render() {
    return (
      <div className={"cardList"}>
        <Row gutter={16}>
          {this.props.listRecommend &&
            !!this.props.listRecommend.length &&
            this.props.listRecommend.map((item, key) => (
              <Col
                key={key}
                xs={24}
                sm={12}
                md={6}
                lg={12}
                xl={12}
                xxl={12}
                className="each-related"
              >
                {/*<Link to={`/view-itinerary/${item._id}`}></Link>*/}
                <Card
                    hoverable
                    onClick={this.handleView.bind(this, item._id)}
                    className="cardBlock"
                    size="small"
                    cover={
                      <aside
                          style={{
                            backgroundImage: `url(${
                                item.cover
                                    ? getImageFromUrl(
                                    isMyResource(item.cover)
                                        ? `${configBaseUrl.baseURL + item.cover}`
                                        : `${item.cover}`,
                                    "large"
                                    )
                                    : defaultImg
                            })`,
                          }}
                          className="cartImage"
                      >
                        {item.city && (
                            <Tag color="#e33c36" className="card-tag-right">
                              {item.city}
                            </Tag>
                        )}
                        {!!item.options.length && (
                            <div className="hover-info">
                              {this.props.list_optionals.map((childA, keyA) => {
                                if (childA.group_name === "pref") {
                                  return (
                                      <React.Fragment key={keyA}>
                                        {childA.detail.map((childB, index) => {
                                          let isChecked = item.options.findIndex(
                                              (el) =>
                                                  el.option_code === childB.option_code
                                          );
                                          if (isChecked >= 0) {
                                            return (
                                                <span
                                                    className="each-option option-pref"
                                                    key={index}
                                                >
                                            <span>
                                              #{childB.name.toLowerCase()}
                                            </span>
                                          </span>
                                            );
                                          }
                                          return null;
                                        })}
                                      </React.Fragment>
                                  );
                                } else if (childA.group_name === "age") {
                                  return (
                                      <React.Fragment key={keyA}>
                                        {childA.detail.map((childB, index) => {
                                          let isChecked = item.options.findIndex(
                                              (el) =>
                                                  el.option_code === childB.option_code
                                          );
                                          if (isChecked >= 0) {
                                            return (
                                                <span
                                                    className="each-option option-age"
                                                    key={index}
                                                >
                                            <span>
                                              #age{childB.name.toLowerCase()}
                                            </span>
                                          </span>
                                            );
                                          }
                                          return null;
                                        })}
                                      </React.Fragment>
                                  );
                                } else if (childA.group_name === "plan") {
                                  return (
                                      <React.Fragment key={key}>
                                        {childA.detail.map((childB, index) => {
                                          let isChecked = item.options.findIndex(
                                              (el) =>
                                                  el.option_code === childB.option_code
                                          );
                                          if (isChecked >= 0) {
                                            return (
                                                <span
                                                    className="each-option option-type"
                                                    key={index}
                                                >
                                            <span>
                                              #{childB.name.toLowerCase()}
                                            </span>
                                          </span>
                                            );
                                          }
                                          return null;
                                        })}
                                      </React.Fragment>
                                  );
                                }else {
                                  return <React.Fragment/>;
                                }
                              })}
                            </div>
                        )}
                      </aside>
                    }
                >
                  <Card.Meta
                      title={
                        <>
                          <div className="total-views">
                            <Icon type="eye" />
                            {" "}
                            <span>{item.views > 1 ? `${formatViews(item.views)} views` : `${formatViews(item.views)} view`}</span>
                          </div>
                          <div className="content">
                            {getNameItinerary(item)}
                          </div>
                        </>
                      }
                  />
                </Card>
              </Col>
            ))}
        </Row>
      </div>
    );
  }
}

export default TyneriRelated;
