export const config = {
  baseURL: 'https://api.tyneri.com/',
  apiKeyExchangeRate: '949b634ab3df4d7faa89efa029a4196f',
  clientURL: "https://tyneri.com/",
  dev: false,
  domain:'tyneri.com',
  http: window.location.protocol,
stripe_pk: "pk_test_51Hvx31GgSAAd6Ktjt9m7LcnIzM1r0dVDlNpwDC9xuWQAMpGH3aTeSg4qsW8qwdim7Bjfu2R6774oMncKZLRBW0AJ00WX7E99ao"
}
export const configBaseUrl = {
  baseURL: "https://api.tyneri.com/"
};

