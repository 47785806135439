import React, { Component } from "react";
import {
  ContentTopDestinations
} from "../../components";
import ApiService from "../../services/api.service";
import { connect } from "react-redux";
import {
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout
} from "../../store/actions";

const mapDispatchToProps = dispatch => {
  return {
    saveCurrentItinerary: itinerary => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: amount => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: info => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    }
  };
};

class TopDestinations extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    document.title = "Tyneri";
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    return <ContentTopDestinations />
  }
}

export default connect(null, mapDispatchToProps)(TopDestinations);
