import React, { Component } from "react";
import ApiService from "../../services/api.service";
import { connect } from "react-redux";
import {
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout,
  updateInforCurrency,
} from "../../store/actions";
import { withRouter } from "react-router";
import { PageHeader, Timeline, Typography } from "antd";
import "./index.scss";
import _ from "lodash";
import { getImageFromUrl, isMyResource } from "../../services/utils.service";
import { configBaseUrl } from "../../config";
import defaultImg from "../../assets/media/images/generic-activities-img.jpg";
import { isMobileOnly } from "react-device-detect";
import Cookies from "universal-cookie";
import $ from "jquery";
const { Title } = Typography;

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
    ip: state.ipReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInforCurrency: (currency) => {
      dispatch(updateInforCurrency(currency));
    },
    saveCurrentItinerary: (itinerary) => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

class ContentViewTopList extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      disabled: {}
    };
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    $('.ant-timeline-item').each((i, item) => {
      let titleHeight = $(item).find('.title-vote > .ant-typography').height();
      $(item).find('.ant-page-header-heading').css('top', titleHeight);
    });
  }

  componentDidMount() {
    $('.ant-timeline-item').each((i, item) => {
      let titleHeight = $(item).find('.title-vote > .ant-typography').height();
      $(item).find('.ant-page-header-heading').css('top', titleHeight);
    });
  }

  handleVoteDown = (_id) => {
    if (this.state.disabled[`${_id}`]) return;
    let tempDisabled = { ...this.state.disabled };
    tempDisabled[`${_id}`] = true;
    this.setState(
      {
        disabled: tempDisabled,
      },
      () => {
        var user_id;
        if (this.props.profile) user_id = this.props.profile._id;
        else user_id = null;

        const cookies = new Cookies();
        const cookie = cookies.get("id");

        this.apiService
          .voteDownTopListDetail(_id, user_id, cookie)
          .then((res) => {
            if (res.status === "success") {
              this.props.setList();
            }
          });
      }
    );
  };

  handleVoteUp = async (_id) => {
    if (this.state.disabled[`${_id}`]) return;
    let tempDisabled = { ...this.state.disabled };
    tempDisabled[`${_id}`] = true;
    this.setState(
      {
        disabled: tempDisabled,
      },
      async () => {
        var user_id;
        if (this.props.profile) user_id = this.props.profile._id;
        else user_id = null;

        const cookies = new Cookies();
        const cookie = cookies.get("id");

        await this.apiService
          .voteUpTopListDetail(_id, user_id, cookie)
          .then(async (res) => {
            if (res.status === "success") {
              await this.props.setList();
            }
          });
      }
    );
  };

  renderCheckVoted = (item) => {
    const cookies = new Cookies();
    const cookie = cookies.get("id");
    if (this.props.profile) {
      var votedByUser = _.find(item.list_votes, {
        user_id: this.props.profile._id,
      });
      if (votedByUser) {
        return (
          <div className="wq-question-votes">
            <button
              type="button"
              disabled={
                votedByUser || this.state.disabled[`${item._id}`] ? true : false
              }
              className={
                !votedByUser.vote
                  ? "wq-question-vote-btn wq-question-vote-down-btn is-voted"
                  : "wq-question-vote-btn wq-question-vote-down-btn"
              }
              onClick={this.handleVoteDown.bind(this, item._id)}
            >
              <span className="icon"></span>
              <span className="number">{item.vote_down}</span>
              <span className="text">Votes</span>
            </button>

            <button
              type="button"
              disabled={
                votedByUser || this.state.disabled[`${item._id}`] ? true : false
              }
              className={
                votedByUser.vote
                  ? "wq-question-vote-btn wq-question-vote-up-btn is-voted"
                  : "wq-question-vote-btn wq-question-vote-up-btn"
              }
              onClick={this.handleVoteUp.bind(this, item._id)}
            >
              <span className="icon"></span>
              <span className="number">{item.vote_up}</span>
              <span className="text">Votes</span>
            </button>
          </div>
        );
      } else {
        return (
          <div className="wq-question-votes">
            <button
              type="button"
              className="wq-question-vote-btn wq-question-vote-down-btn"
              onClick={this.handleVoteDown.bind(this, item._id)}
            >
              <span className="icon"></span>
              <span className="number">{item.vote_down}</span>
              <span className="text">Votes</span>
            </button>

            <button
              type="button"
              className="wq-question-vote-btn wq-question-vote-up-btn"
              onClick={this.handleVoteUp.bind(this, item._id)}
            >
              <span className="icon"></span>
              <span className="number">{item.vote_up}</span>
              <span className="text">Votes</span>
            </button>
          </div>
        );
      }
    } else {
      var votedAnonymous = _.find(item.list_votes, {
        cookie: cookie,
        ip: this.props.ip,
      });
      if (votedAnonymous) {
        return (
          <div className="wq-question-votes">
            <button
              type="button"
              disabled={
                votedAnonymous || this.state.disabled[`${item._id}`]
                  ? true
                  : false
              }
              className={
                !votedAnonymous.vote
                  ? "wq-question-vote-btn wq-question-vote-down-btn is-voted"
                  : "wq-question-vote-btn wq-question-vote-down-btn"
              }
              onClick={this.handleVoteDown.bind(this, item._id)}
            >
              <span className="icon"></span>
              <span className="number">{item.vote_down}</span>
              <span className="text">Votes</span>
            </button>

            <button
              type="button"
              disabled={
                votedAnonymous || this.state.disabled[`${item._id}`]
                  ? true
                  : false
              }
              className={
                votedAnonymous.vote
                  ? "wq-question-vote-btn wq-question-vote-up-btn is-voted"
                  : "wq-question-vote-btn wq-question-vote-up-btn"
              }
              onClick={this.handleVoteUp.bind(this, item._id)}
            >
              <span className="icon"></span>
              <span className="number">{item.vote_up}</span>
              <span className="text">Votes</span>
            </button>
          </div>
        );
      } else {
        return (
          <div className="wq-question-votes">
            <button
              type="button"
              className="wq-question-vote-btn wq-question-vote-down-btn"
              onClick={this.handleVoteDown.bind(this, item._id)}
            >
              <span className="icon"></span>
              <span className="number">{item.vote_down}</span>
              <span className="text">Votes</span>
            </button>

            <button
              type="button"
              className="wq-question-vote-btn wq-question-vote-up-btn"
              onClick={this.handleVoteUp.bind(this, item._id)}
            >
              <span className="icon"></span>
              <span className="number">{item.vote_up}</span>
              <span className="text">Votes</span>
            </button>
          </div>
        );
      }
    }
  };

  renderList = (list_places) => {
    return (
      <Timeline className="content-view-top-list">
        {list_places.map((item, index) => {
          return (
            <Timeline.Item dot={<></>} key={index}>
              <PageHeader
                title={
                  <span className="number-stop">{index + 1}</span>
                }
              >
                <div className="title-vote">
                  <Title level={3} variant="body1" component="strong">
                    {item.name}
                  </Title>
                  {this.renderCheckVoted(item)}
                </div>
                <div className="box-content">
                  <div className="stop-image">
                    <img
                        alt=""
                        src={
                          !_.isEmpty(item.image)
                              ? getImageFromUrl(
                              isMyResource(item.image)
                                  ? configBaseUrl.baseURL + item.image
                                  : item.image,
                              "small"
                              )
                              : defaultImg
                        }
                        style={{ width: "100%" }}
                    />
                  </div>
                  <Typography className="desc-stop" variant="caption">
                    <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </Typography>
                </div>
              </PageHeader>
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  };

  render() {
    return this.renderList(this.props.top_list.list_places);
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentViewTopList)
);
