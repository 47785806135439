import React, { Component } from "react";
import { Row, Col } from "antd";
import { CardCustom } from "../..";
import DefaultBackground from "../../../assets/media/images/generic-activities-img.jpg";
import SliderCustom from "../../slider-card-custom";
import { isTablet, isMobile } from "react-device-detect";
import {BottomLine} from "../../modules/typography/title";

class LatestItineraries extends Component {
  renderCardItem(item, index) {
    return (
      <div key={index} className="custom-card-tyneri">
        <a href={process.env.PUBLIC_URL+"/view-itinerary/" + item._id}>
          <CardCustom
            title={item.name}
            estimated_budget={item.estimated_budget}
            a_number_of_paxs={item.a_number_of_paxs}
            currency_infor={item.currency_infor}
            tag={item.city}
            centerIconName="fas fa-play-circle"
            bottomIconName="fas fa-ellipsis-h"
            bgPhoto={item.cover ? item.cover : DefaultBackground}
            totalReviews={30}
            views={item.views}
            ratingAverage={4.5}
            options={item.options ? item.options : []}
            list_optionals={this.props.list_optionals}
          />
        </a>
      </div>
    );
  }
  getDataTopTrending(data) {
    if (data && !!data.length) {
      let dataHtml = [];
      data.forEach((item, index) => {
        dataHtml.push(this.renderCardItem(item, index));
      });
      return dataHtml;
    }
    return null;
  }

  renderSliderWithCustomRow() {
    const listData = this.props.list;
    if (listData && !!listData.length) {
      let response = [
        {
          breakpoint: 1599,
          settings: {
            slidesToScroll: 3,
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 426,
          settings: {
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ];
      if (isTablet || isMobile) {
        response.push({
          breakpoint: 1367,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        });
      }
      return (
        <div className="two-row-top-trending">
          <SliderCustom
            pauseOnHover={true}
            settings={{
              responsive: response,
              autoplay: true,
              autoplaySpeed: 8000,
              infinite: true
            }}
            list={this.getDataTopTrending(listData)}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <Row className="row-itineraries">
        <Col md={4} xl={4} xxl={4}>
          <div className="grid-header">
            <span className="title-font huge">
              Most Recent
            </span>
            <BottomLine/>
          </div>
        </Col>
        <Col md={20} xl={20} xxl={20}>
          {this.renderSliderWithCustomRow()}
        </Col>
      </Row>
    );
  }
}

export default LatestItineraries;
