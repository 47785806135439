import React, { useState } from "react";
import { Modal, Button, Form, Input } from "antd";
import { EditFilled } from "@ant-design/icons";

import Alert from "../../../alert";
import apiService from "../../../../../../services/api.service";

function EditFolder(props) {
  const { getFieldDecorator } = props.form;
  const [open, setOpen] = useState(false);
  const item = props.item;
  const form = props.form;
  const ApiService = apiService();
  const alert = new Alert();

  function handleChangeName() {
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const folder = {
          _id: item._id,
          name: values["name"],
        };
        ApiService.changeNameFolder({ folder }).then((res) => {
          if (res.status === "success") {
            alert.success("Saved successfully");
            setOpen(false);
            props.getMyFolder();
          }
        });
      }
    });
  }

  function handleDeleteFolder() {
    Modal.confirm({
      content: "Are you sure you want to delete this folder?",
      okText: "Delete",
      cancelText: "Cancel",
      okType: "danger",
      onOk: () => {
        ApiService.deleteFolder(item._id).then((res) => {
          if (res.status === "success") {
            alert.success("Deleted successfully");
            setOpen(false);
            props.getMyFolder();
          }
        });
      },
    });
  }

  return (
    <>
      <Modal
        title={item.name}
        visible={open}
        className="edit-folder-modal"
        onCancel={() => setOpen(false)}
        footer={
          <>
            <Button type="danger" onClick={handleDeleteFolder}>
              Delete
            </Button>
            <Button key="submit" onClick={handleChangeName} type="primary">
              Change
            </Button>
          </>
        }
      >
        <Form>
          <Form.Item label="Folder name">
            {getFieldDecorator("name", {
              initialValue: item.name,
              rules: [
                {
                  required: true,
                  message: "Please input your folder name!",
                },
              ],
            })(<Input autoFocus placeholder="Folder name" />)}
          </Form.Item>
        </Form>
      </Modal>
      {props.manage ? (
        <EditFilled
          onClick={() => {
            setOpen(true);
          }}
        />
      ) : null}
    </>
  );
}

export default Form.create()(EditFolder);
