import React, { Component } from "react";
import { Col, Row } from "antd";
import styles from "./index.scss";
import { connect } from "react-redux";
import ApiService from "../../services/api.service";
import { loadItem } from "../../services/storage.service";

import TopTrending from "./top-trending";
import LatestItineraries from "./latest-itineraries";
import TopDestination from "./top-destination";
import TopContributors from "./top-contributors";
import MyDrafts from "./my-drafts";
import ListTyneri from "./list-tyneri";
import MyPlans from "./my-plans";
import HelperCreateTyneri from "../../assets/media/images/helper_create_tyneri.png";

import { isBrowser } from "react-device-detect";
import TopLists from "./top-lists";
import RecentItinerary from "../recent-itinerary";

class ContentTrendy extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      listItineraries: [],
      topDestinations: [],
      listRecent: [],
      list_optionals: [],
      list_my_plans: [],
      itinerarySeen: null,
      itineraryDraft: null,
      topContributors: [],

      listTopLists: []
    };
  }

  componentDidMount = () => {
    this.apiService.getAllOptionals().then((res) => {
      if (res.status === "success") {
        this.setState({
          list_optionals: res.list_optionals,
        });
      }
    });

    this.apiService.getFilter().then((res) => {
      if (res.status === "success") {
        this.setState({
          listItineraries: res.filter,
        });
      }
    });

    this.apiService.getTopDestinations().then((res) => {
      if (res.status === "success") {
        this.setState({
          topDestinations: res.top_destinations,
        });
      }
    });

    this.apiService.getTopContributors().then((res) => {
      if (res.status === "success") {
        this.setState({
          topContributors: res.listTop,
        });
      }
    });

    this.apiService.getTopListTrending().then((res) => {
      if (res.status === "success") {
        this.setState({
          listTopLists: res.data,
        });
      }
    });

    if (this.props.profile) {
      this.apiService.getMyPlans(this.props.profile._id).then((res) => {
        if (res.status === "success") {
          this.setState({
            list_my_plans: res.my_plans,
          });
        }
      });
    }

    var itineraryDraft =
      loadItem("itineraryDraft") !== null ? loadItem("itineraryDraft") : null;
    this.setState({
      itineraryDraft,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.profile) {
      this.apiService.getMyPlans(nextProps.profile._id).then((res) => {
        if (res.status === "success") {
          this.setState({
            list_my_plans: res.my_plans,
          });
        }
      });
    }
  };

  setNewListMyPlans = (newList) => {
    this.setState({ list_my_plans: newList });
  };

  setNewListDraft = (newList) => {
    this.setState({ itineraryDraft: newList });
  };

  render() {
    var itinerarySeen =
      loadItem("itinerarySeen") !== null ? loadItem("itinerarySeen") : {};
    return (
      <div id="content" className={styles.contentTrendy}>
        <Row>
          {/*  Left */}
          <Col
            className="filter-on-mobile content-left hide"
            md={8}
            xl={isBrowser ? 6 : 8}
            xxl={isBrowser ? 6 : 8}
          >
            {/* <div className="filter">
              {isMobileOnly ? <SearchBar /> : null}
              <Filter
                listCountries={this.state.listItineraries}
                list_optionals={this.state.list_optionals}
              />
            </div> */}

            {/* my drafts */}
            <MyDrafts
              isDraft={true}
              list={
                this.state.itineraryDraft
                  ? this.state.itineraryDraft.itineraries
                  : null
              }
              list_optionals={this.state.list_optionals}
              title={`My drafts (${
                this.state.itineraryDraft
                  ? this.state.itineraryDraft.itineraries.length
                  : null
              })`}
              setNewListDraft={this.setNewListDraft}
            />

            {/* list recently view */}
            {itinerarySeen && (
              <ListTyneri
                list={itinerarySeen.itineraries}
                title="Your recently viewed"
                isHistory={true}
              />
            )}
          </Col>

          {/*  Center */}
          <Col
            md={24}
            xl={24}
            xxl={24}
            id="sidebar"
            className="content-center "
          >
            {/* Search bar */}

            {/* Top trending */}
            <TopTrending
              list={this.props.list}
            />

            {/* Latest itineraries */}
            <LatestItineraries
              list={this.props.listLatest}
            />

            <RecentItinerary/>

            <TopLists data={this.state.listTopLists} />

            {/* Top destination */}
            <TopDestination
              listItineraries={this.state.topDestinations}
              list_optionals={this.state.list_optionals}
            />

            {/* Top contributors */}
            {/* <TopContributors
              topContributors={this.state.topContributors}
            /> */}
          </Col>

          {/*  Right */}
          <Col
            md={24}
            xl={6}
            xxl={5}
            className={"content-right hide " + (!isBrowser ? "hide" : null)}
          >
            {/* my plans */}
            {this.props.profile ? (
              !!this.state.list_my_plans.length ? (
                <MyPlans
                  list={this.state.list_my_plans}
                  list_optionals={this.state.list_optionals}
                  title={`My Plans (${this.state.list_my_plans.length})`}
                  setNewListMyPlans={this.setNewListMyPlans}
                />
              ) : isBrowser ? (
                <div>
                  <img
                    style={{ width: "100%", marginTop: "-30px" }}
                    alt="helper-img"
                    src={HelperCreateTyneri}
                  />
                </div>
              ) : null
            ) : isBrowser ? (
              <div>
                <img
                  style={{ width: "100%", marginTop: "-30px" }}
                  src={HelperCreateTyneri}
                  alt="helper-img"
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

export default connect(mapStateToProps, null)(ContentTrendy);
