import React, { useState, useEffect } from "react";
import { Form, AutoComplete } from "antd";
import ContactApi from "../../../../../../services/contactApi.service";

const { Option } = AutoComplete;

function CustomerForm(props) {
  const errors = props.errors;
  const register = props.register;
  const Controller = props.Controller;
  const control = props.control;
  const defaultData = props.defaultData;
  const setValue = props.setValue;
  const contactApi = ContactApi();
  const [contacts, setContacts] = useState([]);
  const [data, setData] = useState({});

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 18 },
  };

  useEffect(() => {
    if (defaultData) setData(defaultData);
  }, [defaultData]);

  async function searchByName(name) {
    let dataTemp = {...data};
    dataTemp.name = name;
    setData(dataTemp)
    if (name) {
      const response = await contactApi.getContactByName(name);
      if (!response.error) setContacts(response.data);
    }
  }

  function onChangeContact(name, option) {
    const optionSelected = contacts.filter(
      (contact) => contact._id === option.key
    )[0];

    if (optionSelected) {
      const newData = { ...data };
      newData.name = optionSelected.firstName + " " + optionSelected.lastName;
      newData.email = optionSelected.email;
      newData.phone = optionSelected.phone;
      setData(newData);
      setValue("name", newData.name);
    }
  }

  function renderContactOption(contact) {
    return (
      <Option
        key={contact._id}
        value={contact._id}
        text={contact.firstName + " " + contact.lastName}
      >
        {contact.firstName + " " + contact.lastName} <br />
        {contact.email ? "Email: " + contact.email : null} <br />
        {contact.phone ? "Phone: " + contact.phone : null} <br />
      </Option>
    );
  }
  return (
    <>
      <Form.Item
        label="Customer name"
        className={errors.name && " has-error "}
        {...layout}
      >
        <Controller
          control={control}
          name="name"
          defaultValue={data.name && data.name}
          render={({value, onChange}) => {
            return (
              <>
                <AutoComplete
                  value={[data.name && data.name]}
                  onSearch={searchByName}
                  dataSource={contacts.map(renderContactOption)}
                  optionLabelProp="text"
                  onSelect={onChangeContact}
                  onChange={(event) => onChange(event)}
                >
                  <input
                    className="ant-input"
                    defaultValue={data.name && data.name}
                  />
                </AutoComplete>
              </>
            );
          }}
        />
        {errors.name && (
          <div className="ant-form-explain">Please input name </div>
        )}
      </Form.Item>
      <Form.Item
        label="Email address"
        className={errors.email && " has-error "}
        {...layout}
      >
        <input
          name="email"
          className="ant-input "
          defaultValue={data.email && data.email}
          ref={register({
            required: "Please input email",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address",
            },
          })}
        />
        {errors.email && (
          <div className="ant-form-explain">{errors.email.message} </div>
        )}
      </Form.Item>
      <Form.Item
        label="Phone number"
        className={errors.phone && " has-error "}
        {...layout}
      >
        <input
          type="number"
          name="phone"
          className="ant-input "
          defaultValue={data.phone && data.phone}
          ref={register({ required: true })}
        />
        {errors.phone && (
          <div className="ant-form-explain">Please input phone</div>
        )}
      </Form.Item>
    </>
  );
}

export default CustomerForm;
