import React, { Component } from "react";
import {
  ContentTrendy
} from "../../components";
import ApiService from "../../services/api.service";
import { connect } from "react-redux";
import {
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout
} from "../../store/actions";
import styles from "./index.scss";

const mapDispatchToProps = dispatch => {
  return {
    saveCurrentItinerary: itinerary => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: amount => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: info => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    }
  };
};

class TrendyItinerary extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: true,
      redirect: false,
      list: [],
      listLatest: []
    };
  }

  componentDidMount = async () => {
    document.title = "Tyneri";
    await this.apiService.getTrending().then(res => {
      if (res.status === "success") {
        this.setState(
          {
            list: res.list,
          }
        );
      }
    });

    await this.apiService.getLatest().then(res => {
      if (res.status === "success") {
        this.setState(
          {
            isLoading: false,
            listLatest: res.list,
          }
        );
      }
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { isLoading } = this.state;
    return !isLoading ?
        <ContentTrendy list={this.state.list} listLatest={this.state.listLatest} />
    :
    <div className={styles.itineraryDetail}></div>

  }
}

export default connect(null, mapDispatchToProps)(TrendyItinerary);
