import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { AppHeader,  Breadcrumb } from "../../components";
import style from "./index.scss";
import { Input } from "antd";

const { Search } = Input;

class AllTyneris extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destination: "",
      name: "",
      dates: new Date(),
      transportation: "Car",
      isSubmit: false,
      isLoading: false
    };
  }

  componentDidMount = () => {};

  render() {
    return (
      <div className={style.allTyneris}>
        <AppHeader />
        <Row
          style={{
            marginRight: `unset`,
            marginTop: `24px`
          }}
        >
          <Col
            sm="12"
            md="12"
            lg="2"
            style={{ minHeight: `calc(100vh - 150px)` }}
          >
            <div
              className="rc-menu-submenu rc-menu-submenu-popup rc-menu-submenu-placement-bottomLeft "
              style={{ position: "unset", height: "100%" }}
            >
              <ul
                id="4$Menu"
                className="rc-menu rc-menu-sub rc-menu-vertical"
                role="menu"
                style={{ height: "100%" }}
              >
                <li className=" rc-menu-item-group">
                  <div className="rc-menu-item-group-title">
                    <strong>
                      <span>Top Destinations</span>
                    </strong>
                  </div>
                  <ul className="rc-menu-item-group-list">
                    <li
                      className="rc-menu-submenu rc-menu-submenu-vertical _1EtdpCruG3A-BBoE4eCOTw"
                      role="menuitem"
                    >
                      <div
                        className="rc-menu-submenu-title"
                        aria-expanded="false"
                        aria-haspopup="true"
                      >
                        <div className="MuiListItem-root MuiListItem-gutters">
                          <div className="MuiListItemAvatar-root">
                            <div className="MuiAvatar-root MuiAvatar-circle flag-image">
                              <img
                                alt="United States"
                                src="https://restcountries.eu/data/usa.svg"
                                className="MuiAvatar-img"
                              />
                            </div>
                          </div>
                          <div className="MuiListItemText-root">
                            <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1">
                              United States
                            </span>
                          </div>
                          <i className="menu-arrow" />
                        </div>
                        <i className="rc-menu-submenu-arrow" />
                      </div>
                    </li>
                    <li
                      className="rc-menu-submenu rc-menu-submenu-vertical _1EtdpCruG3A-BBoE4eCOTw"
                      role="menuitem"
                    >
                      <div
                        className="rc-menu-submenu-title"
                        aria-expanded="false"
                        aria-haspopup="true"
                      >
                        <div className="MuiListItem-root MuiListItem-gutters">
                          <div className="MuiListItemAvatar-root">
                            <div className="MuiAvatar-root MuiAvatar-circle flag-image">
                              <img
                                alt="Việt Nam"
                                src="https://restcountries.eu/data/vnm.svg"
                                className="MuiAvatar-img"
                              />
                            </div>
                          </div>
                          <div className="MuiListItemText-root">
                            <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1">
                              Việt Nam
                            </span>
                          </div>
                          <i className="menu-arrow" />
                        </div>
                        <i className="rc-menu-submenu-arrow" />
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm="12" md="12" lg="10">
            <Container fluid={true}>
              <Row>
                <Breadcrumb page="all-tyneris" />
              </Row>
              <Row style={{marginTop: '10px'}}>
                <Search
                  placeholder="Search itinerary in Việt nam"
                  enterButton="Search"
                  size="large"
                />
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AllTyneris;
