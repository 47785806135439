import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { MdCheck } from "react-icons/md";
import { format, differenceInCalendarDays } from "date-fns";
import styles from "./index.scss";
import "sweetalert2/src/sweetalert2.scss";
import ApiService from "../../services/api.service";
import {
  dateToTimestamp,
  searchTree,
  updateTree,
  calcTotalAmount,
  getAllLeaf,
  getParentIndex,
  changeDateUpInMiddle,
  changeDateDownInMiddle,
  setNewStartEndDate
} from "../../services/utils.service";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { loadItem, saveItem } from "../../services/storage.service";
import { updateTotalAmount } from "../../store/actions";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import { configBaseUrl } from "../../config";
import ClearIcon from "@material-ui/icons/Clear";
import { Grid, IconButton } from "@material-ui/core";
import { Input, Form } from "antd";
import { NumericInput } from "../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { AccountType } from "../../params/AccountType"
import {
  redirectToBillingAfterLogin
} from "../../store/actions";
import $ from "jquery";

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    currency: state.itineraryReducer.currency,
    profile: state.profileReducer.info,
    currency_infor: state.itineraryReducer.currency_infor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    redirectToBillingAfterLogin: (flag) => {
      dispatch(redirectToBillingAfterLogin(flag));
    },
  };
};

class EditDayTitleChangeDate extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    const now = new Date();
    this.state = {
      isLoading: true,
      isSubmit: false,

      isFirstOrLast: false,

      date: now,
      monthDayYear: "",
      title: "",
      description: "",
      day: "",
      monthYear: "",
      isOpen: false,
      openOption: false,

      includedLodging: 0,
      titleLodging: "",
      addressLodging: "",
      amountLodging: "",

      includedMeals: 0,
      amountBreakfast: "",
      amountLunch: "",
      amountDinner: "",

      includedGuide: 0,
      amountGuide: "",

      stay_in: "",
      meal: [],
      file_list_trix_delete: [],
      showText: "Show more",
      minDate: "",
      maxDate: "",
    };
  }

  handleChange = (name) => (event) => {
    if (
      name === "amountLodging" ||
      name === "amountBreakfast" ||
      name === "amountLunch" ||
      name === "amountDinner" ||
      name === "amountGuide"
    ) {
      this.setState({
        [name]: event,
      });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleChangeIncluded = (name) => (event) => {
    this.setState({
      [name]: parseInt(event.target.value),
    });
  };

  validateLodgingAndMeal = () => {
    if (!this.props.profile || (this.props.profile && this.props.profile.accountType < 2))
        return true
    if (
      (this.state.includedLodging &&
        (!this.state.titleLodging.trim() ||
          !this.state.addressLodging.trim())) ||
      (this.state.includedMeals && !this.validateMeals(this.state.meal))
    )
      return false;
    return true;
  };

  validateMeals = (meal) => {
    for(let i = 0; i < meal.length; i++) {
      if(meal[i])
        return true
    }
    return false
  }
  
  handleSaveChange = async (option) => {
    if (option === 3) {
      await this.props.hideEditDayTitleChangeDate(2);
    } else {
      var temp;
      if (this.state.monthDayYear) {
        temp = dateToTimestamp(this.state.monthDayYear);
        temp = temp.toString();
      } else temp = null;
      this.setState({
        isSubmit: true,
      });
      if (!this.validateLodgingAndMeal()) return;
      var newItineraryDetail = {
        title: this.state.title,
        month_day_year: temp,
        description: this.state.description,
        file_list_trix_delete: this.state.file_list_trix_delete,
        stay_in: this.state.stay_in,
        meal: this.state.meal,
      };
      var itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      // var index = itineraries[current].itinerary_detail.findIndex((item) => {
      //   return item._id === this.props.params.idItineraryDetail;
      // });
      let tempItem = searchTree(
        itineraries[current].itinerary_detail,
        this.props.params.idItineraryDetail
      )[0];

      if (temp) {
        var isNull = false;
        var numberDaysAddMiddle;
        if (!tempItem.month_day_year) isNull = true;
        if (!isNull) {
          numberDaysAddMiddle = differenceInCalendarDays(
            this.state.monthDayYear,
            new Date(tempItem.month_day_year * 1000)
          );
        }
      }
      
      tempItem.title = newItineraryDetail.title;
      tempItem.month_day_year = newItineraryDetail.month_day_year;
      tempItem.description = newItineraryDetail.description;
      tempItem.file_list_trix_delete = newItineraryDetail.file_list_trix_delete;
      tempItem.stay_in = newItineraryDetail.stay_in;
      tempItem.meal = newItineraryDetail.meal;
      tempItem.lodging = {
        included: this.state.includedLodging,
        title: this.state.titleLodging,
        address: this.state.addressLodging,
        amount: this.state.amountLodging,
      };

      tempItem.amountMeals = {
        breakfast: this.state.amountBreakfast,
        lunch: this.state.amountLunch,
        dinner: this.state.amountDinner,
        included: this.state.includedMeals,
      };

      tempItem.guide = {
        included: this.state.includedGuide,
        amount: this.state.amountGuide,
      };

      if (temp) {
        var isFirstDate = getAllLeaf(itineraries[current].itinerary_detail).findIndex((item) => {
          return item._id === this.props.params.idItineraryDetail;
        });;

        if ((isFirstDate === 0 || isFirstDate === getAllLeaf(itineraries[current].itinerary_detail).length - 1) && !isNull) {
          if (numberDaysAddMiddle > 0)
            itineraries[current].itinerary_detail = await changeDateUpInMiddle(numberDaysAddMiddle, itineraries[current].itinerary_detail, this.props.params.idItineraryDetail)
          else 
            itineraries[current].itinerary_detail = await changeDateDownInMiddle(numberDaysAddMiddle, itineraries[current].itinerary_detail, this.props.params.idItineraryDetail)
        } else if (isFirstDate !== 0 && !isNull) {
          if (numberDaysAddMiddle > 0)
            itineraries[current].itinerary_detail = await changeDateUpInMiddle(numberDaysAddMiddle, itineraries[current].itinerary_detail, this.props.params.idItineraryDetail)
        }
      }
      
      // if (option === 2) {
      //   var numberDaysAdd = differenceInCalendarDays(
      //     this.state.monthDayYear,
      //     new Date(
      //       getAllLeaf(itineraries[current].itinerary_detail)[0].month_day_year * 1000
      //     )
      //   );
      //   itineraries[current].itinerary_detail.forEach(country => {
      //     country.children.forEach(city => {
      //       city.children.forEach(eachDate => {
      //         eachDate.month_day_year = dateToTimestamp(
      //           addDays(
      //             new Date(
      //               eachDate.month_day_year * 1000
      //             ),
      //             numberDaysAdd
      //           )
      //         ).toString();
      //       })
      //     })
      //   })
      //   // for (
      //   //   let i = index + 1;
      //   //   i < itineraries[current].itinerary_detail.length;
      //   //   i++
      //   // ) {
      //   //   itineraries[current].itinerary_detail[
      //   //     i
      //     // ].month_day_year = dateToTimestamp(
      //     //   addDays(
      //     //     new Date(
      //     //       itineraries[current].itinerary_detail[i].month_day_year * 1000
      //     //     ),
      //     //     numberDaysAdd
      //     //   )
      //     // ).toString();
      //   // }
      // }

      // if (option === 1) {
      //   var result = itineraries[current].itinerary_detail;
      //   result.sort((data_A, data_B) => {
      //     return compareAsc(
      //       new Date(data_A.month_day_year * 1000),
      //       new Date(data_B.month_day_year * 1000)
      //     );
      //   });
      //   itineraries[current].itinerary_detail = result;
      // }
      // itineraries[current].dates =
      //   itineraries[current].itinerary_detail[0].month_day_year;
      // itineraries[current].end_dates =
      //   itineraries[current].itinerary_detail[
      //     itineraries[current].itinerary_detail.length - 1
      //   ].month_day_year;

      let updated = updateTree(itineraries[current].itinerary_detail, tempItem);
      itineraries[current].itinerary_detail = updated;
      itineraries[current] = setNewStartEndDate(itineraries[current]);
      saveItem("itineraries", itineraries);
      let total_amount = calcTotalAmount(this.props.params.idItinerary);
      this.props.updateTotalAmount(total_amount);
      await this.props.hideEditDayTitleChangeDate(newItineraryDetail);
      if (this.props.handleCloseFormEditDate) {
        this.props.handleCloseFormEditDate();
      }
    }
  };

  handleDeleteDay = async () => {
    // await this.apiService.deleteItineraryDetail(this.props.params.idItinerary, this.props.params.idItineraryDetail)
    var itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });
    var index = itineraries[current].itinerary_detail.findIndex((item) => {
      return item._id === this.props.params.idItineraryDetail;
    });
    itineraries[current].itinerary_detail.splice(index, 1);
    saveItem("itineraries", itineraries);
    let total_amount = calcTotalAmount(this.props.params.idItinerary);
    this.props.updateTotalAmount(total_amount);
    await this.props.hideEditDayTitleChangeDate(1);
  };

  componentDidMount = () => {
    var itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });

    let tempItem = searchTree(
      itineraries[current].itinerary_detail,
      this.props.params.idItineraryDetail
    )[0];

    if (tempItem.lodging) {
      this.setState({
        titleLodging: tempItem.lodging.title,
        addressLodging: tempItem.lodging.address,
        amountLodging: tempItem.lodging.amount,
        includedLodging: tempItem.lodging.included,
      });
    }

    if (tempItem.amountMeals) {
      this.setState({
        amountBreakfast: tempItem.amountMeals.breakfast,
        amountLunch: tempItem.amountMeals.lunch,
        amountDinner: tempItem.amountMeals.dinner,
        includedMeals: tempItem.amountMeals.included,
      });
    }

    if (tempItem.guide) {
      this.setState({
        amountGuide: tempItem.guide.amount,
        includedGuide: tempItem.guide.included,
      });
    }

    var index = getAllLeaf(itineraries[current].itinerary_detail).findIndex(
      (item) => {
        return item._id === this.props.params.idItineraryDetail;
      }
    );
    if (index === 0 || (index === getAllLeaf(itineraries[current].itinerary_detail).length - 1))
      this.setState({
        isFirstOrLast: true,
      });

    this.setState(
      {
        isLoading: false,
        title: this.props.title,
        description: tempItem.description,
        day: this.props.day,
        monthYear: this.props.monthYear,
        monthDayYear: this.props.monthDayYear,
        stay_in: tempItem.stay_in,
        meal: tempItem.meal ? tempItem.meal : [],
        minDate: new Date(itineraries[current].dates * 1000),
        maxDate: new Date(itineraries[current].end_dates * 1000),
      },
      () => {
        document.addEventListener("trix-attachment-add", this.func);
        document.addEventListener(
          "trix-attachment-remove",
          this.trixRemoveAttachmentEvent
        );

        document.addEventListener("trix-initialize", this.trixEmbedVideo);

        var element = document.querySelector("trix-editor");
        element.editor.insertHTML(
          tempItem.description ? tempItem.description : ""
        );
        element.blur();

        document.addEventListener("trix-change", (event) => {
          var element = event.target;
          var html = element.value;
          this.setState({
            description: html,
          });
        });
      }
    );
  };

  func = (event) => {
    if (event.attachment.file) {
      //this.uploadFileAttachment(event.attachment);
      const formData = new FormData();
      formData.append("description_itinerary_detail", event.attachment.file);
      var percentCompleted;
      this.apiService
        .uploadImagesDescriptionItineraryDetail(formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((res) => {
          if (res.status === "success" && percentCompleted === 100) {
            event.attachment.setUploadProgress(100);
            var attributes = {
              url: configBaseUrl.baseURL + res.path,
              href: configBaseUrl.baseURL + res.path,
            };
            event.attachment.setAttributes(attributes);
          }
        });
    }
  };

  trixRemoveAttachmentEvent = (event) => {
    const attributes = event.attachment.getAttributes();
    if (attributes.href) {
      var nameFileDelete = attributes.href.split("/");
      nameFileDelete = nameFileDelete[nameFileDelete.length - 1];
      this.setState({
        file_list_trix_delete: [
          ...this.state.file_list_trix_delete,
          nameFileDelete,
        ],
      });
    }
  };

  trixEmbedVideo = (event) => {
    let editor = event.target;
    let toolbar = editor.toolbarElement;
    let ttools = toolbar.querySelector(".trix-button-group--text-tools");
    let dialogs = toolbar.querySelector(".trix-dialogs");
    let trixId = editor.trixId;

    let buttonContent = `
      <button type="button"
        class="trix-button trix-button--icon trix-button--icon-video"
        data-trix-attribute="video"
        data-trix-key="+" title="Embed video" tabindex="-1">
      </button>
    `;

    let dialogContent = `
      <div class="trix-dialog trix-dialog--video" data-trix-dialog="video" data-trix-dialog-attribute="video">
        <div class="trix-dialog__attach-fields">
          <input data-type="url-video" class="trix-input trix-input--dialog" placeholder="Insert url youtube video here">
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog embed-video"
              onclick="
                var trix = document.querySelector('trix-editor[trix-id=\\'${trixId}\\']');
                var videoEmbed = this.parentElement.parentElement.querySelector('input[data-type=\\'url-video\\']');
              
                var url = videoEmbed.value
                var video_id = url.split('v=')[1];
                var ampersandPosition = video_id.indexOf('&');
                if(ampersandPosition != -1) {
                  video_id = video_id.substring(0, ampersandPosition);
                }

                const iframeMarkup = '<iframe width=\\'560\\' height=\\'315\\' src=\\'https://www.youtube.com/embed/'+video_id+'\\' frameborder=\\'0\\' allowfullscreen></iframe>';
                var attachment = new Trix.Attachment({content: iframeMarkup})
                trix.editor.insertAttachment(attachment)
              "
              value="Embed video" data-trix-method="removeAttribute">
            <input type="button" class="trix-button trix-button--dialog" value="Cancel" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
    `;
    // add attach icon button
    ttools.insertAdjacentHTML("beforeend", buttonContent);
    // add dialog
    dialogs.insertAdjacentHTML("beforeend", dialogContent);
  };

  componentWillUnmount = () => {
    document.removeEventListener("trix-initialize", this.trixEmbedVideo);
    document.removeEventListener("trix-attachment-add", this.func);
    document.removeEventListener(
      "trix-attachment-remove",
      this.trixRemoveAttachmentEvent
    );
  };

  handleDateChange = (date) => {
    var itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });
    // var index = getAllLeaf(itineraries[current].itinerary_detail).findIndex((item) => {
    //   return item._id === this.props.params.idItineraryDetail;
    // });;
    // if (index === 0)
    //   this.setState({
    //     openOption: true,
    //   });
    if (date) {
      this.setState({
        monthDayYear: date,
        day: format(date, "dd"),
        monthYear: format(date, "MMM, yyyy"),
        isOpen: false,
      });
    } else {
      this.setState({
        monthDayYear: "",
        day: "",
        monthYear: "",
        isOpen: false,
      });
    }
  };

  handleChangeStayIn = (e) => {
    this.setState({
      stay_in: e.target.value,
    });
  };

  handleChangeMeal = (name) => (e) => {
    if (!e.target.checked) {
      this.setState({
        meal: this.state.meal.filter((item) => {
          return item !== name;
        }),
      }, () => {
        if (name === "breakfast") {
          this.setState({
            amountBreakfast: ""
          })
        }
        if (name === "lunch") {
          this.setState({
            amountLunch: ""
          })
        }
        if (name === "dinner") {
          this.setState({
            amountDinner: ""
          })
        }
      });
    } else {
      var temp = [...this.state.meal];
      if (name === "breakfast") {
        temp[0] = name;
      }
      if (name === "lunch") {
        temp[1] = name;
      }
      if (name === "dinner") {
        temp[2] = name;
      }
      this.setState({
        meal: temp,
      });
    }
  }

  checkDisable = () => {
    if (
      !this.props.profile ||
      (this.props.profile && this.props.profile.accountType < 2)
    ) {
      return true;
    }
    return false;
  };

  disableDatesExistInCity = (date) => {
    var itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });

    let index = getParentIndex(this.props.params.idItineraryDetail, itineraries[current].itinerary_detail);
    let arrDatesExist = itineraries[current].itinerary_detail[index.indexCountry].children[index.indexCity].children.filter(item => {
      return item._id !== this.props.params.idItineraryDetail
    })
    let result = arrDatesExist.findIndex(item => {
      return format(date, "dd/MM/yyyy") === format(new Date(item.month_day_year * 1000), "dd/MM/yyyy")
    })
    return result >= 0 ? true : false
  }

  handleUpgrateSubscript = () => {
    this.props.redirectToBillingAfterLogin(1)
  }

  render() {
    var checkBreakfast = false;
    var checkLunch = false;
    var checkDinner = false;

    this.state.meal.map((item) => {
      if (item === "breakfast") checkBreakfast = true;
      if (item === "lunch") checkLunch = true;
      if (item === "dinner") checkDinner = true;
    });

    return this.state.isLoading ? (
      <DialogContent style={this.state.isLoading && { minHeight: "300px" }}>
        <div className={styles.loader}></div>
      </DialogContent>
    ) : (
      <>
        <DialogContent dividers className={styles.editDayTitleChangeDate}>
          <Row>
            <Dialog
              open={this.state.openOption ? true : false}
              aria-labelledby="form-option"
              maxWidth={"xs"}
              fullWidth={true}
              scroll={"body"}
            >
              <DialogTitle id="form-option">
                Looks like you're trying to change the first day of your Tyneri
              </DialogTitle>
              <DialogContent className={styles.editDayTitleChangeDate}>
                <ButtonGroup
                  fullWidth
                  aria-label="full width outlined button group"
                >
                  <Button
                    variant="contained"
                    onClick={this.handleSaveChange.bind(this, 1)}
                  >
                    Change Only this Date
                  </Button>
                </ButtonGroup>
                <ButtonGroup
                  fullWidth
                  aria-label="full width outlined button group"
                >
                  <Button
                    variant="contained"
                    onClick={this.handleSaveChange.bind(this, 2)}
                  >
                    Move Entire Tyneri to This Start Date
                  </Button>
                </ButtonGroup>
                <ButtonGroup
                  fullWidth
                  aria-label="full width outlined button group"
                >
                  <Button
                    variant="contained"
                    onClick={this.handleSaveChange.bind(this, 3)}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </DialogContent>
            </Dialog>
            <Col xs="12" md="6" lg="7">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container alignItems="flex-end">
                  <DatePicker
                    open={this.state.isOpen}
                    onOpen={() => this.setState({ isOpen: true })}
                    onClose={() => this.setState({ isOpen: false })}
                    variant="inline"
                    label="Date"
                    format="EEE, MMM d yyyy"
                    value={
                      this.state.monthDayYear ? this.state.monthDayYear : null
                    }
                    shouldDisableDate={(date) => {
                      return this.disableDatesExistInCity(date);
                    }}
                    onChange={this.handleDateChange}
                    className="date-picker"
                    minDate={
                      this.state.isFirstOrLast ? undefined : this.state.minDate
                    }
                    maxDate={
                      this.state.isFirstOrLast ? undefined : this.state.maxDate
                    }
                  />
                  {!this.state.isFirstOrLast ? (
                    <IconButton
                      edge="end"
                      size="small"
                      disabled={!this.state.monthDayYear}
                      onClick={() => this.handleDateChange("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : null}
                </Grid>
              </MuiPickersUtilsProvider>
              <Input
                style={{ marginTop: `10px` }}
                onChange={this.handleChange("title")}
                value={this.state.title}
                placeholder="Title"
              />
              <div style={{ marginTop: "1rem" }}>
                <input type="hidden" id="trix" />
                <trix-editor class="trix-content" input="trix" />
              </div>
            </Col>
            <Col xs="12" md="6" lg="5">
              <div className="advance-infor">
                {(!this.props.profile ||
                  (this.props.profile &&
                    this.props.profile.accountType < 2)) && (
                  <div className="block">
                    <div>
                      Feature unavailable under your current plan. <span className="what-this" onClick={this.handleUpgrateSubscript} >Upgrade your subscription to use feature</span>
                    </div>
                  </div>
                )}
                <div className="advance-infor__header">
                  Lodging
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={this.state.includedLodging}
                      onChange={this.handleChangeIncluded("includedLodging")}
                      row
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label="Included"
                        disabled={this.checkDisable()}
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio color="primary" />}
                        label="Not included"
                        disabled={this.checkDisable()}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {this.state.includedLodging ? (
                  <>
                    <Form.Item
                      required
                      validateStatus={
                        this.state.isSubmit &&
                        this.state.includedLodging &&
                        !this.state.titleLodging.trim() &&
                        this.props.profile &&
                        this.props.profile.accountType === AccountType.Pro
                          ? "error"
                          : ""
                      }
                      help={
                        this.state.isSubmit &&
                        this.state.includedLodging &&
                        !this.state.titleLodging.trim() &&
                        this.props.profile &&
                        this.props.profile.accountType === AccountType.Pro
                          ? "Title is required"
                          : ""
                      }
                    >
                      <Input
                        disabled={this.checkDisable()}
                        onChange={this.handleChange("titleLodging")}
                        value={this.state.titleLodging}
                        placeholder="Title"
                      />
                    </Form.Item>
                    <Form.Item
                      required
                      validateStatus={
                        this.state.isSubmit &&
                        this.state.includedLodging &&
                        !this.state.addressLodging.trim() &&
                        this.props.profile &&
                        this.props.profile.accountType === AccountType.Pro
                          ? "error"
                          : ""
                      }
                      help={
                        this.state.isSubmit &&
                        this.state.includedLodging &&
                        !this.state.addressLodging.trim() &&
                        this.props.profile &&
                        this.props.profile.accountType === AccountType.Pro
                          ? "Address is required"
                          : ""
                      }
                    >
                      <Input
                        disabled={this.checkDisable()}
                        onChange={this.handleChange("addressLodging")}
                        value={this.state.addressLodging}
                        placeholder="Address"
                      />
                    </Form.Item>

                    <Form.Item>
                      <div className="input-amount">
                        <NumericInput
                          disabled={this.checkDisable()}
                          placeholder="Total fees"
                          addonBefore={`${this.props.currency_infor.code} (${this.props.currency_infor.symbol})`}
                          value={this.state.amountLodging}
                          onChange={this.handleChange("amountLodging")}
                        />
                      </div>
                    </Form.Item>
                  </>
                ) : null}

                <div className="advance-infor__header">
                  Meals
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={this.state.includedMeals}
                      onChange={this.handleChangeIncluded("includedMeals")}
                      row
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label="Included"
                        disabled={this.checkDisable()}
                      />
                      <FormControlLabel
                        value={0}
                        disabled={this.checkDisable()}
                        control={<Radio color="primary" />}
                        label="Not included"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {this.state.includedMeals ? (
                  <FormControl component="fieldset">
                    <FormGroup>
                      <Row>
                        <Col xs="4">
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={this.checkDisable()}
                                checked={checkBreakfast}
                                color="primary"
                                onChange={this.handleChangeMeal("breakfast")}
                                value="breakfast"
                              />
                            }
                            label="Breakfast"
                          />
                        </Col>
                        <Col xs="8">
                          <NumericInput
                            disabled={this.checkDisable() || !checkBreakfast}
                            placeholder="Total fees"
                            addonBefore={`${this.props.currency_infor.code} (${this.props.currency_infor.symbol})`}
                            value={this.state.amountBreakfast}
                            onChange={this.handleChange("amountBreakfast")}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="4">
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={this.checkDisable()}
                                checked={checkLunch}
                                color="primary"
                                onChange={this.handleChangeMeal("lunch")}
                                value="lunch"
                              />
                            }
                            label="Lunch"
                          />
                        </Col>
                        <Col xs="8">
                          <NumericInput
                            disabled={this.checkDisable() || !checkLunch}
                            placeholder="Total fees"
                            addonBefore={`${this.props.currency_infor.code} (${this.props.currency_infor.symbol})`}
                            value={this.state.amountLunch}
                            onChange={this.handleChange("amountLunch")}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="4">
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={this.checkDisable()}
                                checked={checkDinner}
                                color="primary"
                                onChange={this.handleChangeMeal("dinner")}
                                value="dinner"
                              />
                            }
                            label="Dinner"
                          />
                        </Col>
                        <Col xs="8">
                          <NumericInput
                            disabled={this.checkDisable() || !checkDinner}
                            placeholder="Total fees"
                            addonBefore={`${this.props.currency_infor.code} (${this.props.currency_infor.symbol})`}
                            value={this.state.amountDinner}
                            onChange={this.handleChange("amountDinner")}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    {this.state.isSubmit &&
                      !this.validateMeals(this.state.meal) &&
                      this.props.profile &&
                      this.props.profile.accountType === AccountType.Pro && (
                        <div className="required-meal">
                          Need to select at least one
                        </div>
                      )}
                  </FormControl>
                ) : null}

                <div className="advance-infor__header">
                  Guide
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={this.state.includedGuide}
                      onChange={this.handleChangeIncluded("includedGuide")}
                      row
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label="Included"
                        disabled={this.checkDisable()}
                      />
                      <FormControlLabel
                        value={0}
                        disabled={this.checkDisable()}
                        control={<Radio color="primary" />}
                        label="Not included"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {this.state.includedGuide ? (
                  <NumericInput
                    disabled={this.checkDisable()}
                    placeholder="Total fees"
                    addonBefore={`${this.props.currency_infor.code} (${this.props.currency_infor.symbol})`}
                    value={this.state.amountGuide}
                    onChange={this.handleChange("amountGuide")}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions className={styles.editDayTitleChangeDate}>
          <Button
            className="btn-green"
            type="button"
            onClick={this.handleSaveChange.bind(this, 1)}
          >
            <MdCheck />
            &nbsp;Done
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDayTitleChangeDate);
