import React, { useEffect, useState } from "react";
import { Row, Col, Card, Icon, Skeleton, Empty } from "antd";

import dashboardApi from "../../../../services/dashboardApi.service";
import "./index.scss";
import TableCustomer from "./tableReport/tableCustomer";
import TableQuotation from "./tableReport/tableQuotation";

const CardReport = ({ color, icon, name, number, loading, link }) => {
  if (loading)
    return (
      <Skeleton
        loading={loading}
        paragraph={{ rows: 1 }}
        width="100%"
        shape="square"
        title={false}
        active
        className=" loading-card"
      ></Skeleton>
    );

  return (
    <Card className="card-dashboard" style={{ background: color }}>
      <Row>
        <Col xs={12} md={12} xl={12}>
          <div className="static-content">
            <span className="name">{name}</span>
            <a href={link} target="blank">
              <span className="number">{number}</span>
            </a>
          </div>
        </Col>
        <Col xs={12} md={12} xl={12}>
          <div className="icon">{icon}</div>
        </Col>
      </Row>
    </Card>
  );
};

function DashBoard(props) {
  const DashboardApi = dashboardApi();
  const [myItinerary, setMyItinerary] = useState(0);
  const [myInquiry, setMyInquiry] = useState(0);
  const [myQuotation, setMyQuotation] = useState(0);
  const [myList, setMyList] = useState(0);
  const [loading, setLoading] = useState(true);
  const [myCustomer, setMyCustomer] = useState([]);
  const [myCustomerQuotation, setMyCustomerQuotation] = useState([]);

  useEffect(() => {
    DashboardApi.getDashboardReport().then(async (res) => {
      if (!res.error) {
        await setMyItinerary(res.myItinerary);
        await setMyInquiry(res.myInquiry);
        await setMyQuotation(res.myQuotation);
        await setMyList(res.myList);
        await setMyCustomer(res.myCustomer);
        await setMyCustomerQuotation(res.myCustomerQuotation);
      }

      setLoading(false);
    });
  }, []);

  return (
    <div className="dashboard-container">
      <Row className="report">
        <Col xs={24} md={12} xl={6}>
          <CardReport
            color={"linear-gradient(110deg, #f6741c, #f85208)"}
            icon={<Icon type="lock" />}
            name={"My Itinerary"}
            number={myItinerary}
            loading={loading}
            link={"/my-library"}
          />
        </Col>
        <Col xs={24} md={12} xl={6}>
          <CardReport
            color={"linear-gradient(110deg, #058fe7, #047edf)"}
            icon={<Icon type="schedule" />}
            name={"My Lists"}
            number={myList}
            loading={loading}
            link={"/my-library/#3"}
          />
        </Col>
        <Col xs={24} md={12} xl={6}>
          <CardReport
            color={"linear-gradient(110deg, #0bb5aa, #0ea49b)"}
            icon={<Icon type="mail" />}
            name={"Inquiry"}
            number={myInquiry}
            loading={loading}
            link={"/inquiries"}
          />
        </Col>
        <Col xs={24} md={12} xl={6}>
          <CardReport
            color={"linear-gradient(110deg, #6454eb, #352c7b)"}
            icon={<Icon type="tag" />}
            name={"Quotations"}
            number={myQuotation}
            loading={loading}
            link={"/quotations"}
          />
        </Col>
      </Row>
      <Row className="table-static">
        <Col xs={24} md={12} xl={8}>
          <TableCustomer data={myCustomer} reportName="Recent Customers" />
        </Col>
        <Col xs={24} md={12} xl={16}>
          <TableQuotation
            data={myCustomerQuotation}
            reportName="Recent Quotes"
          />
        </Col>
      </Row>
    </div>
  );
}

export default DashBoard;
