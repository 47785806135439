export function getToken() {
  return localStorage.getItem("token");
}

export function saveToken(token) {
  return localStorage.setItem("token", token);
}

export function removeToken() {
  return localStorage.removeItem("token");
}

export function removeKeyAfterLoginOtherAccount() {
  localStorage.removeItem("itineraries");
  localStorage.removeItem("itinerarySeen");
  localStorage.removeItem("itineraryDraft");
  return;
}
