import React, { Component } from "react";
import { Col, Row, List, Typography } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { isMyResource, getImageFromUrl, getNameAuthor } from "../../../services/utils.service";
import defaultImg from "../../../assets/media/images/generic-activities-img.jpg";
import { configBaseUrl } from "../../../config";
import moment from "moment";
import styles from "./index.scss";
import StringHelper from "../../../helper/stringHelper";

const { Title, Text } = Typography;

class listTopLists extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      listItineraries: [],
      topDestinations: [],
      list_optionals: [],
      listDetail: [],
      country: null,

      data: [],
      totalData: 0,
      page: 1,
      paramLimit: 10,
      detailMode: false,
    };
  }

  render() {
    return (
      <div className={styles.listTopLists}>
        <List
          header={<h4 className="font-weight-normal">Recently Lists</h4>}
          itemLayout="vertical"
          style={{marginBottom: '20px', marginTop: 20}}
          size="large"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 10,
          }}
          grid={{gutter: 16, md: 2, lg: 1, xl: 1, xxl: 1, xs: 1, sm: 1}}
          dataSource={this.props.data}
          className="custom-row"
          renderItem={(item) => (
            <List.Item key={item._id}>
              <Row gutter={16}>
                <Col xs={24} lg={6}>
                  <Link to={`/view-top-list/${item._id}`}>
                    <div
                        className="img-fluid"
                        style={{
                          backgroundImage: `url(${
                              item.cover
                                  ? getImageFromUrl(
                                  isMyResource(item.cover)
                                      ? `${configBaseUrl.baseURL + item.cover}`
                                      : `${item.cover}`,
                                  "large"
                                  )
                                  : defaultImg
                          })`,
                          borderRadius: 5
                        }}
                    ></div>
                  </Link>
                </Col>
                <Col xs={24} lg={18}>
                  <Title level={4} className="title-attraction">
                    <Link to={`/view-top-list/${item._id}`}>{item.title}</Link>
                  </Title>
                  <Text>
                    <div className="author">
                      By{" "}
                      {getNameAuthor(item.user, item.company)}{" "}
                      - {moment(new Date(item.created_at)).fromNow()}
                    </div>
                    <div className="content-attraction">
                      {StringHelper().stripeHtml(item.description, 250)}
                    </div>
                  </Text>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

export default withRouter(connect(mapStateToProps, null)(listTopLists));
