import React, { Component } from "react";
import ApiService from "../../services/api.service";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Icon,
  Button,
  Row,
  Col,
  Upload,
  Radio,
  Select,
  Divider,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./index.scss";
import { configBaseUrl } from "../../config";
import ImgCrop from "antd-img-crop";
import { isMobile } from "react-device-detect";
import { getBase64FromAvatar } from "../../services/utils.service";
import { withRouter } from "react-router";
import Alert from "../../module/admin/components/alert";
import { FormSignInUp } from "../../components";
import Dialog from "@material-ui/core/Dialog";
import { maxTitleTopList } from "../../constants/itinerary";
import { EditableText } from "../../components";

const { Option } = Select;

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

let id = 1;
var initialValue = [0];

class ContentCreateTopLists extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      cover: "",
      showFormLogin: false,
      autoSave: false,
      categories: [],
      arrIdDelete: [],

      redirectBilling: false,
      isFocus: false
    };
  }

  componentDidMount = () => {
    this.apiService.getTopListCategories().then((res) => {
      if (res.status === "success")
        this.setState({
          categories: res.categories,
        });
    });

    if (this.props.top_list) {
      id = this.props.top_list.list_places.length;
      this.setState({
        cover: this.props.top_list.cover,
      });
      this.props.form.setFieldsValue({
        cover: this.props.top_list.cover,
      });
      this.props.top_list.list_places.forEach((item, index) => {
        this.props.form.setFieldsValue({
          ["descriptions[" + index + "]"]: item.description,
        });
        this.props.form.setFieldsValue({
          ["imageStop[" + index + "]"]: item.image,
        });
      });
    }
  };

  componentWillUnmount = () => {
    id = 1;
    initialValue = [0];
  };

  remove = (k) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    const idDeleted = form.getFieldValue(`ids[${k}]`);
    let arrIdDelete = [...this.state.arrIdDelete];
    arrIdDelete.push(idDeleted);
    this.setState({
      arrIdDelete: arrIdDelete,
    });

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
      ["imageStop[" + k + "]"]: null,
    });
    // id = keys.length;
  };

  add = () => {
    
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");

    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
    this.setState({
      isFocus: true
    })
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      !this.props.profile &&
      nextProps.profile &&
      nextProps.profile.accountType === 0
    ) {
      this.props.form.setFieldsValue({ show: true });
    }
    if (nextProps.profile && this.state.redirectBilling) {
      if (nextProps.profile.accountType > 0) {
        this.setState({
          redirectBilling: false,
          showFormLogin: false,
        });
        return;
      }
      this.setState(
        {
          redirectBilling: false,
        },
        () => {
          this.props.history.push("/billing");
        }
      );
      return;
    }
    if (nextProps.profile && this.state.autoSave) {
      this.setState(
        {
          autoSave: false,
          showFormLogin: false,
        },
        () => {
          this.handleSubmit();
        }
      );
    }
  };

  handleSubmit = (e) => {
    if (e) e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (!this.props.profile) {
          this.setState({
            showFormLogin: true,
            autoSave: true,
          });
          return;
        }
        window.onbeforeunload = null;
        if (this.props.top_list) {
          values = {
            ...values,
            _id: this.props.top_list._id,
            arrIdDelete: this.state.arrIdDelete,
          };
          this.apiService.updateTopList(values).then((res) => {
            if (res.status === "success") {
              let alert = new Alert();
              alert.success("Updated successfully");
              this.props.history.push(
                `/view-top-list/${this.props.top_list._id}`
              );
            } else {
              let alert = new Alert();
              alert.warning(res.error);
              // this.props.history.push(
              //   `/top-lists`
              // );
            }
          });
        } else {
          this.apiService.createTopList(values).then((res) => {
            this.props.history.push(`/view-top-list/${res._id}`);
          });
        }
      }
    });
  };

  customUploadInDynamic = (k) => ({ onError, onSuccess, file }) => {
    // var percentCompleted;
    const formData = new FormData();
    formData.append("cover", file);
    try {
      this.apiService
        .uploadCover(formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            /*percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );*/
          },
        })
        .then((res) => {
          this.props.form.setFieldsValue({
            ["imageStop[" + k + "]"]: res.path,
          });
          onSuccess(null, res.path);
        });
    } catch (e) {
      onError(e);
    }
  };

  customUpload = async ({ onError, onSuccess, file }) => {
    // var percentCompleted;
    const formData = new FormData();
    formData.append("cover", file);
    try {
      this.apiService
        .uploadCover(formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            /*percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );*/
          },
        })
        .then((res) => {
          this.props.form.setFieldsValue({
            cover: res.path,
          });
          onSuccess(null, res.path);
        });
    } catch (e) {
      onError(e);
    }
  };

  handleChangeCover = (info) => {
    getBase64FromAvatar(info.file.originFileObj, (image) => {
      this.setState({
        cover: info.file.xhr,
        fileCover: info.file.xhr,
        onUpload: true,
      });
    });
  };

  handleChangeImageStop = (info) => {
    getBase64FromAvatar(info.file.originFileObj, (image) => {
      this.setState({
        onUpload: true,
      });
    });
  };

  checkEdit = (key) => {
    if (this.props.top_list.list_places[key]) return true;
    return false;
  };

  closeFormLogin = () => {
    this.setState({
      showFormLogin: false,
    });
  };

  validateLengthTitle = (rule, value, callback) => {
    if (
      value.trim().length > maxTitleTopList ||
      value.length > maxTitleTopList
    ) {
      this.props.form.setFieldsValue({
        title: value.substring(0, maxTitleTopList),
      });
      callback("Length exceed");
    } else {
      callback();
    }
  };

  handleRedirectBilling = () => {
    if (this.props.profile) {
      this.props.history.push("/billing");
    } else {
      this.setState({
        redirectBilling: true,
        showFormLogin: true,
      });
    }
  };

  render() {
    const { cover } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    if (this.props.top_list) {
      initialValue = [];
      this.props.top_list.list_places.forEach((item, index) => {
        initialValue.push(index);
      });
    }

    getFieldDecorator("keys", { initialValue: initialValue });
    const keys = getFieldValue("keys");
    const formItems = keys.map((k, index) => {
      return (
        <Row gutter={[16, 16]} className="ant-form-item" key={index}>
          <Form.Item key={k}>
            {getFieldDecorator(`ids[${k}]`, {
              initialValue: this.props.top_list
                ? this.checkEdit(k)
                  ? this.props.top_list.list_places[k]._id
                  : ""
                : "",
            })(<Input type="hidden" />)}
          </Form.Item>
          <Col xs={6} xl={2} className="number-item">
            {index + 1}
          </Col>
          <Col xs={18} xl={4} className="form-item-label">
            <Form.Item key={k}>
              {getFieldDecorator(`imageStop[${k}]`)(
                <ImgCrop
                  aspect={16 / 9}
                  modalWidth={isMobile ? "100%" : "70%"}
                  grid
                >
                  <Upload
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={this.customUploadInDynamic(k)}
                    onChange={this.handleChangeImageStop}
                  >
                    {this.props.form.getFieldValue("imageStop[" + k + "]") ? (
                      <img
                        src={
                          configBaseUrl.baseURL +
                          this.props.form.getFieldValue("imageStop[" + k + "]")
                        }
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </ImgCrop>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} xl={18} className="ant-form-item-control-wrapper">
            <Row gutter={[0, 8]}>
              <Col>
                <Form.Item key={k}>
                  {getFieldDecorator(`names[${k}]`, {
                    initialValue: this.props.top_list
                      ? this.checkEdit(k)
                        ? this.props.top_list.list_places[k].name
                        : ""
                      : "",
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input item's name",
                      },
                    ],
                  })(
                    <EditableText
                      onChange={(value) => {
                        this.props.form.setFieldsValue({
                          ["names[" + k + "]"]: value,
                        });
                      }}
                      typeInput="Text"
                      placeholder="Item Name"
                      editClassName="ant-input"
                      style={
                        keys.length > 1
                          ? { width: `94%`, marginRight: 8 }
                          : null
                      }
                      add={this.add}
                      isFocus={this.state.isFocus}
                    />
                  )}
                  {keys.length > 1 ? (
                    <i
                      className="far fa-trash-alt dynamic-delete-button"
                      onClick={() => this.remove(k)}
                    ></i>
                  ) : null}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator(`descriptions[${k}]`, {
                    initialValue: this.props.top_list
                      ? this.checkEdit(k)
                        ? this.props.top_list.list_places[k].description
                        : ""
                      : "",
                  })(
                    <EditableText
                      onChange={(value) => {
                        this.props.form.setFieldsValue({
                          ["descriptions[" + k + "]"]: value,
                        });
                      }}
                      initialValue={this.props.form.getFieldValue(
                        `descriptions[${k}]`
                      )}
                      valueText={this.props.form.getFieldValue(
                        `descriptions[${k}]`
                      )}
                      typeInput="Editor"
                      placeholder="Description (optional)"
                      editClassName="ant-input"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    });
    return (
      <>
        <Dialog
          open={this.state.showFormLogin ? true : false}
          onClose={this.closeFormLogin.bind(this)}
          fullWidth={true}
          maxWidth={"xs"}
          aria-labelledby="form-login-social-media content"
        >
          <FormSignInUp closeForm={this.closeFormLogin}/>
        </Dialog>
        <Row className={styles.contentCreateTopList}>
          <Col xs={24} xxl={16}>
            <div className="title-create">Creating your list</div>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <Row gutter={[16, 16]}>
                <Col xl={12}>
                  <Row gutter={[0, 8]}>
                    <Col>
                      <Form.Item
                        // extra={
                        //   <small className="warning-limit">
                        //     limit to{" "}
                        //     <span>
                        //       {this.props.form.getFieldValue("title")
                        //         ? this.props.form.getFieldValue("title").length
                        //         : 0}
                        //       /{maxTitleTopList}
                        //     </span>{" "}
                        //     characters
                        //   </small>
                        // }
                        className="title-list"
                      >
                        {getFieldDecorator(`title`, {
                          initialValue: this.props.top_list
                            ? this.props.top_list.title
                            : "",
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please input list's name",
                            },
                            {
                              validator: this.validateLengthTitle,
                            },
                          ],
                        })(
                          <Input
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            placeholder="Name your list here"
                            className="input-title-top-list"
                            autoFocus
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        {getFieldDecorator("category_id", {
                          initialValue: this.props.top_list
                            ? this.props.top_list.category_id
                            : undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please choose category",
                            },
                          ],
                        })(
                          <Select placeholder="Choose a category">
                            {!!this.state.categories.length &&
                              this.state.categories.map((item, key) => (
                                <Option key={key} value={item._id}>
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        {getFieldDecorator("show", {
                          initialValue: this.props.top_list
                            ? this.props.top_list.show
                            : true,
                        })(
                          <Radio.Group>
                            <Radio value={true}>Public</Radio>
                            <Radio
                              disabled={
                                this.props.profile &&
                                this.props.profile.accountType > 0
                                  ? false
                                  : true
                              }
                              value={false}
                            >
                              Private
                            </Radio>
                            {this.props.profile &&
                            this.props.profile.accountType > 0 ? null : (
                              <span
                                className="what-this"
                                onClick={this.handleRedirectBilling.bind(this)}
                              >
                                Upgrade your subscription to use Private.
                              </span>
                            )}
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[0, 8]}>
                    <Form.Item>
                      {getFieldDecorator(`description`, {
                        initialValue: this.props.top_list
                          ? this.props.top_list.description
                          : "",
                      })(
                        <EditableText
                          onChange={(value) => {
                            this.props.form.setFieldsValue({
                              description: value,
                            });
                          }}
                          valueText={this.props.form.getFieldValue(
                            `description`
                          )}
                          initialValue={this.props.form.getFieldValue(
                            `description`
                          )}
                          typeInput="Editor"
                          placeholder="Click here to add a description or voting guidelines"
                          editClassName="ant-input"
                        />
                      )}
                    </Form.Item>
                  </Row>
                </Col>
                <Col xl={12}>
                  <Form.Item>
                    {getFieldDecorator("cover")(
                      <ImgCrop
                        aspect={16 / 9}
                        modalWidth={isMobile ? "100%" : "70%"}
                        grid
                      >
                        <Upload
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          customRequest={this.customUpload}
                          onChange={this.handleChangeCover}
                        >
                          {cover ? (
                            <img
                              src={configBaseUrl.baseURL + cover}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </ImgCrop>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              {formItems}
              <Row gutter={[16, 16]}>
                <Col xs={6} xl={2}></Col>
                <Col xs={18} xl={4}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={this.add.bind(this)}
                      className="btn-add-more"
                    >
                      <Icon type="plus" /> Add more
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={18}></Col>
              </Row>

              <div className="section-save">
                <Form.Item style={isMobile ? { textAlign: "center" } : null}>
                  <Button
                    style={
                      isMobile
                        ? { fontSize: 25, width: `50%` }
                        : { margin: `15px 0`, float: `right` }
                    }
                    type="primary"
                    htmlType="submit"
                    size={isMobile ? "large" : "default"}
                  >
                    {this.props.top_list ? "Update" : "Save"}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Form.create({ name: "dynamic_form_item" })(ContentCreateTopLists))
);
