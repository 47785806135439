import React from "react";
import {Col, Row} from "antd";
import RightContentAttraction from "../../content-attraction/right-content";
import {BottomLine} from "../../modules/typography/title";

const ListAttraction = ({alphacode, ...props}) => {
    return (
        <Row className="row-itineraries">
            <Col md={6} xl={6} xxl={6}>
                <div className="grid-header">
                    <span className="title-font huge">Top Attractions</span>
                    <BottomLine/>
                </div>
            </Col>
            <Col md={18} xl={18} xxl={18}>
                <RightContentAttraction className="flex-row-attraction" limit={8} alphaCode={alphacode} url='attractions/by-country' grid={{md: 2, lg: 3, xl: 4, xxl: 4, xs: 1, sm: 1}}/>
            </Col>
        </Row>
    );
}

export default ListAttraction;
