import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Row, Col, Badge } from "reactstrap";
import ApiService from "../../services/api.service";
import Typography1 from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { loadItem } from "../../services/storage.service";
import "./index.scss";
import moment from "moment";
import { Input, Form, Upload, Icon, Modal, TimePicker, Select } from "antd";
import {
  getBase64,
  getBase64FromAvatar,
  convertSecondsToHours,
  convertSecondsToMinutes,
  isMyResource,
  getImageFromUrl,
  getAllLeaf,
} from "../../services/utils.service";
import { format } from "date-fns";

import { configBaseUrl } from "../../config";
import { NumericInput } from "../../components";
import {
  redirectToBillingAfterLogin,
  closeFormViewEditPlace
} from "../../store/actions";
import { categoriesDefined } from "../../constants/categories";
import _ from "lodash"

const { Option } = Select;

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
  {
    value: "VNĐ",
    label: "đ",
  },
];

const mapDispatchToProps = (dispatch) => {
  return {
    redirectToBillingAfterLogin: (flag) => {
      dispatch(redirectToBillingAfterLogin(flag));
    },
    closeFormViewEditPlace: () => {
      dispatch(closeFormViewEditPlace());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    currency: state.itineraryReducer.currency,
    profile: state.profileReducer.info,
    currency_infor: state.itineraryReducer.currency_infor,
  };
};

class LodgingTab extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      _id: "",
      contentState: {},
      type: "check-in",
      title: "",
      address: "",
      lat: "",
      lng: "",
      phone_number: "",
      descriptions: "",
      booked_through: "",
      confirmation: "",
      time_start: null,
      day_start: "",
      duration: "",
      timezone: "",
      amount: "",
      currency: "",
      image: "",
      listDay: [],
      previewVisible: false,
      previewImage: "",
      fileList: [],
      loading: false,
      hours: undefined,
      minutes: undefined,

      category: "",
      file_list_delete: [],
      file_list_trix_delete: [],
    };
  }

  handleChange = (name) => (event) => {
    if (name === "time_start") {
      if (!event) {
        this.setState(
          {
            time_start: null,
          },
          () => {
            this.props.onFillForm({
              _id: this.state._id,
              category: this.state.category,
              title: this.state.title,
              type: this.state.type,
              address: this.state.address,
              lat: this.state.lat,
              lng: this.state.lng,
              phone_number: this.state.phone_number,
              descriptions: this.state.descriptions,
              booked_through: this.state.booked_through,
              confirmation: this.state.confirmation,
              provider: this.state.provider,
              time_start: null,
              day_start: this.state.day_start,
              duration: this.state.duration,
              timezone: this.state.timezone,
              amount: this.state.amount,
              currency: this.state.currency,
              image: this.state.image,
              images: this.state.fileList,
              images_delete: this.state.file_list_delete,
              file_list_trix_delete: this.state.file_list_trix_delete,
            });
          }
        );
      } else {
        let hours = new Date(event).getHours();
        let minutes = new Date(event).getMinutes();
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        this.setState(
          {
            time_start: moment(hours + ":" + minutes, ["h:m a", "H:m"]),
          },
          () => {
            this.props.onFillForm({
              _id: this.state._id,
              category: this.state.category,
              type: this.state.type,
              title: this.state.title,
              address: this.state.address,
              lat: this.state.lat,
              lng: this.state.lng,
              phone_number: this.state.phone_number,
              descriptions: this.state.descriptions,
              booked_through: this.state.booked_through,
              confirmation: this.state.confirmation,
              provider: this.state.provider,
              time_start: hours + ":" + minutes,
              day_start: this.state.day_start,
              duration: this.state.duration,
              timezone: this.state.timezone,
              amount: this.state.amount,
              currency: this.state.currency,
              image: this.state.image,
              images: this.state.fileList,
              images_delete: this.state.file_list_delete,
              file_list_trix_delete: this.state.file_list_trix_delete,
            });
          }
        );
      }
    } else if (name === "day_start") {
      this.setState(
        {
          day_start: event,
        },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            type: this.state.type,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            phone_number: this.state.phone_number,
            descriptions: this.state.descriptions,
            booked_through: this.state.booked_through,
            confirmation: this.state.confirmation,
            time_start: moment(this.state.time_start, "HH:mm")._i,
            day_start: this.state.day_start,
            duration: this.state.duration,
            timezone: this.state.timezone,
            amount: this.state.amount,
            currency: this.state.currency,
            image: this.state.image,
            images: this.state.fileList,
            images_delete: this.state.file_list_delete,
            file_list_trix_delete: this.state.file_list_trix_delete,
          });
        }
      );
    } else {
      if (name === "amount") {
        this.setState(
          {
            [name]: event,
          },
          () => {
            this.props.onFillForm({
              _id: this.state._id,
              category: this.state.category,
              type: this.state.type,
              title: this.state.title,
              address: this.state.address,
              lat: this.state.lat,
              lng: this.state.lng,
              phone_number: this.state.phone_number,
              descriptions: this.state.descriptions,
              booked_through: this.state.booked_through,
              confirmation: this.state.confirmation,
              time_start: moment(this.state.time_start, "HH:mm")._i,
              day_start: this.state.day_start,
              duration: this.state.duration,
              timezone: this.state.timezone,
              amount: this.state.amount,
              currency: this.state.currency,
              image: this.state.image,
              images: this.state.fileList,
              images_delete: this.state.file_list_delete,
              file_list_trix_delete: this.state.file_list_trix_delete,
            });
          }
        );
      } else {
        this.setState(
          {
            [name]: event.target.value,
          },
          () => {
            this.props.onFillForm({
              _id: this.state._id,
              category: this.state.category,
              type: this.state.type,
              title: this.state.title,
              address: this.state.address,
              lat: this.state.lat,
              lng: this.state.lng,
              phone_number: this.state.phone_number,
              descriptions: this.state.descriptions,
              booked_through: this.state.booked_through,
              confirmation: this.state.confirmation,
              time_start: moment(this.state.time_start, "HH:mm")._i,
              day_start: this.state.day_start,
              duration: this.state.duration,
              timezone: this.state.timezone,
              amount: this.state.amount,
              currency: this.state.currency,
              image: this.state.image,
              images: this.state.fileList,
              images_delete: this.state.file_list_delete,
              file_list_trix_delete: this.state.file_list_trix_delete,
            });
          }
        );
      }
    }
  };

  handleChangeNotes = (value) => {
    this.setState(
      {
        descriptions: value,
      },
      () => {
        this.props.onFillForm({
          _id: this.state._id,
          category: this.state.category,
          type: this.state.type,
          title: this.state.title,
          address: this.state.address,
          lat: this.state.lat,
          lng: this.state.lng,
          phone_number: this.state.phone_number,
          descriptions: this.state.descriptions,
          booked_through: this.state.booked_through,
          confirmation: this.state.confirmation,
          time_start: moment(this.state.time_start, "HH:mm")._i,
          day_start: this.state.day_start,
          duration: this.state.duration,
          timezone: this.state.timezone,
          amount: this.state.amount,
          currency: this.state.currency,
          image: this.state.image,
          images: this.state.fileList,
          images_delete: this.state.file_list_delete,
          file_list_trix_delete: this.state.file_list_trix_delete,
        });
      }
    );
  };

  uploadFileAttachment = async (attachment, setProgress, setAttributes) => {
    // await this.uploadFile(attachment.file, setProgress, setAttributes);

    // // function setProgress(progress) {
    // //   attachment.setUploadProgress(progress);
    // // }

    // attachment.setUploadProgress(100)

    // function setAttributes(attributes) {
    //   attachment.setAttributes(attributes);
    // }

    const formData = new FormData();
    formData.append("file", attachment.file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      // onUploadProgress: function(progressEvent) {
      //   var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      //   progressCallback(percentCompleted)
      // }
    };

    return this.apiService.uploadImage(formData, config).then((res) => {
      if (res.status === "success") {
        attachment.setUploadProgress(100);
        var attributes = {
          url: configBaseUrl.baseURL + res.fileNameInServer,
          href: configBaseUrl.baseURL + res.fileNameInServer,
        };
        attachment.setAttributes(attributes);
      }
    });
  };

  uploadFile = (file, progressCallback, successCallback) => {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      // onUploadProgress: function(progressEvent) {
      //   var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      //   progressCallback(percentCompleted)
      // }
    };

    return this.apiService.uploadImage(formData, config).then((res) => {
      if (res.status === "success") {
        var attributes = {
          url: configBaseUrl.baseURL + res.fileNameInServer,
          href: configBaseUrl.baseURL + res.fileNameInServer,
        };
        successCallback(attributes);
      }
    });
  };

  createStorageKey = (file) => {
    var date = new Date();
    var day = date.toISOString().slice(0, 10);
    var name = date.getTime() + "-" + file.name;
    return ["tmp", day, name].join("/");
  };

  createFormData = (key, file) => {
    var data = new FormData();
    data.append("key", key);
    data.append("Content-Type", file.type);
    data.append("file", file);
    return data;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    document.removeEventListener("trix-initialize", this.trixEmbedVideo);
    document.removeEventListener("trix-attachment-add", this.func);
    document.removeEventListener(
      "trix-attachment-remove",
      this.trixRemoveAttachmentEvent
    );
  };

  trixEmbedVideo = (event) => {
    let editor = event.target;
    let toolbar = editor.toolbarElement;
    let ttools = toolbar.querySelector(".trix-button-group--text-tools");
    let dialogs = toolbar.querySelector(".trix-dialogs");
    let trixId = editor.trixId;

    let buttonContent = `
      <button type="button"
        class="trix-button trix-button--icon trix-button--icon-video"
        data-trix-attribute="video"
        data-trix-key="+" title="Embed video" tabindex="-1">
      </button>
    `;

    let dialogContent = `
      <div class="trix-dialog trix-dialog--video" data-trix-dialog="video" data-trix-dialog-attribute="video">
        <div class="trix-dialog__attach-fields">
          <input data-type="url-video" class="trix-input trix-input--dialog" placeholder="Insert url youtube video here">
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog embed-video"
              onclick="
                var trix = document.querySelector('trix-editor[trix-id=\\'${trixId}\\']');
                var videoEmbed = this.parentElement.parentElement.querySelector('input[data-type=\\'url-video\\']');
              
                var url = videoEmbed.value
                var video_id = url.split('v=')[1];
                var ampersandPosition = video_id.indexOf('&');
                if(ampersandPosition != -1) {
                  video_id = video_id.substring(0, ampersandPosition);
                }

                const iframeMarkup = '<iframe width=\\'560\\' height=\\'315\\' src=\\'https://www.youtube.com/embed/'+video_id+'\\' frameborder=\\'0\\' allowfullscreen></iframe>';
                var attachment = new Trix.Attachment({content: iframeMarkup})
                trix.editor.insertAttachment(attachment)
              "
              value="Embed video" data-trix-method="removeAttribute">
            <input type="button" class="trix-button trix-button--dialog" value="Cancel" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
    `;
    // add attach icon button
    ttools.insertAdjacentHTML("beforeend", buttonContent);
    // add dialog
    dialogs.insertAdjacentHTML("beforeend", dialogContent);
  };

  func = (event) => {
    if (event.attachment.file) {
      //this.uploadFileAttachment(event.attachment);
      const formData = new FormData();
      formData.append("description_image", event.attachment.file);
      var percentCompleted;
      this.apiService
        .uploadImage(formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((res) => {
          if (res.status === "success" && percentCompleted === 100) {
            event.attachment.setUploadProgress(100);
            var attributes = {
              url: configBaseUrl.baseURL + res.path,
              href: configBaseUrl.baseURL + res.path,
            };
            event.attachment.setAttributes(attributes);
          }
        });
    }
  };

  trixRemoveAttachmentEvent = (event) => {
    const attributes = event.attachment.getAttributes();
    if (attributes.href) {
      var nameFileDelete = attributes.href.split("/");
      nameFileDelete = nameFileDelete[nameFileDelete.length - 1];
      this.setState(
        {
          file_list_trix_delete: [
            ...this.state.file_list_trix_delete,
            nameFileDelete,
          ],
        },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            type: this.state.type,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            phone_number: this.state.phone_number,
            descriptions: this.state.descriptions,
            booked_through: this.state.booked_through,
            confirmation: this.state.confirmation,
            time_start: moment(this.state.time_start, "HH:mm")._i,
            day_start: this.state.day_start,
            duration: this.state.duration,
            timezone: this.state.timezone,
            amount: this.state.amount,
            currency: this.state.currency,
            image: this.state.image,
            images: this.state.fileList,
            images_delete: this.state.file_list_delete,
            file_list_trix_delete: this.state.file_list_trix_delete,
          });
        }
      );
    }
  };

  componentDidMount = () => {
    this._isMounted = true;
    this.setState({
      currency: this.props.itinerary.currency,
    });

    document.addEventListener("trix-attachment-add", this.func);
    document.addEventListener(
      "trix-attachment-remove",
      this.trixRemoveAttachmentEvent
    );

    let temp = _.find(this.props.categories, (category) => {
      return category.name === categoriesDefined.ACCOMMODATION;
    });

    this.setState({
      category: temp._id
    })

    document.addEventListener("trix-initialize", this.trixEmbedVideo);

    document.addEventListener("trix-change", (event) => {
      var element = event.target;
      var html = element.value;
      this.setState(
        {
          descriptions: html,
        },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            type: this.state.type,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            phone_number: this.state.phone_number,
            descriptions: this.state.descriptions,
            booked_through: this.state.booked_through,
            confirmation: this.state.confirmation,
            time_start: moment(this.state.time_start, "HH:mm")._i,
            day_start: this.state.day_start,
            duration: this.state.duration,
            timezone: this.state.timezone,
            amount: this.state.amount,
            currency: this.state.currency,
            image: this.state.image,
            images: this.state.fileList,
            images_delete: this.state.file_list_delete,
            file_list_trix_delete: this.state.file_list_trix_delete,
          });
        }
      );
    });
    // this.apiService.getItineraryDetail(this.props.params.idItinerary).then(res => {
    //     if (this._isMounted) {
    //         var result = res.itineraries_detail
    //         result.sort((data_A, data_B) => {
    //             return compareAsc(new Date(data_A.month_day_year * 1000), new Date(data_B.month_day_year * 1000))
    //         })
    //         this.setState({
    //             listDay: result
    //         }, () => {
    //             let tempDayStart = result.filter(item => {
    //                 return item._id === this.props.params.idItineraryDetail
    //             })
    //             this.setState({
    //                 day_start: tempDayStart[0]._id
    //             })
    //         })
    //     }
    // })

    if (this._isMounted) {
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });

      var result = getAllLeaf(itineraries[current].itinerary_detail);
      this.setState(
        {
          listDay: result,
        },
        () => {
          let tempDayStart = result.filter((item) => {
            return item._id === this.props.params.idItineraryDetail;
          });
          this.setState({
            day_start: tempDayStart[0]._id,
          });
        }
      );
    }

    if (this.props.item) {
      const { item } = this.props;
      let tempImgs = [];
      var element = document.querySelector("trix-editor");
      element.editor.insertHTML(
        this.props.item.data.actual_data.descriptions
          ? this.props.item.data.actual_data.descriptions
          : ""
      );
      element.blur();
      if (item.data.actual_data.images) {
        item.data.actual_data.images.forEach((item) => {
          if (isMyResource(item.preview)) {
            tempImgs.push({
              ...item,
              url: configBaseUrl.baseURL + item.preview,
              thumbnail: configBaseUrl.baseURL + item.preview,
              thumbUrl: configBaseUrl.baseURL + item.preview,
            });
          } else {
            tempImgs.push({
              ...item,
              uid: new Date(),
              url: getImageFromUrl(item.preview, "large"),
              thumbnail: getImageFromUrl(item.preview, "large"),
              thumbUrl: getImageFromUrl(item.preview, "large"),
            });
          }
        });
      }
      this.setState(
        {
          _id: item._id,
          category: temp._id,
          type:
            item.data.actual_data.type === "check-in" ||
            item.data.actual_data.type === "check-out"
              ? item.data.actual_data.type
              : "check-in",
          title: item.data.actual_data.title ? item.data.actual_data.title : "",
          address: item.data.actual_data.address
            ? item.data.actual_data.address
            : "",
          lat: item.data.actual_data.lat ? item.data.actual_data.lat : "",
          lng: item.data.actual_data.lng ? item.data.actual_data.lng : "",
          phone_number: item.data.actual_data.phone_number
            ? item.data.actual_data.phone_number
            : "",
          descriptions: item.data.actual_data.descriptions
            ? item.data.actual_data.descriptions
            : "",
          booked_through: item.data.actual_data.booked_through
            ? item.data.actual_data.booked_through
            : "",
          confirmation: item.data.actual_data.confirmation
            ? item.data.actual_data.confirmation
            : "",
          // time_start: item.data.actual_data.time_start ? item.data.actual_data.time_start : '',
          time_start: item.data.actual_data.time_start
            ? moment(item.data.actual_data.time_start, ["h:m a", "H:m"])
            : null,
          day_start: item.data.actual_data.day_start
            ? item.data.actual_data.day_start
            : "",
          duration: item.data.actual_data.duration
            ? item.data.actual_data.duration
            : "",
          timezone: item.data.actual_data.timezone
            ? item.data.actual_data.timezone
            : "",
          amount:
            item.data.actual_data.amount !== ""
              ? item.data.actual_data.amount
              : "",
          currency: this.props.currency,
          image: item.data.actual_data.image ? item.data.actual_data.image : "",
          //fileList: item.data.actual_data.images ? item.data.actual_data.images : [],
          fileList: tempImgs,
          hours: item.data.actual_data.duration
            ? convertSecondsToHours(item.data.actual_data.duration)
            : undefined,
          minutes: item.data.actual_data.duration
            ? convertSecondsToMinutes(item.data.actual_data.duration)
            : undefined,
        },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            type: this.state.type,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            phone_number: this.state.phone_number,
            descriptions: this.state.descriptions,
            booked_through: this.state.booked_through,
            confirmation: this.state.confirmation,
            time_start: item.data.actual_data.time_start
              ? item.data.actual_data.time_start
              : "",
            day_start: this.state.day_start,
            duration: this.state.duration,
            timezone: this.state.timezone,
            amount: this.state.amount,
            currency: this.props.currency,
            image: this.state.image,
            images: this.state.fileList,
            images_delete: this.state.file_list_delete,
            file_list_trix_delete: this.state.file_list_trix_delete,
          });
        }
      );
    }
  };

  handleChangeAvatar = (info) => {
    this.setState({ loading: true });
    // Get this url from response in real world.
    getBase64FromAvatar(info.file.originFileObj, (image) => {
      this.setState(
        {
          image,
          loading: false,
        },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            type: this.state.type,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            phone_number: this.state.phone_number,
            descriptions: this.state.descriptions,
            booked_through: this.state.booked_through,
            confirmation: this.state.confirmation,
            time_start: moment(this.state.time_start, "HH:mm")._i,
            day_start: this.state.day_start,
            duration: this.state.duration,
            timezone: this.state.timezone,
            amount: this.state.amount,
            currency: this.state.currency,
            image: this.state.image,
            images: this.state.fileList,
            images_delete: this.state.file_list_delete,
            file_list_trix_delete: this.state.file_list_trix_delete,
          });
        }
      );
    });
  };

  handleChangeFiles = ({ fileList }) => {
    let tempfileList = [...fileList];
    tempfileList.forEach(async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
    });
    this.setState({ fileList: tempfileList }, () => {
      this.props.onFillForm({
        _id: this.state._id,
        category: this.state.category,
        type: this.state.type,
        title: this.state.title,
        address: this.state.address,
        lat: this.state.lat,
        lng: this.state.lng,
        phone_number: this.state.phone_number,
        descriptions: this.state.descriptions,
        booked_through: this.state.booked_through,
        confirmation: this.state.confirmation,
        time_start: moment(this.state.time_start, "HH:mm")._i,
        day_start: this.state.day_start,
        duration: this.state.duration,
        timezone: this.state.timezone,
        amount: this.state.amount,
        currency: this.state.currency,
        image: this.state.image,
        images: this.state.fileList,
        images_delete: this.state.file_list_delete,
        file_list_trix_delete: this.state.file_list_trix_delete,
      });
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChangeHours = (value) => {
    if (!this.state.minutes) {
      this.setState(
        { minutes: 0, hours: value, duration: value * 3600 },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            type: this.state.type,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            phone_number: this.state.phone_number,
            descriptions: this.state.descriptions,
            booked_through: this.state.booked_through,
            confirmation: this.state.confirmation,
            time_start: moment(this.state.time_start, "HH:mm")._i,
            day_start: this.state.day_start,
            duration: this.state.duration,
            timezone: this.state.timezone,
            amount: this.state.amount,
            currency: this.state.currency,
            image: this.state.image,
            images: this.state.fileList,
            images_delete: this.state.file_list_delete,
            file_list_trix_delete: this.state.file_list_trix_delete,
          });
        }
      );
    } else {
      this.setState(
        {
          hours: value,
          duration: value * 3600 + this.state.minutes * 60,
        },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            type: this.state.type,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            phone_number: this.state.phone_number,
            descriptions: this.state.descriptions,
            booked_through: this.state.booked_through,
            confirmation: this.state.confirmation,
            time_start: moment(this.state.time_start, "HH:mm")._i,
            day_start: this.state.day_start,
            duration: this.state.duration,
            timezone: this.state.timezone,
            amount: this.state.amount,
            currency: this.state.currency,
            image: this.state.image,
            images: this.state.fileList,
            images_delete: this.state.file_list_delete,
            file_list_trix_delete: this.state.file_list_trix_delete,
          });
        }
      );
    }
  };

  handleChangeMinutes = (value) => {
    if (!this.state.hours) {
      this.setState({ hours: 0, minutes: value, duration: value * 60 }, () => {
        this.props.onFillForm({
          _id: this.state._id,
          category: this.state.category,
          type: this.state.type,
          title: this.state.title,
          address: this.state.address,
          lat: this.state.lat,
          lng: this.state.lng,
          phone_number: this.state.phone_number,
          descriptions: this.state.descriptions,
          booked_through: this.state.booked_through,
          confirmation: this.state.confirmation,
          time_start: moment(this.state.time_start, "HH:mm")._i,
          day_start: this.state.day_start,
          duration: this.state.duration,
          timezone: this.state.timezone,
          amount: this.state.amount,
          currency: this.state.currency,
          image: this.state.image,
          images: this.state.fileList,
          images_delete: this.state.file_list_delete,
          file_list_trix_delete: this.state.file_list_trix_delete,
        });
      });
    } else {
      this.setState(
        {
          minutes: value,
          duration: this.state.hours * 3600 + value * 60,
        },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            type: this.state.type,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            phone_number: this.state.phone_number,
            descriptions: this.state.descriptions,
            booked_through: this.state.booked_through,
            confirmation: this.state.confirmation,
            time_start: moment(this.state.time_start, "HH:mm")._i,
            day_start: this.state.day_start,
            duration: this.state.duration,
            timezone: this.state.timezone,
            amount: this.state.amount,
            currency: this.state.currency,
            image: this.state.image,
            images: this.state.fileList,
            images_delete: this.state.file_list_delete,
            file_list_trix_delete: this.state.file_list_trix_delete,
          });
        }
      );
    }
  };

  customUpload = async ({ onError, onSuccess, file }) => {
    var percentCompleted;
    const formData = new FormData();
    formData.append("gallery", file);
    try {
      this.apiService
        .uploadGallery(formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((res) => {
          onSuccess(null, res.path);
        });
    } catch (e) {
      onError(e);
    }
  };

  handleRemove = (file) => {
    let temp = [...this.state.file_list_delete];
    temp.push(file);
    this.setState(
      {
        file_list_delete: temp,
      },
      () => {
        this.props.onFillForm({
          _id: this.state._id,
          category: this.state.category,
          type: this.state.type,
          title: this.state.title,
          address: this.state.address,
          lat: this.state.lat,
          lng: this.state.lng,
          phone_number: this.state.phone_number,
          descriptions: this.state.descriptions,
          booked_through: this.state.booked_through,
          confirmation: this.state.confirmation,
          time_start: moment(this.state.time_start, "HH:mm")._i,
          day_start: this.state.day_start,
          duration: this.state.duration,
          timezone: this.state.timezone,
          amount: this.state.amount,
          currency: this.state.currency,
          image: this.state.image,
          images: this.state.fileList,
          images_delete: this.state.file_list_delete,
          file_list_trix_delete: this.state.file_list_trix_delete,
        });
      }
    );
  };

  handleChangeCategory = (value, option) => {
    if (option.props.children === categoriesDefined.ATTRACTION)
      this.props.changeCategory(1);
    if (option.props.children === categoriesDefined.ACCOMMODATION)
      this.props.changeCategory(2);
    if (option.props.children === categoriesDefined.RESTAURANT)
      this.props.changeCategory(3);
    if (option.props.children === categoriesDefined.TOURACTIVITY)
      this.props.changeCategory(4);
    if (option.props.children === categoriesDefined.TRANSPORTATION)
      this.props.changeCategory(5);
    if (option.props.children === categoriesDefined.INFORMATION)
      this.props.changeCategory(6);

    this.setState({
      category: value,
    }, () => {
      this.props.onFillForm({
        _id: this.state._id,
        category: this.state.category,
        type: this.state.type,
        title: this.state.title,
        address: this.state.address,
        lat: this.state.lat,
        lng: this.state.lng,
        phone_number: this.state.phone_number,
        descriptions: this.state.descriptions,
        booked_through: this.state.booked_through,
        confirmation: this.state.confirmation,
        time_start: moment(this.state.time_start, "HH:mm")._i,
        day_start: this.state.day_start,
        duration: this.state.duration,
        timezone: this.state.timezone,
        amount: this.state.amount,
        currency: this.state.currency,
        image: this.state.image,
        images: this.state.fileList,
        images_delete: this.state.file_list_delete,
        file_list_trix_delete: this.state.file_list_trix_delete,
      });
    });
  };

  handleUpgrateSubscript = () => {
    this.props.redirectToBillingAfterLogin(1)
    this.props.closeFormViewEditPlace();
  }

  render() {
    const { previewVisible, previewImage, fileList, image } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    var listHours = [];
    var listMinutes = [];
    for (let i = 0; i <= 59; i = i + 10) {
      listMinutes.push(
        <Option value={i} key={i}>
          {i}
        </Option>
      );
    }

    for (let i = 0; i <= 23; i++) {
      listHours.push(
        <Option value={i} key={i}>
          {i}
        </Option>
      );
    }
    return (
      <>
        <Row>
          <Col xs={12} md={6}>
            <div className="basic-infor">
              {/* <div className="basic-infor__header">Basic</div> */}
              <Row className="each-row-in-form date">
                <Col xs="12" sm="12" md="12" lg="12">
                  <Form.Item
                    label="Date"
                    labelAlign="left"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    colon={false}
                  >
                    <Select
                      label="Date"
                      value={this.state.day_start}
                      defaultValue={[`${this.state.day_start}`]}
                      onChange={this.handleChange("day_start")}
                    >
                      {this.state.listDay.map((option, index) => (
                        <Option key={option._id} value={option._id}>
                          <Typography1 variant="inherit" noWrap>
                            {option.title ? (
                              option.month_day_year ? (
                                <span>
                                  <Badge color="primary">Day {index + 1}</Badge>{" "}
                                  {format(
                                    new Date(option.month_day_year * 1000),
                                    "MMM d, yyyy"
                                  )}
                                  <br />
                                  {option.title}
                                </span>
                              ) : (
                                <span>
                                  <Badge color="primary">Day {index + 1}</Badge>{" "}
                                  <br />
                                  {option.title}
                                </span>
                              )
                            ) : option.month_day_year ? (
                              <span>
                                <Badge color="primary">Day {index + 1}</Badge>{" "}
                                {format(
                                  new Date(option.month_day_year * 1000),
                                  "MMM d, yyyy"
                                )}
                              </span>
                            ) : (
                              <span>
                                <Badge color="primary">Day {index + 1}</Badge>{" "}
                              </span>
                            )}
                          </Typography1>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="each-row-in-form">
                <Col xs="12">
                  <Form.Item
                    label="Category"
                    labelAlign="left"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    colon={false}
                  >
                    {this.state.category ? (
                      <Select
                        defaultValue={this.state.category}
                        onChange={this.handleChangeCategory.bind(this)}
                      >
                        {!!this.props.categories.length &&
                          this.props.categories.map((item) => (
                            <Option key={item._id} value={item._id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="each-row-in-form">
                <Col xs="12" sm="12" md="12" lg="12">
                  <Form.Item
                    label="Title"
                    required
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    labelAlign="left"
                    colon={false}
                    validateStatus={
                      !this.props.validate && (this.state.title === "" || !this.state.title.trim())
                        ? "error"
                        : ""
                    }
                    help={
                      !this.props.validate && (this.state.title === "" || !this.state.title.trim())
                        ? "Title is required"
                        : ""
                    }
                  >
                    <Input
                      value={this.state.title}
                      onChange={this.handleChange("title")}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Address"
                    labelAlign="left"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    colon={false}
                  >
                    <Input
                      value={this.state.address}
                      onChange={this.handleChange("address")}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Phone number"
                    labelAlign="left"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    colon={false}
                  >
                    <Input
                      value={this.state.phone_number}
                      onChange={this.handleChange("phone_number")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="advance-infor">
              {(!this.props.profile ||
                (this.props.profile && this.props.profile.accountType < 2)) && (
                <div className="block">
                  <div>Feature unavailable under your current plan. <span className="what-this" onClick={this.handleUpgrateSubscript} >Upgrade your subscription to use feature</span></div>
                </div>
              )}
              {/* <div className="advance-infor__header">Advance</div> */}
              <Row className="each-row-in-form">
                <Col xs={12}>
                  <Form.Item
                    label="Status"
                    labelAlign="left"
                    wrapperCol={{ span: 17 }}
                    labelCol={{ span: 7 }}
                    colon={false}
                  >
                    <RadioGroup
                      aria-label="gender"
                      name="gender2"
                      style={{ display: `inline` }}
                      value={this.state.type}
                      onChange={this.handleChange("type")}
                    >
                      <FormControlLabel
                        disabled={
                          !this.props.profile ||
                          (this.props.profile &&
                            this.props.profile.accountType < 2)
                            ? true
                            : false
                        }
                        value="check-in"
                        control={<Radio color="primary" />}
                        label="Check-in"
                        style={{ marginLeft: 0 }}
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        disabled={
                          !this.props.profile ||
                          (this.props.profile &&
                            this.props.profile.accountType < 2)
                            ? true
                            : false
                        }
                        value="check-out"
                        control={<Radio color="primary" />}
                        label="Check-out"
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="each-row-in-form">
                <Col sm="12" md="12" lg="12">
                  <Row>
                    <Col
                      className="start-time-select"
                      xs="12"
                      style={{ alignSelf: "center" }}
                    >
                      <Form.Item
                        label="Start time"
                        labelAlign="left"
                        wrapperCol={{ span: 17 }}
                        labelCol={{ span: 7 }}
                        colon={false}
                      >
                        <TimePicker
                          disabled={
                            !this.props.profile ||
                            (this.props.profile &&
                              this.props.profile.accountType < 2)
                              ? true
                              : false
                          }
                          value={
                            this.state.time_start
                              ? moment(new Date(this.state.time_start), "hh:mm")
                              : null
                          }
                          defaultOpenValue={moment("00:00", "hh:mm")}
                          use12Hours
                          format="hh:mm A"
                          minuteStep={10}
                          onChange={this.handleChange("time_start")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs="12" className="row-duration">
                      <Form.Item
                        label="Duration"
                        labelAlign="left"
                        wrapperCol={{ span: 17 }}
                        labelCol={{ span: 7 }}
                        colon={false}
                      >
                        <div style={{ display: `flex` }}>
                          <div style={{ width: `100%` }}>
                            <Select
                              disabled={
                                !this.props.profile ||
                                (this.props.profile &&
                                  this.props.profile.accountType < 2)
                                  ? true
                                  : false
                              }
                              placeholder="Hours"
                              style={{ width: 120 }}
                              value={this.state.hours}
                              onChange={this.handleChangeHours}
                            >
                              {listHours}
                            </Select>
                          </div>
                          <div
                            style={{ margin: `0 0.5rem`, alignSelf: `center` }}
                          >
                            {" - "}
                          </div>
                          <div style={{ width: `100%` }}>
                            <Select
                              disabled={
                                !this.props.profile ||
                                (this.props.profile &&
                                  this.props.profile.accountType < 2)
                                  ? true
                                  : false
                              }
                              placeholder="Minutes"
                              style={{ width: 120 }}
                              value={this.state.minutes}
                              onChange={this.handleChangeMinutes}
                            >
                              {listMinutes}
                            </Select>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="each-row-in-form">
                <Col xs="12">
                  <Form.Item
                    label="Booked through"
                    labelAlign="left"
                    wrapperCol={{ span: 17 }}
                    labelCol={{ span: 7 }}
                    colon={false}
                  >
                    <Input
                      disabled={
                        !this.props.profile ||
                        (this.props.profile &&
                          this.props.profile.accountType < 2)
                          ? true
                          : false
                      }
                      value={this.state.booked_through}
                      onChange={this.handleChange("booked_through")}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Reservation code"
                    labelAlign="left"
                    wrapperCol={{ span: 17 }}
                    labelCol={{ span: 7 }}
                    colon={false}
                  >
                    <Input
                      disabled={
                        !this.props.profile ||
                        (this.props.profile &&
                          this.props.profile.accountType < 2)
                          ? true
                          : false
                      }
                      value={this.state.confirmation}
                      onChange={this.handleChange("confirmation")}
                    />
                  </Form.Item>

                  <Form.Item
                    className="input-amount"
                    label="Amount"
                    labelAlign="left"
                    wrapperCol={{ span: 17 }}
                    labelCol={{ span: 7 }}
                    colon={false}
                  >
                    <NumericInput
                      disabled={
                        !this.props.profile ||
                        (this.props.profile &&
                          this.props.profile.accountType < 2)
                          ? true
                          : false
                      }
                      addonBefore={`${this.props.currency_infor.code} (${this.props.currency_infor.symbol})`}
                      value={this.state.amount}
                      onChange={this.handleChange("amount")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row className="each-row-in-form">
                <Col xs="12">

                </Col>

                <Col xs="12">
                  <Form.Item
                    label="Currency"
                    labelAlign="left"
                    wrapperCol={{ span: 17 }}
                    labelCol={{ span: 7 }}
                    colon={false}
                  >
                    <Select
                      value={this.state.currency}
                      onChange={this.handleChange("currency")}
                    >
                      {currencies.map((option, index) => (
                        <Option
                          disabled={option.value !== this.state.currency}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row> */}
            </div>
          </Col>
        </Row>
        <Row className="each-row-in-form" style={{ marginBottom: "16px" }}>
          <Col xs="12">
            <Form.Item label="Photos" colon={false}>
              <>
                <Upload
                  customRequest={this.customUpload}
                  listType="picture-card"
                  fileList={fileList}
                  multiple
                  name="gallery"
                  onPreview={this.handlePreview}
                  onChange={this.handleChangeFiles}
                  onRemove={this.handleRemove}
                >
                  {uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </>
            </Form.Item>
          </Col>
          <Col sm="12" md="12" lg="12">
            <input type="hidden" id="trix" />
            <trix-editor class="trix-content" input="trix" />
          </Col>
        </Row>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LodgingTab);
