import React from 'react';
import { Pagination } from 'antd';
import queryString from 'query-string';

const PaginationCustomize = (props) => {
  const {
    paramLimit,
    totalPage,
    location,
    setPage,
    history,
    currentPage,
  } = props;
  if (totalPage === 0) {
    return null;
  }

  return (
    <Pagination
      style={{ float: 'right', marginTop: '1rem' }}
      pageSize={+paramLimit}
      current={currentPage}
      total={totalPage}
      onChange={(page) => {
        setPage(page);
        const params = queryString.parse(location.search);
        let limit = params.limit ? '&limit=' + params.limit : '&limit=' + 10;
        history.push(`/quotations?page=${page}` + limit);
      }}
    />
  );
};

export default PaginationCustomize;
