import React, { Component } from "react";
import "./index.scss";
import {Row, Input, AutoComplete, Icon} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { withRouter } from "react-router";
import { saveItem, loadItem } from "../../../services/storage.service";

const { Option } = AutoComplete;

class SearchBar extends Component {
  insertHistory = (value) => {
    if (!value || !value.trim()) return;
    let keySearch = [];
    if (!loadItem("keySearch")) {
      keySearch.splice(0, 0, { value });
      saveItem("keySearch", keySearch);
    } else {
      let temp = loadItem("keySearch");
      const found = temp.findIndex((el) => el.value === value);
      if (found >= 0) {
        let item = temp.splice(found, 1)[0];
        temp.splice(0, 0, item);
      } else temp.splice(0, 0, { value });
      if (temp.length > 10) {
        temp.pop();
      }
      saveItem("keySearch", temp);
    }
  };
  onSearch = (value) => {
    if (value) {
      this.insertHistory(value);

      this.props.history.push(`/search?name=${value}`);
    }
  };

  renderOption = (item) => {
    return (
      <Option key={item.value} text={item.value}>
        <div className="history-search">
          <Icon type="search" className="icon-history" />
          <p className="history-word">{item.value}</p>
          {/* <p className="history-action">remove</p> */}
        </div>
      </Option>
    );
  };

  onSelect = (value) => {
    this.insertHistory(value);

    this.props.history.push(`/search?name=${value}`);
  };

  render() {
    let keySearch = [];
    if (loadItem("keySearch")) keySearch = loadItem("keySearch");
    return (
      <Row className="search-bar">
        <div className="section-search">
          <div className="section-search__input">
            <AutoComplete
              dataSource={keySearch.map(this.renderOption)}
              filterOption={(inputValue, option) =>
                option.props.text
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              style={{ width: "100%" }}
              size="large"
              onSelect={this.onSelect.bind(this)}
              optionLabelProp="text"
            >
              <Input.Search
                onSearch={this.onSearch.bind(this)}
                allowClear
                size="large"
                placeholder="Where are you going now?"
              />
            </AutoComplete>
          </div>
        </div>
      </Row>
    );
  }
}

export default withRouter(SearchBar);
