import React from "react";
import { Form, Radio, Input } from "antd";
import { useFieldArray } from "react-hook-form";

import { AlertOutlined } from "@ant-design/icons";

export default ({
  nestIndex,
  control,
  register,
  item,
  layout,
  Controller,
  dayData,
  currency,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `days[${nestIndex}].guide`,
  });
  return (
    <div className={"body "}>
      <div className="detail">
        <Form.Item
          label={
            <strong>
              <AlertOutlined /> Guide
            </strong>
          }
          {...layout}
        >
          <Controller
            control={control}
            name={`days[${nestIndex}].guideCheck`}
            defaultValue={dayData.guideCheck ? true : false}
            render={({ value, onChange }) => {
              return (
                <Radio.Group
                  defaultValue={dayData.guideCheck}
                  onChange={(value) => {
                    if (value.target.value) {
                      append({
                        budget: 0,
                      });
                    } else {
                      remove(0);
                    }
                    onChange(value.target.value);
                  }}
                >
                  <Radio value={true}>Included</Radio>
                  <Radio value={false}>Not included</Radio>
                </Radio.Group>
              );
            }}
          />
        </Form.Item>
      </div>
      {fields.map((item, k) => {
        return (
          <div className="content" key={item.id}>
            <Form.Item label={null}>
              <Controller
                control={control}
                name={`days[${nestIndex}].guide[${k}].budget`}
                defaultValue={item.budget}
                render={({ value, onChange }) => (
                  <Input
                  type="number"
                    placeholder="budget"
                    defaultValue={item.budget}
                    onChange={onChange}
                    addonBefore={currency}
                  />
                )}
              />
            </Form.Item>
          </div>
        );
      })}
    </div>
  );
};
