import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

interface ListBreadCrumbItem{
  link: string,
  title: string,
  type: string
}

const MyBreadcrumb = ({separator = '>', ...props}) => {
  const list: [ListBreadCrumbItem] = props.list;

  return <Breadcrumb separator={separator}>
    {list.map(item => (
        <Breadcrumb.Item>
          {item.link ? (item.type === 'redirect' ? <a href ={item.link} >{item.title} </a> : <Link to={item.link}>{item.title}</Link>) : item.title}
        </Breadcrumb.Item>
    ))}
  </Breadcrumb>;
}

export default MyBreadcrumb;
