import React, { Component } from 'react';
import { Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import logo from '../../assets/media/images/tyneri-logo.png';
import './index.scss';
import { withRouter } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { config } from '../../config';
import SearchBar from '../content-trendy/search-bar';

const MenuItems = [
  {
    link: '/trending',
    name: 'Trending',
  },
  {
    link: '/top-lists',
    name: 'Top Lists',
  },
  {
    link: '/top-destinations',
    name: 'Top Destinations',
  },
  {
    link: '/top-attractions',
    name: 'Top Attractions',
  },
];
const { SubMenu } = Menu;
class MenuMain extends Component {
  state = {
    visible: false,
  };

  getMenu = () => {
    let menuHtml = [];
    MenuItems.forEach((menu, key) => {
      menuHtml.push(
        <Menu.Item key={key} className="menu-item-custom">
          <a href={config.http + '//' + config.domain + menu.link}>
            {menu.name}
          </a>
        </Menu.Item>
      );
    });
    return menuHtml;
  };

  clickMenu = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  menuIcon() {
    if (this.props.isAdmin) {
      return <MenuOutlined onClick={this.clickMenu} />;
    } else {
      return <MenuOutlined onClick={this.clickMenu} />;
    }
  }

  render() {
    const { idItineraryDetail, idItinerary } = this.props.match.params;
    return (
      <div className="main-menu-container">
        <div className="menu-and-logo">
          <a className="logo-container" href={config.clientURL + 'app'}>
            <img className="main-logo" src={logo} alt="logo" />
          </a>
          {!isMobileOnly && !this.props.isAdmin && !this.props.hideSearchBar ? (
            <SearchBar />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(MenuMain);
