import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import MenuAdmin from "./menu";
import MenuLogin from "../../components/menu-login";
import CreateButtonTyneri from "../../components/create-tyneri-button";
import { connect } from "react-redux";

import "./index.scss";
import "../../assets/sass/common.scss"
const { Content, Sider, Header } = Layout;

function LayoutAdmin(props) {
  const [title, setTitle] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(props.profile);
  }, [props.profile]);

  return (
    <Layout className="admin-layout">
      <Sider breakpoint="lg" collapsible>
        {user !== null ? (
          <MenuAdmin
            setTitle={setTitle}
            {...props.children.props}
            profile={user}
          />
        ) : null}
      </Sider>
      <Layout>
        <div className="content abs-content">
          <Header className="header-admin">
              <span className="name-page">{title}</span>
              <div className="menu d-flex align-items-center">
                <CreateButtonTyneri />
                {user !== null ? (
                  <>
                    <MenuLogin adminLayout={true} {...props.children.props} />
                  </>
                ) : null}
              </div>
          </Header>
          <div className="box-scroll">
            <div className="content-right">
              <Content className="site-layout-background">
                {props.children}
              </Content>
            </div>
          </div>
        </div>
      </Layout>
    </Layout>
  );
}

const mapDispatchToProps = () => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutAdmin);
