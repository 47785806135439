import React, {useState} from "react";
import TitleTyneri from "../modules/typography/title";
import {loadItem} from "../../services/storage.service";
import ListTyneri from "../content-trendy/list-tyneri";

const RecentItinerary = () => {
    const itinerarySeen = loadItem('itinerarySeen');
    const [recent] = useState(itinerarySeen ? itinerarySeen.itineraries : []);
    return (
        recent.length ? <div className="row-itineraries recent-itinerary">
                <div className="grid-header">
                    <TitleTyneri text={"Recently viewed"} />
                </div>
                <ListTyneri
                    list={recent}
                    isHistory={true}
                />
            </div> : <React.Fragment/>
    );
}

export default RecentItinerary;
