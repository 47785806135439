import React from 'react';
import { Calendar } from 'antd';
import './index.scss'

const MyCalendar = () => {
    return <div>
        <Calendar/>
    </div>
}

export default MyCalendar;