import React, { useEffect, useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import { connect } from "react-redux";
import ApiService from "../../../../../services/api.service";
import Alert from "../../alert/index";

const FormItem = Form.Item;
function FormPassword(props) {
  const apiService = ApiService();
  const form = props.form;
  const { getFieldDecorator } = form;
  const [existPassword, setExistPassword] = useState(false);
  const [ openModal, setOpenModal ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const alert = new Alert();

  useEffect(() => {
    checkExistPassword();
  },[]);

  function checkExistPassword() {
    if (props.profile) {
      apiService.checkExistPassword(props.profile._id).then((res) => {
        if (res.status === "success") {
          setExistPassword(res.exist);
          setLoading(false)
        }
      });
    }
  }

  function checkPassword(rule, value, callback) {
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  }
  function checkConfirm(rule, value, callback) {
    if (value) {
      form.validateFields(["repeatPassword"], { force: true });
    }
    callback();
  }

  function handleCreateNewPassword(e) {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        apiService
          .createPassword(props.profile.email, values["newPassword"])
          .then((res) => {
            if (res.status === "success") {
              checkExistPassword();
              alert.success("Create new password successfully!");
              form.resetFields("newPassword");
              form.resetFields("currentPassword");
              setOpenModal(false);
            }
          });
      }
    });
  }

  function handleChangePassword(e) {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        apiService
          .changePassword(
            props.profile.email,
            values["currentPassword"],
            values["newPassword"]
          )
          .then((res) => {
            if (
              res.status === "failed" &&
              res.error === "Current password is wrong! Please try again"
            ) {
              alert.error(res.error);
            } else if (res.status === "success") {
              alert.success('Update new password successfully!');
              form.resetFields("newPassword");
              form.resetFields("retypePassword");
              form.resetFields("currentPassword");
              setOpenModal(false);
            }
          });
      }
    });
  }

  function PasswordForm() {
    return (
      <Modal visible={ openModal }
             footer={
                <Button type="primary" htmlType="submit"
                  onClick={(e) => {
                    handleChangePassword(e);
                  }}>
                  Change password
                </Button>
             }
             onCancel={ () => setOpenModal(false) }>
        <Form className="password-form"
          {...props.formItemLayout} >
          <FormItem label="Current Password">
            {getFieldDecorator("currentPassword", {
              rules: [
                {
                  required: true,
                  message: "Please input your password!",
                  whitespace: true
                },
              ],
            })(<Input type="password" />)}
          </FormItem>
          <FormItem label="New Password">
            {getFieldDecorator("newPassword", {
              rules: [
                {
                  required: true,
                  message: "Please input your new password!",
                  whitespace: true
                },
                {
                  validator: checkConfirm,
                },
              ],
            })(<Input type="password" />)}
          </FormItem>
          <FormItem label="Repeat Password">
            {getFieldDecorator("retypePassword", {
              rules: [
                {
                  required: true,
                  message: "Please confirm your password!",
                  whitespace: true
                },
                {
                  validator: checkPassword,
                },
              ],
            })(<Input type="password" />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }

  function NewPasswordForm() {
    return (
      <Modal visible={ openModal }
             footer={
              <Button type="primary" htmlType="submit"
                onClick={(e) => {
                  handleCreateNewPassword(e);
                }}>
                Create password
              </Button>
             }
             onCancel={ () => setOpenModal(false) }>
        <Form className="password-form"
          {...props.formItemLayout}
          onSubmit={(e) => {
            handleCreateNewPassword(e);
          }}>
          <FormItem label="Password">
            {getFieldDecorator("newPassword", {
              rules: [
                {
                  required: true,
                  message: "Please input your password!",
                  whitespace: true
                },
                {
                  validator: checkConfirm,
                },
              ],
            })(<Input type="password" />)}
          </FormItem>
          <FormItem label="Repeat Password">
            {getFieldDecorator("retypePassword", {
              rules: [
                {
                  required: true,
                  message: "Please confirm your password!",
                  whitespace: true
                },
                {
                  validator: checkPassword,
                },
              ],
            })(<Input type="password" />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }

  if(loading) return null;

  return  (<div className="change-password">
    {existPassword ? PasswordForm() : NewPasswordForm() }
    <Button type="primary" onClick={ () => setOpenModal(!openModal) }>
      {existPassword ? "Change password" : "Create password"}
    </Button>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

export default Form.create()(
  connect(mapStateToProps, mapDispatchToProps)(FormPassword)
);
