import React, { Component } from "react";
import "./index.scss";
import { withRouter } from "react-router";
import ApiService from "../../services/userApi.service";
import ErrorPage from "../../pages/error";
import {
  Button,
  Spin,
} from "antd";

const loadingContent = () => (
  <div className="loading-container">
    <Spin />
  </div>
);

class VerifyPage extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      tokenExpire: false,
      loadingPage: true
    };
  }

  componentDidMount = async () => {
    document.title = "Tyneri";
    const token = this.props.match.params.token
    await this.apiService.checkTokenVerifyEmail(token).then((res) => {
      this.setState({
        loadingPage: false,
        tokenExpire: res.exist,
      });
    });
    await this.apiService.verifyEmail(token).then(response => {
      if (!response.tokenExpires) {
        // this.setState({
        //   loadingPage: false,
        // });
      }
    });
  };

  handleGoBack = () => {
    this.props.history.push("/");
  };

  render() {
    const { tokenExpire, loadingPage} = this.state

    if (loadingPage) return loadingContent;

    if (!tokenExpire) return <ErrorPage />;



    return (
      <div id="notfound">
        <div className="notfound-bg" />
        <div className="notfound">
          <div className="notfound-404">
            <h1>Verify success</h1>
          </div>
          <h2>Success! Your email has been verified.</h2>
          <div onClick={this.handleGoBack.bind(this)} className="goback-btn">
            Go home
          </div>
          <a href="/" className="contact-btn">
            Contact us
          </a>
          <div className="notfound-social">
            <Button type="link">
              <i className="fa fa-facebook" />
            </Button>
            <Button type="link">
              <i className="fa fa-twitter" />
            </Button>
            <Button type="link">
              <i className="fa fa-pinterest" />
            </Button>
            <Button type="link">
              <i className="fa fa-google-plus" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(VerifyPage);
