import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Typography,
  Row,
  Col,
  Steps,
  notification,
  Checkbox,
} from "antd";
import { connect } from "react-redux";
import { useEffect } from "react";

import UserApiService from "../../services/userApi.service";
import InquiriesApiService from "../../services/inquiriesApi.service";
import "./index.scss";
import ImageHelper from "../../functions/image";
import { config, configBaseUrl } from "../../config";

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Step } = Steps;

const formItemLayout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 21 },
  size: "large",
  layout: "horizontal",
  labelAlign: "left",
};

const emailRuleValidate = Object(
  {
    required: true,
    whitespace: true,
    message: "Please input your E-mail!",
  },
  {
    type: "email",
    message: "The input is not valid E-mail!",
  }
);

function Inquiries(props) {
  const [openForm, setForm] = useState(props.formOpen);
  const inquiriesApiService = InquiriesApiService();
  const [proAccount, setProAccount] = useState(false);
  const [company, setCompany] = useState(null);
  const [countItineraries, setCountItineraries] = useState(0);
  const [countLists, setCountLists] = useState(0);
  const [user, setUser] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);
  const [submitLoadings, setSubmitLoadings] = useState(false);
  const form = props.form;
  const { getFieldDecorator } = form;

  useEffect(() => {
    setForm(props.formOpen);
  }, [props.formOpen])

  useEffect(() => {
    if (!openForm && props.updateForm && typeof props.updateForm === 'function')
      props.updateForm(openForm);
  }, [openForm]);

  useEffect(() => {
    if (props.noShowCompany) {
      setProAccount(true);
    } else {
      const userApiService = UserApiService();
      userApiService
        .checkIsProAccountByUserId(props.itinerary.belong_to_user)
        .then((response) => {
          if (response.proAccount) {
            setProAccount(true);
            setUser(response.user);
            setCompany(response.company);
            setCountItineraries(response.countItineraries);
            setCountLists(response.countLists);
          }
        });
    }
  }, [props.itinerary, props.noShowCompany]);

  function submitInquiriesForm(e) {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, values) => {
      setSubmitLoadings(true);
      if (!err) {
        const InquiriesData = {
          title: values["title"],
          date: values["date"],
          information: values["information"],
          firstName: values["firstName"],
          phone: values["phone"],
          email: values["email"],
          lastName: values["lastName"],
          sendEmail: sendEmail,
          belong_to_itinerary: props.itinerary._id,
          belong_to_user: props.itinerary.belong_to_user,
        };
        const response = await inquiriesApiService.insertNewInquiries(
          InquiriesData
        );
        if (!response.error) {
          notification.info({
            message: `Success`,
            description: "Your Inquiries has been sent",
            placement: "topRight",
            duration: 3,
          });
          setForm(false);
          setSendEmail(false);
          form.resetFields();
        } else {
          notification.error({
            message: `Error`,
            description: "Cannot send request",
            placement: "topRight",
            duration: 3,
          });
        }
      }
      setSubmitLoadings(false);
    });
  }

  // function checkEmail(rule, value, callback) {
  //   if (value && value !== form.getFieldValue("email")) {
  //     callback("Two email that you enter is inconsistent!");
  //   } else {
  //     callback();
  //   }
  // }
  // function checkConfirmEmail(rule, value, callback) {
  //   if (value) {
  //     form.validateFields(["confirmEmail"], { force: true });
  //   }
  //   callback();
  // }

  function InquiriesForm() {
    return (
      <>
        <Modal
          title={null}
          width="650px"
          {...formItemLayout}
          className="modal-inquiries"
          visible={openForm}
          onCancel={() => {
            setForm(false);
          }}
          footer={[
            <>
              <Checkbox
                name="sendMail"
                checked={sendEmail}
                onChange={() => setSendEmail(!sendEmail)}
              >
                Send me a copy email
              </Checkbox>
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                size="large"
                loading={submitLoadings}
                onClick={(e) => {
                  submitInquiriesForm(e);
                }}
              >
                Submit My Request
              </Button>
            </>,
          ]}
        >
          <Form
            name="inquiries-form"
            className="inquiries-form"
            form={form}
            onSubmit={(e) => {
              submitInquiriesForm(e);
            }}
          >
            <Steps direction="vertical" initial={0}>
              <Step title={<Title level={4}>Your travel plan</Title>} />
            </Steps>

            <Form.Item label="Title">
              {getFieldDecorator("title", {
                initialValue:
                  props.itinerary.name !== null ? props.itinerary.name : "",
                rules: [
                  {
                    whitespace: true,
                    required: true,
                    message: "Please input Title!",
                  },
                ],
              })(<Input name="title" />)}
            </Form.Item>
            <Form.Item label="Dates you would like to travel, and for how long? ">
              {getFieldDecorator("date", {
                rules: [
                  {
                    whitespace: true,
                  },
                ],
              })(<TextArea name="date" />)}
            </Form.Item>
            <Form.Item label="Your comments">
              {getFieldDecorator("information", {
                rules: [
                  {
                    whitespace: true,
                  },
                ],
              })(
                <TextArea
                  name="information"
                  placeholder="Please enter any further information or special interests
                           such as food tours, cooking class, nature & Wildlife, beach,
                           culture shows, history, museum, market & local village
                           visits and soft adventure, ..etc"
                />
              )}
            </Form.Item>

            <Steps direction="vertical" initial={1} current={1}>
              <Step
                title={<Title level={4}>Tell us about your self</Title>}
                description="Notes: Your information will be kept private"
              />
            </Steps>
            <Row className="about">
              <Col xs={24} sm={24} md={12}>
                <Form.Item>
                  {getFieldDecorator("firstName", {
                    initialValue:
                      props.profile !== null ? props.profile.firstName : "",
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input your First name!",
                      },
                    ],
                  })(
                    <Input
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item>
                  {getFieldDecorator("lastName", {
                    initialValue:
                        props.profile !== null ? props.profile.lastName : "",
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input your Last name!",
                      },
                    ],
                  })(<Input placeholder="Last Name" />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item>
                  {getFieldDecorator("phone", {
                    initialValue:
                        props.profile !== null ? props.profile.phone : "",
                    rules: [
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ],
                  })(
                      <Input
                          name="phone"
                          type="number"
                          placeholder="Phone (+country code and number)"
                      />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item>
                  {getFieldDecorator("email", {
                    initialValue:
                      props.profile !== null ? props.profile.email : "",
                    rules: [emailRuleValidate],
                  })(<Input name="email" type="email" placeholder="Email" />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
  }

  if (!proAccount) return null;

  return (
    <>
      {InquiriesForm()}
      <div className="company-request-section">
        {company ? (
          company.url ? (
            <a
              href={config.http + "//" + company.url + "." + config.domain}
              target="blank"
            >
              <div className="company-section">
                <ImageHelper
                  src={
                    company.name
                      ? configBaseUrl.baseURL + company.logo
                      : configBaseUrl.baseURL + user.avatar
                  }
                  noShowDeImage={false}
                  className="company-logo"
                />
                <div className="company-infor">
                  <span className="company-infor__name">
                    {company.name
                      ? company.name
                        ? company.name
                        : company.short_name
                        ? company.short_name
                        : null
                      : `${user.firstName} ${user.lastName}`}
                    {user.job ? <Text className="type-user" type="secondary">@{user.job.name}</Text> : null}
                  </span>
                  <div className="company-infor__summary">
                    <span>
                      Lists
                      <span className="company-infor__summary__detail">
                        {countLists}
                      </span>
                    </span>
                    <span>
                      Itinerary
                      <span className="company-infor__summary__detail">
                        {countItineraries}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </a>
          ) : (
            <div className="company-section">
              <ImageHelper
                src={
                  company.name
                    ? configBaseUrl.baseURL + company.logo
                    : configBaseUrl.baseURL + user.avatar
                }
                noShowDeImage={false}
                className="company-logo"
              />
              <div className="company-infor">
                <span className="company-infor__name">
                  {company.name
                    ? company.name
                      ? company.name
                      : company.short_name
                      ? company.short_name
                      : null
                    : `${user.firstName} ${user.lastName}`}
                  {user.job ? <Text className="type-user" type="secondary">@{user.job.name}</Text> : null}
                </span>
                <div className="company-infor__summary">
                  <span>
                    Lists
                    <span className="company-infor__summary__detail">
                      {countLists}
                    </span>
                  </span>
                  <span>
                    Itinerary
                    <span className="company-infor__summary__detail">
                      {countItineraries}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )
        ) : null}
      </div>
    </>
  );
}

const mapDispatchToProps = (dispath) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};
export default Form.create()(
  connect(mapStateToProps, mapDispatchToProps)(Inquiries)
);
