import React, { Component } from "react";
import { Col, Row, Breadcrumb, Pagination } from "antd";
import { connect } from "react-redux";
import ApiService from "../../services/api.service";
import Filter from "../content-trendy/filter";
import { isBrowser, isMobileOnly } from "react-device-detect";
import styles from "./index.scss";
import queryString from "query-string";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import ListCard from "../content-latest/list-card";

class ContentSearch extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      list: [],
      totalData: 0,
      page: 1,
      paramLimit: 10,
    };
  }

  componentDidMount = () => {
    // const params = queryString.parse(this.props.location.search);
    // if (params) {
    //   if (params.name && params.name.trim())
    //     this.apiService.searchItineraries(params.name).then((res) => {
    //       if (res.status === "success") {
    //         this.setState({
    //           list: res.itineraries,
    //         });
    //       }
    //     });
    // }
    this.setData(this.props.location.search)
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.location.search !== nextProps.location.search) {
      this.setData(nextProps.location.search);
    }
  };

  setData = (query) => {
    const params = queryString.parse(query);
    if (!params.name || !params.name.trim()) return;
    this.setState({
      paramLimit: params.limit || 10,
      page: +params.page || 1
    })
    this.apiService.searchItineraries(params.name, params.page || 1, params.limit || 10).then((res) => {
      if (res.status === "success") {
        this.setState({
          list: res.itineraries,
          totalData: res.total,
        });
      }
    });
  }

  render() {
    return (
      <div id="content" className={styles.contentSearch}>
        <div
            id="sidebar"
            className="content-center "
        >
          <div className="grid-header bread">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Search</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          {/* result itineraries */}
          <ListCard
              title={`Search (${this.state.totalData}) ${
                  this.state.totalData > 1 ? "results" : "result"
              } matching "${
                  queryString.parse(this.props.location.search).name
              }"`}
              list={this.state.list}
          />
          <Pagination
              style={{ float: "right", margin: "1rem 0" }}
              pageSize={+this.state.paramLimit}
              current={this.state.page}
              total={this.state.totalData}
              onChange={(page) => {
                this.setState({
                  page
                })
                const params = queryString.parse(this.props.location.search);
                const name = queryString.parse(this.props.location.search).name
                let limit = params.limit
                    ? "&limit=" + params.limit
                    : "&limit=" + 10;
                this.props.history.push(`/search?name=${name}&page=${page}` + limit);
              }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

export default withRouter(connect(mapStateToProps, null)(ContentSearch));
