import { actionTypes } from "../../constants/actionTypes";

var initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CURRENT_ITINERARY:
      return action.itinerary;
    case actionTypes.UPDATE_TOTAL_DAY:
      return {
        ...state,
        total_day: action.day,
      };
    case actionTypes.UPDATE_TOTAL_AMOUNT:
      return {
        ...state,
        total_amount: action.amount,
      };
    case actionTypes.UPDATE_CURRENCY:
      return {
        ...state,
        currency: action.currency,
      };
    case actionTypes.UPDATE_INFOR_CURRENCY:
      return {
        ...state,
        currency_infor: action.currency,
      };
    default:
      return state;
  }
};
