import {
  MyTyneri,
  Billing,
  MyProfile,
  Quotation,
  Inquiries,
  Calendar,
  Contacts,
  FormQuotation,
  MyLibrary,
  DashBoard,
  ViewQuotation,
} from '../../module/admin/components';
import RouteModel from '../model/route';

const routesAdmin = [
  RouteModel({
    path: '/my-tyneri',
    component: MyTyneri,
    privateRoute: true,
  }),
  RouteModel({
    path: '/my-library/:tab?',
    component: MyLibrary,
    privateRoute: true,
  }),
  RouteModel({
    path: '/my-profile',
    component: MyProfile,
    privateRoute: true,
  }),
  RouteModel({
    path: '/billing/:package?',
    component: Billing,
    privateRoute: true,
    exact: false,
  }),
  RouteModel({
    path: '/quotations',
    component: Quotation,
    privateRoute: true,
    proAccount: true,
  }),
  RouteModel({
    path: '/inquiries/:tab?',
    component: Inquiries,
    privateRoute: true,
    proAccount: true,
  }),
  RouteModel({
    path: '/calendar',
    component: Calendar,
    privateRoute: true,
    proAccount: true,
  }),
  RouteModel({
    path: '/contacts',
    component: Contacts,
    proAccount: true,
  }),
  RouteModel({
    path: ['/create-quotation/:idTyneri', '/create-quotation'],
    component: FormQuotation,
    privateRoute: true,
    proAccount: true,
  }),
  RouteModel({
    path: '/edit-quotation/:id',
    component: FormQuotation,
    privateRoute: true,
    proAccount: true,
  }),
  RouteModel({
    path: '/dashboard',
    component: DashBoard,
    privateRoute: true,
    proAccount: false,
  }),
  RouteModel({
    path: '/view-quotation/:id',
    component: ViewQuotation,
    privateRoute: true,
    proAccount: true,
  }),
];

export default routesAdmin;
