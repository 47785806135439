import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Empty } from "antd";
import { connect } from "react-redux";
import { Icon, Input, Row, Col } from "antd";

import ApiService from "../../../../../services/api.service";
import Item from "./item";

function ListFolder(props) {
  const apiService = ApiService();
  const [filterText, setFilterText] = useState("");
  const [listFolderFilter, setListFolderFilter] = useState([]);
  const [listFolder, setListFolder] = useState([]);
  const [listFolderOpen, setListFolderOpen] = useState([]);

  useEffect(() => {
    getMyFolder();
  }, [props.profile]);

  function getMyFolder() {
    apiService.getMyTyneriesByFolder(props.profile._id).then((res) => {
      if (res.status === "success") {
        let listFolderTemp = [...res.my_tyneries];
        listFolderTemp.forEach((folder) => {
          if (!folder.name) folder.name = "Others";
        });
        setListFolder(listFolderTemp);
        setListFolderFilter(listFolderTemp);
      }
    });
  }

  function searchFolder(event) {
    let listFolderTemp = [...listFolder];
    let value = event.target.value;
    setFilterText(value);
    if (value) {
      /* filter by ItemItinerary name  */
      listFolderTemp = listFolderTemp.filter((folder) =>
        folder.list.some((item) =>
          item.name.toUpperCase().includes(value.toUpperCase())
        )
      );
      setListFolderFilter(listFolderTemp);

      /* auto expand folder */
      let listFolderOpenTemp = [...listFolderOpen];
      listFolderTemp.forEach((folder) => {
        listFolderOpenTemp.push(folder._id);
      });
      setListFolderOpen(listFolderOpenTemp);
    } else {
      setListFolderFilter(listFolderTemp);
      setListFolderOpen([]);
    }
  }

  return (
    <div className="folder-list-container">
      <Row className="search-bar">
        <Col xl={24}>
          <Input
            placeholder="Find by itinerary name "
            value={filterText}
            suffix={
              <Icon
                type="close"
                onClick={() => {
                  setListFolderFilter(listFolder);
                  setListFolderOpen([]);
                  setFilterText("");
                }}
                className="certain-category-icon"
              />
            }
            onChange={searchFolder}
          />
        </Col>
      </Row>
      <Table className="table-list-folder table-custom">
        <Thead>
          <Tr>
            <Th>Title</Th>
            {props.manage ? (
              <>
                <Th>Views</Th>
                <Th>Customize</Th>
                <Th>Last updated</Th>
                <Th>Show</Th>
              </>
            ) : null}

            <Th className="actions-th">Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {listFolderFilter.length > 0 ? (
            listFolderFilter.map((itemList, key) => (
              <Item
                itemList={itemList}
                key={key}
                listFolder={listFolder}
                getMyFolder={getMyFolder}
                listFolderOpen={listFolderOpen}
                setListFolderOpen={setListFolderOpen}
                {...props}
              />
            ))
          ) : (
            <Tr className="body-row">
              <Td colSpan="7">
                <Empty />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListFolder);
