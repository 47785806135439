import { actionTypes } from '../../constants/actionTypes'

const initialState = {
    info: null,
    company: null,
    companyDomain: null,
    profileDomain: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_PROFILE:
            return {
                ...state,
                info: {
                    ...action.info
                }
            }
        case actionTypes.SAVE_COMPANY:
            return {
                ...state,
                company: {
                    ...action.company
                }
            }
        case actionTypes.SAVE_COMPANY_DOMAIN:
            return {
                ...state,
                companyDomain: {
                    ...action.company
                }
            }
        case actionTypes.SAVE_PROFILE_DOMAIN:
            return {
                ...state,
                profileDomain: {
                    ...action.profile
                }
            }
        case actionTypes.LOGOUT:
            return {
                ...state,
                info: null
            }
        default:
            return state
    }
}
