import React, { Component } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { Row, Col } from 'reactstrap';
import TextField from '@material-ui/core/TextField';

import { closeFormViewEditTransportation } from '../../store/actions'
import { connect } from 'react-redux'
import { loadItem } from '../../services/storage.service'
import { format24hTo12h } from '../../services/utils.service'

const mapStateToProps = (state) => {
    return {
        isDisplayFormViewEditTransportation: state.formViewEditTransportationReducer.open,
        transportation: state.formViewEditTransportationReducer.transportation
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeFormViewEditTransportation: () => { dispatch(closeFormViewEditTransportation()) }
    }
}

class FromViewEditTransportation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: ''
        };
    }

    handleCloseViewAndEditTransportation = () => {
        this.props.closeFormViewEditTransportation()
    }

    componentDidMount = () => {
        var itineraries
        itineraries = loadItem('itineraries')
        let current = itineraries.findIndex(item => {
            return item._id === this.props.params.idItinerary
        })
        if (current < 0)
            return
        this.setState({
            name: itineraries[current].transportation
        })
    }

    render() {
        var { isDisplayFormViewEditTransportation, transportation } = this.props
        return (
            <Dialog
                open={isDisplayFormViewEditTransportation ? true : false}
                onClose={this.handleCloseViewAndEditTransportation.bind(this)}
                aria-labelledby="form-view-edit-place"
            >
                <DialogTitle id="form-view-edit-place">Information your transpotation</DialogTitle>
                <DialogContent>
                    <Row>
                        <Col>
                            <TextField
                                disabled
                                InputProps={{
                                    readOnly: true
                                }}
                                id="standard-name"
                                label="Name"
                                margin="none"
                                type="text"
                                value={transportation.name ? transportation.name : this.state.name}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                disabled
                                InputProps={{
                                    readOnly: true
                                }}
                                id="standard-name"
                                label="Price"
                                margin="none"
                                type="number"
                                value={transportation ? transportation.price : ''}
                                fullWidth
                            />
                        </Col>
                        <Col>
                            <TextField
                                disabled
                                InputProps={{
                                    readOnly: true
                                }}
                                id="standard-name"
                                label="Distance"
                                margin="none"
                                value={transportation ? transportation.distance : ''}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                disabled
                                InputProps={{
                                    readOnly: true
                                }}
                                id="standard-name"
                                label="Time"
                                margin="none"
                                value={transportation && transportation.time ? format24hTo12h(transportation.time) : ''}
                                fullWidth
                            />
                        </Col>
                        <Col>
                            <TextField
                                disabled
                                InputProps={{
                                    readOnly: true
                                }}
                                id="standard-name"
                                label="Duration"
                                margin="none"
                                value={transportation ? transportation.duration : ''}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                disabled
                                InputProps={{
                                    readOnly: true
                                }}
                                id="standard-name"
                                label="Note"
                                margin="none"
                                fullWidth
                                multiline
                                value={transportation ? transportation.note : ''}
                                rowsMax="2"
                            />
                        </Col>
                    </Row>
                </DialogContent>
            </Dialog>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FromViewEditTransportation)