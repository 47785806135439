import React, {useEffect, useState} from "react";
import Inquiries from "../inquiries";
import {AccountType} from "../../params/AccountType";
import {config, configBaseUrl} from "../../config";
import {Avatar, Button, Typography} from "antd";
import {getAllLeaf, isMyResource} from "../../services/utils.service";
import {isMobileOnly} from "react-device-detect";
import styles from './index.scss';
import UserApiService from "../../services/userApi.service";
import {connect} from "react-redux";
import {ArrowRightOutlined} from "@ant-design/icons";

const {Text} = Typography;

const AuthorInfor = ({isPreview, ...props}) => {
    const userApiService = UserApiService();
    const handleCustomize = () => {
        if (isMobileOnly)
            props.history.push(`/itinerary/${props.itinerary._id}`);
        else {
            props.history.push(
                `/itinerary/${props.itinerary._id}/${
                    getAllLeaf(props.itinerary.itinerary_detail)[0]._id
                }`
            );
        }
    };

    const [proAccount, setProAccount] = useState(false);
    const [countItineraries, setCountItineraries] = useState(0);
    const [countLists, setCountLists] = useState(0);
    const [form, setForm] = useState(false);
    const [user, setUser] = useState({});

    useEffect(() => {
        async function fetchData() {
            let res = await userApiService.checkIsProAccountByUserId(props.itinerary.belong_to_user);
            setProAccount(res.proAccount);
            setCountItineraries(res.countItineraries);
            setCountLists(res.countLists);
            setUser(res.user);
        }
        fetchData();
    }, [props.itinerary.belong_to_user]);

    return (
        <div className={'author-infor '+styles.base}>
            {proAccount ? (
                <Inquiries updateForm={(val) => setForm(val)} formOpen={form} itinerary={props.itinerary} />
            ) : (
                <div className="company-request-section">
                    <div className="company-section author">
                        {props.itinerary.belong_to_user ? (
                            props.itinerary.user_infor.accountType === AccountType.Pro && props.itinerary.company.url ? (
                                <a
                                    href={config.http + "//" + props.itinerary.company.url + "." + config.domain+'/'+process.env.PUBLIC_URL}
                                    target="blank"
                                >
                                    <Avatar
                                        src={isMyResource(props.itinerary.user_infor.avatar) ? configBaseUrl.baseURL + props.itinerary.user_infor.avatar : props.itinerary.user_infor.avatar}
                                    /> {props.itinerary.user_infor.firstName}{" "}{props.itinerary.user_infor.lastName}
                                </a>
                            ) : (
                                <div className="author-avatar">
                                    <Avatar
                                        src={isMyResource(props.itinerary.user_infor.avatar) ? configBaseUrl.baseURL + props.itinerary.user_infor.avatar : props.itinerary.user_infor.avatar}
                                    />
                                </div>
                            )
                        ) : "Tyneri"}
                        <div className="company-infor">
                            <span className="company-infor__name">
                                {props.itinerary.user_infor.firstName+ ' ' +props.itinerary.user_infor.lastName}
                                {user.job ? <Text className="type-user" type="secondary">@{user.job.name}</Text> : null}
                            </span>
                            <div className="company-infor__summary">
                                <span>Lists <span className="company-infor__summary__detail">{countLists}</span></span>
                                <span>Itinerary <span className="company-infor__summary__detail">{countItineraries}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="d-flex flex-column justify-content-between flex-grow-1">
                {isPreview ? null : (
                    <Button
                        id="send-button"
                        type="submit"
                        className="btn-customize mb-3"
                        fullWidth
                        onClick={() => handleCustomize()}
                    >
                        <i className="fas fa-pencil-alt" style={{color: `#fff`, marginRight: "5px",}}/>
                        {props.profile && props.profile._id === props.itinerary.belong_to_user ? `Edit this Tyneri` : `Let's customize`}
                    </Button>
                )}
                {props.profile && props.profile._id === props.itinerary.belong_to_user ? null : (isPreview ? null : (
                    <Button
                        className="btn-request"
                        type="primary"
                        onClick={() => setForm(true)}
                    >
                        Ask for Availability <ArrowRightOutlined />
                    </Button>
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.profileReducer.info,
    };
};

export default connect(mapStateToProps, null)(AuthorInfor);
