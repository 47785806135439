import React from 'react'
import PropTypes from 'prop-types'
import style from './index.scss'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import AppBar from '../app-bar'
import Toolbar, { styles as toolbarStyles } from '../toolbar'
import logo from '../../assets/media/images/tyneri-logo.png'
import { Col } from 'reactstrap'

const styles = theme => ({
  placeholder: toolbarStyles(theme).root
});

function AppHeader(props) {
  const { classes } = props;

  return (
    <div className={style.appHeader}>
      <AppBar position="fixed">
        <Toolbar>
            <Col md={{ size: 2 }} sm={{ size: 6 }}>
              <Link to="/">
                <div className="main-logo">
                  <img src={logo} alt="logo" />
                </div>
              </Link>
            </Col>
            <Col md={{ size: 10 }} sm={{ size: 6 }}></Col>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
}

AppHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppHeader);