import React, { useState } from "react";
import { Row, Col, Form, Input, Button, notification } from "antd";
import * as QuotationApi from "../../../api/quotation";

function TokenForm(props) {
  const form = props.form;
  const [loadingButton, setLoadingButton] = useState(false);
  const { getFieldDecorator } = form;

  function submit(e) {
    e.preventDefault();
    setLoadingButton(true);
    form.validateFieldsAndScroll(async (err, values) => {
      const token = values["token"];
      if (!err) {
        QuotationApi.submitToken(token).then((res) => {
          if (!res.error && res.exist) {
            props.setTokenExist(true);
            props.setToken(token);
          }
          else
            notification.error({
              message: `Error `,
              description: "Your token is not valid",
              placement: "topRight",
            });
        });
      }
      setLoadingButton(false);
    });
  }

  return (
    <Row className="reset-password confirm-token">
      <Col xs={24} sm={24} md={24}>
        <Form
          size="large"
          className="reset-password-form"
          onSubmit={(e) => {
            submit(e);
          }}
        >
          <div className="label">
            <span>Please enter your token we had sent to your email.</span>
          </div>
          <Form.Item label="">
            {getFieldDecorator("token", {
              rules: [
                {
                  required: true,
                  message: "Please input your token!",
                  whitespace: true,
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loadingButton}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default Form.create()(TokenForm);
