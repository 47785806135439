import React from "react";
import styles from "./new-style.scss";
import { Col, Empty, Row, Spin } from "antd";
import ApiService from "../../services/api.service";
import { config } from "../../config";
import { updateInforCurrency } from "../../store/actions";
import { connect } from "react-redux";
import LeftContent from "../../components/detail-itinerary/left-content";
import GridHeader from "../../components/detail-itinerary/grid-header";
import RightContent from "../../components/detail-itinerary/right-content";
import Cookies from "universal-cookie";
import Alert from "../../module/admin/components/alert"
import MenuMobile from "./menu-mobile";
import {MobileView} from "react-device-detect";
import RecentService from "../../services/recent.service";

class NewViewItinerary extends React.Component {
    constructor(props) {
        super(props);
        this.apiService = ApiService();
        this.state = {
            itinerary: null,
        };
    }

    fetchData = async (idItinerary, user_id, cookie) => {
        let data = await this.apiService.viewItinerary(
            idItinerary,
            user_id,
            cookie
        );
        if (data.status === "success") {
            let result = await this.apiService.getDetailCurrency(
                data.itinerary.currency
            );
            if (result.status === "success") {
                this.props.updateInforCurrency(result.currency);
                this.setState({
                    itinerary: data.itinerary,
                }, () => {
                    RecentService.save({
                        ...this.state.itinerary,
                        currency_infor: result.currency,
                        last_seen: new Date(),
                    });
                });
            }
        } else {
            let alert = new Alert();
            alert.error(data.error);
            this.props.history.push("/");
        }
    }

    componentDidMount = async () => {
        var user_id;
        if (this.props.profile) user_id = this.props.profile._id;
        else user_id = null;

        const cookies = new Cookies();
        const cookie = cookies.get("id");

        var idItinerary = this.props.match.params.idItinerary;
        await this.fetchData(idItinerary, user_id, cookie);
    };

    UNSAFE_componentWillReceiveProps = async (nextProps) => {
        if (this.props.profile && !nextProps.profile && !this.state.itinerary.status) {
            this.props.history.push("/");
        }

        if (
          this.props.match.params.idItinerary !== nextProps.match.params.idItinerary
        ) {
            var user_id;
            if (nextProps.profile) user_id = nextProps.profile._id;
            else user_id = null;

            const cookies = new Cookies();
            const cookie = cookies.get("id");

            var idItinerary = nextProps.match.params.idItinerary;
            await this.fetchData(idItinerary, user_id, cookie);
        }
    };

    loadView = () => {
        let itinerary = this.state.itinerary || {};
        return (
            <div className={styles.wrapperContent}>
                <Row type="flex" gutter={24}>
                    <Col
                        lg={!this.props.isPreview ? 16 : 24}
                        md={!this.props.isPreview ? 24 : 24}
                        xl={!this.props.isPreview ? 16 : 24}
                        xxl={!this.props.isPreview ? 16 : 24}
                    >
                        <GridHeader
                            itinerary={itinerary}
                            isPreview={this.props.isPreview}
                        />
                        {itinerary.cover ? (
                            <img
                                className="img-fluid max-width"
                                src={config.baseURL + itinerary.cover}
                                alt=""
                            />
                        ) : null}
                        <LeftContent
                            isPreview={this.props.isPreview}
                            itinerary={itinerary}
                        />
                    </Col>
                    {this.props.isPreview ? null : (
                        <Col md={24} lg={8} xl={8} xxl={8} className="section-like">
                            <RightContent itinerary={itinerary} />
                        </Col>
                    )}
                </Row>
                <MobileView>
                    <MenuMobile itinerary={itinerary} params={this.props.match.params} />
                </MobileView>
            </div>
        );
    };

    render() {
        return this.state.itinerary ? (
            this.loadView()
        ) : (
            <Spin tip="Loading...">
                <Empty />
            </Spin>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        itinerary: state.itineraryReducer,
        profile: state.profileReducer.info,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateInforCurrency: (currency) => {
            dispatch(updateInforCurrency(currency));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewViewItinerary);
