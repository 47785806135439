import PaymentService from '../services/payment.service';

const AccountType = {
  Basic: 0,
  Personal: 1,
  Pro: 2,
};

const ParamNameAccountType = {
  Basic: { name: 'basic', value: 0 },
  Personal: { name: 'personal', value: 1 },
  Pro: { name: 'pro', value: 2 },
};

const AccountTypeColor = {
  Basic: '#8c8c8c',
  Personal: '#722ed1',
  Pro: '#389e0d',
};

const AccountData = async () => {
  return await PaymentService()
    .getPackages()
    .then((res) => res.packages);
};

export { AccountType, AccountTypeColor, AccountData, ParamNameAccountType };
/*const AccountData = [
    {
        id: AccountType.Basic,
        name: 'Basic',
        color: AccountTypeColor.Basic,
        packages: [
            {
                type: 'forever',
                unix: 0,
                price: 0,
                name: 'Forever subscription',
                id: 1
            }
        ]
    },
    {
        id: AccountType.Personal,
        name: 'Personal',
        color: AccountTypeColor.Personal,
        packages: [
            {
                type: 'months',
                unix: 1,
                price: 1,
                name: 'Monthly subscription',
                desc: 'Pay $1 per month, cancel anytime',
                id: 1
            },
            {
                type: 'years',
                unix: 1,
                price: 10,
                name: 'Yearly subscription',
                desc: 'Saving $2 by Buying annual plan',
                id: 2
            }
        ]
    },
    {
        id: AccountType.Pro,
        name: 'Pro',
        color: AccountTypeColor.Pro,
        packages: [
            {
                type: 'months',
                unix: 1,
                price: 10,
                name: 'Monthly subscription',
                desc: 'Pay $10 per month, cancel anytime',
                id: 1
            },
            {
                type: 'years',
                unix: 1,
                price: 100,
                name: 'Yearly subscription',
                desc: 'Saving $20 by Buying annual plan',
                id: 2
            }
        ]
    }
];*/
