import { Modal, Form, Input, Select, Row, Col } from "antd";
import React, { useEffect, useState } from "react";

import Alert from "../../alert";
import ContactApi from "../../../../../services/contactApi.service";
import "./index.scss";

const { Option } = Select;

function ContactsForm(props) {
  const form = props.form;
  const contact = props.contact;
  const alert = new Alert();
  const { getFieldDecorator } = form;
  const [groups, setGroups] = useState([]);
  const contactApi = ContactApi();

  useEffect(() => {
    contactApi.getGroup().then((response) => {
      setGroups(response.data);
    });
    form.setFieldsValue({
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phone: contact.phone,
      belong_to_user: contact.belong_to_user,
      id: contact._id,
      country: contact.country ? contact.country : "",
      group: contact.group ? contact.group._id : "",
    });
  }, [contact]);

  function saveContact() {
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        contactApi.save(values).then((response) => {
          if (!response.error) {
            alert.success("Success");
            props.close();
            props.loadPage();
          } else {
            alert.error("Error");
          }
        });
      }
    });
  }

  return (
    <Modal
      className="edit-contact-form"
      title="Edit Contact"
      visible={props.visible}
      onOk={saveContact}
      okText="Save"
      onCancel={props.close}
    >
      {getFieldDecorator("id", {})(<Input type="hidden" />)}
      {getFieldDecorator("belong_to_user", {})(<Input type="hidden" />)}
      <Row gutter={24}>
        <Col md={24} xl={12}>
          <Form.Item label="First Name">
            {getFieldDecorator("firstName", {
              rules: [
                {
                  required: true,
                  message: "Please input first name",
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col md={24} xl={12}>
          <Form.Item label="Last Name">
            {getFieldDecorator("lastName", {
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: "Please input last name",
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Email address">
        {getFieldDecorator("email", {
          rules: [
            {
              required: true,
              message: "Please input your email",
            },
            {
              type: "email",
              message: "This is not validate email!",
            },
          ],
        })(<Input disabled />)}
      </Form.Item>
      <Form.Item label="Phone number">
        {getFieldDecorator("phone", {
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input phone number",
            },
          ],
        })(<Input type="number" />)}
      </Form.Item>
      <Form.Item label="Country">
        {getFieldDecorator("country", {
          rules: [
            {
              required: true,
            },
          ],
        })(
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a country"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {props.countries.map((country) => {
              return (
                <Option key={country._id} value={country._id}>
                  {country.name}
                </Option>
              );
            })}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Group">
        {getFieldDecorator("group", {
          rules: [
            {
              required: true,
            },
          ],
        })(
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a group"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {groups.map((group) => {
              return (
                <Option key={group._id} value={group._id}>
                  {group.name}
                </Option>
              );
            })}
          </Select>
        )}
      </Form.Item>
    </Modal>
  );
}
export default Form.create()(ContactsForm);
