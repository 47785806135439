import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import './index.scss';
import SliderCustom from '../../slider-card-custom';
import { isTablet } from 'react-device-detect';
import { getFlag } from '../../../services/utils.service';
class ListScrollCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialSlide: 0,
      settings: {
        centerMode: false,
        responsive: [
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
        ],
      },
    };
    if (isTablet) {
      this.state.settings = {
        slidesToShow: 4,
        slidesToScroll: 4,
        ...this.state.settings,
      };
    }
  }

  getDataCountry = (data) => {
    if (data && !!data.length) {
      let dataHtml = [];
      let countIndex = 1;
      for (var i = 0; i < data.length; i++) {
        dataHtml.push(this.renderCardCountry(data[i], countIndex));
      }
      return dataHtml;
    }
    return null;
  };

  renderCardCountry = (item, index) => {
    return (
      <div className="each-country-scroll" key={index}>
        <Link key={index} to={`/top-destinations/country-${item.alpha2Code}`}>
          <div className="each-country-scroll__content">
            <img
              size="10"
              className="mr-1"
              alt="flag"
              src={getFlag(item.alpha2Code)}
            />{' '}
            {item.name}
          </div>
        </Link>
      </div>
    );
  };

  render() {
    return (
      <div className="row-country-slider">
        <SliderCustom
          slidesToShow={6}
          initialSlide={this.state.initialSlide}
          settings={this.state.settings}
          list={this.getDataCountry(this.props.listCountry)}
        />
      </div>
    );
  }
}

export default withRouter(ListScrollCountry);
