import axios from '../axios-config';

export default () =>{

    return {
        getTopView: () => {
            return axios.get('top-list/top-top-lists').then(res => res.data);
        },
        getUserTopList: (params = {}) => {
            return axios.get('top-list/company-list', true, {params}).then(res => res.data);
        }
    };
}
