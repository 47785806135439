import formatMoney from "accounting-js/lib/formatMoney.js";
import moment from "moment";
import { ObjectID } from "bson";
import _ from "lodash";
import { format, addDays } from "date-fns";
import { config } from "../config";
import { loadItem, saveItem } from "./storage.service";
import { AccountType } from "../params/AccountType";
import { categoriesDefined } from "../constants/categories";

import React from "react";

export const isSubdomain = () => {
  if (config.dev) {
    return !!window.location.host.split(".")[1];
  } else {
    const regexSubdomain = new RegExp(
      /^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/
    );
    return !!window.location.host.match(regexSubdomain);
  }
};

export const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    _id: `item-${k + offset}`,
    title: `Saigon Opera House (Nhà Hát Thành Phố)`,
    descriptions: `7 Lam Son Square, Dist. 1, Thành phố Hồ Chí Minh, Thành phố Hồ Chí Minh`,
  }));

const grid = 8;

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: `16`,
  margin: `0 0 ${grid}px 0`,
  cursor: `pointer`,
  // change background colour if dragging
  width: "100%",
  outline: "none",
  // styles we need to apply on draggables
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver, lengthListSelected) => ({
  background:
    lengthListSelected < 1 ? "#c2e9ff" : isDraggingOver ? "#FFF8DC" : "",
  paddingBottom: lengthListSelected < 1 ? "" : "0rem",
});

export const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

export const move = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const removed = sourceClone[droppableSource.index];
  destClone.splice(droppableDestination.index, 0, removed);
  const position = destClone.findIndex((x) => x._id === removed._id);
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  result["removed"] = removed;
  result["position"] = position;

  return result;
};

export const moveFromExplore = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const removed = sourceClone[droppableSource.index];
  destClone.splice(droppableDestination.index, 0, removed);
  const position = destClone.findIndex((x) => x.place_id === removed.place_id);
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  result["removed"] = removed;
  result["position"] = position;

  return result;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const dateToTimestamp = (strDate) => {
  var datum = Date.parse(strDate);
  return datum / 1000;
};

export const format24hTo12h = (timeString) => {
  var H = +timeString.substr(0, 2);
  var h = H % 12 || 12;
  if (h < 10) h = "0" + h;
  var ampm = H < 12 ? " AM" : " PM";
  return h + timeString.substr(2, 3) + ampm;
};

export const formatMoneyByCurrency = (total_amount, currency) => {
  if (currency === "USD")
    return formatMoney(total_amount, {
      symbol: "$",
      precision: 2,
      thousand: ".",
      decimal: ",",
      format: "%s %v",
    });
  if (currency === "EUR")
    return formatMoney(total_amount, {
      symbol: "€",
      precision: 2,
      thousand: ".",
      decimal: ",",
      format: "%s %v",
    });
  if (currency === "BTC")
    return formatMoney(total_amount, {
      symbol: "฿",
      precision: 2,
      thousand: ".",
      decimal: ",",
      format: "%s %v",
    });
  if (currency === "JPY")
    return formatMoney(total_amount, {
      symbol: "¥",
      precision: 2,
      thousand: ".",
      decimal: ",",
      format: "%s %v",
    });
  if (currency === "VNĐ")
    return formatMoney(total_amount, {
      symbol: "đ",
      precision: 0,
      thousand: ",",
      format: "%s %v",
    });
};

export const createPlaceDetail = (
  order,
  placeDetail,
  idItineraryDetail,
  transportationName,
  id,
  arrCategories
) => {
  let temp = _.find(arrCategories, (category) => {
    return category._id === placeDetail.category;
  });

  var newPlaceDetail = {};
  if (
    temp.name === categoriesDefined.TOURACTIVITY ||
    temp.name === categoriesDefined.RESTAURANT ||
    temp.name === categoriesDefined.ATTRACTION
  ) {
    newPlaceDetail = {
      _id: id,
      order: order,
      belong_to_itinerary_detail: idItineraryDetail,
      belong_to_library: placeDetail.belong_to_library
        ? placeDetail.belong_to_library
        : null,
      belong_to_library_place: placeDetail.belong_to_library_place
        ? placeDetail.belong_to_library_place
        : null,
      place_id: placeDetail.place_id,
      vendor: placeDetail.vendor,
      category: placeDetail.category,
      data: {
        default_data: {
          is_actived: true,
          title: null,
          address: null,
          lat: null,
          lng: null,
          phone_number: null,
          booked_through: null,
          confirmation: null,
          provider: null,
          time_start: null,
          day_start: null,
          duration: null,
          timezone: null,
          amount: null,
          currency: null,
          descriptions: null,
          image: null,
          images: null,
        },
        actual_data: {
          is_actived: true,
          title: placeDetail.title,
          address: placeDetail.address,
          lat: placeDetail.lat,
          lng: placeDetail.lng,
          phone_number: placeDetail.phone_number,
          booked_through: placeDetail.booked_through,
          confirmation: placeDetail.confirmation,
          provider: placeDetail.provider,
          time_start: placeDetail.time_start,
          day_start: idItineraryDetail,
          duration: placeDetail.duration,
          timezone: placeDetail.timezone,
          amount: placeDetail.amount,
          currency: placeDetail.currency,
          descriptions: placeDetail.descriptions,
          image: placeDetail.image,
          images: placeDetail.images,
          images_delete: placeDetail.images_delete,
          trix_delete: placeDetail.file_list_trix_delete,
        },
      },
      quote: placeDetail.quote ? placeDetail.quote : "",
      transportation: {
        name: transportationName,
        price: 0,
        distance: "",
        time: "",
        duration: "",
        note: "",
      },
    };
  } else if (
    temp.name === categoriesDefined.ACCOMMODATION ||
    temp.name === categoriesDefined.TRANSPORTATION
  ) {
    newPlaceDetail = {
      _id: id,
      order: order,
      place_id: placeDetail.place_id,
      vendor: placeDetail.vendor,
      category: placeDetail.category,
      belong_to_library: placeDetail.belong_to_library
        ? placeDetail.belong_to_library
        : null,
      belong_to_library_place: placeDetail.belong_to_library_place
        ? placeDetail.belong_to_library_place
        : null,
      belong_to_itinerary_detail: idItineraryDetail,
      data: {
        default_data: {
          is_actived: true,
          type: null,
          title: null,
          address: null,
          lat: null,
          lng: null,
          phone_number: null,
          descriptions: null,
          booked_through: null,
          confirmation: null,
          time_start: null,
          day_start: null,
          duration: null,
          timezone: null,
          amount: null,
          currency: null,
          image: null,
          images: null,
        },
        actual_data: {
          is_actived: true,
          type: placeDetail.type,
          title: placeDetail.title,
          address: placeDetail.address,
          lat: placeDetail.lat,
          lng: placeDetail.lng,
          phone_number: placeDetail.phone_number,
          booked_through: placeDetail.booked_through,
          confirmation: placeDetail.confirmation,
          time_start: placeDetail.time_start,
          day_start: idItineraryDetail,
          duration: placeDetail.duration,
          timezone: placeDetail.timezone,
          amount: placeDetail.amount,
          currency: placeDetail.currency,
          descriptions: placeDetail.descriptions,
          image: placeDetail.image,
          images: placeDetail.images,
          images_delete: placeDetail.images_delete,
          trix_delete: placeDetail.file_list_trix_delete,
        },
      },
      quote: placeDetail.quote ? placeDetail.quote : "",
      transportation: {
        name: transportationName,
        price: 0,
        distance: "",
        time: "",
        duration: "",
        note: "",
      },
    };
  } else if (temp.name === categoriesDefined.INFORMATION) {
    newPlaceDetail = {
      _id: id,
      order: order,
      belong_to_library: placeDetail.belong_to_library
        ? placeDetail.belong_to_library
        : null,
      belong_to_library_place: placeDetail.belong_to_library_place
        ? placeDetail.belong_to_library_place
        : null,
      belong_to_itinerary_detail: idItineraryDetail,
      category: placeDetail.category,
      data: {
        default_data: {
          is_actived: true,
          title: null,
          address: null,
          lat: null,
          lng: null,
          time_start: null,
          day_start: null,
          descriptions: null,
        },
        actual_data: {
          is_actived: true,
          title: placeDetail.title,
          address: placeDetail.address,
          lat: placeDetail.lat,
          lng: placeDetail.lng,
          time_start: placeDetail.time_start,
          day_start: idItineraryDetail,
          descriptions: placeDetail.descriptions,
          trix_delete: placeDetail.file_list_trix_delete,
        },
      },
      quote: placeDetail.quote ? placeDetail.quote : "",
      transportation: {
        name: transportationName,
        price: 0,
        distance: "",
        time: "",
        duration: "",
        note: "",
      },
    };
  }
  return newPlaceDetail;
};

export const createPlaceDetailFromExplore = (
  order,
  placeDetail,
  idItineraryDetail,
  currency,
  transportationName,
  arrCategories
) => {
  var newPlaceDetail;
  let temp = _.find(arrCategories, (category) => {
    return category._id === placeDetail.status;
  });

  if (
    temp.name === categoriesDefined.TOURACTIVITY ||
    temp.name === categoriesDefined.RESTAURANT ||
    temp.name === categoriesDefined.ATTRACTION
  ) {
    newPlaceDetail = {
      _id: new ObjectID().toHexString(),
      belong_to_itinerary_detail: idItineraryDetail,
      place_id: placeDetail.place_id,
      vendor: placeDetail.vendor,
      order: order,
      category: placeDetail.status,
      data: {
        default_data: {
          is_actived: true,
          title: null,
          address: null,
          phone_number: null,
          lat: null,
          lng: null,
          descriptions: null,
          booked_through: null,
          confirmation: null,
          time_start: null,
          day_start: null,
          duration: null,
          timezone: null,
          amount: null,
          currency: null,
          image: null,
          images: null,
        },
        actual_data: {
          title: placeDetail.name,
          address: placeDetail.vicinity,
          phone_number: placeDetail.phone_number,
          lat: placeDetail.lat,
          lng: placeDetail.lng,
          booked_through: "",
          confirmation: "",
          provider: "",
          time_start: "",
          day_start: idItineraryDetail,
          duration: "",
          timezone: "",
          amount: "",
          currency: currency,
          descriptions: "",
          //image: placeDetail.image,
          images: placeDetail.images
            ? [
                {
                  preview: placeDetail.images,
                  xhr: placeDetail.images,
                  thumbUrl: placeDetail.images,
                },
              ]
            : [],
        },
      },
      transportation: {
        name: transportationName,
        price: 0,
        distance: "",
        time: "",
        duration: "",
        note: "",
      },
    };
  } else if (
    temp.name === categoriesDefined.ACCOMMODATION ||
    temp.name === categoriesDefined.TRANSPORTATION
  ) {
    newPlaceDetail = {
      _id: new ObjectID().toHexString(),
      belong_to_itinerary_detail: idItineraryDetail,
      place_id: placeDetail.place_id,
      vendor: placeDetail.vendor,
      order: order,
      category: placeDetail.status,
      data: {
        default_data: {
          is_actived: true,
          type: null,
          title: null,
          address: null,
          phone_number: null,
          lat: null,
          lng: null,
          descriptions: null,
          booked_through: null,
          confirmation: null,
          time_start: null,
          day_start: null,
          duration: null,
          timezone: null,
          amount: null,
          currency: null,
          image: null,
          images: null,
        },
        actual_data: {
          type: placeDetail.status === "Lodging" ? "check-in" : "departure",
          title: placeDetail.name,
          address: placeDetail.vicinity,
          phone_number: placeDetail.phone_number,
          lat: placeDetail.lat,
          lng: placeDetail.lng,
          descriptions: "",
          booked_through: "",
          confirmation: "",
          time_start: "",
          day_start: idItineraryDetail,
          duration: "",
          timezone: "",
          amount: "",
          currency: currency,
          // image: placeDetail.image,
          images: placeDetail.images
            ? [
                {
                  preview: placeDetail.images,
                  xhr: placeDetail.images,
                  thumbUrl: placeDetail.images,
                },
              ]
            : [],
        },
      },
      transportation: {
        name: transportationName,
        price: 0,
        distance: "",
        time: "",
        duration: "",
        note: "",
      },
    };
  }
  return newPlaceDetail;
};

export const createMyPlace = (
  userId,
  placeDetail,
  transportationName,
  country,
  alpha2Code
) => {
  if (!placeDetail.category) placeDetail.category = "Activity";
  var newPlaceDetail = {};
  if (
    placeDetail.category === "Activity" ||
    placeDetail.category === "Restaurant" ||
    placeDetail.category === "Attraction"
  ) {
    newPlaceDetail = {
      _id: new ObjectID().toHexString(),
      belong_to_user: userId,
      country: country,
      alpha2Code: alpha2Code,
      category: placeDetail.category,
      data: {
        default_data: {
          is_actived: true,
          title: null,
          address: null,
          lat: null,
          lng: null,
          phone_number: null,
          booked_through: null,
          confirmation: null,
          provider: null,
          time_start: null,
          day_start: null,
          duration: null,
          timezone: null,
          amount: null,
          currency: null,
          descriptions: null,
          image: null,
          images: null,
        },
        actual_data: {
          is_actived: true,
          title: placeDetail.title,
          address: placeDetail.address,
          lat: placeDetail.lat,
          lng: placeDetail.lng,
          phone_number: placeDetail.phone_number,
          booked_through: placeDetail.booked_through,
          confirmation: placeDetail.confirmation,
          provider: placeDetail.provider,
          time_start: placeDetail.time_start,
          day_start: "",
          duration: placeDetail.duration,
          timezone: placeDetail.timezone,
          amount: placeDetail.amount,
          currency: placeDetail.currency,
          descriptions: placeDetail.descriptions,
          image: placeDetail.image,
          images: placeDetail.images,
        },
      },
      transportation: {
        name: transportationName,
        price: 0,
        distance: "",
        time: "",
        duration: "",
        note: "",
      },
    };
  } else if (
    placeDetail.category === "Lodging" ||
    placeDetail.category === "Transportation"
  ) {
    newPlaceDetail = {
      _id: new ObjectID().toHexString(),
      belong_to_user: userId,
      country: country,
      alpha2Code: alpha2Code,
      category: placeDetail.category,
      data: {
        default_data: {
          is_actived: true,
          type: null,
          title: null,
          address: null,
          lat: null,
          lng: null,
          phone_number: null,
          descriptions: null,
          booked_through: null,
          confirmation: null,
          time_start: null,
          day_start: null,
          duration: null,
          timezone: null,
          amount: null,
          currency: null,
          image: null,
          images: null,
        },
        actual_data: {
          is_actived: true,
          type: placeDetail.type,
          title: placeDetail.title,
          address: placeDetail.address,
          lat: placeDetail.lat,
          lng: placeDetail.lng,
          phone_number: placeDetail.phone_number,
          booked_through: placeDetail.booked_through,
          confirmation: placeDetail.confirmation,
          time_start: placeDetail.time_start,
          day_start: "",
          duration: placeDetail.duration,
          timezone: placeDetail.timezone,
          amount: placeDetail.amount,
          currency: placeDetail.currency,
          descriptions: placeDetail.descriptions,
          image: placeDetail.image,
          images: placeDetail.images,
        },
      },
      transportation: {
        name: transportationName,
        price: 0,
        distance: "",
        time: "",
        duration: "",
        note: "",
      },
    };
  }
  return newPlaceDetail;
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file && file.type.match("image.*")) {
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    }
  });
};

export const getBase64FromAvatar = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const formatSecondsToDuration = (secNum) => {
  var sec_num = parseInt(secNum, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);

  if (hours < 10 && hours > 0) {
    hours = "0" + hours;
  }
  if (minutes < 10 && minutes > 0) {
    minutes = "0" + minutes;
  }

  return (
    (hours ? hours + `${hours > 1 ? " hours " : " hour "}` : "") +
    (minutes ? minutes + `${minutes > 1 ? " minutes" : " minute"}` : "")
  );
};

export const convertSecondsToHours = (secNum) => {
  var sec_num = parseInt(secNum, 10);
  var hours = Math.floor(sec_num / 3600);
  return hours;
};

export const convertSecondsToMinutes = (secNum) => {
  var sec_num = parseInt(secNum, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  return minutes;
};

export const calcEndTime = (startTime, duration) => {
  let formatStartTime = moment(`${startTime}`, "HH:mm:ss").second(duration);
  return format24hTo12h(moment(formatStartTime._d, "HH:mm").format("HH:mm"));
};

export const formatLastUpdated = (date) => {
  var d = new Date(date);

  var datestring =
    ("0" + d.getDate()).slice(-2) +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    d.getFullYear() +
    " " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2);
  return datestring;
};

export const isMyResource = (url) => {
  if (!url) return false;
  if (url.search("images/") >= 0) return true;
  if (url.search("storage/uploads/") >= 0) return true;

  return false;
};

export const getImageFromUrl = (url, size) => {
  var domain = url
    .replace("http://", "")
    .replace("https://", "")
    .split(/[/?#]/)[0];
  if (domain === "lh3.googleusercontent.com") {
    var newUrl;
    if (size === "small") {
      newUrl = url.slice(0, url.length - 9) + "w360";
    }
    if (size === "medium") {
      newUrl = url.slice(0, url.length - 9) + "w720";
    }
    if (size === "large") {
      newUrl = url.slice(0, url.length - 9) + "w920";
    }
    if (size === "big") {
      newUrl = url.slice(0, url.length - 9) + "w1280";
    }
    return newUrl;
  } else {
    return url;
  }
};

export const getDateNow = () => {
  let today = new Date(),
    dd = today.getDate(),
    mm = today.getMonth() + 1,
    yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return yyyy + "-" + mm + "-" + dd;
};

export const getTimestamp = (time) => {
  const date = new Date(time);
  return (date.getTime() - date.getTimezoneOffset() * 60 * 1000) / 1000;
};

export const searchTree = (items, id) => {
  var i = 0,
    found,
    result = [];
  for (; i < items.length; i++) {
    if (items[i]._id === id) {
      result.push({ ...items[i], index: i });
    } else if (_.isArray(items[i].children)) {
      found = searchTree(items[i].children, id);
      if (found.length) {
        result = result.concat(found);
      }
    }
  }
  return result;
};

export const getAllLeaf = (items, result = []) => {
  for (var i = 0, length = items.length; i < length; i++) {
    if (!items[i].children) {
      result.push(items[i]);
    } else {
      result = getAllLeaf(items[i].children, result);
    }
  }
  return result;
};

export const updateTree = (items, item) => {
  return _.map(items, function (treeOne) {
    treeOne.children = _.map(treeOne.children, (treeTwo) => {
      treeTwo.children = _.map(treeTwo.children, (o) => {
        if (item._id === o._id) {
          o = item;
        }
        return o;
      });
      return treeTwo;
    });
    return treeOne;
  });
};

export const deleteLeaf = (key, tree) => {
  var index;
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].children) {
      if (tree[i].children.findIndex((item) => item._id === key) >= 0) {
        index = i;
        tree[i].children.splice(
          tree[i].children.findIndex((item) => {
            return item._id === key;
          }),
          1
        );
        return (index = i);
      } else {
        index = deleteLeaf(key, tree[i].children);
        if (index >= 0) {
          return {
            indexCountry: i,
            indexCity: index,
          };
        }
      }
    }
  }
};

export const getParentIndex = (key, tree) => {
  var index;
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].children) {
      if (tree[i].children.some((item) => item._id === key)) {
        return (index = i);
      } else {
        index = getParentIndex(key, tree[i].children);
        if (index >= 0) {
          return {
            indexCountry: i,
            indexCity: index,
          };
        }
      }
    }
  }
};

export const monthYearFormat = (
  month_day_year = new Date(),
  formatType = "MMM d, yyyy"
) => {
  return format(new Date(month_day_year), formatType);
};

export const setStartDateEndDate = (itinerary, flag = 0) => {
  if (getAllLeaf(itinerary.itinerary_detail).length === 1) {
    if (flag !== 1) {
      itinerary.itinerary_detail[0].children[0].children[0].month_day_year =
        itinerary.dates;
    }
    var lengthCountry = itinerary.itinerary_detail.length;
    var lengthCity =
      itinerary.itinerary_detail[lengthCountry - 1].children.length;
    var lengthDates =
      itinerary.itinerary_detail[lengthCountry - 1].children[lengthCity - 1]
        .children.length;
    if (flag !== 2) {
      itinerary.itinerary_detail[lengthCountry - 1].children[
        lengthCity - 1
      ].children[lengthDates - 1].month_day_year = itinerary.end_dates;
    }
  } else {
    itinerary.itinerary_detail[0].children[0].children[0].month_day_year =
      itinerary.dates;
    var lengthCountry = itinerary.itinerary_detail.length;
    var lengthCity =
      itinerary.itinerary_detail[lengthCountry - 1].children.length;
    var lengthDates =
      itinerary.itinerary_detail[lengthCountry - 1].children[lengthCity - 1]
        .children.length;
    if (
      !itinerary.itinerary_detail[lengthCountry - 1].children[lengthCity - 1]
        .children[lengthDates - 1].month_day_year
    )
      itinerary.itinerary_detail[lengthCountry - 1].children[
        lengthCity - 1
      ].children[lengthDates - 1].month_day_year = itinerary.end_dates;
  }

  return itinerary;
};

export const calcTotalAmount = (idItinerary) => {
  let itineraries;
  itineraries = loadItem("itineraries");
  let current = itineraries.findIndex((item) => {
    return item._id === idItinerary;
  });
  let total_amount = 0;
  getAllLeaf(itineraries[current].itinerary_detail).forEach((itemA) => {
    if (itemA.lodging && itemA.lodging.included) {
      if (itemA.lodging.amount) total_amount += +itemA.lodging.amount;
    }
    if (itemA.amountMeals && itemA.amountMeals.included) {
      if (itemA.amountMeals.breakfast)
        total_amount += +itemA.amountMeals.breakfast;
      if (itemA.amountMeals.lunch) total_amount += +itemA.amountMeals.lunch;
      if (itemA.amountMeals.dinner) total_amount += +itemA.amountMeals.dinner;
    }

    if (itemA.guide && itemA.guide.included) {
      if (itemA.guide.amount) total_amount += +itemA.guide.amount;
    }
    itemA.place_detail.forEach((itemB) => {
      if (+itemB.data.actual_data.amount > 0) {
        total_amount += +itemB.data.actual_data.amount;
      }
    });
  });
  itineraries[current].total_amount = total_amount;
  saveItem("itineraries", itineraries);
  return total_amount;
};

export const getNameItinerary = (itinerary) => {
  let estimated_budget = itinerary.estimated_budget
    ? ` on a ${formatMoney(itinerary.estimated_budget, {
        symbol: itinerary.currency_infor.symbol,
        precision: itinerary.currency_infor.decimal_digits,
        thousand: ",",
        decimal: ".",
      })}${" "}
${itinerary.currency_infor.code} budget`
    : "";

  let a_number_of_paxs = itinerary.a_number_of_paxs
    ? ` for ${itinerary.a_number_of_paxs} pax`
    : "";
  let result = itinerary.name + estimated_budget + a_number_of_paxs;
  return result;
};

export const moveOutDrafts = (idItinerary) => {
  if (loadItem("itineraryDraft")) {
    let draft;
    draft = loadItem("itineraryDraft");
    let current = draft.itineraries.findIndex((item) => {
      return item._id === idItinerary;
    });
    if (current >= 0) draft.itineraries.splice(current, 1);
    saveItem("itineraryDraft", draft);
  }
};

export const updateDraft = (idItinerary, itinerary) => {
  if (loadItem("itineraryDraft")) {
    let draft;
    draft = loadItem("itineraryDraft");
    let current = draft.itineraries.findIndex((item) => {
      return item._id === idItinerary;
    });
    if (current >= 0) {
      draft.itineraries[current] = itinerary;
      saveItem("itineraryDraft", draft);
    }
  }
};

export const changeDateUpInMiddle = (
  numberDaysAddMiddle,
  itinerary_detail,
  idItineraryDetail
) => {
  let index = getParentIndex(idItineraryDetail, itinerary_detail);
  var flag = false;
  // curren section
  var country = itinerary_detail[index.indexCountry];
  for (var j = index.indexCity; j < country.children.length; j++) {
    var city = country.children[j];
    for (var z = 0; z < city.children.length; z++) {
      var eachDate = city.children[z];
      if (flag) {
        if (eachDate.month_day_year) {
          eachDate.month_day_year = dateToTimestamp(
            addDays(
              new Date(eachDate.month_day_year * 1000),
              numberDaysAddMiddle
            )
          ).toString();
        }
      }
      if (eachDate._id === idItineraryDetail) flag = true;
    }
  }

  // next section
  for (var i = index.indexCountry + 1; i < itinerary_detail.length; i++) {
    var country = itinerary_detail[i];
    for (var j = 0; j < country.children.length; j++) {
      var city = country.children[j];
      for (var z = 0; z < city.children.length; z++) {
        var eachDate = city.children[z];
        if (eachDate.month_day_year) {
          eachDate.month_day_year = dateToTimestamp(
            addDays(
              new Date(eachDate.month_day_year * 1000),
              numberDaysAddMiddle
            )
          ).toString();
        }
      }
    }
  }
  return itinerary_detail;
};

export const changeDateDownInMiddle = (
  numberDaysAddMiddle,
  itinerary_detail,
  idItineraryDetail
) => {
  let index = getParentIndex(idItineraryDetail, itinerary_detail);

  var flag = true;
  // curren section
  // for (var i = 0; i <= index.indexCountry; i++) {
  //   var country = itinerary_detail[i];
  //   for (var j = index.indexCity; j < country.children.length; j++) {
  //     var city = country.children[j];
  //     for (var z = 0; z < city.children.length; z++) {
  //       var eachDate = city.children[z];
  //       if (flag) {
  //         if (eachDate.month_day_year) {
  //           eachDate.month_day_year = dateToTimestamp(
  //             addDays(
  //               new Date(eachDate.month_day_year * 1000),
  //               numberDaysAddMiddle
  //             )
  //           ).toString();
  //         }
  //       }
  //       if (eachDate._id === idItineraryDetail) {
  //         flag = false;
  //         return itinerary_detail
  //       }
  //     }
  //   }
  // }

  for (var i = 0; i <= itinerary_detail.length - 1; i++) {
    var country = itinerary_detail[i];
    for (var j = 0; j < country.children.length; j++) {
      var city = country.children[j];
      for (var z = 0; z < city.children.length; z++) {
        var eachDate = city.children[z];
        if (eachDate._id === idItineraryDetail) continue;
        if (eachDate.month_day_year) {
          eachDate.month_day_year = dateToTimestamp(
            addDays(
              new Date(eachDate.month_day_year * 1000),
              numberDaysAddMiddle
            )
          ).toString();
        }
      }
    }
  }
  return itinerary_detail;
};

export const setNewStartEndDate = (itinerary) => {
  itinerary.dates =
    itinerary.itinerary_detail[0].children[0].children[0].month_day_year;
  var lengthCountry = itinerary.itinerary_detail.length;
  var lengthCity =
    itinerary.itinerary_detail[lengthCountry - 1].children.length;
  var lengthDates =
    itinerary.itinerary_detail[lengthCountry - 1].children[lengthCity - 1]
      .children.length;
  itinerary.end_dates =
    itinerary.itinerary_detail[lengthCountry - 1].children[
      lengthCity - 1
    ].children[lengthDates - 1].month_day_year;
  return itinerary;
};

export const convertAdvanceFieldToNormal = (itinerary, user) => {
  if (user.accountType < AccountType.Pro) {
    for (var i = 0; i <= itinerary.itinerary_detail.length - 1; i++) {
      var country = itinerary.itinerary_detail[i];
      for (var j = 0; j < country.children.length; j++) {
        var city = country.children[j];
        for (var z = 0; z < city.children.length; z++) {
          var eachDate = city.children[z];
          delete eachDate.meal;
          delete eachDate.amountMeals;
          delete eachDate.guide;
          delete eachDate.lodging;
          eachDate.place_detail.forEach((item) => {
            if (
              item.category === "5ffff0d5f5ea7239eca9f010" ||
              item.category === "5ffff0d5f5ea7239eca9f00f" ||
              item.category === "5ffff0d5f5ea7239eca9f00d"
            ) {
              item.data.actual_data.booked_through = "";
              item.data.actual_data.confirmation = "";
              item.data.actual_data.time_start = "";
              item.data.actual_data.amount = "";
              item.data.actual_data.duration = "";
            } else if (
              item.category === "5ffff0d5f5ea7239eca9f00e" ||
              item.category === "5ffff0d5f5ea7239eca9f011"
            ) {
              item.data.actual_data.type = "";
              item.data.actual_data.booked_through = "";
              item.data.actual_data.confirmation = "";
              item.data.actual_data.time_start = "";
              item.data.actual_data.amount = "";
              item.data.actual_data.duration = "";
            }
          });
        }
      }
    }
  }
  return itinerary;
};

export const getFlag = (alpha2Code) => {
  return `https://www.countryflags.io/${alpha2Code}/flat/24.png`;
};

export const getNameAuthor = (user, company) => {
  return company ? (
    company.url ? (
      <a
        href={config.http + "//" + company.url + "." + config.domain}
        target="blank"
      >
        <span className="author__name">
          {company.name
            ? company.name
              ? company.name
              : company.short_name
              ? company.short_name
              : null
            : `${user.firstName} ${user.lastName}`}
        </span>
      </a>
    ) : (
      <span className="author__name">
        {company.name
          ? company.name
            ? company.name
            : company.short_name
            ? company.short_name
            : null
          : `${user.firstName} ${user.lastName}`}
      </span>
    )
  ) : null;
};

export const formatViews = (num, digits) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};
