import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

import { closeFormQuote } from "../../store/actions";
import { connect } from "react-redux";
import styles from "./index.scss";
import { loadItem, saveItem } from "../../services/storage.service.js";
import { searchTree, updateTree } from "../../services/utils.service";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const mapStateToProps = state => {
  return {
    isDisplayFormQuote: state.formQuoteReducer.open,
    item: state.formQuoteReducer.item
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeFormQuote: () => {
      dispatch(closeFormQuote());
    }
  };
};

class FormQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
        quote: ""
    };
  }

  handleCloseQuote = () => {
      this.props.closeFormQuote()
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.isDisplayFormQuote && nextProps.item) {
        this.setState({
            quote: nextProps.item.quote
        })
    }
  }

  handleAddEditQuote = () => {
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex(item => {
      return item._id === this.props.params.idItinerary;
    });

    let tempItem = searchTree(
      itineraries[current].itinerary_detail,
      this.props.params.idItineraryDetail
    )[0];
    let currentPlace = tempItem.place_detail.findIndex(item => {
        return item._id === this.props.item._id
    })

    tempItem.place_detail[currentPlace].quote = this.state.quote;
    let updated = updateTree(
      itineraries[current].itinerary_detail,
      tempItem
    );
    itineraries[current].itinerary_detail = updated;
    saveItem("itineraries", itineraries);
    this.props.newList(6, tempItem.place_detail);
    this.props.closeFormQuote()
  };

  handleRemoveQuote = () => {
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex(item => {
      return item._id === this.props.params.idItinerary;
    });

    let tempItem = searchTree(
      itineraries[current].itinerary_detail,
      this.props.params.idItineraryDetail
    )[0];
    let currentPlace = tempItem.place_detail.findIndex(item => {
        return item._id === this.props.item._id
    })

    tempItem.place_detail[currentPlace].quote = ""
    let updated = updateTree(
      itineraries[current].itinerary_detail,
      tempItem
    );
    itineraries[current].itinerary_detail = updated;
    saveItem("itineraries", itineraries);
    this.props.newList(6, tempItem.place_detail);
    this.props.closeFormQuote()
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.isDisplayFormQuote ? true : false}
        fullWidth={true}
        maxWidth={"xs"}
        aria-labelledby="form-quote"
        className={styles.formQuote}
      >
        <MuiDialogTitle className="popup-title" disableTypography>
          <Typography variant="h6">Share your experience</Typography>
          <IconButton
            aria-label="close"
            className="btn-close"
            onClick={this.handleCloseQuote.bind(this)}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <div style={{ padding: `15px` }}>
          <TextField
            id="outlined-multiline-static"
            multiline
            fullWidth
            value={this.state.quote}
            variant="outlined"
            onChange={this.handleChange("quote")}
          />
          <div
            style={
              this.state.quote
                ? {
                    marginTop: "1rem",
                    display: `flex`,
                    justifyContent: `space-between`
                  }
                : {
                    marginTop: "1rem",
                    textAlign: "end"
                  }
            }
          >
            {this.state.quote && (
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handleRemoveQuote.bind(this)}
                >
                  Remove
                </Button>
              </div>
            )}
            <div>
              <Button
                onClick={this.handleAddEditQuote.bind(this)}
                className="btn-primary"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormQuote);
