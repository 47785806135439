import React from "react";
import {Descriptions, Icon} from "antd";
import MoneyHelper from "../../helper/moneyHelper";
import styles from "./index.scss";
import {connect} from "react-redux";

const Overview = ({itinerary, isPreview, ...props}) => {
    return (
        <div className={'overview '+styles.overBase}>
            <Descriptions column={4} size="small">
                <Descriptions.Item label={<b><Icon type="user"/>People</b>}>{itinerary.a_number_of_paxs}</Descriptions.Item>
                <Descriptions.Item label={<b><Icon type="dashboard" />Duration</b>}>{props.listAllDate.length + ' day'+(props.listAllDate.length>1 ? 's' : null)}</Descriptions.Item>
                <Descriptions.Item label={<b><Icon type="dollar" />Budget</b>}>{MoneyHelper(itinerary.estimated_budget).format({
                    symbol: props.currency_infor.symbol,
                    precision: props.currency_infor.decimal_digits,
                })} {props.currency_infor.code}</Descriptions.Item>
                {(props.profile && props.profile._id === itinerary.belong_to_user) || isPreview ? (
                    <Descriptions.Item label={<b>Expenses</b>}>{MoneyHelper(itinerary.total_amount).format({
                        symbol: props.currency_infor.symbol,
                        precision: props.currency_infor.decimal_digits,
                    })} {props.currency_infor.code}</Descriptions.Item>
                ) : null}
            </Descriptions>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        currency_infor: state.itineraryReducer.currency_infor,
    };
};

export default connect(mapStateToProps, null)(Overview);
