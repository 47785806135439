import React from "react";

import MainMenu from "../../../components/menu-main";
import MenuLogin from "../../../components/menu-login";

function MenuCompany(props) {
  return (
    <div className="header tyneri-container">
      <div className="main-menu-subdomain">
        <MainMenu hideSearchBar={true} />
      </div>
      <div className="menu-container">
        <MenuLogin pageCompany={true} />
        {/* <ul>
          <li>
            <CreateTyneriButton />
          </li>
        </ul> */}
      </div>
    </div>
  );
}

export default MenuCompany;
