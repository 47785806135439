/*global google*/
import React, { Component } from "react";
import { MdAddCircleOutline, MdCheckCircle } from "react-icons/md";

import styles from "./index.scss";
import ApiService from "../../services/api.service";
import { loadItem, saveItem } from "../../services/storage.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { createPlaceDetailFromExplore, searchTree } from "../../services/utils.service";
import _ from "lodash"
import { categoriesDefined } from "../../constants/categories";

const mapStateToProps = state => {
  return {
    selected2: state.listPlacesExploreReducer,
    currency: state.itineraryReducer.currency
  };
};

class PlacesInExplore extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      btnDropleft: false,
      open: false,
      categoryTab: 1,
      isLoading: false,
      isChoosen: false
    };
  }

  handleViewAndEditPlace = () => {
    if (!this.state.open) {
      this.setState({
        open: true
      });
    }
  };

  handleCloseViewAndEditPlace = () => {
    this.setState({
      open: false
    });
  };

  handleChangeActivityTab = () => {
    this.setState({
      categoryTab: 1
    });
  };

  handleChangeLodgingTab = () => {
    this.setState({
      categoryTab: 2
    });
  };

  handleAddToListSelected = async () => {
    var image = null;
    if (this.props.item.photos) {
      await this.apiService.getImg(this.props.item.place_id).then(res => {
        if (res.status === "success") {
          image = res.img;
        } else {
          image = null;
        }
      });
    }

    this.setState({
      isLoading: true
    });

    var placeDetail;
    var status = this.props.status;

    if (status === "tourist_attraction") {
      let temp = _.find(this.props.categories, (category) => {
        return category.name === categoriesDefined.ATTRACTION;
      });
      status = temp._id;
    }

    if (status === "lodging") {
      let temp = _.find(this.props.categories, (category) => {
        return category.name === categoriesDefined.ACCOMMODATION;
      });
      status = temp._id;
    }
    if (status === "restaurant") {
      let temp = _.find(this.props.categories, (category) => {
        return category.name === categoriesDefined.RESTAURANT;
      });
      status = temp._id;
    }
    if (status === "travel_agency") {
      let temp = _.find(this.props.categories, (category) => {
        return category.name === categoriesDefined.TOURACTIVITY;
      });
      status = temp._id;
    }
    if (status === "transit_station") {
      let temp = _.find(this.props.categories, (category) => {
        return category.name === categoriesDefined.TRANSPORTATION;
      });
      status = temp._id;
    }

    if (!this.props.item.vicinity) {
      placeDetail = {
        name: this.props.item.name,
        vicinity: this.props.item.formatted_address,
        lat: "",
        lng: "",
        images: image,
        status: status
      };
    } else {
      placeDetail = {
        name: this.props.item.name,
        vicinity: this.props.item.vicinity,
        lat: "",
        lng: "",
        images: image,
        status: status
      };
    }
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: placeDetail.vicinity }, async (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        placeDetail = {
          ...placeDetail,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        let itineraries;
        itineraries = loadItem("itineraries");
        let current = itineraries.findIndex((item) => {
          return item._id === this.props.params.idItinerary;
        });

        var request = {
          placeId: this.props.item.place_id,
          fields: [
            "address_component",
            "adr_address",
            "formatted_address",
            "geometry",
            "icon",
            "name",
            "photo",
            "place_id",
            "plus_code",
            "type",
            "url",
            "utc_offset_minutes",
            "vicinity",
            "formatted_phone_number",
            "international_phone_number",
            "opening_hours",
            "website",
            "price_level",
            "rating",
            "review",
            "user_ratings_total",
          ],
        };

        var service = new google.maps.places.PlacesService(
          document.createElement("div")
        );

        await service.getDetails(request, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (place.formatted_phone_number) {
              placeDetail = {
                ...placeDetail,
                place_id: place.place_id,
                vendor: "google",
                phone_number: place.formatted_phone_number,
              };

              let newPlaceDetail = createPlaceDetailFromExplore(
                -1,
                placeDetail,
                this.props.params.idItineraryDetail,
                this.props.currency,
                itineraries[current].transportation,
                this.props.categories
              );
              let tempItem = searchTree(
                itineraries[current].itinerary_detail,
                this.props.params.idItineraryDetail
              )[0];
              tempItem.place_detail.push({...newPlaceDetail, isNew: true});
              tempItem.place_detail.forEach((item, index) => {
                item.order = index + 1;
              });
              saveItem("itineraries", itineraries);
              this.props.newList(9);
              this.setState({
                isLoading: false,
                isChoosen: true,
              });
            } else {
              placeDetail = {
                ...placeDetail,
                vendor: "google",
                place_id: place.place_id,
                phone_number: "",
              };

              let newPlaceDetail = createPlaceDetailFromExplore(
                -1,
                placeDetail,
                this.props.params.idItineraryDetail,
                this.props.currency,
                itineraries[current].transportation,
                this.props.categories
              );
              let tempItem = searchTree(
                itineraries[current].itinerary_detail,
                this.props.params.idItineraryDetail
              )[0];
              tempItem.place_detail.push({...newPlaceDetail, isNew: true});
              tempItem.place_detail.forEach((item, index) => {
                item.order = index + 1;
              });
              saveItem("itineraries", itineraries);
              this.props.newList(9);
              this.setState({
                isLoading: false,
                isChoosen: true,
              });
            }
          }
        });
      } else {
        placeDetail = {
          ...placeDetail,
          lat: "",
          lng: "",
        };
        let itineraries;
        itineraries = loadItem("itineraries");
        let current = itineraries.findIndex((item) => {
          return item._id === this.props.params.idItinerary;
        });

        var request = {
          placeId: this.props.item.place_id,
          fields: [
            "address_component",
            "adr_address",
            "formatted_address",
            "geometry",
            "icon",
            "name",
            "photo",
            "place_id",
            "plus_code",
            "type",
            "url",
            "utc_offset_minutes",
            "vicinity",
            "formatted_phone_number",
            "international_phone_number",
            "opening_hours",
            "website",
            "price_level",
            "rating",
            "review",
            "user_ratings_total",
          ],
        };

        var service = new google.maps.places.PlacesService(
          document.createElement("div")
        );

        await service.getDetails(request, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (place.formatted_phone_number) {
              placeDetail = {
                ...placeDetail,
                place_id: place.place_id,
                vendor: "google",
                phone_number: place.formatted_phone_number,
              };

              let newPlaceDetail = createPlaceDetailFromExplore(
                -1,
                placeDetail,
                this.props.params.idItineraryDetail,
                this.props.currency,
                itineraries[current].transportation,
                this.props.categories
              );
              let tempItem = searchTree(
                itineraries[current].itinerary_detail,
                this.props.params.idItineraryDetail
              )[0];
              tempItem.place_detail.push({...newPlaceDetail, isNew: true});
              tempItem.place_detail.forEach((item, index) => {
                item.order = index + 1;
              });
              saveItem("itineraries", itineraries);
              this.props.newList(9);
              this.setState({
                isLoading: false,
                isChoosen: true,
              });
            } else {
              placeDetail = {
                ...placeDetail,
                vendor: "google",
                place_id: place.place_id,
                phone_number: "",
              };

              let newPlaceDetail = createPlaceDetailFromExplore(
                -1,
                placeDetail,
                this.props.params.idItineraryDetail,
                this.props.currency,
                itineraries[current].transportation,
                this.props.categories
              );
              let tempItem = searchTree(
                itineraries[current].itinerary_detail,
                this.props.params.idItineraryDetail
              )[0];
              tempItem.place_detail.push({...newPlaceDetail, isNew: true});
              tempItem.place_detail.forEach((item, index) => {
                item.order = index + 1;
              });
              saveItem("itineraries", itineraries);
              this.props.newList(9);
              this.setState({
                isLoading: false,
                isChoosen: true,
              });
            }
          }
        });
      }
    });
  };

  componentDidMount = () => {
    var photo = document.getElementsByClassName("small-view");
    for (let i = 0; i < photo.length; i++) {
      if (this.props.selected2.list[i].photos) {
        var src = this.props.selected2.list[i].photos[0].getUrl();
        photo[i].style.backgroundImage = "url('" + src + "')";
      }
    }
  };

  render() {
    const { item } = this.props;
    return (
      <div className={styles.placesInExplore}>
        <div onClick={this.handleViewAndEditPlace.bind(this)}>
          <div className="sidebar-idea-container ember-view draggable-item zoom-place">
            <div className="ember-view component-idea-tile idea-tile-horizontal _can-view-details">
              <figure className="ember-view small-view">
                <div className="btn _btn-small _btn-outline-white"> View </div>
              </figure>
              <ul className="_c-info">
                <li>
                  <span>{item.name}</span>
                </li>
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.vicinity
                        ? item.vicinity
                        : item.formatted_address
                    }}
                  ></span>
                </li>
              </ul>
              <div
                className="click-to-add"
                onClick={this.handleAddToListSelected}
              >
                {this.state.isLoading ? (
                  <CircularProgress thickness={7} />
                ) : this.state.isChoosen ? (
                  <MdCheckCircle className="isChecked" />
                ) : (
                  <MdAddCircleOutline />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(PlacesInExplore);
