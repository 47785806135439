import React, { Component } from "react";
import ApiService from "../../services/api.service";
import { Container } from "reactstrap";
import styles from "./index.scss";
import { connect } from "react-redux";
import { compareAsc } from "date-fns";
import { Empty, Tabs } from "antd";
import {
  saveProfile,
  logout,
  closeFormLoad,
  saveCurrentItinerary,
} from "../../store/actions";
import { saveItem } from "../../services/storage.service";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { FormSignInUp } from "../../components";
// import ListPast from "./list-past";
// import ListUpcoming from "./list-upcoming";
// import ListInProgress from "./list-in-progress";
import ListLibraries from "./list-libraries";
import ListFolder from "../../module/admin/components/my-library/listFolder/listFolder";

const { TabPane } = Tabs;

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    closeFormLoad: () => {
      dispatch(closeFormLoad());
    },
    saveCurrentItinerary: (itinerary) => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

class ListMyTyneries extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: true,
      redirect: false,
      itinerary: {},
      listUpcoming: [],
      listInProgress: [],
      listPast: [],

      listReferencesUpcoming: [],
      listReferencesInProgress: [],
      listReferencesPast: [],

      listLibraries: [],

      isLoadingEditUpcomingButton: false,
      isLoadingEditInProgressButton: false,
      isLoadingEditPassButton: false,

      isLoadingRemoveButton: false,
      showFormLogin: false,
    };
  }

  componentDidMount = () => {
    var tempListUpcoming = [],
      tempListProgress = [],
      tempListPast = [];

    if (this.props.profile) {
      this.setState({
        isLoading: true,
      });
      this.apiService.getMyTyneries().then((res) => {
        if (res.status === "success") {
          res.my_tyneries.forEach((item) => {
            if (this.isUpcoming(item.dates)) {
              tempListUpcoming.push(item);
            } else if (this.isPast(item.end_dates)) tempListPast.push(item);
            else tempListProgress.push(item);
          });
          this.setState({
            listUpcoming: tempListUpcoming,
            listInProgress: tempListProgress,
            listPast: tempListPast,
            isLoading: false,
          });
        }
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.profile) {
      this.setState({
        showFormLogin: false,
      });
    }
    if (!this.props.profile && nextProps.profile) {
      this.setState({
        isLoading: true,
      });
      this.apiService.getMyTyneries().then((res) => {
        let tempListUpcoming = [...this.state.listUpcoming],
          tempListProgress = [...this.state.listInProgress],
          tempListPast = [...this.state.listPast];
        if (res.status === "success") {
          res.my_tyneries.forEach((item) => {
            if (this.isUpcoming(item.dates)) {
              tempListUpcoming.push(item);
            } else if (this.isPast(item.end_dates)) tempListPast.push(item);
            else tempListProgress.push(item);
          });
          this.setState({
            listUpcoming: tempListUpcoming,
            listInProgress: tempListProgress,
            listPast: tempListPast,
            isLoading: false,
          });
        }
      });
    } else if (this.props.profile && !nextProps.profile) {
      this.setState({
        isLoading: true,
      });
      let tempListUpcoming = [...this.state.listUpcoming],
        tempListProgress = [...this.state.listInProgress],
        tempListPast = [...this.state.listPast];
      tempListUpcoming.forEach((item, index) => {
        if (!(item.hasOwnProperty("saved") && !item.saved)) {
          tempListUpcoming.splice(index, 1);
        }
      });
      tempListProgress.forEach((item, index) => {
        if (!(item.hasOwnProperty("saved") && !item.saved)) {
          tempListProgress.splice(index, 1);
        }
      });
      tempListPast.forEach((item, index) => {
        if (!(item.hasOwnProperty("saved") && !item.saved)) {
          tempListPast.splice(index, 1);
        }
      });
      let itineraries = [];
      itineraries.push(...tempListUpcoming);
      itineraries.push(...tempListProgress);
      itineraries.push(...tempListPast);
      saveItem("itineraries", itineraries);
      this.setState({
        listUpcoming: tempListUpcoming,
        listInProgress: tempListProgress,
        listPast: tempListPast,
        isLoading: false,
      });
    }
  };

  isUpcoming = (startDate) => {
    if (compareAsc(new Date(startDate * 1000), new Date()) === 1) return true;
    return false;
  };

  isPast = (endDate) => {
    if (compareAsc(new Date(endDate * 1000), new Date()) === -1) return true;
    return false;
  };

  showFormLogin = () => {
    this.setState({
      showFormLogin: true,
    });
  };

  closeFormLogin = () => {
    this.setState({
      showFormLogin: false,
    });
  };

  callback = (key) => {
    if (key === "2") {
      if (this.props.profile) {
        this.apiService.getListLibraries().then((res) => {
          if (res.status === "success") {
            this.setState({
              listLibraries: res.list_libraries,
            });
          }
        });
      }
    }
  };

  render() {
    return this.state.isLoading ? (
      <DialogContent
        dividers
        style={this.state.isLoading && { minHeight: "300px" }}
      >
        <div className={styles.loader}></div>
      </DialogContent>
    ) : (
      <React.Fragment>
        <Dialog
          open={this.state.showFormLogin ? true : false}
          onClose={this.closeFormLogin.bind(this)}
          fullWidth={true}
          maxWidth={"xs"}
          aria-labelledby="form-login-social-media my-tyneris"
        >
          <FormSignInUp closeForm={this.closeFormLogin}/>
        </Dialog>

        <DialogContent dividers>
          <Tabs
            className={" card-custom " + "library-tab"}
            defaultActiveKey="1"
            onChange={this.callback}
          >
            <TabPane tab="My Tyneri" key="1">
              {!this.props.profile ? (
                <div
                  style={{ textAlign: "center" }}
                  className={styles.listMyTyneries}
                >
                  <div>
                    Please{" "}
                    <span
                      className="btn-login"
                      onClick={this.showFormLogin.bind(this)}
                    >
                      Sign in
                    </span>{" "}
                    to show your saved itineraries.
                  </div>
                </div>
              ) : (
                <Container className={styles.listMyTyneries} fluid={true}>
                  <ListFolder />
                </Container>
              )}
            </TabPane>
            <TabPane tab="My Places" key="2">
              {!this.props.profile ? (
                <div
                  style={{ textAlign: "center" }}
                  className={styles.listMyTyneries}
                >
                  <div>
                    Please{" "}
                    <span
                      className="btn-login"
                      onClick={this.showFormLogin.bind(this)}
                    >
                      Sign in
                    </span>{" "}
                    to show your libraries.
                  </div>
                </div>
              ) : (
                <Container className={styles.listMyTyneries} fluid={true}>
                  <ListLibraries listLibraries={this.state.listLibraries} />
                </Container>
              )}
            </TabPane>
          </Tabs>
        </DialogContent>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListMyTyneries);
