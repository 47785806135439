import React, {useState} from "react";
import { Card, Avatar } from "antd";
import "./index.scss";
import {
    getImageFromUrl,
    isMyResource,
    getNameAuthor,
} from "../../../services/utils.service";
import NoPhoto from "../../../assets/media/images/no-photo.png";
import { configBaseUrl, config } from "../../../config";
import defaultImg from "../../../assets/media/images/generic-activities-img.jpg";
import { Link } from "react-router-dom";
import ImageHelper from "../../../functions/image";
const { Meta } = Card;

const CardTyneri = (props) => {
    const { item } = props;
    const [avatar, setAvatar] = useState(item.company && item.company.url && item.company.name
        ? configBaseUrl.baseURL + item.company.logo
        : configBaseUrl.baseURL + item.user.avatar);
    return (
        <Card
            className="card-tyneri"
            cover={
                <Link to={`view-top-list/${item._id}`}>
                    <ImageHelper
                        notFound={defaultImg}
                        alt="example"
                        src={
                            item.cover
                                ? getImageFromUrl(
                                isMyResource(item.cover)
                                    ? configBaseUrl.baseURL + item.cover
                                    : item.cover,
                                "large"
                                )
                                : defaultImg
                        }
                    />
                </Link>
            }
        >
            <div className="user-info">
                {item.company.url ? (
                    <a href={config.http + "//" + item.company.url + "." + config.domain} target="blank">
                        <Avatar
                            size={64}
                            className="avatar-custom"
                            src={avatar}
                            onError={() => setAvatar(NoPhoto)}
                        />
                    </a>
                ) : (
                    <Avatar
                        size={64}
                        className="avatar-custom"
                        src={avatar}
                        onError={() => setAvatar(NoPhoto)}
                    />
                )}

                <span>By {getNameAuthor(item.user, item.company)}</span>
            </div>
            <Meta
                title={<Link to={`view-top-list/${item._id}`}>{item.title}</Link>}
                description={
                    <div
                        dangerouslySetInnerHTML={{
                            __html: item.descriptions,
                        }}
                    ></div>
                }
            />
        </Card>
    );
};

export default CardTyneri;
