import React, { Component } from "react";
import { Row, Col } from "antd";
import { CountryCard } from "../../../components";

class ListCountry extends Component {
  render() {
    return (
      <Row className="row-itineraries">
        <div className="grid-header">
          <span>Top destinations</span>
        </div>
        <Col md={24} xl={24} xxl={24}>
          {this.props.list && !!this.props.list.length ? (
            <Row gutter={[16, 16]}>
              {" "}
              {this.props.list.map((item, index) => {
                return (
                  <Col key={index} md={12} lg={12} xl={6} xxl={6}>
                    <CountryCard item={item} />
                  </Col>
                );
              })}
            </Row>
          ) : null}
        </Col>
      </Row>
    );
  }
}

export default ListCountry;
