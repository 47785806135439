import React, { useState } from "react";
import { Button, Row, Col, Form, Input, DatePicker } from "antd";
import moment from "moment";
import {
  EnvironmentOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useFieldArray, Controller } from "react-hook-form";

import Lodging from "./lodging";
import Meals from "./meals";
import Guide from "./guide";
import Stops from "./stops";
import EditorCustom from "./editorCustom/WYSIWYG";

import "./index.scss";

function QuotationDetail({
  control,
  register,
  setValue,
  getValues,
  currency,
  dayDataDefault,
}) {
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: "days",
  });

  const [expand, setExpand] = useState([]);

  function handleExpand(id) {
    let expandTemp = [...expand];
    if (expandTemp.includes(id))
      expandTemp = expandTemp.filter((item) => item !== id);
    else expandTemp.push(id);
    setExpand(expandTemp);
  }

  function dayInput(item, index) {
    const dayDetailCol = {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 17,
      },
    };

    return (
      <div className="day-input__header">
        <div className="expand-click" onClick={() => handleExpand(index)}>
          <h4>{"Day " + (index + 1)}</h4>
          <DeleteOutlined
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={() => remove(index)}
          />
        </div>
        <div
          className="day-input__expand-content"
          style={{ display: expand.includes(index) ? "block" : "none" }}
        >
          <Row className="day-input__day-section">
            <Col xs={24} sm={24} md={12} lg={12} className="day-input__left">
              <div className="day-input__name">
                <div className="day-input__day-section__day">
                  <Form.Item
                    label={"Day title"}
                    labelCol={{ span: 4, offset: 0 }}
                    wrapperCol={{ span: 20, offset: 0 }}
                  >
                    <Controller
                      control={control}
                      name={`days[${index}].name`}
                      defaultValue={item.name}
                      render={({ value, onChange }) => (
                        <Input
                          placeholder="Day title"
                          defaultValue={item.name}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={6}
              lg={6}
              className="day-input__day-section__center"
            >
              <span className="day-input__day-section__address">
                <Form.Item label={null} wrapperCol={{ span: 24, offset: 3 }}>
                  <Controller
                    control={control}
                    name={`days[${index}].address`}
                    defaultValue={item.address}
                    render={({ value, onChange }) => (
                      <Input
                        placeholder="Address"
                        defaultValue={item.address}
                        onChange={onChange}
                        addonAfter={<EnvironmentOutlined />}
                      />
                    )}
                  />
                </Form.Item>
              </span>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={4}
              lg={6}
              className="day-input__day-section__right"
            >
              <span className="day-input__day-section__date">
                <Form.Item label={null} wrapperCol={{ span: 24, offset: 4 }}>
                  <Controller
                    control={control}
                    name={`days[${index}].date`}
                    defaultValue={item.date}
                    render={({ value, onChange }) => (
                      <DatePicker
                        defaultValue={moment(item.date)}
                        onChange={(value) =>
                          onChange(value ? value.format() : null)
                        }
                        format="LL"
                        placeholder="Date"
                      />
                    )}
                  />
                </Form.Item>
              </span>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                label={<span style={{ marginRight: "25px" }}>Description</span>}
                labelCol={{ span: 2, offset: 0 }}
                wrapperCol={{ span: 22, offset: 0 }}
              >
                <Controller
                  control={control}
                  name={`days[${index}].description`}
                  defaultValue={item.description ? item.description : ""}
                  as={
                    <EditorCustom
                      initialValue={item.description ? item.description : ""}
                    />
                  }
                />
                <Row className="day-input__day-section" type="flex">
                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    className="day-input__day-section__lodging"
                  >
                    <Lodging
                      nestIndex={index}
                      {...{ control, register }}
                      layout={dayDetailCol}
                      Controller={Controller}
                      dayData={item}
                      currency={currency}
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    className="day-input__day-section__meal"
                  >
                    <Meals
                      nestIndex={index}
                      {...{ control, register }}
                      layout={dayDetailCol}
                      Controller={Controller}
                      dayData={item}
                      currency={currency}
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    className="day-input__day-section__guide"
                  >
                    <Guide
                      nestIndex={index}
                      {...{ control, register }}
                      layout={dayDetailCol}
                      Controller={Controller}
                      dayData={item}
                      currency={currency}
                    />
                  </Col>
                </Row>

                <Stops
                  nestIndex={index}
                  control={control}
                  register={register}
                  dayData={item}
                  currency={currency}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  function DayInputContainer() {
    return fields.map((item, index) => {
      return (
        <div className="day-input" key={item.id}>
          {dayInput(item, index)}
        </div>
      );
    });
  }

  return (
    <>
      {DayInputContainer()}
      <div className="add-day">
        <Button
          type="primary"
          onClick={() =>
            append({
              dayDataDefault,
            })
          }
        >
          <PlusOutlined />
          Add a new day
        </Button>
      </div>
    </>
  );
}

export default QuotationDetail;
