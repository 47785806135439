import React, { Component } from "react";
import { Row, Col, Badge, FormGroup } from "reactstrap";
import styles from "./index.scss";
import {
  stopUpdateListItineraryDetail,
  updateTotalDay,
  updateTotalAmount,
  handleSlickGoTo,
} from "../../store/actions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { format, compareAsc, addDays } from "date-fns";
import ApiService from "../../services/api.service";
import {
  dateToTimestamp,
  searchTree,
  deleteLeaf,
  getAllLeaf,
  getParentIndex,
  getItemStyle,
  getListStyle,
  reorder,
  setStartDateEndDate,
  setNewStartEndDate,
  calcTotalAmount,
  getFlag
} from "../../services/utils.service";
import AddIcon from "@material-ui/icons/Add";
import { loadItem, saveItem } from "../../services/storage.service";
import { ObjectID } from "bson";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { EditDayTitleChangeDate } from "../../components";
import {Tree, Modal, Form, Radio, Popconfirm} from "antd";
import { withRouter } from "react-router";
import { isMobileOnly } from "react-device-detect";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { GrDrag } from "react-icons/gr";
import { MdCheck, MdSwapVert } from "react-icons/md";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { LeftOutlined } from "@ant-design/icons";
import _ from "lodash";

const { DirectoryTree, TreeNode } = Tree;
const mapStateToProps = (state) => {
  return {
    update: state.itineraryDetailReducer.open,
    typeUpdate: state.itineraryDetailReducer.typeUpdate,
    itinerary: state.itineraryReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    stopUpdateListItineraryDetail: () => {
      dispatch(stopUpdateListItineraryDetail());
    },
    updateTotalDay: (day) => {
      dispatch(updateTotalDay(day));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    handleSlickGoTo: (index) => {
      dispatch(handleSlickGoTo(index));
    },
  };
};

let final = [];

class ItineraryDetails extends Component {
  //_isMounted = false;

  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      original_itineraries_detail: [],
      itineraries_detail: [],
      isLoading: false,
      redirect: false,
      showConfirmDelete: false,

      editDayTitleChangeDate: false,
      finalGroup: [],

      listDragDropCity: [],
      show: 1,
      index: 0,
      showPopupMergeUnknownLocation: false,
      listCityMerge: [],
      listDatesUnknownLocation: [],
      tempDragDropCountry: [],
    };
  }

  compareLastDateCurrentVsFirstDateNext = (currentDateAdd, itinerary_detail, idItineraryDetail, index, alpha2Code, country, city) => {
    var flag = false

    var tempDate = ""

    var tempCountry = country
    var tempCity = city

    // curren section
    var country = itinerary_detail[index.indexCountry];
    for (var j = index.indexCity; j < country.children.length; j++) {
      var city = country.children[j];
      if (tempDate)
        break
      for (var z = 0; z < city.children.length; z++) {
        var eachDate = city.children[z];
        if (flag) {
          if (eachDate.month_day_year) {
            tempDate = eachDate.month_day_year
            break
          }
        }
        if (eachDate._id === idItineraryDetail)
          flag = true
      }
    }

    if (!tempDate) {
      // next section
      for (var i = index.indexCountry + 1; i < itinerary_detail.length; i++) {
        var country = itinerary_detail[i];
        if (tempDate)
          break
        for (var j = 0; j < country.children.length; j++) {
          var city = country.children[j];
          if (tempDate)
            break
          for (var z = 0; z < city.children.length; z++) {
            var eachDate = city.children[z];
            if (eachDate.month_day_year) {
              if (eachDate.month_day_year) {
                tempDate = eachDate.month_day_year
                break
              }
            }
          }
        }
      }
    }

    let resultOfCompare = compareAsc(new Date(currentDateAdd * 1000), new Date(tempDate * 1000))
    let newDate = {
      month_day_year: currentDateAdd,
      place_detail: [],
      title: "",
      _id: new ObjectID().toHexString(),
      country: tempCountry,
      alpha2Code: alpha2Code,
      city: tempCity,
    }
    itinerary_detail[index.indexCountry].children[
      index.indexCity
    ].children.push(newDate);
    if (resultOfCompare > 0) {
      itinerary_detail = this.autoIncreaseDates(itinerary_detail, newDate._id, index)
    }
    return itinerary_detail
  }

  autoIncreaseDates = (itinerary_detail, idItineraryDetail, index) => {
    var flag = false;

    // current section
    var country = itinerary_detail[index.indexCountry];
    for (var j = index.indexCity; j < country.children.length; j++) {
      var city = country.children[j];
      for (var z = 0; z < city.children.length; z++) {
        var eachDate = city.children[z];
        if (flag) {
          if (eachDate.month_day_year) {
            eachDate.month_day_year = dateToTimestamp(
              addDays(
                new Date(eachDate.month_day_year * 1000),
                1
              )
            ).toString();
          }
        }
        if (eachDate._id === idItineraryDetail) flag = true;
      }
    }

    // next section
    for (var i = index.indexCountry + 1; i < itinerary_detail.length; i++) {
      var country = itinerary_detail[i];
      for (var j = 0; j < country.children.length; j++) {
        var city = country.children[j];
        for (var z = 0; z < city.children.length; z++) {
          var eachDate = city.children[z];
          if (eachDate.month_day_year) {
            if (eachDate.month_day_year) {
              eachDate.month_day_year = dateToTimestamp(
                addDays(
                  new Date(eachDate.month_day_year * 1000),
                  1
                )
              ).toString();
            }
          }
        }
      }
    }

    return itinerary_detail;
  }

  handleAddNewDay = async (alpha2Code, country, city, _id) => {
    var itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });

    let index = getParentIndex(_id, itineraries[current].itinerary_detail);

    let currentDay =
      itineraries[current].itinerary_detail[index.indexCountry].children[
        index.indexCity
      ].children[
        itineraries[current].itinerary_detail[index.indexCountry].children[
          index.indexCity
        ].children.length - 1
      ].month_day_year;

    if (!currentDay) {
      itineraries[current].itinerary_detail[index.indexCountry].children[
        index.indexCity
      ].children.push({
        month_day_year: "",
        place_detail: [],
        title: "",
        _id: new ObjectID().toHexString(),
        country: country,
        alpha2Code: alpha2Code,
        city: city,
      });
    } else {
      var tempCurrentDay = currentDay
      currentDay = new Date(currentDay * 1000);
      let nextDay = new Date(currentDay);
      nextDay.setDate(currentDay.getDate() + 1);
      let temp = dateToTimestamp(nextDay);
      temp = temp.toString();
      if (tempCurrentDay === getAllLeaf(itineraries[current].itinerary_detail)[getAllLeaf(itineraries[current].itinerary_detail).length - 1].month_day_year) {
        itineraries[current].itinerary_detail[index.indexCountry].children[
          index.indexCity
        ].children.push({
          month_day_year: temp,
          place_detail: [],
          title: "",
          _id: new ObjectID().toHexString(),
          country: country,
          alpha2Code: alpha2Code,
          city: city,
        });
      } else
        itineraries[current].itinerary_detail = this.compareLastDateCurrentVsFirstDateNext(temp, itineraries[current].itinerary_detail, _id, index, alpha2Code, country, city)
    }

    let arrDates = getAllLeaf(itineraries[current].itinerary_detail);
    itineraries[current].end_dates =
      arrDates[arrDates.length - 1].month_day_year;
    saveItem("itineraries", itineraries);
    await this.updateListItineraryDetail("NoSort");
  };

  componentDidMount = () => {
    if (!this.props.preview) {
      var itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      if (current < 0) return;
      var result = itineraries[current].itinerary_detail;
      result.sort((data_A, data_B) => {
        return compareAsc(
          new Date(data_A.month_day_year * 1000),
          new Date(data_B.month_day_year * 1000)
        );
      });

      var results = searchTree(
        itineraries[current].itinerary_detail,
        this.props.params.idItineraryDetail
      );

      this.props.onRef(this);
      if (results[0]) {
        this.setState({
          title: results[0].title,
          day: results[0].month_day_year
            ? format(new Date(results[0].month_day_year * 1000), "d")
            : null,
          monthYear: results[0].month_day_year
            ? format(new Date(results[0].month_day_year * 1000), "MMM d, yyyy")
            : null,
          description: results[0].description,
          stay_in: results[0].stay_in,
          meal: results[0].meal,
          monthDayYear: results[0].month_day_year
            ? new Date(results[0].month_day_year * 1000)
            : null,
          lengthItineraryDetail: itineraries[current].itinerary_detail.length,
          items: results[0].place_detail,
          order:
            results[0].place_detail !== undefined
              ? results[0].place_detail.length + 1
              : 0,
          name: itineraries[current].name,
          estimated_budget: itineraries[current].estimated_budget
            ? ` on a $${itineraries[current].estimated_budget} USD budget`
            : "",
          a_number_of_paxs: itineraries[current].a_number_of_paxs
            ? ` for ${itineraries[current].a_number_of_paxs} pax`
            : "",
          index: results[0].index,
          itineraries_detail: result,
        });
      }
    } else {
      this.setState({
        itineraries_detail: this.props.itinerary_detail
      });
      // this.apiService
      // .getItinerary(this.props.match.params.idItinerary).then(res => {
      //   if (res.status === "success") {
      //     this.setState({
      //       itineraries_detail: res.itinerary.itinerary_detail
      //     });
      //   }
      // })
    }
  };

  updateListItineraryDetail = (params) => {
    this.props.stopUpdateListItineraryDetail();

    var itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });
    var result = itineraries[current].itinerary_detail;

    result.sort((data_A, data_B) => {
      return compareAsc(
        new Date(data_A.month_day_year * 1000),
        new Date(data_B.month_day_year * 1000)
      );
    });

    this.props.updateTotalDay(result.length);
    this.props.reRenderContenDetail(
      itineraries[current]._id,
      this.props.params.idItineraryDetail
    );
    this.setState({
      itineraries_detail: result,
      isLoading: false,
    });
  };

  updateListItineraryDetailAfterDelete = () => {
    this.props.stopUpdateListItineraryDetail();
    var itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });
    // var result = getAllLeaf(itineraries[current].itinerary_detail);
    // this.props.updateTotalDay(result.length)
    // this.apiService.totalAmountItinerary(this.props.params.idItinerary).then((response) => {
    //     if (response.status === 'success') {
    //         this.props.updateTotalAmount(response.total_amount)
    //     }
    // })
    // this.props.updateTotalDay(results.length);
    this.setState(
      {
        itineraries_detail: itineraries[current].itinerary_detail,
      },
      () => {
        if (!isMobileOnly) {
          this.props.history.push(
              "/itinerary/" +
              itineraries[current]._id +
              "/" +
              itineraries[current].itinerary_detail[0].children[0].children[0]._id
          );
        }
        this.props.reRenderContenDetail(
          itineraries[current]._id,
          itineraries[current].itinerary_detail[0].children[0].children[0]._id
        );
      }
    );
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (!this.props.preview) {
      if (this.props.params !== nextProps.params) {
        var itineraries;
        itineraries = loadItem("itineraries");
        let current = itineraries.findIndex((item) => {
          return item._id === nextProps.params.idItinerary;
        });
        var result = itineraries[current].itinerary_detail;
        result.sort((data_A, data_B) => {
          return compareAsc(
            new Date(data_A.month_day_year * 1000),
            new Date(data_B.month_day_year * 1000)
          );
        });
        var results = [];
        if (this.props.params.idItineraryDetail) {
          results = searchTree(
            itineraries[current].itinerary_detail,
            this.props.params.idItineraryDetail
          );
          var index = itineraries[current].itinerary_detail.findIndex(
            (item) => {
              return item._id === this.props.params.idItineraryDetail;
            }
          );
        }
        if (results[0]) {
          this.setState({
            title: results[0].title,
            day: format(new Date(results[0].month_day_year * 1000), "d"),
            monthYear: format(
              new Date(results[0].month_day_year * 1000),
              "MMM d, yyyy"
            ),
            description: results[0].description,
            stay_in: results[0].stay_in,
            meal: results[0].meal,
            monthDayYear: results[0].month_day_year
              ? new Date(results[0].month_day_year * 1000)
              : null,
            lengthItineraryDetail: itineraries[current].itinerary_detail.length,
            items: results[0].place_detail,
            order: results[0].place_detail.length + 1,
            name: itineraries[current].name,
            estimated_budget: itineraries[current].estimated_budget
              ? ` on a $${itineraries[current].estimated_budget} USD budget`
              : "",
            a_number_of_paxs: itineraries[current].a_number_of_paxs
              ? ` for ${itineraries[current].a_number_of_paxs} pax`
              : "",
            index: results[0].index,
          });
        }
        this.props.updateTotalDay(result.length);
        this.setState({
          itineraries_detail: result,
        });
      }
      if (
        nextProps.update &&
        nextProps.typeUpdate === "editDayTitleChangeDate"
      ) {
        this.updateListItineraryDetail();
      } else if (
        nextProps.update &&
        nextProps.typeUpdate === "deleteItineraryDetail"
      ) {
        this.updateListItineraryDetailAfterDelete();
      }
    }
    if (nextProps.handleShowOrderCountry) {
      this.setState({
        show: 2,
      });
    }
  };

  handleOpenConfirmDelete = (id) => e => {
    this.handleDelete(id);
    e.preventDefault();
    /*this.setState({
      showConfirmDelete: true,
    });*/
  };

  handleCloseConfirmDelete = () => {
    this.setState({
      showConfirmDelete: false,
    });
  };

  handleDelete = (id = null) => {
    var itineraries;
    id = id || this.props.params.idItineraryDetail;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });
    /*let current = itineraries.findIndex((item) => {
      return item.itinerary_detail.findIndex(o => {
        return o.children.findIndex(t => {
          return t.children.findIndex(s => {
            return item._id === id;
          });
        });
      });
    });*/
    // saveItem("currentAlpha2Code", tempItem.alpha2Code);
    // itineraries[current].itinerary_detail.splice(index, 1);

    var result = getAllLeaf(itineraries[current].itinerary_detail);

    deleteLeaf(
        id,
      itineraries[current].itinerary_detail
    );
    itineraries[current].itinerary_detail.forEach((item) => {
      item.children.forEach((child, key) => {
        if (child.children.length === 0) {
          item.children.splice(key, 1);
        }
      });
    });

    var MergeAfterDelete = false;
    itineraries[current].itinerary_detail.forEach((item, index) => {
      if (item.children.length === 0) {
        MergeAfterDelete = true;
        itineraries[current].itinerary_detail.splice(index, 1);
      }
    });

    if (MergeAfterDelete) {
      var afterDelete = itineraries[current].itinerary_detail;
      var flagMerge = false;
      var tempCountryCityMerge = [];
      for (let i = 0; i < afterDelete.length - 1; i++) {
        if (afterDelete[i].alpha2Code === afterDelete[i + 1].alpha2Code) {
          afterDelete[i].children = [
            ...afterDelete[i].children,
            ...afterDelete[i + 1].children,
          ];
          afterDelete.splice(i + 1, 1);
          for (let j = 0; j < afterDelete[i].children.length; j++) {
            if (j === afterDelete[i].children.length - 1) break;

            // Merge 2 country cùng city
            if (
              afterDelete[i].children[j].title ===
              afterDelete[i].children[j + 1].title
            ) {
              afterDelete[i].children[j].children = [
                ...afterDelete[i].children[j].children,
                ...afterDelete[i].children[j + 1].children,
              ];
              afterDelete[i].children.splice(j + 1, 1);
              afterDelete.forEach((item, index) => {
                item.order = index;
              });
            } else {
              // Merge Country không có city với Country có city
              if (
                !afterDelete[i].children[j].title ||
                !afterDelete[i].children[j + 1].title
              ) {
                flagMerge = true;
                if (!afterDelete[i].children[j].title) {
                  let tempCity = [];
                  afterDelete[i].children.forEach((item) => {
                    if (item.title) tempCity.push(item.title);
                  });
                  tempCountryCityMerge.push({
                    country: afterDelete[i].title,
                    index: i,
                    listCity: tempCity,
                    listDateUnkown: afterDelete[i].children[j].children,
                  });
                } else if (!afterDelete[i].children[j + 1].title) {
                  let tempCity = [];
                  afterDelete[i].children.forEach((item) => {
                    if (item.title) tempCity.push(item.title);
                  });
                  tempCountryCityMerge.push({
                    country: afterDelete[i].title,
                    index: i,
                    listCity: tempCity,
                    listDateUnkown: afterDelete[i].children[j + 1].children,
                  });
                }
              } else {
                let checkExistsCountryWithoutCity = afterDelete[
                  i
                ].children.some((country) => {
                  return country.title === "";
                });
                if (!checkExistsCountryWithoutCity) {
                  // Merge Country không cùng city
                  afterDelete.forEach((item, index) => {
                    item.order = index;
                  });
                }
              }
            }
          }
          i--;
        }
      }

      if (!flagMerge) {
        afterDelete.forEach((item, index) => {
          item.order = index;
        });
        let itineraries;
        itineraries = loadItem("itineraries");
        let current = itineraries.findIndex((item) => {
          return item._id === this.props.params.idItinerary;
        });
        itineraries[current].itinerary_detail = afterDelete;
        itineraries[current] = setStartDateEndDate(itineraries[current]);
        itineraries[current] = setNewStartEndDate(itineraries[current]);
        saveItem("itineraries", itineraries);
        this.setState({ itineraries_detail: afterDelete });
      } else {
        this.setState({
          showConfirmDelete: false,
          showPopupMergeUnknownLocation: true,
          listCityMerge: tempCountryCityMerge,
          tempDragDropCountry: afterDelete,
        });
        return;
      }
    }

    if (result[result.length - 1]._id === id)
      itineraries[current] = setStartDateEndDate(itineraries[current], 2);
    else if (result[0]._id === id)
      itineraries[current] = setStartDateEndDate(itineraries[current], 1);
    else
      itineraries[current] = setStartDateEndDate(itineraries[current]);
    itineraries[current] = setNewStartEndDate(itineraries[current]);
    saveItem("itineraries", itineraries);
    let total_amount = calcTotalAmount(this.props.params.idItinerary);
    this.props.updateTotalAmount(total_amount);
    this.updateListItineraryDetailAfterDelete();
    this.setState({
      showConfirmDelete: false,
    });
  };

  handleCloseFormEditDate = () => {
    this.setState({
      editDayTitleChangeDate: false,
    });
  };

  handleEnableEditTitleChangeDay = () => {
    this.setState({
      editDayTitleChangeDate: true,
    });
  };

  handleChangeIndex = (_id) => {
    var itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });
    var listDates = getAllLeaf(itineraries[current].itinerary_detail);
    let index = listDates.findIndex((item) => {
      return item._id === _id;
    });
    this.props.handleSlickGoTo(index);
  };

  renderTreeNodes = (data, countLeaf) =>
    data.map((item, index) => {
      if (item.children) {
        if (item.title && item.flag) {
          return (
            <TreeNode
              title={
                <div style={{ display: "flex" }}>
                  <Avatar alt="flag" src={getFlag(item.alpha2Code)} />
                  {item.title}
                </div>
              }
              key={`${item.title}-${index}`}
              dataRef={item}
            >
              {this.renderTreeNodes(item.children, countLeaf)}
            </TreeNode>
          );
        } else if (item.title) {
          if (index === 0) {
            if (!this.props.preview && data.length > 1) {
              return [
                <TreeNode
                  className="section-re-arrange-city"
                  title={
                    <div
                      onClick={this.handleSelectDragDropCity.bind(
                        this,
                        item.children[0]._id
                      )}
                      className="re-arrange-city"
                    >
                      <MdSwapVert />
                    </div>
                  }
                />,
                <TreeNode
                  title={item.title}
                  className="city-name"
                  key={`${item.title}-${item.children[0]._id}`}
                  dataRef={item}
                >
                  {this.renderTreeNodes(item.children, countLeaf)}
                </TreeNode>,
              ];
            } else {
              return [
                <TreeNode
                  title={item.title}
                  className="city-name"
                  key={`${item.title}-${item.children[0]._id}`}
                  dataRef={item}
                >
                  {this.renderTreeNodes(item.children, countLeaf)}
                </TreeNode>,
              ];
            }
          } else {
            return (
              <TreeNode
                title={item.title}
                className="city-name"
                key={`${item.title}-${item.children[0]._id}`}
                dataRef={item}
              >
                {this.renderTreeNodes(item.children, countLeaf)}
              </TreeNode>
            );
          }
        } else {
          return this.renderTreeNodes(item.children, countLeaf);
        }
      } else {
        if (index !== data.length - 1) {
          if (!this.props.preview) {
            return (
              <TreeNode
                title={
                  <Link
                    to={
                      `/itinerary/` +
                      this.props.params.idItinerary +
                      `/` +
                      item._id
                    }
                    onClick={this.handleChangeIndex.bind(this, item._id)}
                  >
                    <Row
                      className={
                        this.props.params.idItineraryDetail === item._id
                          ? "selected each-itinerary-detail"
                          : "each-itinerary-detail"
                      }
                    >
                      <Col xs="12">
                        <Row className="day-item">
                          <div className="day">
                            <Badge color="primary">Day {index + 1}</Badge>
                            {item.month_day_year && (
                              <p className="itinerary-detail-content text-day">
                                {format(
                                  new Date(item.month_day_year * 1000),
                                  "MMM d, yyyy"
                                )}
                              </p>
                            )}
                          </div>
                          {(this.props.params.idItineraryDetail === item._id || isMobileOnly) && (
                            <div className="item">
                              <div
                                className="edit-icon"
                                onClick={this.handleEnableEditTitleChangeDay}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </div>
                              <Popconfirm placement="topRight" title="Are you sure you want to delete this day?" onCancel={event => event.preventDefault()} onConfirm={this.handleOpenConfirmDelete(item._id)} okText="Yes" cancelText="No">
                                <div
                                    className="remove-icon"
                                >
                                  <i className="far fa-trash-alt" />
                                </div>
                              </Popconfirm>
                            </div>
                          )}
                        </Row>
                        <Row>
                          <span className="itinerary-detail-content">
                            {item.title}
                          </span>
                        </Row>
                      </Col>
                    </Row>
                  </Link>
                }
                key={item._id}
              />
            );
          } else {
            return (
              <TreeNode
                title={
                  <a
                    href={`#${item._id}-1`}
                    onClick={() => this.handleScrollToContent(`${item._id}-1`)}
                  >
                    <Row
                      className={
                        this.props.params.idItineraryDetail === item._id
                          ? "selected each-itinerary-detail"
                          : "each-itinerary-detail"
                      }
                    >
                      <Col xs="12">
                        <Row className="day-item">
                          <div className="day">
                            <Badge color="primary">Day {index + 1}</Badge>
                            {item.month_day_year && (
                              <p className="itinerary-detail-content text-day">
                                {format(
                                  new Date(item.month_day_year * 1000),
                                  "MMM d, yyyy"
                                )}
                              </p>
                            )}
                          </div>
                        </Row>
                        <Row>
                          <span className="itinerary-detail-content">
                            {item.title}
                          </span>
                        </Row>
                      </Col>
                    </Row>
                  </a>
                }
                key={item._id}
              />
            );
          }
        } else {
          if (!this.props.preview) {
            return [
              <TreeNode
                title={
                  <Link
                    to={
                      `/itinerary/` +
                      this.props.params.idItinerary +
                      `/` +
                      item._id
                    }
                    onClick={this.handleChangeIndex.bind(this, item._id)}
                  >
                    <Row
                      className={
                        this.props.params.idItineraryDetail === item._id
                          ? "selected each-itinerary-detail"
                          : "each-itinerary-detail"
                      }
                    >
                      <Col xs="12">
                        <Row className="day-item">
                          <div className="day">
                            <Badge color="primary">Day {index + 1}</Badge>
                            {item.month_day_year && (
                              <p className="itinerary-detail-content text-day">
                                {format(
                                  new Date(item.month_day_year * 1000),
                                  "MMM d, yyyy"
                                )}
                              </p>
                            )}
                          </div>
                          {(this.props.params.idItineraryDetail === item._id || isMobileOnly) && (
                            <div className="item">
                              <div
                                className="edit-icon"
                                onClick={this.handleEnableEditTitleChangeDay}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </div>
                              {countLeaf > 1 && (
                                <Popconfirm placement="topRight" title="Are you sure you want to delete this day?" onCancel={event => event.preventDefault()} onConfirm={this.handleOpenConfirmDelete(item._id)} okText="Yes" cancelText="No">
                                  <div
                                    className="remove-icon"
                                  >
                                    <i className="far fa-trash-alt" />
                                  </div>
                                </Popconfirm>
                              )}
                            </div>
                          )}
                        </Row>
                        <Row>
                          <span className="itinerary-detail-content">
                            {item.title}
                          </span>
                        </Row>
                      </Col>
                    </Row>
                  </Link>
                }
                key={item._id}
              />,
              <TreeNode
                title={
                  <div
                    className="footer-day-detail"
                    onClick={this.handleAddNewDay.bind(
                      this,
                      item.alpha2Code,
                      item.country,
                      item.city,
                      item._id
                    )}
                  >
                    <Button
                      disabled={this.state.isLoading}
                      variant="outlined"
                      color="primary"
                      aria-label="add"
                    >
                      <AddIcon />
                      <span>Add a day</span>
                    </Button>
                  </div>
                }
                className="section-btn-add-day"
              />,
            ];
          } else {
            return (
              <TreeNode
                title={
                  <a
                    href={`#${item._id}-1`}
                    onClick={() => this.handleScrollToContent(`${item._id}-1`)}
                  >
                    <Row
                      className={
                        this.props.params.idItineraryDetail === item._id
                          ? "selected each-itinerary-detail"
                          : "each-itinerary-detail"
                      }
                    >
                      <Col xs="12">
                        <Row className="day-item">
                          <div className="day">
                            <Badge color="primary">Day {index + 1}</Badge>
                            {item.month_day_year && (
                              <p className="itinerary-detail-content text-day">
                                {format(
                                  new Date(item.month_day_year * 1000),
                                  "MMM d, yyyy"
                                )}
                              </p>
                            )}
                          </div>
                        </Row>
                        <Row>
                          <span className="itinerary-detail-content">
                            {item.title}
                          </span>
                        </Row>
                      </Col>
                    </Row>
                  </a>
                }
                key={item._id}
              />
            );
          }
        }
      }
    });

  idList = {
    reorderItineraryDetail: "itineraries_detail",
    reorderCity: "listDragDropCity",
  };

  handleScrollToContent = (id) => {
    if (document.getElementById(id))
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    if (this.props.onCloseMobile)
      this.props.onCloseMobile();
  };

  getList = (_id) => this.state[this.idList[_id]];

  setDefaultAfterOrder = (itinerary_detail, start_date, end_date) => {
    for (var i = 0; i < itinerary_detail.length; i++) {
      var country = itinerary_detail[i]
      var tempDateIncrease = start_date
      for (var j = 0; j < country.children.length; j++) {
        var city = country.children[j]
        for (var z = 0; z < city.children.length; z++) {
          var eachDate = city.children[z]
          if (i === 0 && j === 0 && z === 0) {
            eachDate.month_day_year = start_date
            continue
          } else if (i === 0) {
            tempDateIncrease = dateToTimestamp(
              addDays(new Date(tempDateIncrease * 1000), 1)
            ).toString();
            eachDate.month_day_year = tempDateIncrease
          }
          else if ((i === itinerary_detail.length - 1) && (j === country.children.length - 1) && (z === city.children.length - 1)) {
            eachDate.month_day_year = end_date
          } else {
            eachDate.month_day_year = ""
          }
        }
      }
    }
    return itinerary_detail
  }

  onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (
      source.droppableId === destination.droppableId &&
      source.droppableId === "reorderItineraryDetail" &&
      destination.droppableId === "reorderItineraryDetail"
    ) {
      if (source.index === destination.index) return;
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      var flagMerge = false;
      var tempCountryCityMerge = [];
      for (let i = 0; i < items.length - 1; i++) {
        if (items[i].alpha2Code === items[i + 1].alpha2Code) {
          items[i].children = [...items[i].children, ...items[i + 1].children];
          items.splice(i + 1, 1);
          for (let j = 0; j < items[i].children.length; j++) {
            if (j === items[i].children.length - 1) break;

            // Merge 2 country cùng city
            if (items[i].children[j].title === items[i].children[j + 1].title) {
              items[i].children[j].children = [
                ...items[i].children[j].children,
                ...items[i].children[j + 1].children,
              ];
              items[i].children.splice(j + 1, 1);
              items.forEach((item, index) => {
                item.order = index;
              });
            } else {
              // Merge Country không có city với Country có city
              if (
                !items[i].children[j].title ||
                !items[i].children[j + 1].title
              ) {
                flagMerge = true;
                if (!items[i].children[j].title) {
                  let tempCity = [];
                  items[i].children.forEach((item) => {
                    if (item.title) tempCity.push(item.title);
                  });
                  tempCountryCityMerge.push({
                    country: items[i].title,
                    index: i,
                    listCity: tempCity,
                    listDateUnkown: items[i].children[j].children,
                  });
                } else if (!items[i].children[j + 1].title) {
                  let tempCity = [];
                  items[i].children.forEach((item) => {
                    if (item.title) tempCity.push(item.title);
                  });
                  tempCountryCityMerge.push({
                    country: items[i].title,
                    index: i,
                    listCity: tempCity,
                    listDateUnkown: items[i].children[j + 1].children,
                  });
                }
              } else {
                let checkExistsCountryWithoutCity = items[i].children.some(
                  (country) => {
                    return country.title === "";
                  }
                );
                if (!checkExistsCountryWithoutCity) {
                  // Merge Country không cùng city
                  items.forEach((item, index) => {
                    item.order = index;
                  });
                }
              }
            }
          }
          i--;
        }
      }

      // Drag drop 2 country khác nhau
      if (!flagMerge) {
        items.forEach((item, index) => {
          item.order = index;
        });
        let itineraries;
        itineraries = loadItem("itineraries");
        let current = itineraries.findIndex((item) => {
          return item._id === this.props.params.idItinerary;
        });
        itineraries[current].itinerary_detail = items;
        itineraries[current] = setStartDateEndDate(itineraries[current]);
        itineraries[current] = setNewStartEndDate(itineraries[current]);
        itineraries[current].itinerary_detail = this.setDefaultAfterOrder(itineraries[current].itinerary_detail, itineraries[current].dates, itineraries[current].end_dates)
        saveItem("itineraries", itineraries);
        this.setState({ itineraries_detail: items });
      } else {
        this.setState({
          showPopupMergeUnknownLocation: true,
          listCityMerge: tempCountryCityMerge,
          tempDragDropCountry: items,
        });
      }
    }
  };

  showReArrangeCountry = () => {
    this.setState({
      show: 2,
    });
  };

  hideReArrangeCountry = () => {
    this.setState({
      show: 1,
    });
    this.props.hideOrderCountry();
  };

  hideReArrangeCity = () => {
    this.props.hideOrderCity();
    this.setState({
      show: 1,
    });
  };

  handleSelectDragDropCity = (_id) => {
    this.props.showOrderCity();
    var itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });

    let index = getParentIndex(_id, itineraries[current].itinerary_detail);
    this.setState({
      index,
      show: 3,
      listDragDropCity:
        itineraries[current].itinerary_detail[index.indexCountry].children,
    });
  };

  onDragEndCity = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (
      source.droppableId === destination.droppableId &&
      source.droppableId === "reorderCity" &&
      destination.droppableId === "reorderCity"
    ) {
      if (source.index === destination.index) return;
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      for (let i = 0; i < items.length - 1; i++) {
        if (items[i].title === items[i + 1].title) {
          items[i].children = [...items[i].children, ...items[i + 1].children];
          items.splice(i + 1, 1);
          i--;

          let itineraries;
          itineraries = loadItem("itineraries");
          let current = itineraries.findIndex((item) => {
            return item._id === this.props.params.idItinerary;
          });
          itineraries[current].itinerary_detail[
            this.state.index.indexCountry
          ].children = items;
          saveItem("itineraries", itineraries);
          this.setState({
            listDragDropCity: items,
            itineraries_detail: itineraries[current].itinerary_detail,
          });
          Swal.fire({
            type: "warning",
            title: "We merge",
            showConfirmButton: true,
          });
        }
      }

      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      itineraries[current].itinerary_detail[
        this.state.index.indexCountry
      ].children = items;

      itineraries[current] = setStartDateEndDate(itineraries[current]);
      itineraries[current] = setNewStartEndDate(itineraries[current]);
      itineraries[current].itinerary_detail = this.setDefaultAfterOrder(itineraries[current].itinerary_detail, itineraries[current].dates, itineraries[current].end_dates)
      saveItem("itineraries", itineraries);
      this.setState({
        listDragDropCity: items,
        itineraries_detail: itineraries[current].itinerary_detail,
      });
    }
  };

  handleCancelMerge = () => {
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });

    this.setState({
      itineraries_detail: itineraries[current].itinerary_detail,
      showPopupMergeUnknownLocation: false,
    });
  };

  getComponent = (defaultExpandedKeys, countLeaf) => {
    switch (this.state.show) {
      case 1:
        return (
          this.state.itineraries_detail.length > 0 && (
            <DirectoryTree
              defaultExpandAll={
                this.props.preview
              }
              defaultExpandedKeys={
                this.props.preview ? [] : defaultExpandedKeys
              }
              showIcon={false}
            >
              {this.renderTreeNodes(this.state.itineraries_detail, countLeaf)}
            </DirectoryTree>
          )
        );
      case 2:
        return (
          <React.Fragment>
            <div className="header-drag-drop">
              <div
                onClick={this.hideReArrangeCountry}
                className="header-drag-drop__left"
              >
                <LeftOutlined />
              </div>
              <div className="header-drag-drop__right">
                Drag and drop to sort
              </div>
            </div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="reorderItineraryDetail">
                {(provided, snapshot) => (
                  <div
                    id="reorderItineraryDetail"
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver, 1)}
                  >
                    {!!this.state.itineraries_detail.length &&
                      this.state.itineraries_detail.map((item, index) => (
                        <Draggable
                          key={index}
                          draggableId={`${item._id}-${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              id={`${item._id}-${index}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              className="each-item-drag-drop"
                            >
                              <FormGroup row>
                                <Col xs={12}>
                                  <div className="form-control destination-disabled">
                                    <div>{item.title}</div>
                                    <div>
                                      <GrDrag />
                                    </div>
                                  </div>
                                </Col>
                              </FormGroup>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* <div
                onClick={this.hideReArrangeCountry}
                className="done-re-arrange"
            >
              <MdCheck />
              Done
            </div> */}
          </React.Fragment>
        );
      case 3:
        return (
          <>
            <Col xs="12">
              <div className="header-drag-drop">
                <div
                  onClick={this.hideReArrangeCity}
                  className="header-drag-drop__left"
                >
                  <LeftOutlined />
                </div>
                <div className="header-drag-drop__right">
                  Drag and drop to sort
                </div>
              </div>
            </Col>
            <Col xs="12">
              <DragDropContext onDragEnd={this.onDragEndCity}>
                <Droppable droppableId="reorderCity">
                  {(provided, snapshot) => (
                    <div
                      id="reorderCity"
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver, 1)}
                    >
                      {!!this.state.listDragDropCity.length &&
                        this.state.listDragDropCity.map((item, index) => (
                          <Draggable
                            key={index}
                            draggableId={`${item.title}-${index}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                id={`${item.title}-${index}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                className="each-item-drag-drop"
                              >
                                <FormGroup row>
                                  <Col xs={12}>
                                    <div className="form-control destination-disabled">
                                      <div>{item.title}</div>
                                      <div>
                                        <GrDrag />
                                      </div>
                                    </div>
                                  </Col>
                                </FormGroup>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Col>
            {/* <Col xs="12">
              <div onClick={this.hideReArrangeCity} className="done-re-arrange">
                <MdCheck />
                Done
              </div>
            </Col> */}
          </>
        );
      default:
        return (
          <>
            <Col xs="12" style={{ paddingRight: `0` }}>
              {this.state.itineraries_detail.length > 0 && (
                <DirectoryTree
                  defaultExpandAll={this.props.preview}
                  defaultExpandedKeys={
                    this.props.preview ? [] : defaultExpandedKeys
                  }
                  showIcon={false}
                >
                  {this.renderTreeNodes(
                    this.state.itineraries_detail,
                    countLeaf
                  )}
                </DirectoryTree>
              )}
            </Col>
          </>
        );
    }
  };

  handleMergeToCity = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, value) => {
      if (!err) {
        var newTree;
        Object.keys(value).forEach((key, index) => {
          this.state.tempDragDropCountry[
            this.state.listCityMerge[index].index
          ].children.forEach((item, key) => {
            if (!item.title)
              this.state.tempDragDropCountry[
                this.state.listCityMerge[index].index
              ].children.splice(key, 1);
          });

          let indexCity = this.state.tempDragDropCountry[
            this.state.listCityMerge[index].index
          ].children.findIndex((item) => {
            return item.title === value[key];
          });

          newTree = [...this.state.tempDragDropCountry];
          newTree[this.state.listCityMerge[index].index].children[
            indexCity
          ].children.push(...this.state.listCityMerge[index].listDateUnkown);

          newTree.forEach((item, index) => {
            item.order = index;
          });
        });
        let itineraries;
        itineraries = loadItem("itineraries");
        let current = itineraries.findIndex((item) => {
          return item._id === this.props.params.idItinerary;
        });
        itineraries[current].itinerary_detail = newTree;
        itineraries[current] = setStartDateEndDate(itineraries[current]);
        itineraries[current] = setNewStartEndDate(itineraries[current]);
        itineraries[current].itinerary_detail = this.setDefaultAfterOrder(itineraries[current].itinerary_detail, itineraries[current].dates, itineraries[current].end_dates)

        saveItem("itineraries", itineraries);
        let total_amount = calcTotalAmount(this.props.params.idItinerary);
        this.props.updateTotalAmount(total_amount);

        this.updateListItineraryDetailAfterDelete();
        this.setState({
          itineraries_detail: newTree,
          showPopupMergeUnknownLocation: false,
        });
        Swal.fire({
          type: "success",
          title: "Merged successfully",
          showConfirmButton: true,
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let defaultExpandedKeys = [];
    if (this.state.itineraries_detail.length > 0) {
      this.state.itineraries_detail[0].children.forEach((item) => {
        if (!item.title) {
          defaultExpandedKeys.push(
            `${this.state.itineraries_detail[0].title}-0`
          );
        } else {
          item.children.forEach((child) => {
            defaultExpandedKeys.push(`${item.title}-${child._id}`);
          });
        }
      });
    }

    let countLeaf = getAllLeaf(this.state.itineraries_detail).length;

    return (
      <div className={styles.itineraryDetails}>
        <Modal
          title=""
          visible={this.state.showPopupMergeUnknownLocation}
          onOk={this.handleMergeToCity}
          maskClosable={false}
          onCancel={this.handleCancelMerge}
        >
          {this.state.listCityMerge.map((item, key) => (
            <React.Fragment key={key}>
              <div style={{ fontSize: "16px" }}>
                <div>
                  Do you want to move {item.listDateUnkown.length}
                  {item.listDateUnkown.length > 1 ? " days" : " day"} itinerary
                  to other city in {item.country}?
                </div>
                <div>Please choose a destination city below:</div>
              </div>

              <Form onSubmit={this.handleMergeToCity}>
                <Form.Item>
                  {getFieldDecorator(item.country, {
                    rules: [
                      { required: true, message: "Please select your city!" },
                    ],
                    initialValue:
                      item.listCity.length === 1 ? item.listCity[0] : null,
                  })(
                    <Radio.Group>
                      {item.listCity.map((child, index) => (
                        <Radio key={index} value={child}>
                          {child}
                        </Radio>
                      ))}
                    </Radio.Group>
                  )}
                </Form.Item>
              </Form>
            </React.Fragment>
          ))}
        </Modal>

        <Dialog
          open={this.state.editDayTitleChangeDate ? true : false}
          fullWidth={true}
          maxWidth={"md"}
          aria-labelledby="edit-day-infor"
          className={styles.contentDetail}
        >
          <MuiDialogTitle disableTypography className="popup-title">
            <Typography variant="h6">
              {this.state.title
                ? this.state.title
                : "Provide a description for this day"}
            </Typography>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={this.handleCloseFormEditDate.bind(this)}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <EditDayTitleChangeDate
            monthDayYear={this.state.monthDayYear}
            title={this.state.title}
            day={this.state.day}
            monthYear={this.state.monthYear}
            length={this.state.lengthItineraryDetail}
            params={this.props.params}
            hideEditDayTitleChangeDate={this.props.hideEditDayTitleChangeDate}
            handleCloseFormEditDate={this.handleCloseFormEditDate}
          />
        </Dialog>

        <Dialog
          open={this.state.showConfirmDelete ? true : false}
          onClose={this.handleCloseConfirmDelete.bind(this)}
          aria-labelledby="form-add-new-stop"
          maxWidth={"sm"}
          fullWidth={true}
          scroll={"body"}
        >
          <DialogTitle>Are you sure you want to delete this day?</DialogTitle>
          <DialogActions className={styles.addNewStopForm}>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleDelete.bind(this)}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              onClick={this.handleCloseConfirmDelete.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {this.getComponent(defaultExpandedKeys, countLeaf)}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(ItineraryDetails))
);
