import React, { useState, useEffect } from "react";
import { Descriptions, Typography } from "antd";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  GlobalOutlined,
  MailOutlined,
} from "@ant-design/icons";

import ImageHelper from "../../../../../functions/image";
import { config } from "../../../../../config";
import {connect} from "react-redux";

const {Title, Text} = Typography;

function Information(props) {
  const company = props.company;
  const profile = props.profileDomain;
  const [seeMore, setSeeMore] = useState(false);

  useEffect(() => {
    if (company) {
      setSeeMore(!!(company.about_us && company.about_us.length > 300));
    }
  }, [company]);

  function seeMoreRender() {
    if(!company.about_us) return null;

    return company.about_us.length > 300 ? (
      <span className="see-more" onClick={() => setSeeMore(!seeMore)}>
        {" "}
        {seeMore ? "see more" : "see less"}
      </span>
    ) : null;
  }

  return (
    <section className="company-info p-3 mt-3">
      <div className="company-logo-container">
        <ImageHelper
          src={config.baseURL + company.logo}
          className="company-logo"
        />
        <div className="company-name">
          <Title level={3} className="d-flex flex-column">
            <a
                href={config.http + "//" + company.url + "." + config.domain}
            >
              {company.name || company.short_name || profile.firstName + ' ' + profile.lastName}
            </a>
            {profile.job ? <Text type="secondary" className="jobname font-weight-normal">@{profile.job.name}</Text> : null}
          </Title>
        </div>
      </div>
      <div className="contact">
        <div className="content">
          <Descriptions column={1}>
            {company.address && <Descriptions.Item className="d-flex align-items-start" label={<EnvironmentOutlined />}>
              {company.address ? company.address : null}
            </Descriptions.Item>}
            {company.phone && <Descriptions.Item label={<PhoneOutlined />}>
              {company.phone ? company.phone : null}
            </Descriptions.Item>}
            {company.website && <Descriptions.Item label={<GlobalOutlined />}>
              <a href={company.website} target="blank">
                {company.website ? company.website : null}
              </a>
            </Descriptions.Item>}
            {company.email && <Descriptions.Item label={<MailOutlined />}>
              { company.email ?
                  <a href={"mailto::" + company.email}>{company.email }</a>
                  : null}
            </Descriptions.Item>}
          </Descriptions>
        </div>
        <div className="content mt-2">
          {seeMore ? (
              <>
                {company.about_us.substring(0, 300) + "... "}
                {seeMoreRender()}
              </>
          ) : (
              <>
                {company.about_us}
                {seeMoreRender()}
              </>
          )}
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    profileDomain: state.profileReducer.profileDomain
  };
};

export default connect(mapStateToProps, null)(Information);
