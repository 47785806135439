import {loadItem, saveItem} from "./storage.service";
import {daysBetween} from "../helper/Helper";
import _ from "lodash";

class RecentService {
    static itinerarySeen = loadItem('itinerarySeen') || {};

    static save = (item) => {
        this.itinerarySeen = loadItem('itinerarySeen') || {};
        let itineraries = this.itinerarySeen.itineraries || [];
        if (!this.itinerarySeen.dateSeen)
            this.itinerarySeen.dateSeen = Date.now();
        this.fetchISToday();
        _.remove(itineraries, o => o._id === item._id);
        itineraries.unshift(item);
        if (itineraries.length > 6)
            itineraries.pop();
        this.itinerarySeen.itineraries = itineraries;
        saveItem("itinerarySeen", this.itinerarySeen);
    }
    static fetchISToday = () => {
        if (daysBetween(new Date(this.itinerarySeen.dateSeen), new Date()) >= 1) {
            this.itinerarySeen.dateSeen = Date.now();
            this.itinerarySeen.itineraries = [];
            saveItem("itinerarySeen", this.itinerarySeen);
        }
    }
}

export default RecentService;
