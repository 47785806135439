import React, { Component } from "react";
import styled from "styled-components";

const StarContainer = styled.span`
  color: #f4d931;
`;

const FullStar = () => (
  <StarContainer>
    <i className="fas fa-star" />
  </StarContainer>
);

const HalfStar = () => (
  <StarContainer>
    <i className="fas fa-star-half" />
  </StarContainer>
);

class StarRating extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { ratingAverage } = this.props;
    return (
      <>
        {ratingAverage <= 0.5 && <HalfStar />}
        {0.5 < ratingAverage && ratingAverage <= 1 && <FullStar />}
        {1 < ratingAverage && ratingAverage <= 1.5 && (
          <>
            <FullStar />
            <HalfStar />
          </>
        )}
        {1.5 < ratingAverage && ratingAverage <= 2 && (
          <>
            <FullStar />
            <FullStar />
          </>
        )}
        {2 < ratingAverage && ratingAverage <= 2.5 && (
          <>
            <FullStar />
            <FullStar />
            <HalfStar />
          </>
        )}
        {2.5 < ratingAverage && ratingAverage <= 3 && (
          <>
            <FullStar />
            <FullStar />
            <FullStar />
          </>
        )}
        {3 < ratingAverage && ratingAverage <= 3.5 && (
          <>
            <FullStar />
            <FullStar />
            <FullStar />
            <HalfStar />
          </>
        )}
        {3.5 < ratingAverage && ratingAverage <= 4 && (
          <>
            <FullStar />
            <FullStar />
            <FullStar />
            <FullStar />
          </>
        )}
        {4 < ratingAverage && ratingAverage <= 4.5 && (
          <>
            <FullStar />
            <FullStar />
            <FullStar />
            <FullStar />
            <HalfStar />
          </>
        )}
        {4.5 < ratingAverage && ratingAverage <= 5 && (
          <>
            <FullStar />
            <FullStar />
            <FullStar />
            <FullStar />
            <FullStar />
          </>
        )}
      </>
    );
  }
}

export default StarRating;
