import React, { Component } from "react";
import {
  ContentTopAttractions,
} from "../../components";
import ApiService from "../../services/api.service";
import { connect } from "react-redux";
import {
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout,
} from "../../store/actions";
import styles from "./index.scss";

const mapDispatchToProps = (dispatch) => {
  return {
    saveCurrentItinerary: (itinerary) => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

class TopAttractions extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: true,
      redirect: false
    };
  }

  componentDidMount = async () => {
    this.setState(
        {
          isLoading: false
        });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { isLoading } = this.state;
    return !isLoading ? (
        <ContentTopAttractions maxPage={Math.ceil(this.state.max)} />
    ) : (
      <div className={styles.topAttractions}></div>
    );
  }
}

export default connect(null, mapDispatchToProps)(TopAttractions);
