import React, { Component } from "react";
import { ContentDetailWithSocket, SocketContext } from "../../components";
import ApiService from "../../services/api.service";
import { loadItem, saveItem } from "../../services/storage.service";
import { connect } from "react-redux";
import {
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout,
} from "../../store/actions";
import styles from "./index.scss";

import {
  MobileView,
  BrowserView,
  isBrowser,
  isTablet,
} from "react-device-detect";

import io from "socket.io-client";
import { config } from "../../config";
import ItineraryCreate from "../../mobile/itinerary-create/parent";
import { getDateNow } from "../../services/utils.service";
import Alert from "../../module/admin/components/alert"

const mapDispatchToProps = (dispatch) => {
  return {
    saveCurrentItinerary: (itinerary) => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

const socket = io(config.baseURL);

class ItineraryDetail extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: true,
      redirect: false,
      itinerary: {},
      saved: false,
    };
  }

  handleOnBeforeUnload = (e) => {
    const message = "Are you sure?";
    e.returnValue = message;
    return message;
  };

  saveToDraft = (itinerary, currency_infor) => {
    let itineraryDraft =
      loadItem("itineraryDraft") !== null ? loadItem("itineraryDraft") : null;
    if (Object.keys(itinerary).length !== 0) {
      if (itineraryDraft !== null) {
        let uniqueItinerary = [];
        uniqueItinerary.push({
          ...itinerary,
          currency_infor: currency_infor,
          last_seen: new Date(),
        });
        itineraryDraft.itineraries.map((x) =>
          uniqueItinerary.filter((a) => a._id === x._id).length > 0
            ? (x.last_seen = new Date())
            : uniqueItinerary.push(x)
        );
        itineraryDraft.itineraries = uniqueItinerary;
        if (itineraryDraft.itineraries.length > 5) {
          itineraryDraft.itineraries.pop();
        }
        saveItem("itineraryDraft", itineraryDraft);
      } else this.saveNewItineraryDraft(itinerary, currency_infor);
    }
  };

  saveNewItineraryDraft = (itinerary, currency_infor) => {
    let itineraryDraft = {},
      itineraries = [];
    itineraryDraft.dateSeen = getDateNow();
    itineraries.splice(0, 0, {
      ...itinerary,
      currency_infor: currency_infor,
      last_seen: new Date(),
    });
    let newItineraries;
    if (itineraries.length > 5) {
      itineraries.pop();
      newItineraries = itineraries;
    } else newItineraries = itineraries;
    itineraryDraft.itineraries = newItineraries;
    saveItem("itineraryDraft", itineraryDraft);
  };

  componentDidMount = async () => {
    this._isMounted = true;

    window.onbeforeunload = this.handleOnBeforeUnload;

    document.title = "Tyneri";

    let userId = null;
    if (this.props.profile) userId = this.props.profile._id;
    await this.apiService
      .getItinerary(this.props.match.params.idItinerary, userId)
      .then((res) => {
        if (res && this._isMounted && res.status === "success") {
          this.setState({
            itinerary: res.itinerary,
            saved: true,
          });
          this.props.saveCurrentItinerary(res.itinerary);
          let itineraries = [];
          if (!loadItem("itineraries")) {
            itineraries.push({ ...res.itinerary });
            saveItem("itineraries", itineraries);
          } else {
            itineraries = loadItem("itineraries");
            let index = itineraries.findIndex((item) => {
              return item._id === res.itinerary._id;
            });
            if (index < 0) {
              itineraries.push({ ...res.itinerary });
              saveItem("itineraries", itineraries);
            } else {
              this.setState(
                {
                  itinerary: itineraries[index],
                },
                () => {
                  if (!itineraries[index].saved) {
                    this.apiService
                      .getDetailCurrency(itineraries[index].currency)
                      .then((response) => {
                        if (response.status === "success") {
                          this.saveToDraft(
                            itineraries[index],
                            response.currency
                          );
                        }
                      });
                  }
                }
              );
            }
          }
          if (!this.props.match.params.idItineraryDetail) {
            if (isBrowser) {
              this.props.history.push("/404");
            } else if (isTablet) {
              window.location =
                "/itinerary/" +
                res.itinerary._id +
                "/" +
                res.itinerary.itinerary_detail[0].children[0].children[0]._id;
            }
          }
        } else {
          if (res.error === "Itinerary is private") {
            let alert = new Alert();
            alert.error(res.error);
            this.props.history.push("/");
            return;
          }
          let itineraries = loadItem("itineraries");
          let index = itineraries.findIndex((item) => {
            return item._id === this.props.match.params.idItinerary;
          });
          this.setState(
            {
              itinerary: itineraries[index],
            },
            () => {
              this.apiService
                .getDetailCurrency(itineraries[index].currency)
                .then((response) => {
                  if (response.status === "success") {
                    this.saveToDraft(itineraries[index], response.currency);
                  }
                });
            }
          );
          this.props.saveCurrentItinerary(itineraries[index].currency);
          if (!this.props.match.params.idItineraryDetail) {
            if (isBrowser) {
              this.props.history.push("/404");
            } else if (isTablet) {
              window.location =
                "/itinerary/" +
                itineraries[index]._id +
                "/" +
                itineraries[index].itinerary_detail[0].children[0].children[0]
                  ._id;
            }
          }
        }
      });

    let itineraries = loadItem("itineraries");
    itineraries.forEach((item) => {
      socket.emit("join_itinerary", item._id);
    });

    this.setState({
      isLoading: false,
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.profile && !nextProps.profile && !this.state.itinerary.status) {
      this.props.history.push("/");
    }

    if (nextProps.profile) {
      if (!this.props.profile) {
        if (this.state.itinerary.belong_to_user === nextProps.profile._id) {
          let itineraries = loadItem("itineraries");
          let index = itineraries.findIndex((item) => {
            return item._id === this.state.itinerary._id;
          });
          if (index >= 0) {
            itineraries.splice(index, 1);
            saveItem("itineraries", itineraries);
          }
          window.onbeforeunload = null;
          window.location.reload(true);
        }
      }
    }
  };

  changeStatusSaved = () => {
    this.setState({
      saved: true,
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    window.onbeforeunload = null;
  };

  render() {
    const { isLoading } = this.state;
    return !isLoading ? (
      <>
        <BrowserView
          style={{
            height: "100%",
            display: "block",
          }}
        >
          <SocketContext.Provider value={socket}>
            <ContentDetailWithSocket
              itinerary={this.state.itinerary}
              location={this.props.location}
              params={this.props.match.params}
              saved={this.state.saved}
              changeStatusSaved={this.changeStatusSaved}
            />
          </SocketContext.Provider>
        </BrowserView>
        <MobileView>
          <ItineraryCreate
            changeStatusSaved={this.changeStatusSaved}
            saved={this.state.saved}
            itinerary={this.state.itinerary}
            className={styles.wrapper}
            socket={socket}
          />
        </MobileView>
      </>
    ) : (
      <div className={styles.itineraryDetail} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryDetail);
