import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CreateItineraryForm, FormSignInUp } from '../../components';
import { Row, Button, Col } from 'antd';
import styles from './index.scss';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

class ContentGetStarted extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      showFormLogin: false,
    };
  }

  closeFormLogin = () => {
    this.setState({
      showFormLogin: false,
    });
  };

  render() {
    return (
      <>
        <Dialog
          open={this.state.showFormLogin ? true : false}
          onClose={this.closeFormLogin.bind(this)}
          fullWidth={true}
          maxWidth={'xs'}
          aria-labelledby="form-login-social-media content"
        >
          <FormSignInUp closeForm={this.closeFormLogin.bind(this)} />
        </Dialog>
        <Row className={styles.contentGetStarted}>
          <Col xs={24} lg={14}>
            <div className="get-started-form">
              <div className="get-started-form__head">
                <h3 className="get-started-form__head__title">CREATE A TRIP</h3>
                <p className="get-started-form__head__option">
                  (NO SIGN-UP REQUIRED)
                </p>
              </div>
              <div className="get-started-form__content">
                <CreateItineraryForm getStarted={true} />
                {!this.props.profile && (
                  <div className="get-started-form__content__more-infor">
                    <span>
                      You are not logged in, all progress will be lost if you
                      exit Tyneri.com
                    </span>
                    <br />
                    <span>
                      <span
                        onClick={() => this.setState({ showFormLogin: true })}
                        className="show-login"
                      >
                        Log-in
                      </span>{' '}
                      to save your work or <Link to="/sign-up">Sign-up</Link> if
                      you are new to Tyneri.com
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} lg={10} className="section-browse-idea--mobile">
            <div className="section-browse-idea">
              <div className="section-browse-idea__img"></div>
              <span className="section-browse-idea__helper">
                Not sure what to do or where to go?
              </span>
              <Button
                size="large"
                onClick={() => this.props.history.push('/')}
                className="section-browse-idea__btn"
              >
                Browse for ideas
              </Button>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(ContentGetStarted));
