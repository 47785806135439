import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Menu } from 'antd';
import { MenuObject } from '../menu-login';
import { Link, useParams, useHistory } from 'react-router-dom';
import { getToken } from '../../services/authService';
import { connect } from 'react-redux';
import styles from './index.scss';

const menuHorizon = [
  MenuObject({
    name: 'Trending',
    link: '/',
  }),
  MenuObject({
    name: 'Top lists',
    link: '/top-lists',
  }),
  MenuObject({
    name: 'Top Attractions',
    link: '/top-attractions',
  }),
  MenuObject({
    name: (
      <>
        <Icon style={{ marginRight: 5 }} type="folder" />
        My Library
      </>
    ),
    link: '/my-library',
    hideOnNoLogin: true,
  }),
];

const MenuOnMobileTyneri = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { idItineraryDetail, idItinerary } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (props.profile) {
      setIsAuthenticated(true);
    }
    if (!getToken()) {
      setIsAuthenticated(false);
    }
  }, [props.profile]);

  const menu = (
    <Menu className="custom-menu" theme="dark" mode="vertical">
      {menuHorizon.map((item) => {
        if (item.hideOnNoLogin) {
          if (!isAuthenticated) return;
        }
        return (
          <Menu.Item>
            <Link target={item.target} to={item.link}>
              {item.name}
            </Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div className={styles.blockMenu}>
      {idItineraryDetail && idItinerary ? (
        /*<Menu mode="horizontal">
                    <Menu.Item>

                    </Menu.Item>
                </Menu>*/
        <Button
          size="large"
          type="link"
          onClick={() => history.push('/itinerary/' + idItinerary)}
          icon="arrow-left"
          className="ml-2 font-weight-bold"
          style={{ fontSize: 28, color: '#fdc632' }}
        />
      ) : (
        <Dropdown trigger={['click']} overlay={menu}>
          <Button
            size="large"
            type="link"
            className="ml-2 font-weight-bold"
            icon="menu"
            style={{ fontSize: 28, color: '#fdc632' }}
          />
        </Dropdown>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

export default connect(mapStateToProps, null)(MenuOnMobileTyneri);
