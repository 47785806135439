import Tab from '../../model/utility/Tab';
import QuotationList from '../../module/admin/components/quotation/List';

export const QUOTATION_TAB = [
  new Tab({
    id: 'new',
    label: 'New',
    position: 1,
    children: QuotationList,
    backGroundColor: '#4caf50',
    colorLabel: 'white',
  }),
  new Tab({
    id: 'pending',
    label: 'Pending',
    position: 2,
    children: QuotationList,
    backGroundColor: '#ffaf11',
    colorLabel: 'white',
  }),
  new Tab({
    id: 'confirmed',
    label: 'Confirmed',
    position: 3,
    children: QuotationList,
    backGroundColor: 'blue',
    colorLabel: 'white',
  }),
  new Tab({
    id: 'cancelled',
    label: 'Cancelled',
    position: 4,
    children: QuotationList,
    backGroundColor: 'red',
    colorLabel: 'white',
  }),
];

export const PARAM_QUERY_STATUS_NEW =
  '&is_cancel=0&is_confirm=0&is_pending=0&is_new=1';
export const PARAM_QUERY_STATUS_PENDING =
  '&is_cancel=0&is_confirm=0&is_pending=1&is_new=0';
export const PARAM_QUERY_STATUS_STATUS_CANCEL =
  '&is_cancel=1&is_confirm=0&is_pending=0&is_new=0';
export const PARAM_QUERY_STATUS_STATUS_CONFIRM =
  '&is_cancel=0&is_confirm=1&is_pending=0&is_new=0';
