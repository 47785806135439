import React, { Component } from "react";
import {Row, Col, Button, Skeleton} from "antd";
import { CardCustom } from "../..";
import DefaultBackground from "../../../assets/media/images/generic-activities-img.jpg";
import {RiseOutlined} from "@ant-design/icons";
import TitleTyneri, {BottomLine} from "../../modules/typography/title";

class ListCard extends Component {

    renderCardItem(item, index) {
        return (
            <div key={index} className="custom-card-tyneri">
                <a href={process.env.PUBLIC_URL+"/view-itinerary/" + item._id}>
                    <CardCustom
                        title={item.name}
                        estimated_budget={item.estimated_budget}
                        a_number_of_paxs={item.a_number_of_paxs}
                        currency_infor={item.currency_infor}
                        tag={item.city}
                        centerIconName="fas fa-play-circle"
                        bottomIconName="fas fa-ellipsis-h"
                        bgPhoto={item.cover ? item.cover : DefaultBackground}
                        totalReviews={30}
                        ratingAverage={4.5}
                        options={item.options ? item.options : []}
                        list_optionals={this.props.list_optionals}
                    />
                </a>
            </div>
        );
    }

    render() {
        const {params} = this.props;
        return (
            <Row className="row-itineraries">
                <div className="grid-header line-mobile">
                    <TitleTyneri lineBot={false} className="normal-font" text={<span>{this.props.title}</span>}/>
                </div>
                <Col md={6} xl={6} xxl={6}>
                    <div className="grid-header">
                        <span className="title-font huge">Top Itineraries</span>
                        <BottomLine/>
                    </div>
                </Col>
                <Col md={18} xl={18} xxl={18}>
                    {this.props.list && !!this.props.list.length ? (
                        <React.Fragment>
                            <Row type="flex" gutter={[16, 16]}>
                                {this.props.list.map((item, index) => {
                                    if (item.loading)
                                        return <Col key={index} md={12} lg={8} xl={6} xxl={6}><Skeleton/></Col>;
                                    else
                                        return <Col key={index} md={12} lg={8} xl={6} xxl={6}>{this.renderCardItem(item, index)}</Col>;
                                })}
                            </Row>
                            {params && params.page < params.total / params.limit ? (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        marginTop: 12,
                                        height: 32,
                                        lineHeight: '32px',
                                    }}
                                >
                                    <Button onClick={() => this.props.onLoad({
                                        page: params.page + 1,
                                        limit: params.limit,
                                        total: params.total
                                    })} type="primary" shape="round" >Show more</Button>
                                </div>
                            ) : null}
                        </React.Fragment>
                    ) : null}
                </Col>

            </Row>
        );
    }
}

export default ListCard;
