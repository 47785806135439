import React, { Component } from "react";
import {
  ContentAllTopLists
} from "../../components";
import ApiService from "../../services/api.service";
import { connect } from "react-redux";
import {
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout
} from "../../store/actions";

const mapDispatchToProps = dispatch => {
  return {
    saveCurrentItinerary: itinerary => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: amount => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: info => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    }
  };
};

class AllTopLists extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: true,
      data: []
    };
  }

  componentDidMount = async () => {
    document.title = "Tyneri";
    this.apiService.getAllTopList().then(res => {
      if (res.status === "success"){
        this.setState({
          data: res.data
        })
      }
    })
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    return <ContentAllTopLists data={this.state.data} />
  }
}

export default connect(null, mapDispatchToProps)(AllTopLists);
