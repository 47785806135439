/*global google*/
import React, {useEffect, useState} from "react";
import {Col, Row, Typography} from "antd";
import MyBreadcrumb from "../../components/breadcrumb";
import styles from "./index.scss";
import {withRouter} from "react-router-dom";
import moment from "moment";
import TyneriCarousel from "../../components/tyneri-carousel/tyneri-carousel";
import ShareSection from "../../components/detail-itinerary/share-section";
import {FaMapMarkerAlt, FaPhoneSquare} from "react-icons/fa";
import AspectRatio from "react-aspect-ratio";
import MapPreview from "../../components/map-preview/map-preview";
import RightContentAttraction from "../../components/content-attraction/right-content";
import { getNameAuthor } from "../../services/utils.service";

const {Title, Text, Paragraph} = Typography;

const NewContentAttraction = (props) => {
    const [map, setMap] = useState();
    const item = props.item;

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (item.data.address) {
            let place = {
                lat: item.data.lat,
                lng: item.data.lng,
            };
            let map = new google.maps.Map(document.getElementById("map"), {
                zoom: 18,
                center: place,
            });
            new google.maps.Marker({
                position: place,
                map: map,
            });
            setMap(map);
        }
    }, [item]);

    return (
        <Row gutter={16} type="flex" className={styles.newContainer}>
            <Col
                xs={24}
                md={16}
                lg={16}
                xl={16}
                xxl={16}
            >
                <div className="grid-header bread">
                    <MyBreadcrumb list={[
                        {link: '/', title: 'Home'},
                        {link: '/top-attractions', title: 'Top attractions'},
                        {title: item.data.title}
                    ]} />
                </div>
                <div className="tag-and-share">
                    <span className="tag-category">
                        {item.category_name}
                    </span>
                    <ShareSection/>
                </div>
                <Title level={3} className="head-title">
                    {item.data.title}
                    <Text type="secondary" className="author">
                        By <span className="author__name">{getNameAuthor(item.user_infor, item.company)}</span> - {moment(new Date(item.created_at)).fromNow()}
                    </Text>
                </Title>
                <div className="content">
                    <Row gutter={12} type="flex">
                        <Col xl={12} xxl={12} lg={12} md={12} sm={24} xs={24}>
                            <TyneriCarousel className="slide-images" images={item.data.images}/>
                        </Col>
                        {item.data.address ? <Col xl={12} xxl={12} lg={12} md={12} sm={24} xs={24}>
                            <AspectRatio style={{
                                maxWidth: '100%'
                            }} ratio={16/9}><div id="map" /></AspectRatio>
                        </Col> : null}
                    </Row>
                    <Paragraph>
                        <span dangerouslySetInnerHTML={{__html: item.data.descriptions}}/>
                    </Paragraph>
                    <div className="location-info">
                        {item.data.address && (
                            <div className="info-summary">
                                <FaMapMarkerAlt />
                                <div>{item.data.address}</div>
                            </div>
                        )}
                        {item.data.phone_number && (
                            <div className="info-summary">
                                <FaPhoneSquare />
                                <div>{item.data.phone_number}</div>
                            </div>
                        )}
                    </div>
                    <div className="section-listed">

                    </div>
                    <MapPreview map={map} place_id={item.place_id}/>
                </div>
            </Col>
            <Col
                xs={24}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
            >
                <div className="grid-header bread">
                    <h5 className="main-title-section m-0">You might also like</h5>
                </div>
                <RightContentAttraction descLimit={50} className="list-like" imageSize="small" limit={21} name={item.category} grid={{column: 2, sm: 1, xs: 1, md: 1, lg: 2, xl: 2, xxl: 3}}/>
            </Col>
        </Row>
    );
}

export default withRouter(NewContentAttraction);
