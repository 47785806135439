import React, {useEffect, useState} from "react";
import TopListsServices from "../../../services/topList.service";
import styles from './index.scss';
import {Card, Col, List, Row, Skeleton, Spin} from "antd";
import {getImageFromUrl, isMyResource} from "../../../services/utils.service";
import {configBaseUrl} from "../../../config";
import ImageHelper from "../../../functions/image";
import DefaultBackground from "../../../assets/media/images/generic-activities-img.jpg";
import StringHelper from "../../../helper/stringHelper";
import _ from "lodash";
import {Link} from "react-router-dom";

const {Meta} = Card;

const TopView = () => {
    const [topListViewLeft, setTopListViewLeft] = useState({});
    const [topListViewRight, setTopListViewRight] = useState([]);

    useEffect(() => {
        async function fetchData () {
            let res = await TopListsServices().getTopView();
            if (res.status === 'success') {
                setTopListViewLeft(res.data.shift());
                setTopListViewRight(res.data);
            }
        }
        fetchData();
    }, []);

    const renderLeft = () => {
        let item = topListViewLeft;
        if (_.isEmpty(item))
            return <Skeleton loading={true} />;
        return <Link to={'view-top-list/'+item._id}><Card
            className="head-item"
            hoverable
            cover={<ImageHelper notFound={DefaultBackground} src={getImageFromUrl(isMyResource(item.cover) ? configBaseUrl.baseURL + item.cover : '', "small")}/>}
        >
            <Meta title={item.title} description={<span dangerouslySetInnerHTML={{__html: StringHelper().stripeHtml(item.description, 200)}} />} />
        </Card></Link>;
    }

    const renderRight = () => {
        let items = topListViewRight;
        if (_.isEmpty(items))
            return <Skeleton active loading={true} />;
        return(
            <List
                itemLayout="vertical"
                size="large"
                dataSource={items}
                renderItem={item => (
                    <List.Item
                        key={item.title}
                        style={{borderBottom: 0}}
                    >
                        <div className="item-list">
                            <div className="item-img-block">
                                <Link to={'view-top-list/'+item._id}><ImageHelper className='img-fluid' notFound={DefaultBackground} src={getImageFromUrl(isMyResource(item.cover) ? configBaseUrl.baseURL + item.cover : '', "small")}/></Link>
                            </div>
                            <List.Item.Meta
                                title={<Link to={'view-top-list/'+item._id}>{item.title}</Link>}
                                description={StringHelper().stripeHtml(item.description, 200)}
                            />
                        </div>
                    </List.Item>
                )}
            />
        );
    }

    return <div className={styles.topView}>
        <Row type="flex" align="top" gutter={24}>
            <Col className="left-article" xs={24} md={24} lg={12} xl={12} xxl={12}>
                {renderLeft()}
            </Col>
            <Col className="right-article mt-3 mt-lg-0 mt-xl-0" xs={24} md={24} lg={12} xl={12} xxl={12}>
                {renderRight()}
            </Col>
        </Row>
    </div>;
}

export default TopView;
