import React, { Component } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { withRouter } from "react-router";
import {Button, Result, Typography} from "antd";

const {Title} = Typography;

class ErrorPage extends Component {
  componentDidMount = () => {
    document.title = "404 - Page not found - Tyneri";
  };

  handleGoBack = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      /*<div id="notfound">
        <div className="notfound-bg" />
        <div className="notfound">
          <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
            <div className="notfound-404">
              <h1>404</h1>
            </div>
            <h2>Sorry, this content isn't available right now</h2>
          </ScrollAnimation>
          <div onClick={this.handleGoBack.bind(this)} className="goback-btn">
            Go back
          </div>
          <a href="/" className="contact-btn">
            Contact us
          </a>
          <div className="notfound-social">
            <a href="#">
              <i className="fa fa-facebook" />
            </a>
            <a href="#">
              <i className="fa fa-twitter" />
            </a>
            <a href="#">
              <i className="fa fa-pinterest" />
            </a>
            <a href="#">
              <i className="fa fa-google-plus" />
            </a>
          </div>
        </div>
      </div>*/
        <Result
            className="new-error-page"
            status="404"

            title={<Title>404</Title>}
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button className="font-weight-bold" shape="round" size="large" onClick={() => this.handleGoBack()} type="primary">Back Home</Button>}
        />
    );
  }
}

export default withRouter(ErrorPage);
