import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import styles from './index.scss';
import FormSignUp from '../form-sign-in-up/components/FormSignUp';

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

class ContentSignUp extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    return (
      <Row className={styles.contentSignUp}>
        <Col xs={24} lg={14}>
          <div className="sign-up-form">
            <div className="sign-up-form__head">
              <h3 className="sign-up-form__head__title">Get started</h3>
              <p className="sign-up-form__head__option">
                Sign up and start using Tyneri. No credit card needed.
              </p>
            </div>
            <div className="sign-up-form__content">
              <FormSignUp />
            </div>
          </div>
        </Col>
        <Col xs={24} lg={10} className="section-browse-idea--mobile">
          <div className="section-browse-idea">
            <div className="section-browse-idea__img"></div>
            <span className="section-browse-idea__helper-main">
              EASY AND FREE
            </span>
            <span className="section-browse-idea__helper-secondary">
              trip planner for everyone
            </span>
          </div>
        </Col>
      </Row>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(ContentSignUp));
