import axios from '../axios-config';
import queryString from 'query-string';

export const save = (data, sendEmail = false) => {
  try {
    return axios
      .post('quotation/save?sendEmail=' + sendEmail, {
        ...data,
      })
      .then((res) => {
        return res.data;
      });
  } catch (error) {
    console.log(error);
  }
};
export const getById = (id) => {
  try {
    return axios.get('quotation/get?id=' + id).then((res) => {
      return res.data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const getListData = (params, pageNumber, limit) => {
  try {
    return axios
      .get(`quotation/list?pageNumber=${pageNumber}&limit=${limit}&${params}`)
      .then((res) => {
        return res.data;
      });
  } catch (error) {
    console.log(error);
  }
};

export const convertItineraryToQuotation = (id) => {
  try {
    return axios
      .get('quotation/convert-itinerary-to-quotation?id=' + id)
      .then((res) => {
        return res.data;
      });
  } catch (error) {
    console.log(error);
  }
};

export const deleteQuotation = (id) => {
  try {
    return axios.delete('quotation/delete/' + id).then((res) => {
      return res.data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const getConfirmQuotation = (id) => {
  try {
    return axios.get('quotation/get-confirm/' + id).then((res) => {
      return res.data;
    });
  } catch (error) {
    console.log(error);
  }
};
export const checkExist = (id) => {
  try {
    return axios.get('quotation/check-exist/' + id).then((res) => {
      return res.data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const submitToken = (token) => {
  try {
    return axios.post('quotation/submit-token/' + token).then((res) => {
      return res.data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const submitConfirm = (data) => {
  try {
    return axios
      .post('quotation/submit-confirm/', {
        ...data,
      })
      .then((res) => {
        return res.data;
      });
  } catch (error) {
    console.log(error);
  }
};

export const rejected = (id) => {
  try {
    return axios.post('quotation/rejected/' + id).then((res) => {
      return res.data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendMail = (data) => {
  try {
    return axios
      .post('quotation/send-mail', {
        ...data,
      })
      .then((res) => {
        return res.data;
      });
  } catch (error) {
    console.log(error);
  }
};

export const canceled = (id) => {
  try {
    return axios.post('quotation/canceled/' + id).then((res) => {
      return res.data;
    });
  } catch (error) {
    console.log(error);
  }
};
