import { actionTypes } from '../../constants/actionTypes'

var initialState = {
    open: false,
    transportation: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_FORM_VIEW_EDIT_TRANSPORTATION:
            state.open = true
            state.transportation = action.transportation
            return {
                open: state.open,
                transportation: state.transportation
            }
        case actionTypes.CLOSE_FORM_VIEW_EDIT_TRANSPORTATION:
            state.open = false
            return {
                open: state.open,
                transportation: state.transportation
            }
        default:
            return state
    }
}
