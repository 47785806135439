import React, { useState } from "react";
import { Form, Avatar, Modal, Input, Button } from "antd";

import apiService from "../../../../../../services/api.service";

function NewFolder(props) {
  const [open, setOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const form = props.form;
  const itemList = props.itemList;
  const { getFieldDecorator } = form;
  const ApiService = apiService();

  function handleCreateFolder() {
    setSubmitLoading(true);
    form.validateFields(["name"], (err, values) => {
      if (!err) {
        values = {
          ...values,
          _id: itemList._id,
        };
        ApiService.createFolder(values).then((res) => {
          if (res.status === "success") {
            setOpen(false);
            props.setIdMoveChecked(res.folder._id)
            props.openMoveTo();
          }
        });
      } else {
        setSubmitLoading(false);
      }
    });
  }

  return (
    <>
      <Modal
        visible={open}
        onCancel={() => {
          setOpen(false);
          props.openMoveTo();
        }}
        footer={
          <Button
            key="submit"
            onClick={handleCreateFolder}
            type="primary"
            loading={submitLoading}
          >
            Create
          </Button>
        }
      >
        <Form>
          <Form.Item label="Folder name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please input your folder name!",
                },
              ],
            })(<Input autoFocus placeholder="Folder name" />)}
          </Form.Item>
        </Form>
      </Modal>
      <Avatar
        onClick={() => {
          setOpen(true);
          props.setOpenMoveto(false);
        }}
        shape="square"
        size="large"
        style={{
          color: "#fff",
          backgroundColor: "#ccc",
          cursor:'pointer'
        }}
      >
        +
      </Avatar>{" "}
      New folder
    </>
  );
}

export default Form.create()(NewFolder);
