import React, { useState } from "react";
import { Form, Input, Button, Modal, Icon, Divider } from "antd";

import "./index.scss";
import Api from "../../../../../../services/userApi.service";

const FormItem = Form.Item;

function FormNewJob(props) {
  const [openModal, setOpenModal] = useState(false);
  const form = props.form;
  const { getFieldDecorator } = form;
  const api = Api();

  function saveJob(e) {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const dataSave = {
          name: values["name"],
        };
        api.saveJob(dataSave).then((res) => {
          const data = res.data;
          if (!data.error) {
            props.setOpenFormEdit(true);
            setOpenModal(false);
            props.fetchAllJob();
            props.formParent.setFieldsValue({
              job:  data.item._id,
            });
          }
        });
      }
    });
  }

  const ModelNewJob = () => {
    return (
      <Modal
        title="New Job"
        footer={
          <div className="new-job-modal__footer">
            <Button
              className="new-job-modal__footer__save"
              type="primary"
              onClick={(e) => saveJob(e)}
            >
              Save
            </Button>
          </div>
        }
        className="new-job-modal"
        visible={openModal}
        onCancel={() => {
          props.setOpenFormEdit(true);
          setOpenModal(false);
        }}
      >
        <Form name="new-job-form">
          <FormItem label="New Job">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please input name",
                  whitespace: true,
                },
              ],
            })(<Input />)}
          </FormItem>
        </Form>
      </Modal>
    );
  };
  return (
    <>
      {ModelNewJob()}

      <div
        className="new-job"
        style={{ padding: "4px 8px", cursor: "pointer" }}
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => {
          props.setOpenFormEdit(false);
          setOpenModal(true);
        }}
      >
        <Divider style={{ margin: "4px 0" }} />
        <span
          className="new-job__text"
          onClick={() => {
            props.setOpenFormEdit(false);
            setOpenModal(true);
          }}
        >
          <i>+</i> Other job
        </span>
      </div>
    </>
  );
}
export default Form.create()(FormNewJob);
