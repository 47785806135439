import React, { Component } from "react";
import { ContentCreateTopLists } from "../../components";
import ApiService from "../../services/api.service";
import { connect } from "react-redux";
import Alert from "../../module/admin/components/alert";

class CreateTopLists extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      top_list: null,
      isLoading: true,
    };
  }

  handleOnBeforeUnload = (e) => {
    const message = "Are you sure?";
    e.returnValue = message;
    return message;
  };

  componentDidMount = async () => {
    this._isMounted = true;
    window.onbeforeunload = this.handleOnBeforeUnload;
    var user_id;
    if (this.props.profile) user_id = this.props.profile._id;
    else user_id = null;

    if (this.props.match.params.idTopList && this._isMounted) {
      this.setState({
        isLoading: true,
      });
      await this.apiService
        .getTopList(this.props.match.params.idTopList, user_id)
        .then((res) => {
          if (res.status === "success") {
            if (res.top_list) {
              this.setState({
                top_list: res.top_list,
                isLoading: false,
              });
            } else this.props.history.push("/top-lists");
          } else {
            let alert = new Alert();
            alert.error(res.error);
            this.props.history.push("/top-lists");
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    window.onbeforeunload = null;
  };

  render() {
    if (this.state.isLoading) return <></>;
    else {
      if (this.props.match.params.idTopList) {
        return <ContentCreateTopLists top_list={this.state.top_list} />;
      } else {
        return <ContentCreateTopLists top_list={null} />;
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

export default connect(mapStateToProps, null)(CreateTopLists);
