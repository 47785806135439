import React, { useEffect, useState } from "react";
import style from "./index.scss";
import { Button } from "antd";
import { loadItem, saveItem } from "../../services/storage.service";
import { withRouter } from "react-router-dom";
import { getAllLeaf, getNameItinerary } from "../../services/utils.service";

const BoxDraft = (props) => {
  const [draft, setDraft] = useState(null);
  useEffect(() => {
    let itineraryDraft =
      loadItem("itineraryDraft") !== null ? loadItem("itineraryDraft") : null;
    if (itineraryDraft && itineraryDraft.itineraries.length > 0)
      setDraft(itineraryDraft.itineraries[0]);
  }, []);

  const continueEditting = () => {
    props.history.push(
      `/itinerary/${draft._id}/${getAllLeaf(draft.itinerary_detail)[0]._id}`
    );
  };

  const deleteIt = () => {
    let itineraryDraft =
      loadItem("itineraryDraft") !== null ? loadItem("itineraryDraft") : null;
    if (itineraryDraft) {
      itineraryDraft.itineraries.splice(0, 1);
      saveItem("itineraryDraft", itineraryDraft);
      if (itineraryDraft.itineraries.length > 0) {
        setDraft(itineraryDraft.itineraries[0]);
      } else {
        setDraft(null);
      }
    }
  };

  return draft ? (
    <div className={style.boxDraft}>
      <div className="bottom-draft">
        <div className="bottom-draft__content">
          <p>Don't lose your unfinished itinerary.</p>
          <p>
            <strong>{getNameItinerary(draft)}</strong>
          </p>
        </div>
        <Button size="large" type="danger" className="mr-2" onClick={deleteIt}>
          Delete it
        </Button>
        <Button size="large" type="primary" onClick={continueEditting}>
          Continue editing
        </Button>
      </div>
    </div>
  ) : null;
};

export default withRouter(BoxDraft);
