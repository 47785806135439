import { actionTypes } from '../../constants/actionTypes'

var initialState = 0

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.HANDLE_SLICK_GO_TO:
            state = action.index
            return state
        default:
            return state
    }
}
