import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import $ from "jquery";
import {getAllLeaf, getNameItinerary} from "../../services/utils.service";
import Overview from "./overview";
import AuthorInfor from "./author-infor";
import ListPlace from "./list-place";
import {withRouter} from "react-router-dom";
import {isBrowser} from "react-device-detect";
import {ItineraryDetails} from "../index";
import ScrollspyNav from "react-scrollspy-nav";
import {connect} from "react-redux";
import styles from "./index.scss";
import Sticky from "react-sticky-el";
import _ from "lodash";

const LeftContent = ({itinerary, isPreview, ...props}) => {

    const [listItem, setListItem] = useState([]);
    const [listAllDate, setListAllDate] = useState([]);

    useEffect(() => {
        let tempList = [];
        let allDate = getAllLeaf(itinerary.itinerary_detail);
        allDate.forEach((item) => {
            tempList.push(`${item._id}-1`);
        });
        setListItem(tempList);
        setListAllDate(allDate);
        scroll(tempList);
    }, [itinerary.itinerary_detail]);

    const scroll = (list) => {
        $('.ant-layout-content').on('scroll', function () {
            let data = [];
            list.forEach((id) => {
                let item =$('#'+id);
                let target = item.offset() ? item.offset().top - 100 : 0;
                $('a[href="#'+id+'"]').removeClass('_active');
                if (target < 0) {
                    data.push({
                        id,
                        offset: target
                    });
                }
            });
            if (!_.isEmpty(data)) {
                let setActive = _.first(_.orderBy(data, ['offset'], ['desc']));
                $('a[href="#'+setActive.id+'"]').addClass('_active');
            }
        });
    }

    const renderScroll = () => {
        return (
            itinerary && (
                <ScrollspyNav
                    scrollTargetIds={listItem}
                    activeNavClass="_active"
                    scrollDuration="1000"
                    offset={-50}
                >
                    <ItineraryDetails itinerary_detail={itinerary.itinerary_detail} preview={true} params={props.match.params} />
                </ScrollspyNav>
            )
        );
    }

    const onFixedToggle = val => {
        let heightSet = $('.set-height');
        if (val) {
            let height = isBrowser ? $('.ant-layout-content').height() : (
                heightSet.height() > $(window).height() || heightSet.height() === 0 ? $(window).height() : 'auto'
            );
            heightSet.height(height);
        }else {
            heightSet.height('auto');
        }
    }

    return (
        <Row gutter={16} className={styles.leftContent +' left-content'}>
            <Col md={8} lg={8} xl={8} xxl={8} style={{position: 'unset'}} className="to-sticky">
                {listItem ? <Sticky onFixedToggle={onFixedToggle} scrollElement={isBrowser ? '.ant-layout-content' : window} boundaryElement=".left-content">
                    <nav
                        id="ember13"
                        className="si-day-nav set-height left-bar _fixed ember-view hide-on-mobile"
                    >
                        {renderScroll()}
                    </nav>
                </Sticky> : null}
            </Col>
            <Col className="right-bar" md={16} lg={16} xl={16} xxl={16}>
                <div className="section-title-itinerary">
                    <h2 className="name">
                        {getNameItinerary({
                            ...itinerary,
                            currency_infor: props.currency_infor,
                        })}
                    </h2>
                </div>
                <Overview isPreview={isPreview} itinerary={itinerary} currency_infor={props.currency_infor} listAllDate={listAllDate}/>
                <div className="description">
                    {itinerary.description}
                </div>
                <AuthorInfor isPreview={isPreview} history={props.history} itinerary={itinerary}/>
                <ListPlace location={props.location} listAllDates={listAllDate} itinerary={itinerary}/>
            </Col>
        </Row>
    );
}

const mapStateToProps = (state) => {
    return {
        currency_infor: state.itineraryReducer.currency_infor,
    };
};

export default withRouter(connect(mapStateToProps, null)(LeftContent));
