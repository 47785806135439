import React from 'react';
import {Button, Carousel, Empty, Icon, PageHeader, Timeline} from "antd";
import _ from "lodash";
import {
    calcEndTime,
    format24hTo12h,
    formatSecondsToDuration,
    getImageFromUrl,
    isMyResource
} from "../../services/utils.service";
import {configBaseUrl} from "../../config";
import defaultImg from "../../assets/media/images/generic-activities-img.jpg";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {openFormQuote, openFormViewEditPlace} from "../../store/actions";
import {connect} from "react-redux";
import location from "../../assets/media/images/location.png";
import {SRLWrapper} from "simple-react-lightbox";
import {Collapse} from "@material-ui/core";
import MoneyHelper from "../../helper/moneyHelper";
import IconQuote from "../../assets/media/images/quote.jpg";
import Tooltip from "@material-ui/core/Tooltip";

const mapDispatchToProps = (dispatch) => {
    return {
        openFormViewEditPlace: (item) => {
            dispatch(openFormViewEditPlace(item));
        },
        openFormQuote: (item) => {
            dispatch(openFormQuote(item));
        }
    };
};

const mapStateToProps = (state) => {
    return {
        currency_infor: state.itineraryReducer.currency_infor
    };
};

class _TimeLineContent extends React.Component{
    callbacks = {
        onSlideChange: slide => console.log(slide),
        onLightboxOpened: current => console.log(current),
        onLightboxClosed: current => console.log(current),
        onCountSlides: total => console.log(total)
    };

    constructor(props) {
        super(props);
        const viewerIsOpen = {};
        viewerIsOpen[props.index] = false;
        this.state = {
            viewerIsOpen: viewerIsOpen,
            currImg: 0,
            expand: props.expand
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (this.props.expand !== prevProps.expand)
            this.setState({expand: this.props.expand});
    }

    goNext = () => {
        this.setState({
            currImg: this.state.currImg + 1
        });
    };

    goBack = () => {
        this.setState({
            currImg: this.state.currImg - 1
        });
    };

    openEditPlace = (item) => {
        this.props.openFormViewEditPlace(item);
        this.setState({
            selectItem: item
        });
    };

    handleShowQuote = () => {
        this.props.openFormQuote(this.props.item);
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {item, index} = this.props;
        const imageList = item.data.actual_data.images ? item.data.actual_data.images.slice() : [];
        imageList.shift();
        const imageViewer = [];
        return (
            <Timeline.Item
                key={index}
                id={`${item._id}-${index}`}
                className="places-hover"
                onClick={() => this.setState({expand: !this.state.expand})}
            >
                <PageHeader
                    title={item.category !== 'Infor' && (<img alt={item.data.actual_data.title} src={
                        !_.isEmpty(item.data.actual_data.images) ?
                            getImageFromUrl(isMyResource(
                                item.data.actual_data.images[0].preview
                            ) ? configBaseUrl.baseURL + item.data.actual_data.images[0].preview
                                : item.data.actual_data.images[0].preview, "small") : defaultImg
                    } />)}
                    subTitle={
                        <React.Fragment>
                            <Typography style={{
                                color: "#1890ff",
                                fontWeight: 'bold',
                                fontSize: 14,
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: "start"
                            }} onClick={() => this.openEditPlace(item)} variant="body1" component="strong">{item.data.actual_data.title} <Icon style={{marginLeft: 5}} type="edit" /></Typography>
                            <Typography style={{
                                fontSize: 10
                            }} variant="caption" component="small">{item.data.actual_data.address}</Typography>
                        </React.Fragment>
                    }
                    extra={[
                        <Tooltip title="Quote">
                            <Button shape="circle">
                                <img
                                    alt="Quote"
                                    className="icon-quote"
                                    src={IconQuote}
                                    onClick={this.handleShowQuote.bind(this)}
                                />
                            </Button>
                        </Tooltip>
                    ]}
                >
                    <span className="timing">
                        {item.data.actual_data.time_start ? (
                            <Typography component="small" variant="inherit">
                                <AccessTimeIcon fontSize="small" style={{
                                    fontSize: 12
                                }} />
                                {" "+format24hTo12h(
                                    item.data.actual_data.time_start
                                )}
                            </Typography>
                        ) : null}
                        {
                            !item.data.actual_data.time_start && item.data.actual_data.duration ? (
                                <Typography component="small" variant="inherit">
                                    <AccessTimeIcon />
                                    Duration:{" "}
                                    {formatSecondsToDuration(
                                        item.data.actual_data.duration
                                    )}
                                </Typography>
                            ) : null
                        }
                        {
                            item.data.actual_data.time_start &&
                            item.data.actual_data.duration > 0 && (
                                <Typography component="small" variant="inherit">
                                    {" "}-{" "}
                                    {calcEndTime(
                                        item.data.actual_data.time_start,
                                        item.data.actual_data.duration
                                    )}
                                </Typography>
                            )
                        }
                    </span>
                    <div className="small-detail">
                        {(item.data.actual_data.booked_through ||
                            item.data.actual_data.confirmation ||
                            item.data.actual_data.amount) && (
                            <div
                                className="content-event-detail"
                            >
                                {item.data.actual_data.booked_through && (
                                    <div className="content">
                                        Booked through:{" "}<span className="value">{item.data.actual_data.booked_through}</span>
                                    </div>
                                )}
                                {item.data.actual_data.confirmation && (
                                    <div className="content">
                                        Reservation code:{" "}<span className="value">{item.data.actual_data.confirmation}</span>
                                    </div>
                                )}
                                {item.data.actual_data.amount && this.props.currency_infor && (
                                    <div className="content">
                                        Price:{" "}<span className="value">{MoneyHelper(item.data.actual_data.amount).format({
                                        symbol: this.props.currency_infor.symbol,
                                        precision: this.props.currency_infor.decimal_digits
                                    })} {this.props.currency_infor.code}</span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {item.data.actual_data.descriptions && (
                        <div className="detail-content">
                            <Collapse
                                in={this.state.expand}
                            >
                                <div dangerouslySetInnerHTML={{__html: item.data.actual_data.descriptions}}/>
                                <SRLWrapper>
                                    <Carousel infinite={false} slidesToShow={3} slidesToScroll={1}>
                                        {imageList.map((o, i) => {
                                            imageViewer.push({
                                                src: getImageFromUrl(isMyResource(o.preview) ? configBaseUrl.baseURL + o.preview : o.preview, "large"),
                                                thumbnail: getImageFromUrl(isMyResource(o.preview) ? configBaseUrl.baseURL + o.preview : o.preview, "small")
                                            });
                                            return (
                                                <div key={i} onClick={() => {
                                                    const {viewerIsOpen} = this.state;
                                                    viewerIsOpen[index] = true;
                                                    this.setState({viewerIsOpen: viewerIsOpen, currImg: i})
                                                }} className="imgSlider">
                                                    {/*<div className="image" style={{
                                                        backgroundImage: "url('"+getImageFromUrl(isMyResource(o.preview) ? configBaseUrl.baseURL + o.preview : o.preview, "small")+"')"
                                                    }}/>*/}
                                                    <img className="image" src={getImageFromUrl(isMyResource(o.preview) ? configBaseUrl.baseURL + o.preview : o.preview, "small")} alt=""/>
                                                </div>
                                            );
                                        })}
                                    </Carousel>
                                </SRLWrapper>
                            </Collapse>
                        </div>
                    )}
                    {item.quote && (
                        <div className="setting-transport">
                            <blockquote
                                onClick={this.handleShowQuote.bind(this)}
                                // style={!this.props.expand ? hiddenQuote : null}
                            >
                                <div className="quote">{item.quote}</div>
                            </blockquote>
                        </div>
                    )}
                </PageHeader>
            </Timeline.Item>
        );
    }
}

class TimelineView extends React.Component<{source: [], renderItem?: (item: T, index: number) => React.ReactNode}> {
    getContent() {
        let content;
        if (this.props.renderItem) {
            content = _.map(this.props.source, (ie, i) => {
                return this.props.renderItem(ie, i);
            });
        }else {
            content = this.props.children;
        }
        return content;
    }

    render() {
        return (
            this.props.source ? (
                <Timeline>
                    {this.getContent()}
                </Timeline>
            ) : <Empty/>
        );
    }
}

const TimeLineContent = connect(mapStateToProps, mapDispatchToProps)(_TimeLineContent);

export {TimelineView, TimeLineContent};
