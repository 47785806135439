import React, { useState } from "react";
import { Button, Form, Modal, Radio, Avatar } from "antd";

import apiService from "../../../../../../services/api.service";
import alert from "../../../alert";
import NewFolder from "./newFolder";
import { saveItem, loadItem } from "../../../../../../services/storage.service";

function MoveTo(props) {
  const form = props.form;
  const itemList = props.itemList;
  const itemItinerary = props.itemItinerary;
  const { getFieldDecorator } = form;
  const [ idMovetoChecked, setIdMoveChecked] = useState('');
  const [ open, setOpenMoveto] = useState(false);
  const [ folders, setListFolder] = useState([]);
  const ApiService = apiService();
  const Alert = new alert();

  function openModal() {
    setOpenMoveto(true);
    getFolder();
  }

  function getFolder() {
    ApiService.getMyFolders().then((res) => {
      if (res.status === "success") {
        setListFolder(res.folders);
      }
    });
  }


  function handleChooseFolder() {
    props.form.validateFields((err, values) => {
      if (!err) {
        values = {
          ...values,
          _id: itemItinerary._id,
        };
        ApiService.chooseFolder(values).then(async (res) => {
          if (res.status === "success") {
            Alert.success(`Success`);
            props.getMyFolder();
            setOpenMoveto(false);
            if (loadItem("itineraries")) {
              let itineraries = loadItem("itineraries");
              let index = itineraries.findIndex((item) => {
                return item._id === values._id;
              });
              if (index >= 0) {
                itineraries[index].belong_to_folder = values.folder;
                saveItem("itineraries", itineraries)
              }
            }

          } else {
            Alert.error("Error");
          }
        });
      }
    });
  }

  function getDefaultChecked() {
    return itemList._id !== null ? itemList._id : 0;
  }
  return (
    <>
      <Modal
        visible={open}
        onCancel={() => {
          setOpenMoveto(false)
          if(props.listFolder.length !== folders.length) {
            props.getMyFolder();
          }
          setIdMoveChecked('');
        }}
        title="Move to"
        footer={
          <>
            <Button key="submit" onClick={handleChooseFolder} type="primary">
              Move
            </Button>
          </>
        }
      >
        <Form>
          {!!folders.length && (
            <Form.Item name="radio-group">
              {getFieldDecorator("folder", {
                initialValue: idMovetoChecked !== '' ? idMovetoChecked : getDefaultChecked(),
              })(
                <Radio.Group>
                  {folders.map((item, key) => (
                    <Radio value={item._id} key={key}>
                      <Avatar
                        shape="square"
                        size="large"
                        style={{
                          color: "#f56a00",
                          backgroundColor: "#fde3cf",
                        }}
                      >
                        {item.name.charAt(0).toUpperCase()}
                      </Avatar>{" "}
                      {item.name}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </Form.Item>
          )}
          <NewFolder getMyFolder={props.getMyFolder}
                    itemList={itemList}
                    openMoveTo={ openModal }
                    setOpenMoveto={setOpenMoveto}
                    setIdMoveChecked={ setIdMoveChecked } />
        </Form>
      </Modal>
      <Button className="btn-move" onClick={openModal}>
        Move to
      </Button>
    </>
  );
}

export default Form.create()(MoveTo);
