import React, { Component } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ApiService from '../../../services/api.service';

import { connect } from 'react-redux';
import { Empty, Button, Modal } from 'antd';
import { updateListLoad, closeFormLoad } from '../../../store/actions';
import {
  getImageFromUrl,
  isMyResource,
  formatViews,
} from '../../../services/utils.service';
import { configBaseUrl } from '../../../config';
import defaultImg from '../../../assets/media/images/generic-activities-img.jpg';
import styles from '../index.scss';
import moment from 'moment';
import { withRouter } from 'react-router';
import Alert from '../../../module/admin/components/alert';
import { Typography } from 'antd';

const { Text } = Typography;

const mapDispatchToProps = (dispatch) => {
  return {
    closeFormLoad: () => {
      dispatch(closeFormLoad());
    },
    updateListLoad: (list) => {
      dispatch(updateListLoad(list));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

class ListTopLists extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: false,
    };
  }

  showConfirm = (_id) => {
    Modal.confirm({
      content: 'Are you sure you want to delete this list?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.apiService.deleteTopList(_id).then((res) => {
          if (res.status === 'success') {
            this.props.setNewListTopLists();
            let alert = new Alert();
            alert.success('Delete successfully');
          }
        });
      },
      onCancel() {},
    });
  };

  render() {
    return (
      <Table className={styles.listMyTyneries}>
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Category</Th>
            <Th>Views</Th>
            <Th>Last updated</Th>
            <Th>Show</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          {this.props.data.length > 0 ? (
            this.props.data.map((item, key) => (
              <Tr className="body-row" key={key}>
                <Td>
                  <div className="title-image">
                    <div className="image">
                      <img
                        width="70px"
                        src={
                          item.cover
                            ? getImageFromUrl(
                                isMyResource(item.cover)
                                  ? configBaseUrl.baseURL + item.cover
                                  : item.cover,
                                'large'
                              )
                            : defaultImg
                        }
                        alt=""
                      />
                    </div>
                    <div className="item_detail">
                      <a href={'/view-top-list/' + item._id} target="blank">
                        {item.title}
                      </a>
                    </div>
                  </div>
                </Td>
                <Td>{item.category.name}</Td>
                <Td>{formatViews(item.views, 1)}</Td>
                <Td>{moment(new Date(item.updated_at)).format('LLL')}</Td>
                <Td>
                  {item.show ? (
                    <Text type="success">Public</Text>
                  ) : (
                    <Text type="danger">Private</Text>
                  )}
                </Td>
                <Td className="group-action">
                  <div className="td-action">
                    <Button
                      className="btn-edit"
                      type="primary"
                      onClick={() =>
                        window.open(
                          process.env.PUBLIC_URL + '/edit-top-list/' + item._id
                        )
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      className="btn-delete"
                      type="danger"
                      onClick={() => {
                        this.showConfirm(item._id);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr className="body-row">
              <Td colSpan="7">
                <Empty />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListTopLists)
);
