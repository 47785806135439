import React from "react";
import styles from "./index.scss";
import MyBreadcrumb from "../breadcrumb";
import ShareSection from "./share-section";

const GridHeader = ({isPreview, ...props}) => {
    const {views} = props.itinerary;
    return (
        <div className={'bread '+styles.gridHeader}>
            <MyBreadcrumb list={[
                {link: '/', title: 'Home'},
                {title: 'itinerary'}
            ]} />
            {isPreview ? null : (
                <ShareSection views={views}/>
            )}
        </div>
    );
}

export default GridHeader;
