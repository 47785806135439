import React from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import DialogContent from "@material-ui/core/DialogContent";
import {Col, Row} from "reactstrap";
import ErrorIcon from "@material-ui/icons/Error";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import styles from "../../pages/view-itinerary/index.scss";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {ObjectId} from "bson";
import ApiService from "../../services/api.service";

class ShareDialog extends React.Component<{showForm: boolean, close: void, idItinerary: ObjectId}> {
    constructor(props) {
        super(props);
        this.apiService = ApiService();
        this.state = {
            name: null,
            receiver: null,
            messages: null,
            isLoadingShare: false
        };
    }

    handleChange = (key) => (event) => {
        this.setState({
            [key]: event.target.value,
        });
    };

    shareItineraryByEmail = () => {
        this.setState({
            isLoadingShare: true,
        });
        this.apiService
            .shareItineraryByEmail(
                this.state.name,
                this.state.receiver,
                this.state.messages,
                this.props.idItinerary
            )
            .then((res) => {
                if (res.status === "success") {
                    Swal.fire({
                        type: "success",
                        title: "Share successfully",
                        showConfirmButton: true,
                    });
                    this.props.close();
                }
            });
    };

    render() {
        return <Dialog
            open={this.props.showForm}
            aria-labelledby="form-share-by-email"
            maxWidth={"sm"}
            fullWidth={true}
        >
            <MuiDialogTitle className="popup-title" disableTypography>
                <Typography variant="h6">
                    Share itinerary by email
                </Typography>
                <IconButton
                    aria-label="close"
                    className="btn-close"
                    onClick={() => this.props.close()}
                >
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <ValidatorForm onSubmit={this.shareItineraryByEmail}>
                <DialogContent>
                    <Row>
                        <Col>
                            <TextValidator
                                label="Your name *"
                                margin="none"
                                type="text"
                                fullWidth
                                autoFocus={true}
                                value={this.state.name}
                                validators={["required"]}
                                onChange={this.handleChange("name")}
                                errorMessages={[
                                    <span>
                                    <ErrorIcon />
                                    This field is required
                                  </span>,
                                ]}
                            />
                        </Col>
                        <Col>
                            <TextValidator
                                label="To email*"
                                fullWidth
                                value={this.state.receiver}
                                validators={["required", "isEmail"]}
                                onChange={this.handleChange("receiver")}
                                errorMessages={[
                                    <span>
                                    <ErrorIcon />
                                    This field is required
                                  </span>,
                                    <span>
                                    <ErrorIcon />
                                    Email is not valid
                                  </span>,
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                label="Messages"
                                multiline
                                rowsMax="4"
                                rows="4"
                                value={this.state.messages}
                                onChange={this.handleChange("messages")}
                                margin="none"
                                fullWidth
                            />
                        </Col>
                    </Row>
                </DialogContent>
                <DialogActions className={styles.viewItinerary}>
                    <div className="section-btn-progress">
                        <Button
                            disabled={
                                this.state.isLoadingShare
                            }
                            id="send-button"
                            type="submit"
                            className="btn-primary"
                        >
                            <SendIcon />
                            Send
                        </Button>
                        {this.state.isLoadingShare && (
                            <CircularProgress
                                size={24}
                                className="btn-progress"
                            />
                        )}
                    </div>
                </DialogActions>
            </ValidatorForm>
        </Dialog>;
    }
}

export default ShareDialog;