import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import $ from 'jquery';
import {
  getToken,
  removeKeyAfterLoginOtherAccount,
  removeToken,
} from '../services/authService';
import {
  saveProfile,
  saveCompany,
  saveCompanyDomain,
  saveProfileDomain,
  logout,
  saveIp,
} from '../store/actions';
import { AccountType } from '../params/AccountType';

import companyApi from '../services/companyApi.service';
import ApiService from '../services/api.service';

import RouteNotAuthData from './data/route';
import RouteAuthData from './data/route-admin';
import RouteCompany from './data/route-company';

import LayoutMain from '../layout/main';
import LayoutAdmin from '../layout/admin';
import LayoutCompany from '../layout/company';
import { isSubdomain } from '../services/utils.service';
import { config } from '../config';
import { withRouter } from 'react-router';
import { isTablet, isMobile } from 'react-device-detect';
import Cookies from 'universal-cookie';
import { sha256 } from 'js-sha256';
import { ErrorPage } from '../pages';

const stripePromise = loadStripe(config.stripe_pk);

const RoutePublic = ({
  privateRoute: PrivateRoute,
  customData: CustomProps,
  component: Component,
  layout: Layout,
  profile: Profile,
  ...props
}) => (
  <Route
    {...props}
    render={(props) => {
      if (PrivateRoute && !Profile) return <Redirect to="/" {...props} />;
      return (
        <Layout>
          <Component {...CustomProps} {...props} />
        </Layout>
      );
    }}
  />
);

const RoutePrivate = ({
  component: Component,
  layout: Layout,
  proAccount: RouteProAccount,
  profile: Profile,
  ...props
}) => (
  <Route
    {...props}
    render={(props) => {
      if (
        RouteProAccount &&
        Profile !== null &&
        Profile.accountType !== AccountType.Pro
      )
        return <Redirect to="/" {...props} />;

      return (
        <Layout>
          {getToken() !== null ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" {...props} />
          )}
        </Layout>
      );
    }}
  />
);

class RouteControl extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.companyApi = companyApi();
    this.state = {
      renderSubDomain: false,
      loading: true,
    };
  }

  async checkUserLogin() {
    if (getToken()) {
      await this.apiService
        .getCurrentProfile()
        .then((res) => {
          if (res.status === 'success') {
            this.props.saveProfile(res.user);
            if (res.user.accountType === AccountType.Pro && res.company) {
              this.props.saveCompany(res.company);
            }
          } else {
            removeToken();
            this.props.logout();
            this.props.history.push('/');
          }
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          removeToken();
          this.props.logout();
          this.props.history.push('/');
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  async checkSubDomain() {
    if (isSubdomain()) {
      await this.companyApi.checkDomain().then((res) => {
        if (res.exist) {
          this.setState({
            renderSubDomain: true,
          });
          this.props.saveCompanyDomain(res.company);
          this.props.saveProfileDomain(res.profile);
        } else {
          const mainDomain =
            window.location.host.split('.')[1] +
            '.' +
            window.location.host.split('.')[2];
          const location = window.location;
          location.assign(location.protocol + '//' + mainDomain);
        }
      });
    }
  }

  componentDidMount = async () => {
    if (isTablet || isMobile) {
      $('#root').addClass('normal-root');
    }
    window.addEventListener('storage', (e) => {
      if (e.key === 'userSection') {
        if (this.props.profile) {
          if (e.newValue !== this.props.profile._id) {
            removeKeyAfterLoginOtherAccount();
            window.onbeforeunload = null;
            window.location.reload();
          }
        }
      }
      if (e.key === 'token') {
        window.onbeforeunload = null;
        window.location.reload();
      }
    });
    await this.checkSubDomain();
    await this.checkUserLogin();

    await this.apiService.getIp().then((res) => {
      if (res.status === 'success') {
        this.props.saveIp(res.ip);
        const cookies = new Cookies();
        if (!cookies.get('id')) {
          cookies.set('id', sha256(new Date().getTime() + res.ip), {
            path: '/',
            expires: new Date(Date.now() + 2592000),
          });
        }
      }
    });
  };

  getRouteNotAuth = () => {
    return RouteNotAuthData.map((route, index) => {
      return (
        <RoutePublic
          key={index}
          exact={route.exact}
          layout={LayoutMain}
          path={route.path.toString()}
          component={route.component}
          profile={this.props.profile}
          privateRoute={route.privateRoute}
          customData={route.customData}
        />
      );
    });
  };

  getRouteAuth = () => {
    return RouteAuthData.map((route, index) => {
      return (
        <RoutePrivate
          key={index}
          exact={route.exact}
          layout={LayoutAdmin}
          path={route.path}
          component={route.component}
          proAccount={route.proAccount}
          profile={this.props.profile}
          privateRoute={route.privateRoute}
        />
      );
    });
  };

  getRouteCompany = () => {
    return RouteCompany.map((route, index) => (
      <RoutePublic
        key={index}
        exact={route.exact}
        layout={LayoutCompany}
        path={route.path.toString()}
        component={route.component}
        privateRoute={route.privateRoute}
      />
    ));
  };

  setSectionUser = () => {
    if (this.props.profile)
      localStorage.setItem('userSection', this.props.profile._id);
  };

  render() {
    if (this.state.loading) return null;

    if (this.state.renderSubDomain) return this.getRouteCompany();

    this.setSectionUser();

    return (
      <Elements stripe={stripePromise}>
        <Switch>
          {this.getRouteAuth()}
          {this.getRouteNotAuth()}
        </Switch>
      </Elements>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    saveCompany: (company) => {
      dispatch(saveCompany(company));
    },
    saveCompanyDomain: (company) => {
      dispatch(saveCompanyDomain(company));
    },
    saveProfileDomain: (profile) => {
      dispatch(saveProfileDomain(profile));
    },
    logout: () => {
      dispatch(logout());
    },
    saveIp: (ip) => {
      dispatch(saveIp(ip));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RouteControl)
);
