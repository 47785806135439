import React, { Component } from "react";
import { MdAddCircleOutline, MdCheckCircle } from "react-icons/md";

import styles from "./index.scss";
import ApiService from "../../services/api.service";
import { loadItem, saveItem } from "../../services/storage.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ObjectID } from "bson";
import { configBaseUrl } from "../../config";
import { getImageFromUrl, isMyResource } from "../../services/utils.service";
import { withRouter } from "react-router";
import { searchTree, updateTree } from "../../services/utils.service";
import { Button, Modal } from "antd";
import { FormSaveToLibrary } from "../../components";
import _ from "lodash"
import { categoriesDefined } from "../../constants/categories";

class PlacesInShared extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      btnDropleft: false,
      open: false,
      categoryTab: 1,
      isLoadingAdd: false,
      isLoadingRemove: false,
      isChoosen: false,

      openMoveTo: false,
    };
  }

  handleViewPlace = (_id) => {
    this.props.history.push(`/attraction/${_id}`);
  };

  handleAddToListSelected = () => {
    // this.setState({
    //   isLoadingAdd: true,
    // });

    // let itineraries;
    // itineraries = loadItem("itineraries");
    // let current = itineraries.findIndex((item) => {
    //   return item._id === this.props.params.idItinerary;
    // });
    // let index = itineraries[current].itinerary_detail.findIndex((item) => {
    //   return item._id === this.props.params.idItineraryDetail;
    // });

    // let temp = { ...this.props.item };
    // temp._id = new ObjectID().toHexString();
    // temp.transportation = {
    //   distance: "",
    //   duration: "",
    //   name: itineraries[current].transportation,
    //   note: "",
    //   price: 0,
    //   time: "",
    // };
    // itineraries[current].itinerary_detail[index].place_detail.push(temp);
    // saveItem("itineraries", itineraries);
    // this.setState(
    //   {
    //     isLoadingAdd: false,
    //     isChoosen: true,
    //   },
    //   () => {
    //     this.props.newList(9);
    //   }
    // );

    this.setState({
      isLoadingAdd: true,
    });
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });

    let temp = {
      ...this.props.item,
      belong_to_library: this.props.item.belong_to_library,
      belong_to_library_place: this.props.item._id,
    };
    temp._id = new ObjectID().toHexString();

    let tempItem = searchTree(
      itineraries[current].itinerary_detail,
      this.props.params.idItineraryDetail
    )[0];
    tempItem.place_detail.push(temp);
    let updated = updateTree(itineraries[current].itinerary_detail, tempItem);
    itineraries[current].itinerary_detail = updated;
    saveItem("itineraries", itineraries);
    this.setState(
      {
        isLoadingAdd: false,
        isChoosen: true,
      },
      () => {
        this.props.newList(9);
      }
    );

    // this.apiService.addNewPlaceDetail(this.props.params.idItineraryDetail, this.props.item, this.props.length, temp.transportation, this.props.order).then((res) => {
    //     if (res.status === 'success') {
    //         this.props.newList(3, res.new_place_detail)
    //         this.setState({
    //             isLoadingAdd: false
    //         })
    //     }
    // })
  };

  removeMyPlace = () => {
    this.setState({
      isLoadingRemove: true,
    });
    this.apiService.removeMyPlace(this.props.item._id).then((res) => {
      if (res.status === "success") {
        this.props.updateListMyPlaces(2, this.props.item._id);
        this.setState({
          isLoadingRemove: false,
        });
      }
    });
  };

  handleOpenMoveTo = () => {
    this.setState({
      openMoveTo: true,
    });
  };

  closeFormSaveToLibrary = () => {
    this.setState({
      openMoveTo: false,
    });
  };

  deletePlaceInLibrary = () => {
    Modal.confirm({
      content: "Are you sure you want to delete this stop?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => {
        this.apiService
          .deletePlaceInLibrary(this.props.item._id)
          .then((res) => {
            if (res.status === "success") {
              this.props.setNewListLibrary();
            }
          });
      },
      onCancel() {},
    });
  };

  renderNotItemInfor = () => {
    if (!!this.props.categories.length) {
      const { item } = this.props;
      var temp = _.find(this.props.categories, (category) => {
        return category._id === item.category;
      });
      return (
        temp.name !== categoriesDefined.INFORMATION && (
          <figure
            // onClick={this.handleViewPlace.bind(this, item._id)}
            className="ember-view small-view"
            style={
              !!item.data.actual_data.images.length
                ? {
                    backgroundImage: `url(${getImageFromUrl(
                      isMyResource(item.data.actual_data.images[0].preview)
                        ? `${
                            configBaseUrl.baseURL +
                            item.data.actual_data.images[0].preview
                          }`
                        : `${item.data.actual_data.images[0].preview}`,
                      "large"
                    )})`,
                  }
                : null
            }
          ></figure>
        )
      );
    }
  };

  render() {
    const { item } = this.props;
    return (
      <div className={styles.placesInShared}>
        {this.state.openMoveTo && (
          <FormSaveToLibrary
            item={this.props.item}
            open={this.state.openMoveTo}
            closeFormSaveToLibrary={this.closeFormSaveToLibrary}
            manage={true}
            setNewListLibrary={this.props.setNewListLibrary}
          />
        )}
        <div>
          <div className="sidebar-idea-container ember-view draggable-item zoom-place">
            <div className="ember-view component-idea-tile idea-tile-horizontal _can-view-details">
              {this.renderNotItemInfor()}
              <ul className="_c-info">
                <li>
                  <span>{item.data.actual_data.title}</span>
                </li>
                <li>
                  <span>{item.data.actual_data.address}</span>
                </li>
              </ul>
              {!this.props.manage ? (
                <div
                  className="click-to-add"
                  onClick={this.handleAddToListSelected}
                >
                  {this.state.isLoadingAdd ? (
                    <CircularProgress thickness={7} />
                  ) : this.state.isChoosen ? (
                    <MdCheckCircle className="isChecked" />
                  ) : (
                    <MdAddCircleOutline />
                  )}
                </div>
              ) : (
                <div className="action">
                  <Button
                    className="btn-move-to"
                    onClick={this.handleOpenMoveTo.bind(this)}
                  >
                    Move to
                  </Button>
                  <Button
                    className="btn-delete"
                    onClick={this.deletePlaceInLibrary.bind(this)}
                    type="danger"
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PlacesInShared);
