import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateListPlacesExplore,
  startLoading,
  stopLoading,
} from "../../store/actions";
import "./index.scss";
import { EnvironmentOutlined } from "@ant-design/icons";
import { withRouter } from "react-router";
import { loadItem } from "../../services/storage.service";
import { searchTree } from "../../services/utils.service";

const mapDispatchToProps = (dispatch) => {
  return {
    updateListPlacesExplore: (list, status) => {
      dispatch(updateListPlacesExplore(list, status));
    },
    startLoading: () => {
      dispatch(startLoading());
    },
    stopLoading: () => {
      dispatch(stopLoading());
    },
  };
};

class ChangeLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: "",
    };
  }

  componentDidMount = () => {
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.match.params.idItinerary;
    });

    let tempItem = searchTree(
      itineraries[current].itinerary_detail,
      this.props.match.params.idItineraryDetail
    )[0];

    this.setState({
      country: tempItem.country,
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === nextProps.match.params.idItinerary;
    });

    let tempItem = searchTree(
      itineraries[current].itinerary_detail,
      nextProps.match.params.idItineraryDetail
    )[0];

    this.setState({
      country: tempItem.country,
    });
  }

  render() {
    return (
      <div className="change-location">
        <span>
          <EnvironmentOutlined />
        </span>
        <select onChange={this.changeCategory}>
          <option value="tourist_attraction">{this.state.country}</option>
        </select>
      </div>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(ChangeLocation));
