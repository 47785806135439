import React, { useState, useEffect, useRef } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.scss";

const WYSIWYGEditor = (props) => {
  const editorRef = useRef();
  const [onFocus, setOnFocus] = useState(false);
  const contentInit =
    props.initialValue !== "" && props.initialValue
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(props.initialValue))
        )
      : EditorState.createEmpty();
  const [editorState, setEditorState] = useState(contentInit);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    return props.onChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  useEffect(() => {
    if (onFocus) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onFocus]);

  const handleClickOutside = (e) => {
    if (editorRef.current.contains(e.target)) {
      return;
    }
    setOnFocus(false);
  };

  const editorHtml = () => {
    const htmlParse = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    if (htmlParse.length === 8 || htmlParse === null || htmlParse === "")
      return "<i> Please insert your description </i>";

    return htmlParse;
  };

  return (
    <>
      {onFocus ? (
        <div className="editor" ref={editorRef}>
          <Editor
            toolbar={{
              options: [
                "inline",
                "fontFamily",
                "blockType",
                "fontSize",
                "textAlign",
                "colorPicker",
                "link",
              ],
            }}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
        </div>
      ) : (
        <p className="html-content"
          onClick={(e) => setOnFocus(true)}
          dangerouslySetInnerHTML={{
            __html: editorHtml(),
          }}
        ></p>
      )}
    </>
  );
};

export default WYSIWYGEditor;
