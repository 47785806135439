const RouteModel = (props = {}) => {
  class Route {
    constructor({
      path = "",
      component = "",
      exact = true,
      privateRoute = false,
      proAccount = false,
      customData = {}
    }) {
      this.path = path;
      this.component = component;
      this.exact = exact;
      this.privateRoute = privateRoute;
      this.proAccount = proAccount;
      this.customData = customData;
    }
  }
  return new Route(props);
};

export default RouteModel;