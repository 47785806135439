/*global google*/
import React, { Component } from "react";
import {
  Col,
  Row,
  CustomInput,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap";

import { Select } from "antd";

import "react-datepicker/dist/react-datepicker.css";
import {
  dateToTimestamp,
  getBase64FromAvatar,
  getAllLeaf,
  searchTree,
  updateTree,
  updateDraft,
  changeDateDownInMiddle,
  changeDateUpInMiddle,
  setNewStartEndDate,
} from "../../services/utils.service";
import ApiService from "../../services/api.service";
import styles from "./index.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MultiDayCalendar } from "../../components";
import { loadItem, saveItem } from "../../services/storage.service.js";
import { ObjectID } from "bson";
import { connect } from "react-redux";
import { MdClose } from "react-icons/md";
import $ from "jquery";
import { differenceInCalendarDays } from "date-fns";

import Checkbox from '@material-ui/core/Checkbox';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Upload, Icon } from "antd";
import { Collapse } from "antd";
import _ from "lodash";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { maxCharacters } from "../../constants/itinerary";
import { WarningFilled } from "@ant-design/icons";
import getCroppedImg from "../../helper/imageHelper";
import { isMobile } from "react-device-detect";
import ImgCrop from "antd-img-crop";
import { configBaseUrl } from "../../config";
import { redirectToBillingAfterLogin } from "../../store/actions";

const { Panel } = Collapse;
const { Option } = Select;

const mapDispatchToProps = (dispatch) => {
  return {
    redirectToBillingAfterLogin: (flag) => {
      dispatch(redirectToBillingAfterLogin(flag));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
    itinerary: state.itineraryReducer,
    currency_infor: state.itineraryReducer.currency_infor,
  };
};

class EditItineraryForm extends Component {
  constructor(props) {
    super(props);
    this.autocompleteMutiInput = [];
    this.apiService = ApiService();
    this.state = {
      country: "",
      secondaryCountries: [],
      flag: "",
      alpha2Code: "",
      numberOfCountry: 1,
      destination: "",
      city: "",
      cover: "",
      name: "",
      description: "",
      status: 1,
      currency: "",
      dates: new Date(),
      transportation: "Walk",
      estimatedBudget: "",
      isSubmit: false,
      isLoading: false,
      existItineraries: false,
      from: "",
      to: "",
      aNumberOfPaxs: "",
      addCountries: {},
      mainCountries: [],
      loading: false,
      onUpload: false,

      fileCover: "",

      showOption1: false,
      showOption2: false,
      showOption3: false,

      list_optionals: [],
      options: [],
      showText: "Show more",
      currencies: [],
      showExpenses: true
    };
  }

  componentDidMount = async () => {
    await this.apiService.getAllOptionals().then((res) => {
      if (res.status === "success") {
        this.setState({
          list_optionals: res.list_optionals,
        });
      }
    });

    await this.apiService.getAllCurrency().then((res) => {
      if (res.status === "success") {
        this.setState({
          currencies: res.currencies,
        });
      }
    });

    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });

    this.setState({
      name: itineraries[current].name,
      aNumberOfPaxs: itineraries[current].a_number_of_paxs,
      estimatedBudget: itineraries[current].estimated_budget,
      currency: itineraries[current].currency,
      from: itineraries[current].dates,
      to: itineraries[current].end_dates,
      status:
        this.props.profile && this.props.profile.accountType > 0
          ? itineraries[current].status
          : 1,
      description: itineraries[current].description,
      cover: itineraries[current].cover ? itineraries[current].cover : "",
      options: itineraries[current].options ? itineraries[current].options : [],
      showExpenses:
        this.props.profile && this.props.profile.accountType > 0
          ? itineraries[current].show_expenses
          : true,
    });

    var numCountries = 0;
    var tempMainCountries = [];
    itineraries[current].itinerary_detail.forEach((item) => {
      numCountries += item.children.length;
      item.children.forEach((child) => {
        tempMainCountries.push({
          country: item.title,
          city: child.title,
          alpha2Code: item.alpha2Code,
        });
      });
    });

    this.setState({
      numberOfCountry: numCountries,
      mainCountries: tempMainCountries,
    });
  };

  handleChange = (name) => (event) => {
    if (name === "showExpenses") {

      this.setState({
        [name]: event.target.checked,
      });
      return;
    }
    if (name === "name") {
      this.setState({
        [name]: event.target.value,
      });
      return;
    }

    if (name === "numberOfCountry") {
      var temp = this.state.secondaryCountries;
      for (let i = 0; i < +event.target.value - 1; i++) {
        temp.push({
          country: this.state.country,
          alpha2Code: this.state.alpha2Code,
        });
      }
      this.setState({
        secondaryCountries: temp,
      });
    }

    if (name === "currency") {
      this.setState({
        [name]: event,
      });
      return;
    }

    if (name === "estimatedBudget") {
      const reg = /^-?\d*(\.\d*)?$/;
      if (
        !isNaN(event.target.value) &&
        reg.test(event.target.value) &&
        event.target.value !== "-"
      ) {
        this.setState({
          [name]: event.target.value,
        });
      }
      return;
    }

    if (name === "aNumberOfPaxs") {
      const reg = /^-?\d*(\\d*)?$/;
      if (
        !isNaN(event.target.value) &&
        reg.test(event.target.value) &&
        event.target.value !== "-" &&
        event.target.value !== "."
      ) {
        this.setState({
          [name]: event.target.value,
        });
      }
      return;
    }

    if (name === "status") {
      this.setState({
        [name]: +event.target.value,
      });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  onBlur = (name) => (event) => {
    let valueTemp = this.state[name];
    if (
      this.state[name].charAt(this.state[name].length - 1) === "." ||
      this.state[name] === "-"
    ) {
      valueTemp = this.state[name].slice(0, -1);
    }
    this.setState({
      [name]: valueTemp.replace(/0*(\d+)/, "$1"),
    });
  };

  handleChangeDate = (dates) => {
    this.setState({
      dates: dates,
    });
  };

  validate = () => {
    if (
      !this.state.name.trim() ||
      this.state.name === "" ||
      this.state.name.length > maxCharacters
    ) {
      return false;
    }
    return true;
  };

  handleChangeDestination = (destination) => {
    this.setState({
      destination: destination,
    });
  };

  getDatesBetween = (startDate, endDate) => {
    const dates = [];

    // Strip hours minutes seconds etc.
    let currentDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );

    while (currentDate <= endDate) {
      dates.push(currentDate);

      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1 // Will increase month if over range
      );
    }

    return dates;
  };

  customUpload = async ({ onError, onSuccess, file }) => {
    var percentCompleted;
    const formData = new FormData();
    formData.append("cover", file);
    try {
      this.apiService
        .uploadCover(formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((res) => {
          onSuccess(null, res.path);
        });
    } catch (e) {
      onError(e);
    }
  };

  validateAutoComplete = (tempCountries) => {
    var arrayIdInput = [];
    $("input[placeholder|='Enter City or Country']").each(function (
      index,
      element
    ) {
      if ($(element)[0].hasAttribute("id")) {
        arrayIdInput.push($(this).attr("id"));
      }
    });

    let validateAutoComplete = true;
    tempCountries.forEach((item, index) => {
      if (!item.flag || item.flag === "") {
        validateAutoComplete = false;
        if (
          !$(`input[id=${arrayIdInput[index]}]`).hasClass(
            "is-invalid-autocomplete"
          )
        ) {
          $(`input[id=${arrayIdInput[index]}]`)
            .closest("div")
            .append(
              "<div invalid='true' class='d-block invalid-feedback'>Wrong format!</div>"
            );
          $(`input[id=${arrayIdInput[index]}]`).addClass(
            "is-invalid-autocomplete"
          );
          $(`input[id=${arrayIdInput[index]}]`)
            .closest("div")
            .find("span.input-group-append > button")
            .addClass("is-invalid-btn-remove")
            .removeClass("btn-outline-primary");
        }
      }
    });
    return validateAutoComplete;
  };

  validateDatePicker = (from) => {
    var flag = true;
    if (isNaN(from)) {
      flag = false;
      if (
        !$(`input[placeholder='Start date']`).hasClass("is-invalid-datepicker")
      ) {
        $(`input[placeholder='Start date']`).addClass("is-invalid-datepicker");
      }
    } else {
      $(`input[placeholder='Start date']`).removeClass("is-invalid-datepicker");
    }
    return flag;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      isSubmit: true,
    });

    if (!this.validate()) return;
    else {
      var itineraries;
      itineraries = loadItem("itineraries");
      var current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });

      if (this.state.secondaryCountries.length > 0) {
        let tempCountries = [...this.state.secondaryCountries];

        if (!this.validateAutoComplete(tempCountries)) return;

        var tempInfoCountryCity = tempCountries[0];

        if (tempCountries.length > 1) {
          for (let i = 0; i < tempCountries.length - 1; i++) {
            if (
              tempCountries[i].alpha2Code === tempCountries[i + 1].alpha2Code
            ) {
              if (tempCountries[i].city === tempCountries[i + 1].city) {
                tempCountries.splice(i, 1);
                i--;
              } else {
                if (!tempCountries[i].city) {
                  tempCountries.splice(i, 1);
                  i--;
                } else if (!tempCountries[i + 1].city) {
                  tempCountries.splice(i + 1, 1);
                  i--;
                }
              }
            }
          }
        }

        tempCountries = tempCountries.map(
          (item) =>
            (item = {
              belong_to_itinerary: this.props.params.idItinerary,
              title: item.country,
              alpha2Code: item.alpha2Code,
              flag: item.flag,
              children: [
                {
                  title: item.city,
                  children: [],
                },
              ],
            })
        );

        let originLength = tempCountries.length;
        if (tempCountries.length === 1) {
          let detail = {
            month_day_year: itineraries[current].end_dates,
            place_detail: [],
            title: "",
            _id: new ObjectID().toHexString(),
            country: tempInfoCountryCity.country,
            alpha2Code: tempInfoCountryCity.alpha2Code,
            city: tempInfoCountryCity.city,
          };
          tempCountries[0].children[0].children.push(detail);
        } else if (tempCountries.length > 1) {
          for (let i = 0; i < tempCountries.length - 1; i++) {
            if (
              tempCountries[i].alpha2Code === tempCountries[i + 1].alpha2Code
            ) {
              if (tempCountries.length === 2) {
                tempCountries[i].children.push({
                  title: tempCountries[i + 1].children[0].title,
                  children: [],
                });
              } else {
                if (i === tempCountries.length - 2) {
                  tempCountries[i].children.push({
                    title: tempCountries[i + 1].children[0].title,
                    children: [
                      {
                        month_day_year: "",
                        place_detail: [],
                        title: "",
                        _id: new ObjectID().toHexString(),
                        country: tempCountries[i].title,
                        alpha2Code: tempCountries[i].alpha2Code,
                        city: tempCountries[i + 1].children[0].title,
                      },
                    ],
                  });
                } else {
                  tempCountries[i].children.push({
                    title: tempCountries[i + 1].children[0].title,
                    children: [],
                  });
                }
              }
              tempCountries.splice(i + 1, 1);
              i--;
            }
          }

          if (tempCountries.length === 1 && originLength > 1) {
            tempCountries[0].children.forEach((item, index) => {
              if (index === tempCountries[0].children.length - 1) {
                item.children.push({
                  month_day_year: dateToTimestamp(
                    new Date(this.state.to * 1000)
                  ).toString(),
                  place_detail: [],
                  title: "",
                  _id: new ObjectID().toHexString(),
                  country: tempCountries[0].title,
                  alpha2Code: tempCountries[0].alpha2Code,
                  city: tempCountries[0].children[index].title,
                });
              } else {
                item.children.push({
                  month_day_year: "",
                  place_detail: [],
                  title: "",
                  _id: new ObjectID().toHexString(),
                  country: tempCountries[0].title,
                  alpha2Code: tempCountries[0].alpha2Code,
                  city: tempCountries[0].children[index].title,
                });
              }
            });
          } else {
            if (originLength === tempCountries.length) {
              for (let i = 0; i < tempCountries.length; i++) {
                if (i === tempCountries.length - 1) {
                  tempCountries[i].children[0].children.push({
                    month_day_year: dateToTimestamp(
                      new Date(this.state.to * 1000)
                    ).toString(),
                    place_detail: [],
                    title: "",
                    _id: new ObjectID().toHexString(),
                    country: tempCountries[i].title,
                    alpha2Code: tempCountries[i].alpha2Code,
                    city: tempCountries[i].children[0].title,
                  });
                } else {
                  tempCountries[i].children[0].children.push({
                    month_day_year: "",
                    place_detail: [],
                    title: "",
                    _id: new ObjectID().toHexString(),
                    country: tempCountries[i].title,
                    alpha2Code: tempCountries[i].alpha2Code,
                    city: tempCountries[i].children[0].title,
                  });
                }
              }
            } else {
              for (let i = 0; i < tempCountries.length; i++) {
                for (let j = 0; j < tempCountries[i].children.length; j++) {
                  if (j === tempCountries[i].children.length - 1) {
                    tempCountries[i].children[j].children.push({
                      month_day_year: dateToTimestamp(
                        new Date(this.state.to * 1000)
                      ).toString(),
                      place_detail: [],
                      title: "",
                      _id: new ObjectID().toHexString(),
                      country: tempCountries[i].title,
                      alpha2Code: tempCountries[i].alpha2Code,
                      city: tempCountries[i].children[0].title,
                    });
                  } else {
                    tempCountries[i].children[j].children.push({
                      month_day_year: "",
                      place_detail: [],
                      title: "",
                      _id: new ObjectID().toHexString(),
                      country: tempCountries[i].title,
                      alpha2Code: tempCountries[i].alpha2Code,
                      city: tempCountries[i].children[0].title,
                    });
                  }
                }
              }
            }
          }
        }

        let oldCountryTree = itineraries[current].itinerary_detail;
        if (_.last(oldCountryTree).alpha2Code === tempCountries[0].alpha2Code) {
          if (
            _.last(_.last(oldCountryTree).children).title ===
            tempCountries[0].children[0].title
          ) {
            tempCountries[0].children.splice(0, 1);
            let mergeBranch = tempCountries[0].children;
            tempCountries.splice(0, 1);
            let afterMerge = _.last(oldCountryTree).children.concat(
              mergeBranch
            );
            oldCountryTree[oldCountryTree.length - 1].children = afterMerge;
          } else {
            let mergeBranch = tempCountries[0].children;
            tempCountries.splice(0, 1);
            let afterMerge = _.last(oldCountryTree).children.concat(
              mergeBranch
            );
            oldCountryTree[oldCountryTree.length - 1].children = afterMerge;
          }
        }

        let newTree = oldCountryTree.concat(tempCountries);
        newTree.forEach((item, index) => {
          item.order = index;
        });

        itineraries[current].itinerary_detail = newTree;
      }

      if (!this.validateDatePicker(this.state.from, this.state.to)) {
        this.setState({
          isSubmit: false,
        });
        return;
      }
      if (typeof this.state.from === "undefined") return;

      var numberDaysAdd = differenceInCalendarDays(
        new Date(this.state.from * 1000),
        new Date(itineraries[current].dates * 1000)
      );

      var result = getAllLeaf(itineraries[current].itinerary_detail);
      let tempItem = searchTree(
        itineraries[current].itinerary_detail,
        this.props.params.idItineraryDetail
      )[0];
      tempItem.month_day_year = this.state.from;
      let updated = updateTree(itineraries[current].itinerary_detail, tempItem);
      itineraries[current].itinerary_detail = updated;
      if (numberDaysAdd > 0)
        itineraries[current].itinerary_detail = changeDateUpInMiddle(
          numberDaysAdd,
          itineraries[current].itinerary_detail,
          this.props.params.idItineraryDetail
        );
      else
        itineraries[current].itinerary_detail = changeDateDownInMiddle(
          numberDaysAdd,
          itineraries[current].itinerary_detail,
          this.props.params.idItineraryDetail
        );
      itineraries[current] = setNewStartEndDate(itineraries[current]);
      // if (numberDaysAdd > 0) {
      //   result.forEach((item) => {
      //     let tempItem = searchTree(
      //       itineraries[current].itinerary_detail,
      //       item._id
      //     )[0];

      //     if (tempItem.month_day_year) {
      //       tempItem.month_day_year = dateToTimestamp(
      //         addDays(new Date(item.month_day_year * 1000), numberDaysAdd)
      //       );
      //     }
      //     let updated = updateTree(
      //       itineraries[current].itinerary_detail,
      //       tempItem
      //     );
      //     itineraries[current].itinerary_detail = updated;
      //   });
      //   itineraries[current].dates = dateToTimestamp(
      //     addDays(new Date(itineraries[current].dates * 1000), numberDaysAdd)
      //   );
      //   itineraries[current].end_dates = dateToTimestamp(
      //     addDays(
      //       new Date(itineraries[current].end_dates * 1000),
      //       numberDaysAdd
      //     )
      //   );
      // }

      var options = this.state.options;
      options.forEach((item) => {
        item.belong_to_itinerary = this.props.params.idItinerary;
      });

      itineraries[current].name = this.state.name;
      itineraries[current].a_number_of_paxs = this.state.aNumberOfPaxs;
      itineraries[current].estimated_budget = this.state.estimatedBudget;
      itineraries[current].description = this.state.description;
      itineraries[current].cover = this.state.cover;
      itineraries[current].options = options;
      itineraries[current].status = this.state.status;
      itineraries[current].currency = this.state.currency;
      itineraries[current].show_expenses = this.state.showExpenses;
      saveItem("itineraries", itineraries);
      updateDraft(itineraries[current]._id, {
        ...itineraries[current],
        currency_infor: this.props.currency_infor,
      });
      this.props.rerenderParentCallback();
    }
  };

  handleChangeMultiDay = (from, to) => {
    this.setState({
      from: from,
      to: to,
    });
  };

  removeInput = (time) => {
    const addCountries = { ...this.state.addCountries };
    var keytoFind = time;
    var i = Object.keys(addCountries).indexOf(keytoFind.toString());

    const secondaryCountries = [...this.state.secondaryCountries];
    secondaryCountries.splice(i, 1);
    delete addCountries[time];

    this.setState({
      addCountries: addCountries,
      secondaryCountries: secondaryCountries,
      numberOfCountry: Object.keys(addCountries).length + 1,
    });
  };

  handleAddAnotherDestination = () => {
    const addCountries = { ...this.state.addCountries };
    const time = new Date().getTime();
    this.autocompleteMutiInput[time] = React.createRef();
    addCountries[time] = (
      <FormGroup row key={time}>
        <Col xs={12}>
          <InputGroup className="row-destination-added">
            <Input
              innerRef={this.autocompleteMutiInput[time]}
              id={`start-country-${time}`}
              placeholder="Enter City or Country"
            />
            <span className="input-group-append input-group-btn">
              <Button
                className="btn-outline-primary"
                onClick={this.removeInput.bind(this, time)}
              >
                <MdClose />
              </Button>
            </span>
          </InputGroup>
        </Col>
      </FormGroup>
    );

    this.setState(
      {
        numberOfCountry: this.state.numberOfCountry + 1,
        addCountries: addCountries,
      },
      () => {
        this.addAutoComplete(
          this.autocompleteMutiInput[time].current,
          `start-country-${time}`
        );

        var temp = this.state.secondaryCountries;
        temp.push({
          country: "",
          alpha2Code: "",
        });
        // for (
        //   let i = this.state.secondaryCountries.length;
        //   i < this.state.numberOfCountry;
        //   i++
        // ) {
        //   temp.push({
        //     country: "",
        //     alpha2Code: "",
        //   });
        // }
        this.setState({
          secondaryCountries: temp,
        });
      }
    );
  };

  addAutoComplete = (ele, id) => {
    var currentInp = id;
    var placeBox = new google.maps.places.Autocomplete(ele, {
      types: ["(regions)"],
    });
    google.maps.event.addListener(placeBox, "place_changed", () => {
      var place = placeBox.getPlace();
      if (place.geometry) {
        var latlng = new google.maps.LatLng(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );

        $(`input[id=${currentInp}]`)
          .closest("div")
          .find("div.invalid-feedback")
          .remove();
        $(`input[id=${currentInp}]`).removeClass("is-invalid-autocomplete");
        $(`input[id=${currentInp}]`)
          .closest("div")
          .find("span.input-group-append > button")
          .addClass("btn-outline-primary")
          .removeClass("is-invalid-btn-remove");

        new google.maps.Geocoder().geocode(
          { latLng: latlng },
          (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                var country = null,
                  countryCode = null,
                  city = null,
                  cityAlt = null;
                var c, lc, component;
                for (var r = 0, rl = results.length; r < rl; r += 1) {
                  var result = results[r];

                  if (!city && result.types[0] === "locality") {
                    for (
                      c = 0, lc = result.address_components.length;
                      c < lc;
                      c += 1
                    ) {
                      component = result.address_components[c];

                      if (component.types[0] === "locality") {
                        city = component.long_name;
                        break;
                      }
                    }
                  } else if (
                    !city &&
                    !cityAlt &&
                    result.types[0] === "administrative_area_level_1"
                  ) {
                    for (
                      c = 0, lc = result.address_components.length;
                      c < lc;
                      c += 1
                    ) {
                      component = result.address_components[c];

                      if (
                        component.types[0] === "administrative_area_level_1"
                      ) {
                        cityAlt = component.long_name;
                        break;
                      }
                    }
                  } else if (!country && result.types[0] === "country") {
                    country = result.address_components[0].long_name;
                    countryCode = result.address_components[0].short_name;
                  }

                  if (city && country) {
                    break;
                  }
                }

                var index = place.types.findIndex((item) => {
                  return item === "country";
                });

                var keytoFind = currentInp.substr(14);
                var i = Object.keys(this.state.addCountries).indexOf(keytoFind);

                if (index >= 0) {
                  let temp2 = [...this.state.secondaryCountries];
                  temp2[i] = {
                    city: "",
                    country: country,
                    alpha2Code: countryCode,
                    flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                  };
                  this.setState({
                    secondaryCountries: temp2,
                  });
                } else {
                  if (cityAlt) {
                    let temp2 = [...this.state.secondaryCountries];
                    temp2[i] = {
                      city: cityAlt,
                      country: country,
                      alpha2Code: countryCode,
                      flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                    };
                    this.setState({
                      secondaryCountries: temp2,
                    });
                  } else {
                    let temp2 = [...this.state.secondaryCountries];
                    temp2[i] = {
                      city: city,
                      country: country,
                      alpha2Code: countryCode,
                      flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                    };
                    this.setState({
                      secondaryCountries: temp2,
                    });
                  }
                }
              }
            }
          }
        );
      } else {
        var keytoFind = currentInp.substr(14);
        var i = Object.keys(this.state.addCountries).indexOf(keytoFind);
        var temp2 = [...this.state.secondaryCountries];
        temp2[i] = {
          city: "",
          country: "",
          alpha2Code: "",
          flag: "",
        };
        this.setState({
          secondaryCountries: temp2,
        });
      }
    });
  };

  handleChangeCover = (info) => {
    this.setState({ loading: true });
    getBase64FromAvatar(info.file.originFileObj, (image) => {
      this.setState({
        cover: info.file.xhr,
        loading: false,
        fileCover: info.file.xhr,
        onUpload: true,
      });
    });
  };

  handleShowOption = (option) => {
    if (option === 1) {
      this.setState({
        showOption1: !this.state.showOption1,
      });
    }
    if (option === 2) {
      this.setState({
        showOption2: !this.state.showOption2,
      });
    }
    if (option === 3) {
      this.setState({
        showOption3: !this.state.showOption3,
      });
    }
  };

  handleChangeOption = (name) => (e) => {
    if (!e.target.checked) {
      this.setState({
        options: this.state.options.filter((item) => {
          return item.option_code !== name;
        }),
      });
    } else {
      if (this.state.options.findIndex((el) => el.option_code === name) < 0) {
        let temp = [
          ...this.state.options,
          {
            option_code: name,
          },
        ];
        this.setState({
          options: temp,
        });
      }
    }
  };

  handleChangeOptionType = (name) => (e) => {
    this.setState(
      {
        options: this.state.options.filter((item) => {
          return item.option_code <= 18;
        }),
      },
      () => {
        if (this.state.options.findIndex((el) => el.option_code === name) < 0) {
          let temp = [
            ...this.state.options,
            {
              option_code: name,
            },
          ];
          this.setState({
            options: temp,
          });
        }
      }
    );
  };

  handleShowMoreLess = (key) => {
    if (key.length > 0) {
      this.setState({
        showText: "Show less",
      });
    } else {
      this.setState({
        showText: "Show more",
      });
    }
  };

  handleDrop = async (pixel, source) => {
    try {
      const croppedImage = await getCroppedImg(source, pixel);
      let link = await croppedImage.toBase64();
      const formData = new FormData();
      formData.append("cover", link);
      try {
        this.apiService
          .uploadCover(formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            // onSuccess(null, res.path);
            this.setState({ onUpload: false, cover: res.path });
          });
      } catch (e) {
        console.error(e);
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleUpgrateSubscript = () => {
    this.props.redirectToBillingAfterLogin(1);
  };

  render() {
    const { cover } = this.state;
    const uploadCoverButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <>
        <DialogContent className={styles.editItineraryForm} dividers>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <FormGroup row>
              <Col xs={12}>
                <small className="warning-limit">
                  <WarningFilled /> Choose a name for your new Tyneri (limit to{" "}
                  <span>
                    {this.state.name.length}/{maxCharacters}
                  </span>{" "}
                  characters)
                </small>
                <Input
                  value={this.state.name}
                  invalid={
                    (!this.state.name.trim() || !this.state.name) &&
                    this.state.isSubmit
                      ? true
                      : false
                  }
                  type="text"
                  onChange={this.handleChange("name")}
                  name="name"
                  placeholder={
                    "Trying using the included destinations, i.e. Beijing-Thailand-Hongkong"
                  }
                />
                {this.state.name.length > maxCharacters ? (
                  <FormFeedback invalid="true" className="d-block">
                    Exceed {maxCharacters} characters
                  </FormFeedback>
                ) : null}
                <FormFeedback
                  invalid="true"
                  className={
                    (!this.state.name.trim() || !this.state.name) &&
                    this.state.isSubmit
                      ? "d-block"
                      : ""
                  }
                >
                  Itinerary title is empty!
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12} md={8}>
                <RadioGroup
                  aria-label="gender"
                  name="gender2"
                  style={{ display: `inline` }}
                  value={
                    this.props.profile && this.props.profile.accountType > 0
                      ? this.state.status
                      : 1
                  }
                  onChange={this.handleChange("status")}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label="Public"
                    name="radioPublicPrivate"
                    style={{ marginBottom: 0 }}
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio color="primary" />}
                    label="Private"
                    disabled={
                      this.props.profile && this.props.profile.accountType > 0
                        ? false
                        : true
                    }
                    style={{ marginBottom: 0 }}
                  />
                  {this.props.profile &&
                  this.props.profile.accountType > 0 ? null : (
                    <span
                      className="what-this"
                      onClick={this.handleUpgrateSubscript}
                    >
                      Upgrade your subscription to use Private.
                    </span>
                  )}
                </RadioGroup>
              </Col>
              {this.state.status &&
              this.props.profile &&
              this.props.profile.accountType > 0 ? (
                <Col className="text-md-right" xs={12} md={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          this.props.profile &&
                          this.props.profile.accountType > 0
                            ? false
                            : true
                        }
                        checked={this.state.showExpenses}
                        onChange={this.handleChange("showExpenses")}
                        color="primary"
                      />
                    }
                    label="Show Expenses"
                  />
                </Col>
              ) : null}
              <Col xs={12}>
                <Input
                  style={{ marginTop: "1rem", minHeight: "20vh" }}
                  type="textarea"
                  placeholder="Description"
                  value={this.state.description ? this.state.description : ""}
                  onChange={this.handleChange("description")}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col>
                <ImgCrop
                  aspect={16 / 9}
                  modalWidth={isMobile ? "100%" : "70%"}
                  grid
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={this.customUpload}
                    onChange={this.handleChangeCover}
                  >
                    {cover ? (
                      <img
                        src={configBaseUrl.baseURL + cover}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadCoverButton
                    )}
                  </Upload>
                </ImgCrop>
              </Col>
            </FormGroup>
            <FormGroup row>
              {/* <Label sm={3}>When you go?</Label> */}
              <Col
                className="col-muilti-day-edit"
                xs={12}
                style={{ textAlign: "center" }}
              >
                <MultiDayCalendar
                  handleChangeMultiDay={this.handleChangeMultiDay}
                  edit={true}
                  params={this.props.params}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs="12" lg="6" className="form-input-budget-mobile">
                <InputGroup className="row-destination-added">
                  {this.state.currency && (
                    <Select
                      onChange={this.handleChange("currency")}
                      defaultValue={this.state.currency}
                      className="input-group-append input-group-btn currency"
                    >
                      {!!this.state.currencies.length &&
                        this.state.currencies.map((item, key) => (
                          <Option key={key} value={item.code}>
                            {item.code} {item.symbol}
                          </Option>
                        ))}
                    </Select>
                  )}
                  <Input
                    placeholder="Budget"
                    onContextMenu={(e) => {
                      e.preventDefault();
                    }}
                    value={
                      this.state.estimatedBudget
                        ? this.state.estimatedBudget
                        : ""
                    }
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    onChange={this.handleChange("estimatedBudget")}
                    onBlur={this.onBlur("estimatedBudget")}
                  />
                </InputGroup>
              </Col>
              <Col xs="12" lg="6" className="form-input-pax-mobile">
                <Input
                  placeholder="Number of pax"
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  value={
                    this.state.aNumberOfPaxs ? this.state.aNumberOfPaxs : ""
                  }
                  onChange={this.handleChange("aNumberOfPaxs")}
                  onBlur={this.onBlur("aNumberOfPaxs")}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                />
              </Col>
            </FormGroup>
            {this.state.mainCountries.map((item, index) => (
              <FormGroup row key={index}>
                <Col xs={12}>
                  <Input
                    disabled
                    className="destination-disabled"
                    placeholder="Enter City, Country or Region"
                    value={`${item.city && `${item.city}, `} ${item.country}`}
                  />
                </Col>
              </FormGroup>
            ))}
            <div id="new-row-added">
              {Object.keys(this.state.addCountries).map(
                (key) => this.state.addCountries[key]
              )}
            </div>
            <FormGroup row>
              <Col xs={12}>
                <Button
                  className="btn-add-destination"
                  onClick={this.handleAddAnotherDestination.bind(this)}
                  size="lg"
                  block
                >
                  + Add another destination
                </Button>
              </Col>
            </FormGroup>
            <Collapse
              className="itinerary-optional"
              onChange={this.handleShowMoreLess}
            >
              <Panel showArrow={false} header={this.state.showText} key="1">
                <Collapse expandIconPosition="right" x>
                  <Panel header="Activity types" key="1">
                    <FormGroup row inline>
                      <Col xs={12}>
                        {this.state.list_optionals &&
                          !!this.state.list_optionals.length &&
                          this.state.list_optionals.map((item, key) => {
                            if (item.group_name === "pref") {
                              return (
                                <React.Fragment key={key}>
                                  {/* <div
                                      className="optional"
                                      onClick={this.handleShowOption.bind(
                                        this,
                                        1
                                      )}
                                    >
                                      <Label className="label-optional">
                                        Activity types (optional)
                                      </Label>
                                      <div
                                        className={
                                          this.state.showOption1
                                            ? "wrapper3 active"
                                            : "wrapper3"
                                        }
                                      >
                                        <span className="arrow"></span>
                                      </div>
                                    </div> */}

                                  <Row>
                                    {item.detail.map((child, index) => {
                                      let isChecked = this.state.options.findIndex(
                                        (el) =>
                                          el.option_code === child.option_code
                                      );
                                      if (isChecked >= 0) {
                                        return (
                                          <Col xs="4" key={index}>
                                            <CustomInput
                                              type="checkbox"
                                              id={child.option_code}
                                              label={child.name}
                                              inline
                                              onChange={this.handleChangeOption(
                                                child.option_code
                                              )}
                                              checked={true}
                                            />
                                          </Col>
                                        );
                                      } else {
                                        return (
                                          <Col xs="4" key={index}>
                                            <CustomInput
                                              type="checkbox"
                                              id={child.option_code}
                                              label={child.name}
                                              inline
                                              onChange={this.handleChangeOption(
                                                child.option_code
                                              )}
                                              checked={false}
                                            />
                                          </Col>
                                        );
                                      }
                                    })}
                                  </Row>
                                </React.Fragment>
                              );
                            }
                          })}
                      </Col>
                    </FormGroup>
                  </Panel>
                  <Panel header="Age groups" key="2">
                    <FormGroup row inline>
                      <Col xs={12}>
                        {this.state.list_optionals &&
                          !!this.state.list_optionals.length &&
                          this.state.list_optionals.map((item, key) => {
                            if (item.group_name === "age") {
                              return (
                                <React.Fragment key={key}>
                                  {/* <div
                                      className="optional"
                                      onClick={this.handleShowOption.bind(
                                        this,
                                        2
                                      )}
                                    >
                                      <Label className="label-optional">
                                        Age groups (optional)
                                      </Label>
                                      <div
                                        className={
                                          this.state.showOption2
                                            ? "wrapper3 active"
                                            : "wrapper3"
                                        }
                                      >
                                        <span className="arrow"></span>
                                      </div>
                                    </div> */}

                                  <Row>
                                    {item.detail.map((child, index) => {
                                      let isChecked = this.state.options.findIndex(
                                        (el) =>
                                          el.option_code === child.option_code
                                      );
                                      if (isChecked >= 0) {
                                        return (
                                          <Col xs="4" key={index}>
                                            <CustomInput
                                              type="checkbox"
                                              id={child.option_code}
                                              label={child.name}
                                              inline
                                              onChange={this.handleChangeOption(
                                                child.option_code
                                              )}
                                              checked={true}
                                            />
                                          </Col>
                                        );
                                      } else {
                                        return (
                                          <Col xs="4" key={index}>
                                            <CustomInput
                                              type="checkbox"
                                              id={child.option_code}
                                              label={child.name}
                                              inline
                                              onChange={this.handleChangeOption(
                                                child.option_code
                                              )}
                                              checked={false}
                                            />
                                          </Col>
                                        );
                                      }
                                    })}
                                  </Row>
                                </React.Fragment>
                              );
                            }
                          })}
                      </Col>
                    </FormGroup>
                  </Panel>
                  <Panel header="Types of group" key="3">
                    <FormGroup row inline>
                      <Col xs={12}>
                        {this.state.list_optionals &&
                          !!this.state.list_optionals.length &&
                          this.state.list_optionals.map((item, key) => {
                            if (item.group_name === "plan") {
                              return (
                                <React.Fragment key={key}>
                                  {/* <div
                                      className="optional"
                                      onClick={this.handleShowOption.bind(
                                        this,
                                        3
                                      )}
                                    >
                                      <Label className="label-optional">
                                        Types of group (optional)
                                      </Label>
                                      <div
                                        className={
                                          this.state.showOption3
                                            ? "wrapper3 active"
                                            : "wrapper3"
                                        }
                                      >
                                        <span className="arrow"></span>
                                      </div>
                                    </div> */}

                                  <Row>
                                    {
                                      // item.detail.map((child, index) => (
                                      //   <Col xs="4" key={index}>
                                      //     <CustomInput
                                      //       type="radio"
                                      //       id={child.option_code}
                                      //       label={child.name}
                                      //       name="customRadio"
                                      //       inline
                                      //     />
                                      //   </Col>
                                      // ))
                                      item.detail.map((child, index) => {
                                        let isChecked = this.state.options.findIndex(
                                          (el) =>
                                            el.option_code === child.option_code
                                        );
                                        if (isChecked >= 0) {
                                          return (
                                            <Col xs="4" key={index}>
                                              <CustomInput
                                                type="radio"
                                                id={child.option_code}
                                                label={child.name}
                                                name="customRadio"
                                                inline
                                                onChange={this.handleChangeOptionType(
                                                  child.option_code
                                                )}
                                                checked={true}
                                              />
                                            </Col>
                                          );
                                        } else {
                                          return (
                                            <Col xs="4" key={index}>
                                              <CustomInput
                                                type="radio"
                                                id={child.option_code}
                                                label={child.name}
                                                name="customRadio"
                                                inline
                                                onChange={this.handleChangeOptionType(
                                                  child.option_code
                                                )}
                                                checked={false}
                                              />
                                            </Col>
                                          );
                                        }
                                      })
                                    }
                                  </Row>
                                </React.Fragment>
                              );
                            }
                          })}
                      </Col>
                    </FormGroup>
                  </Panel>
                </Collapse>
              </Panel>
            </Collapse>
          </Form>
        </DialogContent>
        <DialogActions className={styles.editItineraryForm}>
          <Button
            disabled={this.state.isLoading ? true : false}
            className="color-primary btn-start"
            type="button"
            onClick={this.handleSubmit.bind(this)}
          >
            {this.state.isLoading ? <CircularProgress /> : `Save`}
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditItineraryForm);
