import axios from "../axios-config";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";

import AppReducer from "../store/reducers";
import { getToken } from "../services/authService";

const configureAxios = () => {
  axios.interceptors.request.use((config) => {
    if (getToken()) {
      const token = getToken();
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config.method === "patch") {
      config.headers["Content-Type"] = "application/merge-patch+json";
    }

    return config;
  });
};

const configureStore = () => {
  const store = createStore(AppReducer, {}, applyMiddleware(thunk));

  configureAxios();

  return store;
};

export default configureStore;
