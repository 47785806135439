import React from 'react';
import { Button, Modal, Empty } from 'antd';
import formatMoney from 'accounting-js/lib/formatMoney';
import { format } from 'date-fns';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import * as QuotationApi from '../../../../../api/quotation';

const List = (props) => {
  const { history, setData, listData } = props;
  return (
    <Table className="table-customize">
      <Thead>
        <Tr>
          <Th>Start date</Th>
          <Th>End date</Th>
          <Th>Title</Th>
          <Th>Email</Th>
          <Th>Budget</Th>
          <Th>&nbsp;</Th>
        </Tr>
      </Thead>
      <Tbody>
        {listData.length > 0 ? (
          listData.map((item, key) => (
            <Tr className="body-row" key={key}>
              <Td>
                {item.start_date &&
                  format(new Date(item.start_date), 'MMM d yyyy')}
              </Td>
              <Td>
                {item.end_date && format(new Date(item.end_date), 'MMM d yyyy')}
              </Td>
              <Td className={item.tokenConfirm && 'data_title'}>
                <span>{item.title}</span>
                {item.tokenConfirm && (
                  <span className="email_sent">Email Sent</span>
                )}
              </Td>
              <Td>{item.email}</Td>
              <Td>
                {item.currency_infor &&
                  formatMoney(item.estimated_budget, {
                    symbol: item.currency_infor.symbol,
                    precision: item.currency_infor.decimal_digits,
                    thousand: ',',
                    decimal: '.',
                  })}
              </Td>
              <Td>
                <div className="action td-action">
                  <Button
                    className="btn-edit"
                    type="warning"
                    onClick={() =>
                      history.push({
                        pathname:
                          '/view-quotation/' +
                          item._id +
                          (window.location.hash && window.location.hash),
                        state: {
                          title: item.title,
                        },
                      })
                    }
                  >
                    View
                  </Button>
                  <Button
                    className="btn-edit"
                    type="primary"
                    onClick={() =>
                      history.push({
                        pathname:
                          '/edit-quotation/' +
                          item._id +
                          (window.location.hash && window.location.hash),
                        state: {
                          title: item.title,
                        },
                      })
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    className="btn-delete"
                    type="danger"
                    onClick={async () => {
                      Modal.confirm({
                        content:
                          'Are you sure you want to delete this quotation?',
                        okText: 'Delete',
                        okType: 'danger',
                        cancelText: 'Cancel',
                        onOk: async () => {
                          const response = await QuotationApi.deleteQuotation(
                            item._id
                          );
                          if (!response.error) setData();
                        },
                      });
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr className="body-row">
            <Td colSpan="7">
              <Empty />
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default List;
