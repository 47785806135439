import React, {useEffect, useState} from "react";
import {isBrowser, isMobile, isTablet} from "react-device-detect";
import {handleSlickGoTo} from "../../store/actions";
import {connect} from "react-redux";
import {Carousel} from "antd";

const SliderTynery = ({handleSlickGoTo, initialSlide, sliderSettings, ...props}) => {
    const getSlidesToShow = () => {
        if (isBrowser) {
            return props.slidesToShow ? props.slidesToShow : 3;
        } else if (isMobile) return 1;
        else if (isTablet) return 2;
    };

    const getInitialSlide = () => {
        if (initialSlide >= 0)
            handleSlickGoTo(initialSlide);
        return initialSlide
    };

    useEffect(() => {
        if (slider)
            slider.slickGoTo(props.goToIndex);
    }, [props.goToIndex])

    const defaultSettings = {
        dots: false,
        slidesToShow: getSlidesToShow(),
        slidesToScroll: 1,
        infinite: false,
        initialSlide: initialSlide >= 0 ? getInitialSlide() : 0,
        rows: props.rows > 1 ? props.rows : 1,
        slidesPerRow: props.slidesPerRow > 1 ? props.slidesPerRow : 1
    };

    const [settings, setSettings] = useState({...defaultSettings, ...(sliderSettings || {})});
    const [slider, setSlider] = useState();

    return (
        <Carousel
            ref={(slider) => setSlider(slider)}
            {...settings}
        >
            {props.list}
        </Carousel>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleSlickGoTo: (index) => {
            dispatch(handleSlickGoTo(index));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        goToIndex: state.slickGoToReducer,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderTynery);
