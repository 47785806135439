/*global google*/
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import styles from './index.scss';
import { isMyResource } from '../../services/utils.service';
import { withRouter } from 'react-router';
import { configBaseUrl } from '../../config';
import ApiService from '../../services/api.service';
import { connect } from 'react-redux';
import TimeLineViewIti from './time-line-view';
import { categoriesDefined } from '../../constants/categories';

class ItemView extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      expanded: true,
      duration: '',
      distance: '',
      images: [],
    };
  }

  handleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  componentDidMount = () => {
    // if (!this.props.isPdf) {
    //     if (this.props.item.data.actual_data.image) {
    //         var photo = document.getElementById(this.props.item._id)
    //         var src = this.props.item.data.actual_data.image
    //         photo.style.backgroundImage = "url('" + src + "')"
    //     }
    // }
    if (
      this.props.item.data.actual_data.images &&
      !!this.props.item.data.actual_data.images.length
    ) {
      let temp = [];
      let tempImgs = [...this.props.item.data.actual_data.images];
      tempImgs.forEach((item, index) => {
        if (index > 0) {
          if (isMyResource(item.preview)) {
            temp.push({
              src: configBaseUrl.baseURL + item.preview,
              thumbnail: configBaseUrl.baseURL + item.preview,
            });
          } else {
            temp.push({
              src: item.preview,
              thumbnail: item.preview,
            });
          }
        }
      });
      this.setState({
        images: temp,
      });
    }
    // if (this.props.item.transportation) {
    //   if (this.props.index < this.props.length - 1) {
    //     var transportation = this.props.item.transportation.name;
    //     if (this.props.item.transportation.name === "Car")
    //       transportation = "DRIVING";
    //     if (this.props.item.transportation.name === "Walk")
    //       transportation = "WALKING";
    //     if (this.props.item.transportation.name === "Bike")
    //       transportation = "BICYCLING";
    //     if (this.props.item.transportation.name === "Train")
    //       transportation = "TRANSIT";
    //     if (
    //       transportation === "DRIVING" ||
    //       transportation === "WALKING" ||
    //       transportation === "BICYCLING" ||
    //       transportation === "TRANSIT"
    //     ) {
    //       var origin = new google.maps.LatLng(
    //         this.props.item.data.actual_data.lat,
    //         this.props.item.data.actual_data.lng
    //       );
    //       var destination = new google.maps.LatLng(
    //         this.props.items[this.props.index + 1].data.actual_data.lat,
    //         this.props.items[this.props.index + 1].data.actual_data.lng
    //       );
    //       var service = new google.maps.DistanceMatrixService();
    //       service.getDistanceMatrix(
    //         {
    //           origins: [origin],
    //           destinations: [destination],
    //           travelMode: transportation,
    //           unitSystem: google.maps.UnitSystem.METRIC,
    //           avoidHighways: false,
    //           avoidTolls: false,
    //         },
    //         (response, status) => {
    //           if (status === "OK") {
    //             if (response.rows[0].elements[0].status === "OK") {
    //               this.setState({
    //                 duration: response.rows[0].elements[0].duration.text,
    //                 distance: response.rows[0].elements[0].distance.text,
    //               });
    //             } else {
    //               this.setState({
    //                 duration: "",
    //                 distance: "",
    //               });
    //             }
    //           }
    //         }
    //       );
    //     } else {
    //       this.setState({
    //         ...this.state,
    //         duration: "",
    //         distance: "",
    //       });
    //     }
    //   }
    //   this.setState({
    //     name: this.props.item.transportation.name
    //       ? this.props.item.transportation.name
    //       : this.state.current_itinerary.transportation,
    //     price: this.props.item.transportation.price
    //       ? this.props.item.transportation.price
    //       : "",
    //     distance: this.props.item.transportation.distance
    //       ? this.props.item.transportation.distance
    //       : "",
    //     time: this.props.item.transportation.time
    //       ? this.props.item.transportation.time
    //       : "",
    //     duration: this.props.item.transportation.duration
    //       ? this.props.item.transportation.duration
    //       : "",
    //     note: this.props.item.transportation.note
    //       ? this.props.item.transportation.note
    //       : "",
    //   });
    // }
  };

  handleView = (_id) => {
    var win = window.open(
      `${process.env.PUBLIC_URL}/attraction/${_id}`,
      '_blank'
    );
    win.focus();
    // this.props.history.push(`/attraction/${_id}`);
  };

  render() {
    return (
      <div
        className={
          this.props.categoryName === categoriesDefined.INFORMATION
            ? styles.itemInforView
            : styles.itemView
        }
      >
        <TimeLineViewIti
          handleView={this.handleView}
          preview={this.props.preview}
          item={this.props.item}
          index={this.props.index}
          images={this.state.images}
          categoryName={this.props.categoryName}
        />
        {this.props.item.quote && (
          <Row className="setting-transport">
            <Col xs="4" className="float-left left-distant">
              {/* {this.props.index < this.props.length - 1 && (
              <div
                className={
                  this.props.isPdf
                    ? "icon-transportation-pdf"
                    : "icon-transportation"
                }
              >
                <span>
                  {this.props.item.transportation.name === "Car" ? (
                    <MdDirectionsCar />
                  ) : this.props.item.transportation.name === "Walk" ? (
                    <MdDirectionsWalk />
                  ) : this.props.item.transportation.name === "Bike" ? (
                    <MdDirectionsBike />
                  ) : this.props.item.transportation.name === "Bus" ? (
                    <MdDirectionsBus />
                  ) : this.props.item.transportation.name === "Train" ? (
                    <MdTrain />
                  ) : this.props.item.transportation.name === "Boat" ? (
                    <MdDirectionsBoat />
                  ) : this.props.item.transportation.name === "Flight" ? (
                    <MdFlight />
                  ) : null}
                </span>
              </div>
            )}
            <p
              className={this.props.isPdf ? "description-transportation" : null}
            >
              {this.state.duration && this.state.distance
                ? this.state.duration + " (" + this.state.distance + ")"
                : null}
            </p> */}
            </Col>
            <Col xs="8" className="float-right">
              {this.props.item.quote && (
                <blockquote>
                  <div className="quote">{this.props.item.quote}</div>
                </blockquote>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
    currency_infor: state.itineraryReducer.currency_infor,
  };
};

export default withRouter(connect(mapStateToProps, null)(ItemView));
