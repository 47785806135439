import React from "react";
import {Link} from "react-router-dom";
import formatMoney from "accounting-js/lib/formatMoney";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {
    calcEndTime,
    format24hTo12h,
    formatSecondsToDuration,
    getImageFromUrl,
    isMyResource
} from "../../services/utils.service";
import {configBaseUrl} from "../../config";
import defaultImg from "../../assets/media/images/generic-activities-img.jpg";
import Gallery from "react-grid-gallery";
import {TimelineEvent} from "react-event-timeline";
import { GoLinkExternal } from "react-icons/go";
import {connect} from "react-redux";
import { GoReport } from "react-icons/go";
import { openFormReportPlace } from "../../store/actions";
import { categoriesDefined } from "../../constants/categories";

const eachItemInfor = {
    margin: 0,
};

class TimeLineViewIti extends React.Component<{handleView: void}>{

    handleOpenFormReportPlace = () => {
        this.props.openFormReportPlace(null)
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <TimelineEvent
                title={
                    <React.Fragment>
                        {this.props.categoryName !== categoriesDefined.INFORMATION ? <div className="report-section hide" onClick={this.handleOpenFormReportPlace}><span><GoReport /> Report</span></div> : null}
                        <p
                            className="place-title"
                            onClick={this.props.categoryName !== categoriesDefined.INFORMATION ? this.props.handleView.bind(this, this.props.item._id) : null}
                        >
                            {this.props.preview ? (
                                this.props.item.data && this.props.item.data.actual_data.title
                            ) : (
                                <Link to={`/attraction/${this.props.item._id}`}>
                                    {this.props.item.data &&
                                    this.props.item.data.actual_data.title}{" "}
                                    <GoLinkExternal />
                                </Link>
                            )}
                        </p>
                        <p className="place-address">
                            {this.props.item.data &&
                            this.props.item.data.actual_data.address}
                        </p>
                        {(this.props.item.data.actual_data.booked_through ||
                            this.props.item.data.actual_data.confirmation ||
                            this.props.item.data.actual_data.provider ||
                            this.props.item.data.actual_data.amount) && (
                            <div
                                className="content-event"
                                style={{
                                    background: `#f6f6f6`,
                                    padding: `10px`,
                                    marginTop: "20px",
                                }}
                            >
                                {this.props.item.data.actual_data.booked_through && (
                                    <div className="content">
                                        Booked through:{" "}
                                        <span className="value">
                        {this.props.item.data.actual_data.booked_through}
                      </span>
                                    </div>
                                )}
                                {this.props.item.data.actual_data.confirmation && (
                                    <div className="content">
                                        Reservation code:{" "}
                                        <span className="value">
                        {this.props.item.data.actual_data.confirmation}
                      </span>
                                    </div>
                                )}
                                {this.props.item.data.actual_data.amount && (
                                    <div className="content">
                                        Price:{" "}
                                        <span className="value">
                                            {formatMoney(this.props.item.data.actual_data.amount, {
                                                symbol: this.props.currency_infor.symbol,
                                                precision: this.props.currency_infor.decimal_digits,
                                                thousand: ",",
                                                decimal: ".",
                                            })}{" "}
                                            {this.props.currency_infor.code}
                      </span>
                                    </div>
                                )}
                            </div>
                        )}
                    </React.Fragment>
                }
                createdAt={
                    <div className="title-action">
                        {this.props.item.data.actual_data.time_start && (
                            <span className="time">
                  <AccessTimeIcon />
                  <span>
                    {format24hTo12h(
                        this.props.item.data.actual_data.time_start
                    )}
                  </span>
                </span>
                        )}
                        {!this.props.item.data.actual_data.time_start &&
                        this.props.item.data.actual_data.duration ? (
                            <span className="time">
                  <AccessTimeIcon />
                  <span>
                    Duration:{" "}
                      {formatSecondsToDuration(
                          this.props.item.data.actual_data.duration
                      )}
                  </span>
                </span>
                        ) : (
                            this.props.item.data.actual_data.time_start &&
                            this.props.item.data.actual_data.duration > 0 && (
                                <span className="time">
                    <span>
                      -{" "}
                        {calcEndTime(
                            this.props.item.data.actual_data.time_start,
                            this.props.item.data.actual_data.duration
                        )}
                    </span>
                  </span>
                            )
                        )}
                        {this.props.categoryName === categoriesDefined.ACCOMMODATION ||
                        this.props.categoryName === categoriesDefined.TRANSPORTATION ? (
                            <span className="status">
                  <span>
                    {this.props.item.data.actual_data.type.toUpperCase()}
                  </span>
                </span>
                        ) : null}
                    </div>
                }
                icon={
                    this.props.categoryName !== categoriesDefined.INFORMATION &&
                    (!!this.props.item.data.actual_data.images.length ? (
                        <>
                            <div
                                className="img-fluid-thumbnail"
                                style={{
                                    backgroundImage: `url(${
                                        !!this.props.item.data.actual_data.images.length
                                            ? getImageFromUrl(
                                            isMyResource(
                                                this.props.item.data.actual_data.images[0].preview
                                            )
                                                ? `${
                                                    configBaseUrl.baseURL +
                                                    this.props.item.data.actual_data.images[0]
                                                        .preview
                                                }`
                                                : `${this.props.item.data.actual_data.images[0].preview}`,
                                            "large"
                                            )
                                            : defaultImg
                                    })`,
                                }}
                            ></div>
                            <span
                                style={{
                                    position: `absolute`,
                                    textAlign: `-webkit-center`,
                                    top: `-20px`,
                                    right: `-5px`,
                                    background: `#0882e6`,
                                    borderRadius: `50%`,
                                    width: `50px`,
                                    height: `50px`,
                                    color: `#fff`,
                                }}
                            >
                  {this.props.index}
                </span>
                        </>
                    ) : (
                        <>
                            <div
                                className="img-fluid-thumbnail"
                                style={{
                                    backgroundImage: `url(${defaultImg})`,
                                }}
                            ></div>
                            <span
                                style={{
                                    position: `absolute`,
                                    textAlign: `-webkit-center`,
                                    top: `-20px`,
                                    right: `-5px`,
                                    background: `#0882e6`,
                                    borderRadius: `50%`,
                                    width: `50px`,
                                    height: `50px`,
                                    color: `#fff`,
                                }}
                            >
                  {this.props.index}
                </span>
                        </>
                    ))
                }
                className="each-event"
                style={this.props.categoryName === categoriesDefined.INFORMATION ? eachItemInfor : {}}
            >
                <div
                    className="description-event trix-content-temp"
                    dangerouslySetInnerHTML={{
                        __html: this.props.item.data.actual_data.descriptions,
                    }}
                ></div>
                {!!this.props.images.length && (
                    <div className="grid-gallery">
                        <Gallery
                            images={this.props.images}
                            enableImageSelection={false}
                        />
                    </div>
                )}
            </TimelineEvent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currency_infor: state.itineraryReducer.currency_infor,
    };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openFormReportPlace: (item) => {
      dispatch(openFormReportPlace(item));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeLineViewIti);
