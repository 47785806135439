import React, { Component } from "react";
import { connect } from "react-redux";
import html2pdf from "html2pdf.js";
import { Button, Col, Row, Skeleton, Modal } from "antd";

import * as QuotationApi from "../../../../../api/quotation";
import QuotationDetail from "./quotationView";
import Alert from "../../alert";
import LoadingOverLay from "../../loading";
import "./style.scss";

const alert = new Alert();

class ViewQuotation extends Component {
  state = {
    quotation: null,
    submitLoading: false,
  };

  componentDidMount() {
    this.getQuotation();
  }

  getQuotation() {
    QuotationApi.getById(this.props.match.params.id).then((response) => {
      this.setState({
        quotation: response.data,
      });
    });
  }

  sendMail() {
    this.setState({ submitLoading: true });
    QuotationApi
      .sendMail({ id: this.props.match.params.id })
      .then((response) => {
        if (!response.error) {
          alert.success("Success");
        } else alert.error("Error");
        this.setState({ submitLoading: false });
      })
      .catch((error) => {
        this.setState({ submitLoading: false });
      });
  }

  async exportPdf() {
    this.setState({ submitLoading: true });
    const pdfName = this.state.quotation.title;
    const element = document.getElementById("body-content");
    await html2pdf().set({
      margin: [5, 5, 10, 5],
      filename: pdfName + ".pdf",
      html2canvas: {
        letterRendering: true,
        useCORS: true,
        scale: 2,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: {
        mode: ['css'],
      }
    }).from(element).save();

    this.setState({ submitLoading: false });
  }
  render() {
    if (!this.state.quotation)
      return (
        <div className="view " id="pdf-quotation">
          <div id="body-content">
            <Skeleton active paragraph={{ rows: 1 }} title={false} />
          </div>
        </div>
      );

    return (
      <>
        <LoadingOverLay submitLoading={this.state.submitLoading} />
        <div className="view-quotation">
          <Row className="action">
            <Col xl={24}>
              <div className="content-action">
                <div className="send-mail">
                  <Button type="button" onClick={() => this.sendMail()}>
                    Send to client
                  </Button>
                </div>
                <div className="action-ucd">
                  <Button
                    type="button"
                    className="btn-reports-submit"
                    onClick={() => this.exportPdf()}
                  >
                    Download PDF
                  </Button>
                  <Button
                    className="btn-edit"
                    type="primary"
                    onClick={() =>
                      this.props.history.push({
                        pathname: "/edit-quotation/" + this.state.quotation._id,
                        state: {
                          title: this.state.quotation.title,
                        },
                      })
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    className="btn-delete"
                    type="danger"
                    onClick={async () => {
                      Modal.confirm({
                        content:
                          "Are you sure you want to delete this quotation?",
                        okText: "Delete",
                        okType: "danger",
                        cancelText: "Cancel",
                        onOk: async () => {
                          const response = await QuotationApi.deleteQuotation(
                            this.state.quotation._id
                          );
                          if (!response.error)
                            this.props.history.push("/quotations");
                        },
                      });
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <QuotationDetail
            company={this.props.company}
            quotation={this.state.quotation}
          />
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
    company: state.profileReducer.company,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewQuotation);
