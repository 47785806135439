import { actionTypes } from '../../constants/actionTypes'

const initialState = 0;

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_AFTER_LOGIN:
            return action.flag
        default:
            return state
    }
}
