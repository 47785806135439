import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Tooltip } from 'antd';
import { CreateItineraryForm } from '../../components';
import { withRouter } from 'react-router';
import styles from './index.scss';
import PlanIcon from '../../assets/media/images/icon-tyneri.png';
import ListIcon from '../../assets/media/images/icon-list.png';
import { config } from '../../config';
import { isMobileOnly } from 'react-device-detect';

class ButtonCreateTyneri extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateItineraryForm: false,
    };
  }

  closeCreateItineraryForm = () => {
    this.setState({
      showCreateItineraryForm: false,
    });
  };

  showFormCreateItinerary = () => {
    this.setState({
      showCreateItineraryForm: true,
    });
  };

  renderPopupCreateTyneri = () => {
    return (
      <Dialog
        open={this.state.showCreateItineraryForm}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="create-itinerary-form"
        className={styles.contentTrendy}
      >
        <MuiDialogTitle disableTypography className="popup-title">
          <Typography variant="h6">Build Your Own Itinerary</Typography>
          <IconButton
            aria-label="close"
            className="btn-close"
            onClick={this.closeCreateItineraryForm.bind(this)}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <CreateItineraryForm />
      </Dialog>
    );
  };

  render2ButtonMobile = () => {
    return (
      <div
        style={{
          width: `100%`,
          display: 'flex',
          justifyContent: 'space-between',
          color: '#F7664B',
        }}
      >
        <div
          className="btn-tyneri-list"
          onClick={this.showFormCreateItinerary.bind(this)}
        >
          <img width={30} height={30} src={PlanIcon} alt="plan-icon" />
          Create a new Tyneri
        </div>
        <div
          href={
            this.props.pageCompany
              ? config.http + '//' + config.domain + '/create-top-list'
              : '#'
          }
          onClick={() => {
            if (!this.props.pageCompany)
              this.props.history.push('/create-top-list');
          }}
          className="mx-3 btn-tyneri-list"
        >
          <img width={30} height={30} src={ListIcon} alt="list-icon" /> Make a
          List
        </div>
      </div>
    );
  };

  render2ButtonDesktop = () => {
    return (
      <>
        <Tooltip placement="bottom" title={'Create a new Tyneri'}>
          <div
            className="btn-tyneri-list"
            onClick={this.showFormCreateItinerary.bind(this)}
          >
            <img width={30} height={30} src={PlanIcon} alt="plan-icon" />
          </div>
        </Tooltip>
        <Tooltip placement="bottom" title={'Make a List'}>
          <div
            href={
              this.props.pageCompany
                ? config.http + '//' + config.domain + '/create-top-list'
                : '#'
            }
            onClick={() => {
              if (!this.props.pageCompany)
                this.props.history.push('/create-top-list');
            }}
            className="mx-3 btn-tyneri-list"
          >
            <img width={30} height={30} src={ListIcon} alt="list-icon" />
          </div>
        </Tooltip>
      </>
    );
  };

  render() {
    return (
      <div className="text-center d-flex" style={this.props.style || {}}>
        {this.renderPopupCreateTyneri()}
        {isMobileOnly
          ? this.render2ButtonMobile()
          : this.render2ButtonDesktop()}
      </div>
    );
  }
}

export default withRouter(ButtonCreateTyneri);
