/*global google*/
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateListPlacesExplore,
  startLoading,
  stopLoading,
  updateListLoad,
} from "../../store/actions";
import "./index.scss";
import { loadItem } from "../../services/storage.service";
import { searchTree } from "../../services/utils.service";
import $ from "jquery";
import ApiService from "../../services/api.service";
import _ from "lodash";
var autocomplete;
var autocompleteLsr;

const mapDispatchToProps = (dispatch) => {
  return {
    updateListLoad: (list) => {
      dispatch(updateListLoad(list));
    },
    updateListPlacesExplore: (list, status) => {
      dispatch(updateListPlacesExplore(list, status));
    },
    startLoading: () => {
      dispatch(startLoading());
    },
    stopLoading: () => {
      dispatch(stopLoading());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    listLoad: state.listLoadReducer.list,
  };
};

class SearchPlacesNearBy extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      selected2: [],
      params: "",
      category: "tourist_attraction",
      lat: "",
      lng: "",
      place: "",
      country: "",
      showIconClear: false,

      categories: [],
    };
  }

  componentDidMount = () => {
    this.setState({
      params: this.props.params,
    });

    this.apiService.getAttractionCategories().then((res) => {
      if (res.status === "success")
        this.setState({
          categories: res.categories,
        });
    });

    this.initAutoComplete();
  };

  initAutoComplete = () => {
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });

    let tempItem = searchTree(
      itineraries[current].itinerary_detail,
      this.props.params.idItineraryDetail
    )[0];

    this.setState({
      country: tempItem.country,
    });
    var input = document.getElementById("search-places-in-country");
    autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: [tempItem.alpha2Code],
    });
    autocompleteLsr = google.maps.event.addListener(
      autocomplete,
      "place_changed",
      () => {
        this.props.updateListLoad([]);
        this.props.updateListPlacesExplore([], null);
        var place = autocomplete.getPlace();
        if (place.geometry) {
          this.props.startLoading();
          var pyrmont = new google.maps.LatLng(
            place.geometry.location.lat(),
            place.geometry.location.lng()
          );
          var service = new google.maps.places.PlacesService(
            document.createElement("div")
          );
          this.setState({
            place: place,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
          const request = {
            location: pyrmont,
            radius: "1500",
            type: [`${this.state.category}`],
          };
          service.nearbySearch(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              results.splice(0, 0, place);
              this.props.stopLoading();
              this.props.updateListPlacesExplore(results, this.state.category);
            } else {
              let temp = [];
              temp.push(place);
              this.props.stopLoading();
              this.props.updateListPlacesExplore(temp, this.state.category);
            }
          });
        } else {
          this.props.stopLoading();
          this.props.updateListPlacesExplore([], null);
        }
      }
    );
  };

  initAutoCompleteAfterChangeCountry = (props, nextProps) => {
    if (props !== nextProps) {
      this.handleClearText();
      this.props.updateListPlacesExplore([], null);
      google.maps.event.clearInstanceListeners(autocomplete);
      google.maps.event.removeListener(autocompleteLsr);
      $(".pac-container").remove();
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });

      let tempItem = searchTree(
        itineraries[current].itinerary_detail,
        nextProps
      )[0];

      var input = document.getElementById("search-places-in-country");
      autocomplete = new google.maps.places.Autocomplete(input);
      this.setState({
        country: tempItem.country,
      });
      autocomplete.setComponentRestrictions({
        country: [tempItem.alpha2Code],
      });
      autocompleteLsr = google.maps.event.addListener(
        autocomplete,
        "place_changed",
        () => {
          this.props.updateListLoad([]);
          this.props.updateListPlacesExplore([], null);
          var place = autocomplete.getPlace();
          if (place.geometry) {
            this.props.startLoading();
            var pyrmont = new google.maps.LatLng(
              place.geometry.location.lat(),
              place.geometry.location.lng()
            );
            var service = new google.maps.places.PlacesService(
              document.createElement("div")
            );
            this.setState({
              place: place,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            });
            const request = {
              location: pyrmont,
              radius: "1500",
              type: [`${this.state.category}`],
            };
            service.nearbySearch(request, (results, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                results.splice(0, 0, place);
                this.props.stopLoading();
                this.props.updateListPlacesExplore(
                  results,
                  this.state.category
                );
              } else {
                let temp = [];
                temp.push(place);
                this.props.stopLoading();
                this.props.updateListPlacesExplore(temp, this.state.category);
              }
            });
          } else {
            this.props.stopLoading();
            this.props.updateListPlacesExplore([], null);
          }
          // this.setState({
          //     destination: place.formatted_address
          // })
        }
      );
    }
  };
  // updateListPlacesExplore = (list, status) => {
  //     this.setState({
  //         selected2: list
  //     }, () => {
  //         this.props.updateListPlacesExplore(list, status)
  //     })
  // }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    // let index = itineraries[current].itinerary_detail.findIndex(item => {
    //   return item._id === this.props.params.idItineraryDetail;
    // });

    //   itineraries[current].itinerary_detail[index].alpha2Code;
    // if (loadItem("currentAlpha2Code") && loadItem("itineraries")) {
    //   let currentAlpha2Code = loadItem("currentAlpha2Code");
    //   let itineraries;
    //   itineraries = loadItem("itineraries");
    //   let current = itineraries.findIndex(item => {
    //     return item._id === this.props.params.idItinerary;
    //   });
    //   let next = itineraries.findIndex(item => {
    //     return item._id === nextProps.params.idItinerary;
    //   });
    //   let nextIndex = itineraries[current].itinerary_detail.findIndex(item => {
    //     return item._id === nextProps.params.idItineraryDetail;
    //   });
    //   let nextAlpha2Code =
    //     itineraries[next].itinerary_detail[nextIndex].alpha2Code;
    //   removeItem("currentAlpha2Code");
    //   if (currentAlpha2Code !== nextAlpha2Code) {
    //     this.setState(
    //       {
    //         params: nextProps.params
    //       },
    //       () => {
    //         this.initAutoCompleteAfterChangeCountry();
    //       }
    //     );
    //   }
    // }

    if (nextProps.listLoad && !!nextProps.listLoad.length) {
      $("#search-places-in-country").val("");
      this.setState({
        showIconClear: false,
        lat: "",
        lng: "",
        place: "",
      });
    }

    this.initAutoCompleteAfterChangeCountry(
      this.props.params.idItineraryDetail,
      nextProps.params.idItineraryDetail
    );
  };

  changeCategory = (e) => {
    this.setState(
      {
        category: e.target.value,
      },
      () => {
        if (this.state.lat && this.state.lng) {
          this.props.updateListLoad([]);
          this.props.updateListPlacesExplore([], null);
          this.props.startLoading();
          var pyrmont = new google.maps.LatLng(this.state.lat, this.state.lng);
          var service = new google.maps.places.PlacesService(
            document.createElement("div")
          );
          const request = {
            location: pyrmont,
            radius: "1500",
            type: [`${this.state.category}`],
          };
          service.nearbySearch(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              results.splice(0, 0, this.state.place);
              this.props.stopLoading();
              this.props.updateListPlacesExplore(results, this.state.category);
            } else {
              let temp = [];
              temp.push(this.state.place);
              this.props.stopLoading();
              this.props.updateListPlacesExplore(temp, this.state.category);
            }
          });
        }
      }
    );
  };

  handleShowClearText = (e) => {
    if (e.currentTarget.value) {
      this.setState({
        showIconClear: true,
      });
    } else {
      this.setState({
        showIconClear: false,
      });
    }
  };

  handleClearText = () => {
    $("#search-places-in-country").val("");
    this.props.updateListPlacesExplore([], null);
    this.setState({
      showIconClear: false,
      lat: "",
      lng: "",
    });
  };

  getPlaceHolder = () => {
    let temp = _.find(this.state.categories, (item) => {
      return item.name_in_google === this.state.category;
    });
    return `Search ${temp.name} in ${this.state.country}`;
  };

  render() {
    return (
      <div className="input-group">
        <div className="input-group-btn search-panel">
          {!!this.state.categories.length && (
            <select
              onChange={this.changeCategory}
              name="search_param"
              id="search_param"
              className="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
            >
              {this.state.categories.map((item, index) => {
                if (item.name_in_google)
                  return (
                    <option key={index} value={item.name_in_google}>
                      {item.name}
                    </option>
                  );
              })}
            </select>
          )}
        </div>
        <input
          type="text"
          placeholder={
            !!this.state.categories.length ? this.getPlaceHolder() : ""
          }
          className="form-control"
          id="search-places-in-country"
          onChange={this.handleShowClearText}
        />
        {this.state.showIconClear && (
          <span
            className="btn btn-clear"
            onClick={this.handleClearText}
            style={{ marginLeft: `-40px`, zIndex: `3`, background: "#fff" }}
          >
            <i className="fa fa-times"></i>
          </span>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPlacesNearBy);
