import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import ApiService from '../../services/api.service';
import { connect } from 'react-redux';
import { saveProfile, saveCompany } from '../../store/actions';
import Box from '@material-ui/core/Box';
import ForgetPassword from '../form-forget-password';
import styles from './index.scss';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormSignIn from './components/FormSignIn';
import FormSignUp from './components/FormSignUp';

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    saveCompany: (company) => {
      dispatch(saveCompany(company));
    },
  };
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

class FormSignInUp extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      value: 0,
      showReset: false,
    };
  }

  handleChangeTab = (newValue) => {
    this.setState({
      value: newValue,
    });
  };

  handleChangeIndex = (index) => {
    this.setState({
      value: index,
    });
  };

  showFormReset = () => {
    this.setState({
      showReset: true,
    });
  };

  render() {
    return this.state.showReset ? (
      <DialogContent>
        <IconButton
          aria-label="close"
          style={{
            color: 'red',
            position: `absolute`,
            right: 0,
            zIndex: 9,
            top: 0,
          }}
        >
          <CloseIcon
            onClick={() => {
              this.setState({
                showReset: false,
              });
            }}
          />
        </IconButton>
        <ForgetPassword />
      </DialogContent>
    ) : (
      <DialogContent dividers>
        <Row className={styles.formSignInUp}>
          <IconButton
            aria-label="close"
            style={{
              color: 'red',
              position: `absolute`,
              right: 0,
              zIndex: 9,
              top: 0,
            }}
          >
            <CloseIcon
              onClick={() => {
                if (this.props.closeForm) {
                  this.props.closeForm();
                }
              }}
            />
          </IconButton>
          <Col xs="12">
            <SwipeableViews
              index={this.state.value}
              onChangeIndex={() => this.handleChangeIndex}
            >
              <TabPanel value={this.state.value} index={0}>
                <Typography
                  style={{ textAlign: 'center' }}
                  variant="h5"
                  gutterBottom
                >
                  Sign in to Tyneri
                </Typography>
                <FormSignIn
                  isPopup={true}
                  handleChangeTab={() => this.handleChangeTab(1)}
                  showFormReset={this.showFormReset}
                />
              </TabPanel>
              <TabPanel value={this.state.value} index={1}>
                <Typography
                  style={{ textAlign: 'center' }}
                  variant="h5"
                  gutterBottom
                >
                  Sign up to Tyneri
                </Typography>
                <FormSignUp
                  isPopup={true}
                  handleChangeTab={() => this.handleChangeTab(0)}
                />
              </TabPanel>
            </SwipeableViews>
          </Col>
        </Row>
      </DialogContent>
    );
  }
}

export default connect(null, mapDispatchToProps)(FormSignInUp);
