import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { keys } from '../../../constants/keySocialMedia';
import GoogleLogin from 'react-google-login';
import { GoogleLoginButton } from 'react-social-login-buttons';
import FormControl from '@material-ui/core/FormControl';
import { saveToken } from '../../../services/authService';
import ApiService from '../../../services/api.service';
import { connect } from 'react-redux';
import { saveProfile, saveCompany } from '../../../store/actions';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ErrorIcon from '@material-ui/icons/Error';
import FormHelperText from '@material-ui/core/FormHelperText';
import { AccountType } from '../../../params/AccountType';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    saveCompany: (company) => {
      dispatch(saveCompany(company));
    },
  };
};

class FormSignIn extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      value: 0,
      email: '',
      password: '',
      fullName: '',
      firstName: '',
      lastName: '',
      submitted: false,
      showSignInError: false,
      showSignUpError: false,
      showReset: false,
    };
  }

  responseGoogle = (response) => {
    const tokenBlob = new Blob(
      [JSON.stringify({ access_token: response.accessToken }, null, 2)],
      { type: 'application/json' }
    );
    const options = {
      method: 'POST',
      body: tokenBlob,
      mode: 'cors',
      cache: 'default',
    };
    this.apiService.loginWithGoogle(options).then((r) => {
      const token = r.headers.get('x-auth-token');
      r.json().then((result) => {
        if (token) {
          saveToken(token);
          this.props.saveProfile(result.user);
          if (result.user.accountType === AccountType.Pro && result.company) {
            this.props.saveCompany(result.company);
          }
        }
      });
    });
  };

  responseFacebook = (response) => {
    const tokenBlob = new Blob(
      [JSON.stringify({ access_token: response.accessToken }, null, 2)],
      { type: 'application/json' }
    );
    const options = {
      method: 'POST',
      body: tokenBlob,
      mode: 'cors',
      cache: 'default',
    };
    this.apiService.loginWithFacebook(options).then((r) => {
      const token = r.headers.get('x-auth-token');
      r.json().then((result) => {
        if (token) {
          saveToken(token);
          this.props.saveProfile(result.user);
          if (result.user.accountType === AccountType.Pro && result.company) {
            this.props.saveCompany(result.company);
          }
        }
      });
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSignIn = () => {
    this.setState({ submitted: true, showSignInError: false }, () => {
      this.apiService
        .login(this.state.email, this.state.password)
        .then((res) => {
          if (res.status === 'success') {
            saveToken(res.token);
            this.props.saveProfile(res.user);
            if (res.user.accountType === AccountType.Pro && res.company) {
              this.props.saveCompany(res.company);
            }
          } else
            this.setState({
              showSignInError: true,
              submitted: false,
            });
        });
    });
  };

  render() {
    console.log(this.props);
    return (
      <>
        <ValidatorForm onSubmit={this.handleSignIn}>
          <Row>
            <Container fluid={true}>
              <FormControl fullWidth={true} style={{ marginBottom: '1rem' }}>
                <TextValidator
                  variant="outlined"
                  ref={this.emailRef}
                  label="Email *"
                  onChange={this.handleChange('email')}
                  name="email"
                  value={this.state.email}
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    <span>
                      <ErrorIcon />
                      This field is required
                    </span>,
                    <span>
                      <ErrorIcon />
                      Email is not valid
                    </span>,
                  ]}
                />
              </FormControl>
              <FormControl fullWidth={true} style={{ marginBottom: '1rem' }}>
                <TextValidator
                  variant="outlined"
                  label="Password *"
                  onChange={this.handleChange('password')}
                  name="password"
                  type="password"
                  value={this.state.password}
                  validators={['required']}
                  errorMessages={[
                    <span>
                      <ErrorIcon />
                      This field is required
                    </span>,
                  ]}
                />
              </FormControl>
              {this.state.showSignInError && (
                <FormHelperText className="Mui-error">
                  Your email or password is incorrect
                </FormHelperText>
              )}
            </Container>
          </Row>
          <Row>
            <Container style={{ textAlign: `end` }} fluid={true}>
              <Button
                size="large"
                className="btn-sign-in"
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={this.state.submitted ? true : false}
              >
                Sign In
              </Button>
            </Container>
          </Row>
          <div className="section-sign-up-now">
            Forgot password?{' '}
            <span
              className="sign-up-now"
              onClick={() => this.props.showFormReset()}
            >
              Click here
            </span>
          </div>
        </ValidatorForm>
        <Row>
          <Col xs="12">
            <div style={{ margin: `0 auto`, marginTop: `4rem` }}>
              <p
                style={{
                  width: '100%',
                  textAlign: 'center',
                  borderBottom: '1px solid #F5F5F5',
                  lineHeight: '0.1em',
                  margin: '10px 0 20px',
                }}
              >
                <span style={{ background: '#fff', padding: '0 10px' }}>
                  Or sign in with
                </span>
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <GoogleLogin
              clientId={keys.GOOGLE_CLIENT_ID}
              buttonText="Login"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
              cookiePolicy={'single_host_origin'}
              render={(renderProps) => (
                <GoogleLoginButton
                  className="btn-google"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <span>Continue with Google</span>
                </GoogleLoginButton>
              )}
            />
          </Col>
        </Row>
        {/* <Row>
                  <Col xs="12">
                    <FacebookLogin
                      appId={keys.FACEBOOK_APP_ID}
                      fields="name,email,picture"
                      render={(renderProps) => (
                        <FacebookLoginButton onClick={renderProps.onClick}>
                          <span>Continue with Facebook</span>
                        </FacebookLoginButton>
                      )}
                      callback={this.responseFacebook.bind(this)}
                    />
                  </Col>
                </Row> */}
        <div className="section-sign-up-now">
          Not a member?{' '}
          <span
            className="sign-up-now"
            onClick={() =>
              this.props.isPopup
                ? this.props.handleChangeTab()
                : this.props.history.push('/sign-up')
            }
          >
            Sign up now
          </span>
        </div>
      </>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(FormSignIn));
