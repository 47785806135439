import React, {useEffect, useState} from "react";
import {Button, List, Skeleton} from "antd";
import ApiService from "../../services/api.service";
import {Link} from "react-router-dom";
import TyneriCard from "../tyneri-card/tyneriCard";
import moment from "moment";
import StringHelper from "../../helper/stringHelper";
import {isBrowser} from "react-device-detect";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import {getNameAuthor} from "../../services/utils.service";

const RightContentAttraction = ({grid, imageSize, descLimit = 100, className, load = true, ...props}) => {
    let location = useLocation();
    let params = queryString.parse(location.search);
    let apiService = ApiService();
    const [attractions, setAttractions] = useState([]);
    const [categoryId, setCategoryId] = useState(params.category || null);
    const [query, setQuery] = useState(params.q || null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(props.limit || (isBrowser ? 12 : 8));
    const [maxPage, setMaxPage] = useState(1);
    let gridDefault = {gutter: 16, column: 4};
    gridDefault = Object.assign(gridDefault, grid);

    async function fetchData (type = 'new') {
        const old = attractions;
        setLoading(true);
        if (type === 'next')
            setAttractions(attractions.concat([...new Array(limit)].map(() => ({ loading: true, name: {} }))));
        else
            setAttractions([].concat([...new Array(limit)].map(() => ({ loading: true, name: {} }))));
        let res = await apiService.getAttractionsByCategory({
            category_id: categoryId,
            name: query,
            page,
            limit,
            alpha2Code: props.alphaCode
        }, props.url);
        if (res.status === "success") {
            const data = type === 'next' ? old.concat(res.data) : res.data;
            setLoading(false);
            setAttractions(data);
            setMaxPage(res.total / limit);
            setPage(page);
        }
    }

    useEffect(() => {
        let params = queryString.parse(location.search);
        setCategoryId(params.category || null);
        setQuery(params.q || null);
        setPage(1);
    }, [location.search]);

    useEffect(() => {
        fetchData(page > 1 ? 'next' : 'new');
    }, [categoryId, query, page]);

    const loadMore = load && !loading && page < maxPage ? (
        <div
            style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,
                lineHeight: '32px',
            }}
        >
            <Button type="primary" shape="round" onClick={()=> setPage(page + 1)}>Show more</Button>
        </div>
    ) : null;

    return (
        <List
            className={className}
            itemLayout="vertical"
            size="large"
            grid={gridDefault}
            dataSource={attractions}
            loadMore={loadMore}
            renderItem={item => (
                <List.Item key={item._id}>
                    <Skeleton title={true} loading={item.loading} active>
                        {item.loading ? null : (<Link to={'/attraction/' + item._id}>
                            <TyneriCard
                                title={item.data.actual_data.title}
                                imageSize={imageSize}
                                images={(item.data.actual_data.images && item.data.actual_data.images.length) ? item.data.actual_data.images[0].preview : null}
                                subtitle={item.user?
                                    <div className="author">
                                        By <span className="author__name">{getNameAuthor(item.user, item.company)}</span> - {moment(new Date(item.created_at)).fromNow()}
                                    </div> : null}
                                description={StringHelper().stripeHtml(item.data.actual_data.descriptions, descLimit)}
                            />
                        </Link>)}
                    </Skeleton>
                </List.Item>
            )}
        />
    );
}

export default RightContentAttraction;
