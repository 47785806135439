import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Pagination from './Pagination';
import List from './List';

import * as QuotationApi from '../../../../../api/quotation';
import {
  PARAM_QUERY_STATUS_PENDING,
  PARAM_QUERY_STATUS_STATUS_CONFIRM,
  PARAM_QUERY_STATUS_NEW,
  PARAM_QUERY_STATUS_STATUS_CANCEL,
} from '../../../../../constants/quotation';

const QuotationList = (props) => {
  const [paramLimit, setParamLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    setData();
  }, []);

  const setData = async () => {
    const hash = window.location.hash;
    let params = '';
    if (hash === '' || hash === '#new') {
      params = PARAM_QUERY_STATUS_NEW;
    } else if (hash === '#pending') {
      params = PARAM_QUERY_STATUS_PENDING;
    } else if (hash === '#confirmed') {
      params = PARAM_QUERY_STATUS_STATUS_CONFIRM;
    } else if (hash === '#cancelled') {
      params = PARAM_QUERY_STATUS_STATUS_CANCEL;
    }
    setParamLimit(params.limit || 10);
    setPage(+params.page || 1);
    await QuotationApi.getListData(
      params,
      params.page || 1,
      params.limit || 10
    ).then((res) => {
      setListData(res.data);
      setTotalPage(res.total);
    });
  };

  return (
    <>
      <List {...props} setData={setData} listData={listData} />
      <Pagination
        {...props}
        paramLimit={paramLimit}
        page={page}
        listData={listData}
        totalPage={totalPage}
        setPage={setPage}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

export default connect(mapStateToProps, null)(QuotationList);
