import React, { Component } from "react";
import { ContentAttraction } from "../../components";
import ApiService from "../../services/api.service";
import { connect } from "react-redux";
import {
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout,
} from "../../store/actions";
import styles from "./index.scss";
import { loadItem, saveItem } from "../../services/storage.service";
import { getDateNow } from "../../services/utils.service";
import $ from "jquery";
import NewContentAttraction from "./new";
import Alert from "../../module/admin/components/alert";

const mapDispatchToProps = (dispatch) => {
  return {
    saveCurrentItinerary: (itinerary) => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

class Attraction extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: true,
      item: null,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.match.params._id !== nextProps.match.params._id) {
      this.state = {
        isLoading: true,
      };

      this.apiService.getPlaceDetail(nextProps.match.params._id).then((res) => {
        if (res.status === "success") {
          this.setState(
            {
              item: res.place_detail,
              isLoading: false,
            },
            () => {
              let attractionSeen =
                loadItem("attractionSeen") !== null
                  ? loadItem("attractionSeen")
                  : null;
              if (Object.keys(res.place_detail).length !== 0) {
                if (attractionSeen !== null) {
                  let uniqueAttraction = [];
                  uniqueAttraction.push({
                    ...res.place_detail,
                    last_seen: new Date(),
                  });
                  attractionSeen.attractions.map((x) =>
                    uniqueAttraction.filter((a) => a._id === x._id).length > 0
                      ? (x.last_seen = new Date())
                      : uniqueAttraction.push(x)
                  );
                  attractionSeen.attractions = uniqueAttraction;
                  if (attractionSeen.attractions.length > 5) {
                    attractionSeen.attractions.pop();
                  }
                  saveItem("attractionSeen", attractionSeen);
                } else this.saveNewAttractionSeen();
              }
            }
          );
        } else {
          let alert = new Alert();
          alert.error(res.error);
          this.props.history.push("/top-attractions");
        }
      });
    }
  };

  componentDidMount = async () => {
    this.apiService.getPlaceDetail(this.props.match.params._id).then((res) => {
      if (res.status === "success") {
        this.setState(
          {
            item: res.place_detail,
            isLoading: false,
          },
          () => {
            let attractionSeen =
              loadItem("attractionSeen") !== null
                ? loadItem("attractionSeen")
                : null;
            if (Object.keys(res.place_detail).length !== 0) {
              if (attractionSeen !== null) {
                let uniqueAttraction = [];
                uniqueAttraction.push({
                  ...res.place_detail,
                  last_seen: new Date(),
                });
                attractionSeen.attractions.map((x) =>
                  uniqueAttraction.filter((a) => a._id === x._id).length > 0
                    ? (x.last_seen = new Date())
                    : uniqueAttraction.push(x)
                );
                attractionSeen.attractions = uniqueAttraction;
                if (attractionSeen.attractions.length > 5) {
                  attractionSeen.attractions.pop();
                }
                saveItem("attractionSeen", attractionSeen);
              } else this.saveNewAttractionSeen();
            }
          }
        );
      } else {
        let alert = new Alert();
        alert.error(res.error);
        this.props.history.push("/top-attractions");
      }
    });
  };

  saveNewAttractionSeen = () => {
    let attractionSeen = {},
      attractions = [];
    attractionSeen.dateSeen = getDateNow();
    attractions.splice(0, 0, { ...this.state.item, last_seen: new Date() });
    let newAttractions;
    if (attractions.length > 5) {
      attractions.pop();
      newAttractions = attractions;
    } else newAttractions = attractions;
    attractionSeen.attractions = newAttractions;
    saveItem("attractionSeen", attractionSeen);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { isLoading } = this.state;
    return !isLoading ? (
      <NewContentAttraction item={this.state.item} />
    ) : (
      <div className={styles.itineraryDetail}></div>
    );
  }
}

export default connect(null, mapDispatchToProps)(Attraction);
