import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ApiService from "../../../services/api.service";

import { connect } from "react-redux";
import { format } from "date-fns";
import { Empty, Button, Modal } from "antd";
import { updateListLoad, closeFormLoad } from "../../../store/actions";
import {
  getImageFromUrl,
  isMyResource,
  getNameItinerary,
} from "../../../services/utils.service";
import { configBaseUrl } from "../../../config";
import defaultImg from "../../../assets/media/images/generic-activities-img.jpg";
import styles from "../index.scss";
import moment from "moment";
import { withRouter } from "react-router";
import Alert from "../../../module/admin/components/alert";
import { loadItem, saveItem } from "../../../services/storage.service";

const mapDispatchToProps = (dispatch) => {
  return {
    closeFormLoad: () => {
      dispatch(closeFormLoad());
    },
    updateListLoad: (list) => {
      dispatch(updateListLoad(list));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

class ListResponsive extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount = () => {};

  showConfirm = (_id) => {
    Modal.confirm({
      content: "Are you sure you want to delete this itinerary?",
      okText: "Delete",
      okType: 'danger',
      cancelText: "Cancel",
      onOk: () => {
        this.apiService.deleteItinerary(_id).then((res) => {
          if (res.status === "success") {
            const index = this.props.list.findIndex((item) => {
              return item._id === _id;
            });
            let temp = [...this.props.list];
            if (index > -1) {
              temp.splice(index, 1);
            }
            this.props.setDataTable(temp);
            let alert = new Alert();
            alert.success("Delete successfully");
          }
        });
      },
      onCancel() {},
    });
  };

  render() {
    return (
      <Table className={styles.listMyTyneries}>
        <Thead>
          <Tr>
            <Th>Start date</Th>
            <Th>Title</Th>
            <Th>Viewed</Th>
            <Th>Customize</Th>
            <Th>Last updated</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          {this.props.list.length > 0 ? (
            this.props.list.map((item, key) => (
              <Tr className="body-row" key={key}>
                <Td>{format(new Date(item.dates * 1000), "MMM d yyyy")}</Td>
                <Td>
                  <div className="title-image">
                    <div className="image">
                      <img
                        width="70px"
                        src={
                          item.cover
                            ? getImageFromUrl(
                                isMyResource(item.cover)
                                  ? configBaseUrl.baseURL + item.cover
                                  : item.cover,
                                "large"
                              )
                            : defaultImg
                        }
                        alt=""
                      />
                    </div>
                    <div className="item_detail">
                      <a href={"view-itinerary/" + item._id} target="blank">
                        {getNameItinerary(item)}
                      </a>
                      {item.reference && item.reference.name ? (
                        <>
                          <br />
                          <span className="source-text">Source: </span>
                          <a
                            href={"view-itinerary/" + item.reference._id}
                            target="blank"
                            className="source"
                          >
                            {" "}
                            {getNameItinerary(item.reference)}
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Td>
                <Td>{item.view ? item.view : 0}</Td>
                <Td>{item.customize}</Td>
                <Td>{moment(new Date(item.last_updated)).format("LLL")}</Td>
                <Td className="group-action">
                  <div className="td-action">
                    <Button
                      className="new-quotation-btn"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/create-quotation/" + item._id,
                          state: {
                            title: item.name,
                          },
                        });
                      }}
                    >
                      New Quotation
                    </Button>
                    <Button
                      className="btn-edit"
                      type="primary"
                      onClick={() =>
                        window.open(
                          "itinerary/" + item._id + "/" + item.itinerary_detail[0].children[0].children[0]._id
                        )
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      className="btn-delete"
                      type="danger"
                      onClick={() => {
                        this.showConfirm(item._id);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr className="body-row">
              <Td colSpan="7">
                <Empty />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListResponsive)
);
