import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  InputNumber,
  Select,
} from "antd";
import { EditFilled } from "@ant-design/icons";
import { connect } from "react-redux";

import { saveProfile } from "../../../../../store/actions";
import ApiService from "../../../../../services/api.service";
import Alert from "../../alert/index";
import FormNewJob from "./new-job";

const FormItem = Form.Item;
const { Option } = Select;

function FormInformation(props) {
  const apiService = ApiService();
  const form = props.form;
  const profile = props.profile;
  const { getFieldDecorator } = form;
  const [openForm, setOpenForm] = useState(false);
  const alert = new Alert();

  function submitInformation(e) {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const information = {
          firstName: values["firstName"],
          lastName: values["lastName"],
          email: values["email"],
          phone: values["phone"],
          twitter: values["twitter"],
          facebook: values["facebook"],
          location: {
            name: values["location"],
          },
          website: values["website"],
          about_me: values["about_me"],
          job: values["job"],
        };
        apiService.changeInfor(information).then((res) => {
          if (res.status === "success") {
            props.saveProfile({
              ...res.profile,
            });
            alert.success("Update profile successfully");
            setOpenForm(false);
          }
        });
      }
    });
  }

  function renderFormInformation() {
    return (
      <Modal
        visible={openForm}
        footer={
          <Button
            type="primary"
            htmlType="submit"
            onClick={(e) => {
              submitInformation(e);
            }}
          >
            Update
          </Button>
        }
        onCancel={() => setOpenForm(!openForm)}
      >
        <Form
          className="profile-form"
          name="profile-form"
          {...props.formItemLayout}
          onSubmit={(e) => {
            submitInformation(e);
          }}
        >
          <FormItem label="First name">
            {getFieldDecorator("firstName", {
              initialValue: profile.firstName ? profile.firstName : "",
              rules: [
                {
                  required: true,
                  message: "Please input your First name",
                  whitespace: true,
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem label="Last name">
            {getFieldDecorator("lastName", {
              initialValue: profile.lastName ? profile.lastName : "",
              rules: [
                {
                  required: true,
                  message: "Please input your Last name",
                  whitespace: true,
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem label="Email">
            {getFieldDecorator("email", {
              initialValue: profile.email,
              rules: [
                {
                  required: true,
                  message: "Please input your email",
                },
                {
                  type: "email",
                  message: "This is not validate email!",
                },
              ],
            })(<Input readOnly disabled />)}
          </FormItem>
          <FormItem label="Phone">
            {getFieldDecorator("phone", {
              initialValue: profile.phone ? profile.phone : "",
              rules: [
                {
                  required: true,
                  message: "Please input your phone",
                },
              ],
            })(<InputNumber maxLength={11} />)}
          </FormItem>

          <FormItem label="Location">
            {getFieldDecorator("location", {
              initialValue: profile.location ? profile.location.name : "",
            })(<Input />)}
          </FormItem>
          <FormItem label="Job title">
            {getFieldDecorator("job", {
              initialValue: profile.job ? profile.job._id : null,
              rules: [
                {
                  required: true,
                  message: "Please select your job",
                },
              ],
            })(
              <Select
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <FormNewJob
                      setOpenFormEdit={setOpenForm}
                      fetchAllJob={props.fetchAllJob}
                      formParent={form}
                    />
                  </div>
                )}
              >
                {props.jobs &&
                  props.jobs.map((job) => (
                    <Option key={job._id} value={job._id}>
                      {job.name}
                    </Option>
                  ))}
              </Select>
            )}
          </FormItem>

          <FormItem label="Facebook">
            {getFieldDecorator("facebook", {
              initialValue: profile.facebook ? profile.facebook : "",
              rules: [
                {
                  pattern: new RegExp(
                    /^[a-zA-Z0-9@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                  ),
                  message: "This is not validate facebook!",
                },
              ],
            })(<Input addonBefore="https://www.facebook.com/" />)}
          </FormItem>
          <FormItem label="Twitter">
            {getFieldDecorator("twitter", {
              initialValue: profile.twitter ? profile.twitter : "",
              rules: [
                {
                  pattern: new RegExp(
                    /^[a-zA-Z0-9@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                  ),
                  message: "This is not validate twitter!",
                },
              ],
            })(<Input addonBefore="https://twitter.com/" />)}
          </FormItem>
          <FormItem label="Person website">
            {getFieldDecorator("website", {
              initialValue: profile.website ? profile.website : "",
              rules: [
                {
                  type: "url",
                  message:
                    "This is not validate website! ex: https://yourwebsite.com",
                },
              ],
            })(<Input />)}
          </FormItem>
          <Form.Item label="About you">
            {getFieldDecorator("about_me", {
              initialValue: profile && profile.about_me ? profile.about_me : "",
            })(<Input.TextArea />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  return (
    <>
      {renderFormInformation()}
      <Button onClick={() => setOpenForm(true)}>
        <EditFilled />
        Edit
      </Button>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

export default Form.create()(
  connect(mapStateToProps, mapDispatchToProps)(FormInformation)
);
