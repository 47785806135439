import React from "react";
import { Input } from "antd";
import { EditorWysiwyg } from "../../components"
import "./index.scss";

class EditableText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      value: props.value || "",
      editClassName: props.editClassName,
      edit: (props.typeInput === "Text" && !props.value) ? true : false,
    };
  }

  renderText = () => {
    if (this.props.typeInput === "Text") {
      if (!this.state.value.trim()) return this.props.placeholder;
      return this.state.value;
    } else {
      if (
        !this.props.valueText ||
        !this.state.value.trim() ||
        this.state.value.trim() === "<p></p>"
      )
        return this.props.placeholder;
      return <div dangerouslySetInnerHTML={{ __html: this.props.valueText }} />;
    }
    // !this.state.value.trim() || this.state.value.trim() === "<p></p>" ? (
    //   this.props.placeholder
    // ) : this.props.typeInput === "Text" ? (
    //   this.state.value
    // ) : (
    //   <div dangerouslySetInnerHTML={{ __html: this.props.valueText }} />
    // );
  }
  render() {
    return (
      (this.state.edit === true &&
        (this.props.typeInput === "Text" ? (
          <Input
            {...this.props}
            name={this.state.name}
            value={this.state.value}
            className={this.state.editClassName}
            placeholder={this.props.placeholder}
            autoFocus={this.props.isFocus}
            onFocus={(event) => {
              const value = event.target.value;
              event.target.value = "";
              event.target.value = value;
              this.setState({ backup: this.state.value });
            }}
            onChange={(event) => {
              this.setState({ value: event.target.value }, () => {
                return this.props.onChange(this.state.value);
              });
            }}
            onBlur={(event) => {
              this.setState({ edit: false });
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                this.props.add();
              }
            }}
            onKeyUp={(event) => {
              if (event.key === "Escape") {
                this.setState({ edit: false, value: this.state.backup });
              }
              // if (event.key === "Enter") {
              //   this.setState({ edit: false });
              // }
            }}
          />
        ) : (
          <EditorWysiwyg
            initialValue={this.props.initialValue}
            onChange={(value) => {
              this.setState({ value: value }, () => {
                return this.props.onChange(value);
              });
            }}
            onBlur={(event) => {
              this.setState({ edit: false });
            }}
            onKeyUp={(event) => {
              if (event.key === "Escape") {
                this.setState({ edit: false, value: this.state.backup });
              }
              if (event.key === "Enter") {
                this.setState({ edit: false });
              }
            }}
            editTopList={true}
          />
        ))) || (
        <span
          className={
            this.props.typeInput === "Text"
              ? "text-edit-inline"
              : "comment-optional"
          }
          onClick={(event) => {
            this.setState({ edit: this.state.edit !== true });
          }}
        >
          {this.renderText()}
        </span>
      )
    );
  }
}

export default EditableText;
