import React, { Component } from "react";
import { Breadcrumb } from "antd";
import { connect } from "react-redux";
import ApiService from "../../services/api.service";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import ListTopLists from "./list-top-lists";
import TopView from "./top-view";

class ContentAllTopLists extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
    };
  }

  render() {
    return (
      <div id="content">
        <div
            id="sidebar"
            className="content-center"
        >
          <div className="grid-header bread">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Top lists</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <TopView/>
          <ListTopLists data={this.props.data}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

export default withRouter(connect(mapStateToProps, null)(ContentAllTopLists));
