import React, { Component } from "react";
import ApiService from "../../services/api.service";
import { Container } from "reactstrap";
import "./index.scss";
import { connect } from "react-redux";
import { Card, Button } from "antd";

const mapDispatchToProps = dispatch => {
  return {};
};

const mapStateToProps = state => {
  return {
    profile: state.profileReducer.info
  };
};

class BillingPlan extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: false,

      listMyItineraries: [],
      listMyFavorite: [],
      listDraft: [],

      selectedTab: 1
    };
  }

  handleChangeTab = index => {
    this.setState({
      selectedTab: index
    });
  };

  render() {
    return this.state.isLoading ? (
      <div className="loader"></div>
    ) : (
      <React.Fragment>
        <div className="header-form">
          <span>Billing</span>
        </div>
        <Container fluid={true}>
          <div className="contain-form">
            <Card title="Your billing subscriptions" style={{ width: 300 }}>
              <div style={{ display: "flex" }}>
                <p>Current pricing plan: "Basic</p>
                <Button type="primary">Upgrade</Button>
              </div>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingPlan);
