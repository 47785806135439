import React from "react";
import moment from "moment";
import Helmet from "react-helmet";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import { formatDate, parseDate } from "react-day-picker/moment";
import { dateToTimestamp } from "../../services/utils.service";
import { loadItem } from "../../services/storage.service";
import { differenceInCalendarDays, addDays } from "date-fns";
import "./index.scss";
import $ from "jquery";

class MultiDayCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: "",
      to: "",
      highlighted: [],
    };
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), "months") < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    if (!this.props.edit) {
      // Change the from date and focus the "to" input field
      let fromDay, toDay;
      fromDay = toDay = "";
      if (from > this.state.to) {
        this.setState({ from });
        fromDay = dateToTimestamp(from);
        fromDay = fromDay.toString();
        toDay = dateToTimestamp(from);
        toDay = toDay.toString();
        this.setState({ to: from });
      } else {
        this.setState({ from });
        fromDay = dateToTimestamp(from);
        fromDay = fromDay.toString();
        toDay = dateToTimestamp(this.state.to);
        toDay = toDay.toString();
      }
      if (isNaN(fromDay)) {
        if (
          !$(`input[placeholder='Start date']`).hasClass(
            "is-invalid-datepicker"
          )
        ) {
          $(`input[placeholder='Start date']`).addClass(
            "is-invalid-datepicker"
          );
        }
      } else {
        $(`input[placeholder='Start date']`).removeClass(
          "is-invalid-datepicker"
        );
        if (!isNaN(toDay)) {
          $(`input[placeholder='End date']`).removeClass(
            "is-invalid-datepicker"
          );
        }
      }
      this.props.handleChangeMultiDay(fromDay, toDay);
    } else {
      let fromDay, toDay;
      this.setState({ from });
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });

      if (typeof from === "undefined") {
        if (
          !$(`input[placeholder='Start date']`).hasClass(
            "is-invalid-datepicker"
          )
        ) {
          $(`input[placeholder='Start date']`).addClass(
            "is-invalid-datepicker"
          );
        }
        this.props.handleChangeMultiDay(this.state.from, this.state.to);
        return;
      } else {
        $(`input[placeholder='Start date']`).removeClass(
          "is-invalid-datepicker"
        );
      }
      let numberDaysAdd = differenceInCalendarDays(
        from,
        new Date(itineraries[current].dates * 1000)
      );
      let temp = [];
      itineraries[current].itinerary_detail.forEach((item) => {
        item.month_day_year = dateToTimestamp(
          addDays(new Date(item.month_day_year * 1000), numberDaysAdd)
        );
        temp.push(new Date(item.month_day_year * 1000));
      });
      this.setState({
        highlighted: temp,
        to: addDays(
          new Date(itineraries[current].end_dates * 1000),
          numberDaysAdd
        ),
      });
      fromDay = dateToTimestamp(from);
      fromDay = fromDay.toString();
      toDay = dateToTimestamp(
        addDays(new Date(itineraries[current].end_dates * 1000), numberDaysAdd)
      );
      toDay = toDay.toString();
      this.props.handleChangeMultiDay(fromDay, toDay);
    }
  }

  componentDidMount = () => {
    if (this.props.edit) {
      let from, to;
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      from = new Date(itineraries[current].dates * 1000);
      to = new Date(itineraries[current].end_dates * 1000);
      this.setState({
        from,
        to,
      });
      let temp = [];
      itineraries[current].itinerary_detail.forEach((item) => {
        temp.push(new Date(item.month_day_year * 1000));
      });
      this.setState({
        highlighted: temp,
      });
    } else {
      let from, to;
      from = to = "";
      from = dateToTimestamp(this.state.from);
      from = from.toString();
      to = dateToTimestamp(this.state.to);
      to = to.toString();
      this.props.handleChangeMultiDay(from, to);
    }
  };

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
    var fromDay, toDay;
    fromDay = toDay = "";
    fromDay = dateToTimestamp(this.state.from);
    fromDay = fromDay.toString();
    toDay = dateToTimestamp(to);
    toDay = toDay.toString();
    if (isNaN(toDay)) {
      if (
        !$(`input[placeholder='End date']`).hasClass("is-invalid-datepicker")
      ) {
        $(`input[placeholder='End date']`).addClass("is-invalid-datepicker");
      }
    } else {
      $(`input[placeholder='End date']`).removeClass("is-invalid-datepicker");
    }
    this.props.handleChangeMultiDay(fromDay, toDay);
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    const { highlighted } = this.state;
    const modifierSpecifyDays = { highlighted };
    return this.props.edit ? (
      <div className="InputFromTo">
        <DayPickerInput
          className="left"
          value={from}
          placeholder="Start date"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: modifierSpecifyDays,
            toMonth: to,
            modifiers: modifierSpecifyDays,
            numberOfMonths: 1,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />
        <DayPickerInput
          className="right"
          ref={(el) => (this.to = el)}
          value={to}
          inputProps={
            this.props.edit && {
              className: "day-picker-disabled",
              disabled: true,
            }
          }
          placeholder="End date"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: modifierSpecifyDays,
            disabledDays: { before: from },
            modifiers: modifierSpecifyDays,
            numberOfMonths: 1,
          }}
          onDayChange={this.handleToChange}
        />
        <Helmet>
          <style>{`
  .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 550px;
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -198px;
  }
`}</style>
        </Helmet>
      </div>
    ) : (
      <div className="InputFromTo">
        <DayPickerInput
          value={from}
          placeholder="Start date"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            // toMonth: to,
            modifiers,
            numberOfMonths: 1,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />
        <DayPickerInput
          ref={(el) => (this.to = el)}
          value={to}
          placeholder="End date"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { before: from },
            modifiers,
            numberOfMonths: 1,
            month: from,
          }}
          onDayChange={this.handleToChange}
        />
        <Helmet>
          <style>{`
.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
background-color: #f0f8ff !important;
color: #4a90e2;
}
.InputFromTo .DayPicker-Day {
border-radius: 0 !important;
}
.InputFromTo .DayPicker-Day--start {
border-top-left-radius: 50% !important;
border-bottom-left-radius: 50% !important;
}
.InputFromTo .DayPicker-Day--end {
border-top-right-radius: 50% !important;
border-bottom-right-radius: 50% !important;
}
.InputFromTo .DayPickerInput-Overlay {
width: 550px;
}
.InputFromTo-to .DayPickerInput-Overlay {
margin-left: -198px;
}
`}</style>
        </Helmet>
      </div>
    );
  }
}

export default MultiDayCalendar;
