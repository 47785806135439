/*global google*/
import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Button from "@material-ui/core/Button";
import { closeFormReportPlace } from "../../store/actions";
import { connect } from "react-redux";
import ApiService from "../../services/api.service";
import { Input, Form, Radio } from "antd";

const { TextArea } = Input;

const mapStateToProps = (state) => {
  return {
    isDisplayFormReportPlace: state.formReportPlaceReducer.open,
    item: state.formReportPlaceReducer.item,
    profile: state.profileReducer.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeFormReportPlace: () => {
      dispatch(closeFormReportPlace());
    },
  };
};

class FormReportPlace extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      categoryTab: 1,
      open: false,
      newStop: null,
      isLoadingDelete: false,
      isLoadingSave: false,
      isLoadingSaveToMyPlaces: false,
      validate: true,
      length: 0,
      order: 0,
      listPlaceDetail: [],

      openFormSaveToLibrary: false,
    };
  }

  handleCloseViewAndEditPlace = () => {
    this.props.closeFormReportPlace();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isDisplayFormReportPlace } = this.props
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
      marginRight: 0
    };

    return (
      <Dialog
        open={isDisplayFormReportPlace ? true : false}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <MuiDialogTitle className="popup-title" disableTypography>
          <Typography variant="h6">Report this place</Typography>
          <IconButton
            aria-label="close"
            className="btn-close"
            onClick={this.handleCloseViewAndEditPlace}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent dividers>
          <Form>
            <Form.Item name="radio-group">
              {getFieldDecorator("library", {
                rules: [
                  {
                    required: true,
                    message: "Please choose library!",
                  },
                ],
              })(
                <Radio.Group>
                  <Radio style={radioStyle} value={"1"}>
                    Incorrect information (address, opening hours, etc...)
                  </Radio>
                  <Radio style={radioStyle} value={"2"}>
                    Place is closed/ Does not exist
                  </Radio>
                  <Radio style={radioStyle} value={"3"}>
                    Place is illegal
                  </Radio>
                  <Radio style={radioStyle} value={"4"}>
                    Others (please specify below)
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item name="radio-group">
              {getFieldDecorator("comment", {
              })(
                <TextArea autoSize={{ minRows: 5, maxRows: 9 }}  placeholder="Enter your comments" allowClear />
              )}
            </Form.Item>
          </Form>
        </DialogContent>
        <DialogActions>
          <div>
            <Button type="button" className="btn-save-to-library">
              Submit report
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FormReportPlace));
