import React, {useEffect, useState} from "react";
import {Form, Icon, Input, Select} from "antd";
import styles from "./index.scss"
import ApiService from "../../services/api.service";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import {isMobileOnly} from "react-device-detect";
import _ from "lodash";

const FilterBar = ({form, ...props}) => {
    const [options, setOptions] = useState([]);
    const [params, setParams] = useState(queryString.parse(props.location.search));
    const {getFieldDecorator} = form;
    const apiServices = new ApiService();

    useEffect(() => {
        apiServices.getAttractionCategories().then(res => {
            if (res.status === 'success') {
                setOptions(res.categories);
            }
        });
    }, []);

    useEffect(() => {
        let qs = queryString.stringify(params);
        props.history.push({
            pathname: props.location.pathname,
            search: qs
        });
    }, [JSON.stringify(params)])

    const handleSubmit = (key, val) => {
        let ps = params;
        ps[key] = val;
        if (_.isEmpty(ps[key]))
            delete ps[key];
        setParams(ps);
    }

    /*useEffect(() => {
        let params = queryString.parse(props.location.search);
        setParams(params);
    }, [props.location.search]);*/

    return (
        <Form className={styles.filterForm} layout={isMobileOnly ? 'horizontal' : 'inline'} >
            <Form.Item>
                {getFieldDecorator('category', {
                    initialValue: params.category || ""
                })(
                    <Select className="category" onChange={(val) => handleSubmit('category', val)} style={{minWidth: 200}}>
                        <Select.Option key="default" value="">All</Select.Option>
                        {options.map(item => <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>)}
                    </Select>
                )}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator('q', {
                    initialValue: params.q || null
                })(
                    <Input allowClear onChange={(e) => handleSubmit('q', e.target.value)} placeholder="Type to find." suffix={<Icon type="search"/>}/>
                )}
            </Form.Item>
        </Form>
    );
};

export default withRouter(Form.create()(FilterBar));
