import React, { Component } from 'react'
import { MdAddCircleOutline, MdCheckCircle } from "react-icons/md"

import styles from './index.scss'
import ApiService from '../../services/api.service'
import { loadItem, saveItem } from '../../services/storage.service'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteIcon from '@material-ui/icons/Delete'
import { ObjectID } from 'bson'
import {
    searchTree,
    updateTree,
  } from "../../services/utils.service";

class PlacesInMyPlaces extends Component {
    constructor(props) {
        super(props);
        this.apiService = ApiService()
        this.state = {
            btnDropleft: false,
            open: false,
            categoryTab: 1,
            isLoadingAdd: false,
            isLoadingRemove: false,
            isChoosen: false
        };
    }

    handleViewAndEditPlace = () => {
        if (!this.state.open) {
            this.setState({
                open: true
            })
        }
    }

    handleCloseViewAndEditPlace = () => {
        this.setState({
            open: false
        })
    }

    handleChangeActivityTab = () => {
        this.setState({
            categoryTab: 1
        })
    };

    handleChangeLodgingTab = () => {
        this.setState({
            categoryTab: 2
        })
    };

    handleAddToListSelected = () => {
        this.setState({
            isLoadingAdd: true
        })
        let itineraries
        itineraries = loadItem('itineraries')
        let current = itineraries.findIndex(item => {
            return item._id === this.props.params.idItinerary
        })
        let index = itineraries[current].itinerary_detail.findIndex(item => {
            return item._id === this.props.params.idItineraryDetail
        })
        let temp =  {...this.props.item}
        temp._id = new ObjectID().toHexString()

        let tempItem = searchTree(
          itineraries[current].itinerary_detail,
          this.props.params.idItineraryDetail
        )[0];
        tempItem.place_detail.push(temp);
        let updated = updateTree(
          itineraries[current].itinerary_detail,
          tempItem
        );
        itineraries[current].itinerary_detail = updated;
        saveItem('itineraries', itineraries)
        this.setState({
            isLoadingAdd: false,
            isChoosen: true
        }, () => {
            this.props.newList(9)
        })
    }

    removeMyPlace = () => {
        this.setState({
            isLoadingRemove: true
        })
        this.apiService.removeMyPlace(this.props.item._id).then((res) => {
            if (res.status === 'success') {
                this.props.updateListMyPlaces(2, this.props.item._id)
                this.setState({
                    isLoadingRemove: false
                })
            }
        })
    }

    componentDidMount = () => {
        var photo = document.getElementsByClassName('small-view-2')
        for (let i = 0; i < photo.length; i++) {
            if (this.props.items[i] && this.props.items[i].data.actual_data.image) {
                var src = this.props.items[i].data.actual_data.image
                photo[i].style.backgroundImage = "url('" + src + "')"
            }
        }
    }

    render() {
        const { item } = this.props
        return (
            <div className={styles.placesInMyPlaces}>
                <div onClick={this.handleViewAndEditPlace.bind(this)}>
                    <div className="sidebar-idea-container ember-view draggable-item zoom-place">
                        <div className="ember-view component-idea-tile idea-tile-horizontal _can-view-details">
                            <figure className="ember-view small-view-2">
                                <div className="btn _btn-small _btn-outline-white"> View </div>
                            </figure>
                            <ul className="_c-info">
                                <li><span>{item.data.actual_data.title}</span></li>
                                <li><span>{item.data.actual_data.address}</span></li>
                                {/* <li><span dangerouslySetInnerHTML={{ __html: item.data.actual_data.descriptions}}></span></li> */}
                            </ul>
                            <div className="click-to-add" onClick={this.handleAddToListSelected} >
                                {this.state.isLoadingAdd ? <CircularProgress thickness={7} /> : this.state.isChoosen ? <MdCheckCircle className="isChecked" /> : <MdAddCircleOutline />}
                            </div>
                            {this.props.removeMode && 
                                <div className="click-to-remove" onClick={this.removeMyPlace.bind(this)} >
                                    {this.state.isLoadingRemove ? <CircularProgress thickness={7} /> : this.state.isChoosen ? <MdCheckCircle className="isChecked" /> : <DeleteIcon />}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (PlacesInMyPlaces);