import React, { useEffect, useRef, useState } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.scss"

const EditorWysiwyg = (props) => {
  const editorRef = useRef();
  const contentInit =
    props.initialValue !== "" &&  props.initialValue
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(props.initialValue)
          )
        )
      : EditorState.createEmpty();
  const [editorState, setEditorState] = useState(contentInit);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    return props.onChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  useEffect(() => {
    if (editorRef.current)
      editorRef.current.focusEditor();
}, [editorRef]);

  return (
    <React.Fragment>
      <div className="editor">
        <Editor
          initialValue={props.initialValue}
          onBlur={props.editTopList ? () => {
            props.onBlur();
          } : null}
          onKeyUp={props.editTopList ? () => {
            props.onKeyUp();
          } : null}

          toolbar={{
            options: [
              "inline",
              "fontFamily",
              "blockType",
              "fontSize",
              "textAlign",
              "colorPicker",
              "link",
            ],
          }}
          ref={editorRef}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />
      </div>
    </React.Fragment>
  );
};

export default EditorWysiwyg;
