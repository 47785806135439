import React from "react";
import { FormSignInUp, SocketContext } from "../../components";
import Step1 from "./step1";
import { withRouter } from "react-router-dom";
import styles from "../../components/content-detail/index.scss";
import stylesFrom from "./index.scss";
import { loadItem, saveItem } from "../../services/storage.service";
import ApiService from "../../services/api.service";
import _ from "lodash";
import ButtonMaterial from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import CreateNewFolderOutlinedIcon from "@material-ui/icons/CreateNewFolderOutlined";
import {Affix, Avatar, Button, Col, Form, Modal, Radio, Row} from "antd";
import Step2 from "./step2";
import { saveAfterLogin, updateInforCurrency, redirectToBillingAfterLogin } from "../../store/actions";
import { connect } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { ObjectID } from "bson";
import { getAllLeaf, isMyResource, moveOutDrafts, convertAdvanceFieldToNormal } from "../../services/utils.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import notiMusic from "../../assets/media/music/noti.mp3";
import WarningIcon from "@material-ui/icons/Warning";
import { format } from "date-fns";
import DialogActions from "@material-ui/core/DialogActions";
import { MobileOnlyView, TabletView, isMobileOnly } from "react-device-detect";
import { maxCharacters } from "../../constants/itinerary"
import { WarningFilled } from "@ant-design/icons";
import { Input, FormFeedback } from "reactstrap";
import stylesGlobal from "./index.scss"
const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
});

const UpdateDialog = ({ open, data, close, update }) => {
    return (
        <Dialog open={open}>
            <DialogContent style={{ fontSize: "1.2rem" }}>
                <div style={{ display: `flex`, flexDirection: "column" }}>
                    <div style={{ flex: `0 0 10%`, alignSelf: `center` }}>
                        <WarningIcon
                            style={{
                                fill: `#EEB711`,
                            }}
                        />
                    </div>
                    <div
                        style={{
                            fontSize: `1.2rem`,
                            padding: `0.5rem`,
                            textAlign: "left",
                        }}
                    >
                        <span style={{ fontWeight: `bold` }}>{data.written_by}</span> just
                        ADDED new information to this Tyneri at{" "}
                        {data.last_updated
                            ? format(new Date(data.last_updated), "HH:mm:ss MMM d, yyyy ")
                            : ""}
                    </div>
                    <div style={{ fontSize: `1rem`, color: `#959595` }}>
                        Would you like to see the updated version?
                    </div>
                </div>
            </DialogContent>
            <DialogActions
                className={styles.contentDetail}
                style={{ borderTop: "1px solid #DDDDDD" }}
            >
                <ButtonMaterial variant="outlined" onClick={() => close()}>
                    Skip
                </ButtonMaterial>
                <ButtonMaterial
                    id="update-now-ButtonMaterial"
                    variant="contained"
                    onClick={() => update()}
                >
                    UPDATE NOW
                </ButtonMaterial>
            </DialogActions>
        </Dialog>
    );
};

const FormSaveTyneri = ({
        open,
        close,
        handleSaveItinerary,
        isBelongToMe,
        isLoadingSave,
        changeItineraryName,
        ...props
    }) => {
    return (
        <Dialog className="on-mobile" open={open} onClose={close.bind(this)}>
            {!isBelongToMe ? (
                <DialogContent className={styles.contentDetail}>
                    <small className="warning-limit">
                        <WarningFilled /> Choose a name for your new Tyneri (limit to{" "}
                        <span>
              {props.name.length}/{maxCharacters}
            </span>{" "}
                        characters)
                    </small>
                    <Input
                        autoFocus
                        value={props.name}
                        type="text"
                        onChange={changeItineraryName.bind(this)}
                        name="name"
                        placeholder={
                            "Trying using the included destinations, i.e. Beijing-Thailand-Hongkong"
                        }
                    />
                    {props.name.length > maxCharacters ? (
                        <FormFeedback invalid="true" className="d-block">Exceed {maxCharacters} characters</FormFeedback>
                    ) : null}
                </DialogContent>
            ) : (
                <DialogContent style={{ textAlign: "center" }}>
                    Save tyneri
                </DialogContent>
            )}
            <DialogActions className={styles.contentDetail}>
                <ButtonMaterial
                    disabled={isLoadingSave}
                    id="send-ButtonMaterial"
                    className="btn-primary"
                    onClick={handleSaveItinerary.bind(this)}
                >
                    {isLoadingSave ? (
                        <CircularProgress size={24} className="processing" />
                    ) : (
                        "OK"
                    )}
                </ButtonMaterial>
                <ButtonMaterial
                    disabled={isLoadingSave}
                    variant="contained"
                    onClick={close.bind(this)}
                >
                    Cancel
                </ButtonMaterial>
            </DialogActions>
        </Dialog>
    );
};

class ItineraryCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 1,
            itinerary: props.itinerary,
            isLoadingSave: false,
            isBelongToMe: false,
            showFormLogin: false,
            showWarningHaveNewVersion: false,
            newVerItinerary: {},
            createFolderMode: false,
            loadingCreateFolder: false,
            myFolders: []
        };
        this.apiService = ApiService();
    }

    components = {
        step1: Step1,
        step2: Step2,
    };

    componentDidMount = async () => {
        // await this.triggerUpdateData();
        this.whenUrlChange = this.props.history.listen((location, action) => {
            if (action === "PUSH") {
                if (this.props.match.params.idItineraryDetail)
                    this.changeStep(2);
            } else {
                this.changeStep(1);
            }
        });
        if (this.props.match.params.idItineraryDetail) {
            this.changeStep(2);
        }
        let res = await this.apiService.getDetailCurrency(
            this.state.itinerary.currency
        );
        if (res.status === "success") {
            this.props.updateInforCurrency(res.currency);
        }
        if (this.props.profile) {
            this.apiService.getMyFolders().then((res) => {
                if (res.status === "success") {
                    this.setState({myFolders: res.folders,},
                        () => {
                            this.props.form.setFieldsValue({
                                folder: this.state.itinerary.belong_to_folder,
                            });
                        }
                    );
                }
            });
            if (
                this.props.profile._id === this.state.itinerary.belong_to_user ||
                !this.state.itinerary.belong_to_user
            ){
                this.setState({ isBelongToMe: true });
            }
        } else {
            if (!this.state.itinerary.belong_to_user) {
                this.setState({ isBelongToMe: true });
            }
        }
        this.props.socket.on("save_change", ({ idItinerary, itinerary }) => {
            if (idItinerary === this.props.match.params.idItinerary) {
                this.setState(
                    {
                        showWarningHaveNewVersion: true,
                        newVerItinerary: itinerary,
                    },
                    () => {
                        const audioEl = document.getElementsByClassName("audio-element")[0];
                        audioEl.play();
                    }
                );
            } else {
                this.beforeSave(itinerary);
            }
        });
    };

    handleSaveToFolder = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    handleChooseFolder = (e) => {
        e.preventDefault();
        if (!this.state.myFolders.length) {
            this.setState({
                visible: false,
            });
            return;
        }
        this.setState({
            loadingChooseFolder: true,
        });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values = {
                    ...values,
                    _id: this.state.itinerary._id,
                };
                this.apiService.chooseFolder(values).then(async (res) => {
                    if (res.status === "success") {
                        let itineraries;
                        itineraries = loadItem("itineraries");
                        let current = itineraries.findIndex((item) => {
                            return item._id === this.props.match.params.idItinerary;
                        });
                        itineraries[current].belong_to_folder = values.folder;
                        saveItem("itineraries", itineraries);
                        this.setState({
                            loadingChooseFolder: false,
                            createFolderMode: false,
                            visible: false,
                        });
                        await this.apiService.nameFolder(values).then((result) => {
                            if (res.status === "success") {
                                Swal.fire({
                                    type: "success",
                                    title: `Saved to "${result.folder.name}"`,
                                    showConfirmButton: true,
                                });
                            }
                        });
                    }
                });
            } else {
                this.setState({
                    loadingChooseFolder: false,
                });
            }
        });
    };

    showCreateFolder = () => {
        this.setState({ createFolderMode: true });
    };

    hideCreateFolder = () => {
        this.setState({ createFolderMode: false });
    };

    handleCreateFolder = (e) => {
        e.preventDefault();
        this.setState({
            loadingCreateFolder: true,
        });
        this.props.form.validateFields(["name"], (err, values) => {
            if (!err) {
                values = {
                    ...values,
                    _id: this.state.itinerary._id,
                };
                this.apiService.createFolder(values).then((res) => {
                    if (res.status === "success") {
                        let itineraries;
                        itineraries = loadItem("itineraries");
                        let current = itineraries.findIndex((item) => {
                            return item._id === this.props.match.params.idItinerary;
                        });
                        itineraries[current].belong_to_folder = res.folder._id;
                        saveItem("itineraries", itineraries);
                        this.setState({
                            loadingCreateFolder: false,
                            createFolderMode: false,
                            visible: false,
                            myFolders: [...this.state.myFolders, res.folder],
                        });
                        this.props.form.setFieldsValue({
                            folder: res.folder._id,
                        });
                        Swal.fire({
                            type: "success",
                            title: `Saved to "${values.name}"`,
                            showConfirmButton: true,
                        });
                    }
                });
            } else {
                this.setState({
                    loadingCreateFolder: false,
                });
            }
        });
    };

    onCloseWarningNewVersion = () => {
        this.setState({
            showWarningHaveNewVersion: false,
        });
    };

    onUpdateData = () => {
        this.beforeSave(this.state.newVerItinerary);
        /*let listDatesNew = getAllLeaf(this.state.newVerItinerary.itinerary_detail);
            let checkDateCurrentExistAfterSave = listDatesNew.some(el => {
                return el._id === this.props.match.params.idItineraryDetail;
            });*/
        if (isMobileOnly) {
            window.location = `/itinerary/${this.props.match.params.idItinerary}`;
        } else {
            window.location = `/itinerary/${this.props.match.params.idItinerary}/${this.props.match.params.idItineraryDetail}`;
        }
        /*if (checkDateCurrentExistAfterSave) {
                window.location = `/itinerary/${this.props.match.params.idItinerary}/${this.props.match.params.idItineraryDetail}`;
            }else {
                window.location = `/itinerary/${this.props.match.params.idItinerary}/${listDatesNew[0]._id}`;
            }*/
    };

    closeFormLogin = () => {
        this.props.redirectToBillingAfterLogin(0);
        this.setState({
            showFormLogin: false,
        });
    };

    componentDidUpdate(
        prevProps: Readonly<P>,
        prevState: Readonly<S>,
        snapshot: SS
    ): void {
        this.checkUser(prevProps);
    }

    checkUser = (prevProps) => {
        if (this.props.profile !== prevProps.profile && this.props.profile) {
            this.setState(
                {
                    isAuthenticated: true,
                    showFormLogin: false,
                },
                () => {
                    if (this.props.saveOrSaveAsAfterLogin > 0) {
                        this.handleOpenFormSaveItinerary();
                    }
                }
            );
            if (this.props.profile._id === this.state.itinerary.belong_to_user) {
                this.setState({
                    isBelongToMe: true,
                    user: this.props.profile,
                });
            }
        }
    };

    triggerUpdateData = async () => {
        const {
            match: {
                params: { idItinerary },
            },
        } = this.props;
        let res = await this.apiService.getItinerary(idItinerary);
        this.setState((preState) => {
            let state = { ...preState };
            if (res && res.status !== "failed") {
                state.itinerary = res.itinerary;
            } else {
                const itineraries = loadItem("itineraries");
                state.itinerary = itineraries.find((el) => el._id === idItinerary);
            }
            return state;
        });
    };

    componentWillUnmount() {
        if (typeof this.whenUrlChange === "function") this.whenUrlChange();
    }

    changeStep = (val) => {
        this.setState({
            activeStep: val || 1,
        });
    };

    changeItineraryName = (e) => {
        const { itinerary } = this.state;
        itinerary.name = e.target.value;
        let itineraries;
        itineraries = loadItem("itineraries");
        let current = itineraries.findIndex((item) => {
          return item._id === this.props.match.params.idItinerary;
        });
        itineraries[current].name = e.target.value
        saveItem("itineraries", itineraries);
        this.setState({ itinerary });
    };

    handleOpenFormSaveItinerary = () => {
        if (!this.props.profile) {
            if (this.state.isBelongToMe) this.props.saveAfterLogin(1);
            else this.props.saveAfterLogin(2);

            this.setState({
                showFormLogin: true,
            });
            return;
        }
        if (this.state.isBelongToMe) {
            this.handleSaveItinerary();
            return;
        }
        this.setState({
            openFormSaveItinerary: true,
        });
        this.props.saveAfterLogin(0);
    };

    closeFormSaveItinerary = () => {
        this.setState({
            openFormSaveItinerary: false,
        });
    };

    updateImageLink = (itineraries) => {
        getAllLeaf(itineraries.itinerary_detail).forEach((root) => {
            root.place_detail.forEach((child) => {
                delete child.belong_to_library
                delete child.belong_to_library_place
                delete child.isNew
                if (child.data.actual_data.images) {
                    child.data.actual_data.images.forEach((item) => {
                        if (isMyResource(item.thumbUrl)) {
                            item.thumbUrl = item.thumbUrl.replace(
                                "storage/uploads/",
                                "images/galleries/image-save-as-"
                            );
                            item.xhr = item.xhr.replace(
                                "storage/uploads/",
                                "images/galleries/image-save-as-"
                            );
                            item.preview = item.preview.replace(
                                "storage/uploads/",
                                "images/galleries/image-save-as-"
                            );
                        }
                    });
                }
            });
        });
    };

    beforeSave = (item) => {
        let itineraries = loadItem("itineraries");
        itineraries = itineraries.map((i) => {
            if (i._id === item._id) return item;
            return i;
        });
        saveItem("itineraries", itineraries);
    };

    handleSaveItinerary = async () => {
        this.props.saveAfterLogin(0);
        if (this.state.itinerary.name.length > maxCharacters)
            return;
        this.setState({
            isLoadingSave: true,
        });
        // const itinerary = this.loadAgainItinerary();
        let itineraries;
        itineraries = loadItem("itineraries");
        let current = itineraries.findIndex((item) => {
          return item._id === this.props.match.params.idItinerary;
        });
        var itinerary = itineraries[current];
        itinerary.belong_to_user = this.props.profile._id;
        itinerary.last_updated = new Date();
        itinerary.written_by = `${this.props.profile.firstName} ${this.props.profile.lastName}`;
        if (!this.state.isBelongToMe) {
            moveOutDrafts(itinerary._id);
            // if (!itinerary.reference)
            itinerary.reference = itinerary._id;
            itinerary.created_at = new Date();
            itinerary._id = new ObjectID().toHexString();
            if (itinerary.options && itinerary.options.length > 0) {
              itinerary.options.forEach((item) => {
                item.belong_to_itinerary = itinerary._id;
              });
            }

            itinerary.itinerary_detail.map((item) => {
                item._id = new ObjectID().toHexString();
                item.belong_to_itinerary = itinerary._id;
                item.children.map((country) => {
                    country.children.map((eachDate) => {
                        eachDate._id = new ObjectID().toHexString();
                        eachDate.place_detail.map((childItem) => {
                            childItem.belong_to_itinerary_detail = eachDate._id;
                            if (!childItem.isNew) {
                                childItem.belong_to_attraction = childItem._id;
                              }
                            childItem._id = new ObjectID().toHexString();
                            return childItem;
                        });
                        return eachDate;
                    });
                    return country;
                });
                return item;
            });
            let arrDates = getAllLeaf(itinerary.itinerary_detail);
            itinerary.end_dates = arrDates[arrDates.length - 1].month_day_year;

            this.updateImageLink(itinerary);
            itineraries[current] = itinerary
            itineraries[current] = convertAdvanceFieldToNormal(itineraries[current], this.props.profile)
            saveItem("itineraries", itineraries);
            moveOutDrafts(itineraries[current]._id);
            window.onbeforeunload = null;
            this.apiService
                .saveAsItinerary(itinerary, this.props.profile)
                .then((res) => {
                    if (res.status === "success") {
                        this.setState(
                            {
                                isLoadingSave: false,
                                openFormSaveItinerary: false,
                            },
                            () => {
                                getAllLeaf(
                                  itineraries[current].itinerary_detail
                                ).forEach((item) => {
                                  item.place_detail.forEach((child) => {
                                    delete child.isNew;
                                  });
                                });
                                saveItem("itineraries", itineraries);
                                window.location = `/itinerary/` + itinerary._id;
                            }
                        );
                    }
                });
        } else {
            // itinerary.name = this.state.itinerary.name;

            itinerary.saved = 1;
            if (itinerary.cover)
                itinerary.cover = itinerary.cover.replace(
                    "storage/uploads",
                    "images/cover"
                );
            this.beforeSave(itinerary);
            this.setState({ itinerary: itinerary }, () => {
                this.props.changeStatusSaved();
                this.props.socket.emit("save_change", {
                    idItinerary: this.props.match.params.idItinerary,
                    itinerary: itinerary,
                });
                this.updateImageLink(itinerary);
                itinerary = convertAdvanceFieldToNormal(itinerary, this.props.profile)
                itineraries[current] = itinerary
                saveItem("itineraries", itineraries);
                this.apiService
                    .saveItinerary(itinerary, this.props.profile)
                    .then((res) => {
                        if (res.status === "success")
                            this.setState(
                                {
                                    isLoadingSave: false,
                                    openFormSaveItinerary: false,
                                },
                                () => {
                                    Toast.fire({
                                        type: "success",
                                        title: "Save itinerary successfully",
                                    });
                                }
                            );
                    });
            });
        }
    };

    loadAgainItinerary = () => {
        let itineraries = loadItem("itineraries");
        const itinerary = itineraries.find(
            (item) => item._id === this.props.match.params.idItinerary
        );
        this.setState({
            itinerary: itinerary,
        });
        return itinerary;
    };

    UNSAFE_componentWillReceiveProps = async (nextProps) => {
        if (nextProps.isRedirectToBillingAfterLogin > 0) {
          this.handleRedirectToBilling(nextProps);
        }
    }

    handleRedirectToBilling = (nextProps) => {
        if (!nextProps.profile) {
          this.setState({
            showFormLogin: true,
          });
          return;
        }
        this.props.redirectToBillingAfterLogin(0);
        this.props.history.push("/billing")
    };

    render() {
        const TagName = this.components["step" + this.state.activeStep];
        const { getFieldDecorator } = this.props.form;
        return !_.isEmpty(this.state.itinerary) ? (
          <SocketContext.Provider
            className={this.props.className}
            value={this.props.socket}
          >
            <Modal
              title="Move to"
              visible={this.state.visible}
              maskClosable={false}
              onCancel={this.handleCancel}
              footer={[
                !this.state.createFolderMode ? (
                  <div className={stylesGlobal.sectionFooterSaveToFolder}>
                    <div
                      style={{
                        cursor: "pointer",
                        borderBottom: `1px solid #e8e8e8`,
                        paddingBottom: `12px`,
                        width: `100%`,
                        padding: `12px 24px`,
                      }}
                      onClick={this.showCreateFolder}
                    >
                      <Avatar
                        shape="square"
                        size="large"
                        style={{
                          color: "#fff",
                          backgroundColor: "#ccc",
                        }}
                      >
                        +
                      </Avatar>{" "}
                      New folder
                    </div>
                    <div
                      style={{
                        paddingBottom: `12px`,
                        width: `100%`,
                        padding: `12px 24px`,
                        textAlign: "right",
                      }}
                    >
                      <Button
                        onClick={this.handleChooseFolder}
                        key="submit"
                        type="primary"
                        className="btn-choose-folder"
                      >
                        Move
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className={stylesGlobal.sectionFooterSaveToFolder}>
                    <div
                      style={{
                        cursor: "pointer",
                        borderBottom: `1px solid #e8e8e8`,
                        paddingBottom: `12px`,
                        width: `100%`,
                        padding: `12px 24px`,
                      }}
                    >
                      <Form onSubmit={this.handleCreateFolder}>
                        <Form.Item label="Folder name">
                          {getFieldDecorator("name", {
                            rules: [
                              {
                                required: true,
                                message: "Please input your folder name!",
                              },
                            ],
                          })(<Input autoFocus placeholder="Folder name" />)}
                        </Form.Item>
                      </Form>
                    </div>
                    <div
                      style={{
                        paddingBottom: `12px`,
                        width: `100%`,
                        padding: `12px 24px`,
                        textAlign: "right",
                      }}
                    >
                      <Button onClick={this.hideCreateFolder} key="back">
                        Cancel
                      </Button>
                      <Button
                        key="submit"
                        loading={this.state.loadingCreateFolder}
                        onClick={this.handleCreateFolder}
                        type="primary"
                      >
                        Create
                      </Button>
                    </div>
                  </div>
                ),
              ]}
            >
              <Form onSubmit={this.handleChooseFolder}>
                {!!this.state.myFolders.length && (
                  <Form.Item name="radio-group">
                    {getFieldDecorator("folder", {
                      rules: [
                        {
                          required: true,
                          message: "Please choose folder!",
                        },
                      ],
                    })(
                      <Radio.Group>
                        {this.state.myFolders.map((item, key) => (
                          <>
                            <Radio value={item._id} key={key}>
                              <Avatar
                                shape="square"
                                size="large"
                                style={{
                                  color: "#f56a00",
                                  backgroundColor: "#fde3cf",
                                }}
                              >
                                {item.name.charAt(0).toUpperCase()}
                              </Avatar>{" "}
                              {item.name}
                            </Radio>
                          </>
                        ))}
                      </Radio.Group>
                    )}
                  </Form.Item>
                )}
              </Form>
            </Modal>
            <Dialog
              open={this.state.showFormLogin}
              onClose={() => this.closeFormLogin()}
              fullWidth={true}
              maxWidth={"xs"}
              aria-labelledby="form-login-social-media content"
            >
              <DialogContent style={{ minHeight: "569.281px" }}>
                <FormSignInUp closeForm={this.closeFormLogin} />
              </DialogContent>
            </Dialog>
            <FormSaveTyneri
              handleSaveItinerary={this.handleSaveItinerary}
              changeItineraryName={this.changeItineraryName}
              name={this.state.itinerary.name}
              isLoadingSave={this.state.isLoadingSave}
              isBelongToMe={this.state.isBelongToMe}
              open={this.state.openFormSaveItinerary}
              close={this.closeFormSaveItinerary}
            />
            <UpdateDialog
              update={this.onUpdateData}
              close={this.onCloseWarningNewVersion}
              open={this.state.showWarningHaveNewVersion}
              data={this.state.newVerItinerary}
            />
            {this.state.showWarningHaveNewVersion && (
              <audio className="audio-element">
                <source src={notiMusic}></source>
              </audio>
            )}
            <div
              style={{
                position: "relative",
                marginRight: "-1rem",
                marginLeft: "-1rem",
              }}
            >
              {this.state.itinerary ? (
                <>
                  {" "}
                  <MobileOnlyView renderWithFragment={true}>
                    <TagName
                      className={
                        stylesFrom.contentDetail + " " + styles.contentDetail
                      }
                      itinerary={this.state.itinerary}
                      location={this.props.location}
                      params={this.props.match.params}
                    />
                  </MobileOnlyView>
                  <TabletView>
                    <Row>
                      <Col md={10} lg={10} xl={8} span={12}>
                        <Step1
                          className={
                            stylesFrom.contentDetail +
                            " " +
                            styles.contentDetail
                          }
                          itinerary={this.state.itinerary}
                          location={this.props.location}
                          params={this.props.match.params}
                        />
                      </Col>
                      <Col md={14} lg={14} xl={16} span={12}>
                        <Step2
                          className={
                            stylesFrom.contentDetail +
                            " " +
                            styles.contentDetail
                          }
                          itinerary={this.state.itinerary}
                          location={this.props.location}
                          params={this.props.match.params}
                        />
                      </Col>
                    </Row>
                  </TabletView>
                </>
              ) : null}

              {this.state.isLoadingSave ? (
                <ButtonMaterial
                  variant="contained"
                  disabled
                  fullWidth
                  className="btn-loading btn-cus-bot"
                >
                  <CircularProgress className="circle-loading" size={20} />
                  SAVING...
                </ButtonMaterial>
              ) : (
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  size="small"
                  className="secondary-action btn-cus-bot"
                  fullWidth
                  aria-label="small outlined ButtonMaterial group"
                >
                  <ButtonMaterial
                    size="medium"
                    className="save-itinerary"
                    fullWidth
                    style={{
                      backgroundColor: "#4caf50",
                    }}
                    onClick={this.handleOpenFormSaveItinerary.bind(this)}
                  >
                    {this.state.isBelongToMe && this.props.saved ? (
                      <React.Fragment>
                        <SaveAltIcon /> &nbsp;UPDATE CHANGES
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <SaveAltIcon /> &nbsp;SAVE TO MY LIBRARY
                      </React.Fragment>
                    )}
                  </ButtonMaterial>
                  {this.state.isBelongToMe && this.props.saved && (
                    <ButtonMaterial
                      className="btn-save-to-folder"
                      onClick={this.handleSaveToFolder}
                    >
                      <div style={{ color: "white" }}>
                        <CreateNewFolderOutlinedIcon /> &nbsp;MOVE TO
                      </div>
                    </ButtonMaterial>
                  )}
                </ButtonGroup>
              )}
            </div>
          </SocketContext.Provider>
        ) : null;
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.profileReducer.info,
        saveOrSaveAsAfterLogin: state.saveAfterLoginReducer,
        isRedirectToBillingAfterLogin: state.redirectToBillingAfterLoginReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      saveAfterLogin: (flag) => {
        dispatch(saveAfterLogin(flag));
      },
      updateInforCurrency: (currency) => {
        dispatch(updateInforCurrency(currency));
      },
      redirectToBillingAfterLogin: (flag) => {
        dispatch(redirectToBillingAfterLogin(flag));
      },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(withRouter(ItineraryCreate)));
