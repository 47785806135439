import axios from 'axios';

const CancelToken = axios.CancelToken;

const axiosDefault = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN_API,
});

axiosDefault.CancelToken = CancelToken;
axiosDefault.isCancel = axios.isCancel;

export default axiosDefault;
