import {
  ItineraryDetail,
  ViewItinerary,
  Home,
  AllTyneris,
  TrendyItinerary,
  Attraction,
  TopAttractions,
  ResetPassword,
  ErrorPage,
  VerifyPage,
  Latest,
  TopDestinations,
  QuotationConfirm,
  Search,
  CreateTopLists,
  ViewTopList,
  AllTopLists,
  GetStarted,
  SignUp,
  SignIn,
} from '../../pages';
import RouteModel from '../model/route';
import NewViewItinerary from '../../pages/view-itinerary/new-view';

const routes = [
  RouteModel({
    path: '/sign-in',
    component: SignIn,
  }),
  RouteModel({
    path: '/sign-up/:package?',
    component: SignUp,
  }),
  RouteModel({
    path: '/get-started',
    component: GetStarted,
  }),
  RouteModel({
    path: '/top-lists',
    component: AllTopLists,
  }),
  RouteModel({
    path: '/view-top-list/:idTopList',
    component: ViewTopList,
  }),
  RouteModel({
    path: '/edit-top-list/:idTopList',
    component: CreateTopLists,
    privateRoute: true,
  }),
  RouteModel({
    path: '/create-top-list',
    component: CreateTopLists,
  }),
  RouteModel({
    path: '/',
    component: TrendyItinerary,
  }),
  RouteModel({
    path: '/itinerary/:idItinerary',
    component: ItineraryDetail,
  }),
  RouteModel({
    path: '/itinerary/:idItinerary/:idItineraryDetail',
    component: ItineraryDetail,
  }),
  RouteModel({
    path: '/all-tyneris',
    component: AllTyneris,
  }),
  RouteModel({
    path: '/latest',
    component: Latest,
  }),
  RouteModel({
    path: '/top-destinations',
    component: TopDestinations,
  }),
  RouteModel({
    path: '/top-destinations/country-:alphacode',
    component: TopDestinations,
  }),
  RouteModel({
    path: '/search',
    component: Search,
  }),
  RouteModel({
    path: '/top-attractions',
    component: TopAttractions,
  }),
  RouteModel({
    path: '/attraction/:_id',
    component: Attraction,
  }),
  RouteModel({
    path: '/preview-itinerary/:idItinerary',
    component: NewViewItinerary,
    customData: {
      isPreview: true,
    },
  }),
  RouteModel({
    path: '/reset-password/:token',
    component: ResetPassword,
  }),
  RouteModel({
    path: '/view-itinerary/:idItinerary',
    component: NewViewItinerary,
  }),
  RouteModel({
    path: '/old-view-itinerary/:idItinerary',
    component: ViewItinerary,
  }),
  RouteModel({
    path: '/verify-email/:token',
    component: VerifyPage,
  }),
  RouteModel({
    path: '/confirm-quotation/:quotationId',
    component: QuotationConfirm,
  }),
  RouteModel({
    path: '*',
    component: ErrorPage,
  }),
];

export default routes;
