import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import ApiService from "../../../services/api.service";
import { connect } from "react-redux";
import { Empty, Avatar, Button, Input, Modal, Form } from "antd";
import { updateListLoad, closeFormLoad } from "../../../store/actions";
import styles from "../index.scss";
import uniqueStyles from "./index.scss";

import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { PlacesInShared } from "../../../components";
import { SearchOutlined, EditFilled } from "@ant-design/icons";
import Alert from "../../../module/admin/components/alert";

const mapDispatchToProps = (dispatch) => {
  return {
    closeFormLoad: () => {
      dispatch(closeFormLoad());
    },
    updateListLoad: (list) => {
      dispatch(updateListLoad(list));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

class ListLibraries extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: false,
      visible: false,
      library: null,
      originLibrary: null,
      searchName: "",
      showFormEditName: false,
      name: "",
      _id: "",

      categories: []
    };
  }

  handleLoadStop = (list_places) => {
    this.props.updateListLoad(list_places);
    this.props.closeFormLoad();
  };

  handleEditMyPlaces = (item) => {
    this.apiService.getAttractionCategories().then((res) => {
      if (res.status === "success")
        this.setState({
          categories: res.categories,
        });
    });
    this.setState({
      visible: true,
      library: item,
      originLibrary: item,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      searchName: "",
    });
  };

  handleSearchInMyPlaces = (e) => {
    this.setState({
      searchName: e.target.value,
    });
    if (e.target.value)
      this.apiService
        .searchPlaceInLibrary(this.state.library._id, e.target.value)
        .then((res) => {
          if (res.status === "success") {
            let temp = { ...this.state.library };
            temp.list_places = res.list_places;
            this.setState({
              library: temp,
            });
          }
        });
    else
      this.setState({
        library: this.state.originLibrary,
      });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.listLibraries && this.state.library) {
      if (
        JSON.stringify(this.props.listLibraries) !==
        JSON.stringify(nextProps.listLibraries)
      ) {
        nextProps.listLibraries.forEach((item) => {
          if (item._id === this.state.library._id) {
            this.setState({
              library: item,
            });
          }
        });
      }
    }
  };

  handleDeleteLibrary = (item) => {
    Modal.confirm({
      content: "Are you sure you want to delete this library?",
      okText: "Delete",
      okType: 'danger',
      cancelText: "Cancel",
      onOk: () => {
        this.apiService.deleteLibrary(item).then((res) => {
          if(res.status === "success") {
            this.props.setNewListLibrary()
          }
        });
      },
      onCancel() {},
    });
  };

  handleOpenFormEditName = (name, _id) => {
    this.setState({
      showFormEditName: true,
      name,
      _id,
    })
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.currentTarget.value,
    });
  };

  handleChangeName = () => {
    let library = {
      _id: this.state._id,
      name: this.state.name,
    };
    this.setState({
      isSubmit: true,
    });
    if (!this.state.name) return;
    this.apiService.changeNameLibrary({ library }).then((res) => {
      if (res.status === "success") {
        this.props.setNewListLibrary()
        this.setState(
          {
            showFormEditName: false,
            isSubmit: false,
          },
          () => {
            let alert = new Alert();
            alert.success("Saved successfully");
          }
        );
      }
    });
  };

  render() {
    return (
      <>
        <Dialog
          open={this.state.visible ? true : false}
          maxWidth={"md"}
          fullWidth={true}
        >
          <MuiDialogTitle className="popup-title" disableTypography>
            <Typography variant="h6">
              {this.state.library ? this.state.library.name : null}
            </Typography>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={this.handleCancel.bind(this)}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent dividers className={styles.formSaveToLibrary}>
            <Input
              placeholder="input stop's name"
              allowClear
              prefix={<SearchOutlined />}
              size="large"
              style={{ marginBottom: `8px` }}
              onChange={this.handleSearchInMyPlaces.bind(this)}
              value={this.state.searchName}
            />
            {this.state.searchName &&
            !!this.state.library.list_places.length ? (
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                {this.state.library.list_places.length}{" "}
                {this.state.library.list_places.length > 1 ? `stops` : `stop`}
              </div>
            ) : null}
            {this.state.library && !!this.state.library.list_places.length ? (
              this.state.library.list_places.map((item, key) => (
                <div
                  key={key}
                  style={{ marginBottom: `8px` }}
                  className={uniqueStyles.listLibraries}
                >
                  <PlacesInShared
                    setNewListLibrary={this.props.setNewListLibrary}
                    length={this.state.library.list_places.length}
                    item={item}
                    items={this.state.library.list_places}
                    manage={true}
                    categories={this.state.categories}
                  />
                </div>
              ))
            ) : (
              <Empty />
            )}
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.showFormEditName ? true : false}
          onClose={() => {
            this.setState({
              showFormEditName: false,
            });
          }}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <MuiDialogTitle disableTypography className="popup-title">
            <Typography variant="h6">{this.state.name}</Typography>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={() => {
                this.setState({
                  showFormEditName: false,
                });
              }}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent dividers>
            <Form.Item
              label="Name"
              required
              validateStatus={
                this.state.isSubmit && this.state.name === "" ? "error" : ""
              }
              help={
                this.state.isSubmit && this.state.name === ""
                  ? "Name is required"
                  : ""
              }
            >
              <Input
                value={this.state.name}
                onChange={this.handleChange("name")}
              />
            </Form.Item>
          </DialogContent>
          <DialogActions>
            <Button type="primary" onClick={this.handleChangeName.bind(this)}>
              Change
            </Button>
          </DialogActions>
        </Dialog>

        <Table className={styles.listMyTyneries}>
          <Thead className="header past">
            <Tr>
              <Th>Name</Th>
              <Th>&nbsp;</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.listLibraries.length > 0 ? (
              this.props.listLibraries.map((item, key) => (
                <Tr className="body-row" key={key}>
                  <Td>
                    <Avatar
                      shape="square"
                      size="large"
                      style={{
                        color: "#f56a00",
                        backgroundColor: "#fde3cf",
                      }}
                    >
                      {item.name.charAt(0).toUpperCase()}
                    </Avatar>{" "}
                    <div className="library-name">
                      {" "}
                      <span>
                        {item.name} ({item.list_places.length}{" "}
                        {item.list_places.length > 1 ? "stops" : "stop"})
                      </span>{" "}
                      {this.props.manage ? (
                        <EditFilled
                          onClick={this.handleOpenFormEditName.bind(
                            this,
                            item.name,
                            item._id
                          )}
                        />
                      ) : null}
                    </div>
                  </Td>
                  <Td className="group-action">
                    <div className="td-action">
                      {this.props.manage ? (
                        <>
                          <Button
                            onClick={this.handleEditMyPlaces.bind(this, item)}
                            type="primary"
                            className="btn-edit"
                          >
                            Edit
                          </Button>
                          <Button
                            type="danger"
                            onClick={this.handleDeleteLibrary.bind(this, item)}
                            className="btn-delete"
                          >
                            Delete
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={this.handleLoadStop.bind(
                            this,
                            item.list_places
                          )}
                        >
                          Load
                        </Button>
                      )}
                    </div>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr className="body-row">
                <Td colSpan="3">
                  <Empty />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListLibraries);
