import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Radio, Button, Modal, Empty } from "antd";
import InquiriesApi from "../../../../services/inquiriesApi.service";
import ContactsApi from "../../../../services/contactApi.service";
import InquiriesDetail from "./detail";
import InquiriesModel from "./inquiriesModel";
import moment from "moment";
import "./index.scss";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import styles from "../../../../components/list-my-tyneries/index.scss";
import { getNameItinerary } from "../../../../services/utils.service";

const Status = {
  new: 0,
  seen: 1,
  close: 2,
};

function Inquiries(props) {
  const inquiriesApi = InquiriesApi();
  const contactsApi = ContactsApi();
  const [listInquiriesRead, setInquiriesRead] = useState([]);
  const [listInquiriesUnRead, setInquiriesUnRead] = useState([]);
  const [listInquiriesClose, setInquiriesClose] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [selectedTab, setSelectedTab] = useState(Status.new);
  const [openDetail, setOpenDetail] = useState(false);
  const [itemDetail, setItemDetail] = useState({});

  useEffect(() => {
    if (props.profile) {
      if (props.match.params.tab) {
        let tabIndex = +props.match.params.tab;
        setSelectedTab(tabIndex);
        setData(tabIndex);
      } else {
        setSelectedTab(0);
        setData(0);
      }
    }
  }, [props.profile]);

  const setData = async (statusUpdate = null) => {
    await inquiriesApi
      .getInquiriesByUserIdAndStatus(props.profile._id, Status.new)
      .then((res) => {
        if (!res.error) {
          setInquiriesUnRead(parseInquiriesObToArray(res.data));
          if (statusUpdate === null || statusUpdate === Status.new)
            setDataTable(parseInquiriesObToArray(res.data));
        }
      });

    await inquiriesApi
      .getInquiriesByUserIdAndStatus(props.profile._id, Status.seen)
      .then((res) => {
        if (!res.error) {
          setInquiriesRead(parseInquiriesObToArray(res.data));
          if (statusUpdate === Status.seen)
            setDataTable(parseInquiriesObToArray(res.data));
        }
      });

    await inquiriesApi
      .getInquiriesByUserIdAndStatus(props.profile._id, Status.close)
      .then((res) => {
        if (!res.error) {
          setInquiriesClose(parseInquiriesObToArray(res.data));
          if (statusUpdate === Status.close)
            setDataTable(parseInquiriesObToArray(res.data));
        }
      });
  };

  function handleChangeTab(e) {
    let tabIndex = e.target.value;
    setSelectedTab(tabIndex);
    props.history.push(`/inquiries/${tabIndex}`);

    if (tabIndex === Status.seen) setDataTable(listInquiriesRead);
    else if (tabIndex === Status.new) setDataTable(listInquiriesUnRead);
    else setDataTable(listInquiriesClose);
  }

  function parseInquiriesObToArray(dataArray) {
    let listDataPase = [];
    dataArray.forEach((data) => {
      listDataPase.push(
        new InquiriesModel({
          key: data._id,
          title: data.title,
          information: data.information,
          firstName: data.firstName,
          phone: data.phone,
          email: data.email,
          created_at: data.created_at,
          itinerary: data.itinerary,
          date: data.date,
          status: data.status,
          lastName: data.lastName,
        })
      );
    });
    return listDataPase;
  }

  function updateStatusInquiries(id, status) {
    inquiriesApi.updateInquiriesStatus(id, status).then((res) => {
      if (!res.error) {
        setData(status);
        setSelectedTab(status);
      }
    });
  }

  function saveContact(inquiry) {
    const data = {
      firstName: inquiry.firstName,
      lastName: inquiry.lastName,
      phone: inquiry.phone,
      email: inquiry.email,
      belong_to_user: props.profile._id,
    };
    contactsApi.save(data).then((res) => {
      if (!res.error) {
        props.history.push("/contacts");
      }
    });
  }

  function deleteInquiries(item){
    Modal.confirm({
      content: "Are you sure you want to delete this inquiry?",
      okText: "Delete",
      okType: 'danger',
      cancelText: "Cancel",
      onOk: () => {
        inquiriesApi.deleteInquiries(item.key);
        setData(selectedTab);
      },
      onCancel() {},
    });
  }


  function ListInquiries(props) {
    return (
      <Table className={styles.listMyTyneries}>
        <Thead>
          <Tr>
            <Th>Time</Th>
            <Th>Date</Th>
            <Th>Guest</Th>
            <Th>Itinerary</Th>
            <Th>Request title</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.listData.length > 0 ? (
            props.listData.map((item, key) => (
              <Tr className="body-row" key={key}>
                <Td>
                  {moment(new Date(item.created_at)).format(" h:mm:ss A")}
                </Td>
                <Td>{moment(new Date(item.created_at)).format("MMMM D")}</Td>
                <Td>
                  <>
                    <strong>{item.firstName + " " + item.lastName}</strong>
                    <br />
                    <a href={"mailto:" + item.email}>{item.email}</a>
                  </>
                </Td>
                <Td>{getNameItinerary(item.itinerary)}</Td>
                <Td>{item.title}</Td>
                <Td>
                  <div className="td-action">
                    <Button
                      type="primary"
                      onClick={() => {
                        setOpenDetail(!openDetail);
                        setItemDetail(item);
                      }}
                    >
                      View
                    </Button>
                      <Button type="danger" onClick={() => deleteInquiries(item)}>Delete</Button>
                  </div>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr className="body-row">
              <Td colSpan="7">
                <Empty />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    );
  }

  return (
    <div className="inquiries-container">
      <Radio.Group
        onChange={(event) => handleChangeTab(event)}
        value={selectedTab}
      >
        <Radio.Button className="new-radio radio-custom" value={Status.new}>
          New
        </Radio.Button>
        <Radio.Button className="seen-radio radio-custom" value={Status.seen}>
          Pending
        </Radio.Button>
        <Radio.Button className="close-radio radio-custom" value={Status.close}>
          Answered
        </Radio.Button>
      </Radio.Group>

      <ListInquiries listData={dataTable} />
      <InquiriesDetail
        contactsApi={contactsApi}
        open={openDetail}
        item={itemDetail}
        status={Status}
        updateStatusInquiries={updateStatusInquiries}
        saveContact={saveContact}
        closeDetail={() => setOpenDetail(false)}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inquiries);
