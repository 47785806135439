import React from "react";
import "./index.scss";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import HotelIcon from "@material-ui/icons/Hotel";

class ItineraryDetailOption extends React.Component {
  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  validateMeals = (meal) => {
    for(let i = 0; i < meal.length; i++) {
      if(meal[i])
        return true
    }
    return false
  }

  render() {
    return (
      <div className="overview-itinerary-detail-option">
        {this.props.lodging && this.props.lodging.included ? (
          <div className="section-link-lodging">
            <span>
              <span style={{ fontWeight: "bold" }}>
                <HotelIcon /> Lodging:
              </span>{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.google.com/maps/search/?api=1&query=${this.props.lodging.title} ${this.props.lodging.address}`}
              >
                {this.props.lodging.title}
              </a>
            </span>
          </div>
        ) : this.props.lodging && !this.props.lodging.included ? (
          <div className="section-link-lodging">
            <span>
              <span style={{ fontWeight: "bold" }}>
                <HotelIcon /> Lodging:
              </span>{" "}
              not included
            </span>
          </div>
        ) : (
          null
        )}

        {this.props.amountMeals &&
        this.props.amountMeals.included &&
        this.props.meal &&
        this.validateMeals(this.props.meal) ? (
          <div className="section-tag-meal">
            <span style={{ fontWeight: "bold" }}>
              <RestaurantIcon /> Meals:
            </span>
            {this.props.meal.map((item, index) => {
              if (item === "breakfast")
                return (
                  <div
                    key={index}
                    className="section-tag-meal__breakfast"
                    data-text={this.capitalize(item)}
                  ></div>
                );
              if (item === "lunch")
                return (
                  <div
                    key={index}
                    className="section-tag-meal__lunch"
                    data-text={this.capitalize(item)}
                  ></div>
                );
              if (item === "dinner")
                return (
                  <div
                    key={index}
                    className="section-tag-meal__dinner"
                    data-text={this.capitalize(item)}
                  ></div>
                );
            })}
          </div>
        ) : this.props.amountMeals &&
          !this.props.amountMeals.included  ? (
          <div className="section-tag-meal not-flex">
            <span style={{ fontWeight: "bold" }}>
              <RestaurantIcon /> Meals:
            </span>{" "}
            not included
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

export default ItineraryDetailOption;
