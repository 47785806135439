import React, { useState, useEffect } from "react";
import { Tabs } from "antd";

import ListLibraries from "../../../../components/list-my-tyneries/list-libraries";
import ListTopLists from "../../../../components/list-my-tyneries/list-top-lists";
import ListFolder from "./listFolder/listFolder";
import ApiService from "../../../../services/api.service";

import styles from "./index.scss";

const { TabPane } = Tabs;
const tabsKey = [1, 2, 3];

function MyLibrary(props) {
  const apiService = ApiService();
  const [listLibraries, setListLibraries] = useState([]);
  const [topLists, setTopLists] = useState([]);
  const hash = props.history.location.hash.substring(1);

  useEffect(() => {
    getListLibraries();
    setNewListTopLists();
  }, []);

  function getListLibraries() {
    apiService.getListLibraries().then((res) => {
      if (res.status === "success") {
        setListLibraries(res.list_libraries);
      }
    });
  }

  function setNewListTopLists() {
    apiService.getMyTopList().then((res) => {
      if (res.status === "success") {
        setTopLists(res.data);
      }
    });
  }

  function handleTabClick(key) {
    props.history.push({ pathname: `/my-library/#${key}` });
  }

  return (
    <div className="my-library-container">
      <Tabs
        defaultActiveKey={tabsKey.includes(parseInt(hash)) ? hash : "1"}
        className={ " card-custom "+  "library-tab"}
        onChange={handleTabClick}
        type="card"
      >
        <TabPane tab="My Tyneri" key="1">
          <ListFolder manage={true} {...props} />
        </TabPane>
        <TabPane tab="My Places" key="2">
          <div className="table-custom">
            <ListLibraries
              manage={true}
              listLibraries={listLibraries}
              setNewListLibrary={getListLibraries}
            />
          </div>
        </TabPane>
        <TabPane tab="My Lists" key="3">
          <div className="table-custom">
            <ListTopLists
              setNewListTopLists={setNewListTopLists}
              data={topLists}
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default MyLibrary;
