import React from "react";
import { Layout } from "antd";
import { connect } from "react-redux";

import NoImage from "../../assets/media/images/no-photo.png";

import HeaderMenu from "./header/menu";
import { config } from "../../config/index";
import { isMyResource } from "../../services/utils.service";
import "./index.scss";

const { Header,  Content } = Layout;

function LayoutCompany(props) {
  return (
    <Layout className="company-layout">
      <Header>
        <HeaderMenu />
      </Header>
      <section
        className="banner"
        style={{
          background:
            "url('" +
            (props.profileDomain && isMyResource(config.baseURL + props.profileDomain.banner)
              ? config.baseURL + props.profileDomain.banner
              : NoImage) +
            "')",
        }}/>
      <Content className="content">
        <div className="children tyneri-container">
          {props.children}
        </div>
       </Content>
    </Layout>
  );
}
const mapDispatchToProps = () => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
    companyDomain: state.profileReducer.companyDomain,
    profileDomain: state.profileReducer.profileDomain,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutCompany);
