import React, { useState } from 'react';
import { Button, Col, Form, Input, notification, Row } from 'antd';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import PaymentService from '../../../../services/payment.service';
import { saveProfile } from '../../../../store/actions';
import { connect } from 'react-redux';

const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const AddCard = (props) => {
  const card = props.profile ? props.profile.card || null : null;
  const { stripe, form } = props;
  const { getFieldDecorator } = form;
  const [addLoading, setAddLoading] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const { stripe, elements, form } = props;
    form.validateFields(async (err, values) => {
      if (!err) {
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }
        setAddLoading(true);
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            email: values.email || props.profile.email,
            name:
              values.name ||
              props.profile.firstName + ' ' + props.profile.lastName,
            phone: values.phone || props.profile.phone,
          },
        });
        if (error) {
          notification.error({
            message: error.message,
            placement: 'bottomRight',
          });
          setAddLoading(false);
        } else {
          PaymentService()
            .stripCreateCustomer(paymentMethod)
            .then((res) => {
              if (res.status === 'success') {
                notification.success({
                  message: 'Add card success.',
                  placement: 'bottomRight',
                });
                setAddLoading(false);
                props.saveProfile(res.user);
                props.closeModal();
              } else {
                console.log(res.error);
              }
            })
            .catch((err) => {
              let mes = err.response.data.mes;
              notification.error({
                message:
                  mes.code + ' ' + (mes.decline_code ? mes.decline_code : ''),
                placement: 'bottomRight',
              });
              setAddLoading(false);
            });
        }
      }
    });
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)} className="form-card">
      <Row type="flex" className="step-action">
        <Col span={24}>
          <div className={'card-holder flex-column' + (card ? 'cview' : '')}>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                  },
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ],
              })(
                <Input
                  name="email"
                  className="holder-name"
                  size="large"
                  placeholder="Email"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                  },
                ],
              })(
                <Input
                  name="phone"
                  className="holder-name"
                  size="large"
                  placeholder="Phone"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                  },
                ],
              })(
                <Input
                  name="name"
                  className="holder-name"
                  size="large"
                  placeholder="Card Holder Name"
                />
              )}
            </Form.Item>
            <Form.Item>
              <CardElement options={CARD_OPTIONS} />
            </Form.Item>
            <Form.Item>
              <Button
                loading={addLoading}
                htmlType="submit"
                disabled={!stripe}
                size="large"
                type="primary"
              >
                Add
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

const InjectedCheckoutForm = (props) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <AddCard
          {...props}
          type={props.type}
          elements={elements}
          stripe={stripe}
        />
      )}
    </ElementsConsumer>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
  };
};

export default Form.create()(
  connect(mapStateToProps, mapDispatchToProps)(InjectedCheckoutForm)
);
