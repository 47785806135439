import React, {Component} from "react";
import {Card} from "antd";
import {withRouter} from "react-router";
import DefaultBackground from "../../assets/media/images/generic-activities-img.jpg";
import ImageHelper from "../../functions/image";

class CountryCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null
        };
    }

    async componentDidMount()  {
        await this.loadImage(this.props.item.name.toLowerCase().replace(/\s/g, "") + ".jpg");
    }

    handleSeeItinerariesOfCountry = (alpha2Code) => {
    this.props.history.push(`/top-destinations/country-${alpha2Code}`);
  };

    loadImage = async imageName => {
        try {
            const image = await import("../../assets/media/images/top-destinations/" + imageName);
            this.setState({image: image.default});
        }catch (err) {
            this.setState({image: 'not found'});
        }
    }

  render() {
      var color = [
          null,
          'red',
          'blue',
          'yellow'
      ];
      var index = Math.floor(Math.random()*color.length);
    return (
      <div className="country-card">
        <Card
          onClick={this.handleSeeItinerariesOfCountry.bind(
            this,
            this.props.item.alpha2Code
          )}
          hoverable
          cover={
            <figure className={'section-img-top-destination '+color[index]}>
                {this.state.image ? <ImageHelper
                    alt="example"
                    className="img-top-destination"
                    notFound={DefaultBackground}
                    src={this.state.image}
                /> : null}
              <figcaption className="section-img-top-destination__name">
                  <h3>{this.props.item.name}</h3>
              </figcaption>
            </figure>
          }
          bodyStyle={{ display: "none" }}
        />
      </div>
    );
  }
}

export default withRouter(CountryCard);
