import React from "react";
import { Empty } from "antd";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import moment from "moment";

const TableQuotation = ({ data, reportName }) => {
  return (
    <div className="table-children">
      <span className="recent-customers-text">{reportName}</span>
      {data.length === 0 ? (
        <Empty />
      ) : (
        <div className="content">
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <strong>Title</strong>
                </Th>
                <Th>
                  <strong>Customer email</strong>
                </Th>
                <Td>
                  <strong>Start date </strong>
                </Td>
              </Tr>
            </Thead>
            <Tbody>
              {data &&
                data.map((item, index) => (
                  <Tr className="item" key={index}>
                    <Td>
                      <a href={"/edit-quotation/" + item._id} target="blank">
                        {item.title}
                      </a>
                    </Td>
                    <Td>{item.email}</Td>
                    <Td className="">{moment(item.start_date).format("LL")}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default TableQuotation;
