import React from 'react';
import { FolderFilled, FolderOpenFilled } from '@ant-design/icons';
import { Tag, Button, Modal, Typography } from 'antd';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import { Tr, Td } from 'react-super-responsive-table';
import moment from 'moment';

import {
  getImageFromUrl,
  isMyResource,
  formatViews,
  getAllLeaf,
} from '../../../../../services/utils.service';
import apiService from '../../../../../services/api.service';
import { configBaseUrl } from '../../../../../config';
import defaultImg from '../../../../../assets/media/images/generic-activities-img.jpg';
import EditFolder from './form/editFolder';
import MoveTo from './form/moveTo';
import { updateListLoad, closeFormLoad } from '../../../../../store/actions';
import { connect } from 'react-redux';

const { Text } = Typography;
const ApiService = apiService();

const ItemItinerary = ({
  item,
  history,
  itemList,
  getMyFolder,
  listFolder,
  manage,
  updateListLoad,
  closeFormLoad,
}) => {
  const deleteItinerary = (id) => {
    Modal.confirm({
      content: 'Are you sure you want to delete this itinerary?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        ApiService.deleteItinerary(id).then((res) => {
          if (res.status === 'success') {
            getMyFolder();
          }
        });
      },
      onCancel() {},
    });
  };

  const handleLoadStop = (id) => {
    ApiService.loadItinerary(id).then((res) => {
      if (res.status === 'success') {
        let newListLoad = [];
        getAllLeaf(res.itinerary.itinerary_detail).forEach((item) => {
          item.place_detail.forEach((child) => {
            newListLoad.push(child);
          });
        });
        updateListLoad(newListLoad);
        closeFormLoad();
      }
    });
  };

  return (
    <Tr className="tr-item">
      <Td width={manage ? '40%' : '90%'} className="title">
        <div className="title-image">
          <div className="image">
            <img
              width="70px"
              height="70px"
              src={
                item.cover
                  ? getImageFromUrl(
                      isMyResource(item.cover)
                        ? configBaseUrl.baseURL + item.cover
                        : item.cover,
                      'large'
                    )
                  : defaultImg
              }
              alt=""
            />
          </div>
          <div className="item_detail">
            <a
              href={process.env.PUBLIC_URL + '/view-itinerary/' + item._id}
              target="_blank"
              className="name"
            >
              {item.name}
            </a>
            <br />
            <div className="information">
              <span className="when">
                <strong>When: </strong>{' '}
                {moment(new Date(item.dates * 1000)).format('LL')}
              </span>
              <span className=" budget ">
                | <strong>Budget: </strong>
                {formatMoney(item.estimated_budget, {
                  symbol: item.currency_infor.symbol,
                  precision: item.currency_infor.decimal_digits,
                  thousand: ',',
                  decimal: '.',
                })}
              </span>
              <span className="expense">
                | <strong>Expenses: </strong>
                {formatMoney(item.total_amount, {
                  symbol: item.currency_infor.symbol,
                  precision: item.currency_infor.decimal_digits,
                  thousand: ',',
                  decimal: '.',
                })}
              </span>
              {item.a_number_of_paxs && (
                <span className="pax">
                  {' '}
                  | <strong>Pax: </strong>
                  {item.a_number_of_paxs}
                </span>
              )}
              {item.reference && item.reference.name ? (
                <span className="reference">
                  <strong className="source-text">Source: </strong>
                  <a
                    href={
                      process.env.PUBLIC_URL +
                      'view-itinerary/' +
                      item.reference._id
                    }
                    target="blank"
                    className="source"
                  >
                    {item.reference.name}
                  </a>
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </Td>
      {manage ? (
        <>
          <Td width="5%" className="view">
            {formatViews(item.views, 1)}
          </Td>
          <Td width="5%" className="customize">
            {item.customize}
          </Td>
          <Td className="last-updated">
            {moment(new Date(item.last_updated)).format('LLL')}
          </Td>
          <Td className="show">
            {item.status ? (
              <Text type="success">Public</Text>
            ) : (
              <Text type="danger">Private</Text>
            )}
          </Td>
        </>
      ) : null}
      <Td className="group-action">
        <div className="td-action">
          {manage ? (
            <>
              <Button
                className="new-quotation-btn"
                onClick={() => {
                  history.push({
                    pathname: '/create-quotation/' + item._id,
                    state: {
                      title: item.name,
                    },
                  });
                }}
              >
                New Quotation
              </Button>

              <MoveTo
                itemList={itemList}
                itemItinerary={item}
                getMyFolder={getMyFolder}
                listFolder={listFolder}
              />
              <Button
                className="btn-edit"
                onClick={() => {
                  window.open(
                    process.env.PUBLIC_URL +
                      '/itinerary/' +
                      item._id +
                      '/' +
                      item.itinerary_detail[0].children[0].children[0]._id
                  );
                }}
                type="primary"
              >
                Edit
              </Button>
              <Button
                className="btn-delete"
                type="danger"
                onClick={() => deleteItinerary(item._id)}
              >
                Delete
              </Button>
            </>
          ) : (
            <Button onClick={() => handleLoadStop(item._id)}>Load</Button>
          )}
        </div>
      </Td>
    </Tr>
  );
};

function Item(props) {
  const itemList = props.itemList;

  function handleExpand() {
    let listFolderOpenTemp = [...props.listFolderOpen];
    if (listFolderOpenTemp.includes(itemList._id))
      listFolderOpenTemp = listFolderOpenTemp.filter(
        (item) => item !== itemList._id
      );
    else listFolderOpenTemp.push(itemList._id);
    props.setListFolderOpen(listFolderOpenTemp);
  }

  return (
    <>
      <Tr className="body-row folder">
        <Td width={props.manage ? '40%' : '90%'}>
          <div className="folder-container">
            <div className="folder-content" onClick={handleExpand}>
              <div className="folder-item">
                <Tag color="#f50" className="tag-custom">
                  {itemList.list.length}
                </Tag>
                {props.listFolderOpen.includes(itemList._id) ? (
                  <FolderOpenFilled />
                ) : (
                  <FolderFilled />
                )}
              </div>
              <div className="name-folder">
                <span>{itemList._id ? itemList.name : 'Others'}</span>
              </div>
            </div>

            {itemList._id !== null && itemList._id !== 0 && (
              <EditFolder
                manage={props.manage}
                item={itemList}
                getMyFolder={props.getMyFolder}
              />
            )}
          </div>
        </Td>
        {props.manage ? (
          <>
            {' '}
            <Td width="5%" className="hide-mobile"></Td>
            <Td width="5%" className="hide-mobile"></Td>
            <Td width="15%" className="hide-mobile"></Td>
            <Td className="hide-mobile"></Td>
          </>
        ) : null}
        <Td width={props.manage ? '40%' : '10%'} className="hide-mobile"></Td>
      </Tr>
      {props.listFolderOpen.includes(itemList._id) &&
        itemList.list.map((item, key) => {
          return (
            <ItemItinerary
              listFolder={props.listFolder}
              key={key}
              item={item}
              itemList={itemList}
              history={props.history}
              getMyFolder={props.getMyFolder}
              updateListLoad={props.updateListLoad}
              closeFormLoad={props.closeFormLoad}
              manage={props.manage}
            />
          );
        })}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeFormLoad: () => {
      dispatch(closeFormLoad());
    },
    updateListLoad: (list) => {
      dispatch(updateListLoad(list));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
