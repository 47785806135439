import React from "react";
import {
    EditDayTitleChangeDate, FormQuote,
    FormViewEditPlace,
    ItineraryDetailOption, ListMyTyneries,
    SocketContext,
    ViewOption
} from "../../components";
import {
    getAllLeaf
} from "../../services/utils.service";
import {Badge} from "reactstrap";
import ShowMoreText from "react-show-more-text";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddStop from "./add_stop";
import {Button, Drawer, Modal, PageHeader, Row} from "antd";
import {loadItem} from "../../services/storage.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {TimeLineContent, TimelineView} from "../component/timelineView";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import {format} from "date-fns";
import {closeFormLoad, openFormAddNewStop, openFormLoad, updateListItineraryDetail} from "../../store/actions";
import {connect} from "react-redux";
import LodashHelper from "../../helper/lodashHelper";
import {isMobileOnly, TabletView, isTablet} from "react-device-detect";
import ApiService from "../../services/api.service";

const { confirm } = Modal;

const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
});

const ShowLoad = ({closeLoad , ...props}) => {
    return (
        <Dialog
            open={props.showLoad}
            fullWidth={true}
            maxWidth={"md"}
            aria-labelledby="form-load"
        >
            <MuiDialogTitle disableTypography className="popup-title">
                <Typography variant="h6">My Library</Typography>
                <IconButton
                    aria-label="close"
                    className="btn-close"
                    onClick={closeLoad.bind(this)}
                >
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <ListMyTyneries categories={props.categories} params={props.params} />
        </Dialog>
    );
};

class Step2 extends React.Component{
    constructor(props) {
        super(props);
        this.apiService = ApiService();
        const item = LodashHelper.loopFindId(props.params.idItineraryDetail, props.itinerary.itinerary_detail);
        this.state = {
            item: item[0],
            listDates: getAllLeaf(props.itinerary.itinerary_detail),
            visible: false,
            selectItem: null,
            expand: true,

            categories: []
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (this.props.params && this.props.params.idItineraryDetail !== prevProps.params.idItineraryDetail) {
            const {params: {idItinerary}} = this.props;
            const itineraries = loadItem("itineraries");
            const itinerary = itineraries.find(el => el._id === idItinerary);
            const item = LodashHelper.loopFindId(this.props.params.idItineraryDetail, itinerary.itinerary_detail);
            this.setState({item: item[0]});
        }
        if (!this.state.categories.length) {
            this.apiService.getAttractionCategories().then((res) => {
              if (res.status === "success") {
                this.setState({
                  categories: res.categories,
                });
              }
            });
          }
    }

    updateListSelected = async (typeAlert, closeAddStop = false) => {
        const {params: {idItinerary, idItineraryDetail}} = this.props;
        const itineraries = loadItem("itineraries");
        const itinerary = itineraries.find(el => el._id === idItinerary);
        const item = LodashHelper.loopFindId(idItineraryDetail, itinerary.itinerary_detail);
        const stateParams = {
            item: item[0]
        };
        if (closeAddStop)
            stateParams.visible = false;
        this.setState(stateParams, () => {
            if (typeAlert === 1) {
                Toast.fire({
                    type: "success",
                    title: "Remove place successfully",
                });
            }else if (typeAlert === 4) {
                Toast.fire({
                    type: "success",
                    title: "Edit place successfully",
                });
            }else if (typeAlert === 7){
                Toast.fire({
                    type: "success",
                    title: "Event moved",
                });
            }else {
                Toast.fire({
                    type: "success",
                    title: "Add new place successfully",
                });
            }
        });
    };

    handleRemovePlaceDetail = () => {
        confirm({
            title: "Are you sure delete this item?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {

            },
            onCancel: () => {},
        })
    };

    hideEditDayTitleChangeDate = async (newItieraryDetail) => {
        const {params: {idItinerary}} = this.props;
        const itineraries = loadItem("itineraries");
        const itinerary = itineraries.find(el => el._id === idItinerary);
        let stateList = {
            editDayTitleChangeDate: false,
            listDates: getAllLeaf(itinerary.itinerary_detail)
        };
        if (typeof newItieraryDetail === "object") {
            stateList.item = {...this.state.item, ...newItieraryDetail};
        }
        this.setState(stateList, () => {
            if (newItieraryDetail !== 2) {
                this.props.updateListItineraryDetail("editDayTitleChangeDate");
            }
        });
    };

    dialogEditDayTitle = () => {
        return (
            <Dialog
                fullWidth={true}
                open={this.state.editDayTitleChangeDate}
                className="edit-on-mobile"
            >
                <MuiDialogTitle disableTypography className="popup-title">
                    <Typography variant="h6">
                        {this.state.item.title
                            ? this.state.item.title
                            : "Provide a description for this day"}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className="btn-close"
                        onClick={() => this.setState({editDayTitleChangeDate: false})}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <EditDayTitleChangeDate
                    monthDayYear={this.state.item.month_day_year
                        ? new Date(this.state.item.month_day_year * 1000)
                        : null}
                    title={this.state.item.title}
                    day={format(new Date(this.state.item.month_day_year * 1000), "d")}
                    monthYear={this.state.item.month_day_year ? format(new Date(this.state.item.month_day_year * 1000), "MMM d, yyyy") : null}
                    length={this.props.itinerary.itinerary_detail.length}
                    params={this.props.params}
                    hideEditDayTitleChangeDate={
                        this.hideEditDayTitleChangeDate
                    }
                />
            </Dialog>
        );
    };

    handleExpandCollapse = (flag) => {
        this.setState({
            expand: flag,
        });
    };

    capitalize = (s) => {
        if (typeof s !== "string") return "";
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    showLoad = () => {
        this.props.openFormLoad();
    };

    closeLoad = () => {
        this.props.closeFormLoad();
    };

    render() {
        const {place_detail} = this.state.item || [];
        const currentDetail = LodashHelper.loopFind('alpha2Code', 'alpha2Code', this.state.item.alpha2Code, this.props.itinerary.itinerary_detail)[0];
        return (
            <div className={this.props.className} id="center-column">
                {this.dialogEditDayTitle()}
                <ShowLoad categories={this.state.categories} closeLoad={this.closeLoad} showLoad={this.props.showLoad} params={this.props.params} />
                <ViewOption
                    items={place_detail}
                    listDates={this.state.listDates}
                    handleExpandCollapse={this.handleExpandCollapse}
                    expand={this.state.expand}
                />
                <FormViewEditPlace
                    params={this.props.params}
                    newList={this.updateListSelected}
                    item={this.state.selectItem}
                    items={place_detail}
                />
                <FormQuote
                    params={this.props.params}
                    newList={this.updateListSelected}
                />
                <div className="trip-day-header-labels">
                    <div className="trip-day-header-labels-inner t-editable"
                        onClick={() => this.setState({editDayTitleChangeDate: true})}
                        data-ember-action="2483"
                    >
                        {/*{this.state.item.month_day_year ? <div className="trip-day-header-date">
                            <div className="trip-day-header-date-month">
                                {monthYearFormat(this.state.item.month_day_year * 1000)}
                            </div>
                        </div> : null}*/}
                        <h1 className="trip-day-header-title">
                            <Badge
                                style={{
                                    padding: `0.3rem`,
                                    fontSize: `80%`,
                                    marginRight: "0.5rem",
                                }}
                                color="primary"
                            >
                                Day {this.state.item.index}
                            </Badge>
                            <span>
                              {this.state.item.title
                                  ? this.state.item.title
                                  : "Provide a description for this day"}
                            </span>
                            <figure className="_has-date">
                                <i className="fas fa-pencil-alt"></i>
                            </figure>
                        </h1>
                    </div>
                    {currentDetail && <div className="address" style={{
                        fontSize: '0.8rem',
                        display: 'flex',
                        alignItems: 'center',
                        color: 'rgba(0, 0, 0, 0.45)'
                    }}>
                        <img src={currentDetail.flag} width={24} style={{marginRight: 10, padding: '5px 0'}} alt=""/> {this.state.item.city ? this.state.item.city+', ' : null} {this.state.item.country}
                    </div>}
                    {this.state.item.description && (
                        <div className="description-day">
                            <ShowMoreText
                                /* Default options */
                                lines={3}
                                more="Show more"
                                less="Show less"
                            >
                                <div className="overview-itinerary-detail show-more-overview">
                                    {!(
                                        !this.props.profile ||
                                        (this.props.profile && this.props.profile.accountType < 2)
                                    ) ? (
                                        <ItineraryDetailOption lodging={this.state.item.lodging} amountMeals={this.state.item.amountMeals} meal={this.state.item.meal} />
                                    ) : null}
                                    <div className="trix-content-temp">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: this.state.item.description,
                                            }}
                                        />
                                    </div>
                                </div>
                            </ShowMoreText>
                        </div>
                    )}
                </div>
                <Row type="flex" justify="center">
                    <Button style={{color: '#e9870a', border: '1px solid #e9870a'}} onClick={() => this.setState({visible: true})} icon="plus" type="default">Add a new stop</Button>
                </Row>
                <div className={"time-line-mobile-view "+(place_detail.length === 0 && isTablet ? 'tutorial' : '')}>
                    {place_detail.length === 0 ? (
                        <TabletView>
                            <span style={{ fontSize: "1.5rem" }}>
                                Where to build your detailed itinerary
                            </span>
                        </TabletView>
                    ) : (
                        <TimelineView
                            source={place_detail}
                            renderItem={(item, index) => <TimeLineContent expand={this.state.expand} key={index} item={item} index={index}/>}
                        />
                    )}
                </div>
                <Drawer
                    visible={this.state.visible}
                    closable={false}
                    width="100%"
                    className="draw-content"
                >
                    <PageHeader
                        title={'Day '+this.state.item.index+': '+(this.state.item.title || 'Provide a description for this day')}
                        onBack={() => this.setState({visible: false})}
                    >
                        <Row type="flex" justify="end">
                            {/*<Button style={{padding:0}} size={isMobileOnly ? 'default' : 'large'} icon="plus" type="link" onClick={()=> this.props.openFormAddNewStop()} >Manually add a stop</Button>*/}
                            <Fab
                                className="btn-add"
                                /*onClick={this.handleOpenFormAddNewStop.bind(
                                    this
                                )}*/
                                onClick={()=> this.props.openFormAddNewStop()}
                            >
                                <AddIcon />
                            </Fab>
                            <Button
                                style={{padding:0}}
                                size={isMobileOnly ? 'default' : 'large'}
                                type="link"
                                className="folder-menu"
                                onClick={this.showLoad.bind(this)}
                            >
                                <i className="far fa-folder-open"></i>
                                <span>&nbsp;Load...</span>
                            </Button>
                        </Row>
                        <AddStop categories={this.state.categories} item={this.state.item} newList={this.updateListSelected} params={this.props.params} itinerary={this.props.itinerary} />
                    </PageHeader>
                </Drawer>
            </div>
        );
    }
}

const ContentDetailWithSocket = (props) => {
    return (
        <SocketContext.Consumer>
            {(socket) => <Step2 {...props} socket={socket} />}
        </SocketContext.Consumer>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.profileReducer.info,
        showLoad: state.formLoadReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateListItineraryDetail: (typeUpdate) => {
            dispatch(updateListItineraryDetail(typeUpdate));
        },
        openFormAddNewStop: () => {
            dispatch(openFormAddNewStop());
        },
        openFormLoad: () => {
            dispatch(openFormLoad());
        },
        closeFormLoad: () => {
            dispatch(closeFormLoad());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentDetailWithSocket);
