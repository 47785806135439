import React, {useEffect, useState} from "react";
import {TyneriRelated} from "../index";
import ApiService from "../../services/api.service";
import styles from "./index.scss";

const RightContent = ({itinerary, ...props}) => {
    const apiService = ApiService();
    const [listRecommend, setListRecommend] = useState([]);
    const [list_optionals, setList_optionals] = useState([]);

    useEffect(() => {
        async function fetchData() {
            let recommend = await apiService.getAllItinerariesOfCountry(itinerary.alpha2Code, itinerary._id);
            setListRecommend(recommend.status === "success" ? recommend.list_itineraries : []);
            let options = await apiService.getAllOptionals();
            setList_optionals(options.status === 'success' ? options.list_optionals : []);
        }
        fetchData();
    }, [itinerary.alpha2Code, itinerary._id]);

    return (
        <div className={styles.baseLike}>
            {listRecommend.length > 0 && (
                <div className="grid-header bread">
                    <h2 className="main-title-section">You might also like</h2>
                </div>
            )}
            <TyneriRelated
                listRecommend={listRecommend}
                list_optionals={list_optionals}
            />
        </div>
    );
}

export default RightContent;