import { Model } from 'react-axiom';
import React from 'react';

class Tab extends Model {
  static defaultState() {
    return {
      id: '',
      label: '',
      colorLabel: '#000000',
      backGroundColor: '#ffffff',
      position: 0,
      children: React.createElement(<></>),
    };
  }
}

export default Tab;
