import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { getImageFromUrl } from "../../services/utils.service"

const Container = styled.div`
  position: relative;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  min-height: 472px;
`;

const Header = styled.div`
  &:hover {
    box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  height: 67%;
  border-radius: 8px 8px 0px 0px;
  background-image: url(${props => getImageFromUrl(props.bgPhoto)});
  background-color: ${props => props.bgColor};
  background-size: cover;
  background-position: center center;
`;

const Content = styled.div`
  padding: 20px;
  background-color: white;
  overflow: hidden;
  border-radius: 0px 0px 8px 8px;
  position: relative;
  text-align: center;
`;

const TagContainer = styled.div`
  //background-color: ${props => props.bgColor};
  background-color: transparent;
  font-size: 3rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 3px;
  border-radius: 3px;
  text-align: center;
  color: ${props => props.color};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
`;

const TagText = styled.span``;

const Title = styled.span`
  font-weight: 600;
  font-size: 24px;
  color: ${props => props.color};
  margin-bottom: 20px;
  display: block;
  width: 100%;
  text-transform: uppercase;
`;

const Intro = styled.p`
  height: 80%;
  line-height: 1.5;
  color: #585e6c;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const CTAContainer = styled.div`
  // display: -webkit-box;
  // display: -webkit-flex;
  // display: -ms-flexbox;
  // display: flex;
`;

const CTA = styled.div`
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 25px;
  z-index: 9;
  background: ${props => props.bgColor};
  color: ${props => props.color};
`;

const CTAText = styled.span`
  font-weight: 500;
`;

const Gradient = styled.div`
  position: relative;
  bottom: 115%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
`;

const CardTopDestination = ({
  bgPhoto,
  bgColor = "#DBE0E6",
  tag,
  tagBg = "#E33C36",
  tagColor = "white",
  title,
  titleColor = "#1f2126",
  intro,
  introColor = "#9ca1ae",
  cta,
  ctaBg = "linear-gradient(to left, #006EFE, #494AFF)",
  ctaColor = "white"
}) => {
  return (
  <Container>
    {/* {tag && (
      <TagContainer bgColor={tagBg} color={tagColor}>
        <TagText>{tag}</TagText>
      </TagContainer>
    )} */}
    <Header bgPhoto={bgPhoto} bgColor={bgColor} />
    <Content>
      {title && <Title color={titleColor}>{title}</Title>}
      {intro && <div><Intro color={introColor}>{intro}</Intro></div>}
      {cta && (
        <CTAContainer>
          <CTA bgColor={ctaBg} color={ctaColor}>
              <CTAText>{cta}</CTAText>
          </CTA>
        </CTAContainer>
      )}
    </Content>
  </Container>
)};

CardTopDestination.propTypes = {
  //bgPhoto: PropTypes.string,
  bgColor: PropTypes.string,
  tag: PropTypes.string,
  tagBg: PropTypes.string,
  tagColor: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  intro: PropTypes.string,
  introColor: PropTypes.string,
  cta: PropTypes.string,
  ctaBg: PropTypes.string,
  ctaColor: PropTypes.string
};

export default CardTopDestination;
