import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'; //

const { TabPane } = Tabs;

function TabCustomize(props) {
  const { tabList, tabPosition, classNameTab } = props;
  const [activeTab, setActiveTab] = useState(
    tabList.length > 0 ? tabList[0].getId() : ''
  );

  useEffect(() => {
    const tabActive = tabList.filter(
      (tab) => '#' + tab.getId() === window.location.hash
    );
    if (tabActive.length > 0) {
      setActiveTab(tabActive[0].getId());
    } else if (tabList.length > 0) {
      setActiveTab(tabList[0].getId());
    }
  }, [tabList]);

  if (!tabList) return null;

  const onChangeTab = (activeKey) => {
    window.location.hash = activeKey;
    const tabActive = tabList.filter((tab) => tab.getId() === activeKey);
    if (tabActive.length > 0) {
      setActiveTab(tabActive[0].getId());
    }
  };

  return (
    <Tabs
      className={classNameTab}
      tabPosition={tabPosition}
      onChange={(activeKey) => onChangeTab(activeKey)}
      defaultActiveKey={activeTab}
      activeKey={activeTab}
    >
      {tabList.map((tab) => (
        <TabPane
          tab={
            <div
              style={{
                color: tab.getColorLabel(),
                backgroundColor: tab.getBackGroundColor(),
              }}
              className="tab-label-customize"
            >
              {tab.getLabel()}
            </div>
          }
          key={tab.getId()}
        >
          {React.createElement(tab.getChildren(), {
            ...props,
            activeTab: activeTab,
          })}
        </TabPane>
      ))}
    </Tabs>
  );
}

TabCustomize.propTypes = {
  tabPosition: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  classNameTab: PropTypes.string,
};

TabCustomize.defaultProps = {
  tabPosition: 'top',
  type: 'line',
  size: 'default',
  classNameTab: '',
};

const mapStateToProps = (state) => {
  const { TabsReducer } = state;
  return {
    tabList: TabsReducer.tabs ? TabsReducer.tabs.getSortedList() : [],
  };
};

export default connect(mapStateToProps, null)(TabCustomize);
