import React, { Component } from "react";
import $ from "jquery";
import "./index.scss";
import Footer from "../../layout/footer";
import ScrollspyNav from "react-scrollspy-nav";
import ApiService from "../../services/api.service"
import { CardCustom, SliderCustom } from "../../components";
import DefaultBackground from "../../assets/media/images/generic-activities-img.jpg";
import { isTablet, isMobile } from "react-device-detect";

class Home extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      list: []
    };
  }

  headerFixed = () => {
    var nav_offset_top = $("header").height() + 50;
    if ($(".header_area").length) {
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll >= nav_offset_top) {
          $(".header_area").addClass("navbar_fixed");
        } else {
          $(".header_area").removeClass("navbar_fixed");
        }
      });
    }
  };

  carousel = () => {
    if ($(".active_course").length) {
      window.$(".active_course").owlCarousel({
        loop: true,
        margin: 20,
        items: 3,
        nav: true,
        autoplay: 2500,
        smartSpeed: 1500,
        dots: false,
        responsiveClass: true,
        thumbs: true,
        thumbsPrerendered: true,
        navText: ["<img src='img/prev.png'>", "<img src='img/next.png'>"],
        responsive: {
          0: {
            items: 1,
            margin: 0,
          },
          991: {
            items: 2,
            margin: 30,
          },
          1200: {
            items: 3,
            margin: 30,
          },
        },
      });
    }
  };

  activePricing = () => {
    window.$(".js-period-toggle").on("click", function (e) {
      var $this = $(this),
        pricingItem = $(".pricing-item");
      if ($(".period-toggle").hasClass("active")) {
        $this.removeClass("active");
        pricingItem.removeClass("yearly");
      } else {
        $this.addClass("active");
        pricingItem.addClass("yearly");
      }
      e.preventDefault();
    });
  };

  sliderBanner = () => {
    window.$(".slider").bxSlider({
      mode: "fade",
      auto: true,
      speed: 1500,
      slideMargin: 0,
      controls: false,
      pager: false,
      responsive: true,
      useCSS: true,
      preloadImages: "all",
      touchEnabled: false,
    });

    var thisPage = this;

    window.$("body").on("mousedown", ".bx-viewport a", function () {
      if ($(this).attr("href") && $(this).attr("href") !== "#") {
        let url = $(this).attr("href");
        thisPage.props.history.push(url);
      }
    });
  };

  renderCardItem(item, index) {
    return (
      <div key={index} className="custom-card-tyneri">
        <a href={process.env.PUBLIC_URL+"/view-itinerary/" + item._id}>
          <CardCustom
            title={item.name}
            estimated_budget={item.estimated_budget}
            a_number_of_paxs={item.a_number_of_paxs}
            currency_infor={item.currency_infor}
            tag={item.city}
            centerIconName="fas fa-play-circle"
            bottomIconName="fas fa-ellipsis-h"
            bgPhoto={item.cover ? item.cover : DefaultBackground}
            totalReviews={30}
            ratingAverage={4.5}
            views={item.views}
            options={item.options ? item.options : []}
            list_optionals={this.props.list_optionals}
          />
        </a>
      </div>
    );
  }
  getDataTopTrending(data) {
    if (data && !!data.length) {
      let dataHtml = [];
      data.forEach((item, index) => {
        dataHtml.push(this.renderCardItem(item, index));
      });
      return dataHtml;
    }
    return null;
  }

  renderSliderWithCustomRow() {
    const listData = this.state.list;
    if (listData && !!listData.length) {
      // let slidesPerRow = 2;
      let response = [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 426,
          settings: {
            slidesPerRow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ];
      if (isTablet || isMobile) {
        // slidesPerRow = 4;
        response.push({
          breakpoint: 1367,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        });
      }
      return (
        <div className="two-row-top-trending">
          <SliderCustom
            slidesToShow={3}
            pauseOnHover={true}
            settings={{
              responsive: response,
              autoplay: true,
              autoplaySpeed: 5000,
              infinite: true,
              slidesToScroll: 4,
            }}
            list={this.getDataTopTrending(listData)}
          />
        </div>
      );
    }
    return null;
  }

  componentDidMount = async () => {
    let res = await this.apiService.getTrending();
    if (res.status === "success") {
      this.setState({
        list: res.list
      })
    }
    this.headerFixed();
    this.carousel();
    this.activePricing();
    this.sliderBanner();
  };

  render() {
    return (
      <ScrollspyNav
        scrollTargetIds={[
          "Home",
          "Customers",
          "Features",
          "Pricing",
          "Contact",
        ]}
        activeNavClass="active"
        scrollDuration="1000"
        offset={-100}
      >
        {/*================ Start Header Menu Area =================*/}
        <header className="header_area">
          <div className="main_menu">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container">
                {/* Brand and toggle get grouped for better mobile display */}
                <a className="navbar-brand logo_h" href="index.html">
                  <img src="img/logo.png" alt="" width="120px" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar" /> <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
                {/* Collect the nav links, forms, and other content for toggling */}
                <div
                  className="collapse navbar-collapse offset"
                  id="navbarSupportedContent"
                >
                  <ul className="nav navbar-nav menu_nav ml-auto">
                    <li className="nav-item">
                      <a className="nav-link" href="#Home">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#Customers">
                        Customers
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#Features">
                        Features
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#Pricing">
                        Pricing
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#Contact">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
        {/*================ End Header Menu Area =================*/}
        {/*================ Start Home Banner Area =================*/}
        <section className="home_banner_area" id="Home">
          <div className="slider">
            <div
              style={{
                background: "url(img/banner/home-1.jpg) no-repeat center",
                backgroundSize: "cover",
                minHeight: "100vh",
                position: "relative",
              }}
            >
              <div className="banner_inner">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="banner_content text-center">
                      <p>
                        Visual-friendly multi-platform trip planner for travel
                        lovers
                      </p>
                      <h2 className="text-uppercase mt-4 mb-5">
                        FOR ZERO COST AND NO SIGNUP REQUIRED
                      </h2>
                      <div>
                        <a
                          href="#Customers"
                          className="primary-btn2 mb-3 mb-sm-0"
                        >
                          learn more
                        </a>
                        <a href="/get-started" className="primary-btn ml-3">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                background: "url(img/banner/home-2.jpg) no-repeat center",
                backgroundSize: "cover",
                minHeight: "100vh",
                position: "relative",
              }}
            >
              <div className="banner_inner">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="banner_content text-center">
                      <p>Organize and share your next family trip</p>
                      <h2 className="text-uppercase mt-4 mb-5">
                        SIMPLY BY CLICKING AND DRAG-DROPPING
                      </h2>
                      <div>
                        <a
                          href="#Customers"
                          className="primary-btn2 mb-3 mb-sm-0"
                        >
                          learn more
                        </a>
                        <a href="/get-started" className="primary-btn ml-3">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                background: "url(img/banner/home-3.jpg) no-repeat center",
                backgroundSize: "cover",
                minHeight: "100vh",
                position: "relative",
              }}
            >
              <div className="banner_inner">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="banner_content text-center">
                      <p>
                        Customize a tour to meet your client’s request from
                        anywhere
                      </p>
                      <h2 className="text-uppercase mt-4 mb-5">
                        INSTANTLY AND EFFORTLESSLY
                      </h2>
                      <div>
                        <a
                          href="#Customers"
                          className="primary-btn2 mb-3 mb-sm-0"
                        >
                          learn more
                        </a>
                        <a href="/get-started" className="primary-btn ml-3">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*================ End Home Banner Area =================*/}
        {/*================ Start Our customers Area =================*/}
        <section className="feature_area" id="Customers">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="main_title">
                  <h2 className="mb-3">Our customers are</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single_feature row">
                  <div className="icon col-md-5">
                    <img alt="" src="img/banner/icon-5.jpg" />
                  </div>
                  <div className="desc col-md-7">
                    <h4 className="mt-3 mb-2">TRAVEL BLOGGERS</h4>
                    <p>
                      Who want a place to easily-and-unlimitedly store their
                      memorable trips and share with the community
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single_feature row">
                  <div className="icon col-md-5">
                    <img alt="" src="img/banner/icon-2.jpg" />
                  </div>
                  <div className="desc col-md-7">
                    <h4 className="mt-3 mb-2">SEASONAL TRAVELERS</h4>
                    <p>
                      Who take advantage of such community and thoroughly plan
                      their family/friend trips beforehand
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single_feature row">
                  <div className="icon col-md-5">
                    <img alt="" src="img/banner/icon-3.jpg" />
                  </div>
                  <div className="desc col-md-7">
                    <h4 className="mt-3 mb-2">HODOPHILES</h4>
                    <p>
                      Who want to spend less time researching and more time
                      traveling
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single_feature row">
                  <div className="icon col-md-5">
                    <img alt="" src="img/banner/icon-4.jpg" />
                  </div>
                  <div className="desc col-md-7">
                    <h4 className="mt-3 mb-2">TOUR OPERATORS </h4>
                    <p>
                      Who seek a most efficient way to mass-customize an
                      itinerary and step up their sale game
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*================ End Our customers Area =================*/}
        {/*================ Start About Area =================*/}
        <section className="about_area" id="Features">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="main_title">
                  <h2 className="mb-3">Our features</h2>
                  <p>
                    Replenish man have thing gathering lights yielding shall you
                  </p>
                </div>
              </div>
            </div>
            <div className="row h_blog_item">
              <div className="col-lg-6">
                <div className="h_blog_img">
                  <img className="img-fluid" src="img/creator.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="h_blog_text">
                  <div className="h_blog_text_inner left right">
                    <p />
                    <h5 className="mt-3 mb-2">NO SIGN-UP REQUIRED</h5> We are
                    happy if you get aboard, though!
                    <p />
                    <p />
                    <h5 className="mt-3 mb-2">COMMUNITY-BASED CONTENTS</h5> Tons
                    of existing itineraries to choose from, all made by real
                    travelers around the world who are more than eager to share
                    their awesome experience from their past trips
                    <p />
                    <p />
                    <h5 className="mt-3 mb-2">EASY AND HASSLE-FREE</h5> Design a
                    tour as easily as choosing from a list, or modifying a
                    published itinerary, no travel knowledge necessary.
                    <p />
                    <p />
                    <h5 className="mt-3 mb-2">MULTI-PLATFORM </h5> Start
                    planning or browsing for itineraries with any device you
                    have at hands
                    <p />
                    <p />
                    <h5 className="mt-3 mb-2">
                      MAILABLE AND PRINT-READY ITINERARIES{" "}
                    </h5>{" "}
                    For tour operators to make offers to their customers at the
                    click of a button
                    <p />
                    <a className="primary-btn" href="#">
                      Learn More <i className="ti-arrow-right ml-1" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*================ End About Area =================*/}
        {/*================ Start Feature Area =================*/}
        <section
          className="feature_area section_gap_top title-bg pricing-section"
          id="Pricing"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="main_title">
                  <h2 className="mb-3 text-white">Our Pricing</h2>
                  <div className="switch-plan">
                    <div className="d-inline-flex align-items-center">
                      <div className="period">Monthly</div>
                      <div className="period-toggle js-period-toggle"></div>
                      <div className="period">
                        <span className="mr-2">Yearly</span>
                        <span className="save-percent">Save 20%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 col-lg-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay={0}
              >
                <div className="pricing-item ">
                  <h3>HODOPHILE</h3>
                  <div className="description">
                    <p>
                      Who want to spend less time researching and more time
                      traveling
                    </p>
                  </div>
                  <div className="period-change mb-4 d-block">
                    <div className="price-wrap">
                      <div className="price">
                        <div>
                          <div>FREE</div>
                          <div>FREE</div>
                        </div>
                      </div>
                    </div>
                    <div className="d-inline-flex align-items-center text-center period-wrap">
                      <div className="d-inline-block mr-1">Per</div>
                      <div className="d-block text-left period">
                        <div>
                          <div>Month</div>
                          <div>Year</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled mb-4">
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Trip planner</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Top list maker</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Add manual stop</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Unlimited itinerary count</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Library</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-checkX" />
                      <span>Private itinerary</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-checkX" />
                      <span>Budget manager</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-checkX" />
                      <span>Customer offers</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-checkX" />
                      <span>CRM</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-checkX" />
                      <span>Quotation Management</span>
                    </li>
                  </ul>
                  <div>
                    <a href="#" className="btn btn-primary">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="pricing-item active">
                  <h3>WANDERLUSTER</h3>
                  <div className="description">
                    <p>
                      Who want a place to easily-and-unlimitedly store their
                      memorable trips and share with the community
                    </p>
                  </div>
                  <div className="period-change mb-4 d-block">
                    <div className="price-wrap">
                      <div className="price">
                        <div>
                          <div>$1</div>
                          <div>$10</div>
                        </div>
                      </div>
                    </div>
                    <div className="d-inline-flex align-items-center text-center period-wrap">
                      <div className="d-inline-block mr-1">Per</div>
                      <div className="d-block text-left period">
                        <div>
                          <div>Month</div>
                          <div>Year</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled mb-4">
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Trip planner</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Top list maker</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Add manual stop</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Unlimited itinerary count</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Library</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Private itinerary</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-checkX" />
                      <span>Budget manager</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-checkX" />
                      <span>Customer offers</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-checkX" />
                      <span>CRM</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-checkX" />
                      <span>Quotation Management</span>
                    </li>
                  </ul>
                  <div>
                    <a href="#" className="btn btn-primary">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="pricing-item">
                  <h3>GLOBETROTTER</h3>
                  <div className="description">
                    <p>
                      who seek a most efficient way to mass-customize an
                      itinerary and step up their sale game
                    </p>
                  </div>
                  <div className="period-change mb-4 d-block">
                    <div className="price-wrap">
                      <div className="price">
                        <div>
                          <div>$10</div>
                          <div>$100</div>
                        </div>
                      </div>
                    </div>
                    <div className="d-inline-flex align-items-center text-center period-wrap">
                      <div className="d-inline-block mr-1">Per</div>
                      <div className="d-block text-left period">
                        <div>
                          <div>Month</div>
                          <div>Year</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled mb-4">
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Trip planner</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Top list maker</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Add manual stop</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Unlimited itinerary count</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Library</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Private itinerary</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Budget manager</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Customer offers</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>CRM</span>
                    </li>
                    <li className="d-flex">
                      <span className="icon-check" />
                      <span>Quotation Management</span>
                    </li>
                  </ul>
                  <div>
                    <a href="#" className="btn btn-primary">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </ScrollspyNav>
    );
  }
}

export default Home;
