export const actionTypes = {
    SAVE_IP: 'SAVE_IP',
    REDIRECT_TO_BILLING_AFTER_LOGIN: 'REDIRECT_TO_BILLING_AFTER_LOGIN',
    OPEN_FORM_REPORT_PLACE: 'OPEN_FORM_REPORT_PLACE',
    CLOSE_FORM_REPORT_PLACE: 'CLOSE_FORM_REPORT_PLACE',
    UPDATE_LIST_LOAD: 'UPDATE_LIST_LOAD',
    UPDATE_INFOR_CURRENCY: 'UPDATE_INFOR_CURRENCY',
    SAVE_AFTER_LOGIN: 'SAVE_AFTER_LOGIN',
    HANDLE_SLICK_GO_TO: 'HANDLE_SLICK_GO_TO',
    OPEN_FORM_QUOTE: 'OPEN_FORM_QUOTE',
    CLOSE_FORM_QUOTE: 'CLOSE_FORM_QUOTE',
    OPEN_FORM_LOAD: 'OPEN_FORM_LOAD',
    CLOSE_FORM_LOAD: 'CLOSE_FORM_LOAD',
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    LOGOUT: 'LOGOUT',
    SAVE_PROFILE: 'SAVE_PROFILE',
    UPDATE_CURRENCY: 'UPDATE_CURRENCY',
    UPDATE_TOTAL_DAY: 'UPDATE_TOTAL_DAY',
    UPDATE_TOTAL_AMOUNT: 'UPDATE_TOTAL_AMOUNT',
    SAVE_CURRENT_ITINERARY: 'SAVE_CURRENT_ITINERARY',
    RELOAD_PDF: 'RELOAD_PDF',
    UNRELOAD_PDF: 'UNRELOAD_PDF',
    OPEN_FORM_ADD_NEW_STOP: 'OPEN_FORM_ADD_NEW_STOP',
    CLOSE_FORM_ADD_NEW_STOP: 'CLOSE_FORM_ADD_NEW_STOP',
    OPEN_FORM_SHARE_BY_EMAIL: 'OPEN_FORM_SHARE_BY_EMAIL',
    CLOSE_FORM_SHARE_BY_EMAIL: 'CLOSE_FORM_SHARE_BY_EMAIL',
    UPDATE_LIST_PLACES_EXPLORE: 'UPDATE_LIST_PLACES_EXPLORE',
    UPDATE_LIST_ITINERARY_DETAIL: 'UPDATE_LIST_ITINERARY_DETAIL',
    STOP_UPDATE_LIST_ITINERARY_DETAIL: 'STOP_UPDATE_LIST_ITINERARY_DETAIL',
    OPEN_FORM_VIEW_EDIT_PLACE: 'OPEN_FORM_VIEW_EDIT_PLACE',
    CLOSE_FORM_VIEW_EDIT_PLACE: 'CLOSE_FORM_VIEW_EDIT_PLACE',
    OPEN_FORM_VIEW_EDIT_TRANSPORTATION: 'OPEN_FORM_VIEW_EDIT_TRANSPORTATION',
    CLOSE_FORM_VIEW_EDIT_TRANSPORTATION: 'CLOSE_FORM_VIEW_EDIT_TRANSPORTATION',
    SAVE_COMPANY: 'SAVE_COMPANY',
    SAVE_COMPANY_DOMAIN: 'SAVE_COMPANY_DOMAIN',
    SAVE_PROFILE_DOMAIN: 'SAVE_PROFILE_DOMAIN',
}