import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  notification,
  Spin,
} from "antd";
import ApiService from "../../services/userApi.service";
import ErrorPage from "../../pages/error";
import "./index.scss";

const { Title } = Typography;

const loadingContent = () => (
  <div className="loading-container">
    <Spin />
  </div>
);

function ResetPassword(props) {
  const form = props.form;
  const token = props.match.params.token;
  const { getFieldDecorator } = form;
  const [loadingPage, setLoadingPage] = useState(true);
  const [ loadingButton, setLoadingButton ] = useState(false);
  const [tokenExpire, setTokenExpire] = useState(false);
  const apiService = ApiService();

  useEffect(() => {
    apiService.checkTokenResetPassword(token).then((res) => {
      setTokenExpire(res.exist);
      setLoadingPage(false);
    });
  });

  function checkPassword(rule, value, callback) {
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  }
  function checkConfirm(rule, value, callback) {
    if (value) {
      form.validateFields(["repeatPassword"], { force: true });
    }
    callback();
  }

  function handleChangePassword(e) {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, values) => {
      setLoadingButton(true);
      const password = values["newPassword"];
      if (!err) {
        const response = await apiService.resetPassword(token, password);
        if (!response.tokenExpires) {
          notification.info({
            message: `Success`,
            description: response.message,
            placement: "topRight",
            duration: 3000,
          });
          props.history.push("/");
        }
      }
      setLoadingButton(false);
    });
  }

  if (loadingPage) return loadingContent;

  if (!tokenExpire) return <ErrorPage />;

  return (
    <Row className="reset-password">
      <Col xs={24} sm={24} md={24}>
        <Form
          size="large"
          className="reset-password-form"
          onSubmit={(e) => {
            handleChangePassword(e);
          }}
        >
          <div className="label">
            <Title level={3}>Set your new password</Title>
            <span>
              Please create a new password below. Your new password needs to
              have a minimum of 6 characters.
            </span>
          </div>
          <Form.Item label="New Password">
            {getFieldDecorator("newPassword", {
              rules: [
                {
                  required: true,
                  message: "Please input your new password!",
                  whitespace: true,
                },
                {
                  min: 6,
                  message: "Your password needs to have a minimum of 6 characters.",
                },
                {
                  validator: checkConfirm,
                },
              ],
            })(<Input type="password" />)}
          </Form.Item>
          <Form.Item label="Repeat Password">
            {getFieldDecorator("retypePassword", {
              rules: [
                {
                  required: true,
                  message: "Please confirm your password!",
                  whitespace: true,
                },
                {
                  validator: checkPassword,
                },
              ],
            })(<Input type="password" />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit"  loading={ loadingButton }>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default Form.create()(ResetPassword);
