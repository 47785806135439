import React, {useEffect, useState} from "react";
import Payment from "../../../../../services/payment.service";
import {Icon, Popover, Table, Tag, Typography} from "antd";
import MoneyHelper from "../../../../../helper/moneyHelper";
import {format} from "date-fns";
import _ from "lodash";
import {ElementsConsumer} from "@stripe/react-stripe-js";
import ExpandedRowRender from "./expanedRow";
import {Link} from "react-router-dom";
import {FaFileInvoice} from "react-icons/fa";

const {Title, Paragraph} = Typography;

const columns = [
    {
        title: 'Invoice number',
        dataIndex: 'number',
        key: 'number',
        render: (text, row) => {
            let color = '#87d068';
            let status = row.status;
            let popover;
            if (!row.paid) {
                switch (row.status) {
                    case 'void':
                        color = '#722ed1';
                        break;
                    case 'draft':
                        color = 'grey';
                        status = 'Upcoming'
                        break;
                    case 'open':
                        if (row.auto_advance) {
                            if (row.next_payment_attempt) {
                                color = 'red';
                                status = 'Retrying';
                                popover = "Attempted to charge the customer's payment method on file once and failed. Payment will be retried on "+format(row.next_payment_attempt * 1000, 'MMM dd, yyyy')
                            }else {
                                color = 'blue';
                            }
                        }else {
                            status = 'Failed';
                            color = 'red';
                        }
                        break;
                    default:
                        color = 'red';
                        break;
                }
            }
            let content = <Paragraph className="m-0">{text} <Tag color={color}>{status.toUpperCase()}</Tag></Paragraph>;
            if (popover)
                return <Popover content={popover}>{content}</Popover>;
            return content;
        }
    },
    {
        title: 'Subtotal',
        dataIndex: 'subtotal',
        key: 'subtotal',
        render: (text, row) => {
            return <Paragraph style={{margin: 0}}>{MoneyHelper(text / 100).formatCurrency(row.currency)}</Paragraph>;
        }
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        render: (text, row) => {
            return <Paragraph style={{margin: 0}}>{MoneyHelper(text / 100).formatCurrency(row.currency)}</Paragraph>;
        }
    },
    {
        title: 'Applied balance',
        dataIndex: 'starting_balance',
        key: 'starting_balance',
        render: (text,row) => MoneyHelper(text / 100).formatCurrency(row.currency)
    },
    {
        title: 'Amount paid',
        dataIndex: 'amount_paid',
        key: 'amount_paid',
        render: (text,row) => MoneyHelper(text / 100).formatCurrency(row.currency)
    },
    {
        title: 'Amount due',
        dataIndex: 'amount_due',
        key: 'amount_due',
        render: (text,row) => MoneyHelper(text / 100).formatCurrency(row.currency)
    },
    {
        title: 'Detail',
        dataIndex: 'lines.data',
        key: 'lines',
        align: 'center',
        render: list => {
            list = _.orderBy(list, ['amount'], ['desc']);
            const lineColumns = [
                {
                    title: 'Description',
                    dataIndex: 'description',
                    key: 'description'
                },
                {
                    title: 'Quantity',
                    dataIndex: 'quantity',
                    key: 'quantity'
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                    key: 'amount',
                    render: (text, row) => MoneyHelper(text / 100).formatCurrency(row.currency)
                }
            ];
            return <Popover trigger="click" content={<Table rowKey={(record, index) => record.id} size="small" pagination={false} columns={lineColumns} dataSource={list}/>}>
                <Link to="#"><Icon type="info-circle" /></Link>
            </Popover>;
        }
    },
    {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
        render: text => format(text * 1000, 'MMM d, yyyy hh:mm a')
    }
];

const TyneriInvoices = ({stripe, ...props}) => {
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);

    async function fetchData() {
        let invoices = await Payment().getInvoices();
        if (invoices.status) {
            let up = await Payment().getUpcomingInvoice();
            if (up.status)
                invoices.data.unshift(up);
            setInvoices(invoices.data);
        }
        setLoading(false);
    }
    useEffect(() => {
        fetchData();
    }, [props.package]);

    useEffect(() => {
        if (props.reload) {
            setTimeout(() => {
                fetchData();
            }, 3000)
            props.afterLoad();
        }
    }, [props.reload]);

    return (
        <Typography style={{marginTop: 20}}>
            <Title level={3}><FaFileInvoice className="d-inline-flex text-secondary" /> Billing history</Title>
            <Table pagination={false} loading={loading} size="middle" scroll={{x: true}} expandedRowRender={
                (record, index, indent, expanded) => <ExpandedRowRender expanded={expanded} record={record} index={index} indent={indent}/>
            } rowKey={(record, index) => record.number+'_'+index} columns={columns} dataSource={invoices}/>
        </Typography>
    );
}

export default (props) => (
    <ElementsConsumer>
        {({elements, stripe}) => (
            <TyneriInvoices {...props} elements={elements} stripe={stripe}/>
        )}
    </ElementsConsumer>
);
