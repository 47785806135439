import Swal from "sweetalert2/dist/sweetalert2.js";

class Alert {
  success(text) {
    return Swal.fire({
      type: "success",
      text: text,
      showConfirmButton: true,
    });
  }

  warning(text) {
    return Swal.fire({
      type: "warning",
      text: text,
      showConfirmButton: true,
    });
  }

  error(text) {
    return Swal.fire({
      type: 'error',
      text: text,
      showConfirmButton: true,
    });
  }
}

export default Alert;
