import { config } from '../config';
import axios from '../axios-config';

let cancelToken;
export default () => {
  let { apiKeyExchangeRate } = config;
  let services = {
    //Get Ip from server
    getIp: () => {
      return axios.get(`ip`).then((res) => {
        return res.data;
      });
    },

    //Top list
    viewTopList: (_id, user_id, cookie) => {
      return axios
        .post('top-list/view', {
          _id,
          user_id,
          cookie,
        })
        .then((res) => {
          return res.data;
        });
    },
    voteUpTopListDetail: (_id, user_id, cookie) => {
      return axios
        .post('top-list/vote-up', {
          _id,
          user_id,
          cookie,
        })
        .then((res) => {
          return res.data;
        });
    },
    voteDownTopListDetail: (_id, user_id, cookie) => {
      return axios
        .post('top-list/vote-down', {
          _id,
          user_id,
          cookie,
        })
        .then((res) => {
          return res.data;
        });
    },
    getTopListsRelative: (category_id, _id) => {
      return axios
        .get(`top-list/relative?category_id=${category_id}&_id=${_id}`)
        .then((res) => {
          return res.data;
        });
    },
    getTopListCategories: () => {
      return axios.get(`top-list/categories`).then((res) => {
        return res.data;
      });
    },
    getTopListTrending: () => {
      return axios.get(`top-list/trending`).then((res) => {
        return res.data;
      });
    },
    deleteTopList: (_id) => {
      return axios.post('top-list/delete', { _id }).then((res) => {
        return res.data;
      });
    },
    getMyTopList: () => {
      return axios.get(`top-list/me`).then((res) => {
        return res.data;
      });
    },
    getAllTopList: () => {
      return axios.get(`top-list`).then((res) => {
        return res.data;
      });
    },
    getTopList: (_id, user_id = null) => {
      return axios
        .get(`top-list/detail?id=${_id}&user_id=${user_id}`)
        .then((res) => {
          return res.data;
        });
    },
    updateTopList: (data) => {
      return axios.post('top-list/update', data).then((res) => {
        return res.data;
      });
    },
    createTopList: (data) => {
      return axios.post('top-list/create', data).then((res) => {
        return res.data;
      });
    },

    //Library
    changeNameLibrary: (data) => {
      return axios.post('libraries/change-name', data).then((res) => {
        return res.data;
      });
    },
    deleteLibrary: (library) => {
      return axios.post('libraries/delete', library).then((res) => {
        return res.data;
      });
    },
    deletePlaceInLibrary: (idLibraryPlace) => {
      return axios
        .post('libraries/delete-place-in-library', {
          idLibraryPlace,
        })
        .then((res) => {
          return res.data;
        });
    },
    searchPlaceInLibrary: (libraryId, text) => {
      return axios
        .get(`libraries/search?libraryId=${libraryId}&text=${text}`)
        .then((res) => {
          return res.data;
        });
    },
    getLibraryForPlace: (_id) => {
      return axios.get('libraries/library-for-place?_id=' + _id).then((res) => {
        return res.data;
      });
    },
    getListLibraries: () => {
      return axios.get('libraries/list').then((res) => {
        return res.data;
      });
    },
    getMyLibraries: () => {
      return axios.get('libraries/my').then((res) => {
        return res.data;
      });
    },
    createLibrary: (data) => {
      return axios.post('libraries/create', data).then((res) => {
        return res.data;
      });
    },
    chooseLibrary: (data) => {
      return axios.post('libraries/choose', data).then((res) => {
        return res.data;
      });
    },
    nameLibrary: (_id) => {
      return axios.post('?libraries/name', _id).then((res) => {
        return res.data;
      });
    },

    // Contributors
    getTopContributors: () => {
      return axios.get('company/getTopContributors').then((res) => {
        return res.data;
      });
    },
    // Filter
    getFilter: () => {
      return axios.get('filter-countries').then((res) => {
        return res.data;
      });
    },

    //Currency
    getAllCurrency: () => {
      return axios.get('currency/all').then((res) => {
        return res.data;
      });
    },
    getDetailCurrency: (code) => {
      return axios.post('currency/detail', { code }).then((res) => {
        return res.data;
      });
    },
    //Folder
    getMyFolders: () => {
      return axios.get('folders/my').then((res) => {
        return res.data;
      });
    },
    createFolder: (data) => {
      return axios.post('folders/create', data).then((res) => {
        return res.data;
      });
    },
    chooseFolder: (data) => {
      return axios.post('folders/choose', data).then((res) => {
        return res.data;
      });
    },
    changeNameFolder: (data) => {
      return axios.post('folders/change-name', data).then((res) => {
        return res.data;
      });
    },
    nameFolder: (_id) => {
      return axios.post('folders/name', _id).then((res) => {
        return res.data;
      });
    },
    deleteFolder: (_id) => {
      return axios.post('folders/delete', { _id }).then((res) => {
        return res.data;
      });
    },

    //Attraction
    getAttractionCategories: () => {
      let url = 'attractions/categories';
      return axios.get(url).then((res) => {
        return res.data;
      });
    },
    getAttractionsByCategory: (
      params = {},
      urlApi = 'attractions/by-category'
    ) => {
      let url = urlApi;
      if (typeof cancelToken !== typeof undefined)
        cancelToken.cancel('Operation canceled due to new request.');
      cancelToken = axios.CancelToken.source();
      return axios
        .get(url, {
          params,
          cancelToken: cancelToken.token,
        })
        .then((res) => {
          return res.data;
        });
    },
    getAllAttractions: () => {
      return axios.get('attractions/all').then((res) => {
        return res.data;
      });
    },

    //Option
    getAllOptionals: () => {
      return axios.get('options/all').then((res) => {
        return res.data;
      });
    },

    //Upload
    uploadImage: (file, config) => {
      return axios.post('upload', file, config).then((res) => {
        return res.data;
      });
    },

    uploadGallery: (file, config) => {
      return axios.post('upload/gallery', file, config).then((res) => {
        return res.data;
      });
    },

    uploadCover: (file, config) => {
      return axios.post('upload/cover', file, config).then((res) => {
        return res.data;
      });
    },

    uploadAvatar: (file, config) => {
      return axios.post('upload/avatar', file, config).then((res) => {
        return res.data;
      });
    },

    uploadBanner: (file, config) => {
      return axios.post('upload/banner', file, config).then((res) => {
        return res.data;
      });
    },

    uploadImagesDescriptionItineraryDetail: (file, config) => {
      return axios
        .post('upload/itineraries-detail', file, config)
        .then((res) => {
          return res.data;
        });
    },

    //Country
    getAllCountry: () => {
      return axios.get(`countries`).then((res) => {
        return res.data;
      });
    },

    //Detect IP
    getInfoFromIp: () => {
      let url = `http://ip-api.com/json/`;
      return axios.get(url).then((res) => {
        return res;
      });
    },

    //Exchange rate
    getExchangeRate: () => {
      let url = `https://openexchangerates.org/api/latest.json?app_id=${apiKeyExchangeRate}`;
      return axios.get(url).then((res) => {
        if (res.status === 200) {
          return res.data;
        }
        return null;
      });
    },

    //User
    getName: (userId) => {
      return axios.get(`users/name?userId=${userId}`).then((res) => {
        return res.data;
      });
    },
    createPassword: (email, password) => {
      return axios
        .post('users/create-password', {
          email,
          password,
        })
        .then((res) => {
          return res.data;
        });
    },
    changePassword: (email, currentPassword, newPassword) => {
      return axios
        .post('users/change-password', {
          email,
          currentPassword,
          newPassword,
        })
        .then((res) => {
          return res.data;
        });
    },
    getCurrentProfile: () => {
      return axios.get(`users/me`).then((res) => {
        return res.data;
      });
    },
    changeInfor: (infor) => {
      return axios.post('users/change-infor', infor).then((res) => {
        return res.data;
      });
    },
    checkExistPassword: (_id) => {
      return axios.post('users/check', { _id }).then((res) => {
        return res.data;
      });
    },
    login: (email, password) => {
      return axios.post('users/login', { email, password }).then((res) => {
        return res.data;
      });
    },
    signUp: (firstName, lastName, email, password) => {
      return axios
        .post('users/sign-up', {
          firstName,
          lastName,
          email,
          password,
        })
        .then((res) => {
          return res.data;
        });
    },
    logout: () => {
      return axios.post('users/me/logout');
    },

    logoutAll: () => {
      return axios.post('users/me/logout-all');
    },
    loginWithGoogle: (options) => {
      return fetch(
        `${process.env.REACT_APP_DOMAIN_API}users/auth/google`,
        options
      ).then((res) => {
        return res;
      });
    },
    loginWithFacebook: (options) => {
      return fetch(
        `${process.env.REACT_APP_DOMAIN_API}users/auth/facebook`,
        options
      ).then((res) => {
        return res;
      });
    },

    //Itinerary
    convertItineraryToPdf: (id) => {
      let url = `itineraries/convert-to-pdf?id=${id}`;
      return axios.get(url).then((res) => {
        return res.data;
      });
    },
    searchItineraries: (name, page = 1, limit = 10) => {
      let url = `itineraries/search?name=${name}&page=${page}&limit=${limit}`;
      return axios.get(url).then((res) => {
        return res.data;
      });
    },
    getAllTopDestinations: (page = 1, limit = 10) => {
      let url = `itineraries/all-top-destinations?page=${page}&limit=${limit}`;
      return axios.get(url).then((res) => {
        return res.data;
      });
    },
    getTopDestinations: (alpha2Code = '') => {
      let url = `itineraries/top-destinations`;
      return axios
        .get(url, {
          params: {
            alpha2Code: alpha2Code,
          },
        })
        .then((res) => {
          return res.data;
        });
    },
    getLinkShare: (idItinerary) => {
      return axios
        .post('itineraries/link-share', {
          idItinerary,
        })
        .then((res) => {
          return res.data;
        });
    },
    getMyTyneries: (page = 1, limit = 10) => {
      let url = `itineraries/my-tyneries?page=${page}&limit=${limit}`;
      return axios.get(url).then((res) => {
        return res.data;
      });
    },
    getMyTyneriesByFolder: (userId) => {
      return axios
        .post('itineraries/my-tyneries-by-folder', {
          userId,
        })
        .then((res) => {
          return res.data;
        });
    },
    getMyPlans: (userId) => {
      return axios.post('itineraries/my-plans', { userId }).then((res) => {
        return res.data;
      });
    },
    getMyReferences: (userId) => {
      return axios.post('itineraries/my-references', { userId }).then((res) => {
        return res.data;
      });
    },
    deleteItinerary: (idItinerary) => {
      return axios.post('itineraries/delete', { idItinerary }).then((res) => {
        return res.data;
      });
    },
    getAllDemo: () => {
      let url = `itineraries/all-demo`;
      return axios.get(url).then((res) => {
        return res.data;
      });
    },
    getAll: (page = 1, limit = 20) => {
      let url = `itineraries/all?page=${page}&limit=${limit}`;
      return axios.get(url).then((res) => {
        return res.data;
      });
    },
    getTrending: () => {
      let url = `itineraries/trending`;
      return axios.get(url).then((res) => {
        return res.data;
      });
    },
    getLatest: () => {
      return axios.post('itineraries/latest').then((res) => {
        return res.data;
      });
    },
    getItinerariesOfCountry: (alpha2Code, params) => {
      let url = `itineraries/itineraries-of-country`;
      return axios
        .get(url, {
          params: {
            alpha2Code: alpha2Code,
            ...params,
          },
        })
        .then((res) => {
          return res.data;
        });
    },
    getAllItinerariesOfCountry: (alpha2Code, _id) => {
      return axios
        .post('itineraries/all-itineraries-of-country', {
          alpha2Code,
          _id,
        })
        .then((res) => {
          return res.data;
        });
    },
    getAllItinerariesOfEachCountry: () => {
      let url = `itineraries/all-itineraries-each-country`;
      return axios.get(url).then((res) => {
        return res.data;
      });
    },
    initItinerary: (itinerary) => {
      return axios.post('itineraries', itinerary).then((res) => {
        return res.data;
      });
    },
    saveItinerary: (itinerary, user) => {
      return axios
        .post('itineraries/save-itinerary', {
          itinerary,
          user,
        })
        .then((res) => {
          return res.data;
        });
    },
    saveAsItinerary: (itinerary, user) => {
      return axios
        .post('itineraries/save-as-itinerary', {
          itinerary,
          user,
        })
        .then((res) => {
          return res.data;
        });
    },
    updateCurrencyItinerary: (idItinerary, currency) => {
      return axios
        .post('itineraries/update-currency', {
          idItinerary,
          currency,
        })
        .then((res) => {
          return res.data;
        });
    },
    updateNameItinerary: (idItinerary, name) => {
      return axios
        .post('itineraries/update-name', {
          idItinerary,
          name,
        })
        .then((res) => {
          return res.data;
        });
    },
    totalAmountItinerary: (idItinerary) => {
      return axios
        .post('itineraries/total-amount', {
          idItinerary,
        })
        .then((res) => {
          return res.data;
        });
    },
    loadItinerary: (idItinerary, userId = null) => {
      let url = `itineraries/load-itinerary`;
      return axios
        .get(url, {
          params: {
            idItinerary: idItinerary,
            userId: userId,
          },
        })
        .then((res) => {
          return res.data;
        });
    },
    getItinerary: (idItinerary, userId = null) => {
      let url = `itineraries`;
      return axios
        .get(url, {
          params: {
            idItinerary: idItinerary,
            userId: userId,
          },
        })
        .then((res) => {
          return res.data;
        });
    },
    viewItinerary: (idItinerary, user_id, cookie) => {
      return axios
        .post('itineraries/view', {
          idItinerary,
          user_id,
          cookie,
        })
        .then((res) => {
          return res.data;
        });
    },

    //Itinerary Detail
    getOneItineraryDetail: (idItineraryDetail) => {
      let url = `itineraries-detail/get-one?idItineraryDetail=${idItineraryDetail}`;
      return axios.get(url).then((res) => {
        return res.data;
      });
    },

    getItineraryDetail: (idItinerary) => {
      let url = `itineraries-detail?idItinerary=${idItinerary}`;
      return axios.get(url).then((res) => {
        if (res.data.status === 'success') {
          return res.data;
        }
        return null;
      });
    },
    addItineraryDetail: (idItinerary, itineraryDetail) => {
      return axios
        .post('itineraries-detail/add', {
          idItinerary,
          itineraryDetail,
        })
        .then((res) => {
          return res.data;
        });
    },
    updateItineraryDetail: (
      idItinerary,
      idItineraryDetail,
      newItineraryDetail
    ) => {
      return axios
        .post('itineraries-detail/update', {
          idItinerary,
          idItineraryDetail,
          newItineraryDetail,
        })
        .then((res) => {
          return res.data;
        });
    },
    deleteItineraryDetail: (idItinerary, idItineraryDetail) => {
      return axios
        .post('itineraries-detail/delete', {
          idItinerary,
          idItineraryDetail,
        })
        .then((res) => {
          return res.data;
        });
    },

    //Place Detail
    getImg: (idPlaceDetail) => {
      return axios.post('places-detail/img', { idPlaceDetail }).then((res) => {
        return res.data;
      });
    },
    getListPlaceDetail: (idItineraryDetail) => {
      let url = `places-detail?idItineraryDetail=${idItineraryDetail}`;
      return axios.get(url).then((res) => {
        if (res.data.status === 'success') {
          return res.data;
        }
        return null;
      });
    },
    getPlaceDetail: (idPlaceDetail) => {
      return axios.post('attractions/detail', { idPlaceDetail }).then((res) => {
        return res.data;
      });
    },
    updateAllCurrencyPlaceDetail: (idItinerary, currency) => {
      return axios
        .post('places-detail/update-all-currency', {
          idItinerary,
          currency,
        })
        .then((res) => {
          return res.data;
        });
    },
    addNewPlaceDetail: (
      idItineraryDetail,
      placeDetail,
      position,
      transportationName,
      order
    ) => {
      return axios
        .post('places-detail', {
          idItineraryDetail,
          placeDetail,
          position,
          transportationName,
          order,
        })
        .then((res) => {
          return res.data;
        });
    },
    addNewPlaceDetailAnyWhere: (
      idItineraryDetail,
      placeDetail,
      order,
      transportationName,
      arrOrderUpdate
    ) => {
      return axios
        .post('places-detail/add-any-where', {
          idItineraryDetail,
          placeDetail,
          order,
          transportationName,
          arrOrderUpdate,
        })
        .then((res) => {
          return res.data;
        });
    },
    updatePlaceDetailWhenAddStartTime: (
      idItineraryDetail,
      placeDetail,
      order,
      transportationName,
      arrOrderUpdate
    ) => {
      return axios
        .post('places-detail/update-when-add-start-time', {
          idItineraryDetail,
          placeDetail,
          order,
          transportationName,
          arrOrderUpdate,
        })
        .then((res) => {
          return res.data;
        });
    },
    addNewPlaceDetailFromExplore: (
      idItineraryDetail,
      placeDetail,
      currency,
      transportationName,
      order
    ) => {
      return axios
        .post('places-detail/add-from-list-explore', {
          idItineraryDetail,
          placeDetail,
          currency,
          transportationName,
          order,
        })
        .then((res) => {
          return res.data;
        });
    },
    updatePlaceDetailWhenReorder: (
      sourceId,
      newSourceOrder,
      destinationId,
      newDestinationOrder,
      reset
    ) => {
      return axios
        .post('places-detail/update-when-reorder', {
          sourceId,
          newSourceOrder,
          destinationId,
          newDestinationOrder,
          reset,
        })
        .then((res) => {
          return res.data;
        });
    },
    updatePlaceDetail: (idItineraryDetail, idPlaceDetail, newPlaceDetail) => {
      return axios
        .post('places-detail/update', {
          idItineraryDetail,
          idPlaceDetail,
          newPlaceDetail,
        })
        .then((res) => {
          return res.data;
        });
    },
    deletePlaceDetail: (idItineraryDetail, idPlaceDetail) => {
      return axios
        .post('places-detail/delete', {
          idItineraryDetail,
          idPlaceDetail,
        })
        .then((res) => {
          return res.data;
        });
    },
    addTransportation: (idItineraryDetail, idPlaceDetail, transportation) => {
      return axios
        .post('places-detail/add-transportation', {
          idItineraryDetail,
          idPlaceDetail,
          transportation,
        })
        .then((res) => {
          return res.data;
        });
    },

    //Places Shared
    getAllPlacesShared: () => {
      let url = `attractions`;
      return axios.get(url).then((res) => {
        if (res.data.status === 'success') {
          return res.data;
        }
        return null;
      });
    },
    addPlacesShared: (placesShared, userId) => {
      return axios
        .post('places-shared', {
          placesShared,
          userId,
        })
        .then((res) => {
          return res.data;
        });
    },

    //My places
    getListMyPlace: (userId) => {
      let url = `my-places?userId=${userId}`;
      return axios.get(url).then((res) => {
        if (res.data.status === 'success') {
          return res.data;
        }
        return null;
      });
    },
    removeMyPlace: (idMyPlace) => {
      return axios.post('my-places/remove', { idMyPlace }).then((res) => {
        return res.data;
      });
    },

    //Email
    shareListByEmail: (name, receiver, messages, idTopList) => {
      return axios
        .post('email/list', {
          name,
          receiver,
          messages,
          idTopList,
        })
        .then((res) => {
          return res.data;
        });
    },
    shareItineraryByEmail: (name, receiver, messages, idItinerary) => {
      return axios
        .post('email/itinerary', {
          name,
          receiver,
          messages,
          idItinerary,
        })
        .then((res) => {
          return res.data;
        });
    },
    sendEmailSignUp: (fullName, email) => {
      return axios.post('email/sign-up', { fullName, email }).then((res) => {
        return res.data;
      });
    },
  };
  return services;
};
