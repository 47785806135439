
const InquiriesModel = (props = {}) => {
  class InquiriesClass {
    constructor({
      key = "",
      title = "",
      date = "",
      information = "",
      firstName = "",
      phone = "",
      email = "",
      created_at = "",
      itinerary = {},
      status = 0,
      lastName= ""
    }) {
      this.key = key;
      this.title = title;
      this.date = date;
      this.information = information;
      this.firstName = firstName;
      this.phone = phone;
      this.email = email;
      this.created_at = created_at;
      this.itinerary = itinerary;
      this.status = status;
      this.lastName = lastName;
    }
  }
  return new InquiriesClass(props);
};

export default InquiriesModel;
