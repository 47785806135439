import React, { Component } from "react";
import ApiService from "../../services/api.service";
import { Container } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "./index.scss";
import ButtonMaterial from "@material-ui/core/Button";
import { connect } from "react-redux";
import { format } from "date-fns";
import moment from "moment";
import { Empty, Icon, Button, Modal } from "antd";
import { loadItem, saveItem } from "../../services/storage.service";
import { getImageFromUrl, isMyResource } from "../../services/utils.service";
import defaultImg from "../../assets/media/images/generic-activities-img.jpg";
import { configBaseUrl } from "../../config";

const { confirm } = Modal;

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

class MyTyneriTable extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: true,

      listMyItineraries: [],
      listMyFavorite: [],
      listDraft: [],

      selectedTab: 1,
    };
  }

  componentDidMount = () => {
    if (loadItem("itineraries")) {
      let tempListDraft = [];
      let itineraries = loadItem("itineraries");
      itineraries.forEach((item) => {
        if (item.hasOwnProperty("saved") && !item.saved) {
          tempListDraft.push(item);
        }
      });
      this.setState({
        listDraft: tempListDraft,
      });
    }

    if (this.props.profile) {
      this.apiService.getMyTyneries().then((res) => {
        if (res.status === "success") {
          this.setState({
            listMyItineraries: res.my_tyneries,
          });
        }
      });

      this.apiService.getMyReferences(this.props.profile._id).then((res) => {
        if (res.status === "success") {
          this.setState({
            listMyFavorite: res.my_references,
            isLoading: false,
          });
        }
      });
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.profile) {
      this.apiService.getMyTyneries().then((res) => {
        if (res.status === "success") {
          this.setState({
            listMyItineraries: res.my_tyneries,
          });
        }
      });

      this.apiService.getMyReferences(nextProps.profile._id).then((res) => {
        if (res.status === "success") {
          this.setState({
            listMyFavorite: res.my_references,
            isLoading: false,
          });
        }
      });
    }
  };

  handleChangeTab = (index) => {
    this.setState({
      selectedTab: index,
    });
  };

  handleEdit = (id, idItineraryDetail) => {
    window.open(`/itinerary/${id}/${idItineraryDetail}`, "_blank");
  };

  handleDeleteMyItineraries = (item) => {
    confirm({
      title: "Are you sure delete this?",
      icon: <Icon type="question-circle" />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        this.apiService.deleteItinerary(item._id).then((res) => {
          if (res.status === "success") {
            let temp = [...this.state.listMyItineraries];
            let index = temp.findIndex((itinerary) => {
              return itinerary._id === item._id;
            });
            temp.splice(index, 1);
            let itineraries = loadItem("itineraries");
            itineraries.forEach((itemA, index) => {
              if (itemA._id === item._id) {
                itineraries.splice(index, 1);
              }
            });
            saveItem("itineraries", itineraries);
            this.setState({
              listMyItineraries: temp,
            });
          }
        });
      },
    });
  };

  handleDeleteMyFavorite = (item) => {
    confirm({
      title: "Are you sure delete this?",
      icon: <Icon type="question-circle" />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        this.apiService.deleteItinerary(item._id).then((res) => {
          if (res.status === "success") {
            let temp = [...this.state.listMyFavorite];
            let index = temp.findIndex((itinerary) => {
              return itinerary._id === item._id;
            });
            temp.splice(index, 1);
            let itineraries = loadItem("itineraries");
            itineraries.forEach((itemA, index) => {
              if (itemA._id === item._id) {
                itineraries.splice(index, 1);
              }
            });
            saveItem("itineraries", itineraries);
            this.setState({
              listMyFavorite: temp,
            });
          }
        });
      },
    });
  };

  handleDeleteDraft = (item) => {
    confirm({
      title: "Are you sure delete this draft?",
      icon: <Icon type="question-circle" />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        let temp = [...this.state.listDraft];
        let index = temp.findIndex((itinerary) => {
          return itinerary._id === item._id;
        });
        temp.splice(index, 1);
        let itineraries = loadItem("itineraries");
        itineraries.forEach((itemA, index) => {
          if (itemA._id === item._id) {
            itineraries.splice(index, 1);
          }
        });
        saveItem("itineraries", itineraries);
        this.setState({
          listDraft: temp,
        });
      },
    });
  };

  render() {
    return this.state.isLoading ? (
      <div className="loader"></div>
    ) : (
      <React.Fragment>
        <Container fluid={true}>
          <div className="contain-form">
            <div className="contain-form-group-btn">
              <ButtonMaterial
                size="large"
                className={
                  this.state.selectedTab === 1
                    ? "my-itineraries active"
                    : "my-itineraries"
                }
                variant="contained"
                color="primary"
                onClick={this.handleChangeTab.bind(this, 1)}
              >
                My Itineraries
              </ButtonMaterial>
              <ButtonMaterial
                size="large"
                className={
                  this.state.selectedTab === 2
                    ? "my-favorite active"
                    : "my-favorite"
                }
                variant="contained"
                color="primary"
                onClick={this.handleChangeTab.bind(this, 2)}
              >
                My favorite
              </ButtonMaterial>
              <ButtonMaterial
                size="large"
                className={
                  this.state.selectedTab === 3 ? "draft active" : "draft"
                }
                variant="contained"
                color="primary"
                onClick={this.handleChangeTab.bind(this, 3)}
              >
                Draft
              </ButtonMaterial>
            </div>

            {this.state.selectedTab === 1 ? (
              <div className="animated fadeIn">
                <Table>
                  <Thead className="header itineraries">
                    <Tr>
                      <Th>Start date</Th>
                      <Th width="10%">Cover</Th>
                      <Th width="40%">Title</Th>
                      <Th>Last updated</Th>
                      <Th>&nbsp;</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {this.state.listMyItineraries.length > 0 ? (
                      this.state.listMyItineraries.map((item, key) => (
                        <Tr className="body-row" key={key}>
                          <Td>
                            {format(new Date(item.dates * 1000), "MMM d yyyy")}
                          </Td>
                          <Td>
                            <div
                              className="img-fluid"
                              style={{
                                backgroundImage: `url(${
                                  item.cover
                                    ? getImageFromUrl(
                                        isMyResource(item.cover)
                                          ? `${
                                              configBaseUrl.baseURL + item.cover
                                            }`
                                          : `${item.cover}`,
                                        "large"
                                      )
                                    : defaultImg
                                })`,
                              }}
                            ></div>
                          </Td>
                          <Td>{item.name}</Td>
                          <Td>
                            {moment(new Date(item.last_updated)).fromNow()}
                          </Td>
                          <Td className="group-action">
                            <div style={{ display: "flex" }}>
                              <Button
                                type="primary"
                                shape="round"
                                icon="edit"
                                size="small"
                                style={{ marginRight: "15px" }}
                                onClick={this.handleEdit.bind(this, item._id, item.itinerary_detail[0].children[0].children[0]._id)}
                              >
                                Edit
                              </Button>
                              <Button
                                type="danger"
                                shape="round"
                                icon="delete"
                                size="small"
                                onClick={this.handleDeleteMyItineraries.bind(
                                  this,
                                  item
                                )}
                              >
                                Remove
                              </Button>
                            </div>
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr className="body-row">
                        <Td colSpan="7">
                          <Empty />
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </div>
            ) : this.state.selectedTab === 2 ? (
              <div className="animated fadeIn">
                <Table>
                  <Thead className="header favorite">
                    <Tr>
                      <Th>Start date</Th>
                      <Th width="10%">Cover</Th>
                      <Th width="40%">Title</Th>
                      <Th>Last updated</Th>
                      <Th>&nbsp;</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {this.state.listMyFavorite.length > 0 ? (
                      this.state.listMyFavorite.map((item, key) => (
                        <Tr className="body-row" key={key}>
                          <Td>
                            {format(new Date(item.dates * 1000), "MMM d yyyy")}
                          </Td>
                          <Td>
                            <div
                              className="img-fluid"
                              style={{
                                backgroundImage: `url(${
                                  item.cover
                                    ? getImageFromUrl(
                                        isMyResource(item.cover)
                                          ? `${
                                              configBaseUrl.baseURL + item.cover
                                            }`
                                          : `${item.cover}`,
                                        "large"
                                      )
                                    : defaultImg
                                })`,
                              }}
                            ></div>
                          </Td>
                          <Td>{item.name}</Td>
                          <Td>
                            {moment(new Date(item.last_updated)).fromNow()}
                          </Td>
                          <Td className="group-action">
                            <div style={{ display: "flex" }}>
                              <Button
                                type="primary"
                                shape="round"
                                icon="edit"
                                size="small"
                                style={{ marginRight: "15px" }}
                                onClick={this.handleEdit.bind(this, item._id, item.itinerary_detail[0].children[0].children[0]._id)}
                              >
                                Edit
                              </Button>
                              <Button
                                type="danger"
                                shape="round"
                                icon="delete"
                                size="small"
                                onClick={this.handleDeleteMyFavorite.bind(
                                  this,
                                  item
                                )}
                              >
                                Remove
                              </Button>
                            </div>
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr className="body-row">
                        <Td colSpan="7">
                          <Empty />
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </div>
            ) : (
              <div className="animated fadeIn">
                <Table>
                  <Thead className="header draft">
                    <Tr>
                      <Th>Start date</Th>
                      <Th width="10%">Cover</Th>
                      <Th width="40%">Title</Th>
                      <Th>Last updated</Th>
                      <Th>&nbsp;</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {this.state.listDraft.length > 0 ? (
                      this.state.listDraft.map((item, key) => (
                        <Tr className="body-row" key={key}>
                          <Td>
                            {format(new Date(item.dates * 1000), "MMM d yyyy")}
                          </Td>
                          <Td>
                            <div
                              className="img-fluid"
                              style={{
                                backgroundImage: `url(${
                                  item.cover
                                    ? getImageFromUrl(
                                        isMyResource(item.cover)
                                          ? `${
                                              configBaseUrl.baseURL + item.cover
                                            }`
                                          : `${item.cover}`,
                                        "large"
                                      )
                                    : defaultImg
                                })`,
                              }}
                            ></div>
                          </Td>
                          <Td>{item.name}</Td>
                          <Td>
                            {moment(new Date(item.last_updated)).fromNow()}
                          </Td>
                          <Td className="group-action">
                            <div style={{ display: "flex" }}>
                              <Button
                                type="primary"
                                shape="round"
                                icon="edit"
                                size="small"
                                style={{ marginRight: "15px" }}
                                onClick={this.handleEdit.bind(this, item._id, item.itinerary_detail[0].children[0].children[0]._id)}
                              >
                                Edit
                              </Button>
                              <Button
                                type="danger"
                                shape="round"
                                icon="delete"
                                size="small"
                                onClick={this.handleDeleteDraft.bind(
                                  this,
                                  item
                                )}
                              >
                                Remove
                              </Button>
                            </div>
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr className="body-row">
                        <Td colSpan="7">
                          <Empty />
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </div>
            )}
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyTyneriTable);
