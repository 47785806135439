import React, { Component } from "react";
import ApiService from "../../services/api.service";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import {
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout,
  updateInforCurrency,
} from "../../store/actions";
import { withRouter } from "react-router";
import {
  HeaderTopList,
  ContentViewTopList,
  TopListsRelative,
  ListSameAuthor
} from "../../components";
import Cookies from "universal-cookie";
import Alert from "../../module/admin/components/alert";

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInforCurrency: (currency) => {
      dispatch(updateInforCurrency(currency));
    },
    saveCurrentItinerary: (itinerary) => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

class ViewTopList extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      top_list: null,
      listSameAuthor: []
    };
  }

  componentDidMount = () => {
    this.setList();
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.profile && !nextProps.profile && !this.state.top_list.show) {
      this.props.history.push("/top-lists");
      return;
    }
    if (
      this.props.match.params.idTopList !== nextProps.match.params.idTopList
    ) {
      var user_id;
      if (nextProps.profile) user_id = nextProps.profile._id;
      else user_id = null;

      const cookies = new Cookies();
      const cookie = cookies.get("id");

      this.apiService
        .viewTopList(nextProps.match.params.idTopList, user_id, cookie)
        .then((res) => {
          if (res.status === "success") {
            if (res.top_list) {
              this.setState({
                top_list: res.top_list,
                listSameAuthor: res.list_same_author
              }, () => {
                this.autoScrollTop()
              });
            } else this.props.history.push("/top-lists");
          } else {
            let alert = new Alert();
            alert.error(res.error);
            this.props.history.push("/top-lists");
          }
        });
    }
  };

  autoScrollTop = () => {
    document.getElementById("top-page").scrollIntoView({ behavior: "smooth" });
  }

  setList = async () => {
    var user_id;
    if (this.props.profile) user_id = this.props.profile._id;
    else user_id = null;

    const cookies = new Cookies();
    const cookie = cookies.get("id");

    await this.apiService
      .viewTopList(this.props.match.params.idTopList, user_id, cookie)
      .then((res) => {
        if (res.status === "success") {
          if (res.top_list) {
            this.setState({
              top_list: res.top_list,
              listSameAuthor: res.list_same_author
            });
          } else this.props.history.push("/top-lists");
        } else {
          let alert = new Alert();
          alert.error(res.error);
          this.props.history.push("/top-lists");
        }
      });
  };

  render() {
    return this.state.top_list ? (
      <>
        <Row id="top-page" gutter={[16, 16]}>
          <Col xs={24} lg={16}>
            <HeaderTopList top_list={this.state.top_list} />
            <ContentViewTopList
              setList={this.setList}
              top_list={this.state.top_list}
            />
          </Col>
          <Col xs={24} lg={8}>
            <TopListsRelative top_list={this.state.top_list} />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col>
            <ListSameAuthor listSameAuthor={this.state.listSameAuthor} />
          </Col>
        </Row>
      </>
    ) : null;
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewTopList)
);
