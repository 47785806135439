import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';
import FormControl from '@material-ui/core/FormControl';
import { saveToken } from '../../../services/authService';
import ApiService from '../../../services/api.service';
import { connect } from 'react-redux';
import { saveProfile, saveCompany } from '../../../store/actions';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ErrorIcon from '@material-ui/icons/Error';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link } from 'react-router-dom';
import { ParamNameAccountType } from '../../../params/AccountType';

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    saveCompany: (company) => {
      dispatch(saveCompany(company));
    },
  };
};

class FormSignUp extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      value: 0,
      email: '',
      password: '',
      fullName: '',
      firstName: '',
      lastName: '',
      submitted: false,
      showSignInError: false,
      showSignUpError: false,
      showReset: false,
      confirmPrivacy: false,
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSignUp = () => {
    this.setState({ submitted: true, showSignUpError: false }, () => {
      this.apiService
        .signUp(
          this.state.firstName,
          this.state.lastName,
          this.state.email,
          this.state.password
        )
        .then((res) => {
          if (res.status === 'success') {
            this.apiService.sendEmailSignUp(
              this.state.firstName,
              this.state.email
            );
            this.apiService
              .login(this.state.email, this.state.password)
              .then((result) => {
                if (result.status === 'success') {
                  saveToken(res.token);
                  this.props.saveProfile(result.user);
                  if (!this.props.isPopup) {
                    if (this.props.match.params.package) {
                      if (
                        this.props.match.params.package ===
                        ParamNameAccountType.Basic.name
                      ) {
                        this.props.history.push(
                          `/billing/${ParamNameAccountType.Basic.name}`
                        );
                      }
                      if (
                        this.props.match.params.package ===
                        ParamNameAccountType.Personal.name
                      ) {
                        this.props.history.push(
                          `/billing/${ParamNameAccountType.Personal.name}`
                        );
                      }
                      if (
                        this.props.match.params.package ===
                        ParamNameAccountType.Pro.name
                      ) {
                        this.props.history.push(
                          `/billing/${ParamNameAccountType.Pro.name}`
                        );
                      }
                    }
                  }
                } else
                  this.setState({
                    showSignUpError: true,
                    submitted: false,
                  });
              });
          } else
            this.setState({
              showSignUpError: true,
              submitted: false,
            });
        });
    });
  };

  handleConfirmPrivacy = (event) => {
    this.setState({ confirmPrivacy: event.target.checked });
  };

  render() {
    return (
      <>
        <ValidatorForm onSubmit={this.handleSignUp}>
          <Row>
            <Container fluid={true}>
              <FormControl fullWidth={true} style={{ marginBottom: '1rem' }}>
                <TextValidator
                  variant="outlined"
                  label="First name *"
                  onChange={this.handleChange('firstName')}
                  name="firstName"
                  type="text"
                  value={this.state.firstName}
                  validators={['required']}
                  errorMessages={[
                    <span>
                      <ErrorIcon />
                      This field is required
                    </span>,
                  ]}
                />
              </FormControl>
              <FormControl fullWidth={true} style={{ marginBottom: '1rem' }}>
                <TextValidator
                  variant="outlined"
                  label="Last name *"
                  onChange={this.handleChange('lastName')}
                  name="lastName"
                  type="text"
                  value={this.state.lastName}
                  validators={['required']}
                  errorMessages={[
                    <span>
                      <ErrorIcon />
                      This field is required
                    </span>,
                  ]}
                />
              </FormControl>
              <FormControl fullWidth={true} style={{ marginBottom: '1rem' }}>
                <TextValidator
                  ref={this.emailRef}
                  label="Email *"
                  variant="outlined"
                  onChange={this.handleChange('email')}
                  name="email"
                  value={this.state.email}
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    <span>
                      <ErrorIcon />
                      This field is required
                    </span>,
                    <span>
                      <ErrorIcon />
                      Email is not valid
                    </span>,
                  ]}
                />
              </FormControl>
              <FormControl fullWidth={true} style={{ marginBottom: '1rem' }}>
                <TextValidator
                  variant="outlined"
                  label="Password *"
                  onChange={this.handleChange('password')}
                  name="password"
                  type="password"
                  value={this.state.password}
                  validators={['required']}
                  errorMessages={[
                    <span>
                      <ErrorIcon />
                      This field is required
                    </span>,
                  ]}
                />
              </FormControl>
            </Container>
          </Row>
          <Row>
            <Container fluid={true}>
              {this.state.showSignUpError && (
                <FormHelperText className="Mui-error">
                  Email already exists. Please try again!
                </FormHelperText>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.confirmPrivacy}
                    onChange={(e) => this.handleConfirmPrivacy(e)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <>
                    I agree to Tyneri{' '}
                    <a
                      to="https://tyneri.com/terms-of-service/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                      to="https://tyneri.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </>
                }
              />
            </Container>

            <Container style={{ textAlign: `center` }} fluid={true}>
              <Button
                size="large"
                className="btn-sign-up"
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={
                  !this.state.confirmPrivacy || this.state.submitted
                    ? true
                    : false
                }
              >
                CREATE ACCOUNT
              </Button>
            </Container>
          </Row>
        </ValidatorForm>
        <div className="section-sign-up-now">
          Already have a Tyneri account?{' '}
          <span
            className="sign-up-now"
            onClick={() =>
              this.props.isPopup
                ? this.props.handleChangeTab()
                : this.props.history.push('/sign-in')
            }
          >
            Sign in
          </span>
        </div>
      </>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(FormSignUp));
