import React, { useState } from "react";
import { Button, Modal, Input, Form, InputNumber } from "antd";
import { EditFilled } from "@ant-design/icons";
import { connect } from "react-redux";

import { saveCompany } from "../../../../../store/actions";
import Alert from "../../alert";
import ApiService from "../../../../../services/companyApi.service";

const FormCompany = (props) => {
  const form = props.form;
  const company = props.company;
  const { getFieldDecorator } = form;
  const apiService = ApiService();
  const [openForm, setOpenForm] = useState(false);
  const alert = new Alert();

  function submitCompanyForm(e) {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const CompanyData = {
          url: values["url"],
          name: values["name"],
          short_name: values["short_name"],
          email: values["email"],
          address: values["address"],
          phone: values["phone"],
          website: values["website"],
          about_us: values["about_us"],
          facebook: values["facebook"],
          twitter: values["twitter"],
          belong_to_user: props.profile._id,
        };
        const response = await apiService.save(CompanyData);
        if (!response.error) {
          props.saveCompany({
            ...response.item,
          });
          alert.success("Update Company successfully");
          setOpenForm(false);
        } else {
          alert.error("Cannot update company");
        }
      }
    });
  }

  async function handleValidatorUrl(rule, value, callback) {
    if (value !== "") {
      const url = await apiService.checkUniqueUrl(value);
      if (url.exist)
        callback(
          "Your url has been registered by another company please try new one"
        );
    }
    callback();
  }

  function renderFormCompany() {
    return (
      <Modal
        visible={openForm}
        footer={
          <Button
            type="primary"
            htmlType="submit"
            onClick={(e) => {
              submitCompanyForm(e);
            }}
          >
            Update
          </Button>
        }
        onCancel={() => setOpenForm(!openForm)}
      >
        <Form
          name="company-form"
          className="company-form"
          form={form}
          onSubmit={(e) => {
            submitCompanyForm(e);
          }}
        >
          <Form.Item label="Your URL">
            {getFieldDecorator("url", {
              initialValue: company && company.url ? company.url : "",
              rules: [
                {
                  pattern: new RegExp(
                    /^[a-zA-Z0-9@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]+$/i
                  ),
                  message: "This is not validate url!",
                },
                {
                  validator: handleValidatorUrl,
                },
              ],
            })(<Input addonAfter=".tyneri.com" />)}
          </Form.Item>
          <Form.Item label="Company name">
            {getFieldDecorator("name", {
              initialValue: company && company.name ? company.name : "",
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Short name">
            {getFieldDecorator("short_name", {
              initialValue:
                company && company.short_name ? company.short_name : "",
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Address">
            {getFieldDecorator("address", {
              initialValue: company && company.address ? company.address : "",
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Phone number">
            {getFieldDecorator("phone", {
              initialValue: company && company.phone ? company.phone : "",
            })(<InputNumber />)}
          </Form.Item>
          <Form.Item label="Email">
            {getFieldDecorator("email", {
              initialValue: company && company.email ? company.email : "",
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Website">
            {getFieldDecorator("website", {
              initialValue: company && company.website ? company.website : "",
              rules: [
                {
                  type: "url",
                  message: "This is not validate website! ex:https://yourwebsite.com",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Facebook">
            {getFieldDecorator("facebook", {
              initialValue: company.facebook ? company.facebook : '',
              rules: [
                {
                  pattern: new RegExp(/^[a-zA-Z0-9@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i),
                  message: "This is not validate facebook!",
                },
              ],
            })(<Input addonBefore="https://www.facebook.com/" />)}
          </Form.Item>
          <Form.Item label="Twitter">
            {getFieldDecorator("twitter", {
              initialValue: company.twitter ? company.twitter : '',
              rules: [
                {
                  pattern: new RegExp(/^[a-zA-Z0-9@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i),
                  message: "This is not validate twitter!",
                },
              ],
            })(<Input addonBefore="https://twitter.com/" />)}
          </Form.Item>
          <Form.Item label="About us">
            {getFieldDecorator("about_us", {
              initialValue: company && company.about_us ? company.about_us : "",
            })(<Input.TextArea />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  return (
    <>
      {renderFormCompany()}
      <Button onClick={() => {
        if (!props.profile.verify) {
          let alert = new Alert
          alert.warning("You need verify your email address to update company info")
          return
        }
        setOpenForm(true)}}>
        <EditFilled />
        Edit
      </Button>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveCompany: (company) => {
      dispatch(saveCompany(company));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    company: state.profileReducer.company,
    profile: state.profileReducer.info,
  };
};

export default Form.create()(
  connect(mapStateToProps, mapDispatchToProps)(FormCompany)
);
