import { actionTypes } from '../../constants/actionTypes'
import { removeToken } from "../../services/authService";

export const saveIp = (ip) => {
    return {
        type: actionTypes.SAVE_IP,
        ip: ip
    }
}

export const redirectToBillingAfterLogin = (flag) => {
    return {
        type: actionTypes.REDIRECT_TO_BILLING_AFTER_LOGIN,
        flag: flag
    }
};

export const openFormReportPlace = (item) => {
    return {
        type: actionTypes.OPEN_FORM_REPORT_PLACE,
        item
    }
}

export const closeFormReportPlace = () => {
    return {
        type: actionTypes.CLOSE_FORM_REPORT_PLACE
    }
}

export const updateListLoad = (list) => {
    return {
        type: actionTypes.UPDATE_LIST_LOAD,
        list
    }
}

export const updateInforCurrency = (currency) => {
    return {
        type: actionTypes.UPDATE_INFOR_CURRENCY,
        currency
    }
}

export const saveAfterLogin = (flag) => {
    return {
        type: actionTypes.SAVE_AFTER_LOGIN,
        flag: flag
    }
};

export const handleSlickGoTo = (index) => {
    return {
        type: actionTypes.HANDLE_SLICK_GO_TO,
        index
    }
}

export const openFormQuote = (item) => {
    return {
        type: actionTypes.OPEN_FORM_QUOTE,
        item
    }
}

export const closeFormQuote = () => {
    return {
        type: actionTypes.CLOSE_FORM_QUOTE
    }
}

export const openFormLoad = () => {
    return {
        type: actionTypes.OPEN_FORM_LOAD
    }
}

export const closeFormLoad = () => {
    return {
        type: actionTypes.CLOSE_FORM_LOAD
    }
}

export const startLoading = () => {
    return {
        type: actionTypes.START_LOADING
    }
}

export const stopLoading = () => {
    return {
        type: actionTypes.STOP_LOADING
    }
}

export const saveProfile = (info) => {
    return {
        type: actionTypes.SAVE_PROFILE,
        info: info
    }
}

export const logout = () => {
    removeToken();
    return {
        type: actionTypes.LOGOUT
    }
}

export const updateCurrency = (currency) => {
    return {
        type: actionTypes.UPDATE_CURRENCY,
        currency
    }
}

export const updateTotalDay = (day) => {
    return {
        type: actionTypes.UPDATE_TOTAL_DAY,
        day
    }
}

export const updateTotalAmount = (amount) => {
    return {
        type: actionTypes.UPDATE_TOTAL_AMOUNT,
        amount
    }
}

export const saveCurrentItinerary = (itinerary) => {
    return {
        type: actionTypes.SAVE_CURRENT_ITINERARY,
        itinerary
    }
}

export const reloadPdf = () => {
    return {
        type: actionTypes.RELOAD_PDF
    }
}

export const unReloadPdf = () => {
    return {
        type: actionTypes.UNRELOAD_PDF
    }
}

export const updateListPlacesExplore = (list, status) => {
    return {
        type: actionTypes.UPDATE_LIST_PLACES_EXPLORE,
        list,
        status
    }
}

export const updateListItineraryDetail = (typeUpdate) => {
    return {
        type: actionTypes.UPDATE_LIST_ITINERARY_DETAIL,
        typeUpdate
    }
}

export const stopUpdateListItineraryDetail = () => {
    return {
        type: actionTypes.STOP_UPDATE_LIST_ITINERARY_DETAIL
    }
}

export const openFormAddNewStop = () => {
    return {
        type: actionTypes.OPEN_FORM_ADD_NEW_STOP
    }
}

export const closeFormAddNewStop = () => {
    return {
        type: actionTypes.CLOSE_FORM_ADD_NEW_STOP
    }
}

export const openFormShareByEmail = () => {
    return {
        type: actionTypes.OPEN_FORM_SHARE_BY_EMAIL
    }
}

export const closeFormShareByEmail = () => {
    return {
        type: actionTypes.CLOSE_FORM_SHARE_BY_EMAIL
    }
}

export const openFormViewEditPlace = (item) => {
    return {
        type: actionTypes.OPEN_FORM_VIEW_EDIT_PLACE,
        item
    }
}

export const closeFormViewEditPlace = () => {
    return {
        type: actionTypes.CLOSE_FORM_VIEW_EDIT_PLACE
    }
}

export const openFormViewEditTransportation = (transportation) => {
    return {
        type: actionTypes.OPEN_FORM_VIEW_EDIT_TRANSPORTATION,
        transportation
    }
}

export const closeFormViewEditTransportation = () => {
    return {
        type: actionTypes.CLOSE_FORM_VIEW_EDIT_TRANSPORTATION
    }
}

export const saveCompany = (company) => {
    return {
        type: actionTypes.SAVE_COMPANY,
        company
    }
}

export const saveCompanyDomain = (company) => {
    return {
        type: actionTypes.SAVE_COMPANY_DOMAIN,
        company
    }
}

export const saveProfileDomain = (profile) => {
    return {
        type: actionTypes.SAVE_PROFILE_DOMAIN,
        profile
    }
}
