import { actionTypes } from '../../constants/actionTypes'

var initialState = false

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RELOAD_PDF:
            state = true
            return state
        case actionTypes.UNRELOAD_PDF:
            state = false
            return state
        default:
            return state
    }
}
