import React from "react";
import {Carousel, Icon} from "antd";
import ImageHelper from "../../functions/image";
import DefaultBackground from "../../assets/media/images/generic-activities-img.jpg";
import {getImageFromUrl, isMyResource} from "../../services/utils.service";
import {configBaseUrl} from "../../config";
import AspectRatio from 'react-aspect-ratio';

const SlickArrowLeft = ({ currentSlide, slideCount, className, ...props }) => (
    <div className={className+ ' hover-effect'} {...props}>
        <Icon type="left" />
    </div>
);
const SlickArrowRight = ({ currentSlide, slideCount, className, ...props }) => (
    <div className={className+ ' hover-effect'} {...props}>
        <Icon type="right" />
    </div>
);

const TyneriCarousel = ({className, ...props}) => {
    let settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        speed: 500,
        arrows: true,
        autoplay: true,
        swipeToSlide: true,
        nextArrow: <SlickArrowRight />,
        prevArrow: <SlickArrowLeft />,
    };
    settings = Object.assign(settings, props.settings || {});
    const galleryList: [] = props.images || [];
    return (
        <Carousel
            className={className}
            {...settings}
            style={{}}
        >
            {galleryList.map(img=> (
                <AspectRatio ratio={16/9} style={{maxWidth: '100%',}}>
                    <ImageHelper style={{
                        objectFit: 'cover',
                        objectPosition: 'center'
                    }} className="img-fluid" notFound={DefaultBackground} src={getImageFromUrl(isMyResource(img.preview) ? configBaseUrl.baseURL + img.preview : img.preview, 'medium')} />
                </AspectRatio>
            ))}
        </Carousel>
    );
}

export default TyneriCarousel;
