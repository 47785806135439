import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, Icon } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import moment from "moment";

import Alert from "../../alert";
import FormInformation from "../form-information/index";
import ApiService from "../../../../../services/userApi.service";
import { isTablet, isMobile } from "react-device-detect";

const DescriptionCustom = ({ label, value, classLabel, classValue }) => {
  if (!value) return null;

  return (
    <tr>
      <td className={" label " + (classLabel ? classLabel : "")}>
        <strong>{label}</strong>
      </td>
      <td className={classValue ? classValue : ""}>{value}</td>
    </tr>
  );
};

const EmailVerify = ({ profile, sendEmailVerify }) => {
  const LengthEmailByDevice = () => {
    if (isTablet) return 20;
    if (isMobile) return 10;
    return 30;
  };
  return (
    <>
      <a href={"mailto:" + profile.email}>
        {profile.email.length > LengthEmailByDevice() ? (
          <Tooltip placement="bottom" title={profile.email}>
            {profile.email.slice(0, LengthEmailByDevice()) + "..."}
          </Tooltip>
        ) : (
          profile.email
        )}
      </a>
      {!profile.verify ? (
        <p className="verify">
          (Email not verified.{" "}
          <span
            className="verify-now"
            onClick={() => sendEmailVerify(profile.email)}
          >
            Verify now
          </span>
          )
        </p>
      ) : (
        <Tooltip title="Verified">
          <CheckCircleFilled
            style={{
              color: `#4caf50`,
              verticalAlign: `middle`,
              marginLeft: "5px",
            }}
          />
        </Tooltip>
      )}
    </>
  );
};

const SocialAccount = ({ socialType, socialProfile, linkSocial }) => {
  if (socialProfile === "") return null;
  return (
    <div className="social-account">
      {socialType}
      <a target="blank" href={linkSocial + socialProfile}>
        {socialProfile}
      </a>
    </div>
  );
};

const UserInfo = (props) => {
  const profile = props.profile;
  const apiService = ApiService();
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    fetchAllJob();
  }, []);

  function fetchAllJob() {
    apiService.getAllJob().then((res) => {
      setJobs(res.data.data);
    });
  }

  async function sendEmailVerify(email) {
    const res = await apiService.checkVerified(email);
    if (res.status === "success") {
      if (res.verify) {
        window.location.reload();
        return;
      }
    }
    const response = await apiService.checkEmailVerifyEmail(email);
    if (response.status === "success") {
      let alert = new Alert();
      alert.success("Send email");
    } else {
      let alert = new Alert();
      alert.error("Something went wrong!");
    }
  }

  return (
    <div className="user-info">
      <Row className="edit-container">
        <Col xs={12} sm={12} md={12} lg={12} xl={20}>
          <h4>Account information</h4>
        </Col>
        <FormInformation jobs={jobs}
                         fetchAllJob={ fetchAllJob } />
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <table className="information">
            <thead></thead>
            <tbody>
              <DescriptionCustom
                label="Name"
                value={profile.firstName + " " + profile.lastName}
              />
              <DescriptionCustom label="Phone" value={profile.phone} />
              <DescriptionCustom
                label="Email"
                value={
                  <EmailVerify
                    profile={profile}
                    sendEmailVerify={sendEmailVerify}
                  />
                }
              />
              <DescriptionCustom
                label="Joined in"
                value={moment(profile.created_at).format("ll")}
              />
              <DescriptionCustom
                label="Location "
                value={profile.location.name}
              />
              <DescriptionCustom
                label="Job title "
                value={profile.job && profile.job.name}
              />
              {((profile.facebook && profile.facebook !== "") ||
                (profile.twitter && profile.twitter !== "")) && (
                <DescriptionCustom
                  label={"Social"}
                  value={
                    <div className="social-account-container">
                      {profile.facebook && profile.facebook !== "" && (
                        <SocialAccount
                          socialType={<Icon type="facebook" />}
                          socialProfile={profile.facebook}
                          linkSocial="https://www.facebook.com/"
                        />
                      )}
                      {profile.twitter && profile.twitter !== "" && (
                        <SocialAccount
                          socialType={<Icon type="twitter" />}
                          socialProfile={profile.twitter}
                          linkSocial="https://www.twitter.com/"
                        />
                      )}
                    </div>
                  }
                  classLabel="social"
                />
              )}
              <DescriptionCustom
                label="Personal website "
                value={
                  profile.website && (
                    <a href={profile.website} target="blank">
                      {profile.website}
                    </a>
                  )
                }
              />
            </tbody>
          </table>
          <div className="about-us">
            <label>
              <strong>About you</strong>
            </label>
            <p dangerouslySetInnerHTML={{ __html: profile.about_me }}></p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserInfo;
