import React from 'react';
import { Form, DatePicker, Select, Checkbox } from 'antd';

const { RangePicker } = DatePicker;

function Info(props) {
  const errors = props.errors;
  const register = props.register;
  const Controller = props.Controller;
  const control = props.control;
  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 18 },
  };

  const listCurrency = props.listCurrency;
  const setCurrency = props.setCurrency;
  const currency = props.currency;
  const rangeDate = props.rangeDate;
  return (
    <>
      <Form.Item
        label="Title"
        {...layout}
        className={errors.title && ' has-error '}
      >
        <input
          name="title"
          className={'ant-input  '}
          ref={register({ required: true })}
        />
        {errors.title && (
          <div className="ant-form-explain">Please input title </div>
        )}
      </Form.Item>
      <Form.Item label="Description" {...layout}>
        <textarea name="description" className={'ant-input  '} ref={register} />
      </Form.Item>
      <Form.Item label="People" {...layout}>
        <input
          name="people"
          type="number"
          min="1"
          className={'ant-input  '}
          ref={register}
        />
      </Form.Item>
      <Form.Item label="Date" {...layout}>
        <Controller
          control={control}
          name="date"
          render={({ value, onChange }) => (
            <RangePicker
              format="LL"
              onChange={(value) => {
                props.setRangDate([value[0], value[1]]);
                onChange(value);
              }}
              value={[rangeDate[0], rangeDate[1]]}
              dateRender={(current) => {
                const style = {};
                if (current.date() === 1) {
                  style.border = '1px solid #1890ff';
                  style.borderRadius = '50%';
                }
                return (
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                );
              }}
            />
          )}
        />
      </Form.Item>
      <Form.Item label="Budget" {...layout}>
        <div className="ant-input-group ant-input-group-compact">
          <Controller
            control={control}
            name="currency"
            defaultValue={currency}
            render={({ value, onChange }) => (
              <Select
                value={currency}
                onChange={(value) => {
                  onChange(value);
                  setCurrency(value);
                }}
              >
                {listCurrency.map((currency) => (
                  <Select.Option value={currency.code} key={currency._id}>
                    {currency.code} ({currency.symbol})
                  </Select.Option>
                ))}
              </Select>
            )}
          />
          <input className="ant-input" name="budget" ref={register} />
        </div>
      </Form.Item>
      {props.showConfirm && (
        <Form.Item>
          <Controller
            name="is_confirm"
            control={control}
            defaultValue={props.confirm}
            render={({ value, onChange }) => (
              <Checkbox
                checked={props.confirm}
                onChange={(e) => {
                  props.setConfirm(e.target.checked);
                  onChange(e.target.checked);
                }}
              >
                Confirmed
              </Checkbox>
            )}
          />
        </Form.Item>
      )}
    </>
  );
}

export default Info;
