import React, { useEffect, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import moment from 'moment';
import { format } from 'date-fns';

import { config } from '../../../../../config';
import ImageHelper from '../../../../../functions/image';
import TyneriApi from '../../../../../services/api.service';
import * as QuotationApi from '../../../../../api/quotation';
import './index.scss';

import CustomerForm from './customer';
import QuotationInfo from './info';
import QuotationDetail from './detail';
import Alert from '../../alert';
import ButtonContainer from './button';
import EditorCustom from './detail/editorCustom/WYSIWYG';
import LoadingOverLay from '../../loading';

const dayDataDefault = {
  days: [
    {
      name: '',
      description: '',
      hotel: '',
      address: '',
      date: '',
      lodgingCheck: false,
      guideCheck: false,
      mealCheck: false,
      stops: [
        {
          start_time: '',
          end_time: '',
          title: '',
          description: '',
          budget: 0,
          rejected: false,
        },
      ],
    },
  ],
};

function FormQuotation(props) {
  const tyneriApi = TyneriApi();
  const alert = new Alert();
  const [quotation, setQuotation] = useState({});
  const [itinerary, setItinerary] = useState({});
  const [currency, setCurrency] = useState(null);
  const [listCurrency, setListCurrency] = useState([]);
  const [rangeDate, setRangDate] = useState([]);
  const [edit, setEdit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    control,
    getValues,
  } = useForm(dayDataDefault);

  const company = props.company;

  useEffect(() => {
    if (props.match.params.idTyneri) getDefaultItinerary();
    else if (props.match.params.id) getQuotation();

    getAllCurrency();
  }, []);

  function getQuotation() {
    QuotationApi.getById(props.match.params.id).then((response) => {
      setEdit(true);
      setQuotation(response.data);
      setCurrency(response.data.currency);
      setDefaultValue(response);
    });
  }

  function getAllCurrency() {
    tyneriApi.getAllCurrency().then((response) => {
      setListCurrency(response.currencies);
    });
  }

  function setDefaultValue(response) {
    setItinerary(response.data);

    setValue('description', response.data.description);
    setValue('currency', response.data.currency);
    setCurrency(response.data.currency);

    let rangeDateTemp = [];
    if (response.data._id) {
      if (response.data.is_confirm === 1) setConfirm(true);

      setValue('name', response.data.name);
      setValue('title', response.data.title);
      setValue('people', response.data.people);
      setValue('budget', response.data.budget);
      rangeDateTemp = [
        moment(response.data.start_date),
        moment(response.data.end_date),
      ];
    } else {
      rangeDateTemp = [
        moment(format(new Date(response.data.dates * 1000), 'd MMM yyyy')),
        moment(format(new Date(response.data.end_dates * 1000), 'd MMM yyyy')),
      ];
      setValue('title', response.data.name);
      setValue('people', response.data.a_number_of_paxs);
      setValue('budget', response.data.estimated_budget);
    }

    setRangDate(rangeDateTemp);
    setValue('days', response.data.days);
  }

  function getDefaultItinerary() {
    QuotationApi.convertItineraryToQuotation(props.match.params.idTyneri).then(
      (response) => {
        if (response.status === 'success' && !response.error) {
          setDefaultValue(response);
        }
      }
    );
  }

  const getTextValue = (text) => {
    if (text) return text;
    return '';
  };

  const submitFormQuotation = handleSubmit((values) => {
    saveQuotation(values, 'false');
  });

  const saveQuotationAndSendEmail = handleSubmit((values) => {
    saveQuotation(values, 'true');
  });

  async function submitCancel() {
    const id = props.match.params.id;
    setSubmitLoading(true);
    await QuotationApi.canceled(id).then((res) => {
      if (!res.error) {
        alert.success('Success');
        props.history.push(
          '/quotations/' +
            (window.location.hash ? window.location.hash : '#cancelled')
        );
      } else {
        alert.error('Error');
      }
    });
    setSubmitLoading(false);
  }

  async function saveQuotation(values, sendEmail) {
    setSubmitLoading(true);
    let data = getDataQuotation(values);
    await QuotationApi.save(data, sendEmail).then((response) => {
      if (!response.error) {
        alert.success('Success');
        props.history.push(
          '/quotations' + (window.location.hash && window.location.hash)
        );
      } else {
        alert.error('Error');
      }
    });
    setSubmitLoading(false);
  }

  function getDataQuotation(values) {
    let data = {};
    data = { ...values };

    if (edit) data.id = quotation._id;

    if (rangeDate.length > 0) {
      data.start_date = rangeDate[0].format('YYYY-MM-DD');
      data.end_date = rangeDate[1].format('YYYY-MM-DD');
    }

    if (!edit && props.match.params.idTyneri) {
      data.belong_to_itinerary = props.match.params.idTyneri;
    }
    data.belong_to_user = props.profile._id;
    data.days = getDataDays(values.days);
    if (data.is_confirm) {
      data.is_confirm = 1;
    } else {
      data.is_confirm = 0;
    }
    return data;
  }

  function getDataDays(days) {
    if (days) {
      days.forEach((day, i) => {
        if (day.lodgingCheck) day.lodging = day.lodging[0];
        if (day.guideCheck) day.guide = day.guide[0];
        if (day.stops) {
          day.stops.forEach((s, y) => {
            s.start_time = s.start_time ? s.start_time.format('HH:mm') : '';
            s.end_time = s.end_time ? s.end_time.format('HH:mm') : '';
          });
        }
      });
      return days;
    }
    return null;
  }

  if (
    !company ||
    (edit && !Object.keys(quotation).length && props.match.params.idTyneri) ||
    (!Object.keys(itinerary).length && props.match.params.id)
  )
    return (
      <div className="create-quotation-container">
        <Skeleton
          className="loading-quotation"
          active
          paragraph={{ rows: 1 }}
          title={false}
        />
      </div>
    );

  return (
    <>
      <LoadingOverLay submitLoading={submitLoading} />
      <div className="create-quotation-container">
        <div className="submit-content">
          <form>
            <section className="quotation-to-from">
              <Row>
                <Col className="quotation-to" xs={24} sm={24} md={12} lg={12}>
                  <h5>Quotation To</h5>
                  <CustomerForm
                    setValue={setValue}
                    register={register}
                    errors={errors}
                    Controller={Controller}
                    control={control}
                    defaultData={quotation ? quotation : null}
                  />
                </Col>
                <Col className="quotation-from" xs={24} sm={24} md={12} lg={12}>
                  <h5>Quotation From</h5>
                  <div className="content">
                    <div className="company-info">
                      <span>
                        Main office: {getTextValue(company.address)}
                        <br />
                        Tel: {getTextValue(company.phone)}
                        <br />
                        Email: {getTextValue(company.email)}
                      </span>
                    </div>
                    <div className="company-logo">
                      <ImageHelper src={config.baseURL + company.logo} />
                    </div>
                  </div>
                </Col>
              </Row>
            </section>
            <section className="quotation-content">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <QuotationInfo
                    register={register}
                    errors={errors}
                    Controller={Controller}
                    control={control}
                    listCurrency={listCurrency}
                    setCurrency={setCurrency}
                    currency={currency}
                    rangeDate={rangeDate}
                    setRangDate={setRangDate}
                    confirm={confirm}
                    setConfirm={setConfirm}
                    showConfirm={
                      props.match.params.id &&
                      (quotation.is_pending || quotation.is_confirm)
                        ? true
                        : false
                    }
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}></Col>
              </Row>
            </section>
            <section className="day-detail-container">
              <h5 className="text">Itinerary detailed</h5>

              <QuotationDetail
                {...{
                  control,
                  register,
                  getValues,
                  setValue,
                  errors,
                  currency,
                  dayDataDefault,
                }}
              />
            </section>
            <section className="other-information">
              <h5 className="text">Other information</h5>
              <Controller
                control={control}
                name={`other_information`}
                defaultValue={
                  quotation.other_information ? quotation.other_information : ''
                }
                as={
                  <EditorCustom
                    initialValue={
                      quotation.other_information
                        ? quotation.other_information
                        : ''
                    }
                  />
                }
              />
            </section>
          </form>

          <section
            className={`submit-container ${
              !props.match.params.id && ' submit-container-right'
            }`}
          >
            <ButtonContainer
              submitCancel={submitCancel}
              setSubmitLoading={setSubmitLoading}
              submitFormQuotation={submitFormQuotation}
              saveQuotationAndSendEmail={saveQuotationAndSendEmail}
              showCancel={props.match.params.id ? true : false}
              quotation={quotation}
            />
          </section>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
    company: state.profileReducer.company,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormQuotation);
