/*global google*/
import React, { Component } from "react";
class MapView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    var map;
    var directionsDisplay;
    var directionsService = new google.maps.DirectionsService();
    var locations = [];
    this.props.items.forEach((item, index) => {
      locations.push([
        item.data.actual_data.title,
        item.data.actual_data.lat,
        item.data.actual_data.lng,
        index + 1,
      ]);
    });

    if (locations.length) {
      directionsDisplay = new google.maps.DirectionsRenderer();
      var map = new google.maps.Map(document.getElementById("map"), {
        zoom: 14,
        center: new google.maps.LatLng(
          this.props.items[0].data.actual_data.lat,
          this.props.items[0].data.actual_data.lng
        ),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });
      directionsDisplay.setMap(map);
      var infowindow = new google.maps.InfoWindow();
      var marker, i;
      var request = {
        travelMode: google.maps.TravelMode.DRIVING,
      };
      for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
          position: new google.maps.LatLng(locations[i][1], locations[i][2]),
        });
        google.maps.event.addListener(
          marker,
          "click",
          (function (marker, i) {
            return function () {
              infowindow.setContent(locations[i][0]);
              infowindow.open(map, marker);
            };
          })(marker, i)
        );
        if (i == 0) request.origin = marker.getPosition();
        else if (i == locations.length - 1)
          request.destination = marker.getPosition();
        else {
          if (!request.waypoints) request.waypoints = [];
          request.waypoints.push({
            location: marker.getPosition(),
            stopover: true,
          });
        }
      }
      directionsService.route(request, function (result, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(result);
        }
      });
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (JSON.stringify(this.props.items) != JSON.stringify(nextProps.items)) {
      var map;
      var directionsDisplay;
      var directionsService = new google.maps.DirectionsService();
      var locations = [];
      nextProps.items.forEach((item, index) => {
        locations.push([
          item.data.actual_data.title,
          item.data.actual_data.lat,
          item.data.actual_data.lng,
          index + 1,
        ]);
      });
      if (locations.length) {
        directionsDisplay = new google.maps.DirectionsRenderer();
        var map = new google.maps.Map(document.getElementById("map"), {
          zoom: 14,
          center: new google.maps.LatLng(
            nextProps.items[0].data.actual_data.lat,
            nextProps.items[0].data.actual_data.lng
          ),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        });
        directionsDisplay.setMap(map);
        var infowindow = new google.maps.InfoWindow();
        var marker, i;
        var request = {
          travelMode: google.maps.TravelMode.DRIVING,
        };
        for (i = 0; i < locations.length; i++) {
          marker = new google.maps.Marker({
            position: new google.maps.LatLng(locations[i][1], locations[i][2]),
          });
          google.maps.event.addListener(
            marker,
            "click",
            (function (marker, i) {
              return function () {
                infowindow.setContent(locations[i][0]);
                infowindow.open(map, marker);
              };
            })(marker, i)
          );
          if (i == 0) request.origin = marker.getPosition();
          else if (i == locations.length - 1)
            request.destination = marker.getPosition();
          else {
            if (!request.waypoints) request.waypoints = [];
            request.waypoints.push({
              location: marker.getPosition(),
              stopover: true,
            });
          }
        }
        directionsService.route(request, function (result, status) {
          if (status == google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(result);
          }
        });
      }
    }
  };

  render() {
    return (
      <div id="map" style={{ height: `300px`, marginBottom: "10px" }}></div>
    );
  }
}

export default MapView;
