import { Model } from 'react-axiom';

class Tabs extends Model {
  static defaultState() {
    return {
      list: [],
    };
  }

  getSortedList() {
    return this.state.list.sort(function (itemLeft, itemRight) {
      return itemLeft.getPosition() < itemRight.getPosition() ? -1 : 1;
    });
  }
}

export default Tabs;
