import React from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Statistic,
  List,
  notification,
  Radio,
  Result,
  Row,
  Typography,
  Modal,
} from 'antd';
import userApi from '../../../../services/userApi.service';
import PaymentService from '../../../../services/payment.service';
import { connect } from 'react-redux';
import { saveProfile, saveCompany } from '../../../../store/actions';
import {
  AccountType,
  AccountTypeColor,
  AccountData,
  ParamNameAccountType,
} from '../../../../params/AccountType';
import './index.scss';
import _ from 'lodash';
import MoneyHelper from '../../../../helper/moneyHelper';
import Payment from './payment';
import TyneriInvoices from './invoices/invoices';
import ApiService from '../../../../services/api.service';
import StringHelper from '../../../../helper/stringHelper';
import { FaCcStripe, FaCubes } from 'react-icons/fa';
import AddCard from './addCard';

const { Text, Title } = Typography;
const UserApi = userApi();

const InputCard = ({ visible, close, afterClose, ...props }) => {
  return (
    <Modal
      title="Credit card"
      visible={visible}
      onCancel={close}
      footer={false}
      afterClose={afterClose}
      {...props}
    >
      <AddCard closeModal={close} />
    </Modal>
  );
};

class Billing extends React.Component {
  state = {
    value: null,
    loading: false,
    reloadBilling: false,
    showAddCard: false,
    waitPack: null,
    waitIdPackage: null,
  };

  constructor(props) {
    super(props);
    this.apiService = ApiService();
  }

  componentDidMount = async () => {
    /*let res = await this.apiService.getCurrentProfile();
    if (res.status === "success") {
      this.props.saveProfile(res.user);*/
    this.setState({
      accountData: await AccountData(),
    });

    console.log(this.props);
    if (this.props.profile) {
      if (this.props.match.params.package) {
        if (
          this.props.match.params.package === ParamNameAccountType.Basic.name
        ) {
          this.setState({
            value: ParamNameAccountType.Basic.value,
          });
        }
        if (
          this.props.match.params.package === ParamNameAccountType.Personal.name
        ) {
          this.setState({
            value: ParamNameAccountType.Personal.value,
          });
        }
        if (this.props.match.params.package === ParamNameAccountType.Pro.name) {
          this.setState({
            value: ParamNameAccountType.Pro.value,
          });
        }
      } else {
        this.setState({
          value: this.props.profile.accountType,
        });
      }
    }
    // }
  };

  onChange = (e) => {
    const accountType = e.target.value;
    this.setState({ value: accountType });
  };

  cancelSubscript = () => {
    this.setState({
      loading: true,
    });
    PaymentService()
      .stripeCancelSubscript()
      .then((res) => {
        if (!res.error) {
          this.props.saveProfile(res.user);
          this.setState({
            reloadBilling: true,
          });
          notification.success({
            message: 'Canceled successful your package will return to basic.',
            placement: 'bottomRight',
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err);
      });
  };

  onSubscript = (pack, idPackage) => {
    const card = this.props.profile.card;
    this.setState({
      loading: true,
    });
    if (card) {
      UserApi.upgradeAccount(
        this.props.profile._id,
        this.state.value,
        pack,
        idPackage
      ).then((res) => {
        if (!res.error) {
          notification.success({
            message: res.mes,
            placement: 'bottomRight',
          });
        } else {
          notification.error({
            message: res.mes,
            placement: 'bottomRight',
          });
        }
        this.setState({ value: res.user.accountType });
        this.props.saveProfile(res.user);
        if (res.user.accountType === AccountType.Pro && res.company) {
          this.props.saveCompany(res.company);
        }
        this.setState({
          loading: false,
          reloadBilling: true,
        });
      });
    } else {
      this.setState({
        loading: false,
        showAddCard: true,
        waitPack: pack,
        waitIdPackage: idPackage,
      });
    }
  };

  listPricing = (pick, currentPackage) => {
    return (
      <List
        itemLayout="horizontal"
        size="small"
        className="pl-2 pr-2"
        dataSource={pick.pricing}
        renderItem={(item) =>
          item.status ? (
            <List.Item
              key={item._id}
              actions={[
                <Button
                  loading={this.state.loading}
                  onClick={() => this.onSubscript(item, pick._id)}
                  type="primary"
                  className="btn-stylist"
                  disabled={
                    currentPackage &&
                    currentPackage._id === item._id &&
                    this.state.value === this.props.profile.accountType
                  }
                >
                  Subscription
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={
                  <Title level={4} className="m-0">
                    {item.name}{' '}
                    <Statistic
                      value={MoneyHelper(item.price).format()}
                      precision={2}
                    />
                  </Title>
                }
                description={item.desc}
              />
            </List.Item>
          ) : (
            <List.Item className="hide" />
          )
        }
      />
    );
  };

  renderPricingView = () => {
    const pick = _.find(this.state.accountData, (o) => {
      return o.id === this.state.value;
    });
    const currentPackage = this.props.profile
      ? this.props.profile.pricing
      : null;
    let columns;
    if (pick)
      columns = _.countBy(pick.pricing, _.iteratee({ status: true })).true;
    // return pick ? this.listPricing(pick, currentPackage) : null;
    return pick ? (
      <Row type="flex" gutter={20} style={{ marginTop: 20 }}>
        {pick.pricing.map((item, i) =>
          item.status ? (
            <Col span={24 / columns} key={i}>
              <Card
                hoverable
                className="box-package"
                actions={[
                  <Button
                    loading={this.state.loading}
                    onClick={() => this.onSubscript(item, pick._id)}
                    type="primary"
                    className="btn-stylist"
                    disabled={
                      currentPackage &&
                      currentPackage._id === item._id &&
                      this.state.value === this.props.profile.accountType
                    }
                  >
                    Subscription
                  </Button>,
                ]}
              >
                <Title level={4}>
                  <Text type="secondary">{item.name}</Text>
                </Title>
                {item.type !== 'forever' ? (
                  <Text className="price-show" strong>
                    {MoneyHelper(item.price).format()}
                  </Text>
                ) : null}
                {item.desc ? (
                  <Title level={4}>
                    <Text type="secondary">{item.desc}</Text>
                  </Title>
                ) : null}
              </Card>
            </Col>
          ) : null
        )}
      </Row>
    ) : null;
  };

  payInvoice = () => {
    PaymentService()
      .getInvoicesOpen()
      .then((res) => {
        let pay = _.first(res.data);
        window.open(pay.hosted_invoice_url, '_blank');
      });
  };

  render() {
    const profile = this.props.profile || {};
    const currentPackage = profile ? profile.pricing : null;
    return (
      <div className="billing-container">
        <InputCard
          afterClose={() =>
            profile.card
              ? this.onSubscript(this.state.waitPack, this.state.waitIdPackage)
              : null
          }
          close={() => this.setState({ showAddCard: false })}
          visible={this.state.showAddCard}
        />
        <Row gutter={20}>
          <Col span={14}>
            <Title className="m-0" level={3}>
              <FaCubes className="mr-2 text-secondary" />
              Pricing
            </Title>
            <Divider />
            {profile.subscript &&
            profile.subscript.status !== 'active' &&
            profile.subscript.status !== 'canceled' ? (
              <Result
                status="warning"
                title={
                  'Subscription ' +
                  StringHelper().underscoresToSpace(profile.subscript.status)
                }
                subTitle={
                  'Your subscription is ' +
                  StringHelper().underscoresToSpace(profile.subscript.status) +
                  ' you can following information to canceled or repayment'
                }
                extra={[
                  <Button
                    loading={this.state.loading}
                    onClick={this.cancelSubscript}
                    type="danger"
                  >
                    Canceled
                  </Button>,
                  <Button
                    loading={this.state.loading}
                    onClick={this.payInvoice}
                    type="primary"
                  >
                    Repay
                  </Button>,
                ]}
              />
            ) : (
              <React.Fragment>
                <Radio.Group onChange={this.onChange} value={this.state.value}>
                  <Radio
                    value={AccountType.Basic}
                    className="basic"
                    style={{ backgroundColor: AccountTypeColor.Basic }}
                  >
                    <span className="title">Basic</span>
                  </Radio>
                  <Radio
                    value={AccountType.Personal}
                    className="person"
                    style={{ backgroundColor: AccountTypeColor.Personal }}
                  >
                    <span className="title">Personal</span>
                  </Radio>
                  <Radio
                    value={AccountType.Pro}
                    className="pro"
                    style={{ backgroundColor: AccountTypeColor.Pro }}
                  >
                    <span className="title">Pro</span>
                  </Radio>
                </Radio.Group>
                {this.state.accountData ? this.renderPricingView() : null}
              </React.Fragment>
            )}
          </Col>
          <Col span={10}>
            <Title className="m-0" level={3}>
              <FaCcStripe className="mr-2 text-secondary" />
              Credit Card Info
            </Title>
            <Divider />
            <Payment />
          </Col>
        </Row>
        <TyneriInvoices
          afterLoad={() => this.setState({ reloadBilling: false })}
          reload={this.state.reloadBilling}
          package={currentPackage}
        />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    saveCompany: (company) => {
      dispatch(saveCompany(company));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
