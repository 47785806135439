import React, { useEffect, useState } from "react";
import { Row, Col, Upload } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { EditFilled } from "@ant-design/icons";

import FormPassword from "./form-password";
import UserInfo from "./userInfo/userInfo";
import CompanyInfo from "./companyInfo/companyInfo";
import Image from "../../../../functions/image";
import { AccountData } from "../../../../params/AccountType";
import { saveProfile, saveCompany } from "../../../../store/actions";
import ApiService from "../../../../services/userApi.service";
import CompanyApiService from "../../../../services/companyApi.service";
import { configBaseUrl } from "../../../../config";

import "./index.scss";

function MyProfile(props) {
  const [profile, setProfile] = useState(null);
  const [company, setCompany] = useState(null);
  const apiService = ApiService();
  const companyService = CompanyApiService();
  const [loadingUploadAvatar, setLoadingUploadAvatar] = useState(false);
  const [loadingUploadBanner, setLoadingUploadBanner] = useState(false);
  const [loadingCompanyLogo, setLoadingCompanyLogo] = useState(false);
  const [accountData, setAccountData] = useState([]);

  useEffect(() => {
    if (props.profile) {
      setProfile(props.profile);
    }
    if (props.company) {
      setCompany(props.company);
    }
    async function fetchData() {
      setAccountData(await AccountData());
    }
    fetchData();
  }, [props.profile, props.company]);

  if (profile === null) return null;

  async function uploadAvatar({ onError, onSuccess, file }) {
    const formData = new FormData();
    formData.append("avatar", file);
    setLoadingUploadAvatar(true);
    try {
      const response = await apiService.uploadAvatar(formData);
      props.saveProfile({
        ...profile,
        avatar: response.path,
      });
    } catch (e) {
      onError(e);
    }
    setLoadingUploadAvatar(false);
  }

  async function uploadBanner({ onError, onSuccess, file }) {
    const formData = new FormData();
    formData.append("banner", file);
    setLoadingUploadBanner(true);
    try {
      const response = await apiService.uploadBanner(formData);
      props.saveProfile({
        ...profile,
        banner: response.path,
      });
    } catch (e) {
      onError(e);
    }
    setLoadingUploadBanner(false);
  }

  async function uploadCompanyLogo({ onError, onSuccess, file }) {
    const formData = new FormData();
    formData.append("logo", file);
    setLoadingCompanyLogo(true);
    try {
      const response = await companyService.uploadLogo(formData);
      props.saveCompany({
        ...company,
        logo: response.path,
      });
    } catch (e) {
      onError(e);
    }
    setLoadingCompanyLogo(false);
  }
  return (
    <div className="container-profile">
      <Row>
        <Col xs={24} sm={24} md={8} lg={8} xl={7} className=" left-content">
          <div className="children">
            <div className="basic-info">
              <div className="content-children">
                <h4 className="name">
                  {profile.firstName} {profile.lastName}
                </h4>
                <div className="avatar-container">
                  <Upload
                    showList={false}
                    showUploadList={false}
                    customRequest={uploadAvatar}
                  >
                    <Image
                      src={configBaseUrl.baseURL + profile.avatar}
                      loadingUpload={loadingUploadAvatar}
                      avatarDefault={true}
                    />
                    <EditFilled />
                  </Upload>
                </div>
                <div className="account-type">
                  {accountData.length > 0 ? (
                    <span
                      className="account-badge"
                      onClick={() => props.history.push("/billing")}
                      style={{
                        backgroundColor: accountData.find(
                          (element) => element.id === profile.accountType
                        ).color,
                      }}
                    >
                      {
                        accountData.find(
                          (element) => element.id === profile.accountType
                        ).name
                      }
                    </span>
                  ) : null}
                </div>
                <FormPassword />
              </div>
            </div>
            <UserInfo profile={profile} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={17} className="right-content">
          <div className="children">
            <div className="content">
              <div className="banner">
                <Upload
                  showList={false}
                  showUploadList={false}
                  customRequest={uploadBanner}
                >
                  <Image
                    src={configBaseUrl.baseURL + profile.banner}
                    loadingUpload={loadingUploadBanner}
                  />
                  <EditFilled />
                </Upload>
              </div>
              <CompanyInfo
                profile={profile}
                company={company}
                uploadCompanyLogo={uploadCompanyLogo}
                loadingCompanyLogo={loadingCompanyLogo}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    saveCompany: (company) => {
      dispatch(saveCompany(company));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
    company: state.profileReducer.company,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
