import React, {useEffect, useState} from "react";
import Payment from "../../../../../services/payment.service";
import MoneyHelper from "../../../../../helper/moneyHelper";
import {Icon, Popover, Table, Tag, Typography} from "antd";
import _ from "lodash";
import styles from "./invoice.scss";

const {Paragraph, Text} = Typography;

const ExpandedRowRender = ({record, ...props}) => {
    const [pi, setPi] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData () {
            if (record.payment_intent) {
                const pi = await Payment().getPaymentIntent(record.payment_intent);
                setPi([pi]);
            }
            setLoading(false);
        }
        fetchData();
    }, [record]);

    const columns = [
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, row) => {
                let charge = _.first(row.charges.data);
                let popoverContent;
                let popoverTitle;
                let color;
                let tagText = row.status.toUpperCase();
                let icon = <Icon className="d-inline-flex" type="check" />;
                if (row.status === 'canceled' || row.status === 'requires_payment_method') {
                    popoverContent = 'Payment has canceled';
                    icon = <Icon className="d-inline-flex" type="close" />;
                    color = 'grey';
                }
                if (row.status === 'requires_action') {
                    popoverContent = 'The customer must complete an additional authentication step';
                    tagText = 'incomplete'.toUpperCase();
                    color = 'grey';
                    icon = <Icon className="d-inline-flex" type="clock-circle" />;
                }
                if (row.status === 'requires_payment_method') {
                    if (_.isEmpty(charge)) {
                        popoverContent = 'The customer hasn\'t attempted to pay this invoice yet';
                        tagText = 'incomplete'.toUpperCase();
                        icon = <Icon className="d-inline-flex" type="clock-circle" />;
                    }else {
                        tagText = charge.outcome.type.toUpperCase();
                        icon = <Icon className="d-inline-flex" type="stop" />;
                    }
                }
                if (!_.isEmpty(charge)) {
                    if (charge.status === 'succeeded') {
                        popoverContent = <Text>{charge.outcome.seller_message} <a href={charge.receipt_url}  rel="noopener noreferrer" target="_blank">Receipt</a></Text>;
                        color = '#87d068';
                    }
                    if (row.status === 'canceled' || row.status === 'requires_payment_method') {
                        popoverContent = 'The bank return decline code '+charge.outcome.reason;
                        popoverTitle = <React.Fragment><Icon className="d-inline-flex text-danger" type="stop" /> {charge.failure_message}</React.Fragment>;
                    }
                }
                if (row.last_payment_error && row.last_payment_error.message) {
                    popoverContent = row.last_payment_error.message;
                }
                let tag = <Popover content={popoverContent} title={popoverTitle}>
                    <Tag color={color}>{tagText}{icon}</Tag>
                </Popover>;
                return <Paragraph style={{margin: 0}}>{MoneyHelper(text / 100).format()} {tag}</Paragraph>;
            }
        },
        {
            title: 'Detail',
            dataIndex: 'charges.data',
            render: list => {
                const columns = [
                    {
                        title: 'Name',
                        dataIndex: 'billing_details.name',
                        key: "name"
                    },
                    {
                        title: 'Phone',
                        dataIndex: 'billing_details.phone',
                        key: "phone"
                    },
                    {
                        title: 'Email',
                        dataIndex: 'billing_details.email',
                        key: "email"
                    },
                    {
                        title: 'Card',
                        dataIndex: 'payment_method_details.card.last4',
                        key: 'card',
                        render: text => {
                            return <Text code>
                                <div className="code-card">
                                    <Icon type="credit-card" /> •••• {text}
                                </div>
                            </Text>;
                        }
                    }
                ];
                return <Table showHeader={false} size="small" pagination={false} columns={columns} dataSource={list} rowKey={(record1 => record1.id)}/>
            }
        }
    ];

    return <Table loading={loading} size="middle" className={styles.tableCustom} pagination={false} columns={columns} dataSource={pi} rowKey={(record1, index) => record1.id + '_' + index}/>
}

export default ExpandedRowRender;
