import React, { useEffect, useState } from "react";
import LodashHelper from "../../helper/lodashHelper";
import { getFlag } from "../../services/utils.service";
import { ItemView, ItineraryDetailOption } from "../index";
import { Timeline } from "react-event-timeline";
import ApiService from "../../services/api.service";
import { categoriesDefined } from "../../constants/categories";
import _ from "lodash";

const ListPlace = (props) => {
  const [isPdf, setIsPdf] = useState(true);
  const [categories, setCategories] = useState([]);
  const apiService = ApiService();

  useEffect(() => {
    if (props.location) setIsPdf(false);
    apiService.getAttractionCategories().then((res) => {
      if (res.status === "success") {
        setCategories(res.categories);
      }
    });
  }, [props.location]);

  const renderListPlaces = (item) => {
    let countIndex = 0;
    return item.place_detail.map((place, index) => {
      var temp = _.find(categories, (category) => {
        return category._id === place.category;
      });
      if (temp.name !== categoriesDefined.INFORMATION) countIndex++;
      return (
        <ItemView
          isPdf={isPdf}
          preview={true}
          key={index}
          index={countIndex}
          items={item.place_detail}
          item={place}
          categoryName={temp.name}
          currency={props.itinerary.currency}
          length={item.place_detail.length}
        />
      );
    });
  };

  return (
    !!props.listAllDates.length &&
    props.listAllDates.map((item, key) => {
      const currentDetail = LodashHelper.loopFind(
        "alpha2Code",
        "alpha2Code",
        item.alpha2Code,
        props.itinerary.itinerary_detail
      )[0];
      return (
        <div key={key} id={`${item._id}-1`}>
          <h2 className="day-title">
            <span className="each-title-day">
              Day {key + 1}
              {item.title ? ": " + item.title : null}
            </span>
            <span className="each-title-location">
              {currentDetail ? (
                <img
                  src={getFlag(currentDetail.alpha2Code)}
                  alt={item.country}
                />
              ) : null}
              {item.city ? `${item.city}, ${item.country}` : `${item.country}`}
            </span>
          </h2>
          <ItineraryDetailOption
            lodging={item.lodging}
            amountMeals={item.amountMeals}
            meal={item.meal}
          />
          {item.description && (
            <div className="overview-itinerary-detail trix-content-temp">
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          )}
          {!!item.place_detail.length && !!categories.length && (
            <Timeline
              lineColor={`#e1eef5`}
              className={
                item.place_detail.length > 1
                  ? "timeline-event"
                  : "timeline-event-1"
              }
            >
              {renderListPlaces(item)}
            </Timeline>
          )}
        </div>
      );
    })
  );
};

export default ListPlace;
