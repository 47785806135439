import React, { useState, useEffect } from "react";
import { Button, Modal, Empty, Pagination } from "antd";
import ContactApi from "../../../../services/contactApi.service";
import Api from "../../../../services/api.service";
import { connect } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import FormContact from "./form";
import "./index.scss";
import styles from "../../../../components/list-my-tyneries/index.scss";
import queryString from "query-string"

function Contacts(props) {
  const contactApi = ContactApi();
  const api = Api();
  const [listData, setListData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [contact, setContact] = useState({});
  const [countries, setCountries] = useState([]);
  const [page, setPage] = useState(1);
  const [paramLimit, setParamLimit] = useState(10);

  useEffect(() => {
    if (props.profile) {
      setData();
      api.getAllCountry().then((data) => {
        setCountries(data.countries);
      });
    }
  }, [props.profile, props.location.search]);

  const setData = async () => {
    const params = queryString.parse(props.location.search);
    setParamLimit(params.limit || 10)
    setPage(+params.page || 1)
    await contactApi.getList(params.page || 1, params.limit || 10).then((res) => {
      setListData(res.data);
      setTotalPage(res.total);
    });
  };

  const getNameCountry = (item) => {
    const countryExist = countries.filter(
      (country) => country._id === item.country
    );
    if (countryExist[0]) {
      return countryExist[0].name;
    }
    return "";
  }

  const deleteContact = (item) => {
    const TitleConfirm = () => (
      <>
      Are you sure you want to delete this contact?
      <strong style={{color: '#FFAF11'}}> {item.email}</strong>
      </>
    )
    Modal.confirm({
      content: <TitleConfirm/>,
      okText: "Delete",
      okType: 'danger',
      cancelText: "Cancel",
      onOk: async () => {
        await contactApi.delete(item._id);
        await setData();
      },
      onCancel() {},
    });
  }

  function ListContacts(props) {
    return (
      <Table className={styles.listMyTyneries}>
        <Thead>
          <Tr>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Email</Th>
            <Th>Phone</Th>
            <Th>Group</Th>
            <Th>Country</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.listData.length > 0 ? (
            props.listData.map((item, key) => (
              <Tr className="body-row" key={key}>
                <Td>{item.firstName}</Td>
                <Td>{item.lastName}</Td>
                <Td>{item.email}</Td>
                <Td>{item.phone}</Td>
                <Td>{item.group ? item.group.name : ""}</Td>
                <Td>{getNameCountry(item)}</Td>
                <Td>
                  <div className="td-action">
                    <Button
                      type="primary"
                      onClick={() => {
                        setOpenForm(true);
                        setContact(item);
                      }}
                    >
                      Edit
                    </Button>
                    <Button type="danger" onClick={ () => deleteContact(item)}>
                        Delete
                      </Button>
                  </div>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr className="body-row">
              <Td colSpan="7">
                <Empty />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    );
  }

  return (
    <div className="contacts-container">
      <FormContact
        visible={openForm}
        contact={contact}
        countries={countries}
        close={() => setOpenForm(false)}
        loadPage={() => {
          setData();
        }}
      />
      <ListContacts listData={listData} />
      <Pagination
        style={{ float: "right", marginTop: "1rem" }}
        pageSize={+paramLimit}
        current={page}
        total={totalPage}
        onChange={(page) => {
          setPage(page);
          const params = queryString.parse(props.location.search);
          let limit = params.limit ? "&limit=" + params.limit : "&limit=" + 10;
          props.history.push(`/contacts?page=${page}` + limit);
        }}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
