import React, { Component } from "react";
import ApiService from "../../services/api.service";
import { Row, Col, Container } from "reactstrap";
import "./index.scss";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import ErrorIcon from "@material-ui/icons/Error";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FormHelperText from "@material-ui/core/FormHelperText";
import history from "../history";
import { Upload, Button, Icon } from "antd";
import FormLabel from "@material-ui/core/FormLabel";
import { isMyResource, getBase64FromAvatar } from "../../services/utils.service";
import { saveProfile } from "../../store/actions";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { configBaseUrl } from "../../config" 
const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      isLoading: true,
      fullName: "",
      firstName: "",
      lastName: "",
      email: "",
      currentPassword: "",
      newPassword: "",
      retypePassword: "",
      existPassword: false,
      currentPasswordMatch: true,
      changePasswordSuccess: false,
      createNewPasswordSuccess: false,

      isLoadingSaveInfor: false,
      avatar: "",
      fileAvatar: "",
      fileBanner: "",
      banner: ""
    };
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      const data = this.state.newPassword;
      if (value !== data) {
        return false;
      }
      return true;
    });
    if (this.props.profile) {
      this.apiService.checkExistPassword(this.props.profile._id).then((res) => {
        if (res.status === "success") {
          this.setState({
            existPassword: res.exist,
            isLoading: false,
          });
        }
      });
      this.setState({
        isLoading: false,
        firstName: this.props.profile.firstName,
        lastName: this.props.profile.lastName,
        avatar: this.props.profile.avatar,
        banner: configBaseUrl.baseURL + this.props.profile.banner,
        email: this.props.profile.email,
      });
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.profile) {
      this.apiService.checkExistPassword(nextProps.profile._id).then((res) => {
        if (res.status === "success") {
          this.setState({
            existPassword: res.exist,
            isLoading: false,
          });
        }
      });
      this.setState({
        isLoading: false,
        firstName: nextProps.profile.firstName,
        lastName: nextProps.profile.lastName,
        avatar: nextProps.profile.avatar,
        banner: nextProps.profile.banner ? configBaseUrl.baseURL + nextProps.profile.banner : nextProps.profile.banner,
        email: nextProps.profile.email,
      });
    } else {
      history.goBack();
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangePassword = () => {
    this.setState({
      currentPasswordMatch: true,
      createNewPasswordSuccess: false,
      changePasswordSuccess: false,
    });
    this.apiService
      .changePassword(
        this.props.profile.email,
        this.state.currentPassword,
        this.state.newPassword
      )
      .then((res) => {
        if (
          res.status === "failed" &&
          res.error === "Current password is wrong! Please try again"
        ) {
          this.setState({
            currentPasswordMatch: false,
          });
        } else if (res.status === "success") {
          this.setState({
            changePasswordSuccess: true,
            currentPassword: "",
            newPassword: "",
            retypePassword: "",
          });
        }
      });
  };

  handleCreateNewPassword = () => {
    this.setState({
      currentPasswordMatch: true,
      createNewPasswordSuccess: false,
      changePasswordSuccess: false,
    });
    this.apiService
      .createPassword(this.props.profile.email, this.state.newPassword)
      .then((res) => {
        if (res.status === "success") {
          this.setState(
            {
              createNewPasswordSuccess: true,
              currentPassword: "",
              newPassword: "",
              retypePassword: "",
            },
            () => {
              this.apiService
                .checkExistPassword(this.props.profile._id)
                .then((res) => {
                  if (res.status === "success") {
                    this.setState({
                      existPassword: res.exist,
                    });
                  }
                });
            }
          );
        }
      });
  };

  handleChangeInfo = () => {
    this.setState({
      isLoadingSaveInfor: true,
    });
    var infor = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      avatar: this.state.fileAvatar,
      banner: this.state.fileBanner
    };
    this.apiService.changeInfor(infor).then((res) => {
      if (res.status === "success") {
        this.props.saveProfile({
          ...this.props.profile,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          avatar: res.avatar,
          banner: res.banner
        });
        this.setState({
          isLoadingSaveInfor: false,
        });
        Swal.fire({
          type: "success",
          title: "Update profile succesfully!",
          showConfirmButton: true
        });
      }
    });
  };

  customUploadAvatar = async ({ onError, onSuccess, file }) => {
    var percentCompleted;
    const formData = new FormData();
    formData.append("avatar", file);
    try {
      this.apiService
        .uploadAvatar(formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((res) => {
          onSuccess(null, res.path);
        });
    } catch (e) {
      onError(e);
    }
  };

  customUploadBanner = async ({ onError, onSuccess, file }) => {
    var percentCompleted;
    const formData = new FormData();
    formData.append("banner", file);
    try {
      this.apiService
        .uploadBanner(formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((res) => {
          onSuccess(null, res.path);
        });
    } catch (e) {
      onError(e);
    }
  };

  handleChangeAvatar = (info) => {
    this.setState({ loadingAvatar: true });
    getBase64FromAvatar(info.file.originFileObj, (image) => {
      this.setState({
        avatar: image,
        fileAvatar: info.file.xhr
      });
    });
  };

  handleChangeBanner = (info) => {
    this.setState({ loadingBanner: true });
    getBase64FromAvatar(info.file.originFileObj, (image) => {
      this.setState({
        banner: image,
        fileBanner: info.file.xhr
      });
    });
  };

  render() {
    const { avatar, banner } = this.state;
    const uploadAvatarButton = (
      <div>
        <Icon type={this.state.loadingAvatar ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const uploadBannerButton = (
      <div>
        <Icon type={this.state.loadingBanner ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return this.state.isLoading ? (
      <div className="loader"></div>
    ) : (
      <React.Fragment>
        <div className="header-form">
          <span>Profile</span>
        </div>
        <Container fluid={true}>
          <div className="contain-form">
            <span>My profile</span>
            <Row>
              <Col xs={6}>
                <FormLabel style={{ fontSize: `12px` }}>Banner</FormLabel>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={this.customUploadBanner}
                  onChange={this.handleChangeBanner}
                >
                  {banner ? (
                    <img src={banner} alt="banner" style={{ width: "100%" }} />
                  ) : (
                    uploadBannerButton
                  )}
                </Upload>
              </Col>
              <Col xs={6}>
                <FormLabel style={{ fontSize: `12px` }}>Avatar</FormLabel>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={this.customUploadAvatar}
                  onChange={this.handleChangeAvatar}
                >
                  {avatar ? (
                    <img src={isMyResource(avatar) ? configBaseUrl.baseURL + avatar : avatar} alt="avatar" style={{ width: "100%" }} />
                  ) : (
                    uploadAvatarButton
                  )}
                </Upload>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <TextField
                  required
                  label="First name"
                  value={this.state.firstName}
                  onChange={this.handleChange("firstName")}
                  margin="normal"
                  fullWidth
                />
              </Col>
              <Col xs="6">
                <TextField
                  required
                  label="Last name"
                  value={this.state.lastName}
                  onChange={this.handleChange("lastName")}
                  margin="normal"
                  fullWidth
                />
              </Col>
              <Col sm="12" md="12">
                <TextField
                  disabled
                  label="Email"
                  value={this.state.email}
                  margin="normal"
                  fullWidth
                />
              </Col>
            </Row>
            <div className="footer-form">
              <Button
                type="primary"
                loading={this.state.isLoadingSaveInfor ? true : false}
                onClick={this.handleChangeInfo.bind(this)}
              >
                Save
              </Button>
            </div>
          </div>
        </Container>
        <Container fluid={true}>
          {this.state.existPassword ? (
            <div className="contain-form">
              <span>Password</span>
              <ValidatorForm onSubmit={this.handleChangePassword}>
                <Row>
                  <React.Fragment>
                    <Col sm="12" md="12">
                      <TextValidator
                        label="Current password *"
                        fullWidth
                        value={this.state.currentPassword}
                        onChange={this.handleChange("currentPassword")}
                        type="password"
                        margin="normal"
                        validators={["required"]}
                        errorMessages={[
                          <span>
                            <ErrorIcon />
                            This field is required
                          </span>,
                        ]}
                      />
                    </Col>
                    <Col sm="12" md="12">
                      <TextValidator
                        fullWidth
                        label="New password *"
                        value={this.state.newPassword}
                        onChange={this.handleChange("newPassword")}
                        type="password"
                        margin="normal"
                        validators={["required"]}
                        errorMessages={[
                          <span>
                            <ErrorIcon />
                            This field is required
                          </span>,
                        ]}
                      />
                    </Col>
                    <Col sm="12" md="12">
                      <TextValidator
                        fullWidth
                        label="Retype new password *"
                        value={this.state.retypePassword}
                        onChange={this.handleChange("retypePassword")}
                        type="password"
                        margin="normal"
                        validators={["required", "isPasswordMatch"]}
                        errorMessages={[
                          <span>
                            <ErrorIcon />
                            This field is required
                          </span>,
                          <span>
                            <ErrorIcon />
                            Those passwords didn't match. Try again
                          </span>,
                        ]}
                      />
                    </Col>
                  </React.Fragment>
                </Row>
                <div className="footer-form">
                  {!this.state.currentPasswordMatch && (
                    <FormHelperText className="Mui-error">
                      Your current password is wrong. Please try again!
                    </FormHelperText>
                  )}
                  {this.state.changePasswordSuccess && (
                    <FormHelperText className="Mui-success">
                      Change password successfully!
                    </FormHelperText>
                  )}
                  {this.state.createNewPasswordSuccess && (
                    <FormHelperText className="Mui-success">
                      Create new password successfully!
                    </FormHelperText>
                  )}
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          ) : (
            <div className="contain-form">
              <span>Password</span>
              <ValidatorForm onSubmit={this.handleCreateNewPassword}>
                <Row>
                  <React.Fragment>
                    <Col sm="12" md="12">
                      <TextValidator
                        fullWidth
                        label="New password *"
                        value={this.state.newPassword}
                        onChange={this.handleChange("newPassword")}
                        type="password"
                        margin="normal"
                        validators={["required"]}
                        errorMessages={[
                          <span>
                            <ErrorIcon />
                            This field is required
                          </span>,
                        ]}
                      />
                    </Col>
                    <Col sm="12" md="12">
                      <TextValidator
                        fullWidth
                        label="Retype new password *"
                        value={this.state.retypePassword}
                        onChange={this.handleChange("retypePassword")}
                        type="password"
                        margin="normal"
                        validators={["required", "isPasswordMatch"]}
                        errorMessages={[
                          <span>
                            <ErrorIcon />
                            This field is required
                          </span>,
                          <span>
                            <ErrorIcon />
                            Those passwords didn't match. Try again
                          </span>,
                        ]}
                      />
                    </Col>
                  </React.Fragment>
                </Row>
                <div className="footer-form">
                  {this.state.createNewPasswordSuccess && (
                    <FormHelperText className="Mui-success">
                      Create new password successfully!
                    </FormHelperText>
                  )}
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
