/*global google*/
import React, { Component } from "react";
import { StarRating } from "../../components";
import { Container } from "reactstrap";

import styles from "./index.scss";
import { connect } from "react-redux";
import {
  openFormAddNewStop,
  updateListItineraryDetail,
  updateListPlacesExplore,
  reloadPdf,
  unReloadPdf,
  saveProfile,
  openFormShareByEmail,
  openFormLoad,
  closeFormLoad,
} from "../../store/actions";
import "sweetalert2/src/sweetalert2.scss";
import ApiService from "../../services/api.service";
import { FaMapMarkerAlt, FaPhoneSquare } from "react-icons/fa";
import { Avatar, Icon, List, Typography, Collapse, Row, Col } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getImageFromUrl, isMyResource, getNameAuthor } from "../../services/utils.service";
import { configBaseUrl } from "../../config";
import moment from "moment";
import { Link } from "react-router-dom";
import { loadItem } from "../../services/storage.service";
import defaultImg from "../../assets/media/images/generic-activities-img.jpg";
import { ErrorPage } from "../../pages";
import { compareDesc } from "date-fns";
import LogoGG from "../../assets/media/images/google.png";
import { isBrowser } from "react-device-detect";
import MyBreadcrumb from "../breadcrumb";

const listData = [];
for (let i = 0; i < 23; i++) {
  listData.push({
    href: "http://ant.design",
    title: `ant design part ${i}`,
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    description:
      "Ant Design, a design language for background applications, is refined by Ant UED Team.",
    content:
      "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
  });
}
const { Title, Text } = Typography;
const { Panel } = Collapse;

const categories = [
  {
    name: "Attraction",
    value: "Attraction",
  },
  {
    name: "Accommodation",
    value: "Lodging",
  },
  {
    name: "Restaurant",
    value: "Restaurant",
  },
  {
    name: "Tour & activity",
    value: "Activity",
  },
  {
    name: "Transportation",
    value: "Transportation",
  },
  {
    name: "Infor",
    value: "Infor",
  },
];

const mapDispatchToProps = (dispatch) => {
  return {
    updateListItineraryDetail: (typeUpdate) => {
      dispatch(updateListItineraryDetail(typeUpdate));
    },
    updateListPlacesExplore: (list) => {
      dispatch(updateListPlacesExplore(list));
    },
    reloadPdf: () => {
      dispatch(reloadPdf());
    },
    unReloadPdf: () => {
      dispatch(unReloadPdf());
    },
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    openFormAddNewStop: () => {
      dispatch(openFormAddNewStop());
    },
    openFormShareByEmail: () => {
      dispatch(openFormShareByEmail());
    },
    openFormLoad: () => {
      dispatch(openFormLoad());
    },
    closeFormLoad: () => {
      dispatch(closeFormLoad());
    },
  };
};

const mapStateToProps = (state) => {
  return {};
};

class ContentAttraction extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      showCreateItineraryForm: false,
      list_optionals: [],
      reviews: [],
      rating: 0,
      user_ratings_total: 0,
    };
  }

  SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div {...props}>
      <Icon type="left" />
    </div>
  );
  SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div {...props}>
      <Icon type="right" />
    </div>
  );

  componentDidMount = () => {
    this.apiService.getAllOptionals().then((res) => {
      if (res.status === "success") {
        this.setState({
          list_optionals: res.list_optionals,
        });
      }
    });

    if (this.props.item) {
      if (this.props.item.data.address) {
        var place = {
          lat: this.props.item.data.lat,
          lng: this.props.item.data.lng,
        };
        var map = new google.maps.Map(document.getElementById("map"), {
          zoom: 18,
          center: place,
        });
        var marker = new google.maps.Marker({
          position: place,
          map: map,
        });
      }

      var list = document.querySelectorAll("div.img-fluid[data-image]");

      for (var i = 0; i < list.length; i++) {
        var url = list[i].getAttribute("data-image");
        list[i].style.backgroundImage = "url('" + url + "')";
      }

      var request = {
        placeId: this.props.item.place_id,
        fields: [
          "address_component",
          "adr_address",
          "formatted_address",
          "geometry",
          "icon",
          "name",
          "photo",
          "place_id",
          "plus_code",
          "type",
          "url",
          "utc_offset_minutes",
          "vicinity",
          "formatted_phone_number",
          "international_phone_number",
          "opening_hours",
          "website",
          "price_level",
          "rating",
          "review",
          "user_ratings_total",
        ],
      };

      var service = new google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails(request, (place, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          if (place.reviews) {
            place.reviews.sort((data_A, data_B) => {
              return compareDesc(
                new Date(data_A.time * 1000),
                new Date(data_B.time * 1000)
              );
            });
            this.setState({
              reviews: place.reviews,
              rating: place.rating,
              user_ratings_total: place.user_ratings_total,
            });
          }
        }
      });
    }
  };

  closeCreateItineraryForm = () => {
    this.setState({
      showCreateItineraryForm: false,
    });
  };

  showFormCreateItinerary = () => {
    this.setState({
      showCreateItineraryForm: true,
    });
  };

  render() {
    const { item } = this.props;
    var settings = {
      dots: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      arrow: true,
      nextArrow: <this.SlickArrowRight />,
      prevArrow: <this.SlickArrowLeft />,
    };

    let attractionSeen =
      loadItem("attractionSeen") !== null ? loadItem("attractionSeen") : null;

    return (
      <div id="content" className={styles.contentAttraction}>
        <div className="container">
          <Row>
            <Col
                xs={24}
                md={14}
                lg={16}
                xl={16} xxl={16}
                id="left-column"
            >
              {!item ? (
                  <ErrorPage />
              ) : (
                  <>
                    <div className="grid-header bread">
                      <MyBreadcrumb list={[
                        {link: '/', title: 'Home'},
                        {link: '/top-attractions', title: 'Top attractions'},
                        {title: item.data.title}
                      ]} />
                    </div>

                    {item.data.images && !!item.data.images.length && (
                        <div className={"cardList"}>
                          <Slider {...settings}>
                            {item.data.images.map((item, key) => (
                                <div
                                    className="img-fluid"
                                    key={key}
                                    data-image={getImageFromUrl(
                                        isMyResource(item.preview)
                                            ? `${configBaseUrl.baseURL + item.preview}`
                                            : `${item.preview}`,
                                        "large"
                                    )}
                                ></div>
                            ))}
                          </Slider>
                        </div>
                    )}
                    <h2 className="title-attraction">{item.data.title}</h2>
                    <p className="recommend-by">
                      By {item.user_infor.firstName} {item.user_infor.lastName} -{" "}
                      {moment(new Date(item.created_at)).fromNow()}
                    </p>
                    <div className="content trix-content-temp">
                      <div
                          dangerouslySetInnerHTML={{
                            __html: item.data.descriptions,
                          }}
                      ></div>
                    </div>
                    {item.quote && (
                        <blockquote>
                          <div className="quote">{item.quote}</div>
                        </blockquote>
                    )}
                    {(item.data.address || item.data.phone_number) && (
                        <p className="location">Location</p>
                    )}
                    <div
                        style={{
                          display: `flex`,
                          flexDirection: `column`,
                        }}
                    >
                      {item.data.address && (
                          <div className="info-summary">
                            <FaMapMarkerAlt />
                            <div>{item.data.address}</div>
                          </div>
                      )}
                      {item.data.phone_number && (
                          <div className="info-summary">
                            <FaPhoneSquare />
                            <div>{item.data.phone_number}</div>
                          </div>
                      )}
                    </div>
                    {item.data.address && <div id="map"></div>}
                    <div className="section-listed">
                      <ul className="listed-item">
                        {!!item.listed_in.length &&
                        item.listed_in.map((child, key) => {
                          return (
                              Object.keys(child).length > 0 && (
                                  <Link
                                      key={key}
                                      to={`/view-itinerary/${child._id}`}
                                  >
                                    <li className="each-listed-item" key={key}>
                                      <div className="each-listed-item-content">
                                        {/* <div className="rating">
                                      4.7 <StarRating ratingAverage={4.5} />
                                    </div> */}
                                        <div className="name">
                                          {child.name}{" "}
                                          {`${
                                              child.estimated_budget &&
                                              `on a $${child.estimated_budget} USD budget for ${child.a_number_of_paxs} pax`
                                          }`}
                                        </div>
                                        <div className="recommend">
                                          By {child.user_infor.firstName}{" "}
                                          {child.user_infor.lastName} -{" "}
                                          {moment(
                                              new Date(child.last_updated)
                                          ).fromNow()}
                                        </div>
                                      </div>
                                      <div
                                          className="img-fluid small"
                                          style={{
                                            backgroundImage: `url(${
                                                child.cover
                                                    ? getImageFromUrl(
                                                    isMyResource(child.cover)
                                                        ? `${
                                                            configBaseUrl.baseURL +
                                                            child.cover
                                                        }`
                                                        : `${child.cover}`,
                                                    "large"
                                                    )
                                                    : defaultImg
                                            })`,
                                          }}
                                      ></div>
                                    </li>
                                  </Link>
                              )
                          );
                        })}
                      </ul>
                    </div>
                    {this.state.reviews && !!this.state.reviews.length && (
                        <div className="section-reviews">
                          <p style={{ fontSize: "2rem", paddingBottom: "1rem" }}>
                            Reviews
                          </p>
                          <div>
                            <span style={{ paddingRight: "0.5rem" }}>On</span>
                            <img style={{ height: `28px` }} src={LogoGG} />
                          </div>

                          {/* <div style={{ display: `contents` }}>
                        <span className="point-rating">
                          {this.state.rating}
                        </span>
                        <StarRating ratingAverage={this.state.rating} />/{" "}
                        {this.state.user_ratings_total}
                      </div> */}
                          <List
                              itemLayout="vertical"
                              size="large"
                              dataSource={this.state.reviews}
                              renderItem={(item) => (
                                  <List.Item key={item.title}>
                                    <List.Item.Meta
                                        style={{ marginBottom: 0 }}
                                        avatar={
                                          <Avatar
                                              size={64}
                                              src={item.profile_photo_url}
                                          />
                                        }
                                        title={
                                          <>
                                            <a href={item.author_url}>
                                              {item.author_name}
                                            </a>
                                            <div>
                                              <StarRating ratingAverage={item.rating} />
                                            </div>
                                            <span
                                                style={{
                                                  fontSize: "14px",
                                                  color: `#9a9a9a`,
                                                }}
                                            >
                                    {item.relative_time_description}
                                  </span>
                                          </>
                                        }
                                    />
                                    <span style={{ fontSize: "1rem" }}>
                              {item.text}
                            </span>
                                  </List.Item>
                              )}
                          />
                        </div>
                    )}
                  </>
              )}
            </Col>

            <Col
                id="right-column"
                xs={24}
                md={10}
                lg={8}
                xl={8}
                className={"content-right " + (!isBrowser ? "hide" : null)}
                style={{
                  background: "#e1eef5"
                }}
            >
              <div
                  style={{ width: "100%" }}
                  id="breadcrumb"
                  className={styles.breadcrumb}
              >
                <Container fluid={true}>
                  <Row>
                    <Col>
                      <div>Your recently viewed attractions</div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="section-history" style={{ width: "100%" }}>
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={
                      attractionSeen ? attractionSeen.attractions : []
                    }
                    renderItem={(item) => (
                        <List.Item key={item._id}>
                          <Row
                              style={{
                                padding: `1rem 0`,
                                background: `#fff`,
                                margin: 0
                              }}
                          >
                            {item.category !== "Infor" && (
                                <Col xs="3">
                                  <div
                                      className="img-fluid small"
                                      style={{
                                        backgroundImage: `url(${
                                            !!item.data.images.length
                                                ? getImageFromUrl(
                                                isMyResource(
                                                    item.data.images[0].preview
                                                )
                                                    ? `${
                                                        configBaseUrl.baseURL +
                                                        item.data.images[0].preview
                                                    }`
                                                    : `${item.data.images[0].preview}`,
                                                "large"
                                                )
                                                : defaultImg
                                        })`,
                                      }}
                                  ></div>
                                </Col>
                            )}

                            <Col xs="9">
                              {/* <div>
                              4.7 <StarRating ratingAverage={4.5} />
                            </div> */}
                              <Title level={4} className="title-attraction">
                                <Link to={`/attraction/${item._id}`}>
                                  {item.data.title}
                                </Link>
                              </Title>
                              <Text>
                                <div className="author">
                                  By {item.user_infor.firstName}{" "}
                                  {item.user_infor.lastName} -{" "}
                                  {moment(new Date(item.last_updated)).fromNow()}
                                </div>
                                {/* <div>
                                Seen{" "}
                                {moment(new Date(item.last_seen)).fromNow()}
                              </div> */}
                              </Text>
                            </Col>
                          </Row>
                        </List.Item>
                    )}
                />
              </div>
              {/* <Row>
                <div className="grid-header small">
                  <span>You may also be interested in</span>
                </div>
                <Row className="each-row-itineraries">
                  <List
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                      onChange: (page) => {
                        console.log(page);
                      },
                      pageSize: 10,
                    }}
                    dataSource={listData}
                    renderItem={(item) => (
                      <List.Item key={item.title}>
                        <Row>
                          <Col xs="12" xl="3">
                            <div
                              className="img-fluid"
                              style={{
                                backgroundImage: `url("https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png")`,
                              }}
                            ></div>
                          </Col>
                          <Col xs="12" xl="9">
                            <Title level={4}>{item.title}</Title>
                            <Text>{item.description}</Text>
                            <div className="content-summary">
                              {item.content}
                            </div>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Row>
              </Row> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentAttraction);
