import React, { Component } from "react";
import { Row, Collapse, Checkbox, Col } from "antd";
const { Panel } = Collapse;


class Filter extends Component {
  renderCustomPanelItem = (title, item, key) => {
    return (
      <div className="optional" key={key}>
        <Collapse
          className="custom-collapse-child"
          expandIconPosition={"right"}
        >
          <Panel header={title} key={key}>
            {item.detail.map((child, index) => (
              <p key={index}>
                <Checkbox key={index}>{child.name}</Checkbox>
              </p>
            ))}
          </Panel>
        </Collapse>
      </div>
    );
  };

  renderFilterType() {
    if (this.props.list_optionals && !!this.props.list_optionals.length) {
      let filterHtml = [];
      this.props.list_optionals.forEach((item, key) => {
        if (item.group_name === "pref") {
          filterHtml.push(
            this.renderCustomPanelItem("Activity types", item, key)
          );
        }
        if (item.group_name === "plan") {
          filterHtml.push(
            this.renderCustomPanelItem("Types of group", item, key)
          );
        }
      });
      return filterHtml;
    }
    return null;
  }
  render() {
    return (
      <Row>
        <Col>
          <Collapse className="collapse-filter" expandIconPosition={"right"}>
            <Panel header="Filter" key="1">
              <Collapse
                className="custom-collapse-child"
                expandIconPosition={"right"}
                defaultActiveKey={["1"]}
              >
                <Panel
                  header={`Countries (${this.props.listCountries.length})`}
                  key="1"
                >
                  <div className="list-scroll-countries-filter">
                    {this.props.listCountries &&
                      !!this.props.listCountries.length &&
                      this.props.listCountries.map((item, key) => (
                        <p key={key}>
                          <Checkbox>{`${item.name} (${item.listItineraries.length})`}</Checkbox>
                        </p>
                      ))}
                  </div>
                </Panel>
              </Collapse>
              {this.renderFilterType()}
            </Panel>
          </Collapse>
        </Col>
      </Row>
    );
  }
}

export default Filter;
