import React, { useEffect, useState } from 'react';

import * as QuotationApi from '../../api/quotation';
import ErrorPage from '../../pages/error';
import TokenForm from './tokenForm';

import FormConfirm from './confirmForm';
import './index.scss';

function QuotationConfirm(props) {
  const [loading, setLoading] = useState(true);
  const [exist, setExist] = useState(true);
  const [tokenExist, setTokenExist] = useState(false);
  const [token, setToken] = useState(null);
  const quotationId = props.match.params.quotationId;

  useEffect(() => {
    QuotationApi.checkExist(quotationId).then((res) => {
      if (!res.error && res.exist) {
        setExist(true);
      } else {
        setExist(false);
      }
      setLoading(false);
    });
  }, []);

  if (!exist) return <ErrorPage />;

  function Content() {
    if (loading) return null;

    if (!tokenExist)
      return <TokenForm setTokenExist={setTokenExist} setToken={setToken} />;

    return (
      <FormConfirm
        quotationId={quotationId}
        token={token}
        setLoading={setLoading}
      />
    );
  }

  return <div className="quotation-confirm">{Content()}</div>;
}

export default QuotationConfirm;
