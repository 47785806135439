/*global google*/
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  FormFeedback,
  CustomInput,
} from 'reactstrap';

import { Select } from 'antd';

import 'react-datepicker/dist/react-datepicker.css';
import { dateToTimestamp } from '../../services/utils.service';
import ApiService from '../../services/api.service';
import styles from './index.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MultiDayCalendar } from '../../components';
import { loadItem, saveItem } from '../../services/storage.service.js';
import { ObjectID } from 'bson';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/md';
import { Collapse } from 'antd';
import $ from 'jquery';
import { isBrowser, isTablet } from 'react-device-detect';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { maxCharacters } from '../../constants/itinerary';
import { WarningFilled } from '@ant-design/icons';
import { config } from '../../config';
const { Panel } = Collapse;
const { Option } = Select;

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

class CreateItineraryForm extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.autocompleteMutiInput = [];
    this.state = {
      country: '',
      secondaryCountries: [],
      flag: '',
      alpha2Code: '',
      numberOfCountry: 1,
      destination: '',
      city: '',
      name: '',
      currency: 'USD',
      dates: new Date(),
      transportation: 'Walk',
      estimatedBudget: '',
      isSubmit: false,
      isLoading: false,
      existItineraries: false,
      from: '',
      to: '',
      aNumberOfPaxs: '',
      addCountries: {},

      showOption1: false,
      showOption2: false,
      showOption3: false,

      list_optionals: [],
      options: [],
      currencies: [],

      showText: 'Show more',

      validateBudget: true,
    };
  }

  componentDidMount = async () => {
    await this.apiService.getAllOptionals().then((res) => {
      if (res.status === 'success') {
        this.setState({
          list_optionals: res.list_optionals,
        });
      }
    });

    await this.apiService.getAllCurrency().then((res) => {
      if (res.status === 'success') {
        this.setState({
          currencies: res.currencies,
        });
      }
    });

    var input = document.getElementById('start-country');
    var autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setTypes(['(regions)']);
    autocomplete.addListener('place_changed', () => {
      var place = autocomplete.getPlace();
      if (place.geometry) {
        var latlng = new google.maps.LatLng(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );

        $(`input[id=start-country]`)
          .closest('div')
          .find('div.invalid-feedback')
          .remove();
        $(`input[id=start-country]`).removeClass('is-invalid-autocomplete');

        new google.maps.Geocoder().geocode(
          { latLng: latlng },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                var country = null,
                  countryCode = null,
                  city = null,
                  cityAlt = null;
                var c, lc, component;
                for (var r = 0, rl = results.length; r < rl; r += 1) {
                  var result = results[r];

                  if (!city && result.types[0] === 'locality') {
                    for (
                      c = 0, lc = result.address_components.length;
                      c < lc;
                      c += 1
                    ) {
                      component = result.address_components[c];

                      if (component.types[0] === 'locality') {
                        city = component.long_name;
                        break;
                      }
                    }
                  } else if (
                    !city &&
                    !cityAlt &&
                    result.types[0] === 'administrative_area_level_1'
                  ) {
                    for (
                      c = 0, lc = result.address_components.length;
                      c < lc;
                      c += 1
                    ) {
                      component = result.address_components[c];

                      if (
                        component.types[0] === 'administrative_area_level_1'
                      ) {
                        cityAlt = component.long_name;
                        break;
                      }
                    }
                  } else if (!country && result.types[0] === 'country') {
                    country = result.address_components[0].long_name;
                    countryCode = result.address_components[0].short_name;
                  }

                  if (city && country) {
                    break;
                  }
                }

                var index = place.types.findIndex((item) => {
                  return item === 'country';
                });

                if (index >= 0) {
                  if (!this.state.isSubmit) {
                    this.setState({
                      city: '',
                      flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                      country: country,
                      alpha2Code: countryCode,
                    });
                  } else {
                    var tempCountries = [...this.state.secondaryCountries];
                    tempCountries[0] = {
                      city: '',
                      flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                      country: country,
                      alpha2Code: countryCode,
                    };
                    this.setState({
                      city: '',
                      flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                      country: country,
                      alpha2Code: countryCode,
                      secondaryCountries: tempCountries,
                    });
                  }
                } else {
                  if (cityAlt) {
                    if (!this.state.isSubmit) {
                      this.setState({
                        city: cityAlt,
                        flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                        country: country,
                        alpha2Code: countryCode,
                      });
                    } else {
                      var tempCountries = [...this.state.secondaryCountries];
                      tempCountries[0] = {
                        city: cityAlt,
                        flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                        country: country,
                        alpha2Code: countryCode,
                      };
                      this.setState({
                        city: cityAlt,
                        flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                        country: country,
                        alpha2Code: countryCode,
                        secondaryCountries: tempCountries,
                      });
                    }
                  } else {
                    if (!this.state.isSubmit) {
                      this.setState({
                        city: city,
                        flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                        country: country,
                        alpha2Code: countryCode,
                      });
                    } else {
                      var tempCountries = [...this.state.secondaryCountries];
                      tempCountries[0] = {
                        city: city,
                        flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                        country: country,
                        alpha2Code: countryCode,
                      };
                      this.setState({
                        city: city,
                        flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                        country: country,
                        alpha2Code: countryCode,
                        secondaryCountries: tempCountries,
                      });
                    }
                  }
                }
              }
            }
          }
        );
      } else {
        if (this.state.isSubmit) {
          var tempCountries = [...this.state.secondaryCountries];
          tempCountries[0] = {
            city: '',
            flag: '',
            country: '',
            alpha2Code: '',
          };
          this.setState({
            city: '',
            flag: '',
            country: '',
            alpha2Code: '',
            secondaryCountries: tempCountries,
          });
        } else {
          this.setState({
            city: '',
            flag: '',
            country: '',
            alpha2Code: '',
          });
        }
      }
    });
    if (loadItem('itineraries'))
      this.setState({
        existItineraries: true,
      });
  };

  handleChange = (name) => (event) => {
    if (name === 'numberOfCountry') {
      var temp = this.state.secondaryCountries;
      for (let i = 0; i < +event.target.value - 1; i++) {
        temp.push({
          country: this.state.country,
          alpha2Code: this.state.alpha2Code,
        });
      }
      this.setState({
        secondaryCountries: temp,
      });
    }

    if (name === 'currency') {
      this.setState({
        [name]: event,
      });
      return;
    }

    if (name === 'estimatedBudget') {
      const reg = /^-?\d*(\.\d*)?$/;
      if (
        !isNaN(event.target.value) &&
        reg.test(event.target.value) &&
        event.target.value !== '-'
      ) {
        this.setState({
          [name]: event.target.value,
        });
      }
      return;
    }

    if (name === 'aNumberOfPaxs') {
      const reg = /^-?\d*(\\d*)?$/;
      if (
        !isNaN(event.target.value) &&
        reg.test(event.target.value) &&
        event.target.value !== '-' &&
        event.target.value !== '.'
      ) {
        this.setState({
          [name]: event.target.value,
        });
      }
      return;
    }

    if (name === 'name') {
      if (event.target.value.length > maxCharacters) return;
      this.setState({
        [name]: event.target.value,
      });
      return;
    }

    this.setState({
      [name]: event.target.value,
    });
  };

  onBlur = (name) => (event) => {
    let valueTemp = this.state[name];
    if (
      this.state[name].charAt(this.state[name].length - 1) === '.' ||
      this.state[name] === '-'
    ) {
      valueTemp = this.state[name].slice(0, -1);
    }
    this.setState({
      [name]: valueTemp.replace(/0*(\d+)/, '$1'),
    });
  };

  handleChangeDate = (dates) => {
    this.setState({
      dates: dates,
    });
  };

  validate = () => {
    if (
      !this.state.name.trim() ||
      this.state.name === '' ||
      this.state.dates === '' ||
      this.state.name.length > maxCharacters
    ) {
      return false;
    }
    if (!this.validateDatePicker(this.state.from, this.state.to)) return false;
    return true;
  };

  handleChangeDestination = (destination) => {
    this.setState({
      destination: destination,
    });
  };

  getDatesBetween = (startDate, endDate) => {
    const dates = [];

    // Strip hours minutes seconds etc.
    let currentDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );

    while (currentDate <= endDate) {
      dates.push(currentDate);

      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1 // Will increase month if over range
      );
    }

    return dates;
  };

  validateAutoComplete = (tempCountries) => {
    var arrayIdInput = [];
    $("input[placeholder|='Enter City or Country']").each(function (
      index,
      element
    ) {
      if ($(element)[0].hasAttribute('id')) {
        arrayIdInput.push($(this).attr('id'));
      }
    });

    let validateAutoComplete = true;
    tempCountries.forEach((item, index) => {
      if (!item.flag || item.flag === '') {
        validateAutoComplete = false;
        if (
          !$(`input[id=${arrayIdInput[index]}]`).hasClass(
            'is-invalid-autocomplete'
          )
        ) {
          $(`input[id=${arrayIdInput[index]}]`)
            .closest('div')
            .append(
              "<div invalid='true' class='d-block invalid-feedback'>Wrong format!</div>"
            );
          $(`input[id=${arrayIdInput[index]}]`).addClass(
            'is-invalid-autocomplete'
          );
          $(`input[id=${arrayIdInput[index]}]`)
            .closest('div')
            .find('span.input-group-append > button')
            .addClass('is-invalid-btn-remove')
            .removeClass('btn-outline-primary');
        }
      }
    });
    return validateAutoComplete;
  };

  validateDatePicker = (from, to) => {
    var flag = true;
    if (isNaN(from)) {
      flag = false;
      if (
        !$(`input[placeholder='Start date']`).hasClass('is-invalid-datepicker')
      ) {
        $(`input[placeholder='Start date']`).addClass('is-invalid-datepicker');
      }
    } else {
      $(`input[placeholder='Start date']`).removeClass('is-invalid-datepicker');
    }

    if (isNaN(to)) {
      flag = false;
      if (
        !$(`input[placeholder='End date']`).hasClass('is-invalid-datepicker')
      ) {
        $(`input[placeholder='End date']`).addClass('is-invalid-datepicker');
      }
    } else {
      $(`input[placeholder='End date']`).removeClass('is-invalid-datepicker');
    }

    return flag;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      isSubmit: true,
    });

    if (!this.validate()) return;
    else {
      var temp = dateToTimestamp(this.state.dates);
      temp = temp.toString();
      // Usage
      var dates = this.getDatesBetween(
        new Date(this.state.from * 1000),
        new Date(this.state.to * 1000)
      );

      var tempID = new ObjectID().toHexString();
      var options = this.state.options;
      options.forEach((item) => {
        item.belong_to_itinerary = tempID;
      });

      var tempCountries = [...this.state.secondaryCountries];
      if (!this.state.isSubmit) {
        tempCountries.splice(0, 0, {
          country: this.state.country,
          alpha2Code: this.state.alpha2Code,
          city: this.state.city,
          flag: this.state.flag,
        });
        this.setState({
          secondaryCountries: tempCountries,
        });
      }

      if (!$(`input[id=start-country]`).val()) {
        if (!$(`input[id=start-country]`).hasClass('is-invalid-autocomplete')) {
          $(`input[id=start-country]`)
            .closest('div')
            .append(
              "<div invalid='true' class='d-block invalid-feedback'>Wrong format!</div>"
            );
          $(`input[id=start-country]`).addClass('is-invalid-autocomplete');
        }
        return;
      }
      if (!this.validateAutoComplete(tempCountries)) return;

      if (tempCountries.length > 1) {
        for (let i = 0; i < tempCountries.length - 1; i++) {
          if (tempCountries[i].alpha2Code === tempCountries[i + 1].alpha2Code) {
            if (tempCountries[i].city === tempCountries[i + 1].city) {
              tempCountries.splice(i, 1);
              i--;
            } else {
              if (!tempCountries[i].city) {
                tempCountries.splice(i, 1);
                i--;
              } else if (!tempCountries[i + 1].city) {
                tempCountries.splice(i + 1, 1);
                i--;
              }
            }
          }
        }
      }

      tempCountries = tempCountries.map(
        (item) =>
          (item = {
            belong_to_itinerary: tempID,
            title: item.country,
            alpha2Code: item.alpha2Code,
            flag: item.flag,
            children: [
              {
                title: item.city,
                children: [],
              },
            ],
          })
      );

      let originLength = tempCountries.length;
      if (tempCountries.length === 1) {
        dates.forEach((date) => {
          let detail = {
            month_day_year: dateToTimestamp(date),
            place_detail: [],
            title: '',
            _id: new ObjectID().toHexString(),
            country: this.state.country,
            alpha2Code: this.state.alpha2Code,
            city: this.state.city,
          };
          tempCountries[0].children[0].children.push(detail);
        });
      } else if (tempCountries.length > 1) {
        for (let i = 0; i < tempCountries.length - 1; i++) {
          if (tempCountries[i].alpha2Code === tempCountries[i + 1].alpha2Code) {
            if (tempCountries.length === 2) {
              tempCountries[i].children.push({
                title: tempCountries[i + 1].children[0].title,
                children: [],
              });
            } else {
              if (i === tempCountries.length - 2) {
                tempCountries[i].children.push({
                  title: tempCountries[i + 1].children[0].title,
                  children: [
                    {
                      month_day_year: '',
                      place_detail: [],
                      title: '',
                      _id: new ObjectID().toHexString(),
                      country: tempCountries[i].title,
                      alpha2Code: tempCountries[i].alpha2Code,
                      city: tempCountries[i + 1].children[0].title,
                    },
                  ],
                });
              } else {
                tempCountries[i].children.push({
                  title: tempCountries[i + 1].children[0].title,
                  children: [
                    {
                      month_day_year: '',
                      place_detail: [],
                      title: '',
                      _id: new ObjectID().toHexString(),
                      country: tempCountries[i].title,
                      alpha2Code: tempCountries[i].alpha2Code,
                      city: tempCountries[i + 1].children[0].title,
                    },
                  ],
                });
              }
            }
            tempCountries.splice(i + 1, 1);
            i--;
          }
        }
        if (tempCountries.length === 1 && originLength > 1) {
          tempCountries[0].children.forEach((item, index) => {
            if (index === 0) {
              item.children.push({
                month_day_year: dateToTimestamp(
                  new Date(this.state.from * 1000)
                ).toString(),
                place_detail: [],
                title: '',
                _id: new ObjectID().toHexString(),
                country: tempCountries[0].title,
                alpha2Code: tempCountries[0].alpha2Code,
                city: tempCountries[0].children[index].title,
              });
            } else if (index === tempCountries[0].children.length - 1) {
              item.children.push({
                month_day_year: dateToTimestamp(
                  new Date(this.state.to * 1000)
                ).toString(),
                place_detail: [],
                title: '',
                _id: new ObjectID().toHexString(),
                country: tempCountries[0].title,
                alpha2Code: tempCountries[0].alpha2Code,
                city: tempCountries[0].children[index].title,
              });
            } else {
              item.children.push({
                month_day_year: '',
                place_detail: [],
                title: '',
                _id: new ObjectID().toHexString(),
                country: tempCountries[0].title,
                alpha2Code: tempCountries[0].alpha2Code,
                city: tempCountries[0].children[index].title,
              });
            }
          });
        } else {
          if (originLength === tempCountries.length) {
            for (let i = 0; i < tempCountries.length; i++) {
              if (i === 0) {
                tempCountries[i].children[0].children.push({
                  month_day_year: dateToTimestamp(
                    new Date(this.state.from * 1000)
                  ).toString(),
                  place_detail: [],
                  title: '',
                  _id: new ObjectID().toHexString(),
                  country: tempCountries[i].title,
                  alpha2Code: tempCountries[i].alpha2Code,
                  city: tempCountries[i].children[0].title,
                });
              } else if (i === tempCountries.length - 1) {
                tempCountries[i].children[0].children.push({
                  month_day_year: '',
                  place_detail: [],
                  title: '',
                  _id: new ObjectID().toHexString(),
                  country: tempCountries[i].title,
                  alpha2Code: tempCountries[i].alpha2Code,
                  city: tempCountries[i].children[0].title,
                });
                tempCountries[i].children[
                  tempCountries[i].children.length - 1
                ].children.pop();
                tempCountries[i].children[
                  tempCountries[i].children.length - 1
                ].children.push({
                  month_day_year: dateToTimestamp(
                    new Date(this.state.to * 1000)
                  ).toString(),
                  place_detail: [],
                  title: '',
                  _id: new ObjectID().toHexString(),
                  country: tempCountries[i].title,
                  alpha2Code: tempCountries[i].alpha2Code,
                  city: tempCountries[i].children[0].title,
                });
              } else {
                tempCountries[i].children[0].children.push({
                  month_day_year: '',
                  place_detail: [],
                  title: '',
                  _id: new ObjectID().toHexString(),
                  country: tempCountries[i].title,
                  alpha2Code: tempCountries[i].alpha2Code,
                  city: tempCountries[i].children[0].title,
                });
              }
            }
          } else {
            for (let i = 0; i < tempCountries.length; i++) {
              if (i === 0) {
                tempCountries[i].children[0].children.push({
                  month_day_year: dateToTimestamp(
                    new Date(this.state.from * 1000)
                  ).toString(),
                  place_detail: [],
                  title: '',
                  _id: new ObjectID().toHexString(),
                  country: tempCountries[i].title,
                  alpha2Code: tempCountries[i].alpha2Code,
                  city: tempCountries[i].children[0].title,
                });
              } else if (i === tempCountries.length - 1) {
                tempCountries[i].children[0].children.push({
                  month_day_year: '',
                  place_detail: [],
                  title: '',
                  _id: new ObjectID().toHexString(),
                  country: tempCountries[i].title,
                  alpha2Code: tempCountries[i].alpha2Code,
                  city: tempCountries[i].children[0].title,
                });
                tempCountries[i].children[
                  tempCountries[i].children.length - 1
                ].children.pop();
                tempCountries[i].children[
                  tempCountries[i].children.length - 1
                ].children.push({
                  month_day_year: dateToTimestamp(
                    new Date(this.state.to * 1000)
                  ).toString(),
                  place_detail: [],
                  title: '',
                  _id: new ObjectID().toHexString(),
                  country: tempCountries[i].title,
                  alpha2Code: tempCountries[i].alpha2Code,
                  city: tempCountries[i].children[0].title,
                });
              } else {
                tempCountries[i].children[0].children.push({
                  month_day_year: '',
                  place_detail: [],
                  title: '',
                  _id: new ObjectID().toHexString(),
                  country: tempCountries[i].title,
                  alpha2Code: tempCountries[i].alpha2Code,
                  city: tempCountries[i].children[0].title,
                });
              }
            }
          }
        }
      }

      tempCountries.forEach((item, index) => {
        item.order = index;
      });

      let itinerary;
      if (this.props.profile) {
        itinerary = {
          currency: this.state.currency,
          country: this.state.country,
          alpha2Code: this.state.alpha2Code,
          reference: null,
          city: this.state.city,
          dates: this.state.from,
          end_dates: this.state.to,
          created_at: new Date(),
          last_updated: new Date(),
          destination: this.state.destination,
          itinerary_detail: tempCountries,
          name: this.state.name,
          total_amount: 0,
          estimated_budget: this.state.estimatedBudget,
          a_number_of_paxs: this.state.aNumberOfPaxs,
          transportation: this.state.transportation,
          belong_to_user: this.props.profile._id,
          belong_to_folder: null,
          _id: tempID,
          saved: 0,
          status: 1,
          approved: 1,
          show: 1,
          type: 1,
          options: options,
        };
      } else {
        itinerary = {
          currency: this.state.currency,
          country: this.state.country,
          alpha2Code: this.state.alpha2Code,
          reference: null,
          city: this.state.city,
          dates: this.state.from,
          end_dates: this.state.to,
          created_at: new Date(),
          last_updated: new Date(),
          destination: this.state.destination,
          itinerary_detail: tempCountries,
          name: this.state.name,
          total_amount: 0,
          estimated_budget: this.state.estimatedBudget,
          a_number_of_paxs: this.state.aNumberOfPaxs,
          transportation: this.state.transportation,
          belong_to_user: null,
          belong_to_folder: null,
          _id: tempID,
          saved: 0,
          status: 1,
          type: 1,
          options: options,
        };
      }

      if (loadItem('itineraries')) {
        var itineraries = loadItem('itineraries');
        itineraries.push(itinerary);
        saveItem('itineraries', itineraries);
        this.setState({
          isSubmit: false,
          isLoading: false,
        });
      } else {
        var itineraries = [];
        itineraries.push(itinerary);
        saveItem('itineraries', itineraries);
        this.setState({
          isSubmit: false,
          isLoading: false,
        });
      }

      window.onbeforeunload = null;
      if (isBrowser || isTablet) {
        window.location =
          config.http +
          '//' +
          config.domain +
          process.env.PUBLIC_URL +
          '/itinerary/' +
          itinerary._id +
          '/' +
          itinerary.itinerary_detail[0].children[0].children[0]._id;
      } else {
        window.location =
          config.http +
          '//' +
          config.domain +
          process.env.PUBLIC_URL +
          '/itinerary/' +
          itinerary._id;
      }
    }
  };

  handleChangeMultiDay = (from, to) => {
    this.setState({
      from: from,
      to: to,
    });
  };

  addAutoComplete = (ele, id) => {
    var currentInp = id;
    var placeBox = new google.maps.places.Autocomplete(ele, {
      types: ['(regions)'],
    });
    google.maps.event.addListener(placeBox, 'place_changed', () => {
      var place = placeBox.getPlace();
      if (place.geometry) {
        var latlng = new google.maps.LatLng(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
        $(`input[id=${currentInp}]`)
          .closest('div')
          .find('div.invalid-feedback')
          .remove();
        $(`input[id=${currentInp}]`).removeClass('is-invalid-autocomplete');
        $(`input[id=${currentInp}]`)
          .closest('div')
          .find('span.input-group-append > button')
          .addClass('btn-outline-primary')
          .removeClass('is-invalid-btn-remove');

        new google.maps.Geocoder().geocode(
          { latLng: latlng },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                var country = null,
                  countryCode = null,
                  city = null,
                  cityAlt = null;
                var c, lc, component;
                for (var r = 0, rl = results.length; r < rl; r += 1) {
                  var result = results[r];

                  if (!city && result.types[0] === 'locality') {
                    for (
                      c = 0, lc = result.address_components.length;
                      c < lc;
                      c += 1
                    ) {
                      component = result.address_components[c];

                      if (component.types[0] === 'locality') {
                        city = component.long_name;
                        break;
                      }
                    }
                  } else if (
                    !city &&
                    !cityAlt &&
                    result.types[0] === 'administrative_area_level_1'
                  ) {
                    for (
                      c = 0, lc = result.address_components.length;
                      c < lc;
                      c += 1
                    ) {
                      component = result.address_components[c];

                      if (
                        component.types[0] === 'administrative_area_level_1'
                      ) {
                        cityAlt = component.long_name;
                        break;
                      }
                    }
                  } else if (!country && result.types[0] === 'country') {
                    country = result.address_components[0].long_name;
                    countryCode = result.address_components[0].short_name;
                  }

                  if (city && country) {
                    break;
                  }
                }

                var index = place.types.findIndex((item) => {
                  return item === 'country';
                });
                var keytoFind = currentInp.substr(14);
                var i = Object.keys(this.state.addCountries).indexOf(keytoFind);

                if (index >= 0) {
                  if (!this.state.isSubmit) {
                    let temp2 = [...this.state.secondaryCountries];
                    temp2[i] = {
                      city: '',
                      country: country,
                      alpha2Code: countryCode,
                      flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                    };
                    this.setState({
                      secondaryCountries: temp2,
                    });
                  } else {
                    let temp2 = [...this.state.secondaryCountries];
                    temp2[i + 1] = {
                      city: '',
                      country: country,
                      alpha2Code: countryCode,
                      flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                    };
                    this.setState({
                      secondaryCountries: temp2,
                    });
                  }
                } else {
                  if (cityAlt) {
                    if (!this.state.isSubmit) {
                      let temp2 = [...this.state.secondaryCountries];
                      temp2[i] = {
                        city: cityAlt,
                        country: country,
                        alpha2Code: countryCode,
                        flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                      };
                      this.setState({
                        secondaryCountries: temp2,
                      });
                    } else {
                      let temp2 = [...this.state.secondaryCountries];
                      temp2[i + 1] = {
                        city: cityAlt,
                        country: country,
                        alpha2Code: countryCode,
                        flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                      };
                      this.setState({
                        secondaryCountries: temp2,
                      });
                    }
                  } else {
                    if (!this.state.isSubmit) {
                      let temp2 = [...this.state.secondaryCountries];
                      temp2[i] = {
                        city: city,
                        country: country,
                        alpha2Code: countryCode,
                        flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                      };
                      this.setState({
                        secondaryCountries: temp2,
                      });
                    } else {
                      let temp2 = [...this.state.secondaryCountries];
                      temp2[i + 1] = {
                        city: city,
                        country: country,
                        alpha2Code: countryCode,
                        flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                      };
                      this.setState({
                        secondaryCountries: temp2,
                      });
                    }
                  }
                }
              }
            }
          }
        );
      } else {
        var keytoFind = currentInp.substr(14);
        var i = Object.keys(this.state.addCountries).indexOf(keytoFind);
        var temp2 = [...this.state.secondaryCountries];
        if (!this.state.isSubmit) {
          temp2[i] = {
            city: '',
            country: '',
            alpha2Code: '',
            flag: '',
          };
          this.setState({
            secondaryCountries: temp2,
          });
        } else {
          temp2[i + 1] = {
            city: '',
            country: '',
            alpha2Code: '',
            flag: '',
          };
          this.setState({
            secondaryCountries: temp2,
          });
        }
      }
    });
  };

  removeInput = (time) => {
    const addCountries = { ...this.state.addCountries };
    var keytoFind = time;
    var i = Object.keys(addCountries).indexOf(keytoFind.toString());

    var secondaryCountries = [...this.state.secondaryCountries];
    if (!this.state.isSubmit) {
      secondaryCountries.splice(i, 1);
    } else {
      secondaryCountries.splice(i + 1, 1);
    }

    delete addCountries[time];

    this.setState({
      addCountries: addCountries,
      secondaryCountries: secondaryCountries,
      numberOfCountry: Object.keys(addCountries).length + 1,
    });
  };

  handleAddAnotherDestination = () => {
    const addCountries = { ...this.state.addCountries };
    const time = new Date().getTime();
    this.autocompleteMutiInput[time] = React.createRef();
    addCountries[time] = (
      <FormGroup row key={time}>
        <Col xs={12}>
          <InputGroup className="row-destination-added">
            <Input
              innerRef={this.autocompleteMutiInput[time]}
              id={`start-country-${time}`}
              placeholder="Enter City or Country"
            />
            <span className="input-group-append input-group-btn">
              <Button
                className="btn-outline-primary"
                onClick={this.removeInput.bind(this, time)}
              >
                <MdClose />
              </Button>
            </span>
          </InputGroup>
        </Col>
      </FormGroup>
    );

    this.setState(
      {
        numberOfCountry: this.state.numberOfCountry + 1,
        addCountries: addCountries,
      },
      () => {
        this.addAutoComplete(
          this.autocompleteMutiInput[time].current,
          `start-country-${time}`
        );

        var temp = [...this.state.secondaryCountries];
        temp.push({
          country: '',
          alpha2Code: '',
        });
        this.setState({
          secondaryCountries: temp,
        });
      }
    );
  };

  handleShowOption = (option) => {
    if (option === 1) {
      this.setState({
        showOption1: !this.state.showOption1,
      });
    }
    if (option === 2) {
      this.setState({
        showOption2: !this.state.showOption2,
      });
    }
    if (option === 3) {
      this.setState({
        showOption3: !this.state.showOption3,
      });
    }
  };

  handleChangeOption = (name) => (e) => {
    if (!e.target.checked) {
      this.setState({
        options: this.state.options.filter((item) => {
          return item.option_code !== name;
        }),
      });
    } else {
      if (this.state.options.findIndex((el) => el.option_code === name) < 0) {
        let temp = [
          ...this.state.options,
          {
            option_code: name,
          },
        ];
        this.setState({
          options: temp,
        });
      }
    }
  };

  handleChangeOptionType = (name) => (e) => {
    this.setState(
      {
        options: this.state.options.filter((item) => {
          return item.option_code <= 18;
        }),
      },
      () => {
        if (this.state.options.findIndex((el) => el.option_code === name) < 0) {
          let temp = [
            ...this.state.options,
            {
              option_code: name,
            },
          ];
          this.setState({
            options: temp,
          });
        }
      }
    );
  };

  handleShowMoreLess = (key) => {
    if (key.length > 0) {
      this.setState({
        showText: 'Show less',
      });
    } else {
      this.setState({
        showText: 'Show more',
      });
    }
  };

  render() {
    return (
      <>
        <DialogContent className={styles.createItineraryForm} dividers>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <FormGroup row>
              <Col xs={12}>
                <small className="warning-limit">
                  <WarningFilled /> Choose a name for your new Tyneri (limit to{' '}
                  <span>
                    {this.state.name.length}/{maxCharacters}
                  </span>{' '}
                  characters)
                </small>
                <Input
                  value={this.state.name}
                  invalid={
                    (!this.state.name.trim() || !this.state.name) &&
                    this.state.isSubmit
                      ? true
                      : false
                  }
                  type="text"
                  onChange={this.handleChange('name')}
                  name="name"
                  placeholder={
                    'Trying using the included destinations, i.e. Beijing-Thailand-Hongkong'
                  }
                />
                <FormFeedback
                  invalid="true"
                  className={
                    (!this.state.name.trim() || !this.state.name) &&
                    this.state.isSubmit
                      ? 'd-block'
                      : ''
                  }
                >
                  Itinerary title is empty!
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              {/* <Label sm={3}>When you go?</Label> */}
              <Col
                className="col-muilti-day-create"
                xs={12}
                style={{ textAlign: 'center' }}
              >
                <MultiDayCalendar
                  handleChangeMultiDay={this.handleChangeMultiDay}
                  edit={false}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col lg="6" md={6} xs="12" className="form-input-budget-mobile">
                <InputGroup className="row-destination-added">
                  {/* <span className="input-group-append input-group-btn currency">
                      USD ($)
                    </span> */}
                  <Select
                    onChange={this.handleChange('currency')}
                    defaultValue="USD"
                    className="input-group-append input-group-btn currency"
                  >
                    {!!this.state.currencies.length &&
                      this.state.currencies.map((item, key) => (
                        <Option key={key} value={item.code}>
                          {item.code} {item.symbol}
                        </Option>
                      ))}
                  </Select>
                  <Input
                    placeholder="Budget"
                    onContextMenu={(e) => {
                      e.preventDefault();
                    }}
                    value={this.state.estimatedBudget}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    onChange={this.handleChange('estimatedBudget')}
                    onBlur={this.onBlur('estimatedBudget')}
                  />
                </InputGroup>
              </Col>
              <Col lg="6" md={6} xs="12" className="form-input-pax-mobile">
                <Input
                  placeholder="Number of pax"
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  value={this.state.aNumberOfPaxs}
                  onChange={this.handleChange('aNumberOfPaxs')}
                  onBlur={this.onBlur('aNumberOfPaxs')}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <Input id="start-country" placeholder="Enter City or Country" />
              </Col>
            </FormGroup>
            <div id="new-row-added">
              {Object.keys(this.state.addCountries).map(
                (key) => this.state.addCountries[key]
              )}
            </div>
            <FormGroup row>
              <Col xs={12}>
                <Button
                  className="btn-add-destination"
                  onClick={this.handleAddAnotherDestination.bind(this)}
                  size="lg"
                  block
                >
                  + Add another destination
                </Button>
              </Col>
            </FormGroup>

            {!this.props.getStarted && (
              <Collapse
                className="itinerary-optional"
                onChange={this.handleShowMoreLess}
              >
                <Panel showArrow={false} header={this.state.showText} key="1">
                  <Collapse expandIconPosition="right">
                    <Panel header="Activity types" key="1">
                      <FormGroup row inline>
                        <Col xs={12}>
                          {this.state.list_optionals &&
                            !!this.state.list_optionals.length &&
                            this.state.list_optionals.map((item, key) => {
                              if (item.group_name === 'pref') {
                                return (
                                  <React.Fragment key={key}>
                                    {/* <div
                                        className="optional"
                                        onClick={this.handleShowOption.bind(
                                          this,
                                          1
                                        )}
                                      >
                                        <Label className="label-optional">
                                          Activity types
                                        </Label>
                                        <div
                                          className={
                                            this.state.showOption1
                                              ? "wrapper3 active"
                                              : "wrapper3"
                                          }
                                        >
                                          <span className="arrow"></span>
                                        </div>
                                      </div> */}

                                    <Row>
                                      {item.detail.map((child, index) => (
                                        <Col xs="4" key={index}>
                                          <CustomInput
                                            type="checkbox"
                                            id={child.option_code}
                                            label={child.name}
                                            inline
                                            onChange={this.handleChangeOption(
                                              child.option_code
                                            )}
                                          />
                                        </Col>
                                      ))}
                                    </Row>
                                  </React.Fragment>
                                );
                              }
                            })}
                        </Col>
                      </FormGroup>
                    </Panel>

                    <Panel header="Age groups" key="2">
                      <FormGroup row inline>
                        <Col xs={12}>
                          {this.state.list_optionals &&
                            !!this.state.list_optionals.length &&
                            this.state.list_optionals.map((item, key) => {
                              if (item.group_name === 'age') {
                                return (
                                  <React.Fragment key={key}>
                                    {/* <div
                                      className="optional"
                                      onClick={this.handleShowOption.bind(
                                        this,
                                        2
                                      )}
                                    >
                                      <Label className="label-optional">
                                        Age groups
                                      </Label>
                                      <div
                                        className={
                                          this.state.showOption2
                                            ? "wrapper3 active"
                                            : "wrapper3"
                                        }
                                      >
                                        <span className="arrow"></span>
                                      </div>
                                    </div> */}

                                    <Row>
                                      {item.detail.map((child, index) => (
                                        <Col xs="4" key={index}>
                                          <CustomInput
                                            type="checkbox"
                                            id={child.option_code}
                                            label={child.name}
                                            inline
                                            onChange={this.handleChangeOption(
                                              child.option_code
                                            )}
                                          />
                                        </Col>
                                      ))}
                                    </Row>
                                  </React.Fragment>
                                );
                              }
                            })}
                        </Col>
                      </FormGroup>
                    </Panel>

                    <Panel header="Types of group" key="3">
                      <FormGroup row inline>
                        <Col xs={12}>
                          {this.state.list_optionals &&
                            !!this.state.list_optionals.length &&
                            this.state.list_optionals.map((item, key) => {
                              if (item.group_name === 'plan') {
                                return (
                                  <React.Fragment key={key}>
                                    {/* <div
                                      className="optional"
                                      onClick={this.handleShowOption.bind(
                                        this,
                                        3
                                      )}
                                    >
                                      <Label className="label-optional">
                                        Types of group
                                      </Label>
                                      <div
                                        className={
                                          this.state.showOption3
                                            ? "wrapper3 active"
                                            : "wrapper3"
                                        }
                                      >
                                        <span className="arrow"></span>
                                      </div>
                                    </div> */}

                                    <Row>
                                      {item.detail.map((child, index) => (
                                        <Col xs="4" key={index}>
                                          <CustomInput
                                            type="radio"
                                            id={child.option_code}
                                            label={child.name}
                                            name="customRadio"
                                            inline
                                            onChange={this.handleChangeOptionType(
                                              child.option_code
                                            )}
                                          />
                                        </Col>
                                      ))}
                                    </Row>
                                  </React.Fragment>
                                );
                              }
                            })}
                        </Col>
                      </FormGroup>
                    </Panel>
                  </Collapse>
                </Panel>
              </Collapse>
            )}
          </Form>
        </DialogContent>
        <DialogActions className={styles.createItineraryForm}>
          <Button
            disabled={this.state.isLoading ? true : false}
            className="color-primary btn-start"
            type="button"
            onClick={this.handleSubmit.bind(this)}
          >
            {this.state.isLoading ? (
              <CircularProgress />
            ) : this.props.getStarted ? (
              `START NOW`
            ) : (
              `Continue`
            )}
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default connect(mapStateToProps, null)(CreateItineraryForm);
