import React, { Component } from 'react';
import { Row, Layout, BackTop } from 'antd';
import { withRouter } from 'react-router-dom';
import styles from './index.scss';
import $ from 'jquery';
import ButtonCreateTyneri from '../../components/create-tyneri-button';
import MenuLogin from '../../components/menu-login';
import MenuMain from '../../components/menu-main';
import {
  BrowserView,
  isTablet,
  isMobileOnly,
  MobileOnlyView,
  isBrowser,
} from 'react-device-detect';
import SearchBar from '../../components/content-trendy/search-bar';
import Footer from '../footer';
import MenuOnMobileTyneri from '../../components/menu-mobile';
import { BoxDraft } from '../../components';

const { Header, Content } = Layout;

const HeighBoxMobile = ({ style, ...props }) => (
  <div style={style} className={`height-${props.value}`}>
    {props.children}
  </div>
);

class LayoutMain extends Component {
  render() {
    // const HomeRoute = "/";
    // const location = this.props.location;
    const {
      match: { path },
    } = this.props;

    // if (location.pathname === HomeRoute) return this.props.children;
    return (
      <React.Fragment>
        {isTablet || isMobileOnly ? (
          <div style={{ height: '100%' }}>
            <Layout id="tyneri-layout">
              <Header>
                <Row className="mobile" justify="space-around" align="middle">
                  <MobileOnlyView renderWithFragment={true}>
                    <HeighBoxMobile value={50}>
                      <MenuOnMobileTyneri />
                    </HeighBoxMobile>
                  </MobileOnlyView>
                  <HeighBoxMobile value={50} style={{ paddingRight: 10 }}>
                    <MenuMain />
                  </HeighBoxMobile>
                  <HeighBoxMobile value={50}>
                    <MenuLogin />
                  </HeighBoxMobile>
                </Row>
                {path !== '/itinerary/:idItinerary' &&
                path !== '/itinerary/:idItinerary/:idItineraryDetail' ? (
                  <MobileOnlyView>
                    <Row>
                      <SearchBar />
                      <ButtonCreateTyneri
                        style={{
                          padding: 10,
                        }}
                      />
                    </Row>
                  </MobileOnlyView>
                ) : null}
              </Header>
              <Content className="pl-3 pr-3">{this.props.children}</Content>
              {path !== '/itinerary/:idItinerary' &&
              path !== '/itinerary/:idItinerary/:idItineraryDetail' &&
              path !== '/create-top-list' &&
              path !== '/edit-top-list/:idTopList' &&
              path !== '/view-top-list/:idTopList' ? (
                <>
                  <BoxDraft />
                  <Footer style={{ flex: 0 }} />
                </>
              ) : null}
            </Layout>
          </div>
        ) : (
          <BrowserView style={{ flex: 1, display: 'flex' }}>
            <Layout id="tyneri-layout">
              <Header style={{ flex: 0 }}>
                <Row type="flex" align="middle">
                  <div className="tyneri-container d-flex justify-content-between">
                    {isBrowser ? <MenuMain mode="horizontal" /> : null}
                    <MenuLogin />
                  </div>
                </Row>
              </Header>
              <Content
                style={
                  path === '/get-started' ||
                  path === '/sign-up' ||
                  path === '/sign-in'
                    ? { backgroundColor: '#DDDDDD', flex: 1, overflowY: 'auto' }
                    : { flex: 1, overflowY: 'auto' }
                }
              >
                <div className={styles.wrapper + ' tyneri-container'}>
                  <BackTop
                    style={{ right: 40, bottom: 100 }}
                    target={() => $('.ant-layout-content')[0]}
                  />
                  {this.props.children}
                </div>
              </Content>
              {path !== '/itinerary/:idItinerary' &&
              path !== '/itinerary/:idItinerary/:idItineraryDetail' &&
              path !== '/create-top-list' &&
              path !== '/edit-top-list/:idTopList' &&
              path !== '/view-top-list/:idTopList' ? (
                <>
                  <BoxDraft />
                  <Footer style={{ flex: 0 }} />
                </>
              ) : null}
            </Layout>
          </BrowserView>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(LayoutMain);
