import React, {useState} from "react";
import {Row, Col, Tabs} from "antd";
import { connect } from "react-redux";
import Information from "./information";
import TyneryList from "./tyneryList";

import "./index.scss";
import TopListCompany from "./topList";

function Home(props) {
  const company = props.companyDomain;
  const [totalItinerary, setTotalItinerary] = useState(0);
  const [totalList, setTotalList] = useState(0);

  return (
    <div className="home-page">
      <Row type="flex" gutter={[16]} justify="space-between">
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="d-flex">
            {company ? <Information company={ company }/> : null}
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <Tabs style={{background: "white"}} className="mt-3">
            <Tabs.TabPane tab={"Itineraries ("+totalItinerary+")"} key={1} forceRender={true}>
              <TyneryList fetch={(val) => setTotalItinerary(val)} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Lists ("+totalList+")"} key={2} forceRender={true}>
              <TopListCompany fetch={(val) => setTotalList(val)}/>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}

const mapDispatchToProps = () => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    companyDomain: state.profileReducer.companyDomain,
    profileDomain: state.profileReducer.profileDomain,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
