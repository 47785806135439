import axios from '../axios-config';

export default () => {
  let services = {
    getDashboardReport: () => {
      try {
        return axios.get("dashboard/index").then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
  return services;
};
