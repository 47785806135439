import React, { Component } from "react";
import {
  MyTyneriTable
} from "../../components";
import ApiService from "../../services/api.service";
import styles from "./index.scss";
import { Modal } from "antd";
import { withRouter } from "react-router-dom";

const { confirm } = Modal;

class MyTyneri extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      redirect: false,
      itinerary: {},

      showCreateItineraryForm: false
    };
  }

  closeCreateItineraryForm = () => {
    this.setState({
      showCreateItineraryForm: false
    });
  };

  showFormCreateItinerary = () => {
    this.setState({
      showCreateItineraryForm: true
    });
  };

  handleClick = e => {
    if (e.key === "1") {
      this.props.history.push("/my-tyneri");
    } else if (e.key === "2") {
      confirm({
        title: "Please upgrade your plan to proceed",
        onOk: () => {
          console.log("OK");
        }
      });
    } else if (e.key === "3") {
      confirm({
        title: "Please upgrade your plan to proceed",
        onOk: () => {
          console.log("OK");
        }
      });
    } else if (e.key === "4") {
      confirm({
        title: "Please upgrade your plan to proceed",
        onOk: () => {
          console.log("OK");
        }
      });
    } else if (e.key === "5") {
      this.props.history.push("/my-profile");
    } else if (e.key === "6") {
      this.props.history.push("/billing");
    }
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <MyTyneriTable />
      </div>
    );
  }
}

export default withRouter(MyTyneri);
