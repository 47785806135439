import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "./index.scss";
import {
  isMyResource,
  getImageFromUrl,
} from "../../services/utils.service";
import { configBaseUrl } from "../../config";
import formatMoney from "accounting-js/lib/formatMoney.js";

const Container = styled.div`
  &:hover {
    box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    -webkit-transform: translatey(-10px);
    transform: translatey(-10px);
  }
  -webkit-transition: all 0.2s ease;
  transition: all 0.5s ease;
  position: relative;
  background-image: url(${(props) =>
      getImageFromUrl(
        isMyResource(props.bgPhoto)
          ? `${configBaseUrl.baseURL + props.bgPhoto}`
          : `${props.bgPhoto}`,
        "large"
      )});
  background-position: center center;
  background-size: cover;
  border-radius: 8px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 15px;
  min-height: 300px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ContentColumn = styled.div`
  width: 100%;
`;

const Title = styled.span`
  font-size: 1.2rem;
  display: block;
  margin-bottom: 8px;
  color: ${(props) => props.color};
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #1890ff;
  }
`;

const Subtitle = styled.span`
  font-size: 14px;
  color: ${(props) => props.color};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #1890ff;
  }
`;

const TagContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${(props) => props.tagBg};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 3px 5px;
  width: auto;
  border-radius: 3px;
  text-align: center;
  color: ${(props) => props.color};
`;

const TagText = styled.span``;

const OptionsTagContainer = styled.div`
  display: inline-block;
  font-size: 12px;
  color: rgba(255, 255, 255, 50);
  div {
    margin-right: 0.5rem;
  }
`;

class Card extends Component {
  render() {
    const {
      title,
      titleColor = "black",
      subtitleColor = "black",
      subtitle,
      tag,
      tagBg = "#E33C36",
      tagColor = "white",
      bgPhoto,
      options,
      estimated_budget,
      a_number_of_paxs,
      currency_infor,
    } = this.props;
    const textMore =
      options.length > 2 ? <span className="each-option more">...</span> : "";
    let i = 0;
    return (
      <React.Fragment>
        <Container className="container-card mb-3" bgPhoto={bgPhoto}>
          {tag && (
              <TagContainer tagBg={tagBg} color={tagColor}>
                <TagText>{tag}</TagText>{" "}
              </TagContainer>
          )}
        </Container>
        <Content>
          <ContentColumn>
            {(title || subtitle) && (
                <>
                  {/* <TotalRating>{totalReviews}</TotalRating>{" "}
              <StarRating ratingAverage={ratingAverage} />{" "} */}
                  <Title className="" color={titleColor}>
                    {title}
                    {estimated_budget
                        ? ` on a ${formatMoney(estimated_budget, {
                          symbol: currency_infor.symbol,
                          precision: currency_infor.decimal_digits,
                          thousand: ",",
                          decimal: ".",
                        })}${" "} ${currency_infor.code} budget`
                        : null}{" "}
                    {a_number_of_paxs ? ` for ${a_number_of_paxs} pax` : ""}
                  </Title>
                  <Subtitle color={subtitleColor}>{subtitle}</Subtitle>
                </>
            )}
            {!!options.length && (
                <OptionsTagContainer>
                  {options.map((item, key) => {
                    if (i < 2) {
                      i++;
                      if (item.group_option === "pref") {
                        return (
                            <span className="each-option option-pref" key={key}> <span>#{item.name.toLowerCase()}</span> </span>
                        );
                      } else if (item.group_option === "age") {
                        return (
                            <span className="each-option option-age" key={key}>
                        <span>#age{item.name.toLowerCase()}</span>
                      </span>
                        );
                      } else if (item.group_option === "plan") {
                        return (
                            <span className="each-option option-type" key={key}><span>#{item.name.toLowerCase()}</span></span>
                        );
                      }else {
                        return null;
                      }
                    } else {
                      return null;
                    }
                  })}
                  {textMore}
                </OptionsTagContainer>
            )}
          </ContentColumn>
        </Content>
      </React.Fragment>
    );
  }
}

Card.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  subtitle: PropTypes.string,
  tag: PropTypes.string,
  tagBg: PropTypes.string,
  tagColor: PropTypes.string,
  bottomIconName: PropTypes.string,
  bottomIconSize: PropTypes.number,
  bgPhoto: PropTypes.string,
  totalReviews: PropTypes.number,
  ratingAverage: PropTypes.oneOf([0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]),
};

export default Card;
