import axios from '../axios-config';

export default () => {
  let services = {
    save: (data) => {
      try {
        return axios.post("contact/save", {
          ...data,
        }).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    checkContactExist: (email) => {
      try {
        return axios.get("contact/exist/"+email).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    getList: (pageNumber, limit) => {
      try {
        return axios.get("contact/list?pageNumber="+pageNumber+"&limit="+limit).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    getContactByName: (name) =>  {
      try {
        return axios.get("contact/search/"+name).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    delete: (key) => {
      try {
        return axios.post ("contact/delete/"+key).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    getGroup: () => {
      try {
        return axios.get("contact/getGroups").then( res => {
            return res.data;
        });

      } catch(error) {
        console.log(error);
      }
    },

    addContactGroup: (data) => {
      try {
        return axios.post ("contact/createGroup",{
          ...data,
        }).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    }

  };

  return services;
};
