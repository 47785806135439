export function saveItem(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function loadItem(key) {
  let res = JSON.parse(window.localStorage.getItem(key));
  return res;
}

export function removeItem(key) {
  window.localStorage.removeItem(key);
}
