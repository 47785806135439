import React from 'react';
import {
  Button,
  Col,
  Descriptions,
  Form,
  notification,
  Popconfirm,
  Row,
  Typography,
} from 'antd';
import { connect } from 'react-redux';
import PaymentService from '../../../../services/payment.service';
import { saveProfile } from '../../../../store/actions';
import CardView from './card/view';

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleDeleteCustomer = () => {
    this.setState({
      loading: true,
    });
    PaymentService()
      .stripeDeleteCustomer()
      .then((res) => {
        if (res.status === 'success') {
          notification.success({
            message: 'Delete card success.',
            placement: 'bottomRight',
          });
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.saveProfile(res.user);
            }
          );
        } else {
          console.log(res.error);
        }
      });
  };

  render() {
    const { card } = this.props.profile;
    const billingAddress = this.props.profile
      ? this.props.profile.billing_details || {}
      : {};
    return (
      <div className="py-container">
        <Form onSubmit={(e) => this.handleSubmit(e)} className="form-card">
          {card ? (
            <Row type="flex" className="step-action">
              <Col
                span={24}
                className="d-flex mb-2 align-items-center justify-content-between"
              >
                <Typography.Title level={4}>Credit card</Typography.Title>
                <Button.Group className="list-action">
                  <React.Fragment>
                    <Popconfirm
                      placement="top"
                      onConfirm={this.handleDeleteCustomer}
                      title="Are you sure delete credit card."
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        loading={this.state.loading}
                        size="large"
                        type="danger"
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </React.Fragment>
                </Button.Group>
              </Col>
              <Col span={24}>
                <div
                  className={'card-holder flex-column' + (card ? 'cview' : '')}
                >
                  <React.Fragment>
                    <Descriptions column={1}>
                      <Descriptions.Item label="Card Holder">
                        {billingAddress.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Email">
                        {billingAddress.email}
                      </Descriptions.Item>
                      <Descriptions.Item label="Phone">
                        {billingAddress.phone}
                      </Descriptions.Item>
                    </Descriptions>
                    <CardView card={card} />
                  </React.Fragment>
                </div>
              </Col>
            </Row>
          ) : null}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
  };
};

export default Form.create()(
  connect(mapStateToProps, mapDispatchToProps)(Payment)
);
