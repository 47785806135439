/*global google*/
import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Row, Container } from "reactstrap";
import {
  AttractionTab,
  ActivityTab,
  RestaurantTab,
  LodgingTab,
  TransportationTab,
  InforTab,
} from "../../components";
import { loadItem, saveItem } from "../../services/storage.service.js";

import ApiService from "../../services/api.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import { closeFormAddNewStop, updateTotalAmount } from "../../store/actions";
import { connect } from "react-redux";
import styles from "./index.scss";
import { createPlaceDetail, searchTree, calcTotalAmount } from "../../services/utils.service";
import { ObjectID } from "bson";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const mapDispatchToProps = (dispatch) => {
  return {
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    closeFormAddNewStop: () => {
      dispatch(closeFormAddNewStop());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    isDisplayFormAddNewStop: state.formAddNewStopReducer,
  };
};

class AddNewStopForm extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      categoryTab: 1,
      open: false,
      showAlert: false,
      newStop: {},
      isLoadingAdd: false,
      isLoadingSave: false,
      validate: true,
      length: 0,
      order: 0,
      listPlaceDetail: [],

      categories: [],
    };
  }

  componentDidMount = () => {
    this.apiService.getAttractionCategories().then((res) => {
      if (res.status === "success")
        this.setState({
          categories: res.categories,
        });
    });
  };

  handleOpenNewStop = () => {
    this.setState({ open: true, validate: true });
  };

  handleCloseNewStop = () => {
    if (this.state.isLoadingAdd || this.state.isLoadingSave) return;
    this.props.closeFormAddNewStop();
    this.setState({ validate: true });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleChangeActivityTab = () => {
    this.setState({
      categoryTab: 1,
      validate: true,
    });
  };

  handleChangeLodgingTab = () => {
    this.setState({
      categoryTab: 2,
      validate: true,
    });
  };

  onCreateNewStop = (newStop) => {
    this.setState({
      newStop: newStop,
    });
  };

  validate = (newStop) => {
    if (
      Object.keys(newStop).length === 0 ||
      newStop.title === "" ||
      !newStop.title.trim()
    )
      return false;
    return true;
  };

  onAddNewStop = async () => {
    if (!this.validate(this.state.newStop)) {
      this.setState({
        validate: false,
      });
    } else {
      this.setState({
        isLoadingAdd: true,
      });
      var newStop = { ...this.state.newStop };
      newStop.images.forEach((child) => {
        let basePath;
        if (child.xhr.indexOf("public") >= 0) basePath = child.xhr.slice(7);
        else basePath = child.xhr;
        child.xhr = basePath;
        child.thumbUrl = basePath;
        child.preview = basePath;
        for (var key in child) {
          if (child.hasOwnProperty(key)) {
            if (
              key === "lastModified" ||
              key === "lastModifiedDate" ||
              key === "size" ||
              key === "type" ||
              key === "percent" ||
              key === "originFileObj" ||
              key === "response"
            )
              delete child[key];
          }
        }
      });

      if (newStop.address !== "") {
        var geocoder = new google.maps.Geocoder();
        await geocoder.geocode(
          { address: newStop.address },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              this.setState(
                {
                  newStop: {
                    ...newStop,
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                  },
                },
                () => {
                  if (
                    newStop.day_start !== this.props.params.idItineraryDetail
                  ) {
                    if (newStop.time_start === "") {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        newStop.day_start
                      )[0];
                      let _id = new ObjectID().toHexString();
                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        newStop,
                        newStop.day_start,
                        itineraries[current].transportation,
                        _id,
                        this.state.categories
                      );
                      newPlaceDetail = {
                        ...newPlaceDetail,
                        isNew: true,
                      };
                      tempItem.place_detail.push(newPlaceDetail);
                      tempItem.place_detail.forEach((item, index) => {
                        item.order = index + 1;
                      });

                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(7);
                      this.setState({
                        isLoadingAdd: false,
                        open: false,
                      });
                    } else {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        newStop.day_start
                      )[0];
                      if (tempItem.place_detail.length > 0) {
                        this.setState({
                          listPlaceDetail: tempItem.place_detail,
                          length: tempItem.place_detail.length,
                          order: tempItem.place_detail.length + 1,
                        });
                      } else {
                        this.setState({
                          length: 0,
                          order: 1,
                        });
                      }
                      let _id = new ObjectID().toHexString();
                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        newStop,
                        newStop.day_start,
                        itineraries[current].transportation,
                        _id,
                        this.state.categories
                      );
                      newPlaceDetail = {
                        ...newPlaceDetail,
                        isNew: true,
                      };
                      tempItem.place_detail.push(newPlaceDetail);
                      for (
                        let i = 0;
                        i < tempItem.place_detail.length - 1;
                        i++
                      ) {
                        for (
                          let j = i + 1;
                          j < tempItem.place_detail.length;
                          j++
                        ) {
                          if (
                            tempItem.place_detail[j].data.actual_data
                              .time_start === "" ||
                            tempItem.place_detail[i].data.actual_data
                              .time_start === ""
                          )
                            continue;
                          if (
                            tempItem.place_detail[j].data.actual_data
                              .time_start <
                            tempItem.place_detail[i].data.actual_data.time_start
                          ) {
                            let t = tempItem.place_detail[i];
                            tempItem.place_detail[i] = tempItem.place_detail[j];
                            tempItem.place_detail[j] = t;
                          }
                        }
                      }

                      for (let i = 0; i < tempItem.place_detail.length; i++) {
                        tempItem.place_detail[i].order = i + 1;
                      }

                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(7);
                      this.setState({
                        isLoadingAdd: false,
                        open: false,
                      });
                    }
                  } else {
                    if (newStop.time_start === "") {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        newStop.day_start
                      )[0];
                      let _id = new ObjectID().toHexString();
                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        newStop,
                        this.props.params.idItineraryDetail,
                        itineraries[current].transportation,
                        _id,
                        this.state.categories
                      );
                      newPlaceDetail = {
                        ...newPlaceDetail,
                        isNew: true,
                      };
                      tempItem.place_detail.push(newPlaceDetail);
                      tempItem.place_detail.forEach((item, index) => {
                        item.order = index + 1;
                      });

                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(9);
                      this.setState({
                        isLoadingAdd: false,
                        open: false,
                      });
                    } else {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      // let index = itineraries[current].itinerary_detail.findIndex(
                      //   item => {
                      //     return item._id === this.props.params.idItineraryDetail;
                      //   }
                      // );
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        this.props.params.idItineraryDetail
                      )[0];
                      if (tempItem.place_detail.length > 0) {
                        this.setState({
                          listPlaceDetail: tempItem.place_detail,
                          length: tempItem.place_detail.length,
                          order: tempItem.place_detail.length + 1,
                        });
                      } else {
                        this.setState({
                          length: 0,
                          order: 1,
                        });
                      }
                      let _id = new ObjectID().toHexString();
                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        newStop,
                        newStop.day_start,
                        itineraries[current].transportation,
                        _id,
                        this.state.categories
                      );
                      newPlaceDetail = {
                        ...newPlaceDetail,
                        isNew: true,
                      };
                      tempItem.place_detail.push(newPlaceDetail);
                      for (
                        let i = 0;
                        i < tempItem.place_detail.length - 1;
                        i++
                      ) {
                        for (
                          let j = i + 1;
                          j < tempItem.place_detail.length;
                          j++
                        ) {
                          if (
                            tempItem.place_detail[j].data.actual_data
                              .time_start === "" ||
                            tempItem.place_detail[i].data.actual_data
                              .time_start === ""
                          )
                            continue;
                          if (
                            tempItem.place_detail[j].data.actual_data
                              .time_start <
                            tempItem.place_detail[i].data.actual_data.time_start
                          ) {
                            let t = tempItem.place_detail[i];
                            tempItem.place_detail[i] = tempItem.place_detail[j];
                            tempItem.place_detail[j] = t;
                          }
                        }
                      }

                      for (let i = 0; i < tempItem.place_detail.length; i++) {
                        tempItem.place_detail[i].order = i + 1;
                      }

                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(9);
                      this.setState({
                        isLoadingAdd: false,
                        open: false,
                      });
                    }
                  }
                  // this.apiService.totalAmountItinerary(this.props.params.idItinerary).then((res) => {
                  //     if (res.status === 'success') {
                  //         this.props.updateTotalAmount(res.total_amount)
                  //     }
                  // })
                }
              );
            } else {
              this.setState(
                {
                  newStop: {
                    ...newStop,
                    lat: "",
                    lng: "",
                  },
                },
                () => {
                  if (
                    newStop.day_start !== this.props.params.idItineraryDetail
                  ) {
                    if (newStop.time_start === "") {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      // let index = itineraries[current].itinerary_detail.findIndex(
                      //   item => {
                      //     return item._id === newStop.day_start;
                      //   }
                      // );
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        newStop.day_start
                      )[0];
                      let _id = new ObjectID().toHexString();
                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        newStop,
                        newStop.day_start,
                        itineraries[current].transportation,
                        _id,
                        this.state.categories
                      );
                      newPlaceDetail = {
                        ...newPlaceDetail,
                        isNew: true,
                      };
                      tempItem.place_detail.push(newPlaceDetail);
                      tempItem.place_detail.forEach((item, index) => {
                        item.order = index + 1;
                      });

                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(7);
                      this.setState({
                        isLoadingAdd: false,
                        open: false,
                      });
                    } else {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      // let index = itineraries[current].itinerary_detail.findIndex(
                      //   item => {
                      //     return item._id === newStop.day_start;
                      //   }
                      // );
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        newStop.day_start
                      )[0];
                      if (tempItem.place_detail.length > 0) {
                        this.setState({
                          listPlaceDetail: tempItem.place_detail,
                          length: tempItem.place_detail.length,
                          order: tempItem.place_detail.length + 1,
                        });
                      } else {
                        this.setState({
                          length: 0,
                          order: 1,
                        });
                      }
                      let _id = new ObjectID().toHexString();
                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        newStop,
                        newStop.day_start,
                        itineraries[current].transportation,
                        _id,
                        this.state.categories
                      );
                      newPlaceDetail = {
                        ...newPlaceDetail,
                        isNew: true,
                      };
                      tempItem.place_detail.push(newPlaceDetail);
                      for (
                        let i = 0;
                        i < tempItem.place_detail.length - 1;
                        i++
                      ) {
                        for (
                          let j = i + 1;
                          j < tempItem.place_detail.length;
                          j++
                        ) {
                          if (
                            tempItem.place_detail[j].data.actual_data
                              .time_start === "" ||
                            tempItem.place_detail[i].data.actual_data
                              .time_start === ""
                          )
                            continue;
                          if (
                            tempItem.place_detail[j].data.actual_data
                              .time_start <
                            tempItem.place_detail[i].data.actual_data.time_start
                          ) {
                            let t = tempItem.place_detail[i];
                            tempItem.place_detail[i] = tempItem.place_detail[j];
                            tempItem.place_detail[j] = t;
                          }
                        }
                      }

                      for (let i = 0; i < tempItem.place_detail.length; i++) {
                        tempItem.place_detail[i].order = i + 1;
                      }

                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(7);
                      this.setState({
                        isLoadingAdd: false,
                        open: false,
                      });
                    }
                  } else {
                    if (newStop.time_start === "") {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        newStop.day_start
                      )[0];
                      // let index = itineraries[current].itinerary_detail.findIndex(
                      //   item => {
                      //     return item._id === newStop.day_start;
                      //   }
                      // );
                      let _id = new ObjectID().toHexString();
                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        newStop,
                        this.props.params.idItineraryDetail,
                        itineraries[current].transportation,
                        _id,
                        this.state.categories
                      );
                      newPlaceDetail = {
                        ...newPlaceDetail,
                        isNew: true,
                      };
                      tempItem.place_detail.push(newPlaceDetail);
                      tempItem.place_detail.forEach((item, index) => {
                        item.order = index + 1;
                      });

                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(9);
                      this.setState({
                        isLoadingAdd: false,
                        open: false,
                      });
                    } else {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        this.props.params.idItineraryDetail
                      )[0];
                      if (tempItem.place_detail.length > 0) {
                        this.setState({
                          listPlaceDetail: tempItem.place_detail,
                          length: tempItem.place_detail.length,
                          order: tempItem.place_detail.length + 1,
                        });
                      } else {
                        this.setState({
                          length: 0,
                          order: 1,
                        });
                      }
                      let _id = new ObjectID().toHexString();
                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        newStop,
                        newStop.day_start,
                        itineraries[current].transportation,
                        _id,
                        this.state.categories
                      );
                      newPlaceDetail = {
                        ...newPlaceDetail,
                        isNew: true,
                      };
                      tempItem.place_detail.push(newPlaceDetail);
                      for (
                        let i = 0;
                        i < tempItem.place_detail.length - 1;
                        i++
                      ) {
                        for (
                          let j = i + 1;
                          j < tempItem.place_detail.length;
                          j++
                        ) {
                          if (
                            tempItem.place_detail[j].data.actual_data
                              .time_start === "" ||
                            tempItem.place_detail[i].data.actual_data
                              .time_start === ""
                          )
                            continue;
                          if (
                            tempItem.place_detail[j].data.actual_data
                              .time_start <
                            tempItem.place_detail[i].data.actual_data.time_start
                          ) {
                            let t = tempItem.place_detail[i];
                            tempItem.place_detail[i] = tempItem.place_detail[j];
                            tempItem.place_detail[j] = t;
                          }
                        }
                      }

                      for (let i = 0; i < tempItem.place_detail.length; i++) {
                        tempItem.place_detail[i].order = i + 1;
                      }

                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(9);
                      this.setState({
                        isLoadingAdd: false,
                        open: false,
                      });
                    }
                  }
                }
              );
            }
          }
        );
      } else {
        this.setState(
          {
            newStop: {
              ...newStop,
              lat: "",
              lng: "",
            },
          },
          () => {
            if (newStop.day_start !== this.props.params.idItineraryDetail) {
              if (newStop.time_start === "") {
                let itineraries;
                itineraries = loadItem("itineraries");
                let current = itineraries.findIndex((item) => {
                  return item._id === this.props.params.idItinerary;
                });
                // let index = itineraries[current].itinerary_detail.findIndex(
                //   item => {
                //     return item._id === newStop.day_start;
                //   }
                // );
                let tempItem = searchTree(
                  itineraries[current].itinerary_detail,
                  newStop.day_start
                )[0];
                let _id = new ObjectID().toHexString();
                let newPlaceDetail = createPlaceDetail(
                  -1,
                  newStop,
                  newStop.day_start,
                  itineraries[current].transportation,
                  _id,
                  this.state.categories
                );
                newPlaceDetail = {
                  ...newPlaceDetail,
                  isNew: true,
                };
                tempItem.place_detail.push(newPlaceDetail);
                tempItem.place_detail.forEach((item, index) => {
                  item.order = index + 1;
                });

                saveItem("itineraries", itineraries);
                let total_amount = calcTotalAmount(
                  this.props.params.idItinerary
                );
                this.props.updateTotalAmount(total_amount);
                this.props.newList(7);
                this.setState({
                  isLoadingAdd: false,
                  open: false,
                });
              } else {
                let itineraries;
                itineraries = loadItem("itineraries");
                let current = itineraries.findIndex((item) => {
                  return item._id === this.props.params.idItinerary;
                });
                // let index = itineraries[current].itinerary_detail.findIndex(
                //   item => {
                //     return item._id === newStop.day_start;
                //   }
                // );
                let tempItem = searchTree(
                  itineraries[current].itinerary_detail,
                  newStop.day_start
                )[0];
                if (tempItem.place_detail.length > 0) {
                  this.setState({
                    listPlaceDetail: tempItem.place_detail,
                    length: tempItem.place_detail.length,
                    order: tempItem.place_detail.length + 1,
                  });
                } else {
                  this.setState({
                    length: 0,
                    order: 1,
                  });
                }
                let _id = new ObjectID().toHexString();
                let newPlaceDetail = createPlaceDetail(
                  -1,
                  newStop,
                  newStop.day_start,
                  itineraries[current].transportation,
                  _id,
                  this.state.categories
                );
                newPlaceDetail = {
                  ...newPlaceDetail,
                  isNew: true,
                };
                tempItem.place_detail.push(newPlaceDetail);
                for (let i = 0; i < tempItem.place_detail.length - 1; i++) {
                  for (let j = i + 1; j < tempItem.place_detail.length; j++) {
                    if (
                      tempItem.place_detail[j].data.actual_data.time_start ===
                        "" ||
                      tempItem.place_detail[i].data.actual_data.time_start ===
                        ""
                    )
                      continue;
                    if (
                      tempItem.place_detail[j].data.actual_data.time_start <
                      tempItem.place_detail[i].data.actual_data.time_start
                    ) {
                      let t = tempItem.place_detail[i];
                      tempItem.place_detail[i] = tempItem.place_detail[j];
                      tempItem.place_detail[j] = t;
                    }
                  }
                }

                for (let i = 0; i < tempItem.place_detail.length; i++) {
                  tempItem.place_detail[i].order = i + 1;
                }

                saveItem("itineraries", itineraries);
                let total_amount = calcTotalAmount(
                  this.props.params.idItinerary
                );
                this.props.updateTotalAmount(total_amount);
                this.props.newList(7);
                this.setState({
                  isLoadingAdd: false,
                  open: false,
                });
              }
            } else {
              if (newStop.time_start === "") {
                let itineraries;
                itineraries = loadItem("itineraries");
                let current = itineraries.findIndex((item) => {
                  return item._id === this.props.params.idItinerary;
                });
                let tempItem = searchTree(
                  itineraries[current].itinerary_detail,
                  newStop.day_start
                )[0];
                // let index = itineraries[current].itinerary_detail.findIndex(
                //   item => {
                //     return item._id === newStop.day_start;
                //   }
                // );
                let _id = new ObjectID().toHexString();
                let newPlaceDetail = createPlaceDetail(
                  -1,
                  newStop,
                  this.props.params.idItineraryDetail,
                  itineraries[current].transportation,
                  _id,
                  this.state.categories
                );
                newPlaceDetail = {
                  ...newPlaceDetail,
                  isNew: true,
                };
                tempItem.place_detail.push(newPlaceDetail);
                tempItem.place_detail.forEach((item, index) => {
                  item.order = index + 1;
                });

                saveItem("itineraries", itineraries);
                let total_amount = calcTotalAmount(
                  this.props.params.idItinerary
                );
                this.props.updateTotalAmount(total_amount);
                this.props.newList(9);
                this.setState({
                  isLoadingAdd: false,
                  open: false,
                });
              } else {
                let itineraries;
                itineraries = loadItem("itineraries");
                let current = itineraries.findIndex((item) => {
                  return item._id === this.props.params.idItinerary;
                });
                let tempItem = searchTree(
                  itineraries[current].itinerary_detail,
                  this.props.params.idItineraryDetail
                )[0];
                if (tempItem.place_detail.length > 0) {
                  this.setState({
                    listPlaceDetail: tempItem.place_detail,
                    length: tempItem.place_detail.length,
                    order: tempItem.place_detail.length + 1,
                  });
                } else {
                  this.setState({
                    length: 0,
                    order: 1,
                  });
                }
                let _id = new ObjectID().toHexString();
                let newPlaceDetail = createPlaceDetail(
                  -1,
                  newStop,
                  newStop.day_start,
                  itineraries[current].transportation,
                  _id,
                  this.state.categories
                );
                newPlaceDetail = {
                  ...newPlaceDetail,
                  isNew: true,
                };
                tempItem.place_detail.push(newPlaceDetail);
                for (let i = 0; i < tempItem.place_detail.length - 1; i++) {
                  for (let j = i + 1; j < tempItem.place_detail.length; j++) {
                    if (
                      tempItem.place_detail[j].data.actual_data.time_start ===
                        "" ||
                      tempItem.place_detail[i].data.actual_data.time_start ===
                        ""
                    )
                      continue;
                    if (
                      tempItem.place_detail[j].data.actual_data.time_start <
                      tempItem.place_detail[i].data.actual_data.time_start
                    ) {
                      let t = tempItem.place_detail[i];
                      tempItem.place_detail[i] = tempItem.place_detail[j];
                      tempItem.place_detail[j] = t;
                    }
                  }
                }

                for (let i = 0; i < tempItem.place_detail.length; i++) {
                  tempItem.place_detail[i].order = i + 1;
                }

                saveItem("itineraries", itineraries);
                let total_amount = calcTotalAmount(
                  this.props.params.idItinerary
                );
                this.props.updateTotalAmount(total_amount);
                this.props.newList(9);
                this.setState({
                  isLoadingAdd: false,
                  open: false,
                });
              }
            }
          }
        );
      }

      this.props.closeFormAddNewStop();
    }
  };

  changeCategory = (value) => {
    this.setState({
      categoryTab: value,
    });
  };

  render() {
    return (
      <div className={styles.addNewStopForm}>
        <Dialog
          open={this.props.isDisplayFormAddNewStop ? true : false}
          aria-labelledby="form-add-new-stop"
          maxWidth={"md"}
          fullWidth={true}
          className={styles.addNewStopForm}
        >
          <MuiDialogTitle className="popup-title" disableTypography>
            <Typography variant="h6">Add new stop</Typography>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={this.handleCloseNewStop.bind(this)}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent dividers>
            <Row className={styles.addNewStopForm}>
              <Container>
                {this.state.categoryTab === 1 ? (
                  <AttractionTab
                    length={this.props.length}
                    params={this.props.params}
                    validate={this.state.validate}
                    onFillForm={this.onCreateNewStop}
                    categoryTab={this.state.categoryTab}
                    changeCategory={this.changeCategory}
                    categories={this.state.categories}
                  />
                ) : this.state.categoryTab === 2 ? (
                  <LodgingTab
                    length={this.props.length}
                    params={this.props.params}
                    validate={this.state.validate}
                    onFillForm={this.onCreateNewStop}
                    categoryTab={this.state.categoryTab}
                    changeCategory={this.changeCategory}
                    categories={this.state.categories}
                  />
                ) : this.state.categoryTab === 3 ? (
                  <RestaurantTab
                    length={this.props.length}
                    params={this.props.params}
                    validate={this.state.validate}
                    onFillForm={this.onCreateNewStop}
                    categoryTab={this.state.categoryTab}
                    changeCategory={this.changeCategory}
                    categories={this.state.categories}
                  />
                ) : this.state.categoryTab === 4 ? (
                  <ActivityTab
                    length={this.props.length}
                    params={this.props.params}
                    validate={this.state.validate}
                    onFillForm={this.onCreateNewStop}
                    categoryTab={this.state.categoryTab}
                    changeCategory={this.changeCategory}
                    categories={this.state.categories}
                  />
                ) : this.state.categoryTab === 5 ? (
                  <TransportationTab
                    length={this.props.length}
                    params={this.props.params}
                    validate={this.state.validate}
                    onFillForm={this.onCreateNewStop}
                    categoryTab={this.state.categoryTab}
                    changeCategory={this.changeCategory}
                    categories={this.state.categories}
                  />
                ) : (
                  <InforTab
                    length={this.props.length}
                    params={this.props.params}
                    validate={this.state.validate}
                    onFillForm={this.onCreateNewStop}
                    categoryTab={this.state.categoryTab}
                    changeCategory={this.changeCategory}
                    categories={this.state.categories}
                  />
                )}
              </Container>
            </Row>
          </DialogContent>
          <DialogActions className={styles.addNewStopForm}>
            <Button
              disabled={
                this.state.isLoadingSave || this.state.isLoadingAdd
                  ? true
                  : false
              }
              id="add-button"
              type="submit"
              className="btn-primary"
              onClick={this.onAddNewStop.bind(this)}
            >
              {this.state.isLoadingAdd ? <CircularProgress /> : "Add"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewStopForm);
