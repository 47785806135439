import React, { Component } from "react";
import style from "./index.scss";
import { MdLocationOn } from "react-icons/md";
import moment from "moment";
import { isMyResource, getImageFromUrl } from "../../services/utils.service";
import { configBaseUrl } from "../../config";
import { Modal } from "antd";
import ApiService from "../../services/api.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { withRouter } from "react-router";
import { loadItem, saveItem } from "../../services/storage.service";
import formatMoney from "accounting-js/lib/formatMoney.js";

class CardTopNew extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {};
  }

  showConfirm = (_id) => (e) => {
    e.preventDefault();
    Modal.confirm({
      content: "Are you sure you want to delete this itinerary?",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => {
        if (!this.props.isDraft) {
          this.apiService.deleteItinerary(_id).then((res) => {
            if (res.status === "success") {
              const index = this.props.list.findIndex((item) => {
                return item._id === _id;
              });
              let temp = [...this.props.list];
              if (index > -1) {
                temp.splice(index, 1);
              }
              this.props.setNewListMyPlans(temp);
              Swal.fire({
                type: "success",
                title: "Delete successfully",
                showConfirmButton: true,
              });
            }
          });
        } else {
          if (loadItem("itineraryDraft") !== null) {
            var newDraft = loadItem("itineraryDraft");
            var indexDelete = newDraft.itineraries.findIndex((item) => {
              return item._id === _id;
            });
            if (indexDelete >= 0) {
              newDraft.itineraries.splice(indexDelete, 1);
              saveItem("itineraryDraft", newDraft);
              this.props.setNewListDraft(newDraft);
              Swal.fire({
                type: "success",
                title: "Delete successfully",
                showConfirmButton: true,
              });
            }
          }
        }
      },
      onCancel() {},
    });
  };

  redirectToEdit = (_id, idItineraryDetail) => (e) => {
    e.preventDefault();
    this.props.history.push(`/itinerary/${_id}/${idItineraryDetail}`);
  };

  render() {
    return (
      <div className={style.CardTopNew}>
        <div className="sidebar-idea-container ember-view draggable-item zoom-place">
          <div className="ember-view component-idea-tile idea-tile-horizontal _can-view-details">
            {!this.props.isDraft && (
              <figure
                className="ember-view small-view"
                style={{
                  backgroundImage: `url(${getImageFromUrl(
                    isMyResource(this.props.cover)
                      ? `${configBaseUrl.baseURL + this.props.cover}`
                      : `${this.props.cover}`,
                    "large"
                  )})`,
                }}
              >
                {!this.props.isDraft && (
                  <div className="btn _btn-small _btn-outline-white">
                    {" "}
                    View{" "}
                  </div>
                )}
              </figure>
            )}

            <ul className="_c-info">
              <li
                className="title"
                style={this.props.isDraft ? { fontSize: `1.2rem` } : null}
              >
                {this.props.isDraft ? (
                  <span
                    onClick={this.redirectToEdit(
                      this.props._id,
                      this.props.idItineraryDetail
                    )}
                    style={{ color: `#0f95de`, fontSize: '1.1rem' }}
                  >
                    {this.props.name}{" "}
                    {this.props.estimated_budget
                      ? `on a ${formatMoney(this.props.estimated_budget, {
                          symbol: this.props.currency_infor.symbol,
                          precision: this.props.currency_infor.decimal_digits,
                          thousand: ",",
                          decimal: ".",
                        })}${" "}
                        ${this.props.currency_infor.code} budget`
                      : ``}
                    {this.props.a_number_of_paxs
                      ? ` for ${this.props.a_number_of_paxs} pax`
                      : ""}{" "}
                    <i className="fas fa-pencil-alt"></i>
                  </span>
                ) : (
                  <span>
                    {this.props.name}{" "}
                    {this.props.estimated_budget
                      ? `on a ${formatMoney(this.props.estimated_budget, {
                          symbol: this.props.currency_infor.symbol,
                          precision: this.props.currency_infor.decimal_digits,
                          thousand: ",",
                          decimal: ".",
                        })}${" "}
                        ${this.props.currency_infor.code} budget`
                      : ``}
                    {this.props.a_number_of_paxs
                      ? ` for ${this.props.a_number_of_paxs} pax`
                      : ""}
                  </span>
                )}
              </li>
              {!this.props.isMyPlan && (
                <li className="created">
                  <div>
                    {this.props.created_at ? (
                      <>
                        By&nbsp;
                        <span className="created__name">
                          {this.props.created_by.firstName}{" "}
                          {this.props.created_by.lastName}
                        </span>
                        &nbsp;-{" "}
                        {moment(new Date(this.props.created_at)).fromNow()}
                      </>
                    ) : (
                      <>
                        By&nbsp;
                        <span className="created__name">
                          {this.props.created_by.firstName}{" "}
                          {this.props.created_by.lastName}
                        </span>
                      </>
                    )}
                  </div>
                </li>
              )}
              {this.props.isMyPlan && (
                <div
                  className="action"
                  style={
                    this.props.isDraft
                      ? { fontSize: `1rem`, justifyContent: `flex-end` }
                      : null
                  }
                >
                  <div
                    onClick={this.redirectToEdit(
                      this.props._id,
                      this.props.idItineraryDetail
                    )}
                  >
                    {!this.props.isDraft && (
                      <span className="edit">
                        <i className="fas fa-pencil-alt"></i> Edit
                      </span>
                    )}
                  </div>
                  <div onClick={this.showConfirm(this.props._id)}>
                    <span className="delete">
                      <i className="far fa-trash-alt"></i>{" "}
                      {this.props.isDraft ? "Clear" : "Delete"}
                    </span>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CardTopNew);
