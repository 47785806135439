import React from "react";
import { Input, Button, Form, Radio, Avatar } from "antd";
import { connect } from "react-redux";
import styles from "./index.scss";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ApiService from "../../services/api.service";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { loadItem, saveItem } from "../../services/storage.service";
import { withRouter } from "react-router";
import {
  searchTree,
  updateTree,
  isMyResource,
  getAllLeaf,
  moveOutDrafts,
  convertAdvanceFieldToNormal,
} from "../../services/utils.service";
import {
  openFormViewEditPlace,
  closeFormViewEditPlace,
} from "../../store/actions";

const mapDispatchToProps = (dispatch) => {
  return {
    openFormViewEditPlace: (item) => {
      dispatch(openFormViewEditPlace(item));
    },
    closeFormViewEditPlace: () => {
      dispatch(closeFormViewEditPlace());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    selected2: state.listPlacesExploreReducer,
    itinerary: state.itineraryReducer,
    currency: state.itineraryReducer.currency,
    currency_infor: state.itineraryReducer.currency_infor,
    total_amount: state.itineraryReducer.total_amount,
    isReloadPdf: state.filePdfReducer,
    profile: state.profileReducer.info,
    company: state.profileReducer.company,
    isLoadingExplore: state.loadingReducer,
    showLoad: state.formLoadReducer,
    saveOrSaveAsAfterLogin: state.saveAfterLoginReducer,
    listLoad: state.listLoadReducer.list,
  };
};

class FormSaveToLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      createLibraryMode: false,
      myLibraries: [],

      isExistInLibrary: false,
    };
  }

  componentDidMount = async () => {
    await this.apiService.getMyLibraries().then((res) => {
      if (res.status === "success") {
        this.setState({
          myLibraries: res.libraries,
        });
      }
    });
    if (this.props.item.belong_to_library) {
      await this.apiService
        .getLibraryForPlace(this.props.item.belong_to_library)
        .then((res) => {
          if (res.status === "success") {
            if (res.libraryPlace) {
              this.setState({
                isExistInLibrary: true,
              });
              this.props.form.setFieldsValue({
                library: res.libraryPlace._id,
              });
            }
          }
        });
    }
  };

  handleAutoSave = () => {
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.match.params.idItinerary;
    });
    if (
      this.props.profile._id === itineraries[current].belong_to_user ||
      !itineraries[current].belong_to_user
    ) {
      itineraries[current].belong_to_user = this.props.profile._id;
      itineraries[
        current
      ].written_by = `${this.props.profile.firstName} ${this.props.profile.lastName}`;
      itineraries[current].last_updated = new Date();
      itineraries[current].saved = 1;
      this.props.changeStatusSaved();
      if (itineraries[current].cover)
        itineraries[current].cover = itineraries[current].cover.replace(
          "storage/uploads",
          "images/cover"
        );
      itineraries[current] = convertAdvanceFieldToNormal(
        itineraries[current],
        this.props.profile
      );
      saveItem("itineraries", itineraries);
      // this.props.socket.emit("save_change", {
      //   idItinerary: this.props.params.idItinerary,
      //   itinerary: itineraries[current],
      // });
      getAllLeaf(itineraries[current].itinerary_detail).forEach((root) => {
        root.place_detail.forEach((child) => {
          if (child.data.actual_data.images) {
            child.data.actual_data.images.forEach((item) => {
              if (isMyResource(item.thumbUrl)) {
                item.thumbUrl = item.thumbUrl.replace(
                  "storage\\uploads",
                  "images/galleries"
                );
                item.xhr = item.xhr.replace(
                  "storage\\uploads",
                  "images/galleries"
                );
                item.preview = item.preview.replace(
                  "storage\\uploads",
                  "images/galleries"
                );
              }
            });
          }
        });
      });
      moveOutDrafts(itineraries[current]._id);
      this.apiService
        .saveItinerary(itineraries[current], this.props.profile)
    }
  };

  handleChooseFolder = (e) => {
    e.preventDefault();
    if (!this.state.myLibraries.length) {
      this.props.closeFormSaveToLibrary();
      return;
    }
    this.setState({
      loadingChooseFolder: true,
    });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let temp = { ...this.props.item };
        if (temp.category === "Activity") {
          temp.data.actual_data.amount = "";
          temp.data.actual_data.booked_through = "";
          temp.data.actual_data.confirmation = "";
          temp.data.actual_data.day_start = "";
          temp.data.actual_data.duration = "";
          temp.data.actual_data.provider = "";
          temp.data.actual_data.time_start = "";
          temp.data.actual_data.timezone = "";
        }
        if (temp.category === "Restaurant") {
          temp.data.actual_data.amount = "";
          temp.data.actual_data.booked_through = "";
          temp.data.actual_data.confirmation = "";
          temp.data.actual_data.day_start = "";
          temp.data.actual_data.duration = "";
          temp.data.actual_data.provider = "";
          temp.data.actual_data.time_start = "";
          temp.data.actual_data.timezone = "";
        }
        if (temp.category === "Attraction") {
          temp.data.actual_data.amount = "";
          temp.data.actual_data.booked_through = "";
          temp.data.actual_data.confirmation = "";
          temp.data.actual_data.day_start = "";
          temp.data.actual_data.duration = "";
          temp.data.actual_data.provider = "";
          temp.data.actual_data.time_start = "";
          temp.data.actual_data.timezone = "";
        }
        if (temp.category === "Lodging") {
          temp.data.actual_data.amount = "";
          temp.data.actual_data.booked_through = "";
          temp.data.actual_data.confirmation = "";
          temp.data.actual_data.day_start = "";
          temp.data.actual_data.duration = "";
          temp.data.actual_data.time_start = "";
          temp.data.actual_data.timezone = "";
        }
        if (temp.category === "Transportation") {
          temp.data.actual_data.amount = "";
          temp.data.actual_data.booked_through = "";
          temp.data.actual_data.confirmation = "";
          temp.data.actual_data.day_start = "";
          temp.data.actual_data.duration = "";
          temp.data.actual_data.time_start = "";
          temp.data.actual_data.timezone = "";
        }
        if (temp.category === "Infor") {
          temp.data.actual_data.day_start = "";
          temp.data.actual_data.time_start = "";
        }
        delete temp.order;
        delete temp.belong_to_itinerary_detail;

        if (this.props.manage) {
          values = {
            ...values,
            _id: this.props.item._id,
            place: temp,
            manage: true,
          };
        } else {
          values = {
            ...values,
            _id: this.props.item.belong_to_library_place
              ? this.props.item.belong_to_library_place
              : null,
            place: temp,
          };
        }

        this.apiService.chooseLibrary(values).then(async (res) => {
          if (res.status === "success") {
            if (this.props.manage) {
              this.props.setNewListLibrary();
              this.props.closeFormSaveToLibrary();
            } else {
              this.setState({
                loadingChooseFolder: false,
                createLibraryMode: false,
              });

              let itineraries;
              itineraries = loadItem("itineraries");
              let current = itineraries.findIndex((item) => {
                return item._id === this.props.match.params.idItinerary;
              });
              let tempItem = searchTree(
                itineraries[current].itinerary_detail,
                this.props.match.params.idItineraryDetail
              )[0];

              var newItem = null;
              tempItem.place_detail.forEach((item) => {
                if (item._id === this.props.item._id) {
                  item.belong_to_library = values.library;
                  item.belong_to_library_place = !values.place
                    .belong_to_library_place
                    ? res.belong_to_library_place
                    : item.belong_to_library_place;
                  newItem = item;
                }
              });

              let updated = updateTree(
                itineraries[current].itinerary_detail,
                tempItem
              );
              itineraries[current].itinerary_detail = updated;
              saveItem("itineraries", itineraries);

              this.props.closeFormSaveToLibrary();
              if (this.props.setMarked) {
                this.handleAutoSave()
                this.props.setMarked();
                this.props.setBelongLibrary({
                  belong_to_library: newItem.belong_to_library,
                  belong_to_library_place: newItem.belong_to_library_place,
                });
              }

              await this.apiService.nameLibrary(values).then((result) => {
                if (res.status === "success") {
                  Swal.fire({
                    type: "success",
                    title: `Saved to "${result.library.name}"`,
                    showConfirmButton: true,
                  });
                  this.props.newList(2);
                  if (!this.props.setMarked) {
                    this.props.closeFormViewEditPlace();
                    this.props.openFormViewEditPlace(newItem);
                  }
                }
              });
            }
          }
        });
      } else {
        this.setState({
          loadingChooseFolder: false,
        });
      }
    });
  };

  showCreateLibrary = () => {
    this.setState({ createLibraryMode: true });
  };

  hideCreateLibrary = () => {
    this.setState({ createLibraryMode: false });
  };

  handleCreateLibrary = (e) => {
    e.preventDefault();
    this.setState({
      loadingCreateLibrary: true,
    });
    this.props.form.validateFields(["name"], (err, values) => {
      if (!err) {
        let temp = { ...this.props.item };
        if (temp.category === "Activity") {
          temp.data.actual_data.amount = "";
          temp.data.actual_data.booked_through = "";
          temp.data.actual_data.confirmation = "";
          temp.data.actual_data.day_start = "";
          temp.data.actual_data.duration = "";
          temp.data.actual_data.provider = "";
          temp.data.actual_data.time_start = "";
          temp.data.actual_data.timezone = "";
        }
        if (temp.category === "Restaurant") {
          temp.data.actual_data.amount = "";
          temp.data.actual_data.booked_through = "";
          temp.data.actual_data.confirmation = "";
          temp.data.actual_data.day_start = "";
          temp.data.actual_data.duration = "";
          temp.data.actual_data.provider = "";
          temp.data.actual_data.time_start = "";
          temp.data.actual_data.timezone = "";
        }
        if (temp.category === "Attraction") {
          temp.data.actual_data.amount = "";
          temp.data.actual_data.booked_through = "";
          temp.data.actual_data.confirmation = "";
          temp.data.actual_data.day_start = "";
          temp.data.actual_data.duration = "";
          temp.data.actual_data.provider = "";
          temp.data.actual_data.time_start = "";
          temp.data.actual_data.timezone = "";
        }
        if (temp.category === "Lodging") {
          temp.data.actual_data.amount = "";
          temp.data.actual_data.booked_through = "";
          temp.data.actual_data.confirmation = "";
          temp.data.actual_data.day_start = "";
          temp.data.actual_data.duration = "";
          temp.data.actual_data.time_start = "";
          temp.data.actual_data.timezone = "";
        }
        if (temp.category === "Transportation") {
          temp.data.actual_data.amount = "";
          temp.data.actual_data.booked_through = "";
          temp.data.actual_data.confirmation = "";
          temp.data.actual_data.day_start = "";
          temp.data.actual_data.duration = "";
          temp.data.actual_data.time_start = "";
          temp.data.actual_data.timezone = "";
        }
        if (temp.category === "Infor") {
          temp.data.actual_data.day_start = "";
          temp.data.actual_data.time_start = "";
        }
        delete temp.order;
        delete temp.belong_to_itinerary_detail;

        if (this.props.manage) {
          values = {
            ...values,
            place: temp,
            manage: true,
          };
        } else {
          values = {
            ...values,
            place: temp,
          };
        }
        

        this.apiService.createLibrary(values).then((res) => {
          if (res.status === "success") {
            if (this.props.manage) {
              this.props.setNewListLibrary();
              this.props.closeFormSaveToLibrary();
            } else {
              this.setState({
                loadingCreateLibrary: false,
                createLibraryMode: false,
                myLibraries: [...this.state.myLibraries, res.library],
              });

              let itineraries;
              itineraries = loadItem("itineraries");
              let current = itineraries.findIndex((item) => {
                return item._id === this.props.match.params.idItinerary;
              });
              let tempItem = searchTree(
                itineraries[current].itinerary_detail,
                this.props.match.params.idItineraryDetail
              )[0];

              let newItem = null;
              tempItem.place_detail.forEach((item) => {
                if (item._id === this.props.item._id) {
                  item.belong_to_library = res.library._id;
                  item.belong_to_library_place = res.belong_to_library_place;
                  newItem = item;
                }
              });

              let updated = updateTree(
                itineraries[current].itinerary_detail,
                tempItem
              );
              itineraries[current].itinerary_detail = updated;
              saveItem("itineraries", itineraries);

              this.props.closeFormSaveToLibrary();
              if (this.props.setMarked) {
                this.handleAutoSave()
                this.props.setMarked();
                this.props.setBelongLibrary({
                  belong_to_library: newItem.belong_to_library,
                  belong_to_library_place: newItem.belong_to_library_place,
                });
              }
              this.props.form.setFieldsValue({
                library: res.library._id,
              });
              this.props.newList(2);
              Swal.fire({
                type: "success",
                title: `Saved to "${values.name}"`,
                showConfirmButton: true,
              });
              if (!this.props.setMarked) {
                this.props.closeFormViewEditPlace();
                this.props.openFormViewEditPlace(newItem);
              }
            }
          }
        });
      } else {
        this.setState({
          loadingCreateLibrary: false,
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Dialog
        open={this.props.open ? true : false}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <MuiDialogTitle className="popup-title" disableTypography>
          <Typography variant="h6">
            {this.props.manage ? "Move to" : "Save to My Library"}
          </Typography>
          <IconButton
            aria-label="close"
            className="btn-close"
            onClick={() => {
              this.props.closeFormSaveToLibrary();
            }}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent dividers className={styles.formSaveToLibrary}>
          <Form onSubmit={this.handleChooseFolder}>
            {!!this.state.myLibraries.length && (
              <Form.Item name="radio-group">
                {getFieldDecorator("library", {
                  rules: [
                    {
                      required: true,
                      message: "Please choose library!",
                    },
                  ],
                })(
                  <Radio.Group>
                    {this.state.myLibraries.map((item, key) => (
                      <>
                        <Radio value={item._id} key={key}>
                          <Avatar
                            shape="square"
                            size="large"
                            style={{
                              color: "#f56a00",
                              backgroundColor: "#fde3cf",
                            }}
                          >
                            {item.name.charAt(0).toUpperCase()}
                          </Avatar>{" "}
                          {item.name}
                        </Radio>
                      </>
                    ))}
                  </Radio.Group>
                )}
              </Form.Item>
            )}
          </Form>
        </DialogContent>
        <DialogActions>
          {!this.state.createLibraryMode ? (
            <div className={styles.formSaveToLibrary}>
              <div
                style={{
                  cursor: "pointer",
                  borderBottom: `1px solid #e8e8e8`,
                  paddingBottom: `12px`,
                  width: `100%`,
                  padding: `12px 0px`,
                }}
                onClick={this.showCreateLibrary}
              >
                <Avatar
                  shape="square"
                  size="large"
                  style={{
                    color: "#fff",
                    backgroundColor: "#ccc",
                  }}
                >
                  +
                </Avatar>{" "}
                New folder
              </div>
              <div
                style={{
                  paddingBottom: `12px`,
                  width: `100%`,
                  padding: `12px 0px`,
                  textAlign: "right",
                }}
              >
                <Button
                  onClick={this.handleChooseFolder}
                  key="submit"
                  type="primary"
                  className="btn-choose-folder"
                >
                  {this.props.manage
                    ? "Move"
                    : this.state.isExistInLibrary
                    ? "Overwrite"
                    : "Done"}
                </Button>
              </div>
            </div>
          ) : (
            <div className={styles.formSaveToLibrary}>
              <div
                style={{
                  cursor: "pointer",
                  borderBottom: `1px solid #e8e8e8`,
                  paddingBottom: `12px`,
                  width: `100%`,
                  padding: `12px 0px`,
                }}
              >
                <Form onSubmit={this.handleCreateLibrary}>
                  <Form.Item label="Folder name">
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your folder name!",
                        },
                      ],
                    })(<Input autoFocus placeholder="Folder name" />)}
                  </Form.Item>
                </Form>
              </div>
              <div
                style={{
                  paddingBottom: `12px`,
                  width: `100%`,
                  padding: `12px 0px`,
                  textAlign: "right",
                }}
              >
                <Button onClick={this.hideCreateLibrary} key="back">
                  Cancel
                </Button>
                <Button
                  key="submit"
                  loading={this.state.loadingCreateLibrary}
                  onClick={this.handleCreateLibrary}
                  type="primary"
                >
                  Create
                </Button>
              </div>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(FormSaveToLibrary))
);
