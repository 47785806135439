import _ from "lodash";

const LodashHelper = {
    loopFindId: (id, items) => {
        return _.flatten(_.compact(_.flatten(_.map(items, (item, i) => {
            if (item.children && item.children.length) {
                if (!_.isEmpty(LodashHelper.loopFindId(id, item.children)))
                    return _.compact(_.flatten(LodashHelper.loopFindId(id, item.children)));
            }else {
                if (item._id === id) {
                    item.index = i + 1;
                    return item;
                }
            }
        }))));
    },
    loopFind: (target, key, val, items) => {
        return _.flatten(_.compact(_.flatten(_.map(items, (item, i) => {
            if (item && item[target] && _.isArray(item[target]) && item[target].length) {
                if (!_.isEmpty(LodashHelper.loopFind(target, key, val, item[target])))
                    return _.compact(_.flatten(LodashHelper.loopFind(target, key, val, item[target])));
            }else {
                if (item && item[key] === val) {
                    return item;
                }
            }
        }))));
    }
};

export default LodashHelper;