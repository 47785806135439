import React, { Component } from "react";
import {
  ProfileForm,
  CreateItineraryForm
} from "../../components";
import ApiService from "../../services/api.service";
import styles from "./index.scss";
import { Layout, Menu, Icon, Modal } from "antd";
import Button from "@material-ui/core/Button";
import { IoIosLock } from "react-icons/io";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withRouter } from "react-router-dom";

const { Sider, Content } = Layout;
const { confirm } = Modal;

class MyProfile extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      redirect: false,
      itinerary: {},

      showCreateItineraryForm: false
    };
  }

  componentDidMount = () => {};

  closeCreateItineraryForm = () => {
    this.setState({
      showCreateItineraryForm: false
    });
  };

  showFormCreateItinerary = () => {
    this.setState({
      showCreateItineraryForm: true
    });
  };

  handleClick = e => {
    if (e.key === "1") {
      this.props.history.push("/my-tyneri");
    } else if (e.key === "2") {
      confirm({
        title: "Please upgrade your plan to proceed",
        onOk: () => {
          console.log("OK");
        }
      });
    } else if (e.key === "3") {
      confirm({
        title: "Please upgrade your plan to proceed",
        onOk: () => {
          console.log("OK");
        }
      });
    } else if (e.key === "4") {
      confirm({
        title: "Please upgrade your plan to proceed",
        onOk: () => {
          console.log("OK");
        }
      });
    } else if (e.key === "5") {
      this.props.history.push("/my-profile");
    } else if (e.key === "6") {
      this.props.history.push("/billing");
    }
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <Dialog
          open={this.state.showCreateItineraryForm ? true : false}
          fullWidth={true}
          maxWidth={"sm"}
          aria-labelledby="create-itinerary-form"
          className={styles.contentTrendy}
        >
          <MuiDialogTitle disableTypography className="popup-title">
            <Typography variant="h6">Build Your Own Itinerary</Typography>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={this.closeCreateItineraryForm.bind(this)}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent style={{ padding: `0 15px` }}>
            <CreateItineraryForm />
          </DialogContent>
        </Dialog>

        <div xl="12" id="container">
          <Layout style={{ minHeight: `calc(100vh - 94px - 53px)` }}>
            <Sider width="300" breakpoint="lg" collapsedWidth="0">
            <div style={{ margin: "1rem 0", textAlign: "center", padding: `0 15px` }}>
                <Button
                  type="button"
                  size="large"
                  className="create-tyneri"
                  fullWidth
                  onClick={this.showFormCreateItinerary.bind(this)}
                >
                  Create New Tyneri
                </Button>
              </div>

              <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={["5"]}
                onClick={this.handleClick}
              >
                <Menu.Item key="1">
                  <Icon type="environment" />
                  <span>My Tyneri</span>
                </Menu.Item>
                <Menu.Item key="2">
                  <Icon type="user-add" />
                  <span>Contacts</span>
                  <IoIosLock />
                </Menu.Item>
                <Menu.Item key="3">
                  <Icon type="folder-open" />
                  <span>Inquires</span>
                  <IoIosLock />
                </Menu.Item>
                <Menu.Item key="4">
                  <Icon type="calendar" />
                  <span>Calendar</span>
                  <IoIosLock />
                </Menu.Item>
                <Menu.Item key="5">
                  <Icon type="user" />
                  <span>Profile</span>
                </Menu.Item>
                <Menu.Item key="6">
                  <Icon type="dollar" />
                  <span>Billing</span>
                </Menu.Item>
                <Menu.Item key="7">
                  <Icon type="setting" />
                  <span>Settings</span>
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout className="site-layout">
              <Content className="site-layout-background">
                <ProfileForm />
              </Content>
            </Layout>
          </Layout>
        </div>
      </div>
    );
  }
}

export default withRouter(MyProfile);
