import { actionTypes } from "../../constants/actionTypes";

var initialState = false;

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_FORM_LOAD:
      state = true;
      return state;
    case actionTypes.CLOSE_FORM_LOAD:
      state = false;
      return state;
    default:
      return state;
  }
};
