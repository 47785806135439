/*global google*/
import React, { Component } from "react";
import { Row, Container } from "reactstrap";
import { MdDelete } from "react-icons/md";
import { Modal } from "antd";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Button from "@material-ui/core/Button";
import {
  AttractionTab,
  ActivityTab,
  RestaurantTab,
  LodgingTab,
  TransportationTab,
  InforTab,
} from "../../components";
import { closeFormViewEditPlace, updateTotalAmount } from "../../store/actions";
import { connect } from "react-redux";
import ApiService from "../../services/api.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./index.scss";
import { loadItem, saveItem } from "../../services/storage.service.js";
import {
  createPlaceDetail,
  createMyPlace,
  searchTree,
  calcTotalAmount,
} from "../../services/utils.service";
import FormSaveToLibrary from "../form-save-to-library";
import _ from "lodash";
import { categoriesDefined } from "../../constants/categories";

const mapStateToProps = (state) => {
  return {
    isDisplayFormViewEditPlace: state.formViewEditPlaceReducer.open,
    item: state.formViewEditPlaceReducer.item,
    profile: state.profileReducer.info,
  };
};

const { confirm } = Modal;

const mapDispatchToProps = (dispatch) => {
  return {
    closeFormViewEditPlace: () => {
      dispatch(closeFormViewEditPlace());
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
  };
};

class FromViewEditPlace extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      categoryTab: 1,
      open: false,
      newStop: null,
      isLoadingDelete: false,
      isLoadingSave: false,
      isLoadingSaveToMyPlaces: false,
      validate: true,
      length: 0,
      order: 0,
      listPlaceDetail: [],

      openFormSaveToLibrary: false,
      categories: [],
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.apiService.getAttractionCategories().then((res) => {
      if (res.status === "success")
        this.setState(
          {
            categories: res.categories,
          },
          () => {
            if (this.props.item) {
              let temp = _.find(this.state.categories, (category) => {
                return category._id === this.props.item.category;
              });

              if (temp.name === categoriesDefined.ATTRACTION) {
                if (this._isMounted) {
                  this.setState({
                    categoryTab: 1,
                  });
                }
              }
              if (temp.name === categoriesDefined.ACCOMMODATION) {
                if (this._isMounted) {
                  this.setState({
                    categoryTab: 2,
                  });
                }
              }
              if (temp.name === categoriesDefined.RESTAURANT) {
                if (this._isMounted) {
                  this.setState({
                    categoryTab: 3,
                  });
                }
              }
              if (temp.name === categoriesDefined.TOURACTIVITY) {
                if (this._isMounted) {
                  this.setState({
                    categoryTab: 4,
                  });
                }
              }
              if (temp.name === categoriesDefined.TRANSPORTATION) {
                if (this._isMounted) {
                  this.setState({
                    categoryTab: 5,
                  });
                }
              }
              if (temp.name === categoriesDefined.INFORMATION) {
                if (this._isMounted) {
                  this.setState({
                    categoryTab: 6,
                  });
                }
              }
            }
          }
        );
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.item) {
      let temp = _.find(this.state.categories, (category) => {
        return category._id === nextProps.item.category;
      });

      if (temp.name === categoriesDefined.ATTRACTION) {
        if (this._isMounted) {
          this.setState({
            categoryTab: 1,
          });
        }
      }
      if (temp.name === categoriesDefined.ACCOMMODATION) {
        if (this._isMounted) {
          this.setState({
            categoryTab: 2,
          });
        }
      }
      if (temp.name === categoriesDefined.RESTAURANT) {
        if (this._isMounted) {
          this.setState({
            categoryTab: 3,
          });
        }
      }
      if (temp.name === categoriesDefined.TOURACTIVITY) {
        if (this._isMounted) {
          this.setState({
            categoryTab: 4,
          });
        }
      }
      if (temp.name === categoriesDefined.TRANSPORTATION) {
        if (this._isMounted) {
          this.setState({
            categoryTab: 5,
          });
        }
      }
      if (temp.name === categoriesDefined.INFORMATION) {
        if (this._isMounted) {
          this.setState({
            categoryTab: 6,
          });
        }
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleViewAndEditPlace = () => {
    if (!this.state.open) {
      this.setState({
        open: true,
      });
    }
  };

  handleCloseViewAndEditPlace = () => {
    if (
      this.state.isLoadingDelete ||
      this.state.isLoadingSave ||
      this.state.isLoadingSaveToMyPlaces
    )
      return;
    if (this.props.item && this.props.item.category === "Attraction")
      this.setState({
        categoryTab: 1,
      });
    if (this.props.item && this.props.item.category === "Lodging")
      this.setState({
        categoryTab: 2,
      });
    if (this.props.item && this.props.item.category === "Restaurant")
      this.setState({
        categoryTab: 3,
      });
    if (this.props.item && this.props.item.category === "Activity")
      this.setState({
        categoryTab: 4,
      });
    if (this.props.item && this.props.item.category === "Transportation")
      this.setState({
        categoryTab: 5,
      });
    if (this.props.item && this.props.item.category === "Infor")
      this.setState({
        categoryTab: 6,
      });
    this.props.closeFormViewEditPlace();
  };

  handleChangeActivityTab = () => {
    this.setState({
      categoryTab: 1,
    });
  };

  handleChangeLodgingTab = () => {
    this.setState({
      categoryTab: 2,
    });
  };

  validate = (newStop) => {
    if (!newStop || newStop.title === "" || !newStop.title.trim()) return false;
    return true;
  };

  handleDeletePlace = async () => {
    confirm({
      title: "Are you sure delete this item?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        this.setState({
          isLoadingDelete: true,
        });
        let itineraries;
        itineraries = loadItem("itineraries");
        let current = itineraries.findIndex((item) => {
          return item._id === this.props.params.idItinerary;
        });

        let tempItem = searchTree(
          itineraries[current].itinerary_detail,
          this.props.params.idItineraryDetail
        )[0];

        let j = tempItem.place_detail.findIndex((item) => {
          return item._id === this.state.newStop._id;
        });
        tempItem.place_detail.splice(j, 1);
        tempItem.place_detail.forEach((item, index) => {
          item.order = index + 1;
        });
        saveItem("itineraries", itineraries);
        let total_amount = calcTotalAmount(this.props.params.idItinerary);
        this.props.updateTotalAmount(total_amount);
        this.setState(
          {
            isLoadingDelete: false,
          },
          () => {
            this.props.newList(1);
          }
        );
        await this.props.closeFormViewEditPlace();
      },
      onCancel: () => {},
    });
  };

  onCreateNewStop = (newStop) => {
    this.setState({
      newStop: newStop,
    });
  };

  onSaveNewStop = async () => {
    if (!this.validate(this.state.newStop)) {
      this.setState({
        validate: false,
      });
    } else {
      this.setState({
        isLoadingSave: true,
      });

      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });

      let tempItemTemp = searchTree(
        itineraries[current].itinerary_detail,
        this.props.params.idItineraryDetail
      )[0];

      let indexCurrentPlaceDetail = tempItemTemp.place_detail.findIndex(
        (item) => {
          return item._id === this.props.item._id;
        }
      );

      var newStop = {
        ...this.state.newStop,
        quote: tempItemTemp.place_detail[indexCurrentPlaceDetail].quote
          ? tempItemTemp.place_detail[indexCurrentPlaceDetail].quote
          : null,
        place_id: tempItemTemp.place_detail[indexCurrentPlaceDetail].place_id
          ? tempItemTemp.place_detail[indexCurrentPlaceDetail].place_id
          : null,
        vendor: tempItemTemp.place_detail[indexCurrentPlaceDetail].vendor
          ? tempItemTemp.place_detail[indexCurrentPlaceDetail].vendor
          : null,
      };
      newStop.images.forEach((child) => {
        let basePath;
        if (child.xhr.indexOf("public") >= 0) basePath = child.xhr.slice(7);
        else basePath = child.xhr;
        child.uid = child.uid;
        child.name = child.name;
        child.xhr = basePath;
        child.thumbUrl = basePath;
        child.preview = basePath;
        for (var key in child) {
          if (child.hasOwnProperty(key)) {
            if (
              key === "lastModified" ||
              key === "lastModifiedDate" ||
              key === "size" ||
              key === "type" ||
              key === "percent" ||
              key === "originFileObj" ||
              key === "response"
            )
              delete child[key];
          }
        }
      });
      if (newStop.address !== "") {
        var geocoder = new google.maps.Geocoder();
        await geocoder.geocode(
          { address: newStop.address },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              this.setState(
                {
                  newStop: {
                    ...newStop,
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                  },
                },
                async () => {
                  if (
                    newStop.day_start !== this.props.params.idItineraryDetail
                  ) {
                    if (newStop.time_start === "") {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        this.props.params.idItineraryDetail
                      )[0];

                      let tempItemNext = searchTree(
                        itineraries[current].itinerary_detail,
                        newStop.day_start
                      )[0];

                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        {
                          ...this.state.newStop,
                          belong_to_library_place: this.props.item
                            .belong_to_library_place
                            ? this.props.item.belong_to_library_place
                            : null,
                          belong_to_library: this.props.item.belong_to_library
                            ? this.props.item.belong_to_library
                            : null,
                        },
                        newStop.day_start,
                        this.props.item.transportation.name,
                        this.props.item._id,
                        this.state.categories
                      );
                      tempItem.place_detail.splice(
                        this.props.item.order - 1,
                        1
                      );
                      tempItem.place_detail.forEach((item, index) => {
                        item.order = index + 1;
                      });

                      tempItemNext.place_detail.push(newPlaceDetail);
                      for (
                        let i = 0;
                        i < tempItemNext.place_detail.length - 1;
                        i++
                      ) {
                        for (
                          let j = i + 1;
                          j < tempItemNext.place_detail.length;
                          j++
                        ) {
                          if (
                            tempItemNext.place_detail[j].data.actual_data
                              .time_start === "" ||
                            tempItemNext.place_detail[i].data.actual_data
                              .time_start === ""
                          )
                            continue;
                          if (
                            tempItemNext.place_detail[j].data.actual_data
                              .time_start <
                            tempItemNext.place_detail[i].data.actual_data
                              .time_start
                          ) {
                            let t = tempItemNext.place_detail[i];
                            tempItemNext.place_detail[i] =
                              tempItemNext.place_detail[j];
                            tempItemNext.place_detail[j] = t;
                          }
                        }
                      }
                      tempItemNext.place_detail.forEach((item, index) => {
                        item.order = index + 1;
                      });
                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(7);
                      this.setState(
                        {
                          isLoadingSave: false,
                        },
                        () => {
                          this.props.closeFormViewEditPlace();
                        }
                      );
                    } else {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        this.props.params.idItineraryDetail
                      )[0];

                      let tempItemNext = searchTree(
                        itineraries[current].itinerary_detail,
                        newStop.day_start
                      )[0];

                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        {
                          ...this.state.newStop,
                          belong_to_library_place: this.props.item
                            .belong_to_library_place
                            ? this.props.item.belong_to_library_place
                            : null,
                          belong_to_library: this.props.item.belong_to_library
                            ? this.props.item.belong_to_library
                            : null,
                        },
                        newStop.day_start,
                        this.props.item.transportation.name,
                        this.props.item._id,
                        this.state.categories
                      );
                      tempItem.place_detail.splice(
                        this.props.item.order - 1,
                        1
                      );
                      tempItem.place_detail.forEach((item, index) => {
                        item.order = index + 1;
                      });

                      tempItemNext.place_detail.push(newPlaceDetail);
                      for (
                        let i = 0;
                        i < tempItemNext.place_detail.length - 1;
                        i++
                      ) {
                        for (
                          let j = i + 1;
                          j < tempItemNext.place_detail.length;
                          j++
                        ) {
                          if (
                            tempItemNext.place_detail[j].data.actual_data
                              .time_start === "" ||
                            tempItemNext.place_detail[i].data.actual_data
                              .time_start === ""
                          )
                            continue;
                          if (
                            tempItemNext.place_detail[j].data.actual_data
                              .time_start <
                            tempItemNext.place_detail[i].data.actual_data
                              .time_start
                          ) {
                            let t = tempItemNext.place_detail[i];
                            tempItemNext.place_detail[i] =
                              tempItemNext.place_detail[j];
                            tempItemNext.place_detail[j] = t;
                          }
                        }
                      }
                      tempItemNext.place_detail.forEach((item, index) => {
                        item.order = index + 1;
                      });
                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(7);
                      this.setState(
                        {
                          isLoadingSave: false,
                        },
                        () => {
                          this.props.closeFormViewEditPlace();
                        }
                      );
                    }
                  } else {
                    if (newStop.time_start === "") {
                      let newPlaceDetail = createPlaceDetail(
                        this.props.item.order,
                        {
                          ...this.state.newStop,
                          belong_to_library_place: this.props.item
                            .belong_to_library_place
                            ? this.props.item.belong_to_library_place
                            : null,
                          belong_to_library: this.props.item.belong_to_library
                            ? this.props.item.belong_to_library
                            : null,
                        },
                        this.props.params.idItineraryDetail,
                        this.props.item.transportation.name,
                        this.props.item._id,
                        this.state.categories
                      );
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        this.props.params.idItineraryDetail
                      )[0];
                      tempItem.place_detail[
                        this.props.item.order - 1
                      ] = newPlaceDetail;
                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(4);
                      this.setState(
                        {
                          isLoadingSave: false,
                        },
                        () => {
                          this.props.closeFormViewEditPlace();
                        }
                      );
                    } else {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        this.props.params.idItineraryDetail
                      )[0];
                      if (tempItem.place_detail.length > 0) {
                        this.setState({
                          listPlaceDetail: tempItem.place_detail,
                          length: tempItem.place_detail.length,
                          order: tempItem.place_detail.length + 1,
                        });
                      } else {
                        this.setState({
                          length: 0,
                          order: 1,
                        });
                      }
                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        {
                          ...this.state.newStop,
                          belong_to_library_place: this.props.item
                            .belong_to_library_place
                            ? this.props.item.belong_to_library_place
                            : null,
                          belong_to_library: this.props.item.belong_to_library
                            ? this.props.item.belong_to_library
                            : null,
                        },
                        newStop.day_start,
                        this.props.item.transportation.name,
                        this.props.item._id,
                        this.state.categories
                      );
                      tempItem.place_detail[
                        this.props.item.order - 1
                      ] = newPlaceDetail;
                      for (
                        let i = 0;
                        i < tempItem.place_detail.length - 1;
                        i++
                      ) {
                        for (
                          let j = i + 1;
                          j < tempItem.place_detail.length;
                          j++
                        ) {
                          if (
                            tempItem.place_detail[j].data.actual_data
                              .time_start === "" ||
                            tempItem.place_detail[i].data.actual_data
                              .time_start === ""
                          )
                            continue;
                          if (
                            tempItem.place_detail[j].data.actual_data
                              .time_start <
                            tempItem.place_detail[i].data.actual_data.time_start
                          ) {
                            let t = tempItem.place_detail[i];
                            tempItem.place_detail[i] = tempItem.place_detail[j];
                            tempItem.place_detail[j] = t;
                          }
                        }
                      }

                      for (let i = 0; i < tempItem.place_detail.length; i++) {
                        tempItem.place_detail[i].order = i + 1;
                      }
                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(4);
                      this.setState(
                        {
                          isLoadingSave: false,
                        },
                        () => {
                          this.props.closeFormViewEditPlace();
                        }
                      );
                    }
                  }
                }
              );
            } else {
              this.setState(
                {
                  newStop: {
                    ...newStop,
                    lat: "",
                    lng: "",
                  },
                },
                () => {
                  if (
                    newStop.day_start !== this.props.params.idItineraryDetail
                  ) {
                    if (newStop.time_start === "") {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        this.props.params.idItineraryDetail
                      )[0];

                      let tempItemNext = searchTree(
                        itineraries[current].itinerary_detail,
                        newStop.day_start
                      )[0];

                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        {
                          ...this.state.newStop,
                          belong_to_library_place: this.props.item
                            .belong_to_library_place
                            ? this.props.item.belong_to_library_place
                            : null,
                          belong_to_library: this.props.item.belong_to_library
                            ? this.props.item.belong_to_library
                            : null,
                        },
                        newStop.day_start,
                        this.props.item.transportation.name,
                        this.props.item._id,
                        this.state.categories
                      );
                      tempItem.place_detail.splice(
                        this.props.item.order - 1,
                        1
                      );
                      tempItem.place_detail.forEach((item, index) => {
                        item.order = index + 1;
                      });

                      tempItemNext.place_detail.push(newPlaceDetail);
                      for (
                        let i = 0;
                        i < tempItemNext.place_detail.length - 1;
                        i++
                      ) {
                        for (
                          let j = i + 1;
                          j < tempItemNext.place_detail.length;
                          j++
                        ) {
                          if (
                            tempItemNext.place_detail[j].data.actual_data
                              .time_start === "" ||
                            tempItemNext.place_detail[i].data.actual_data
                              .time_start === ""
                          )
                            continue;
                          if (
                            tempItemNext.place_detail[j].data.actual_data
                              .time_start <
                            tempItemNext.place_detail[i].data.actual_data
                              .time_start
                          ) {
                            let t = tempItemNext.place_detail[i];
                            tempItemNext.place_detail[i] =
                              tempItemNext.place_detail[j];
                            tempItemNext.place_detail[j] = t;
                          }
                        }
                      }
                      tempItemNext.place_detail.forEach((item, index) => {
                        item.order = index + 1;
                      });
                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(7);
                      this.setState(
                        {
                          isLoadingSave: false,
                        },
                        () => {
                          this.props.closeFormViewEditPlace();
                        }
                      );
                    } else {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });

                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        this.props.params.idItineraryDetail
                      )[0];

                      let tempItemNext = searchTree(
                        itineraries[current].itinerary_detail,
                        newStop.day_start
                      )[0];

                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        {
                          ...this.state.newStop,
                          belong_to_library_place: this.props.item
                            .belong_to_library_place
                            ? this.props.item.belong_to_library_place
                            : null,
                          belong_to_library: this.props.item.belong_to_library
                            ? this.props.item.belong_to_library
                            : null,
                        },
                        newStop.day_start,
                        this.props.item.transportation.name,
                        this.props.item._id,
                        this.state.categories
                      );
                      tempItem.place_detail.splice(
                        this.props.item.order - 1,
                        1
                      );
                      tempItem.place_detail.forEach((item, index) => {
                        item.order = index + 1;
                      });

                      tempItemNext.place_detail.push(newPlaceDetail);
                      for (
                        let i = 0;
                        i < tempItemNext.place_detail.length - 1;
                        i++
                      ) {
                        for (
                          let j = i + 1;
                          j < tempItemNext.place_detail.length;
                          j++
                        ) {
                          if (
                            tempItemNext.place_detail[j].data.actual_data
                              .time_start === "" ||
                            tempItemNext.place_detail[i].data.actual_data
                              .time_start === ""
                          )
                            continue;
                          if (
                            tempItemNext.place_detail[j].data.actual_data
                              .time_start <
                            tempItemNext.place_detail[i].data.actual_data
                              .time_start
                          ) {
                            let t = tempItemNext.place_detail[i];
                            tempItemNext.place_detail[i] =
                              tempItemNext.place_detail[j];
                            tempItemNext.place_detail[j] = t;
                          }
                        }
                      }
                      tempItemNext.place_detail.forEach((item, index) => {
                        item.order = index + 1;
                      });
                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(7);
                      this.setState(
                        {
                          isLoadingSave: false,
                        },
                        () => {
                          this.props.closeFormViewEditPlace();
                        }
                      );
                    }
                  } else {
                    if (newStop.time_start === "") {
                      let newPlaceDetail = createPlaceDetail(
                        this.props.item.order,
                        {
                          ...this.state.newStop,
                          belong_to_library_place: this.props.item
                            .belong_to_library_place
                            ? this.props.item.belong_to_library_place
                            : null,
                          belong_to_library: this.props.item.belong_to_library
                            ? this.props.item.belong_to_library
                            : null,
                        },
                        this.props.params.idItineraryDetail,
                        this.props.item.transportation.name,
                        this.props.item._id,
                        this.state.categories
                      );
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        this.props.params.idItineraryDetail
                      )[0];
                      tempItem.place_detail[
                        this.props.item.order - 1
                      ] = newPlaceDetail;
                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(4);
                      this.setState(
                        {
                          isLoadingSave: false,
                        },
                        () => {
                          this.props.closeFormViewEditPlace();
                        }
                      );
                    } else {
                      let itineraries;
                      itineraries = loadItem("itineraries");
                      let current = itineraries.findIndex((item) => {
                        return item._id === this.props.params.idItinerary;
                      });
                      let tempItem = searchTree(
                        itineraries[current].itinerary_detail,
                        this.props.params.idItineraryDetail
                      )[0];
                      if (tempItem.place_detail.length > 0) {
                        this.setState({
                          listPlaceDetail: tempItem.place_detail,
                          length: tempItem.place_detail.length,
                          order: tempItem.place_detail.length + 1,
                        });
                      } else {
                        this.setState({
                          length: 0,
                          order: 1,
                        });
                      }
                      // let tempNewStop = this.state.newStop
                      // tempNewStop._id = ''
                      // this.setState({
                      //     newStop: tempNewStop
                      // })
                      let newPlaceDetail = createPlaceDetail(
                        -1,
                        {
                          ...this.state.newStop,
                          belong_to_library_place: this.props.item
                            .belong_to_library_place
                            ? this.props.item.belong_to_library_place
                            : null,
                          belong_to_library: this.props.item.belong_to_library
                            ? this.props.item.belong_to_library
                            : null,
                        },
                        newStop.day_start,
                        this.props.item.transportation.name,
                        this.props.item._id,
                        this.state.categories
                      );
                      tempItem.place_detail[
                        this.props.item.order - 1
                      ] = newPlaceDetail;
                      for (
                        let i = 0;
                        i < tempItem.place_detail.length - 1;
                        i++
                      ) {
                        for (
                          let j = i + 1;
                          j < tempItem.place_detail.length;
                          j++
                        ) {
                          if (
                            tempItem.place_detail[j].data.actual_data
                              .time_start === "" ||
                            tempItem.place_detail[i].data.actual_data
                              .time_start === ""
                          )
                            continue;
                          if (
                            tempItem.place_detail[j].data.actual_data
                              .time_start <
                            tempItem.place_detail[i].data.actual_data.time_start
                          ) {
                            let t = tempItem.place_detail[i];
                            tempItem.place_detail[i] = tempItem.place_detail[j];
                            tempItem.place_detail[j] = t;
                          }
                        }
                      }

                      for (let i = 0; i < tempItem.place_detail.length; i++) {
                        tempItem.place_detail[i].order = i + 1;
                      }
                      saveItem("itineraries", itineraries);
                      let total_amount = calcTotalAmount(
                        this.props.params.idItinerary
                      );
                      this.props.updateTotalAmount(total_amount);
                      this.props.newList(4);
                      this.setState(
                        {
                          isLoadingSave: false,
                        },
                        () => {
                          this.props.closeFormViewEditPlace();
                        }
                      );
                    }
                  }
                }
              );
            }
          }
        );
      } else {
        this.setState(
          {
            newStop: {
              ...newStop,
              lat: "",
              lng: "",
            },
          },
          () => {
            if (newStop.day_start !== this.props.params.idItineraryDetail) {
              if (newStop.time_start === "") {
                let itineraries;
                itineraries = loadItem("itineraries");
                let current = itineraries.findIndex((item) => {
                  return item._id === this.props.params.idItinerary;
                });
                let tempItem = searchTree(
                  itineraries[current].itinerary_detail,
                  this.props.params.idItineraryDetail
                )[0];

                let tempItemNext = searchTree(
                  itineraries[current].itinerary_detail,
                  newStop.day_start
                )[0];

                let newPlaceDetail = createPlaceDetail(
                  -1,
                  {
                    ...this.state.newStop,
                    belong_to_library_place: this.props.item
                      .belong_to_library_place
                      ? this.props.item.belong_to_library_place
                      : null,
                    belong_to_library: this.props.item.belong_to_library
                      ? this.props.item.belong_to_library
                      : null,
                  },
                  newStop.day_start,
                  this.props.item.transportation.name,
                  this.props.item._id,
                  this.state.categories
                );
                tempItem.place_detail.splice(this.props.item.order - 1, 1);
                tempItem.place_detail.forEach((item, index) => {
                  item.order = index + 1;
                });

                tempItemNext.place_detail.push(newPlaceDetail);
                for (let i = 0; i < tempItemNext.place_detail.length - 1; i++) {
                  for (
                    let j = i + 1;
                    j < tempItemNext.place_detail.length;
                    j++
                  ) {
                    if (
                      tempItemNext.place_detail[j].data.actual_data
                        .time_start === "" ||
                      tempItemNext.place_detail[i].data.actual_data
                        .time_start === ""
                    )
                      continue;
                    if (
                      tempItemNext.place_detail[j].data.actual_data.time_start <
                      tempItemNext.place_detail[i].data.actual_data.time_start
                    ) {
                      let t = tempItemNext.place_detail[i];
                      tempItemNext.place_detail[i] =
                        tempItemNext.place_detail[j];
                      tempItemNext.place_detail[j] = t;
                    }
                  }
                }
                tempItemNext.place_detail.forEach((item, index) => {
                  item.order = index + 1;
                });
                saveItem("itineraries", itineraries);
                let total_amount = calcTotalAmount(
                  this.props.params.idItinerary
                );
                this.props.updateTotalAmount(total_amount);
                this.props.newList(7);
                this.setState(
                  {
                    isLoadingSave: false,
                  },
                  () => {
                    this.props.closeFormViewEditPlace();
                  }
                );
              } else {
                let itineraries;
                itineraries = loadItem("itineraries");
                let current = itineraries.findIndex((item) => {
                  return item._id === this.props.params.idItinerary;
                });

                let tempItem = searchTree(
                  itineraries[current].itinerary_detail,
                  this.props.params.idItineraryDetail
                )[0];

                let tempItemNext = searchTree(
                  itineraries[current].itinerary_detail,
                  newStop.day_start
                )[0];

                let newPlaceDetail = createPlaceDetail(
                  -1,
                  {
                    ...this.state.newStop,
                    belong_to_library_place: this.props.item
                      .belong_to_library_place
                      ? this.props.item.belong_to_library_place
                      : null,
                    belong_to_library: this.props.item.belong_to_library
                      ? this.props.item.belong_to_library
                      : null,
                  },
                  newStop.day_start,
                  this.props.item.transportation.name,
                  this.props.item._id,
                  this.state.categories
                );
                tempItem.place_detail.splice(this.props.item.order - 1, 1);
                tempItem.place_detail.forEach((item, index) => {
                  item.order = index + 1;
                });

                tempItemNext.place_detail.push(newPlaceDetail);
                for (let i = 0; i < tempItemNext.place_detail.length - 1; i++) {
                  for (
                    let j = i + 1;
                    j < tempItemNext.place_detail.length;
                    j++
                  ) {
                    if (
                      tempItemNext.place_detail[j].data.actual_data
                        .time_start === "" ||
                      tempItemNext.place_detail[i].data.actual_data
                        .time_start === ""
                    )
                      continue;
                    if (
                      tempItemNext.place_detail[j].data.actual_data.time_start <
                      tempItemNext.place_detail[i].data.actual_data.time_start
                    ) {
                      let t = tempItemNext.place_detail[i];
                      tempItemNext.place_detail[i] =
                        tempItemNext.place_detail[j];
                      tempItemNext.place_detail[j] = t;
                    }
                  }
                }
                tempItemNext.place_detail.forEach((item, index) => {
                  item.order = index + 1;
                });
                saveItem("itineraries", itineraries);
                let total_amount = calcTotalAmount(
                  this.props.params.idItinerary
                );
                this.props.updateTotalAmount(total_amount);
                this.props.newList(7);
                this.setState(
                  {
                    isLoadingSave: false,
                  },
                  () => {
                    this.props.closeFormViewEditPlace();
                  }
                );
              }
            } else {
              if (newStop.time_start === "") {
                let newPlaceDetail = createPlaceDetail(
                  this.props.item.order,
                  {
                    ...this.state.newStop,
                    belong_to_library_place: this.props.item
                      .belong_to_library_place
                      ? this.props.item.belong_to_library_place
                      : null,
                    belong_to_library: this.props.item.belong_to_library
                      ? this.props.item.belong_to_library
                      : null,
                  },
                  this.props.params.idItineraryDetail,
                  this.props.item.transportation.name,
                  this.props.item._id,
                  this.state.categories
                );
                let itineraries;
                itineraries = loadItem("itineraries");
                let current = itineraries.findIndex((item) => {
                  return item._id === this.props.params.idItinerary;
                });
                let tempItem = searchTree(
                  itineraries[current].itinerary_detail,
                  this.props.params.idItineraryDetail
                )[0];
                tempItem.place_detail[
                  this.props.item.order - 1
                ] = newPlaceDetail;
                saveItem("itineraries", itineraries);
                let total_amount = calcTotalAmount(
                  this.props.params.idItinerary
                );
                this.props.updateTotalAmount(total_amount);
                this.props.newList(4);
                this.setState(
                  {
                    isLoadingSave: false,
                  },
                  () => {
                    this.props.closeFormViewEditPlace();
                  }
                );
              } else {
                let itineraries;
                itineraries = loadItem("itineraries");
                let current = itineraries.findIndex((item) => {
                  return item._id === this.props.params.idItinerary;
                });
                let tempItem = searchTree(
                  itineraries[current].itinerary_detail,
                  this.props.params.idItineraryDetail
                )[0];
                if (tempItem.place_detail.length > 0) {
                  this.setState({
                    listPlaceDetail: tempItem.place_detail,
                    length: tempItem.place_detail.length,
                    order: tempItem.place_detail.length + 1,
                  });
                } else {
                  this.setState({
                    length: 0,
                    order: 1,
                  });
                }
                // let tempNewStop = this.state.newStop
                // tempNewStop._id = ''
                // this.setState({
                //     newStop: tempNewStop
                // })
                let newPlaceDetail = createPlaceDetail(
                  -1,
                  {
                    ...this.state.newStop,
                    belong_to_library_place: this.props.item
                      .belong_to_library_place
                      ? this.props.item.belong_to_library_place
                      : null,
                    belong_to_library: this.props.item.belong_to_library
                      ? this.props.item.belong_to_library
                      : null,
                  },
                  newStop.day_start,
                  this.props.item.transportation.name,
                  this.props.item._id,
                  this.state.categories
                );
                tempItem.place_detail[
                  this.props.item.order - 1
                ] = newPlaceDetail;
                for (let i = 0; i < tempItem.place_detail.length - 1; i++) {
                  for (let j = i + 1; j < tempItem.place_detail.length; j++) {
                    if (
                      tempItem.place_detail[j].data.actual_data.time_start ===
                        "" ||
                      tempItem.place_detail[i].data.actual_data.time_start ===
                        ""
                    )
                      continue;
                    if (
                      tempItem.place_detail[j].data.actual_data.time_start <
                      tempItem.place_detail[i].data.actual_data.time_start
                    ) {
                      let t = tempItem.place_detail[i];
                      tempItem.place_detail[i] = tempItem.place_detail[j];
                      tempItem.place_detail[j] = t;
                    }
                  }
                }

                for (let i = 0; i < tempItem.place_detail.length; i++) {
                  tempItem.place_detail[i].order = i + 1;
                }
                saveItem("itineraries", itineraries);
                let total_amount = calcTotalAmount(
                  this.props.params.idItinerary
                );
                this.props.updateTotalAmount(total_amount);
                this.props.newList(4);
                this.setState(
                  {
                    isLoadingSave: false,
                  },
                  () => {
                    this.props.closeFormViewEditPlace();
                  }
                );
              }
            }
          }
        );
      }
    }
  };

  changeCategory = (value) => {
    this.setState({
      categoryTab: value,
    });
  };

  handleOpenFormSaveToLibrary = () => {
    this.setState({
      openFormSaveToLibrary: true,
    });
  };

  closeFormSaveToLibrary = () => {
    this.setState({
      openFormSaveToLibrary: false,
    });
  };

  render() {
    var { isDisplayFormViewEditPlace, item } = this.props;
    var newPlaceDetail = null;
    if (this.state.newStop) {
      if (this.props.item) {
        newPlaceDetail = createPlaceDetail(
          -1,
          {
            ...this.state.newStop,
            belong_to_library_place: this.props.item.belong_to_library_place,
            belong_to_library: this.props.item.belong_to_library,
          },
          this.state.newStop.day_start,
          this.props.item.transportation.name,
          this.props.item._id,
          this.state.categories
        );
      }
    }

    return (
      <>
        {this.state.openFormSaveToLibrary ? (
          <FormSaveToLibrary
            item={newPlaceDetail}
            closeFormSaveToLibrary={this.closeFormSaveToLibrary}
            setMarked={this.setMarked}
            open={this.state.openFormSaveToLibrary}
            newList={this.props.newList}
          />
        ) : null}
        <Dialog
          open={isDisplayFormViewEditPlace ? true : false}
          maxWidth={"md"}
          fullWidth={true}
          className={styles.formViewEditPlace}
        >
          <MuiDialogTitle className="popup-title" disableTypography>
            <Typography variant="h6">
              {this.state.newStop ? this.state.newStop.title : ""}
            </Typography>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={this.handleCloseViewAndEditPlace}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent dividers>
            <Row>
              <Container>
                {this.state.categoryTab === 1 ? (
                  <AttractionTab
                    params={this.props.params}
                    validate={this.state.validate}
                    item={item}
                    onFillForm={this.onCreateNewStop}
                    categoryTab={this.state.categoryTab}
                    changeCategory={this.changeCategory}
                    categories={this.state.categories}
                  />
                ) : this.state.categoryTab === 2 ? (
                  <LodgingTab
                    params={this.props.params}
                    validate={this.state.validate}
                    item={item}
                    onFillForm={this.onCreateNewStop}
                    categoryTab={this.state.categoryTab}
                    changeCategory={this.changeCategory}
                    categories={this.state.categories}
                  />
                ) : this.state.categoryTab === 3 ? (
                  <RestaurantTab
                    params={this.props.params}
                    validate={this.state.validate}
                    item={item}
                    onFillForm={this.onCreateNewStop}
                    categoryTab={this.state.categoryTab}
                    changeCategory={this.changeCategory}
                    categories={this.state.categories}
                  />
                ) : this.state.categoryTab === 4 ? (
                  <ActivityTab
                    params={this.props.params}
                    validate={this.state.validate}
                    item={item}
                    onFillForm={this.onCreateNewStop}
                    categoryTab={this.state.categoryTab}
                    changeCategory={this.changeCategory}
                    categories={this.state.categories}
                  />
                ) : this.state.categoryTab === 5 ? (
                  <TransportationTab
                    params={this.props.params}
                    validate={this.state.validate}
                    item={item}
                    onFillForm={this.onCreateNewStop}
                    categoryTab={this.state.categoryTab}
                    changeCategory={this.changeCategory}
                    categories={this.state.categories}
                  />
                ) : (
                  <InforTab
                    params={this.props.params}
                    validate={this.state.validate}
                    item={item}
                    onFillForm={this.onCreateNewStop}
                    categoryTab={this.state.categoryTab}
                    changeCategory={this.changeCategory}
                    categories={this.state.categories}
                  />
                )}
              </Container>
            </Row>
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <div>
              <Button
                disabled={
                  this.state.isLoadingDelete ||
                  this.state.isLoadingSaveToMyPlaces ||
                  this.state.isLoadingSave
                    ? true
                    : false
                }
                id="delete-button"
                variant="contained"
                color="secondary"
                onClick={this.handleDeletePlace.bind(this)}
              >
                {this.state.isLoadingDelete ? (
                  <CircularProgress />
                ) : (
                  <span>
                    <MdDelete />
                    &nbsp;Delete
                  </span>
                )}
              </Button>
            </div>
            <div>
              <Button
                disabled={
                  this.state.isLoadingDelete ||
                  this.state.isLoadingSaveToMyPlaces ||
                  this.state.isLoadingSave ||
                  !this.props.profile
                    ? true
                    : false
                }
                type="button"
                className="btn-save-to-library"
                onClick={this.handleOpenFormSaveToLibrary.bind(this)}
              >
                Save to library...
              </Button>
              <Button
                disabled={
                  this.state.isLoadingDelete ||
                  this.state.isLoadingSaveToMyPlaces ||
                  this.state.isLoadingSave
                    ? true
                    : false
                }
                id="save-button"
                type="button"
                className="btn-primary"
                onClick={this.onSaveNewStop.bind(this)}
              >
                {this.state.isLoadingSave ? <CircularProgress /> : "Save"}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FromViewEditPlace);
