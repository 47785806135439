import { actionTypes } from '../../constants/actionTypes'

var initialState = {}


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_LIST_LOAD:
            return {
                ...state,
                list: action.list,
            }
        default:
            return state
    }
}
