String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

const StringHelper = () => {
    return {
        stripeHtml: (html: String, length = 0) => {
            let tmp = document.createElement('div');
            tmp.innerHTML = html;
            let string = tmp.textContent || tmp.innerText || '';
            if (length) {
                string = string.substring(0, length);
            }
            if (string)
                string += '...';
            return string;
        },
        underscoresToSpace: (string: String) => {
            return string.replace(/_/g, ' ').capitalize();
        }
    }
}

export default StringHelper;
