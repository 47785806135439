import React, { Component } from "react";
import { Col, Row } from "antd";
import DefaultBackground from "../../../assets/media/images/generic-activities-img.jpg";
import { CardCustom } from "../../../components";
import "./index.scss";
import { CountryCard } from "../../../components";
import TitleTyneri from "../../modules/typography/title";

class TopDestination extends Component {
  renderCustomCardTyneri = (child, keyChild) => {
    return (
      <div key={keyChild} className="custom-card-tyneri">
        <a href={process.env.PUBLIC_URL+"/view-itinerary/" + child._id}>
          <CardCustom
            title={child.name}
            tag={child.city}
            estimated_budget={child.estimated_budget}
            a_number_of_paxs={child.a_number_of_paxs}
            centerIconName="fas fa-play-circle"
            bottomIconName="fas fa-ellipsis-h"
            bgPhoto={child.cover ? child.cover : DefaultBackground}
            totalReviews={30}
            ratingAverage={4.5}
            options={child.options ? child.options : []}
            list_optionals={this.props.list_optionals}
            currency_infor={child.currency_infor}
          />
        </a>
      </div>
    );
  };

  render() {
    return (
      <Row className="row-itineraries">
        <div className="grid-header">
          <TitleTyneri seeMore={<a target="_blank" href={process.env.PUBLIC_URL+"/top-destinations"}>
              <span className="see-more">See more</span>
          </a>} text={<span>Top destinations</span>}/>
        </div>
        <Col md={24} xl={24} xxl={24}>
          <Row gutter={[16, 16]}>
            {this.props.listItineraries &&
              !!this.props.listItineraries.length &&
              this.props.listItineraries.map((item, key) => (
                <Col key={key} md={12} lg={8} xl={6} xxl={6}>
                  <CountryCard item={item} />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    );
  }
}

export default TopDestination;
