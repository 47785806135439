import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import {
  ItemView,
  ItineraryDetails,
  ItineraryDetailOption,
} from "../../components";
import { Timeline } from "react-event-timeline";
import ApiService from "../../services/api.service";
import styles from "./index.scss";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { getNameItinerary, getFlag } from "../../services/utils.service";
import { saveItem, loadItem } from "../../services/storage.service";
import ScrollspyNav from "react-scrollspy-nav";
import history from "../../components/history";
import { config } from "../../config";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { removeToken, getToken } from "../../services/authService";
import { getAllLeaf } from "../../services/utils.service";
import {
  updateInforCurrency,
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout,
} from "../../store/actions";
import { withRouter } from "react-router";
import formatMoney from "accounting-js/lib/formatMoney.js";
import LodashHelper from "../../helper/lodashHelper";
import $ from "jquery";

const mapStateToProps = (state) => {
  return {
    currency_infor: state.itineraryReducer.currency_infor,
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInforCurrency: (currency) => {
      dispatch(updateInforCurrency(currency));
    },
    saveCurrentItinerary: (itinerary) => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

const options = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
  {
    value: "VNĐ",
    label: "đ",
  },
];

class PreviewItinerary extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      itinerary: "",
      isLoading: true,
      isPdf: true,
      anchorEl: null,
      selectedIndex: 0,
      exchangeRate: 1,
      rates: {},
      baseCurrency: "",
      channel: {},
      listItem: [],

      name: "",
      receiver: "",
      isLoadingMail: false,
      messages: "",
      linkShare: "",

      listAllDates: [],
      showCreateItineraryForm: false,
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    if (getToken()) {
      this.apiService.getCurrentProfile().then((res) => {
        if (res.status === "success") {
          this.props.saveProfile(res.user);
        } else {
          removeToken();
          this.props.logout();
        }
      });
    }

    if (this.props.location) {
      this.setState({
        isPdf: false,
      });

      var idItinerary = this.props.match.params.idItinerary;
      await this.apiService.getItinerary(idItinerary).then((res) => {
        if (this._isMounted && res.status === "success") {
          let itineraries = [];
          if (!loadItem("itineraries")) {
            itineraries.push({ ...res.itinerary });
            saveItem("itineraries", itineraries);
          }
          itineraries = loadItem("itineraries");
          let index = itineraries.findIndex((item) => {
            return item._id === res.itinerary._id;
          });
          if (index < 0) {
            itineraries.push({ ...res.itinerary });
            saveItem("itineraries", itineraries);
            res.itinerary.itinerary_detail.forEach((item) => {
              item.place_detail.sort((itemA, itemB) => {
                if (itemA.order < itemB.order) return -1;
                if (itemA.order > itemB.order) return 1;
                return 0;
              });
            });
            // if (res.itinerary.currency === "EUR")
            //   this.setState({ selectedIndex: 0 });
            // if (res.itinerary.currency === "EUR")
            //   this.setState({ selectedIndex: 1 });
            // if (res.itinerary.currency === "BTC")
            //   this.setState({ selectedIndex: 2 });
            // if (res.itinerary.currency === "JPY")
            //   this.setState({ selectedIndex: 3 });
            // if (res.itinerary.currency === "VNĐ")
            //   this.setState({ selectedIndex: 4 });
            // this.apiService
            //   .getExchangeRate(res.itinerary.currency)
            //   .then((result) => {
            //     this.setState({
            //       baseCurrency: res.itinerary.currency,
            //       rates: result.rates,
            //     });
            //   });
            document.title = `Tyneri | ${res.itinerary.name}`;
            this.setState({
              isLoading: false,
              itinerary: res.itinerary,
            });
          }
        }
      });

      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.match.params.idItinerary;
      });

      let listAllDates = getAllLeaf(itineraries[current].itinerary_detail);
      if (current < 0) {
        this.props.history.push("/404");
      } else {
        // if (itineraries[current].currency === "EUR")
        //   this.setState({ selectedIndex: 0 });
        // if (itineraries[current].currency === "EUR")
        //   this.setState({ selectedIndex: 1 });
        // if (itineraries[current].currency === "BTC")
        //   this.setState({ selectedIndex: 2 });
        // if (itineraries[current].currency === "JPY")
        //   this.setState({ selectedIndex: 3 });
        // if (itineraries[current].currency === "VNĐ")
        //   this.setState({ selectedIndex: 4 });
        // await this.apiService
        //   .getExchangeRate(itineraries[current].currency)
        //   .then((result) => {
        //     this.setState({
        //       baseCurrency: itineraries[current].currency,
        //       rates: result.rates,
        //     });
        //   });

        this.apiService
          .getDetailCurrency(itineraries[current].currency)
          .then((response) => {
            if (response.status === "success") {
              this.props.updateInforCurrency(response.currency);
              this.setState({
                isLoading: false,
                itinerary: itineraries[current],
                listAllDates: listAllDates,
              });
            }
          });
      }

      document.title = `Tyneri | ${itineraries[current].name} ${
        itineraries[current].estimated_budget
          ? `for ` +
            itineraries[current].currency +
            " " +
            itineraries[current].estimated_budget
          : ""
      }`;
      let tempList = [];
      getAllLeaf(itineraries[current].itinerary_detail).forEach((item) => {
        tempList.push(`${item._id}-1`);
      });
      this.setState({
        listItem: tempList,
      });
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    var navLeft = $('#ember13');
    if (navLeft.attr('class')) {
      navLeft.height(navLeft.closest('.full-height').height());
    }
  }

  handleClickListItem = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleMenuItemClick = (index) => {
    var tempExchangeRate;
    if (this.state.baseCurrency === "USD") tempExchangeRate = 1;
    if (this.state.baseCurrency === "EUR")
      tempExchangeRate = 1 / this.state.rates.EUR;
    if (this.state.baseCurrency === "BTC")
      tempExchangeRate = 1 / this.state.rates.BTC;
    if (this.state.baseCurrency === "JPY")
      tempExchangeRate = 1 / this.state.rates.JPY;
    if (this.state.baseCurrency === "VNĐ")
      tempExchangeRate = 1 / this.state.rates.VND;
    this.setState(
      {
        anchorEl: null,
        selectedIndex: index,
      },
      () => {
        if (index === 0) {
          tempExchangeRate *= this.state.rates.USD;
          this.setState({
            itinerary: {
              ...this.state.itinerary,
              currency: "USD",
            },
            exchangeRate: tempExchangeRate,
          });
          return;
        }
        if (index === 1) {
          tempExchangeRate *= this.state.rates.EUR;
          this.setState({
            itinerary: {
              ...this.state.itinerary,
              currency: "EUR",
            },
            exchangeRate: tempExchangeRate,
          });
          return;
        }
        if (index === 2) {
          tempExchangeRate *= this.state.rates.BTC;
          this.setState({
            itinerary: {
              ...this.state.itinerary,
              currency: "BTC",
            },
            exchangeRate: tempExchangeRate,
          });
          return;
        }
        if (index === 3) {
          tempExchangeRate *= this.state.rates.JPY;
          this.setState({
            itinerary: {
              ...this.state.itinerary,
              currency: "JPY",
            },
            exchangeRate: tempExchangeRate,
          });
          return;
        }
        if (index === 4) {
          tempExchangeRate *= this.state.rates.VND;
          this.setState({
            itinerary: {
              ...this.state.itinerary,
              currency: "VNĐ",
            },
            exchangeRate: tempExchangeRate,
          });
          return;
        }
      }
    );
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleRedirectToHome = () => {
    this.props.history.push("/");
  };

  shareItineraryByEmail = () => {
    this.setState({
      isLoading: true,
    });
    this.apiService
      .shareItineraryByEmail(
        this.state.name,
        this.state.receiver,
        this.state.messages,
        this.props.params.idItinerary
      )
      .then((res) => {
        if (res.status === "success") {
          this.setState(
            {
              isLoading: false,
            },
            () => {
              this.props.closeFormShareByEmail();
            }
          );
        }
      });
  };

  handleShowForm = () => {
    this.setState({
      showForm: true,
    });
  };

  handleCloseForm = () => {
    this.setState({
      showForm: false,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCustomize = (id) => {
    history.push(`/itinerary/${id}`);
  };

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  closeCreateItineraryForm = () => {
    this.setState({
      showCreateItineraryForm: false,
    });
  };

  showFormCreateItinerary = () => {
    this.setState({
      showCreateItineraryForm: true,
    });
  };

  renderListPlaces = (item) => {
    var rows = [];
    var countIndex = 0;
    item.place_detail.map((place, index) => {
      if (place.category !== "Infor") {
        countIndex++;
        rows.push(
          <ItemView
            isPdf={this.state.isPdf}
            preview={true}
            key={index}
            index={countIndex}
            items={item.place_detail}
            item={place}
            currency={this.state.itinerary.currency}
            exchangeRate={this.state.exchangeRate}
            length={item.place_detail.length}
          />
        );
      } else {
        rows.push(
          <ItemView
            isPdf={this.state.isPdf}
            preview={true}
            key={index}
            index={countIndex}
            items={item.place_detail}
            item={place}
            currency={this.state.itinerary.currency}
            exchangeRate={this.state.exchangeRate}
            length={item.place_detail.length}
          />
        );
      }
    });
    return rows
  }

  render() {
    const title = `${this.state.itinerary.name}${" "}
    ${
      this.state.itinerary.estimated_budget
        ? `on a $${this.state.itinerary.estimated_budget} USD budget`
        : ``
    }
    ${
      this.state.itinerary.a_number_of_paxs
        ? ` for ${this.state.itinerary.a_number_of_paxs} pax`
        : ""
    }`;

    return this.state.isPdf ? (
      <></>
    ) : this.state.isLoading ? (
      <div className={styles.pageLoader}></div>
    ) : (
      <div className={styles.viewItinerary}>
        <div className="wrapper-content">
          <Menu
            id="view-in-currency"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose.bind(this)}
          >
            {options.map((option, index) => (
              <MenuItem
                key={option.value}
                selected={index === this.state.selectedIndex}
                onClick={this.handleMenuItemClick.bind(this, index)}
              >
                {option.label} {option.value}
              </MenuItem>
            ))}
          </Menu>
          <Container>
            <Row className="full-height">
              <Col xs="12" md="3" lg="3" className="left-bar">
                {!!this.state.listItem.length && (
                  <React.Fragment>
                    <div className="hide-on-mobile" style={{display: 'inline-block', width: '100%'}}>
                      <h2 className="main-title-section">Overview</h2>
                      <ul className="item-in-overview">
                        <li>
                          People: {this.state.itinerary.a_number_of_paxs} pax
                        </li>
                        <li>
                          Duration:{" "}
                          {getAllLeaf(this.state.itinerary.itinerary_detail)
                            .length > 1
                            ? `${
                                getAllLeaf(
                                  this.state.itinerary.itinerary_detail
                                ).length
                              } days`
                            : `${
                                getAllLeaf(
                                  this.state.itinerary.itinerary_detail
                                ).length
                              } day`}
                        </li>
                        <li>
                          Budget:{" "}
                          {formatMoney(this.state.itinerary.estimated_budget, {
                            symbol: this.props.currency_infor.symbol,
                            precision: this.props.currency_infor.decimal_digits,
                            thousand: ",",
                            decimal: ".",
                          })}{" "}
                          {this.props.currency_infor.code}
                        </li>
                        <li>
                          Expenses:{" "}
                          {formatMoney(this.state.itinerary.total_amount, {
                            symbol: this.props.currency_infor.symbol,
                            precision: this.props.currency_infor.decimal_digits,
                            thousand: ",",
                            decimal: ".",
                          })}{" "}
                          {this.props.currency_infor.code}
                        </li>
                      </ul>
                    </div>
                    <nav
                      id="ember13"
                      className="si-day-nav _fixed ember-view hide-on-mobile"
                    >
                      {this.state.itinerary && (
                        <ScrollspyNav
                          scrollTargetIds={this.state.listItem}
                          activeNavClass="_active"
                          scrollDuration="1000"
                          offset={-50}
                        >
                          <h2 className="main-title-section">Itinerary</h2>

                          <ItineraryDetails
                            preview={true}
                            params={this.props.match.params}
                          />
                        </ScrollspyNav>
                      )}
                    </nav>
                  </React.Fragment>
                )}
              </Col>
              <Col xs="12" md="6" lg="6">
                <div className="wrapper main">
                  <div className="grid-header bread">
                    <Breadcrumb separator=">">
                      <Breadcrumb.Item>
                        <Link to="/">itinerary</Link>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </div>
                  <div className="section-title-itinerary">
                    <h5 className="name">
                      {getNameItinerary({
                        ...this.state.itinerary,
                        currency_infor: this.props.currency_infor,
                      })}
                    </h5>
                  </div>
                  {this.state.itinerary.cover ? (
                      <div
                          className="img-fluid"
                          style={{
                            backgroundImage: `url("${config.baseURL}${this.state.itinerary.cover}")`,
                          }}
                      ></div>
                  ) : (
                      <div className="img-fluid"></div>
                  )}
                  <div>
                    <span className="description">
                      {this.state.itinerary.description}
                    </span>
                  </div>
                  <div className="preview hide-on-desktop">
                    <div>
                      <h2 className="main-title-section">Overview</h2>
                      <ul className="item-in-overview">
                        <li>
                          People: {this.state.itinerary.a_number_of_paxs}{" "}
                          pax
                        </li>
                        <li>
                          Duration:{" "}
                          {getAllLeaf(
                              this.state.itinerary.itinerary_detail
                          ).length > 1
                              ? `${
                                  getAllLeaf(
                                      this.state.itinerary.itinerary_detail
                                  ).length
                              } days`
                              : `${
                                  getAllLeaf(
                                      this.state.itinerary.itinerary_detail
                                  ).length
                              } day`}
                        </li>
                        <li>
                          Budget:{" "}
                          {formatMoney(
                              this.state.itinerary.estimated_budget,
                              {
                                symbol: this.props.currency_infor.symbol,
                                precision: this.props.currency_infor
                                    .decimal_digits,
                                thousand: ",",
                                decimal: ".",
                              }
                          )}
                        </li>
                        <li>
                          Expenses:{" "}
                          {formatMoney(
                              this.state.itinerary.total_amount,
                              {
                                symbol: this.props.currency_infor.symbol,
                                precision: this.props.currency_infor
                                    .decimal_digits,
                                thousand: ",",
                                decimal: ".",
                              }
                          )}
                        </li>
                      </ul>
                    </div>
                    {this.state.itinerary && (
                        <ScrollspyNav
                            scrollTargetIds={this.state.listItem}
                            activeNavClass="_active"
                            scrollDuration="1000"
                            offset={-50}
                        >
                          <h2 className="main-title-section">Itinerary</h2>

                          <ItineraryDetails
                              preview={true}
                              params={this.props.match.params}
                          />
                        </ScrollspyNav>
                    )}
                  </div>

                  {!!this.state.listAllDates.length &&
                  this.state.listAllDates.map((item, key) => {
                    const currentDetail = LodashHelper.loopFind(
                        "alpha2Code",
                        "alpha2Code",
                        item.alpha2Code,
                        this.state.itinerary.itinerary_detail
                    )[0];
                    return (
                        <div key={key} id={`${item._id}-1`}>
                          <h2 className="day-title">
                            {item.title ? (
                                <span className="each-title-day">
                                  Day {key + 1}: {item.title}
                                </span>
                            ) : (
                                <span className="each-title-day">
                                  Day {key + 1}
                                </span>
                            )}
                            <span className="each-title-location">
                                {currentDetail ? <img src={getFlag(currentDetail.alpha2Code)} /> : null}
                              {item.city
                                  ? `${item.city}, ${item.country}`
                                  : `${item.country}`}
                              </span>
                          </h2>

                          <ItineraryDetailOption
                              lodging={item.lodging}
                              amountMeals={item.amountMeals}
                              meal={item.meal}
                          />

                          {/* <div className="overview-itinerary-detail-option">
                            {item.stay_in && (
                              <div>
                                <span>
                                  <HotelIcon />{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    Hotel:
                                  </span>{" "}
                                  {item.stay_in + " star"}
                                </span>
                              </div>
                            )}

                            {item.meal && !!item.meal.length && (
                              <div>
                                <span>
                                  <RestaurantIcon />{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    Meals:
                                  </span>{" "}
                                  {item.meal.map((child, index) => {
                                    if (index === item.meal.length - 1) {
                                      return this.capitalize(child.option);
                                    } else
                                      return (
                                        this.capitalize(child.option) + "/"
                                      );
                                  })}
                                </span>
                              </div>
                            )}
                          </div> */}

                          {item.description && (
                              <div className="overview-itinerary-detail trix-content-temp">
                                <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                ></div>
                              </div>
                          )}
                          {/* {item.description && !!item.place_detail.length && (
                            <div className="break-section">
                              Day {key + 1} itinerary
                            </div>
                          )} */}

                          {!!item.place_detail.length && (
                              <Timeline
                                  lineColor={`#e1eef5`}
                                  className={
                                    item.place_detail.length > 1
                                        ? "timeline-event"
                                        : "timeline-event-1"
                                  }
                              >
                                {this.renderListPlaces(item)}
                              </Timeline>
                          )}
                        </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PreviewItinerary)
);
