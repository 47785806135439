import React from "react";
import { Input, TimePicker } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useFieldArray, Controller } from "react-hook-form";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import EditorCustom from "../editorCustom/WYSIWYG";
import moment from "moment";

import "./index.scss";

export default ({ nestIndex, control, register, currency }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `days[${nestIndex}].stops`,
  });

  return (
    <div className="stop-input">
      {fields.length > 0 && (
        <div className="stop-input__body">
          <Table>
            <Thead>
              <Tr>
                <Th with="10%">Start time</Th>
                <Th with="10%">End time</Th>
                <Th with="40%">Activity</Th>
                <Th with="30%">Cost</Th>
                <Th with="10%">&nbsp;</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fields.map((item, k) => {
                const Rejected = ({ rejected }) => {
                  if (rejected)
                    return (
                      <div className="stop-input__content__confirm__reject-text">
                        {" "}
                        Rejected{" "}
                      </div>
                    );
                  return null;
                };
                return (
                  <Tr className="stop-input__body-row" key={item.id}>
                    <Td with="10%" className="stop-input__content">
                      <div className="stop-input__content__time-content">
                        <Controller
                          control={control}
                          name={`days[${nestIndex}].stops[${k}].start_time`}
                          defaultValue={
                            item.start_time
                              ? moment(item.start_time, "h:mm a")
                              : null
                          }
                          as={
                            <TimePicker
                              format="h:mm a"
                              placeholder="Start time"
                              use12Hours
                              defaultValue={
                                item.start_time
                                  ? moment(item.start_time, "h:mm a")
                                  : null
                              }
                            />
                          }
                        />
                      </div>
                    </Td>
                    <Td with="10%" className="stop-input__content">
                      <div className="stop-input__content__time-content">
                        <Controller
                          control={control}
                          name={`days[${nestIndex}].stops[${k}].end_time`}
                          defaultValue={
                            item.end_time
                              ? moment(item.end_time, "h:mm a")
                              : null
                          }
                          as={
                            <TimePicker
                              format="h:mm a"
                              placeholder="End time"
                              use12Hours
                              defaultValue={
                                item.end_time
                                  ? moment(item.end_time, "h:mm a")
                                  : null
                              }
                            />
                          }
                        />
                      </div>
                    </Td>
                    <Td with="40%" className="text-editor stop-input__content">
                      <Controller
                        control={control}
                        name={`days[${nestIndex}].stops[${k}].title`}
                        defaultValue={item.title}
                        as={
                          <Input
                            type="text"
                            placeholder="Activity title"
                            defaultValue={item.title}
                          />
                        }
                      />
                      <Controller
                        control={control}
                        name={`days[${nestIndex}].stops[${k}].description`}
                        defaultValue={item.description ? item.description : ""}
                        as={
                          <EditorCustom
                            initialValue={
                              item.description ? item.description : ""
                            }
                          />
                        }
                      />
                    </Td>
                    <Td with="30%" className="stop-input__content">
                      <div className="stop-input__content__price">
                        <Controller
                          control={control}
                          name={`days[${nestIndex}].stops[${k}].budget`}
                          defaultValue={item.budget}
                          as={
                            <Input
                              type="number"
                              placeholder="budget"
                              defaultValue={item.budget}
                              addonBefore={currency}
                            />
                          }
                        />
                      </div>
                    </Td>
                    <Td with="10%" className="stop-input__content">
                      <div className="stop-input__content__confirm-container">
                        <div className="stop-input__content__confirm">
                          <Rejected rejected={item.rejected} />
                          <Controller
                            control={control}
                            name={`days[${nestIndex}].stops[${k}].rejected`}
                            defaultValue={item.rejected}
                            as={<Input type="hidden" value={item.rejected} />}
                          />
                        </div>
                        <DeleteOutlined
                          className="stop-input__content__dynamic-delete-button"
                          type="minus-circle-o"
                          onClick={() => {
                            console.log(item);
                            console.log(k);
                            remove(k);
                          }}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      )}
      <div className="stop-input__add-stop">
        <span
          className="stop-input__add-stop__add-stop-span"
          onClick={() => {
            append({
              title: "",
              start_time: "",
              end_time: "",
              budget: "",
              description: "",
            });
          }}
        >
          <PlusOutlined />
          Add an activity
        </span>
      </div>
    </div>
  );
};
