import { actionTypes } from '../../constants/actionTypes'

var initialState = false

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_FORM_QUOTE:
            state = true
            return {
                open: state,
                item: action.item
            }
        case actionTypes.CLOSE_FORM_QUOTE:
            state = false
            return {
                open: state
            }
        default:
            return state
    }
}
