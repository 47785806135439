/*global google*/
import React, { Component } from "react";
import {
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { dateToTimestamp } from "../../services/utils.service";
import ApiService from "../../services/api.service";
import styles from "./index.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import { loadItem, saveItem } from "../../services/storage.service.js";
import { ObjectID } from "bson";
import { connect } from "react-redux";
import { MdClose } from "react-icons/md";
import $ from "jquery";
import _ from "lodash";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
    itinerary: state.itineraryReducer,
  };
};

class FormAddCountry extends Component {
  constructor(props) {
    super(props);
    this.autocompleteMutiInput = [];
    this.apiService = ApiService();
    this.state = {
      country: "",
      secondaryCountries: [],
      flag: "",
      alpha2Code: "",
      numberOfCountry: 1,
      destination: "",
      city: "",
      cover: "",
      name: "",
      to: "",
      isSubmit: false,
      isLoading: false,
      aNumberOfPaxs: "",
      addCountries: {},
      mainCountries: [],
      loading: false,
    };
  }

  componentDidMount = async () => {
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });

    this.setState({
      to: itineraries[current].end_dates,
    });

    var numCountries = 0;
    var tempMainCountries = [];
    itineraries[current].itinerary_detail.forEach((item) => {
      numCountries += item.children.length;
      item.children.forEach((child) => {
        tempMainCountries.push({
          country: item.title,
          city: child.title,
          alpha2Code: item.alpha2Code,
        });
      });
    });

    this.setState({
      numberOfCountry: numCountries,
      mainCountries: tempMainCountries,
    });
  };

  handleChange = (name) => (event) => {
    if (name === "numberOfCountry") {
      var temp = this.state.secondaryCountries;
      for (let i = 0; i < +event.target.value - 1; i++) {
        temp.push({
          country: this.state.country,
          alpha2Code: this.state.alpha2Code,
        });
      }
      this.setState({
        secondaryCountries: temp,
      });
    }

    if (name === "currency") {
      this.setState({
        [name]: event,
      });
      return;
    }

    if (
      (name === "aNumberOfPaxs" || name === "estimatedBudget") &&
      event.target.value < 0
    ) {
      event.target.value = Math.abs(event.target.value);
    } else if (
      (name === "aNumberOfPaxs" || name === "estimatedBudget") &&
      event.target.value === 0
    ) {
      event.preventDefault();
    }

    if (name === "status") {
      this.setState({
        [name]: +event.target.value,
      });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  validateAutoComplete = (tempCountries) => {
    var arrayIdInput = [];
    $("input[placeholder|='Enter City or Country']").each(function (
      index,
      element
    ) {
      if ($(element)[0].hasAttribute("id")) {
        arrayIdInput.push($(this).attr("id"));
      }
    });

    let validateAutoComplete = true;
    tempCountries.forEach((item, index) => {
      if (!item.flag || item.flag === "") {
        validateAutoComplete = false;
        if (
          !$(`input[id=${arrayIdInput[index]}]`).hasClass(
            "is-invalid-autocomplete"
          )
        ) {
          $(`input[id=${arrayIdInput[index]}]`)
            .closest("div")
            .append(
              "<div invalid='true' class='d-block invalid-feedback'>Wrong format!</div>"
            );
          $(`input[id=${arrayIdInput[index]}]`).addClass(
            "is-invalid-autocomplete"
          );
          $(`input[id=${arrayIdInput[index]}]`)
            .closest("div")
            .find("span.input-group-append > button")
            .addClass("is-invalid-btn-remove")
            .removeClass("btn-outline-primary");
        }
      }
    });
    return validateAutoComplete;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      isSubmit: true,
    });

    var itineraries;
    itineraries = loadItem("itineraries");
    var current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });

    if (this.state.secondaryCountries.length > 0) {
      let tempCountries = [...this.state.secondaryCountries];

      if (!this.validateAutoComplete(tempCountries)) return;

      var tempInfoCountryCity = tempCountries[0];

      if (tempCountries.length > 1) {
        for (let i = 0; i < tempCountries.length - 1; i++) {
          if (tempCountries[i].alpha2Code === tempCountries[i + 1].alpha2Code) {
            if (tempCountries[i].city === tempCountries[i + 1].city) {
              tempCountries.splice(i, 1);
              i--;
            } else {
              if (!tempCountries[i].city) {
                tempCountries.splice(i, 1);
                i--;
              } else if (!tempCountries[i + 1].city) {
                tempCountries.splice(i + 1, 1);
                i--;
              }
            }
          }
        }
      }

      tempCountries = tempCountries.map(
        (item) =>
          (item = {
            belong_to_itinerary: this.props.params.idItinerary,
            title: item.country,
            alpha2Code: item.alpha2Code,
            flag: item.flag,
            children: [
              {
                title: item.city,
                children: [],
              },
            ],
          })
      );

      let originLength = tempCountries.length;
      if (tempCountries.length === 1) {
        let detail = {
          month_day_year: itineraries[current].end_dates,
          place_detail: [],
          title: "",
          _id: new ObjectID().toHexString(),
          country: tempInfoCountryCity.country,
          alpha2Code: tempInfoCountryCity.alpha2Code,
          city: tempInfoCountryCity.city,
        };
        tempCountries[0].children[0].children.push(detail);
      } else if (tempCountries.length > 1) {
        for (let i = 0; i < tempCountries.length - 1; i++) {
          if (tempCountries[i].alpha2Code === tempCountries[i + 1].alpha2Code) {
            if (tempCountries.length === 2) {
              tempCountries[i].children.push({
                title: tempCountries[i + 1].children[0].title,
                children: [],
              });
            } else {
              if (i === tempCountries.length - 2) {
                tempCountries[i].children.push({
                  title: tempCountries[i + 1].children[0].title,
                  children: [
                    {
                      month_day_year: "",
                      place_detail: [],
                      title: "",
                      _id: new ObjectID().toHexString(),
                      country: tempCountries[i].title,
                      alpha2Code: tempCountries[i].alpha2Code,
                      city: tempCountries[i + 1].children[0].title,
                    },
                  ],
                });
              } else {
                tempCountries[i].children.push({
                  title: tempCountries[i + 1].children[0].title,
                  children: [],
                });
              }
            }
            tempCountries.splice(i + 1, 1);
            i--;
          }
        }

        if (tempCountries.length === 1 && originLength > 1) {
          tempCountries[0].children.forEach((item, index) => {
            if (index === tempCountries[0].children.length - 1) {
              item.children.push({
                month_day_year: dateToTimestamp(
                  new Date(this.state.to * 1000)
                ).toString(),
                place_detail: [],
                title: "",
                _id: new ObjectID().toHexString(),
                country: tempCountries[0].title,
                alpha2Code: tempCountries[0].alpha2Code,
                city: tempCountries[0].children[index].title,
              });
            } else {
              item.children.push({
                month_day_year: "",
                place_detail: [],
                title: "",
                _id: new ObjectID().toHexString(),
                country: tempCountries[0].title,
                alpha2Code: tempCountries[0].alpha2Code,
                city: tempCountries[0].children[index].title,
              });
            }
          });
        } else {
          if (originLength === tempCountries.length) {
            for (let i = 0; i < tempCountries.length; i++) {
              if (i === tempCountries.length - 1) {
                tempCountries[i].children[0].children.push({
                  month_day_year: dateToTimestamp(
                    new Date(this.state.to * 1000)
                  ).toString(),
                  place_detail: [],
                  title: "",
                  _id: new ObjectID().toHexString(),
                  country: tempCountries[i].title,
                  alpha2Code: tempCountries[i].alpha2Code,
                  city: tempCountries[i].children[0].title,
                });
              } else {
                tempCountries[i].children[0].children.push({
                  month_day_year: "",
                  place_detail: [],
                  title: "",
                  _id: new ObjectID().toHexString(),
                  country: tempCountries[i].title,
                  alpha2Code: tempCountries[i].alpha2Code,
                  city: tempCountries[i].children[0].title,
                });
              }
            }
          } else {
            for (let i = 0; i < tempCountries.length; i++) {
              for (let j = 0; j < tempCountries[i].children.length; j++) {
                if (j === tempCountries[i].children.length - 1) {
                  tempCountries[i].children[j].children.push({
                    month_day_year: dateToTimestamp(
                      new Date(this.state.to * 1000)
                    ).toString(),
                    place_detail: [],
                    title: "",
                    _id: new ObjectID().toHexString(),
                    country: tempCountries[i].title,
                    alpha2Code: tempCountries[i].alpha2Code,
                    city: tempCountries[i].children[0].title,
                  });
                } else {
                  tempCountries[i].children[j].children.push({
                    month_day_year: "",
                    place_detail: [],
                    title: "",
                    _id: new ObjectID().toHexString(),
                    country: tempCountries[i].title,
                    alpha2Code: tempCountries[i].alpha2Code,
                    city: tempCountries[i].children[0].title,
                  });
                }
              }
            }
          }
        }
      }

      let oldCountryTree = itineraries[current].itinerary_detail;
      if (_.last(oldCountryTree).alpha2Code === tempCountries[0].alpha2Code) {
        if (
          _.last(_.last(oldCountryTree).children).title ===
          tempCountries[0].children[0].title
        ) {
          tempCountries[0].children.splice(0, 1);
          let mergeBranch = tempCountries[0].children;
          tempCountries.splice(0, 1);
          let afterMerge = _.last(oldCountryTree).children.concat(mergeBranch);
          oldCountryTree[oldCountryTree.length - 1].children = afterMerge;
        } else {
          let mergeBranch = tempCountries[0].children;
          tempCountries.splice(0, 1);
          let afterMerge = _.last(oldCountryTree).children.concat(mergeBranch);
          oldCountryTree[oldCountryTree.length - 1].children = afterMerge;
        }
      }

      let newTree = oldCountryTree.concat(tempCountries);
      newTree.forEach((item, index) => {
        item.order = index;
      });

      itineraries[current].itinerary_detail = newTree;
    }

    saveItem("itineraries", itineraries);
    this.props.rerenderParentCallback();
  };

  removeInput = (time) => {
    const addCountries = { ...this.state.addCountries };
    var keytoFind = time;
    var i = Object.keys(addCountries).indexOf(keytoFind.toString());

    const secondaryCountries = [...this.state.secondaryCountries];
    secondaryCountries.splice(i, 1);
    delete addCountries[time];

    this.setState({
      addCountries: addCountries,
      secondaryCountries: secondaryCountries,
      numberOfCountry: Object.keys(addCountries).length + 1,
    });
  };

  handleAddAnotherDestination = () => {
    const addCountries = { ...this.state.addCountries };
    const time = new Date().getTime();
    this.autocompleteMutiInput[time] = React.createRef();
    addCountries[time] = (
      <FormGroup row key={time}>
        <Col xs={12}>
          <InputGroup className="row-destination-added">
            <Input
              innerRef={this.autocompleteMutiInput[time]}
              id={`start-country-${time}`}
              placeholder="Enter City or Country"
            />
            <span className="input-group-append input-group-btn">
              <Button
                className="btn-outline-primary"
                onClick={this.removeInput.bind(this, time)}
              >
                <MdClose />
              </Button>
            </span>
          </InputGroup>
        </Col>
      </FormGroup>
    );

    this.setState(
      {
        numberOfCountry: this.state.numberOfCountry + 1,
        addCountries: addCountries,
      },
      () => {
        this.addAutoComplete(
          this.autocompleteMutiInput[time].current,
          `start-country-${time}`
        );

        var temp = this.state.secondaryCountries;
        temp.push({
          country: "",
          alpha2Code: "",
        });
        this.setState({
          secondaryCountries: temp,
        });
      }
    );
  };

  addAutoComplete = (ele, id) => {
    var currentInp = id;
    var placeBox = new google.maps.places.Autocomplete(ele, {
      types: ["(regions)"],
    });
    google.maps.event.addListener(placeBox, "place_changed", () => {
      var place = placeBox.getPlace();
      if (place.geometry) {
        var latlng = new google.maps.LatLng(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );

        $(`input[id=${currentInp}]`)
          .closest("div")
          .find("div.invalid-feedback")
          .remove();
        $(`input[id=${currentInp}]`).removeClass("is-invalid-autocomplete");
        $(`input[id=${currentInp}]`)
          .closest("div")
          .find("span.input-group-append > button")
          .addClass("btn-outline-primary")
          .removeClass("is-invalid-btn-remove");

        new google.maps.Geocoder().geocode(
          { latLng: latlng },
          (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                var country = null,
                  countryCode = null,
                  city = null,
                  cityAlt = null;
                var c, lc, component;
                for (var r = 0, rl = results.length; r < rl; r += 1) {
                  var result = results[r];

                  if (!city && result.types[0] === "locality") {
                    for (
                      c = 0, lc = result.address_components.length;
                      c < lc;
                      c += 1
                    ) {
                      component = result.address_components[c];

                      if (component.types[0] === "locality") {
                        city = component.long_name;
                        break;
                      }
                    }
                  } else if (
                    !city &&
                    !cityAlt &&
                    result.types[0] === "administrative_area_level_1"
                  ) {
                    for (
                      c = 0, lc = result.address_components.length;
                      c < lc;
                      c += 1
                    ) {
                      component = result.address_components[c];

                      if (
                        component.types[0] === "administrative_area_level_1"
                      ) {
                        cityAlt = component.long_name;
                        break;
                      }
                    }
                  } else if (!country && result.types[0] === "country") {
                    country = result.address_components[0].long_name;
                    countryCode = result.address_components[0].short_name;
                  }

                  if (city && country) {
                    break;
                  }
                }

                var index = place.types.findIndex((item) => {
                  return item === "country";
                });

                var keytoFind = currentInp.substr(14);
                var i = Object.keys(this.state.addCountries).indexOf(keytoFind);

                if (index >= 0) {
                  let temp2 = [...this.state.secondaryCountries];
                  temp2[i] = {
                    city: "",
                    country: country,
                    alpha2Code: countryCode,
                    flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                  };
                  this.setState({
                    secondaryCountries: temp2,
                  });
                } else {
                  if (cityAlt) {
                    let temp2 = [...this.state.secondaryCountries];
                    temp2[i] = {
                      city: cityAlt,
                      country: country,
                      alpha2Code: countryCode,
                      flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                    };
                    this.setState({
                      secondaryCountries: temp2,
                    });
                  } else {
                    let temp2 = [...this.state.secondaryCountries];
                    temp2[i] = {
                      city: city,
                      country: country,
                      alpha2Code: countryCode,
                      flag: `https://www.countryflags.io/${countryCode}/flat/24.png`,
                    };
                    this.setState({
                      secondaryCountries: temp2,
                    });
                  }
                }
              }
            }
          }
        );
      } else {
        var keytoFind = currentInp.substr(14);
        var i = Object.keys(this.state.addCountries).indexOf(keytoFind);
        var temp2 = [...this.state.secondaryCountries];
        temp2[i] = {
          city: "",
          country: "",
          alpha2Code: "",
          flag: "",
        };
        this.setState({
          secondaryCountries: temp2,
        });
      }
    });
  };

  render() {
    return (
      <>
        <DialogContent dividers className={styles.editItineraryForm}>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            {this.state.mainCountries.map((item, index) => (
              <FormGroup row key={index}>
                <Col xs={12}>
                  <Input
                    disabled
                    className="destination-disabled"
                    placeholder="Enter City, Country or Region"
                    value={`${item.city && `${item.city}, `} ${item.country}`}
                  />
                </Col>
              </FormGroup>
            ))}
            <div id="new-row-added">
              {Object.keys(this.state.addCountries).map(
                (key) => this.state.addCountries[key]
              )}
            </div>
            <FormGroup row>
              <Col xs={12}>
                <Button
                  className="btn-add-destination"
                  onClick={this.handleAddAnotherDestination.bind(this)}
                  size="lg"
                  block
                >
                  + Add another destination
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </DialogContent>
        <DialogActions className={styles.editItineraryForm}>
          <Button
            disabled={this.state.isLoading ? true : false}
            className="color-primary btn-start"
            type="button"
            onClick={this.handleSubmit.bind(this)}
          >
            {this.state.isLoading ? <CircularProgress /> : `Edit`}
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default connect(mapStateToProps, null)(FormAddCountry);
