import React from "react";
import { Row, Col, Icon } from "antd";
import { EditFilled } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { connect } from "react-redux";

import FormCompany from "../form-company";
import Image from "../../../../../functions/image";
import { AccountType } from "../../../../../params/AccountType";
import { configBaseUrl } from "../../../../../config";

const DescriptionCustom = ({ label, value, classLabel, classValue }) => {
  if (!value) return null;
  return (
    <tr>
      <td className={" label " + (classLabel ? classLabel : "")}>
        <strong>{label}</strong>
      </td>
      <td className={classValue ? classValue : ""}>{value}</td>
    </tr>
  );
};

const SocialAccount = ({ socialType, socialProfile, linkSocial }) => {
  if (socialProfile === "") return null;
  return (
    <div className="social-account">
      {socialType}
      <a target="blank" href={linkSocial + socialProfile}>
        {socialProfile}
      </a>
    </div>
  );
};

const CompanyInfo = (props) => {
  const profile = props.profile;
  const company = props.company;
  if (profile.accountType !== AccountType.Pro) return null;
  return (
    <div className="company-info">
      <Row className="edit-container">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h4>Business information</h4>
        </Col>
        <FormCompany />
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <table>
            <thead></thead>
            <tbody>
              <DescriptionCustom
                label="Your URL"
                value={
                  company && company.url ? (
                    <a
                      target="blank"
                      href={"https://" + company.url + ".tyneri.com"}
                    >
                      {company.url + ".tyneri.com"}
                    </a>
                  ) : (
                    "your_url.tyneri.com"
                  )
                }
                classValue="url"
              />
              <DescriptionCustom
                label="Company name"
                value={company && company.name ? company.name : ""}
              />
              <DescriptionCustom
                label="Short name"
                value={company && company.short_name ? company.short_name : ""}
              />
              <DescriptionCustom
                label="Address"
                value={company && company.address ? company.address : ""}
              />
              <DescriptionCustom
                label="Phone"
                value={company && company.phone ? company.phone : ""}
              />
              <DescriptionCustom
                label="Email"
                value={
                  company &&
                  company.email && (
                    <a href={"mailto:" + company.email}> {company.email}</a>
                  )
                }
              />
              <DescriptionCustom
                label="Website"
                value={
                  company &&
                  company.website && (
                    <a target="blank" href={company.website}>
                      {" "}
                      {company.website}{" "}
                    </a>
                  )
                }
                classValue="url"
              />
              {((company.facebook && company.company !== "") ||
                (company.twitter && company.twitter !== "")) && (
                <DescriptionCustom
                  label={"Social"}
                  value={
                    <div className="social-account-container">
                      {company.facebook && company.facebook !== "" && (
                        <SocialAccount
                          socialType={<Icon type="facebook" />}
                          socialProfile={company.facebook}
                          linkSocial="https://www.facebook.com/"
                        />
                      )}
                      {company.twitter && company.twitter !== "" && (
                        <SocialAccount
                          socialType={<Icon type="twitter" />}
                          socialProfile={company.twitter}
                          linkSocial="https://www.twitter.com/"
                        />
                      )}
                    </div>
                  }
                  classLabel="social"
                />
              )}
              <DescriptionCustom
                label="About us"
                value={
                  company &&
                  company.about_us && (
                    <p
                      dangerouslySetInnerHTML={{ __html: company.about_us }}
                    ></p>
                  )
                }
                classLabel="social"
              />
            </tbody>
          </table>
          <div className="company-logo">
            <ImgCrop rotate={true}>
              <Upload
                disabled={!profile.verify ? true : false}
                showList={false}
                showUploadList={false}
                customRequest={props.uploadCompanyLogo}
              >
                <Image
                  src={
                    company && company.logo
                      ? configBaseUrl.baseURL + company.logo
                      : ""
                  }
                  loadingUpload={props.loadingCompanyLogo}
                />
                <EditFilled />
              </Upload>
            </ImgCrop>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};
export default connect(mapStateToProps, null)(CompanyInfo);
