/*global google*/
import React, { Component } from "react";
import {Breadcrumb, Typography, Collapse} from "antd";

import styles from "./index.scss";
import { connect } from "react-redux";
import {
  openFormAddNewStop,
  saveProfile,
  openFormShareByEmail,
  openFormLoad,
  closeFormLoad,
} from "../../store/actions";
import "sweetalert2/src/sweetalert2.scss";

import ApiService from "../../services/api.service";
import { Link } from "react-router-dom";
import {withRouter} from "react-router-dom";
import RightContentAttraction from "../content-attraction/right-content";
import FilterBar from "../content-attraction/filter-bar";

const listData = [];
for (let i = 0; i < 23; i++) {
  listData.push({
    href: "http://ant.design",
    title: `ant design part ${i}`,
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    description:
      "Ant Design, a design language for background applications, is refined by Ant UED Team.",
    content:
      "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
  });
}

const { Title, Text } = Typography;
const { Panel } = Collapse;

const categories = [
  {
    name: "Attraction",
    value: "Attraction",
  },
  {
    name: "Accommodation",
    value: "Lodging",
  },
  {
    name: "Restaurant",
    value: "Restaurant",
  },
  {
    name: "Tour & activity",
    value: "Activity",
  },
  {
    name: "Transportation",
    value: "Transportation",
  },
  {
    name: "Infor",
    value: "Infor",
  },
];

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    openFormAddNewStop: () => {
      dispatch(openFormAddNewStop());
    },
    openFormShareByEmail: () => {
      dispatch(openFormShareByEmail());
    },
    openFormLoad: () => {
      dispatch(openFormLoad());
    },
    closeFormLoad: () => {
      dispatch(closeFormLoad());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    selected2: state.listPlacesExploreReducer,
    itinerary: state.itineraryReducer,
    currency: state.itineraryReducer.currency,
    isReloadPdf: state.filePdfReducer,
    profile: state.profileReducer.info,
    isLoadingExplore: state.loadingReducer,
    showLoad: state.formLoadReducer,
  };
};

class ContentTopAttractions extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.filterBar = React.createRef();
    this.state = {
      showCreateItineraryForm: false
    };
  }

  closeCreateItineraryForm = () => {
    this.setState({
      showCreateItineraryForm: false,
    });
  };

  showFormCreateItinerary = () => {
    this.setState({
      showCreateItineraryForm: true,
    });
  };

  render() {
    return (
      <div id="content" className={styles.contentTopAttractions}>
        <div className="p-sm-0">
          <div className="grid-header bread d-inline-block w-100">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Attractions</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <FilterBar />
          <RightContentAttraction className="each-row-itineraries" grid={{md: 3, lg: 4, xl: 4, xxl: 6, xs: 1, sm: 1}}/>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ContentTopAttractions));
