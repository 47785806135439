import React from "react";
import {Card} from "antd";
import {getImageFromUrl, isMyResource} from "../../services/utils.service";
import {configBaseUrl} from "../../config";
import styles from "./tyneriCard.scss";
import defaultImg from "../../assets/media/images/generic-activities-img.jpg";

const TyneriCard = ({images, title, imageSize = 'large', ...props}) => {
    images = images ? getImageFromUrl(isMyResource(images) ? configBaseUrl.baseURL + images : images, imageSize) : defaultImg;
    return (
        <Card
            className={styles.cardTyneri}
            hoverable
            size="small"
            cover={
                <aside
                    style={{
                        backgroundImage: 'url("'+images+'")'
                    }}
                    className="img-fluid size-height"
                />
            }
        >
            <small className="subtit">{props.subtitle}</small>
            <Card.Meta
                title={title}
                description={props.description}
            />
        </Card>
    );
}

export default TyneriCard;
