import React, { Component } from "react";
import history from "./components/history";
import { BrowserRouter } from "react-router-dom";
import RouteControl from "./route";
import SimpleReactLightbox from "simple-react-lightbox";

class App extends Component {
    render() {
        return (
            <SimpleReactLightbox>
                <BrowserRouter basename="/app" history={history}>
                    <RouteControl />
                </BrowserRouter>
            </SimpleReactLightbox>
        );
    }
}

export default App;
