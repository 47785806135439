import { actionTypes } from '../../constants/actionTypes'

const initialState = null

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_IP:
            return action.ip
        default:
            return state
    }
}
