import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, Layout, Row } from "antd";
import "./index.scss";

const { Footer } = Layout;

const useStyles = makeStyles((theme) => ({
  footer: {
    zIndex: 10,
    marginTop: "auto",
    backgroundColor: "#1B2C49",
    WebkitBoxShadow: `0 0px 5px 0 rgba(0,0,0,0.2)`,
    MozBoxShadow: `0 0px 5px 0 rgba(0,0,0,0.2)`,
    boxShadow: `0 0px 5px 0 rgba(0,0,0,0.2)`,
    color: "white",
    padding: '10px 50px'
  },
}));

export default function () {
  const classes = useStyles();

  return (
      <Footer className={classes.footer + ' tyneri-footer'}>
          <div className="tyneri-container">
              <Row style={{width: '100%'}} className="align-items-center content-footer" type="flex" justify="space-between" align="middle">
                  <div style={{ textAlign: 'center' }}>
                      © 2020 Tyneri. All rights reserved
                  </div>
                  <ul className="social">
                      <li>
                          <a className="hover-effect" href="#">
                              <Icon type="facebook" />
                          </a>
                      </li>
                      <li>
                          <a href="#" className="hover-effect">
                              <Icon type="instagram" />
                          </a>
                      </li>
                      <li>
                          <a href="#" className="hover-effect">
                              <Icon type="twitter" />
                          </a>
                      </li>
                      <li>
                          <a href="#" className="hover-effect">
                              <Icon type="linkedin" />
                          </a>
                      </li>
                  </ul>
              </Row>
          </div>
      </Footer>
  );
}
