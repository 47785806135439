import React from "react";
import { Icon } from "antd";
import { configBaseUrl } from "../../config";
import NoPhoto from "../../assets/media/images/no-photo.png";
import moment from "moment";
import {
  EditItineraryForm,
  FormAddCountry,
  FormShareByEmail,
  ItineraryDetails,
  ItineraryPdf
} from "../../components";
import { loadItem } from "../../services/storage.service";
import { getAllLeaf, searchTree } from "../../services/utils.service";
import { format } from "date-fns";
import MoneyHelper from "../../helper/moneyHelper";
import { connect } from "react-redux";
import { TiCamera } from "react-icons/ti";
import styles from "../../components/content-detail/index.scss";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import ApiService from "../../services/api.service";
import {openFormShareByEmail, updateListItineraryDetail} from "../../store/actions";
import { Link } from "react-router-dom";

const EditDialog = ({ rerenderParentCallback, params, showFormEditItinerary, title, ...props }) => {
  // let myStyle = { padding: `0 30px` };
  // if (isMobile) {
  //   myStyle = { padding: `0 10px` };
  // }
  return (
    <Dialog
      open={showFormEditItinerary}
      fullWidth={true}
      maxWidth={"md"}
      aria-labelledby="edit-itinerary-form"
      className={styles.contentDetail+ ' display-unset'}
    >
      <MuiDialogTitle disableTypography className="popup-title">
        <Typography variant="h6">{title}</Typography>
        <IconButton
          aria-label="close"
          className="btn-close"
          onClick={() => props.handleCloseFormEditItinerary()}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <EditItineraryForm rerenderParentCallback={rerenderParentCallback} params={params} />
    </Dialog>
  );
};

const AddCountry = ({
  showFormAddCountry,
  close,
  rerenderParentCallback,
  ...props
}) => {
  return (
    <Dialog
      open={showFormAddCountry}
      fullWidth={true}
      maxWidth={"md"}
      aria-labelledby="form-add-country"
      className={styles.contentDetail + ' display-unset'}
    >
      <MuiDialogTitle disableTypography className="popup-title">
        <Typography variant="h6">Add country</Typography>
        <IconButton
          aria-label="close"
          className="btn-close"
          onClick={close.bind(this)}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <FormAddCountry
        rerenderParentCallback={rerenderParentCallback.bind(this)}
        params={props.params}
      />
    </Dialog>
  );
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      showFormEditItinerary: false,
      showOrder: false,
      showOrderCity: false,
      showFormAddCountry: false,
      written_by: null,
      showPDF: false,
      quotation: null,
    };
  }

  componentDidMount(): void {
    if (this.props.itinerary.belong_to_user) {
      this.apiService
        .getName(this.props.itinerary.belong_to_user)
        .then((res) => {
          if (res.status === "success") {
            this.setState({
              written_by: res.user.firstName + " " + res.user.lastName,
            });
          }
        });
    }
  }

  showOrderCountry = () => {
    this.setState({
      showOrder: true,
      showOrderCity: false,
    });
  };

  reRenderContenDetail = async (idItinerary, idItineraryDetail) => {
    this.setState({
      editDayTitleChangeDate: false,
    });
    var itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });
    var result = searchTree(
      itineraries[current].itinerary_detail,
      idItineraryDetail
    );

    var listDates = getAllLeaf(itineraries[current].itinerary_detail);
    this.setState({});

    this.setState({
      title: result[0].title,
      day: format(new Date(result[0].month_day_year * 1000), "d"),
      monthYear: result[0].month_day_year
        ? format(new Date(result[0].month_day_year * 1000), "MMM d, yyyy")
        : null,
      monthDayYear: result[0].month_day_year
        ? new Date(result[0].month_day_year * 1000)
        : null,
      lengthItineraryDetail: itineraries[current].itinerary_detail.length,
      listDates: listDates,
      items: result[0].place_detail,
      order: result[0].place_detail.length + 1,
    });
  };
  hideEditDayTitleChangeDate = (newItieraryDetail) => {
    if (newItieraryDetail === 2) {
      this.setState({
        editDayTitleChangeDate: false,
      });
    } else if (newItieraryDetail === 1) {
      this.setState(
        {
          editDayTitleChangeDate: false,
        },
        () => {
          this.props.updateListItineraryDetail("deleteItineraryDetail");
        }
      );
    } else if (newItieraryDetail !== 1) {
      var itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });

      let tempItem = searchTree(
        itineraries[current].itinerary_detail,
        this.props.params.idItineraryDetail
      )[0];

      this.setState(
        {
          editDayTitleChangeDate: false,
          title: newItieraryDetail.title,
          description: newItieraryDetail.description,
          file_list_trix_delete: newItieraryDetail.file_list_trix_delete,
          stay_in: newItieraryDetail.stay_in,
          meal: newItieraryDetail.meal,
          monthDayYear: newItieraryDetail.month_day_year
            ? new Date(newItieraryDetail.month_day_year * 1000)
            : null,
          day: newItieraryDetail.month_day_year
            ? format(new Date(newItieraryDetail.month_day_year * 1000), "d")
            : null,
          monthYear: newItieraryDetail.month_day_year
            ? format(
                new Date(newItieraryDetail.month_day_year * 1000),
                "MMM d, yyyy"
              )
            : null,
          index: tempItem.index,
        },
        () => {
          this.props.updateListItineraryDetail("editDayTitleChangeDate");
        }
      );
    }
  };

  handleShowFormEditItinerary = () => {
    this.setState({
      showFormEditItinerary: true,
    });
  };

  handleOpenFormShare = () => {
    /*this.setState({
            anchorEl: null,
        });*/
    console.log("here");
    this.props.openFormShareByEmail();
  };

  handleCloseFormEditItinerary = () => {
    this.setState({ showFormEditItinerary: false });
  };

  rerenderParentCallback = () => {
    this.setState(
      {
        showFormAddCountry: false,
        showFormEditItinerary: false,
      },
      () => {
        this.child.componentDidMount();
      }
    );
  };

  handleOpenViewPDF = async () => {
    let response = await this.apiService.convertItineraryToPdf(
      this.props.params.idItinerary
    );
    if (response.status === "success") {
      this.setState({
        quotation: response.data,
        showPDF: true,
      });
    }
  };

  closeViewPDF = () => {
    this.setState({
      showPDF: false,
    });
  };

  render() {
    let budgetTitle = null;
    if (this.props.currency_infor) {
      const estimated_budget = this.props.itinerary.estimated_budget
        ? ` on a ${MoneyHelper(this.props.itinerary.estimated_budget).format({
            symbol: this.props.currency_infor.symbol,
            precision: this.props.currency_infor.decimal_digits,
          })} USD budget`
        : "";
      let a_number_of_paxs = this.props.itinerary.a_number_of_paxs
        ? ` for ${this.props.itinerary.a_number_of_paxs} pax`
        : "";
      budgetTitle =
        this.props.itinerary.name + estimated_budget + a_number_of_paxs;
    }
    return (
      <div className={this.props.className}>
        <ItineraryPdf showPDF={this.state.showPDF} closeViewPDF={this.closeViewPDF} quotation={this.state.quotation} />

        <FormShareByEmail params={this.props.params} />
        <EditDialog
          title={this.props.itinerary.name}
          params={this.props.params}
          handleCloseFormEditItinerary={this.handleCloseFormEditItinerary}
          showFormEditItinerary={this.state.showFormEditItinerary}
          rerenderParentCallback={this.rerenderParentCallback}
        />
        <AddCountry
          showFormAddCountry={this.state.showFormAddCountry}
          close={() => this.setState({ showFormAddCountry: false })}
          rerenderParentCallback={this.rerenderParentCallback}
          params={this.props.params}
        />
        <div className="container bg-light">
          <div
            style={
              this.props.itinerary.cover
                ? {
                    backgroundImage: `url("${configBaseUrl.baseURL}${this.props.itinerary.cover}")`,
                  }
                : { backgroundImage: `url("${NoPhoto}")` }
            }
            className="cover-image"
          >
            <div onClick={this.handleShowFormEditItinerary.bind(this)}>
              <span>
                <TiCamera />
              </span>
            </div>
          </div>
          <div className="tyneri-title">
            <div
              style={{
                fontSize: `1.0rem`,
                paddingTop: `0.5rem`,
              }}
            >
              {budgetTitle}
            </div>
          </div>
          {this.props.itinerary && (
            <div className="written-by">
              {this.props.itinerary.created_at &&
                moment(new Date(this.props.itinerary.created_at)).fromNow() +
                  " - "}
              <React.Fragment>
                By {this.state.written_by ? this.state.written_by : "Tyneri"}
              </React.Fragment>
            </div>
          )}
          <div className="row-menu">
            <div style={{ textAlign: "right", display: `flex` }}>
              <Link
                className="each-menu"
                // onClick={this.handleDiscuss.bind(this)}
                to={"/preview-itinerary/" + this.props.params.idItinerary}
                target="_blank"
              >
                <Icon type="file-search" />
                <span>Preview</span>
              </Link>
              <div
                className="each-menu"
                onClick={this.handleOpenFormShare.bind(this)}
              >
                <Icon type="share-alt" />
                <span>Share</span>
              </div>
              <div className="each-menu" onClick={this.handleOpenViewPDF.bind(this)}>
                <Icon type="file-pdf" />
                <span>PDF</span>
              </div>
              <div
                className="each-menu"
                onClick={() => {
                  this.setState({ showFormAddCountry: true });
                }}
              >
                <Icon type="plus" />
                <span>Add country</span>
              </div>
              <div className="each-menu" onClick={this.showOrderCountry.bind(this)}>
                <Icon type="sort-ascending" />
                <span>Re-order</span>
              </div>
              <div
                className="each-menu"
                onClick={this.handleShowFormEditItinerary.bind(this)}
              >
                <Icon type="edit" />
                <span>Edit</span>
              </div>
            </div>
          </div>
          <ItineraryDetails
            onRef={(ref) => (this.child = ref)}
            reRenderContenDetail={this.reRenderContenDetail}
            params={this.props.params}
            location={this.props.location}
            hideEditDayTitleChangeDate={this.hideEditDayTitleChangeDate}
            handleShowOrderCountry={this.state.showOrder}
            hideOrderCountry={() => this.setState({showOrder: false})}
            showOrderCity={() => !this.state.showOrderCity}
            hideOrderCity={() => !this.state.showOrderCity}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currency_infor: state.itineraryReducer.currency_infor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openFormShareByEmail: () => {
      dispatch(openFormShareByEmail());
    },
    updateListItineraryDetail: (typeUpdate) => {
      dispatch(updateListItineraryDetail(typeUpdate));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
