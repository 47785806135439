import React, {useState} from "react";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import {FacebookShareButton, TwitterShareButton} from "react-share";
import {FaFacebook, FaTwitter, FaRegEye} from "react-icons/fa";
import ShareDialog from "../share/shareDialog";
import {withRouter} from "react-router-dom";
import styles from "./index.scss";
import {formatViews} from "../../services/utils.service";

const ShareSection = (props) => {
    const [showForm, setShowForm] = useState(false);

    return (
        <div className={styles.shareSection}>
            <ShareDialog showForm={showForm} close={()=> setShowForm(false)} idItinerary={props.match.params.idItinerary}/>
            <div
                className="share-by-email"
                onClick={() => setShowForm(true)}
            >
                <MailOutlineIcon />
            </div>

            <FacebookShareButton
                url={`https://tyneri.com${props.location.pathname}`}
                className="Demo__some-network__share-button"
            >
                <FaFacebook className="icon icon-facebook" />
            </FacebookShareButton>
            <TwitterShareButton
                url={`https://tyneri.com${props.location.pathname}`}
                className="Demo__some-network__share-button"
            >
                <FaTwitter className="icon icon-twitter" />
            </TwitterShareButton>
            {/* {props.views ? <div className="view-count">
                <FaRegEye className="icon" /> {formatViews(props.views)}
            </div> : null} */}
        </div>
    );
}

export default withRouter(ShareSection);
