import React from "react";
import PropTypes from "prop-types";
import Menu, {
  SubMenu,
  Item as MenuItem,
  ItemGroup as MenuItemGroup
} from "rc-menu";
import "rc-menu/assets/index.css";
import Avatar from "@material-ui/core/Avatar";
import styles from "./index.scss";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

function handleClick(info) {}

function onOpenChange(value) {}

class MenuSample extends React.Component {
  state = {
    overflowedIndicator: undefined
  };

  handleChangeSample = id => {
    window.location = `/itinerary/${id}`;
  };

  render() {
    const { triggerSubMenuAction, data } = this.props;
    const { overflowedIndicator } = this.state;
    return (
      <Menu
        className="none-background "
        onClick={handleClick}
        triggerSubMenuAction={triggerSubMenuAction}
        selectedKeys={["3"]}
        onOpenChange={onOpenChange}
        mode={this.props.mode}
        openAnimation={this.props.openAnimation}
        defaultOpenKeys={this.props.defaultOpenKeys}
        overflowedIndicator={overflowedIndicator}
      >
        <SubMenu
          className="try-sample MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge"
          title={
            <span className="submenu-title-wrapper">
              {this.props.title}
              <ArrowDropDownIcon />
            </span>
          }
          key="4"
          popupOffset={[10, 15]}
        >
          {/* <MenuItemGroup title={<strong><span>Demo</span></strong>}>
                        {
                            !!demo.length && demo.map((item, index) => (
                                item.itineraries.map((child, key) => (
                                    <MenuItem onClick={this.handleChangeSample.bind(this, child._id)} key={child._id}>{child.name}</MenuItem>
                                ))
                            ))
                        }
                    </MenuItemGroup> */}
          <MenuItemGroup
            title={
              <strong>
                <span>Top Destinations</span>
              </strong>
            }
          >
            {!!data.length &&
              data.map((item, index) => (
                <SubMenu
                  className={styles.menuSample}
                  title={
                    <div className="MuiListItem-root MuiListItem-gutters">
                      <ListItemAvatar>
                        <Avatar
                          alt={item.country.nativeName}
                          className="flag-image"
                          src={item.country.flag}
                        />
                      </ListItemAvatar>
                      <ListItemText primary={item.country.nativeName} />
                      <i className="menu-arrow"></i>
                    </div>
                  }
                  key={item.country.nativeName}
                >
                  <MenuItemGroup
                    title={
                      <div className="title-each-country">
                        <div className="left-side">
                          Popular Tyneris from {item.country.nativeName}
                        </div>
                        <div className="right-side">
                          See all {item.count} {item.count > 1 ? 'tyneris' : 'tyneri'}
                        </div>
                      </div>
                    }
                  >
                    {item.itineraries.map((child, key) => (
                      <MenuItem
                        onClick={this.handleChangeSample.bind(this, child._id)}
                        key={child._id}
                      >
                        {child.name}{" "}
                        {child.estimated_budget &&
                          ` on a $${child.estimated_budget} USD budget`}{" "}
                        {child.a_number_of_paxs &&
                          ` for ${child.a_number_of_paxs} pax`}
                      </MenuItem>
                    ))}
                  </MenuItemGroup>
                </SubMenu>
              ))}
          </MenuItemGroup>
        </SubMenu>
      </Menu>
    );
  }
}

MenuSample.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.string,
  openAnimation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  triggerSubMenuAction: PropTypes.string,
  defaultOpenKeys: PropTypes.arrayOf(PropTypes.string),
  updateChildrenAndOverflowedIndicator: PropTypes.bool
};

export default MenuSample;
