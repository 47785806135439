import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { initialTab } from '../../../../store/actions/utility/tab';
import { QUOTATION_TAB } from '../../../../constants/quotation';
import TabContainer from '../../../../components/Tab/TabContainer';
import './index.scss';

function QuotationContainer(props) {
  useEffect(() => {
    const { dispatch } = props;
    dispatch(initialTab(QUOTATION_TAB));
  });

  return (
    <div className=" quotation-container ">
      <div className="quotation-container__new-quotation-btn">
        <Link to="/create-quotation" className="link">
          +New Quotation
        </Link>
      </div>
      <TabContainer {...props} classNameTab={'quotation-container__tab'} />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

export default connect(null, mapDispatchToProps)(QuotationContainer);
