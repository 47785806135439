import React, { Component } from 'react';
import { Col, Row, Breadcrumb, Pagination } from 'antd';
import { connect } from 'react-redux';
import ApiService from '../../services/api.service';
import Filter from '../content-trendy/filter';
import { isBrowser, isMobileOnly } from 'react-device-detect';
import ListCountry from './list-country';
import styles from './index.scss';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { ListCard } from '../../components';
import { Link } from 'react-router-dom';
import RightContentAttraction from '../content-attraction/right-content';
import ListAttraction from './list-attraction';
import ListScrollCountry from './list-scroll-country';

class ContentTopDestinations extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      listItineraries: [],
      topDestinations: [],
      list_optionals: [],
      listDetail: [],
      detailParams: {
        page: 1,
        limit: 8,
      },
      country: null,

      data: [],
      totalData: 0,
      page: 1,
      paramLimit: 12,
      detailMode: false,

      listScrollCountry: [],
    };
  }

  componentDidMount = () => {
    this.apiService.getFilter().then((res) => {
      if (res.status === 'success') {
        this.setState({
          listItineraries: res.filter,
        });
      }
    });
    if (this.props.match.params.alphacode) {
      this.setDataDetail();
    } else {
      this.setData(this.props.location.search);
    }
  };

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    if (
      this.props.match.params.alphacode !== prevProps.match.params.alphacode
    ) {
      this.setDataDetail(this.props.match.params.alphacode);
    } else {
      if (this.props.location.search !== prevProps.location.search) {
        this.setData(this.props.location.search);
      }
    }
  }

  setData = (query) => {
    const params = queryString.parse(query);
    this.setState({
      paramLimit: params.limit || 12,
      page: +params.page || 1,
      detailMode: false,
    });
    this.apiService
      .getAllTopDestinations(params.page || 1, params.limit || 12)
      .then((res) => {
        if (res.status === 'success') {
          this.setState({
            data: res.top_destinations,
            totalData: res.total,
          });
        }
      });
  };

  loadMoreDetail = (params) => {
    this.setState(
      {
        detailParams: params,
      },
      () => {
        this.setDataDetail();
      }
    );
  };

  setDataDetail = () => {
    let old = this.state.listDetail;
    if (this.state.detailParams.page > 1) {
      this.setState({
        listDetail: old.concat(
          [...new Array(this.state.detailParams.limit)].map(() => ({
            loading: true,
            name: {},
          }))
        ),
      });
    } else {
      this.setState({
        listDetail: [].concat(
          [...new Array(this.state.detailParams.limit)].map(() => ({
            loading: true,
            name: {},
          }))
        ),
      });
    }
    this.apiService
      .getItinerariesOfCountry(
        this.props.match.params.alphacode,
        this.state.detailParams
      )
      .then((res) => {
        console.log(res);
        if (res.status === 'success') {
          let listDetail;
          if (this.state.detailParams.page > 1) {
            listDetail = old.concat(res.itinerary);
          } else {
            listDetail = res.itinerary;
          }
          this.setState(
            {
              detailMode: true,
              listDetail: listDetail,
              country: res.country,
              detailParams: {
                total: res.total,
                ...this.state.detailParams,
              },
            },
            () => {
              this.apiService
                .getTopDestinations(res.country.alpha2Code)
                .then((result) => {
                  if (result.status === 'success') {
                    this.setState({
                      listScrollCountry: result.top_destinations,
                    });
                  }
                });
            }
          );
        }
      });
  };

  render() {
    return (
      <div id="content" className={styles.contentTopDestinations}>
        <div id="sidebar" className="content-center ">
          <div className="grid-header bread">
            {!!this.state.country ? (
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/top-destinations">Top destinations</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{this.state.country.name}</Breadcrumb.Item>
              </Breadcrumb>
            ) : (
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Top destinations</Breadcrumb.Item>
              </Breadcrumb>
            )}
          </div>
          {this.state.detailMode && (
            <ListScrollCountry listCountry={this.state.listScrollCountry} />
          )}
          {/* Latest itineraries */}
          {!!this.state.listDetail.length && this.state.detailMode ? (
            <React.Fragment>
              <ListCard
                title={`Explore ${this.state.country.name}`}
                list={this.state.listDetail}
                params={this.state.detailParams}
                onLoad={this.loadMoreDetail}
              />
              <ListAttraction alphacode={this.props.match.params.alphacode} />
            </React.Fragment>
          ) : (
            <>
              <ListCountry list={this.state.data} />
              <Pagination
                style={{ float: 'right', margin: '1rem 0' }}
                pageSize={+this.state.paramLimit}
                current={this.state.page}
                total={this.state.totalData}
                onChange={(page) => {
                  this.setState({
                    page,
                    data: [],
                  });
                  const params = queryString.parse(this.props.location.search);
                  let limit = params.limit
                    ? '&limit=' + params.limit
                    : '&limit=' + 12;
                  this.props.history.push(
                    `/top-destinations?page=${page}` + limit
                  );
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

export default withRouter(
  connect(mapStateToProps, null)(ContentTopDestinations)
);
