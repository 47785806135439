import { actionTypes } from '../../constants/actionTypes'

var initialState = false

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_FORM_SHARE_BY_EMAIL:
            state = true
            return state
        case actionTypes.CLOSE_FORM_SHARE_BY_EMAIL:
            state = false
            return state
        default:
            return state
    }
}
