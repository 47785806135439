import React, { Component } from "react";
import { Col, Row, Breadcrumb, Pagination } from "antd";
import { connect } from "react-redux";
import ApiService from "../../services/api.service";
import Filter from "../content-trendy/filter";
import { isBrowser, isMobileOnly } from "react-device-detect";
import ListCard from "./list-card";
import styles from "./index.scss";
import { Link } from "react-router-dom";
import queryString from "query-string"
import { withRouter } from "react-router";

class ContentLatest extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      listItineraries: [],
      list_optionals: [],

      data: [],
      totalData: 0,
      page: 1,
      paramLimit: 20,
    };
  }

  componentDidMount = () => {
    // this.apiService.getAllOptionals().then((res) => {
    //   if (res.status === "success") {
    //     this.setState({
    //       list_optionals: res.list_optionals,
    //     });
    //   }
    // });

    // this.apiService.getFilter().then((res) => {
    //   if (res.status === "success") {
    //     this.setState({
    //       listItineraries: res.filter,
    //     });
    //   }
    // });
    this.setData(this.props.location.search)
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.location.search !== nextProps.location.search) {
      this.setData(nextProps.location.search)
    }
  }

  setData = (query) => {
    const params = queryString.parse(query);
    this.setState({
      paramLimit: params.limit || 20,
      page: +params.page || 1
    })
    this.apiService.getAll(params.page || 1, params.limit || 20).then((res) => {
      if (res.status === "success") {
        this.setState({
          data: res.list,
          totalData: res.total,
        });
      }
    });
  }

  render() {
    return (
      <div id="content" className={styles.contentLatest}>
        <Row>
          {/*  Left */}
          <Col
            className="filter-on-mobile content-left"
            md={8}
            xl={isBrowser ? 6 : 8}
            xxl={isBrowser ? 6 : 8}
          >
            {/* <div className="filter">
              {isMobileOnly ? <SearchBar /> : null}
              <Filter
                listCountries={this.state.listItineraries}
                list_optionals={this.state.list_optionals}
              />
            </div> */}
          </Col>

          {/*  Right */}
          <Col
            md={16}
            xl={isBrowser ? 18 : 16}
            xxl={isBrowser ? 18 : 16}
            id="sidebar"
            className="content-center "
          >
            <div className="grid-header bread">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Latest itineraries</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            {/* Latest itineraries */}
            <ListCard title={"Latest itineraries"} list={this.state.data} />
            <Pagination
              style={{ float: "right", margin: "1rem 0" }}
              pageSize={+this.state.paramLimit}
              current={this.state.page}
              total={this.state.totalData}
              onChange={(page) => {
                this.setState({
                  page
                })
                const params = queryString.parse(this.props.location.search);
                let limit = params.limit
                  ? "&limit=" + params.limit
                  : "&limit=" + 20;
                this.props.history.push(`/latest?page=${page}` + limit);
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

export default withRouter(connect(mapStateToProps, null)(ContentLatest));
