import React, { Component } from "react";
import { Row, Col, Badge } from "reactstrap";
import ApiService from "../../services/api.service";
import Typography1 from "@material-ui/core/Typography";
import "rc-time-picker/assets/index.css";
import { connect } from "react-redux";
import { loadItem } from "../../services/storage.service";
import "./index.scss";
import moment from "moment";
import { Form, Input, Select } from "antd";
import {
  getAllLeaf,
} from "../../services/utils.service";
import { format } from "date-fns";

import { configBaseUrl } from "../../config";
import { categoriesDefined } from "../../constants/categories";
import _ from "lodash"
const { Option } = Select;

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    currency: state.itineraryReducer.currency,
  };
};

class InforTab extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      _id: "",
      contentState: {},
      title: "",
      address: "",
      lat: "",
      lng: "",
      descriptions: "",
      time_start: null,
      day_start: "",
      listDay: [],

      category: "",
      loading: false,
      file_list_trix_delete: [],
    };
  }

  handleChange = (name) => (event) => {
    if (name === "day_start") {
      this.setState(
        {
          day_start: event,
        },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            descriptions: this.state.descriptions,
            time_start: moment(this.state.time_start, "HH:mm")._i,
            day_start: this.state.day_start,
            file_list_trix_delete: this.state.file_list_trix_delete,
            images: [],
          });
        }
      );
    } else {
      this.setState(
        {
          [name]: event.target.value,
        },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            descriptions: this.state.descriptions,
            time_start: moment(this.state.time_start, "HH:mm")._i,
            day_start: this.state.day_start,
            file_list_trix_delete: this.state.file_list_trix_delete,
            images: [],
          });
        }
      );
    }
  };

  handleChangeNotes = (value) => {
    this.setState(
      {
        descriptions: value,
      },
      () => {
        this.props.onFillForm({
          _id: this.state._id,
          category: this.state.category,
          title: this.state.title,
          address: this.state.address,
          lat: this.state.lat,
          lng: this.state.lng,
          descriptions: this.state.descriptions,
          time_start: moment(this.state.time_start, "HH:mm")._i,
          day_start: this.state.day_start,
          file_list_trix_delete: this.state.file_list_trix_delete,
          images: [],
        });
      }
    );
  };

  uploadFileAttachment = async (attachment, setProgress, setAttributes) => {
    // await this.uploadFile(attachment.file, setProgress, setAttributes);

    // // function setProgress(progress) {
    // //   attachment.setUploadProgress(progress);
    // // }

    // attachment.setUploadProgress(100)

    // function setAttributes(attributes) {
    //   attachment.setAttributes(attributes);
    // }

    const formData = new FormData();
    formData.append("file", attachment.file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      // onUploadProgress: function(progressEvent) {
      //   var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      //   progressCallback(percentCompleted)
      // }
    };

    return this.apiService.uploadImage(formData, config).then((res) => {
      if (res.status === "success") {
        attachment.setUploadProgress(100);
        var attributes = {
          url: configBaseUrl.baseURL + res.fileNameInServer,
          href: configBaseUrl.baseURL + res.fileNameInServer,
        };
        attachment.setAttributes(attributes);
      }
    });
  };

  uploadFile = (file, progressCallback, successCallback) => {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      // onUploadProgress: function(progressEvent) {
      //   var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      //   progressCallback(percentCompleted)
      // }
    };

    return this.apiService.uploadImage(formData, config).then((res) => {
      if (res.status === "success") {
        var attributes = {
          url: configBaseUrl.baseURL + res.fileNameInServer,
          href: configBaseUrl.baseURL + res.fileNameInServer,
        };
        successCallback(attributes);
      }
    });
  };

  createStorageKey = (file) => {
    var date = new Date();
    var day = date.toISOString().slice(0, 10);
    var name = date.getTime() + "-" + file.name;
    return ["tmp", day, name].join("/");
  };

  createFormData = (key, file) => {
    var data = new FormData();
    data.append("key", key);
    data.append("Content-Type", file.type);
    data.append("file", file);
    return data;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    document.removeEventListener("trix-initialize", this.trixEmbedVideo);
    document.removeEventListener("trix-attachment-add", this.func);
    document.removeEventListener(
      "trix-attachment-remove",
      this.trixRemoveAttachmentEvent
    );
  };

  trixEmbedVideo = (event) => {
    let editor = event.target;
    let toolbar = editor.toolbarElement;
    let ttools = toolbar.querySelector(".trix-button-group--text-tools");
    let dialogs = toolbar.querySelector(".trix-dialogs");
    let trixId = editor.trixId;

    let buttonContent = `
      <button type="button"
        class="trix-button trix-button--icon trix-button--icon-video"
        data-trix-attribute="video"
        data-trix-key="+" title="Embed video" tabindex="-1">
      </button>
    `;

    let dialogContent = `
      <div class="trix-dialog trix-dialog--video" data-trix-dialog="video" data-trix-dialog-attribute="video">
        <div class="trix-dialog__attach-fields">
          <input data-type="url-video" class="trix-input trix-input--dialog" placeholder="Insert url youtube video here">
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog embed-video"
              onclick="
                var trix = document.querySelector('trix-editor[trix-id=\\'${trixId}\\']');
                var videoEmbed = this.parentElement.parentElement.querySelector('input[data-type=\\'url-video\\']');
              
                var url = videoEmbed.value
                var video_id = url.split('v=')[1];
                var ampersandPosition = video_id.indexOf('&');
                if(ampersandPosition != -1) {
                  video_id = video_id.substring(0, ampersandPosition);
                }

                const iframeMarkup = '<iframe width=\\'560\\' height=\\'315\\' src=\\'https://www.youtube.com/embed/'+video_id+'\\' frameborder=\\'0\\' allowfullscreen></iframe>';
                var attachment = new Trix.Attachment({content: iframeMarkup})
                trix.editor.insertAttachment(attachment)
              "
              value="Embed video" data-trix-method="removeAttribute">
            <input type="button" class="trix-button trix-button--dialog" value="Cancel" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
    `;
    // add attach icon button
    ttools.insertAdjacentHTML("beforeend", buttonContent);
    // add dialog
    dialogs.insertAdjacentHTML("beforeend", dialogContent);
  };

  func = (event) => {
    if (event.attachment.file) {
      //this.uploadFileAttachment(event.attachment);
      const formData = new FormData();
      formData.append("description_image", event.attachment.file);
      var percentCompleted;
      this.apiService
        .uploadImage(formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((res) => {
          if (res.status === "success" && percentCompleted === 100) {
            event.attachment.setUploadProgress(100);
            var attributes = {
              url: configBaseUrl.baseURL + res.path,
              href: configBaseUrl.baseURL + res.path,
            };
            event.attachment.setAttributes(attributes);
          }
        });
    }
  };

  trixRemoveAttachmentEvent = (event) => {
    const attributes = event.attachment.getAttributes();
    if (attributes.href) {
      var nameFileDelete = attributes.href.split("/");
      nameFileDelete = nameFileDelete[nameFileDelete.length - 1];
      this.setState(
        {
          file_list_trix_delete: [
            ...this.state.file_list_trix_delete,
            nameFileDelete,
          ],
        },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            descriptions: this.state.descriptions,
            time_start: moment(this.state.time_start, "HH:mm")._i,
            day_start: this.state.day_start,
            file_list_trix_delete: this.state.file_list_trix_delete,
            images: [],
          });
        }
      );
    }
  };

  componentDidMount = async () => {
    this._isMounted = true;

    document.addEventListener("trix-attachment-add", this.func);
    document.addEventListener(
      "trix-attachment-remove",
      this.trixRemoveAttachmentEvent
    );

    let temp = _.find(this.props.categories, (category) => {
      return category.name === categoriesDefined.INFORMATION;
    });

    this.setState({
      category: temp._id
    })

    document.addEventListener("trix-initialize", this.trixEmbedVideo);

    document.addEventListener("trix-change", (event) => {
      var element = event.target;
      var html = element.value;
      this.setState(
        {
          descriptions: html,
        },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            descriptions: this.state.descriptions,
            time_start: moment(this.state.time_start, "HH:mm")._i,
            day_start: this.state.day_start,
            file_list_trix_delete: this.state.file_list_trix_delete,
            images: [],
          });
        }
      );
    });

    if (this._isMounted) {
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      var result = getAllLeaf(itineraries[current].itinerary_detail);
      // result.sort((data_A, data_B) => {
      //   return compareAsc(
      //     new Date(data_A.month_day_year * 1000),
      //     new Date(data_B.month_day_year * 1000)
      //   );
      // });
      this.setState(
        {
          listDay: result,
        },
        () => {
          let tempDayStart = result.filter((item) => {
            return item._id === this.props.params.idItineraryDetail;
          });
          this.setState({
            day_start: tempDayStart[0]._id,
          });
        }
      );
    }

    if (this.props.item) {
      const { item } = this.props;
      let tempImgs = [];
      var element = document.querySelector("trix-editor");
      element.editor.insertHTML(
        this.props.item.data.actual_data.descriptions
          ? this.props.item.data.actual_data.descriptions
          : ""
      );
      element.blur();
      if (item.data.actual_data.images) {
        item.data.actual_data.images.forEach((item) => {
          tempImgs.push({
            ...item,
            url: item.preview,
          });
        });
      }
      this.setState(
        {
          _id: item._id,
          category: temp._id,
          title: item.data.actual_data.title ? item.data.actual_data.title : "",
          address: item.data.actual_data.address
            ? item.data.actual_data.address
            : "",
          lat: item.data.actual_data.lat ? item.data.actual_data.lat : "",
          lng: item.data.actual_data.lng ? item.data.actual_data.lng : "",
          descriptions: item.data.actual_data.descriptions
            ? item.data.actual_data.descriptions
            : "",
          time_start: item.data.actual_data.time_start
            ? moment(item.data.actual_data.time_start, ["h:m a", "H:m"])
            : null,
          day_start: item.data.actual_data.day_start
            ? item.data.actual_data.day_start
            : "",
        },
        () => {
          this.props.onFillForm({
            _id: this.state._id,
            category: this.state.category,
            title: this.state.title,
            address: this.state.address,
            lat: this.state.lat,
            lng: this.state.lng,
            descriptions: this.state.descriptions,
            time_start: item.data.actual_data.time_start
              ? item.data.actual_data.time_start
              : "",
            day_start: this.state.day_start,
            file_list_trix_delete: this.state.file_list_trix_delete,
            images: [],
          });
        }
      );
    }
  };

  handleChangeCategory = (value, option) => {
    if (option.props.children === categoriesDefined.ATTRACTION)
      this.props.changeCategory(1);
    if (option.props.children === categoriesDefined.ACCOMMODATION)
      this.props.changeCategory(2);
    if (option.props.children === categoriesDefined.RESTAURANT)
      this.props.changeCategory(3);
    if (option.props.children === categoriesDefined.TOURACTIVITY)
      this.props.changeCategory(4);
    if (option.props.children === categoriesDefined.TRANSPORTATION)
      this.props.changeCategory(5);
    if (option.props.children === categoriesDefined.INFORMATION)
      this.props.changeCategory(6);

    this.setState({
      category: value,
    }, () => {
      this.props.onFillForm({
        _id: this.state._id,
        category: this.state.category,
        title: this.state.title,
        address: this.state.address,
        lat: this.state.lat,
        lng: this.state.lng,
        descriptions: this.state.descriptions,
        time_start: moment(this.state.time_start, "HH:mm")._i,
        day_start: this.state.day_start,
        file_list_trix_delete: this.state.file_list_trix_delete,
        images: [],
      });
    });
  };

  render() {
    return (
      <>
      <Row>
          <Col xs={12} md={6}>
            <div className="basic-infor">
              {/* <div className="basic-infor__header">Basic</div> */}
              <Row className="each-row-in-form date">
                <Col xs="12" sm="12" md="12" lg="12">
                  <Form.Item
                    label="Date"
                    labelAlign="left"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    colon={false}
                  >
                    <Select
                      label="Date"
                      value={this.state.day_start}
                      defaultValue={[`${this.state.day_start}`]}
                      onChange={this.handleChange("day_start")}
                    >
                      {this.state.listDay.map((option, index) => (
                        <Option key={option._id} value={option._id}>
                          <Typography1 variant="inherit" noWrap>
                            {option.title ? (
                              option.month_day_year ? (
                                <span>
                                  <Badge color="primary">Day {index + 1}</Badge>{" "}
                                  {format(
                                    new Date(option.month_day_year * 1000),
                                    "MMM d, yyyy"
                                  )}
                                  <br />
                                  {option.title}
                                </span>
                              ) : (
                                <span>
                                  <Badge color="primary">Day {index + 1}</Badge>{" "}
                                  <br />
                                  {option.title}
                                </span>
                              )
                            ) : option.month_day_year ? (
                              <span>
                                <Badge color="primary">Day {index + 1}</Badge>{" "}
                                {format(
                                  new Date(option.month_day_year * 1000),
                                  "MMM d, yyyy"
                                )}
                              </span>
                            ) : (
                              <span>
                                <Badge color="primary">Day {index + 1}</Badge>{" "}
                              </span>
                            )}
                          </Typography1>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="each-row-in-form">
                <Col xs="12">
                  <Form.Item
                    label="Category"
                    labelAlign="left"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    colon={false}
                  >
                    {this.state.category ? (
                      <Select
                        defaultValue={this.state.category}
                        onChange={this.handleChangeCategory.bind(this)}
                      >
                        {!!this.props.categories.length &&
                          this.props.categories.map((item) => (
                            <Option key={item._id} value={item._id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="each-row-in-form">
                <Col xs="12" sm="12" md="12" lg="12">
                  <Form.Item
                    label="Title"
                    required
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    labelAlign="left"
                    colon={false}
                    validateStatus={
                      !this.props.validate && (this.state.title === "" || !this.state.title.trim())
                        ? "error"
                        : ""
                    }
                    help={
                      !this.props.validate && (this.state.title === "" || !this.state.title.trim())
                        ? "Title is required"
                        : ""
                    }
                  >
                    <Input
                      value={this.state.title}
                      onChange={this.handleChange("title")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={6}>
          </Col>
        </Row>
        <Row className="each-row-in-form" style={{ marginBottom: "16px" }}>
          <Col sm="12" md="12" lg="12">
            <input type="hidden" id="trix" />
            <trix-editor class="trix-content" input="trix" />
          </Col>
        </Row>
      </>
    )
  }
}

export default connect(mapStateToProps, null)(InforTab);
