import Home from "../../module/company/components/home";
import RouteModel from '../model/route';

const routesCompany = [
    RouteModel({
      path: "/",
      component: Home ,
      privateRoute: false,
    }),
]
export default routesCompany;