import axios from '../axios-config';

export default () => {
  let services = {
    insertNewInquiries: (data) =>  {
      try {
        return axios.post("inquiries/save", {
          ...data,
        }).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    getInquiriesByUserIdAndStatus: (userId, status) => {
      try {
        return  axios.get("inquiries/getByUser/"+userId+"?status=" + status,).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    updateInquiriesStatus: (id, status) => {
      try {
        return  axios.post("inquiries/updateStatus/"+id, {
          status: status
        }).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    deleteInquiries: (id) => {
      try {
        return  axios.post("inquiries/delete/" + id).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    }

  }
  return services;
}