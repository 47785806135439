import React from "react";
import TitleTyneri from "../../../components/modules/typography/title";
import { Col, Row } from "antd";
import "./index.scss";
import CardTyneri from "../../../components/modules/card/card";
import {Link} from "react-router-dom";

class TopLists extends React.Component {
    render() {
        return (
            <div className="row-itineraries content-top-lists">
                <div className="grid-header">
                    <TitleTyneri text={"Top lists"} seeMore={<Link to="/top-lists">
                        <span className="see-more">See more</span>
                    </Link>} />
                </div>
                <Row type="flex" gutter={[16, 16]}>
                    {this.props.data &&
                    !!this.props.data.length &&
                    this.props.data.map((item, key) => (
                        <Col key={key} xs={24} md={12} lg={6}>
                            <CardTyneri item={item} />
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }
}

export default TopLists;
