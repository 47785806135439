import React from 'react';

function ButtonContainer(props) {
  const {
    quotation,
    showCancel,
    submitFormQuotation,
    saveQuotationAndSendEmail,
    submitCancel,
  } = props;
  return (
    <>
      {showCancel && (
        <div className="cancel-container">
          <button
            onClick={() => submitCancel()}
            className="btn-cancel btn-custom"
          >
            {quotation.is_cancel ? 'Uncancel' : 'Cancel'}
          </button>
        </div>
      )}

      <div className={`submit-container__content `}>
        <button
          onClick={() => {
            saveQuotationAndSendEmail();
          }}
          className="btn-submit-email btn-custom"
        >
          Send email
        </button>
        <button
          onClick={() => {
            submitFormQuotation();
          }}
          className="btn-submit btn-custom"
        >
          Save
        </button>
      </div>
    </>
  );
}

export default ButtonContainer;
