import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ApiService from "../../../../services/api.service";
import "./index.scss";
import ListResponsive from "../../../../components/list-my-tyneries/list-responsive";
import {
  Pagination,
} from "antd";
import { withRouter } from "react-router";
import queryString from "query-string"

function MyTyneri(props) {
  const apiService = ApiService();
  const [dataTable, setDataTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [paramLimit, setParamLimit] = useState(10);
  useEffect(() => {
    if (props.profile) {
      setData();
    }
  }, [props.profile, props.location.search]);

  function setData() {
    const params = queryString.parse(props.location.search);
    setParamLimit(params.limit || 10)
    setPage(+params.page || 1)
    apiService.getMyTyneries(params.page || 1, params.limit || 10).then((res) => {
      if (res.status === "success") {
        setDataTable(res.my_tyneries);
        setTotalData(res.total)
      }
    });
  }

  return (
    <div className="table-custom">
      <ListResponsive setDataTable={setDataTable} list={dataTable} />
      <Pagination
        style={{ float: "right", marginTop: "1rem" }}
        pageSize={+paramLimit}
        current={page}
        total={totalData}
        onChange={(page) => {
          setPage(page)
          const params = queryString.parse(props.location.search);
          let limit = params.limit ? '&limit='+params.limit : '&limit='+10;
          props.history.push(`/my-tyneri?page=${page}` + limit)
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

export default withRouter(connect(mapStateToProps, null)(MyTyneri));
