import React from "react";
import { Col, Row } from "antd";
import { CardTopNew } from "../..";
import DefaultBackground from "../../../assets/media/images/generic-activities-img.jpg";

const ListTyneri = (props) => {
    if(props.list && !! props.list.length)
        return (
            <div className="list-tinerary" >
                <Row gutter={16} id="top-new" type="flex" className="list-data-item">
                    { props.list.map((item, key) => (
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24}>

                            <a key={key}
                               href={process.env.PUBLIC_URL+"/view-itinerary/" + item._id}>
                                <CardTopNew
                                    key={key}
                                    isHistory={ props.isHistory}
                                    country={item.country.nativeName}
                                    city={item.city}
                                    name={item.name}
                                    estimated_budget={item.estimated_budget}
                                    a_number_of_paxs={item.a_number_of_paxs}
                                    created_at={item.created_at}
                                    created_by={item.user_infor}
                                    currency_infor={item.currency_infor}
                                    cover={
                                        item.cover ? item.cover : DefaultBackground
                                    }
                                />
                            </a>
                        </Col>
                    ))}
                </Row>
            </div>);
    return null;
}

export default ListTyneri;
