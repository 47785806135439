import { INITIAL_TAB } from '../../../constants/actions/tab';
import Tabs from '../../../model/utility/Tabs';

const initialState = {
  tabs: new Tabs({ list: [] }),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_TAB:
      return {
        ...state,
        tabs: new Tabs({
          list: action.payload,
        }),
      };

    default:
      return state;
  }
};
