import React from "react";
import Slider from "react-slick";
import "./index.scss";
import { isBrowser, isTablet, isMobileOnly, isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { handleSlickGoTo } from "../../store/actions";

const mapDispatchToProps = (dispatch) => {
  return {
    handleSlickGoTo: (index) => {
      dispatch(handleSlickGoTo(index));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    goToIndex: state.slickGoToReducer,
  };
};

class SliderCustom extends React.Component {
  getSlidesToShow = () => {
    if (isBrowser) {
      return this.props.slidesToShow ? this.props.slidesToShow : 3;
    } else if (isMobile) return 1;
    else if (isTablet) return 2;
  };

  getInitialSlide = () => {
    if (this.props.initialSlide >= 0)
      this.props.handleSlickGoTo(this.props.initialSlide);
      return this.props.initialSlide
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.goToIndex !== prevProps.goToIndex) {
      this.slider.slickGoTo(this.props.goToIndex);
    }
  }

  render() {
    const defaultSettings = {
      dots: false,
      slidesToShow: this.getSlidesToShow(),
      slidesToScroll: 1,
      infinite: false,
      initialSlide: this.props.initialSlide >= 0 ? this.getInitialSlide() : 0,
      rows: this.props.rows > 1 ? this.props.rows : 1,
      slidesPerRow: this.props.slidesPerRow > 1 ? this.props.slidesPerRow : 1,
      arrows: !(isMobileOnly)
    };
    const settings = {...defaultSettings, ...(this.props.settings || {})};
    if (isMobileOnly)
      settings.slidesToShow = parseFloat(settings.slidesToShow+0.1);
    return (
      <Slider
        ref={(slider) => (this.slider = slider)}
        {...settings}
        className="slider-custom"
      >
        {this.props.list}
      </Slider>
    );
  }
}

export default  connect(mapStateToProps, mapDispatchToProps)(SliderCustom);
