import React from 'react';
import {Form} from 'antd';
import {PlacesInExplore, SearchPlacesNearBy} from "../../components";
import {FormGroup} from "reactstrap";
import {connect} from "react-redux";
import _ from "lodash";
import styles from "../../components/content-detail/index.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import {openFormAddNewStop} from "../../store/actions";
import AddNewStopForm from "../../components/add-new-stop-form";
import PlacesInShared from "../../components/places-in-shared";
import Swal from "sweetalert2/dist/sweetalert2.js";

var loading = [1, 1];

const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
});

class AddStop extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            categories: []
        };
    }

    open = (e) =>{
        e.preventDefault();
        this.props.openFormAddNewStop();
    };

    beforeNewList = (type) => {
        this.props.newList(type, true);
    };

    updateListMyPlaces = (type, placeDetail) => {
        if (type === 1) {
            let temp = [...this.state.selected];
            temp.push(placeDetail);
            this.setState(
                {
                    selected: temp,
                },
                () => {
                    Toast.fire({
                        type: "success",
                        title: "Save to favorite places successfully",
                    });
                }
            );
        } else if (type === 2) {
            let temp = [...this.state.selected];
            let index = temp.findIndex((item) => {
                return item._id === placeDetail;
            });
            temp.splice(index, 1);
            this.setState(
                {
                    selected: temp,
                },
                () => {
                    Toast.fire({
                        type: "success",
                        title: "Remove place in favorite places successfully",
                    });
                }
            );
        }
    };

    render() {
        return (
            <Form
                id="form-search"
                // onSubmit={this.onSubmit.bind(this)}
            >
                <AddNewStopForm
                    params={this.props.params}
                    newList={this.beforeNewList}
                    length={this.props.item.place_detail.length}
                    items={this.props.item.place_detail}
                />
                <FormGroup>
                    <SearchPlacesNearBy
                        params={this.props.params}
                        itinerary={this.props.itinerary}
                    />
                </FormGroup>
                {this.props.isLoadingExplore &&
                loading.map((item, key) => (
                    <div className={styles.loading} key={key}>
                        <div className="sidebar-idea-container ember-view draggable-item zoom-place">
                            <div className="ember-view component-idea-tile idea-tile-horizontal _can-view-details">
                                <Skeleton
                                    variant="rect"
                                    width={80}
                                    height={85}
                                />
                                <ul className="_c-info">
                                    <React.Fragment>
                                        <Skeleton height={6} />
                                        <Skeleton height={6} width="80%" />
                                    </React.Fragment>
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
                {!_.isEmpty(this.props.listLoad) ? this.props.listLoad.map((item, index) => {
                    return <PlacesInShared
                        key={index}
                        params={this.props.params}
                        item={item}
                        newList={this.props.newList}
                        updateListMyPlaces={
                            this.updateListMyPlaces
                        }
                        categories={this.props.categories}
                    />;
                }) : (!_.isEmpty(this.props.selected2) && this.props.selected2.list.map((item, index) => (
                    <PlacesInExplore
                        key={index}
                        status={this.props.selected2.status}
                        params={this.props.params}
                        item={item}
                        newList={this.props.newList}
                        categories={this.props.categories}
                    />
                )))}
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selected2: state.listPlacesExploreReducer,
        isLoadingExplore: state.loadingReducer,
        listLoad: state.listLoadReducer.list
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openFormAddNewStop: () => {
            dispatch(openFormAddNewStop());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddStop);