import React, { Component } from 'react';
import { ContentSignIn } from '../../components';
import ApiService from '../../services/api.service';
import { connect } from 'react-redux';
import {
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout,
} from '../../store/actions';

const mapDispatchToProps = (dispatch) => {
  return {
    saveCurrentItinerary: (itinerary) => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

class SignUp extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {};
  }

  componentDidMount = async () => {};

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    return <ContentSignIn />;
  }
}

export default connect(null, mapDispatchToProps)(SignUp);
