import React, { useState } from "react";
import { Form, Input, Typography, Button, notification, Alert } from "antd";
import ApiService from "../../services/userApi.service";
import "./index.scss";

const { Title } = Typography;

function ForgetPassword(props) {
  const form = props.form;
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false);
  const [ emailValue, setEmailValue ] = useState('');
  const apiService = ApiService();

  function submitForgetPassword(e) {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, values) => {
      setLoading(true);
      if (!err) {
        const response = await apiService.checkEmailResetPassword(
          values["email"]
        );
        if (!response.status === "success") return;
        if (response.existEmail) {
          setSendEmailSuccess(true);
          setEmailValue(values["email"]);
        } else {
          notification.error({
            message: `Error`,
            description: response.message,
            placement: "topRight",
            duration: 4000,
          });
        }
      }
      setLoading(false);
    });
  }

  return (
    <Form
      size="large"
      className="form-forget-password"
      onSubmit={(e) => {
        submitForgetPassword(e);
      }}
    >
      {!sendEmailSuccess ? (
        <>
          <Form.Item>
            <div className="label">
              <Title level={3}>Reset Your Password</Title>
              <span>
                Please enter your email address below to receive a link to reset
                your password
              </span>
            </div>
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: "Please input your email",
                },
                {
                  type: "email",
                  message: "This is not validate email!",
                },
              ],
            })(<Input placeholder="E-mail address" />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Send Reset Link
            </Button>
          </Form.Item>
        </>
      ) : (
        <div className="success-alert" >
          <Alert
            message="Success"
            description={
              <span>
                Thanks! If there's an account associated with {" "}
                <span className="email">{ emailValue }</span>, we'll send the password
                reset instructions immediately
              </span>
            }
            type="success"
          />
        </div>
      )}
    </Form>
  );
}

export default Form.create()(ForgetPassword);
