import React, { useState, useEffect } from "react";
import { Modal, Descriptions, Checkbox, Button } from "antd";
import moment from "moment";
import "./index.scss";


function InquiriesDetail (props) {
  const contactsApi = props.contactsApi;
  const item = props.item;
  const status = props.status;
  const [ activeClose, setActiveClose] = useState(true);
  const [ contactExist, setContactExist ] = useState(false);
  const LabelAndText = (label, text) => (
    <Descriptions.Item label={label}>
      <strong>{text}</strong>
    </Descriptions.Item>
  );

  useEffect(() => {
    if(item && props.open && item.status !== status.close) {
      props.updateStatusInquiries(item.key, status.seen);

    }

    if(item){
      contactsApi.checkContactExist(item.email).then(
        res => {
          if(!res.error) setContactExist(res.exist);
        }
      )
    }

  }, [props.item, props.open, props.item.status]);

  if (!props) return null;
  return (
    <Modal
      className="modal-inquiries-detail"
      title={ item.title }
      footer=
      {
        !contactExist ||  item.status !== status.close ?
          (<div className="footer-container">
          { item.status !== status.close ?
            <>
              <Checkbox defaultChecked={ activeClose }
                        onChange={ () => setActiveClose(!activeClose) }>
                Answered
              </Checkbox>
              <Button disabled={  !activeClose } size="large"
                      className="update-status-button" type="primary"
                      onClick={ () => {
                        props.updateStatusInquiries(item.key, props.status.close)
                        props.closeDetail()
                        setActiveClose(true)
                      }}>
                Save
              </Button>
            </>: null
          }
          { !contactExist ?
            <Button size="large" type="warning"
                    className="contact-list-btn"
                    onClick={ () =>  props.saveContact(item) }>
              Save to contact list...
            </Button> : null
          }
        </div>) :
        null
      }
      visible={props.open}
      onCancel={props.closeDetail}
      width="650px">
      {/* {item.itinerary ? (
        <Descriptions className="tyneri-info" column={1}>
          {LabelAndText(
            <span className="title">Tyneri Info</span>,
            <strong>{item.itinerary.name}</strong>
          )}
        </Descriptions>
      ) : null} */}
      <Descriptions className="request-info" title="" column={1}>
        {LabelAndText(
          "Date",
          <strong>
            {moment(new Date(item.created_at)).format("MMMM D h:mm:ss a")}
          </strong>
        )}
        {LabelAndText("Name", <strong>{item.firstName +" "+ (item.lastName ? item.lastName : '')}</strong>)}
        {LabelAndText("Email", <strong> <a href={"mailto:"+ item.email}>{item.email}</a> </strong>)}
        {LabelAndText("Phone", <strong>{item.phone}</strong>)}
      </Descriptions>
      <div className="information-request">
        <div className="ant-descriptions-title">Request Info</div>
        {
          item.title ?
          <div  className="item">
            <h5><strong>Title</strong></h5>
            <span>{ item.title } </span>
          </div>
        : null
        }
        { item.date ?
          <div  className="item">
            <h5><strong>Dates you would like to travel, and for how long?</strong></h5>
            <span>{ item.date } </span>
          </div>
        : null }
        {
          item.information ?
            <div  className="item">
              <h5> <strong>Your comments</strong></h5>
              <span>{ item.information } </span>
            </div>
          : null
        }
      </div>
    </Modal>
  );
};

export default InquiriesDetail;
