import React, { Component } from "react";
import ApiService from "../../services/api.service";
import { connect } from "react-redux";
import {
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout,
  updateInforCurrency,
} from "../../store/actions";
import { withRouter } from "react-router";
import { Row, Col, Typography, Button, Breadcrumb } from "antd";
import { configBaseUrl, config } from "../../config";
import ImageHelper from "../../functions/image";
import { format } from "date-fns";
import styles from "./index.scss";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import TypographyMaterialUi from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ErrorIcon from "@material-ui/icons/Error";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import SendIcon from "@material-ui/icons/Send";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ButtonMaterialUi from "@material-ui/core/Button";
import { formatViews } from "../../services/utils.service";

const { Title, Paragraph, Text } = Typography;
const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInforCurrency: (currency) => {
      dispatch(updateInforCurrency(currency));
    },
    saveCurrentItinerary: (itinerary) => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

const Author = (props) => {
  const { company, user, top_list } = props;
  return (
    <div className="company-request-section">
      {company ? (
        company.url ? (
          <a
            href={config.http + "//" + company.url + "." + config.domain}
            target="blank"
          >
            <div className="company-section">
              <ImageHelper
                src={
                  company.name
                    ? configBaseUrl.baseURL + company.logo
                    : configBaseUrl.baseURL + user.avatar
                }
                noShowDeImage={false}
                className="company-logo"
              />
              <div className="company-infor">
                <span className="company-infor__name">
                  {company.name
                    ? company.name
                      ? company.name
                      : company.short_name
                      ? company.short_name
                      : null
                    : `${user.firstName} ${user.lastName}`}
                </span>
                {user.job ? <Text className="type-user" type="secondary">@{user.job.name}</Text> : null}
                <div className="company-infor__summary">
                  <span>
                    {format(new Date(top_list.updated_at), "MMM d, yyyy")}
                  </span>
                  <span>
                    {`${top_list.list_places.length} ${
                      top_list.list_places.length > 1 ? "items" : "item"
                    }`}
                  </span>
                  <span>
                    {`${formatViews(top_list.views, 1)}  ${
                      top_list.views > 1 ? "views" : "view"
                    }`}
                  </span>
                </div>
              </div>
            </div>
          </a>
        ) : (
          <div className="company-section">
            <ImageHelper
              src={
                company.name
                  ? configBaseUrl.baseURL + company.logo
                  : configBaseUrl.baseURL + user.avatar
              }
              noShowDeImage={false}
              className="company-logo"
            />
            <div className="company-infor">
              <span className="company-infor__name">
                {company.name
                  ? company.name
                    ? company.name
                    : company.short_name
                    ? company.short_name
                    : null
                  : `${user.firstName} ${user.lastName}`}
              </span>
              {user.job ? <Text className="type-user" type="secondary">@{user.job.name}</Text> : null}
              <div className="company-infor__summary">
                <span>
                  {format(new Date(top_list.updated_at), "MMM d, yyyy")}
                </span>
                <span>
                  {`${top_list.list_places.length} ${
                    top_list.list_places.length > 1 ? "items" : "item"
                  }`}
                </span>
                <span>
                    {`${formatViews(top_list.views, 1)}  ${
                      top_list.views > 1 ? "views" : "view"
                    }`}
                  </span>
              </div>
            </div>
          </div>
        )
      ) : null}
    </div>
  );
};

class HeaderTopList extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      showForm: false,

      name: "",
      receiver: "",
      isLoadingShare: false,
      messages: "",
      linkShare: "",
    };
  }

  handleShowForm = () => {
    this.setState({
      showForm: true,
    });
  };

  handleCloseForm = () => {
    this.setState({
      showForm: false,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  shareListByEmail = () => {
    this.setState({
      isLoadingShare: true,
    });
    this.apiService
      .shareListByEmail(
        this.state.name,
        this.state.receiver,
        this.state.messages,
        this.props.match.params.idTopList
      )
      .then((res) => {
        if (res.status === "success") {
          Swal.fire({
            type: "success",
            title: "Share successfully",
            showConfirmButton: true,
          });
          this.setState({
            showForm: false,
            isLoadingShare: false,
            name: "",
            receiver: "",
            messages: "",
          });
        }
      });
  };

  render() {
    const { company, user } = this.props.top_list;
    return (
      <>
        <Dialog
          open={this.state.showForm ? true : false}
          aria-labelledby="form-share-by-email"
          className={styles.viewItinerary}
          maxWidth={"sm"}
          fullWidth={true}
        >
          <MuiDialogTitle className="popup-title" disableTypography>
            <TypographyMaterialUi variant="h6">
              Share list by email
            </TypographyMaterialUi>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={this.handleCloseForm.bind(this)}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <ValidatorForm onSubmit={this.shareListByEmail}>
            <DialogContent>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <TextValidator
                    label="Your name *"
                    margin="none"
                    type="text"
                    fullWidth
                    autoFocus={true}
                    onChange={this.handleChange("name")}
                    value={this.state.name}
                    validators={["required"]}
                    errorMessages={[
                      <span>
                        <ErrorIcon />
                        This field is required
                      </span>,
                    ]}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <TextValidator
                    ref={this.emailRef}
                    label="To email*"
                    fullWidth
                    onChange={this.handleChange("receiver")}
                    value={this.state.receiver}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      <span>
                        <ErrorIcon />
                        This field is required
                      </span>,
                      <span>
                        <ErrorIcon />
                        Email is not valid
                      </span>,
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    label="Messages"
                    multiline
                    rowsMax="4"
                    rows="4"
                    onChange={this.handleChange("messages")}
                    value={this.state.messages}
                    margin="none"
                    fullWidth
                  />
                </Col>
              </Row>
            </DialogContent>
            <DialogActions className={styles.headerTopList}>
              <div className="section-btn-progress">
                <ButtonMaterialUi
                  disabled={this.state.isLoadingShare ? true : false}
                  id="send-button"
                  type="submit"
                  className="btn-primary"
                >
                  <SendIcon />
                  Send
                </ButtonMaterialUi>
                {this.state.isLoadingShare && (
                  <CircularProgress size={24} className="btn-progress" />
                )}
              </div>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
        <div className={styles.headerTopList}>
          <div className="grid-header bread">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/top-lists">Top lists</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row gutter={16}>
            <Col className="head-title" xs={24} lg={12}>
              {this.props.top_list.title ? (
                <div className="category-share">
                  <div className="tag-category">
                    {this.props.top_list.category.name}
                  </div>
                  <div className="share-section">
                    <div
                      className="share-by-email"
                      onClick={this.handleShowForm.bind(this)}
                    >
                      <MailOutlineIcon />
                    </div>

                    <FacebookShareButton
                      url={`https://tyneri.com${this.props.location.pathname}`}
                      className="Demo__some-network__share-button"
                    >
                      <FaFacebook className="icon icon-facebook" />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={`https://tyneri.com${this.props.location.pathname}`}
                      className="Demo__some-network__share-button"
                    >
                      <FaTwitter className="icon icon-twitter" />
                    </TwitterShareButton>
                  </div>
                </div>
              ) : null}

              <Title>
                {this.props.top_list.title ? this.props.top_list.title : null}
                {this.props.profile &&
                this.props.profile._id === this.props.top_list.user._id ? (
                  <Button
                    className="btn-request"
                    type="primary"
                    size="small"
                    style={{ height: 22, verticalAlign: `middle`, marginLeft: 10 }}
                    onClick={() =>
                      this.props.history.push(
                        "/edit-top-list/" + this.props.top_list._id
                      )
                    }
                  >
                    Edit List
                  </Button>
                ) : null}
              </Title>
              {isMobileOnly && this.props.top_list.cover ? (
                <img
                  className="img-fluid"
                  src={configBaseUrl.baseURL + this.props.top_list.cover}
                  alt="cover"
                />
              ) : null}
              <Author
                {...this.props}
                company={company}
                user={user}
                top_list={this.props.top_list}
              />
            </Col>
            {!isMobileOnly && (
              <Col xs={24} lg={12}>
                {this.props.top_list.cover ? (
                  <img
                    className="img-fluid"
                    src={configBaseUrl.baseURL + this.props.top_list.cover}
                    alt="cover"
                  />
                ) : null}
              </Col>
            )}
          </Row>
          <Row className="row-description">
            <Paragraph>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.top_list.description
                    ? this.props.top_list.description
                    : null,
                }}
              />
            </Paragraph>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderTopList)
);
