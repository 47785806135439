import React, { Component } from "react";
import "./index.scss";
import { withRouter } from "react-router";
import DayView from "../day-view";
import MapView from "../map-view";
import ButtonMaterial from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

class ViewOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewOption: 1,
    };
  }

  handleChangeViewOption = (viewOption) => {
    this.setState({ viewOption });
  };

  handleExpandCollapse = () => {
    this.props.handleExpandCollapse(!this.props.expand)
  };

  render() {
    return (
      <div className="d-flex flex-column justify-content-center">
        {this.state.viewOption === 1 ? (
          <DayView listDates={this.props.listDates} />
        ) : (
          <MapView items={this.props.items} />
        )}
        <div className="section-option-view">
          <div className="section-option-view-left">
            <div
              onClick={this.handleChangeViewOption.bind(this, 1)}
              className={
                this.state.viewOption === 1
                  ? "each-option-view active"
                  : "each-option-view"
              }
            >
              Day view
            </div>
            <div
              onClick={this.handleChangeViewOption.bind(this, 2)}
              className={
                this.state.viewOption === 2
                  ? "each-option-view active"
                  : "each-option-view"
              }
            >
              Map view
            </div>
          </div>
          {!!this.props.items.length && (
            <div
              style={{
                textAlign: `end`,
              }}
            >
              <ButtonMaterial
                style={{ width: "109.13px" }}
                className="btn-expand-collapse"
                onClick={this.handleExpandCollapse.bind(this)}
              >
                {this.props.expand ? (
                  <React.Fragment>
                    <IndeterminateCheckBoxIcon />
                    COLLAPSE
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <AddBoxIcon />
                    EXPAND
                  </React.Fragment>
                )}
              </ButtonMaterial>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(ViewOption);
