import React, { useEffect } from "react";
import { Form, Radio, Checkbox, Input } from "antd";
import { useFieldArray } from "react-hook-form";
import { AlertOutlined } from "@ant-design/icons";

const MealOptions = [
  {
    name: "Breakfast",
    budget: 0,
    status: false,
  },
  {
    name: "Lunch",
    budget: 0,
    status: false,
  },
  {
    name: "Dinner",
    budget: 0,
    status: false,
  },
];
export default ({
  nestIndex,
  control,
  register,
  item,
  layout,
  Controller,
  dayData,
  currency,
}) => {

  const { fields,  append, remove } = useFieldArray({
    control,
    name: `days[${nestIndex}].meal`,
  });

  useEffect(() => {
    if (fields.length === 1) {
      remove(0);
    }
  }, [fields])


  return (
    <div className={"body "}>
      <div className="detail">
        <Form.Item
          label={
            <strong>
              <AlertOutlined /> Meals
            </strong>
          }
          {...layout}
        >
          <Controller
            control={control}
            name={`days[${nestIndex}].mealCheck`}
            defaultValue={dayData.mealCheck ? true : false}
            render={({ value, onChange }) => {
              return (
                <Radio.Group
                  defaultValue={dayData.mealCheck}
                  onChange={(value) => {
                    if (value.target.value) {
                      append(MealOptions);
                    } else {
                        remove(0);
                        remove(1);
                        remove(2);
                    }
                    onChange(value.target.value);
                  }}
                >
                  <Radio value={true}>Included</Radio>
                  <Radio value={false}>Not included</Radio>
                </Radio.Group>
              );
            }}
          />
        </Form.Item>
      </div>
      {fields.map((item, k) => {
        return (
          <div className="meal-detail" key={item.id}>
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-col-7 ant-form-item-label">
                <Controller
                  control={control}
                  name={`days[${nestIndex}].meal[${k}].status`}
                  defaultValue={item.status }
                  render={({ value, onChange }) => {
                    return (
                      <Checkbox
                        onChange={(value) => onChange(value.target.checked)}
                        defaultChecked={item.status}
                      >
                        {item.name}
                      </Checkbox>
                    );
                  }}
                />
              </div>
              <div className="ant-col ant-col-10 ant-form-item-control-wrapper">
                <Controller
                  control={control}
                  name={`days[${nestIndex}].meal[${k}].budget`}
                  defaultValue={item.budget}
                  render={({ value, onChange }) => (
                    <Input
                    type="number"
                      placeholder="budget"
                      defaultValue={item.budget}
                      onChange={onChange}
                      addonBefore={currency}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`days[${nestIndex}].meal[${k}].name`}
                  defaultValue={item.name}
                  render={({ value, onChange }) => (
                    <Input
                       type="hidden"
                       defaultValue={item.name}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
