import React, { Component } from "react";
import { Row, Col, Container, Badge } from "reactstrap";
import {
  ItemView,
  TyneriRelated,
  CreateItineraryForm,
  ItineraryDetails,
  ItineraryDetailOption,
  FormReportPlace,
} from "../../components";
import { Timeline } from "react-event-timeline";
import ApiService from "../../services/api.service";
import styles from "./index.scss";
import { connect } from "react-redux";
import { MdLocationOn, MdToday } from "react-icons/md";
import { FaMoneyBill } from "react-icons/fa";
import {
  formatMoneyByCurrency,
  getDateNow,
  getAllLeaf,
  isMyResource,
  getNameItinerary,
  getFlag,
} from "../../services/utils.service";
import logo from "../../assets/media/images/tyneri-logo.png";
import { saveItem, loadItem } from "../../services/storage.service";
import ScrollspyNav from "react-scrollspy-nav";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { FacebookShareButton, TwitterShareButton } from "react-share";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ErrorIcon from "@material-ui/icons/Error";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { config } from "../../config";
import { Breadcrumb, Avatar, Anchor } from "antd";
import { Link } from "react-router-dom";
import {
  saveCurrentItinerary,
  updateTotalAmount,
  saveProfile,
  logout,
  updateInforCurrency,
} from "../../store/actions";
import { withRouter } from "react-router";
import {
  isTablet,
  isMobileOnly,
  MobileView,
} from "react-device-detect";
import { configBaseUrl } from "../../config";

import Inquiries from "../../components/inquiries";
import Swal from "sweetalert2/dist/sweetalert2.js";
import formatMoney from "accounting-js/lib/formatMoney.js";
import { AccountType } from "../../params/AccountType";
import UserApiService from "../../services/userApi.service";
import MenuMobile from "./menu-mobile";
import LodashHelper from "../../helper/lodashHelper";
import RecentService from "../../services/recent.service";

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInforCurrency: (currency) => {
      dispatch(updateInforCurrency(currency));
    },
    saveCurrentItinerary: (itinerary) => {
      dispatch(saveCurrentItinerary(itinerary));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

class ViewItinerary extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.userApiService = UserApiService();
    this.state = {
      itinerary: "",
      proAccount: false,
      countItineraries: 0,
      countLists: 0,
      isLoading: true,
      isPdf: true,
      channel: {},
      listItem: [],

      name: "",
      receiver: "",
      isLoadingShare: false,
      messages: "",
      linkShare: "",

      listRecommend: [],
      list_optionals: [],

      showCreateItineraryForm: false,
      listAllDates: [],
      currency_infor: "",
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    if (this.props.location) {
      this.setState({
        isPdf: false,
      });

      var idItinerary = this.props.match.params.idItinerary;
      await this.apiService.getItinerary(idItinerary).then((res) => {
        if (this._isMounted && res.status === "success") {
          let itineraries = [];
          if (!loadItem("itineraries")) {
            itineraries.push({ ...res.itinerary });
            saveItem("itineraries", itineraries);
          }

          itineraries = loadItem("itineraries");
          let index = itineraries.findIndex((item) => {
            return item._id === res.itinerary._id;
          });
          if (index < 0) {
            itineraries.push({ ...res.itinerary });
            saveItem("itineraries", itineraries);
          }

          this.apiService
            .getAllItinerariesOfCountry(
              res.itinerary.alpha2Code,
              res.itinerary._id
            )
            .then((result1) => {
              if (result1.status === "success") {
                this.setState({
                  listRecommend: result1.list_itineraries,
                });
              }
            });

          this.apiService.getAllOptionals().then((res) => {
            if (res.status === "success") {
              this.setState({
                list_optionals: res.list_optionals,
              });
            }
          });

          this.userApiService
            .checkIsProAccountByUserId(res.itinerary.belong_to_user)
            .then((response) => {
              if (response.proAccount) {
                this.setState({
                  proAccount: true,
                  countItineraries: response.countItineraries,
                  countLists: response.countLists,
                });
              } else {
                this.setState({
                  proAccount: false,
                  countItineraries: response.countItineraries,
                  countLists: response.countLists,
                });
              }
            });

          let listAllDates = getAllLeaf(res.itinerary.itinerary_detail);

          this.setState(
            {
              itinerary: res.itinerary,
              listAllDates: listAllDates,
            },
            () => {
              this.apiService
                .getDetailCurrency(res.itinerary.currency)
                .then((result) => {
                  if (result.status === "success") {
                    this.setState(
                      {
                        currency_infor: result.currency,
                      },
                      () => {
                        this.props.updateInforCurrency(result.currency);
                        document.title = `Tyneri | ${
                          this.state.itinerary.name
                        } ${
                          this.state.itinerary.estimated_budget
                            ? `on a ${formatMoney(
                                this.state.itinerary.estimated_budget,
                                {
                                  symbol: this.state.currency_infor.symbol,
                                  precision: this.state.currency_infor
                                    .decimal_digits,
                                  thousand: ",",
                                  decimal: ".",
                                }
                              )}${" "}
                        ${this.state.currency_infor.code} budget`
                            : ``
                        }
                  ${
                    this.state.itinerary.a_number_of_paxs
                      ? ` for ${this.state.itinerary.a_number_of_paxs} pax`
                      : ""
                  }`;
                        this.setState({
                          isLoading: false,
                        });
                      }
                    );
                  }
                });

              let tempList = [];
              getAllLeaf(res.itinerary.itinerary_detail).forEach((item) => {
                tempList.push(`${item._id}-1`);
              });
              this.setState(
                {
                  listItem: tempList,
                },
                () => {
                  // var metypeContainer = document.getElementById(
                  //     "metype-container"
                  //   ),
                  //   page_url = metypeContainer.getAttribute(
                  //     "data-metype-page-url"
                  //   );
                  // //You can change the page url incase of infinite scroll to render different widgets.
                  // metypeContainer.setAttribute(
                  //   "data-metype-page-url",
                  //   page_url || window.location.href
                  // );
                  // // The rest of the commands are for metype to render the widget as an iframe
                  // metypeContainer.setAttribute(
                  //   "data-metype-window-height",
                  //   window.innerHeight
                  // );
                  // metypeContainer.setAttribute(
                  //   "data-metype-screen-width",
                  //   window.screen.width
                  // );
                  // var talktype = window.talktype;
                  // talktype(function () {
                  //   talktype.commentWidgetIframe(metypeContainer);
                  // });
                }
              );
            }
          );
        } else {
          this.props.history.push("/404");
        }
      });
    } else if (this.props.params) {
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      this.setState({
        itinerary: itineraries[current],
      });
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleRedirectToHome = () => {
    this.props.history.push("/");
  };

  shareItineraryByEmail = () => {
    this.setState({
      isLoadingShare: true,
    });
    this.apiService
      .shareItineraryByEmail(
        this.state.name,
        this.state.receiver,
        this.state.messages,
        this.props.match.params.idItinerary
      )
      .then((res) => {
        if (res.status === "success") {
          Swal.fire({
            type: "success",
            title: "Share successfully",
            showConfirmButton: true,
          });
          this.setState({
            showForm: false,
            isLoadingShare: false,
            name: "",
            receiver: "",
            messages: "",
          });
        }
      });
  };

  handleShowForm = () => {
    this.setState({
      showForm: true,
    });
  };

  handleCloseForm = () => {
    this.setState({
      showForm: false,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCustomize = () => {
    if (loadItem("itineraries")) {
      let itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.state.itinerary._id;
      });
      if (isMobileOnly)
        this.props.history.push(`/itinerary/${this.state.itinerary._id}`);
      else
        this.props.history.push(
          `/itinerary/${this.state.itinerary._id}/${
            getAllLeaf(itineraries[current].itinerary_detail)[0]._id
          }`
        );
    }
  };

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  closeCreateItineraryForm = () => {
    this.setState({
      showCreateItineraryForm: false,
    });
  };

  showFormCreateItinerary = () => {
    this.setState({
      showCreateItineraryForm: true,
    });
  };

  renderScroll = () => {
    if (isTablet || isMobileOnly) {
      return (
        this.state.itinerary && (
          <Anchor>
            <h2 className="main-title-section">Itinerary</h2>
            <ItineraryDetails preview={true} params={this.props.match.params} />
          </Anchor>
        )
      );
    }
    return (
      this.state.itinerary && (
        <ScrollspyNav
          scrollTargetIds={this.state.listItem}
          activeNavClass="_active"
          scrollDuration="1000"
          offset={-50}
        >
          <h2 className="main-title-section">Itinerary</h2>

          <ItineraryDetails preview={true} params={this.props.match.params} />
        </ScrollspyNav>
      )
    );
  };

  renderListPlaces = (item) => {
    var rows = [];
    var countIndex = 0;
    item.place_detail.map((place, index) => {
      if (place.category !== "Infor") {
        countIndex++;
        rows.push(
          <ItemView
            isPdf={this.state.isPdf}
            preview={true}
            key={index}
            index={countIndex}
            items={item.place_detail}
            item={place}
            currency={this.state.itinerary.currency}
            exchangeRate={this.state.exchangeRate}
            length={item.place_detail.length}
          />
        );
      } else {
        rows.push(
          <ItemView
            isPdf={this.state.isPdf}
            preview={true}
            key={index}
            index={countIndex}
            items={item.place_detail}
            item={place}
            currency={this.state.itinerary.currency}
            exchangeRate={this.state.exchangeRate}
            length={item.place_detail.length}
          />
        );
      }
    });
    return rows;
  };

  render() {
    const { proAccount } = this.state;
    const title = `${this.state.itinerary.name}${" "}
    ${
      this.state.itinerary.estimated_budget
        ? `on a ${formatMoney(this.state.itinerary.estimated_budget, {
            symbol: this.state.currency_infor.symbol,
            precision: this.state.currency_infor.decimal_digits,
            thousand: ",",
            decimal: ".",
          })}${" "}
          ${this.state.currency_infor.code} budget`
        : ``
    }
    ${
      this.state.itinerary.a_number_of_paxs
        ? ` for ${this.state.itinerary.a_number_of_paxs} pax`
        : ""
    }`;

    return this.state.isPdf ? (
      <div className={styles.viewItineraryPdf}>
        <Row className="wrapper-content">
          <Col
            sm={{ size: "8", offset: 2 }}
            md={{ size: "8", offset: 2 }}
            lg={{ size: "5", offset: 4 }}
            xl={{ size: "5", offset: 4 }}
            className="wrapper main"
          >
            <img style={{ height: "90px" }} src={logo} alt="logo" />
            <div className="clearfix">
              <h5 className="float-left name">{this.state.itinerary.name}</h5>
              {this.state.itinerary.itinerary_detail && (
                <h5 className="float-right total-day">
                  <MdToday />
                  &nbsp;{this.state.itinerary.itinerary_detail.length}{" "}
                  {this.state.itinerary.itinerary_detail.length > 1
                    ? "days"
                    : "day"}
                </h5>
              )}
            </div>
            <div className="clearfix">
              <h5 className="float-left destination">
                <MdLocationOn />
                &nbsp;{this.state.itinerary.destination}
              </h5>
              <h5 className="float-right total-amount">
                <FaMoneyBill />
                &nbsp;
                {formatMoneyByCurrency(
                  this.state.itinerary.total_amount * this.state.exchangeRate,
                  this.state.itinerary.currency
                )}
              </h5>
            </div>
            {this.state.itinerary &&
              this.state.itinerary.itinerary_detail.length &&
              this.state.itinerary.itinerary_detail.map((item, key) => (
                <div key={key}>
                  <h2 className="day-title">
                    <Badge
                      style={{
                        padding: `0.3rem`,
                        fontSize: `80%`,
                        marginRight: "0.5rem",
                        color: `#fff`,
                        backgroundColor: `#0882e6`,
                      }}
                      color="primary"
                    >
                      Day {key + 1}
                    </Badge>
                    <span>{item.title}</span>
                  </h2>
                  {/* {!!item.place_detail.length && (
                    <Timeline lineColor={`#0099FF`} className="timeline-event">
                      {item.place_detail.map((place, index) => (
                        <ItemView
                          isPdf={this.state.isPdf}
                          key={index}
                          index={index}
                          items={item.place_detail}
                          item={place}
                          currency={this.state.itinerary.currency}
                          exchangeRate={this.state.exchangeRate}
                          length={item.place_detail.length}
                        />
                      ))}
                    </Timeline>
                  )} */}
                </div>
              ))}
          </Col>
        </Row>
      </div>
    ) : this.state.isLoading ? (
      <div className={styles.pageLoader}></div>
    ) : (
      <div className={styles.viewItinerary}>
        <FormReportPlace />
        <Dialog
          open={this.state.showCreateItineraryForm ? true : false}
          fullWidth={true}
          maxWidth={"sm"}
          aria-labelledby="create-itinerary-form"
          className={styles.contentTrendy}
        >
          <MuiDialogTitle disableTypography className="popup-title">
            <Typography variant="h6">Build Your Own Itinerary</Typography>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={this.closeCreateItineraryForm.bind(this)}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent style={{ padding: `0 15px` }}>
            <CreateItineraryForm />
          </DialogContent>
        </Dialog>

        <div
          className={
            "wrapper-content " +
            (isMobileOnly || isTablet ? "tablet mobile" : null)
          }
        >
          <Container>
            <Row>
              <Col xs="12" sm={4} md={4} lg="3" className="left-bar">
                {!!this.state.listItem.length && (
                  <React.Fragment>
                    <div className="hide-on-mobile">
                      <h2 className="main-title-section">Overview</h2>
                      <ul className="item-in-overview">
                        <li>
                          People: {this.state.itinerary.a_number_of_paxs} pax
                        </li>
                        <li>
                          Duration:{" "}
                          {getAllLeaf(this.state.itinerary.itinerary_detail)
                            .length > 1
                            ? `${
                                getAllLeaf(
                                  this.state.itinerary.itinerary_detail
                                ).length
                              } days`
                            : `${
                                getAllLeaf(
                                  this.state.itinerary.itinerary_detail
                                ).length
                              } day`}
                        </li>
                        <li>
                          Budget:{" "}
                          {formatMoney(this.state.itinerary.estimated_budget, {
                            symbol: this.state.currency_infor.symbol,
                            precision: this.state.currency_infor.decimal_digits,
                            thousand: ",",
                            decimal: ".",
                          })}{" "}
                          {this.state.currency_infor.code}
                        </li>
                        <li>
                          Expenses:{" "}
                          {formatMoney(this.state.itinerary.total_amount, {
                            symbol: this.state.currency_infor.symbol,
                            precision: this.state.currency_infor.decimal_digits,
                            thousand: ",",
                            decimal: ".",
                          })}{" "}
                          {this.state.currency_infor.code}
                        </li>
                      </ul>
                    </div>
                    <nav
                      id="ember13"
                      className="si-day-nav _fixed ember-view hide-on-mobile"
                    >
                      {this.renderScroll()}
                    </nav>
                  </React.Fragment>
                )}
              </Col>
              <Col xs="12" lg="6" sm={8} md={8}>
                <div className="wrapper main">
                  <div className="grid-header bread">
                    <Breadcrumb separator=">">
                      <Breadcrumb.Item
                          onClick={this.handleRedirectToHome.bind(this)}
                      >
                        <Link to="/">Home</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/">itinerary</Link>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="share-section">
                      <div
                          className="share-by-email"
                          onClick={this.handleShowForm.bind(this)}
                      >
                        <MailOutlineIcon />
                      </div>

                      <FacebookShareButton
                          url={`https://tyneri.com${this.props.location.pathname}`}
                          className="Demo__some-network__share-button"
                      >
                        <FaFacebook className="icon icon-facebook" />
                      </FacebookShareButton>
                      <TwitterShareButton
                          url={`https://tyneri.com${this.props.location.pathname}`}
                          className="Demo__some-network__share-button"
                      >
                        <FaTwitter className="icon icon-twitter" />
                      </TwitterShareButton>
                    </div>
                  </div>
                  <div className="section-title-itinerary">
                    <h5 className="name">
                      {getNameItinerary({
                        ...this.state.itinerary,
                        currency_infor: this.state.currency_infor,
                      })}
                    </h5>
                  </div>
                  {this.state.itinerary.cover ? (
                      <img
                          className="img-fluid"
                          src={config.baseURL + this.state.itinerary.cover}
                          alt=""
                      />
                  ) : null}
                  <div>
                      <span className="description">
                        {this.state.itinerary.description}
                      </span>
                  </div>
                  <div className="author-infor">
                    {proAccount ? (
                        <Inquiries itinerary={this.state.itinerary} />
                    ) : (
                        <div className="reference-section">
                          {
                            <React.Fragment>
                              {this.state.itinerary.belong_to_user ? (
                                  this.state.itinerary.user_infor
                                      .accountType === AccountType.Pro &&
                                  this.state.itinerary.company.url ? (
                                      <a
                                          href={
                                            config.http +
                                            "//" +
                                            this.state.itinerary.company.url +
                                            "." +
                                            config.domain
                                          }
                                          target="blank"
                                      >
                                        <Avatar
                                            src={
                                              isMyResource(
                                                  this.state.itinerary.user_infor
                                                      .avatar
                                              )
                                                  ? configBaseUrl.baseURL +
                                                  this.state.itinerary.user_infor
                                                      .avatar
                                                  : this.state.itinerary.user_infor
                                                      .avatar
                                            }
                                        />{" "}
                                        {
                                          this.state.itinerary.user_infor
                                              .firstName
                                        }{" "}
                                        {
                                          this.state.itinerary.user_infor
                                              .lastName
                                        }
                                      </a>
                                  ) : (
                                      <>
                                        <div>
                                          <Avatar
                                              src={
                                                isMyResource(
                                                    this.state.itinerary.user_infor
                                                        .avatar
                                                )
                                                    ? configBaseUrl.baseURL +
                                                    this.state.itinerary
                                                        .user_infor.avatar
                                                    : this.state.itinerary
                                                        .user_infor.avatar
                                              }
                                          />
                                        </div>
                                      </>
                                  )
                              ) : (
                                  "Tyneri"
                              )}{" "}
                              {/* {this.state.itinerary.reference
                                    ? "edited"
                                    : "created"} */}
                            </React.Fragment>
                          }{" "}
                          {/* on{" "}
                              {format(
                                new Date(this.state.itinerary.last_updated),
                                "MMM d, yyyy"
                              )} */}
                          <div>
                                <span className="author-infor__name">
                                  {this.state.itinerary.user_infor.firstName}{" "}
                                  {this.state.itinerary.user_infor.lastName}
                                </span>
                            <div className="author-infor__summary">
                                  <span>
                                    List:{" "}
                                    <span className="author-infor__summary__detail">
                                      {this.state.countLists}
                                    </span>
                                  </span>
                              <span>
                                    Itinerary:{" "}
                                <span className="author-infor__summary__detail">
                                      {this.state.countItineraries}
                                    </span>
                                  </span>
                            </div>
                          </div>
                        </div>
                    )}
                    <Button
                        id="send-button"
                        type="submit"
                        className="btn-customize"
                        fullWidth
                        onClick={this.handleCustomize.bind(this)}
                    >
                      <i
                          className="fas fa-pencil-alt"
                          style={{
                            color: `#fff`,
                            marginRight: "5px",
                          }}
                      ></i>

                      {this.props.profile &&
                      this.props.profile._id ===
                      this.state.itinerary.belong_to_user
                          ? `Edit`
                          : `Let's customize`}
                    </Button>
                  </div>
                  <div
                      className="css-on-mobile"
                      style={{ background: "#e1eef5" }}
                  >
                    <div className="overview hide-on-desktop">
                      <div>
                        <h2 className="main-title-section">Overview</h2>
                        <ul className="item-in-overview">
                          <li>
                            People: {this.state.itinerary.a_number_of_paxs}{" "}
                            pax
                          </li>
                          <li>
                            Duration:{" "}
                            {getAllLeaf(
                                this.state.itinerary.itinerary_detail
                            ).length > 1
                                ? `${
                                    getAllLeaf(
                                        this.state.itinerary.itinerary_detail
                                    ).length
                                } days`
                                : `${
                                    getAllLeaf(
                                        this.state.itinerary.itinerary_detail
                                    ).length
                                } day`}
                          </li>
                          <li>
                            Budget:{" "}
                            {formatMoney(
                                this.state.itinerary.estimated_budget,
                                {
                                  symbol: this.state.currency_infor.symbol,
                                  precision: this.state.currency_infor
                                      .decimal_digits,
                                  thousand: ",",
                                  decimal: ".",
                                }
                            )}
                          </li>
                          <li>
                            Expenses:{" "}
                            {formatMoney(
                                this.state.itinerary.total_amount,
                                {
                                  symbol: this.state.currency_infor.symbol,
                                  precision: this.state.currency_infor
                                      .decimal_digits,
                                  thousand: ",",
                                  decimal: ".",
                                }
                            )}
                          </li>
                        </ul>
                      </div>
                      {this.state.itinerary && (
                          <div className="hide-on-mobile">
                            <ScrollspyNav
                                scrollTargetIds={this.state.listItem}
                                activeNavClass="_active"
                                scrollDuration="1000"
                                offset={-50}
                            >
                              <h2 className="main-title-section">
                                Itinerary
                              </h2>

                              <ItineraryDetails
                                  preview={true}
                                  params={this.props.match.params}
                              />
                            </ScrollspyNav>
                          </div>
                      )}
                    </div>
                  </div>

                  <Dialog
                      open={this.state.showForm ? true : false}
                      aria-labelledby="form-share-by-email"
                      maxWidth={"sm"}
                      fullWidth={true}
                  >
                    <MuiDialogTitle className="popup-title" disableTypography>
                      <Typography variant="h6">
                        Share itinerary by email
                      </Typography>
                      <IconButton
                          aria-label="close"
                          className="btn-close"
                          onClick={this.handleCloseForm.bind(this)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </MuiDialogTitle>
                    <ValidatorForm onSubmit={this.shareItineraryByEmail}>
                      <DialogContent>
                        <Row>
                          <Col>
                            <TextValidator
                                label="Your name *"
                                margin="none"
                                type="text"
                                fullWidth
                                autoFocus={true}
                                onChange={this.handleChange("name")}
                                value={this.state.name}
                                validators={["required"]}
                                errorMessages={[
                                  <span>
                                    <ErrorIcon />
                                    This field is required
                                  </span>,
                                ]}
                            />
                          </Col>
                          <Col>
                            <TextValidator
                                ref={this.emailRef}
                                label="To email*"
                                fullWidth
                                onChange={this.handleChange("receiver")}
                                value={this.state.receiver}
                                validators={["required", "isEmail"]}
                                errorMessages={[
                                  <span>
                                    <ErrorIcon />
                                    This field is required
                                  </span>,
                                  <span>
                                    <ErrorIcon />
                                    Email is not valid
                                  </span>,
                                ]}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <TextField
                                label="Messages"
                                multiline
                                rowsMax="4"
                                rows="4"
                                onChange={this.handleChange("messages")}
                                value={this.state.messages}
                                margin="none"
                                fullWidth
                            />
                          </Col>
                        </Row>
                      </DialogContent>
                      <DialogActions className={styles.viewItinerary}>
                        <div className="section-btn-progress">
                          <Button
                              disabled={
                                this.state.isLoadingShare ? true : false
                              }
                              id="send-button"
                              type="submit"
                              className="btn-primary"
                          >
                            <SendIcon />
                            Send
                          </Button>
                          {this.state.isLoadingShare && (
                              <CircularProgress
                                  size={24}
                                  className="btn-progress"
                              />
                          )}
                        </div>
                      </DialogActions>
                    </ValidatorForm>
                  </Dialog>

                  {!!this.state.listAllDates.length &&
                  this.state.listAllDates.map((item, key) => {
                    const currentDetail = LodashHelper.loopFind(
                        "alpha2Code",
                        "alpha2Code",
                        item.alpha2Code,
                        this.state.itinerary.itinerary_detail
                    )[0];
                    return (
                        <div key={key} id={`${item._id}-1`}>
                          <h2 className="day-title">
                            {item.title ? (
                                <span className="each-title-day">
                                  Day {key + 1}: {item.title}
                                </span>
                            ) : (
                                <span className="each-title-day">
                                  Day {key + 1}
                                </span>
                            )}
                            <span className="each-title-location">
                                {currentDetail ? (
                                    <img
                                        src={getFlag(currentDetail.alpha2Code)}
                                    />
                                ) : null}
                              {item.city
                                  ? `${item.city}, ${item.country}`
                                  : `${item.country}`}
                              </span>
                          </h2>

                          <ItineraryDetailOption
                              lodging={item.lodging}
                              amountMeals={item.amountMeals}
                              meal={item.meal}
                          />

                          {item.description && (
                              <div className="overview-itinerary-detail trix-content-temp">
                                <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                ></div>
                              </div>
                          )}
                          {/* {item.description && !!item.place_detail.length && (
                            <div className="break-section">
                              Day {key + 1} itinerary
                            </div>
                          )} */}

                          {!!item.place_detail.length && (
                              <Timeline
                                  lineColor={`#e1eef5`}
                                  className={
                                    item.place_detail.length > 1
                                        ? "timeline-event"
                                        : "timeline-event-1"
                                  }
                              >
                                {this.renderListPlaces(item)}
                              </Timeline>
                          )}
                        </div>
                    );
                  })}
                </div>
              </Col>

              <Col xs="12" lg="3" className="section-chat">
                {this.state.listRecommend.count > 0 && (
                  <h2 className="main-title-section">You might also like</h2>
                )}

                <TyneriRelated
                  listRecommend={this.state.listRecommend}
                  list_optionals={this.state.list_optionals}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <MobileView>
          <MenuMobile params={this.props.match.params} />
        </MobileView>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewItinerary)
);
