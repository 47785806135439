import React, { Component } from "react";
import { Col, Row, Avatar } from "antd";
import { config, configBaseUrl } from "../../../config";
import "./index.scss";
import TitleTyneri from "../../modules/typography/title";
import ImageHelper from "../../../functions/image";

class TopContributors extends Component {
    render() {
    return (
      <Row className="row-itineraries">
        <div className="grid-header">
            <TitleTyneri text={<span>Top contributors</span>}/>
        </div>
        <Col md={24} xl={24} xxl={24}>
          {this.props.topContributors &&
            !!this.props.topContributors.length &&
            this.props.topContributors.map((company, key) => {
                return <Col key={key} xs={12} md={4}>
                    <div className="each-contributor">
                        <a
                            className="hover-avatar-effect"
                            href={config.http + "//" + company.url + "." + config.domain}
                            target="blank"
                        >
                            <ImageHelper
                                size={140}
                                src={configBaseUrl.baseURL + company.logo}
                                avatar={true}
                            />
                            <span className="avatar-title">{company.name}</span>
                        </a>
                    </div>
                </Col>;
            })}
        </Col>
      </Row>
    );
  }
}

export default TopContributors;
