import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import FormSignIn from '../form-sign-in-up/components/FormSignIn';
import styles from './index.scss';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ForgetPassword from '../form-forget-password';

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.info,
  };
};

class ContentSignIn extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      showReset: false,
    };
  }

  componentDidMount = () => {
    if (this.props.profile) this.props.history.push('/');
  };

  showFormReset = () => {
    this.setState({
      showReset: true,
    });
  };
  render() {
    return (
      <>
        <Dialog open={this.state.showReset}>
          <DialogContent>
            <IconButton
              aria-label="close"
              style={{
                color: 'red',
                position: `absolute`,
                right: 0,
                zIndex: 9,
                top: 0,
              }}
            >
              <CloseIcon
                onClick={() => {
                  this.setState({
                    showReset: false,
                  });
                }}
              />
            </IconButton>
            <ForgetPassword />
          </DialogContent>
        </Dialog>
        <Row className={styles.contentSignIn}>
          <Col xs={24} lg={14}>
            <div className="sign-in-form">
              <div className="sign-in-form__head">
                <h3 className="sign-in-form__head__title">Sign-in</h3>
              </div>
              <div className="sign-in-form__content">
                <FormSignIn showFormReset={this.showFormReset} />
              </div>
            </div>
          </Col>
          <Col xs={24} lg={10} className="section-browse-idea--mobile">
            <div className="section-browse-idea">
              <div className="section-browse-idea__img"></div>
              <span className="section-browse-idea__helper-main">
                EASY AND FREE
              </span>
              <span className="section-browse-idea__helper-secondary">
                trip planner for everyone
              </span>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(ContentSignIn));
