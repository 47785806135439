import React, { Component } from "react";
import ApiService from "../../services/api.service";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import styles from "./index.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import { getNameAuthor } from "../../services/utils.service";
import { config } from "../../config";

const mapStateToProps = (state) => {
  return {
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

export const CardTopListRelative = (props) => {
  const { item } = props;
  return (
    <div className="each-card-top-list-relative">
      <Link style={{ display: `contents` }} to={`/view-top-list/${item._id}`}>
        <div style={{backgroundImage: `url("${config.baseURL}${item.cover}")`}} className="img-card-top-list-relative"></div>
      </Link>

      <div className="content-card-top-list-relative">
        <Link to={`/view-top-list/${item._id}`}>
          <p className="content-card-top-list-relative__title">{item.title}</p>
        </Link>
        <p className="content-card-top-list-relative__author">
          By {getNameAuthor(item.user, item.company)} -{" "}
          {moment(new Date(item.updated_at)).fromNow()}
        </p>
      </div>
    </div>
  );
};

class TopListsRelative extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      lists: [],
    };
  }

  componentDidMount = () => {
    this.apiService
      .getTopListsRelative(
        this.props.top_list.category_id,
        this.props.top_list._id
      )
      .then((res) => {
        if (res.status === "success") {
          this.setState({
            lists: res.lists,
          });
        }
      });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.top_list._id !== this.props.top_list._id) {
      this.apiService
        .getTopListsRelative(
          nextProps.top_list.category_id,
          nextProps.top_list._id
        )
        .then((res) => {
          if (res.status === "success") {
            this.setState({
              lists: res.lists,
            });
          }
        });
    }
  };

  render() {
    return (
      <div className={styles.topListsRelative}>
        {!!this.state.lists.length ? (
          <>
            {" "}
            <div className="header-top-lists-relative">You might also like</div>
            <div>
              {!!this.state.lists.length &&
                this.state.lists.map((item, key) => (
                  <CardTopListRelative key={key} item={item} />
                ))}
            </div>{" "}
          </>
        ) : null}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(TopListsRelative));
