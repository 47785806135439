import React, {useState} from "react";
import AvatarNotFound from "../assets/media/images/default-avatar.png"
import NotFound from "../assets/media/images/no-photo.png";
import { LoadingOutlined } from '@ant-design/icons';
import { Img } from "react-image";
import NoPhoto from "../assets/media/images/no-photo.png";
import {Avatar} from "antd";

const LoadingCustom = () =>  {
    return <div className="loading-custom" style={{ textAlign: 'center',
                         width: '100%',
                         padding: '10%',
                         height: '100%' }}>
            <LoadingOutlined style={{  margin: '0 auto' }} />
    </div>
  };


const getImageNotFound = (props) => {
    if(props.avatarDefault)
        return AvatarNotFound
     else
        return props.notFound || NotFound;
}

const ImageHelper = (props) => {
    const [avatar, setAvatar] = useState(props.src);
    let imageNotFound = (
        <img  className={ props.className ? props.className : '' }
              src={  getImageNotFound(props) } alt="Not found" />
    );

    if(props.loadingUpload) return  <LoadingCustom/>

    return (
        props.avatar ? (<Avatar
            size={props.size || 64}
            src={avatar}
            className={ props.className ? props.className : '' }
            style={props.style}
            alt={ props.alt ? props.alt : '' }
            onError={()=> setAvatar(NoPhoto)}
        />) : (
        <Img src={ props.src }
             style={props.style}
             className={ props.className ? props.className : '' }
             decode={false}
             alt={ props.alt ? props.alt : '' }
             loader={ <LoadingCustom/> }
             unloader={ !props.noShowDeImage ? imageNotFound : null} />)
    )
}

export default ImageHelper;
