import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { Row, Col } from "reactstrap";
import TextField from "@material-ui/core/TextField";

import { closeFormShareByEmail } from "../../store/actions";
import { connect } from "react-redux";
import ApiService from "../../services/api.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import SendIcon from "@material-ui/icons/Send";
import styles from "./index.scss";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ErrorIcon from "@material-ui/icons/Error";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import Fab from "@material-ui/core/Fab";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Swal from "sweetalert2/dist/sweetalert2.js";

const mapStateToProps = (state) => {
  return {
    isShowFormShareByEmail: state.formShareByEmailReducer,
    itinerary: state.itineraryReducer,
    profile: state.profileReducer.info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeFormShareByEmail: () => {
      dispatch(closeFormShareByEmail());
    },
  };
};

class FormShareByEmail extends Component {
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      name: "",
      receiver: "",
      isLoading: false,
      messages: "",
      linkShare: "",
      showForm: false,
      copied: false,
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  shareItineraryByEmail = () => {
    this.setState({
      isLoading: true,
    });
    this.apiService
      .shareItineraryByEmail(
        this.state.name,
        this.state.receiver,
        this.state.messages,
        this.props.params.idItinerary
      )
      .then((res) => {
        if (res.status === "success") {
          Swal.fire({
            type: "success",
            title: "Share successfully",
            showConfirmButton: true,
          });
          this.setState(
            {
              isLoading: false,
              showForm: false,
              name: "",
              receiver: "",
              messages: "",
            },
            () => {
              this.props.closeFormShareByEmail();
            }
          );
        }
      });
  };

  componentDidMount = () => {
    if (this.props.profile) {
      this.setState({
        name: this.props.profile.fullName,
      });
    }
    this.apiService.getLinkShare(this.props.params.idItinerary).then((res) => {
      if (res.status === "success") {
        this.setState({
          linkShare: res.link,
        });
      }
    });
  };

  closeFormShareByEmail = () => {
    if (this.state.isLoading) return;
    this.props.closeFormShareByEmail();
  };

  handleShowForm = () => {
    this.props.closeFormShareByEmail();
    this.setState({
      showForm: true,
    });
  };

  handleCloseForm = () => {
    this.setState({
      showForm: false,
    });
  };

  render() {
    var { isShowFormShareByEmail } = this.props;
    const shareUrl = this.state.linkShare;
    const title = `${this.props.itinerary.name}`;
    return (
      <React.Fragment>
        <Dialog
          open={isShowFormShareByEmail ? true : false}
          aria-labelledby="form-share-by-email"
          className={styles.formShareByEmail}
          maxWidth={"sm"}
          fullWidth={true}
        >
          <MuiDialogTitle className="popup-title" disableTypography>
            <Typography variant="h6">Share Tyneri</Typography>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={this.closeFormShareByEmail.bind(this)}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent>
            <Row>
              <Typography style={{ textAlign: "center" }} variant="subtitle1">
                Anyone with the link can see your plan but will not see your
                custom events, notes, attrachments and reservations
              </Typography>
              <div style={{ display: `inline-flex`, margin: `0 auto` }}>
                <CopyToClipboard
                  text={this.state.linkShare}
                  onCopy={() => this.setState({ copied: true })}
                >
                  {/* <button>Copy to clipboard with button</button> */}
                  <div className="icon-copy-link-share-email">
                    <Fab color="primary" aria-label="add">
                      <LinkIcon />
                    </Fab>
                    {this.state.copied && (
                      <span style={{ textAlign: "center", color: "red" }}>
                        Copied!
                      </span>
                    )}
                    <span>Copy public link</span>
                  </div>
                </CopyToClipboard>

                <div className="icon-copy-link-share-email">
                  <Fab
                    color="primary"
                    aria-label="add"
                    onClick={this.handleShowForm.bind(this)}
                  >
                    <MailOutlineIcon />
                  </Fab>
                  <span>Email public link</span>
                </div>
              </div>
            </Row>
            <Row>
              <div className="row-icon-share">
                <FacebookShareButton
                  url={shareUrl}
                  className="Demo__some-network__share-button"
                >
                  <FaFacebook className="icon icon-facebook" />
                </FacebookShareButton>
                <TwitterShareButton
                  url={shareUrl}
                  title={title}
                  className="Demo__some-network__share-button"
                >
                  <FaTwitter className="icon icon-twitter" />
                </TwitterShareButton>
                {/* <PinterestShareButton
                                    url={String(window.location)}
                                    //media={`${String(window.location)}/${exampleImage}`}
                                    windowWidth={1000}
                                    windowHeight={730}
                                    className="Demo__some-network__share-button">
                                    <FaPinterest className="icon icon-pinter" />
                                </PinterestShareButton> */}
              </div>
            </Row>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.showForm ? true : false}
          aria-labelledby="form-share-by-email"
          className={styles.formShareByEmail}
          maxWidth={"sm"}
          fullWidth={true}
        >
          <MuiDialogTitle className="popup-title" disableTypography>
            <Typography variant="h6">Share itinerary by email</Typography>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={this.handleCloseForm.bind(this)}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <ValidatorForm onSubmit={this.shareItineraryByEmail.bind(this)}>
            <DialogContent>
              <Row>
                <Col xs="12" md="6">
                  <TextValidator
                    label="Your name *"
                    margin="none"
                    type="text"
                    fullWidth
                    autoFocus={true}
                    onChange={this.handleChange("name")}
                    value={this.state.name}
                    validators={["required"]}
                    errorMessages={[
                      <span>
                        <ErrorIcon />
                        This field is required
                      </span>,
                    ]}
                  />
                </Col>
                <Col xs="12" md="6">
                  <TextValidator
                    ref={this.emailRef}
                    label="To email*"
                    fullWidth
                    onChange={this.handleChange("receiver")}
                    value={this.state.receiver}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      <span>
                        <ErrorIcon />
                        This field is required
                      </span>,
                      <span>
                        <ErrorIcon />
                        Email is not valid
                      </span>,
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    label="Messages"
                    multiline
                    rowsMax="4"
                    rows="4"
                    onChange={this.handleChange("messages")}
                    value={this.state.messages}
                    margin="none"
                    fullWidth
                  />
                </Col>
              </Row>
            </DialogContent>
            <DialogActions className={styles.formShareByEmail}>
              <div className="section-btn-progress">
                <Button
                  disabled={this.state.isLoading ? true : false}
                  id="send-button"
                  type="submit"
                  className="btn-primary"
                >
                  <SendIcon />
                  Send
                </Button>
                {this.state.isLoading && (
                  <CircularProgress size={24} className="btn-progress" />
                )}
              </div>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormShareByEmail);
