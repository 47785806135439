import { getToken } from "../services/authService";
import axios from '../axios-config';

export default () => {
  let services = {
    checkEmailVerifyEmail: (email) => {
      try {
        return axios.post("users/checkEmailVerifyEmail", {
          email,
        }).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    checkEmailResetPassword: (email) => {
      try {
        return axios.post ("users/checkEmailResetPassword", {
          email,
        }).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    checkVerified: (email) => {
      try {
        return axios.get("users/checkVerified?email=" + email).then(
          (res) => {
            return res.data;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    checkTokenVerifyEmail: (token) => {
      try {
        return axios.get(
          "users/checkTokenVerifyEmail?token=" + token
        ).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    checkTokenResetPassword: (token) => {
      try {
        return axios.get(
         "users/checkTokenResetPassword?token=" + token
        ).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    verifyEmail: (token) => {
      try {
        return axios.post ("users/verifyEmail", {
          token,
        }).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    resetPassword: (token, password) => {
      try {
        return axios.post ("users/resetPassword", {
          token,
          password,
        }).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    checkIsProAccountByUserId: (userId) => {
      try {
        return axios.post ("users/isProAccount", { id: userId }).then(
          (res) => {
            return res.data;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    upgradeAccount: (userId, accountType, pack, idPackage) => {
      try {
        return axios.post ("users/upgradeAccount", {
          id: userId,
          accountType: accountType,
          pack,
          idPackage,
        }).then((res) => {
          return res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    uploadAvatar: (formData) => {
      return axios.post ("users/uploadAvatar", formData, true, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then((res) => {
        return res.data;
      });
    },

    uploadBanner: (formData) => {
      return axios.post ("users/uploadBanner", formData, true, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + getToken(),
        },
      }).then((res) => {
        return res.data;
      });
    },

    getAllJob: () => {
      return axios.get("job/get-all", (res) => {
        return res.data;
      });
    },

    saveJob: (data, id = null) => {
      const idUpdate = id !== null ? ("?id=" + id) : '';
      return axios.post ("job/save" + idUpdate, data, res=> {
        return res.data;
      });
    },
  };

  return services;
};
