/*global google*/
import React, {useEffect, useState} from "react";
import {compareDesc} from "date-fns";
import styles from "./index.scss";
import {Avatar, List, Typography} from "antd";
import LogoGG from "../../assets/media/images/google.png";
import {StarRating} from "../index";

const {Title} = Typography;

const MapPreview = ({place_id}, props) =>{
    const [reviews, setReviews] = useState();
    const [rating, setRating] = useState();
    const [user_ratings_total, setUser_ratings_total] = useState();
    let request = {
        placeId: place_id,
        fields: [
            "address_component",
            "adr_address",
            "formatted_address",
            "geometry",
            "icon",
            "name",
            "photo",
            "place_id",
            "plus_code",
            "type",
            "url",
            "utc_offset_minutes",
            "vicinity",
            "formatted_phone_number",
            "international_phone_number",
            "opening_hours",
            "website",
            "price_level",
            "rating",
            "review",
            "user_ratings_total",
        ],
    };
    let service = new google.maps.places.PlacesService(
        document.createElement("div")
    );
    useEffect(() => {
        service.getDetails(request, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                if (place.reviews) {
                    place.reviews.sort((data_A, data_B) => {
                        return compareDesc(
                            new Date(data_A.time * 1000),
                            new Date(data_B.time * 1000)
                        );
                    });
                    setReviews(place.reviews);
                    setRating(place.rating);
                    setUser_ratings_total(place.user_ratings_total);
                }
            }
        });
    }, [place_id]);

    return (
        <div className={styles.sectionPreview}>
            <Title className="preview-title" level={3}>
                Reviews
            </Title>
            <div style={{width: '100%'}}>
                <span style={{ paddingRight: "0.5rem" }}>On</span>
                <img style={{ height: `28px` }} src={LogoGG} />
            </div>
            <List
                itemLayout="vertical"
                size="large"
                className="preview-content"
                dataSource={reviews}
                renderItem={(item) => (
                    <List.Item key={item.title}>
                        <List.Item.Meta
                            style={{ marginBottom: 0 }}
                            avatar={
                                <Avatar
                                    size={64}
                                    src={item.profile_photo_url}
                                />
                            }
                            title={
                                <>
                                    <a href={item.author_url}>
                                        {item.author_name}
                                    </a>
                                    <div>
                                        <StarRating ratingAverage={item.rating} />
                                    </div>
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            color: `#9a9a9a`,
                                        }}
                                    >
                                        {item.relative_time_description}
                                    </span>
                                </>
                            }
                        />
                        <span style={{ fontSize: "1rem" }}>
                            {item.text}
                        </span>
                    </List.Item>
                )}
            />
        </div>
    );
}

export default MapPreview;
