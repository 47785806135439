/*global google*/
import React, { Component } from "react";
import {
  PlacesInListSelected,
  AddNewStopForm,
  ItineraryDetails,
  PlacesInExplore,
  PlacesInMyPlaces,
  PlacesInShared,
  EditDayTitleChangeDate,
  FormViewEditPlace,
  FormViewEditTransportation,
  SearchPlacesNearBy,
  FormSignInUp,
  FormShareByEmail,
  ListMyTyneries,
  EditItineraryForm,
  FormQuote,
  SocketContext,
  ChangeLocation,
  ViewOption,
  FormAddCountry,
  ItineraryDetailOption,
  ItineraryPdf,
} from "../../components";
import { Row, Col, FormGroup, Input, Badge, FormFeedback } from "reactstrap";
import { MdFavorite, MdPublic } from "react-icons/md";

import styles from "./index.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  getItemStyle,
  getListStyle,
  move,
  reorder,
  insert,
  moveFromExplore,
  createPlaceDetailFromExplore,
  isMyResource,
  searchTree,
  getAllLeaf,
  updateTree,
  moveOutDrafts,
  convertAdvanceFieldToNormal,
} from "../../services/utils.service";
import { Timeline } from "react-event-timeline";
import { connect } from "react-redux";
import {
  openFormAddNewStop,
  updateListItineraryDetail,
  updateListPlacesExplore,
  reloadPdf,
  unReloadPdf,
  saveProfile,
  openFormShareByEmail,
  openFormLoad,
  closeFormLoad,
  saveAfterLogin,
  updateTotalAmount,
  updateInforCurrency,
  redirectToBillingAfterLogin,
} from "../../store/actions";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

import ApiService from "../../services/api.service";
import { format } from "date-fns";
import { loadItem, saveItem } from "../../services/storage.service";
import ButtonMaterial from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ObjectID } from "bson";
import Typography from "@material-ui/core/Typography";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import Skeleton from "@material-ui/lab/Skeleton";
import Tooltip from "@material-ui/core/Tooltip";
import history from "../history";
import { Icon, Modal, Button, Form, Radio, Avatar, Alert } from "antd";

import notiMusic from "../../assets/media/music/noti.mp3";
import moment from "moment";
import CreateNewFolderOutlinedIcon from "@material-ui/icons/CreateNewFolderOutlined";

import HelperDragDrop from "../../assets/media/images/helper_drag_drop.png";
import { configBaseUrl } from "../../config";

import ShowMoreText from "react-show-more-text";
import NoPhoto from "../../assets/media/images/no-photo.png";

import { TiCamera } from "react-icons/ti";
import { WarningFilled } from "@ant-design/icons";
import formatMoney from "accounting-js/lib/formatMoney.js";
import { maxCharacters } from "../../constants/itinerary";
import { withRouter } from "react-router";
import stylesGlobal from "./index.scss";
import { categoriesDefined } from "../../constants/categories";
import _ from "lodash";

const mapDispatchToProps = (dispatch) => {
  return {
    updateInforCurrency: (currency) => {
      dispatch(updateInforCurrency(currency));
    },
    updateListItineraryDetail: (typeUpdate) => {
      dispatch(updateListItineraryDetail(typeUpdate));
    },
    updateListPlacesExplore: (list) => {
      dispatch(updateListPlacesExplore(list));
    },
    updateTotalAmount: (amount) => {
      dispatch(updateTotalAmount(amount));
    },
    reloadPdf: () => {
      dispatch(reloadPdf());
    },
    unReloadPdf: () => {
      dispatch(unReloadPdf());
    },
    saveProfile: (info) => {
      dispatch(saveProfile(info));
    },
    openFormAddNewStop: () => {
      dispatch(openFormAddNewStop());
    },
    openFormShareByEmail: () => {
      dispatch(openFormShareByEmail());
    },
    openFormLoad: () => {
      dispatch(openFormLoad());
    },
    closeFormLoad: () => {
      dispatch(closeFormLoad());
    },
    saveAfterLogin: (flag) => {
      dispatch(saveAfterLogin(flag));
    },
    redirectToBillingAfterLogin: (flag) => {
      dispatch(redirectToBillingAfterLogin(flag));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    selected2: state.listPlacesExploreReducer,
    itinerary: state.itineraryReducer,
    currency: state.itineraryReducer.currency,
    currency_infor: state.itineraryReducer.currency_infor,
    total_amount: state.itineraryReducer.total_amount,
    isReloadPdf: state.filePdfReducer,
    profile: state.profileReducer.info,
    company: state.profileReducer.company,
    isLoadingExplore: state.loadingReducer,
    showLoad: state.formLoadReducer,
    saveOrSaveAsAfterLogin: state.saveAfterLoginReducer,
    isRedirectToBillingAfterLogin: state.redirectToBillingAfterLoginReducer,
    listLoad: state.listLoadReducer.list,
  };
};

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000,
});
var loading = [1, 1, 1, 1, 1];

class ContentDetail extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiService = ApiService();
    this.state = {
      numTab: 1,
      item: {},
      items: [],
      selected: [],
      selected2: [],
      selected3: [],
      editDayTitleChangeDate: false,
      title: "",
      monthYear: "",
      monthDayYear: "",

      description: "",
      lodging: "",
      meal: [],
      amountMeals: "",
      day: "",
      lengthItineraryDetail: 1,
      searchName: "",
      search: [],
      order: 0,
      isWarning: false,
      listItineraries: [],
      name: "",
      estimated_budget: "",
      a_number_of_paxs: "",
      itinerary: null,

      openFormSaveItinerary: false,
      showFormLogin: false,
      isLoadingSave: false,
      isAuthenticated: false,
      user: null,
      token: "",
      value: 0,
      isBelongToMe: false,
      index: -1,
      checkboxRemove: false,
      isLoadingFavoritePlaces: false,
      isLoadingPlacesShared: false,

      showLoad: false,

      expand: false,

      showFormEditItinerary: false,
      showFormAddCountry: false,

      showWarningHaveNewVersion: false,

      newVerItinerary: {},
      visible: false,
      myFolders: [],
      createFolderMode: false,

      loadingCreateFolder: false,
      loadingChooseFolder: false,

      listDates: [],

      showOrder: false,
      showOrderCity: false,
      categories: [],
      showPDF: false,
      quotation: null,
    };
  }

  handleEnableEditTitleChangeDay = () => {
    this.setState({
      editDayTitleChangeDate: true,
    });
  };

  handleMyPlaces = () => {
    this.setState({
      numTab: 2,
    });
  };

  handleExplore = () => {
    this.setState({
      numTab: 1,
    });
  };

  id4List = {
    droppable: "items",
    droppable2: "selected",
    droppable3: "selected2",
    droppable4: "selected3",
  };

  getList = (_id) => this.state[this.id4List[_id]];

  onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    //Reorder list selected
    if (
      source.droppableId === destination.droppableId &&
      source.droppableId === "droppable" &&
      destination.droppableId === "droppable"
    ) {
      if (source.index === destination.index) return;
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );
      let tempItems = items;
      let flags = false;
      for (let i = 0; i < tempItems.length - 1; i++) {
        for (let j = i + 1; j < tempItems.length; j++) {
          if (
            tempItems[j].data.actual_data.time_start === "" ||
            tempItems[i].data.actual_data.time_start === ""
          )
            continue;
          if (
            tempItems[j].data.actual_data.time_start <
            tempItems[i].data.actual_data.time_start
          ) {
            flags = true;
            break;
          }
        }
        if (flags) break;
      }
      if (flags) {
        this.setState(
          {
            isWarning: true,
          },
          () => {
            items[destination.index].data.actual_data.time_start = "";
            items.forEach((item, index) => {
              item.order = index + 1;
            });
            let itineraries;
            itineraries = loadItem("itineraries");
            let current = itineraries.findIndex((item) => {
              return item._id === this.props.params.idItinerary;
            });
            let tempItem = searchTree(
              itineraries[current].itinerary_detail,
              this.props.params.idItineraryDetail
            )[0];

            tempItem.place_detail = items;
            let updated = updateTree(
              itineraries[current].itinerary_detail,
              tempItem
            );
            itineraries[current].itinerary_detail = updated;
            saveItem("itineraries", itineraries);
            this.setState({
              items: items,
            });
          }
        );
      } else {
        items.forEach((item, index) => {
          item.order = index + 1;
        });
        let itineraries;
        itineraries = loadItem("itineraries");
        let current = itineraries.findIndex((item) => {
          return item._id === this.props.params.idItinerary;
        });
        let tempItem = searchTree(
          itineraries[current].itinerary_detail,
          this.props.params.idItineraryDetail
        )[0];

        tempItem.place_detail = items;
        let updated = updateTree(
          itineraries[current].itinerary_detail,
          tempItem
        );
        itineraries[current].itinerary_detail = updated;
        saveItem("itineraries", itineraries);
        this.setState({
          items: items,
        });
      }
    } else if (
      source.droppableId === "droppable" &&
      destination.droppableId === "droppable3"
    )
      return;
    else if (source.droppableId === destination.droppableId) return;
    else if (
      source.droppableId === "droppable3" &&
      destination.droppableId === "droppable"
    ) {
      const result2 = moveFromExplore(
        this.props.selected2.list,
        this.getList(destination.droppableId),
        source,
        destination
      );
      let place = {
        name: result2.removed.name,
        vicinity: result2.removed.vicinity,
        image: null,
      };
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: place.vicinity }, async (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          place = {
            ...place,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };
          var images;
          if (result2.removed.photos) {
            await this.apiService
              .getImg(result2.removed.place_id)
              .then((res) => {
                if (res.status === "success") {
                  images = res.img;
                } else {
                  images = null;
                }
              });
          }
          //place.image = result2.removed.photos[0].getUrl();
          let itineraries;
          itineraries = loadItem("itineraries");
          let current = itineraries.findIndex((item) => {
            return item._id === this.props.params.idItinerary;
          });
          var status2;
          if (this.props.selected2.status === "tourist_attraction") {
            let temp = _.find(this.state.categories, (category) => {
              return category.name === categoriesDefined.ATTRACTION;
            });
            status2 = temp._id;
          }

          if (this.props.selected2.status === "lodging") {
            let temp = _.find(this.state.categories, (category) => {
              return category.name === categoriesDefined.ACCOMMODATION;
            });
            status2 = temp._id;
          }
          if (this.props.selected2.status === "restaurant") {
            let temp = _.find(this.state.categories, (category) => {
              return category.name === categoriesDefined.RESTAURANT;
            });
            status2 = temp._id;
          }
          if (this.props.selected2.status === "travel_agency") {
            let temp = _.find(this.state.categories, (category) => {
              return category.name === categoriesDefined.TOURACTIVITY;
            });
            status2 = temp._id;
          }
          if (this.props.selected2.status === "transit_station") {
            let temp = _.find(this.state.categories, (category) => {
              return category.name === categoriesDefined.TRANSPORTATION;
            });
            status2 = temp._id;
          }
          place = {
            ...place,
            status: status2,
            images: images,
          };
          let newPlaceDetail = createPlaceDetailFromExplore(
            result2.position + 1,
            place,
            this.props.params.idItineraryDetail,
            this.props.currency,
            itineraries[current].transportation,
            this.state.categories
          );
          let tempItem = searchTree(
            itineraries[current].itinerary_detail,
            this.props.params.idItineraryDetail
          )[0];
          tempItem.place_detail.splice(result2.position, 0, {
            ...newPlaceDetail,
            isNew: true,
          });
          tempItem.place_detail.forEach((item, index) => {
            item.order = index + 1;
          });
          saveItem("itineraries", itineraries);
          this.updateListSelected(9);
        } else {
          place = {
            ...place,
            lat: "",
            lng: "",
          };
          var images;
          if (result2.removed.photos) {
            await this.apiService
              .getImg(result2.removed.place_id)
              .then((res) => {
                if (res.status === "success") {
                  images = res.img;
                } else {
                  images = null;
                }
              });
          }
          //place.image = result2.removed.photos[0].getUrl();
          let itineraries;
          itineraries = loadItem("itineraries");
          let current = itineraries.findIndex((item) => {
            return item._id === this.props.params.idItinerary;
          });
          var status2;
          if (this.props.selected2.status === "tourist_attraction") {
            let temp = _.find(this.state.categories, (category) => {
              return category.name === categoriesDefined.ATTRACTION;
            });
            status2 = temp._id;
          }

          if (this.props.selected2.status === "lodging") {
            let temp = _.find(this.state.categories, (category) => {
              return category.name === categoriesDefined.ACCOMMODATION;
            });
            status2 = temp._id;
          }
          if (this.props.selected2.status === "restaurant") {
            let temp = _.find(this.state.categories, (category) => {
              return category.name === categoriesDefined.RESTAURANT;
            });
            status2 = temp._id;
          }
          if (this.props.selected2.status === "travel_agency") {
            let temp = _.find(this.state.categories, (category) => {
              return category.name === categoriesDefined.TOURACTIVITY;
            });
            status2 = temp._id;
          }
          if (this.props.selected2.status === "transit_station") {
            let temp = _.find(this.state.categories, (category) => {
              return category.name === categoriesDefined.TRANSPORTATION;
            });
            status2 = temp._id;
          }
          place = {
            ...place,
            status: status2,
            images: images,
          };
          let newPlaceDetail = createPlaceDetailFromExplore(
            result2.position + 1,
            place,
            this.props.params.idItineraryDetail,
            this.props.currency,
            itineraries[current].transportation,
            this.state.categories
          );
          let tempItem = searchTree(
            itineraries[current].itinerary_detail,
            this.props.params.idItineraryDetail
          )[0];
          tempItem.place_detail.splice(result2.position, 0, {
            ...newPlaceDetail,
            isNew: true,
          });
          tempItem.place_detail.forEach((item, index) => {
            item.order = index + 1;
          });
          saveItem("itineraries", itineraries);
          this.updateListSelected(9);
        }
      });
    } else {
      const result1 = move(
        this.props.listLoad,
        this.getList(destination.droppableId),
        source,
        destination
      );

      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      let tempItem = searchTree(
        itineraries[current].itinerary_detail,
        this.props.params.idItineraryDetail
      )[0];

      let temp = { ...result1.removed };

      delete temp.alpha2Code;
      delete temp.country;
      delete temp.belong_to_user;
      delete temp._id;

      temp = {
        ...temp,
        belong_to_itinerary_detail: this.props.params.idItineraryDetail,
        belong_to_library: result1.removed.belong_to_library,
        belong_to_library_place: result1.removed._id,
        order: result1.position + 1,
        _id: new ObjectID().toHexString(),
      };

      tempItem.place_detail.splice(result1.position, 0, {
        ...temp,
        isNew: true,
      });
      tempItem.place_detail.forEach((item, index) => {
        item.order = index + 1;
      });
      this.setState({
        items: tempItem.place_detail,
      });
      Toast.fire({
        type: "success",
        title: "Add new place successfully",
      });
      saveItem("itineraries", itineraries);
    }
  };

  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    if (nextProps.isRedirectToBillingAfterLogin > 0) {
      this.handleRedirectToBilling();
    }

    if (nextProps.profile) {
      if (Object.keys(nextProps.profile).length > 0) {
        this.setState(
          {
            isAuthenticated: true,
            showFormLogin: false,
          },
          () => {
            if (nextProps.saveOrSaveAsAfterLogin > 0) {
              this.handleOpenFormSaveItinerary();
            }
          }
        );
        let itineraries;
        itineraries = loadItem("itineraries");
        let current = itineraries.findIndex((item) => {
          return item._id === this.props.params.idItinerary;
        });
        if (nextProps.profile._id === itineraries[current].belong_to_user) {
          this.setState({
            isBelongToMe: true,
            user: nextProps.profile,
          });
        }
        if (this.props.profile !== nextProps.profile) {
          this.setState({
            isLoadingFavoritePlaces: true,
          });
          this.apiService.getListMyPlace(nextProps.profile._id).then((res) => {
            if (this._isMounted) {
              if (res) {
                res.my_places.sort(function (itemA, itemB) {
                  if (
                    itemA.data.actual_data.title < itemB.data.actual_data.title
                  )
                    return -1;
                  if (
                    itemA.data.actual_data.title > itemB.data.actual_data.title
                  )
                    return 1;
                  return 0;
                });
                this.apiService.getMyFolders().then((res) => {
                  if (res.status === "success") {
                    this.setState(
                      {
                        myFolders: res.folders,
                      },
                      () => {
                        this.props.form.setFieldsValue({
                          folder: itineraries[current].belong_to_folder,
                        });
                      }
                    );
                  }
                });
                this.setState({
                  isLoadingFavoritePlaces: false,
                  selected: res.my_places,
                });
              } else {
                this.setState({
                  isLoadingFavoritePlaces: false,
                  selected: [],
                });
              }
            }
          });
        }
      }
    } else {
      this.setState({
        selected: [],
        isBelongToMe: false,
      });
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      if (!itineraries[current].belong_to_user) {
        this.setState({
          isBelongToMe: true,
        });
      }
    }

    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        editDayTitleChangeDate: false,
      });
      var itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === nextProps.params.idItinerary;
      });

      let tempItem = searchTree(
        itineraries[current].itinerary_detail,
        nextProps.params.idItineraryDetail
      )[0];

      this.setState({
        title: tempItem.title,
        description: tempItem.description,
        lodging: tempItem.lodging,
        meal: tempItem.meal,
        amountMeals: tempItem.amountMeals,
        day: format(new Date(tempItem.month_day_year * 1000), "d"),
        monthYear: tempItem.month_day_year
          ? format(new Date(tempItem.month_day_year * 1000), "MMM d, yyyy")
          : null,
        monthDayYear: tempItem.month_day_year
          ? new Date(tempItem.month_day_year * 1000)
          : null,
        //lengthItineraryDetail: itineraries[current].itinerary_detail.length,
        index: tempItem.index,
        expand: false,
      });

      if (tempItem) {
        this.setState({
          items: tempItem.place_detail,
          order: tempItem.place_detail.length + 1,
        });
      } else {
        this.setState({
          items: [],
        });
      }

      if (nextProps.isReloadPdf) {
        this.forceUpdate(() => {
          this.props.unReloadPdf();
        });
      }
    }
  };

  reRenderContenDetail = async (idItinerary, idItineraryDetail) => {
    this.setState({
      editDayTitleChangeDate: false,
    });
    var itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });
    var result = searchTree(
      itineraries[current].itinerary_detail,
      idItineraryDetail
    );

    var listDates = getAllLeaf(itineraries[current].itinerary_detail);
    this.setState({});

    this.setState({
      title: result[0].title,
      day: format(new Date(result[0].month_day_year * 1000), "d"),
      monthYear: result[0].month_day_year
        ? format(new Date(result[0].month_day_year * 1000), "MMM d, yyyy")
        : null,
      monthDayYear: result[0].month_day_year
        ? new Date(result[0].month_day_year * 1000)
        : null,
      lengthItineraryDetail: itineraries[current].itinerary_detail.length,
      listDates: listDates,
      items: result[0].place_detail,
      order: result[0].place_detail.length + 1,
    });
  };

  componentDidMount = async () => {
    this._isMounted = true;

    this.props.socket.on("save_change", ({ idItinerary, itinerary }) => {
      if (idItinerary === this.props.params.idItinerary) {
        this.setState(
          {
            showWarningHaveNewVersion: true,
            newVerItinerary: itinerary,
          },
          () => {
            const audioEl = document.getElementsByClassName("audio-element")[0];
            audioEl.play();
          }
        );
      } else {
        let itineraries = loadItem("itineraries");
        let index = itineraries.findIndex((item) => {
          return item._id === idItinerary;
        });
        itineraries[index] = itinerary;
        saveItem("itineraries", itineraries);
      }
    });

    if (this.props.profile) {
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      this.apiService.getMyFolders().then((res) => {
        if (res.status === "success") {
          this.setState(
            {
              myFolders: res.folders,
            },
            () => {
              this.props.form.setFieldsValue({
                folder: itineraries[current].belong_to_folder,
              });
            }
          );
        }
      });
      if (
        this.props.profile._id === itineraries[current].belong_to_user ||
        !itineraries[current].belong_to_user
      ) {
        this.setState({
          isBelongToMe: true,
          user: this.props.profile,
          isAuthenticated: true,
        });
      } else {
        this.setState({
          user: this.props.profile,
          isAuthenticated: true,
        });
      }
    } else {
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      if (!itineraries[current].belong_to_user) {
        this.setState({
          isBelongToMe: true,
        });
      }
    }

    this.apiService.getAttractionCategories().then((res) => {
      if (res.status === "success")
        this.setState({
          categories: res.categories,
        });
    });

    this.apiService.getAllItinerariesOfEachCountry().then((res) => {
      if (res.status === "success") {
        this.setState({
          listItineraries: res.list_itineraries,
        });
      }
    });

    this.setState({
      editDayTitleChangeDate: false,
    });
    var itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });
    if (current < 0) return;

    let tempItem = searchTree(
      itineraries[current].itinerary_detail,
      this.props.params.idItineraryDetail
    )[0];

    var result = getAllLeaf(itineraries[current].itinerary_detail);
    this.setState({
      listDates: result,
    });

    if (Object.keys(this.props.itinerary).length > 0) {
      this.setState(
        {
          itinerary: this.props.itinerary,
        },
        () => {
          if (this.state.itinerary.belong_to_user) {
            this.apiService
              .getName(this.state.itinerary.belong_to_user)
              .then((res) => {
                if (res.status === "success") {
                  this.setState({
                    itinerary: {
                      ...this.state.itinerary,
                      written_by: `${res.user.firstName} ${res.user.lastName}`,
                    },
                  });
                }
              });
          }
        }
      );
    } else {
      this.setState(
        {
          itinerary: itineraries[current],
        },
        () => {
          if (this.state.itinerary.belong_to_user) {
            this.apiService
              .getName(this.state.itinerary.belong_to_user)
              .then((res) => {
                if (res.status === "success") {
                  this.setState({
                    itinerary: {
                      ...this.state.itinerary,
                      written_by: `${res.user.firstName} ${res.user.lastName}`,
                    },
                  });
                }
              });
          }
        }
      );
    }

    if (tempItem) {
      this.props.updateTotalAmount(itineraries[current].total_amount);
      this.apiService
        .getDetailCurrency(itineraries[current].currency)
        .then((res) => {
          if (res.status === "success") {
            this.props.updateInforCurrency(res.currency);
            this.setState({
              title: tempItem.title,
              day: tempItem.month_day_year
                ? format(new Date(tempItem.month_day_year * 1000), "d")
                : null,
              monthYear: tempItem.month_day_year
                ? format(
                    new Date(tempItem.month_day_year * 1000),
                    "MMM d, yyyy"
                  )
                : null,
              description: tempItem.description,
              lodging: tempItem.lodging,
              meal: tempItem.meal,
              amountMeals: tempItem.amountMeals,
              monthDayYear: tempItem.month_day_year
                ? new Date(tempItem.month_day_year * 1000)
                : null,
              lengthItineraryDetail:
                itineraries[current].itinerary_detail.length,
              items: tempItem.place_detail || [],
              order: tempItem.place_detail
                ? tempItem.place_detail.length + 1
                : 1,
              name: itineraries[current].name,
              cover: itineraries[current].cover,
              budget: itineraries[current].estimated_budget,
              estimated_budget: itineraries[current].estimated_budget
                ? ` on a ${formatMoney(itineraries[current].estimated_budget, {
                    symbol: this.props.currency_infor.symbol,
                    precision: this.props.currency_infor.decimal_digits,
                    thousand: ",",
                    decimal: ".",
                  })}${" "}
              ${this.props.currency_infor.code} budget`
                : "",
              a_number_of_paxs: itineraries[current].a_number_of_paxs
                ? ` for ${itineraries[current].a_number_of_paxs} pax`
                : "",
              index: tempItem.index,
            });
          }
        });
    }

    this.setState({
      isLoadingPlacesShared: true,
    });
    await this.apiService.getAllPlacesShared().then((res) => {
      if (res.status === "success")
        this.setState({
          selected3: res.places_shared,
          isLoadingPlacesShared: false,
        });
    });

    if (this.props.profile) {
      this.setState({
        isLoadingFavoritePlaces: true,
      });
      await this.apiService
        .getListMyPlace(this.props.profile._id)
        .then((res) => {
          if (this._isMounted) {
            if (res) {
              res.my_places.sort(function (itemA, itemB) {
                if (itemA.data.actual_data.title < itemB.data.actual_data.title)
                  return -1;
                if (itemA.data.actual_data.title > itemB.data.actual_data.title)
                  return 1;
                return 0;
              });
              this.setState({
                isLoadingFavoritePlaces: false,
                selected: res.my_places,
              });
            } else {
              this.setState({
                isLoadingFavoritePlaces: false,
                selected: [],
              });
            }
          }
        });
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    window.onscroll = null;
  };

  updateListSelected = async (typeAlert, placeDetail) => {
    await this.props.reloadPdf();
    if (typeAlert === 1) {
      var itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      let tempItem = searchTree(
        itineraries[current].itinerary_detail,
        this.props.params.idItineraryDetail
      )[0];
      this.setState({
        items: tempItem.place_detail,
      });
      Toast.fire({
        type: "success",
        title: "Remove place successfully",
      });
    } else if (typeAlert === 2) {
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      let tempItem = searchTree(
        itineraries[current].itinerary_detail,
        this.props.params.idItineraryDetail
      )[0];
      this.setState({
        items: tempItem.place_detail,
      });
      // Toast.fire({
      //   type: "success",
      //   title: "Set transportation of place successfully",
      // });
    } else if (typeAlert === 3) {
      const newPlaceDetail = [
        {
          belong_to_itinerary_detail: placeDetail.belong_to_itinerary_detail,
          order: placeDetail.order,
          _id: placeDetail._id,
          category: placeDetail.category,
          data: placeDetail.data,
          transportation: placeDetail.transportation,
        },
      ];
      this.setState(
        {
          items: this.state.items.concat(newPlaceDetail),
        },
        () => {
          var current_itinerary = loadItem("current_itinerary");
          let tempItem = searchTree(
            current_itinerary.itinerary_detail,
            this.props.params.idItineraryDetail
          )[0];
          tempItem.place_detail = this.state.items;
          saveItem("current_itinerary", current_itinerary);
          this.setState({
            order: this.state.items.length + 1,
          });
          Toast.fire({
            type: "success",
            title: "Add new place successfully",
          });
        }
      );
    } else if (typeAlert === 4) {
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      let tempItem = searchTree(
        itineraries[current].itinerary_detail,
        this.props.params.idItineraryDetail
      )[0];
      this.setState({
        items: tempItem.place_detail,
      });
      Toast.fire({
        type: "success",
        title: "Edit place successfully",
      });
    } else if (typeAlert === 5) {
      if (!placeDetail.position) placeDetail.position = 0;
      const position = placeDetail.position;
      this.setState({
        items: insert(this.state.items, position, placeDetail.new_place_detail),
      });
      Toast.fire({
        type: "success",
        title: "Add new place successfully",
      });
    } else if (typeAlert === 6) {
      this.setState({
        items: placeDetail,
      });
    } else if (typeAlert === 7) {
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      let tempItem = searchTree(
        itineraries[current].itinerary_detail,
        this.props.params.idItineraryDetail
      )[0];
      this.setState({
        items: tempItem.place_detail,
      });
      Toast.fire({
        type: "success",
        title: "Event moved",
      });
    } else if (typeAlert === 8) {
      Toast.fire({
        type: "success",
        title: "Event moved",
      });
    } else if (typeAlert === 9) {
      let itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });
      let tempItem = searchTree(
        itineraries[current].itinerary_detail,
        this.props.params.idItineraryDetail
      )[0];
      this.setState({
        items: tempItem.place_detail,
      });
      Toast.fire({
        type: "success",
        title: "Add new place successfully",
      });
    }
  };

  updateListMyPlaces = (type, placeDetail) => {
    if (type === 1) {
      let temp = [...this.state.selected];
      temp.push(placeDetail);
      this.setState(
        {
          selected: temp,
        },
        () => {
          Toast.fire({
            type: "success",
            title: "Save to favorite places successfully",
          });
        }
      );
    } else if (type === 2) {
      let temp = [...this.state.selected];
      let index = temp.findIndex((item) => {
        return item._id === placeDetail;
      });
      temp.splice(index, 1);
      this.setState(
        {
          selected: temp,
        },
        () => {
          Toast.fire({
            type: "success",
            title: "Remove place in favorite places successfully",
          });
        }
      );
    }
  };

  updateItem = (newItem) => {
    this.setState({
      item: newItem,
    });
  };

  hideEditDayTitleChangeDate = (newItieraryDetail) => {
    if (newItieraryDetail === 2) {
      this.setState({
        editDayTitleChangeDate: false,
      });
    } else if (newItieraryDetail === 1) {
      this.setState(
        {
          editDayTitleChangeDate: false,
        },
        () => {
          this.props.updateListItineraryDetail("deleteItineraryDetail");
        }
      );
    } else if (newItieraryDetail !== 1) {
      var itineraries;
      itineraries = loadItem("itineraries");
      let current = itineraries.findIndex((item) => {
        return item._id === this.props.params.idItinerary;
      });

      let tempItem = searchTree(
        itineraries[current].itinerary_detail,
        this.props.params.idItineraryDetail
      )[0];

      this.setState(
        {
          editDayTitleChangeDate: false,
          title: newItieraryDetail.title,
          description: newItieraryDetail.description,
          file_list_trix_delete: newItieraryDetail.file_list_trix_delete,
          lodging: tempItem.lodging,
          meal: newItieraryDetail.meal,
          amountMeals: tempItem.amountMeals,
          monthDayYear: newItieraryDetail.month_day_year
            ? new Date(newItieraryDetail.month_day_year * 1000)
            : null,
          day: newItieraryDetail.month_day_year
            ? format(new Date(newItieraryDetail.month_day_year * 1000), "d")
            : null,
          monthYear: newItieraryDetail.month_day_year
            ? format(
                new Date(newItieraryDetail.month_day_year * 1000),
                "MMM d, yyyy"
              )
            : null,
          index: tempItem.index,
        },
        () => {
          this.props.updateListItineraryDetail("editDayTitleChangeDate");
        }
      );
    }
  };

  handleSearchInMyPlaces = (e) => {
    this.setState({
      searchName: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
  };

  onCloseWarning = () => {
    this.setState({
      isWarning: false,
    });
  };

  handleReloadNewVer = () => {
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });
    var listDatesNew = getAllLeaf(this.state.newVerItinerary.itinerary_detail);
    itineraries[current] = this.state.newVerItinerary;

    var checkDateCurrentExistAfterSave = listDatesNew.some((el) => {
      return el._id === this.props.params.idItineraryDetail;
    });
    saveItem("itineraries", itineraries);
    window.onbeforeunload = null;

    if (checkDateCurrentExistAfterSave) {
      window.location = `/itinerary/${this.props.params.idItinerary}/${this.props.params.idItineraryDetail}`;
    } else {
      window.location = `/itinerary/${this.props.params.idItinerary}/${listDatesNew[0]._id}`;
    }
  };

  onCloseWarningNewVersion = () => {
    this.setState({
      showWarningHaveNewVersion: false,
    });
  };

  handleOpenFormSaveItinerary = () => {
    if (!this.props.profile) {
      if (this.state.isBelongToMe) this.props.saveAfterLogin(1);
      else this.props.saveAfterLogin(2);

      this.setState({
        showFormLogin: true,
      });
      return;
    }
    if (this.state.isBelongToMe) {
      this.handleSaveItinerary();
      return;
    }
    this.setState({
      openFormSaveItinerary: true,
    });
    this.props.saveAfterLogin(0);
  };

  handleRedirectToBilling = () => {
    if (!this.props.profile) {
      this.setState({
        showFormLogin: true,
      });
      return;
    }
    this.props.redirectToBillingAfterLogin(0);
    this.props.history.push("/billing");
  };

  closeFormSaveItinerary = () => {
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });

    if (this.state.isLoadingSave) return;
    this.setState({
      openFormSaveItinerary: false,
      name: itineraries[current].name,
    });
  };

  closeFormLogin = () => {
    this.props.redirectToBillingAfterLogin(0);
    this.setState({
      showFormLogin: false,
    });
  };

  changeItineraryName = (event) => {
    this.setState({
      ...this.state.current_itinerary,
      name: event.target.value,
    });
  };

  handleSaveItinerary = async () => {
    this.props.saveAfterLogin(0);
    if (!this.state.isAuthenticated) {
      this.setState({
        openFormSaveItinerary: false,
        showFormLogin: true,
      });
      return;
    }

    if (this.state.name.length > maxCharacters) return;

    this.setState({
      isLoadingSave: true,
    });
    let itineraries;
    itineraries = loadItem("itineraries");
    let current = itineraries.findIndex((item) => {
      return item._id === this.props.params.idItinerary;
    });
    if (!this.state.isBelongToMe) {
      moveOutDrafts(itineraries[current]._id);
      // if (!itineraries[current].reference)
      itineraries[current].reference = itineraries[current]._id;
      itineraries[current].last_updated = new Date();
      itineraries[current].created_at = new Date();
      itineraries[current].belong_to_user = this.props.profile._id;
      itineraries[current].belong_to_folder = 0;
      itineraries[current].written_by = this.props.profile.fullName;
      itineraries[current].name = this.state.name;
      itineraries[current].saved = 1;
      itineraries[current]._id = new ObjectID().toHexString();
      if (
        itineraries[current].options &&
        itineraries[current].options.length > 0
      ) {
        itineraries[current].options.forEach((item) => {
          item.belong_to_itinerary = itineraries[current]._id;
        });
      }
      itineraries[current].itinerary_detail.forEach((item) => {
        item._id = new ObjectID().toHexString();
        item.belong_to_itinerary = itineraries[current]._id;
        item.children.forEach((country) => {
          country.children.forEach((eachDate) => {
            eachDate._id = new ObjectID().toHexString();
            eachDate.place_detail.forEach((childItem) => {
              childItem.belong_to_itinerary_detail = eachDate._id;
              if (!childItem.isNew) {
                childItem.belong_to_attraction = childItem._id;
              }
              childItem._id = new ObjectID().toHexString();
            });
          });
        });
      });

      let arrDates = getAllLeaf(itineraries[current].itinerary_detail);
      itineraries[current].end_dates =
        arrDates[arrDates.length - 1].month_day_year;
      // let placesShared = [];
      // getAllLeaf(itineraries[current].itinerary_detail).forEach((item) => {
      //   item.place_detail.forEach((child) => {
      //     if (child.hasOwnProperty("needChecked"))
      //       placesShared.push({
      //         ...child,
      //         alpha2Code: item.alpha2Code,
      //         country: item.country,
      //       });
      //   });
      // });

      getAllLeaf(itineraries[current].itinerary_detail).forEach((root) => {
        root.place_detail.forEach((child) => {
          delete child.belong_to_library;
          delete child.belong_to_library_place;
          if (child.data.actual_data.images) {
            child.data.actual_data.images.forEach((item) => {
              if (isMyResource(item.thumbUrl)) {
                item.thumbUrl = item.thumbUrl.replace(
                  "storage/uploads/",
                  "images/galleries/image-save-as-"
                );
                item.xhr = item.xhr.replace(
                  "storage/uploads/",
                  "images/galleries/image-save-as-"
                );
                item.preview = item.preview.replace(
                  "storage/uploads/",
                  "images/galleries/image-save-as-"
                );
              }
            });
          }
        });
      });

      itineraries[current] = convertAdvanceFieldToNormal(
        itineraries[current],
        this.props.profile
      );
      saveItem("itineraries", itineraries);
      // await this.apiService
      //   .addPlacesShared(placesShared, this.props.profile._id)
      //   .then((res) => {});
      moveOutDrafts(itineraries[current]._id);
      window.onbeforeunload = null;
      await this.apiService
        .saveAsItinerary(itineraries[current], this.props.profile)
        .then((res) => {
          if (res.status === "success") {
            this.setState(
              {
                isLoadingSave: false,
                openFormSaveItinerary: false,
              },
              () => {
                getAllLeaf(itineraries[current].itinerary_detail).forEach(
                  (item) => {
                    item.place_detail.forEach((child) => {
                      delete child.isNew;
                    });
                  }
                );
                saveItem("itineraries", itineraries);
                window.location =
                  `/itinerary/` +
                  itineraries[current]._id +
                  `/` +
                  itineraries[current].itinerary_detail[0].children[0]
                    .children[0]._id;
              }
            );
          }
        });
    } else {
      itineraries[current].name = this.state.name;
      itineraries[current].belong_to_user = this.props.profile._id;
      itineraries[
        current
      ].written_by = `${this.props.profile.firstName} ${this.props.profile.lastName}`;
      itineraries[current].last_updated = new Date();
      itineraries[current].saved = 1;
      this.props.changeStatusSaved();
      if (itineraries[current].cover)
        itineraries[current].cover = itineraries[current].cover.replace(
          "storage/uploads",
          "images/cover"
        );
      this.setState({ itinerary: itineraries[current] });
      itineraries[current] = convertAdvanceFieldToNormal(
        itineraries[current],
        this.props.profile
      );
      saveItem("itineraries", itineraries);
      this.props.socket.emit("save_change", {
        idItinerary: this.props.params.idItinerary,
        itinerary: itineraries[current],
      });
      getAllLeaf(itineraries[current].itinerary_detail).forEach((root) => {
        root.place_detail.forEach((child) => {
          delete child.isNew;
          if (child.data.actual_data.images) {
            child.data.actual_data.images.forEach((item) => {
              if (isMyResource(item.thumbUrl)) {
                item.thumbUrl = item.thumbUrl.replace(
                  "storage\\uploads",
                  "images/galleries"
                );
                item.xhr = item.xhr.replace(
                  "storage\\uploads",
                  "images/galleries"
                );
                item.preview = item.preview.replace(
                  "storage\\uploads",
                  "images/galleries"
                );
              }
            });
          }
        });
      });
      saveItem("itineraries", itineraries);
      moveOutDrafts(itineraries[current]._id);
      this.apiService
        .saveItinerary(itineraries[current], this.props.profile)
        .then((res) => {
          if (res.status === "success")
            this.setState(
              {
                isLoadingSave: false,
                openFormSaveItinerary: false,
              },
              () => {
                Toast.fire({
                  type: "success",
                  title: "Save itinerary successfully",
                });
              }
            );
        });
    }
  };

  handleChangeIndex = (index) => {
    this.setState({
      value: index,
    });
  };

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  handleCheck = (e) => {
    this.setState({
      checkboxRemove: e.target.checked,
    });
  };

  handleOpenFormAddNewStop = () => {
    this.props.openFormAddNewStop();
  };

  handleOpenFormShare = () => {
    this.props.openFormShareByEmail();
  };

  handleDiscuss = () => {
    window.open(
      `/preview-itinerary/${this.props.params.idItinerary}`,
      "_blank"
    );
  };

  handleLinkToMyTyneries = () => {
    history.push("/my-tyneries");
  };

  closeLoad = () => {
    this.props.closeFormLoad();
  };

  showLoad = () => {
    this.props.openFormLoad();
  };

  handleShowFormEditItinerary = () => {
    this.setState({
      showFormEditItinerary: true,
    });
  };

  handleCloseFormEditItinerary = () => {
    this.setState({
      showFormEditItinerary: false,
    });
  };

  handleExpandCollapse = (flag) => {
    this.setState({
      expand: flag,
    });
  };

  handleCloseFormEditDate = () => {
    this.setState({
      editDayTitleChangeDate: false,
    });
  };

  handleSaveToFolder = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleChooseFolder = (e) => {
    e.preventDefault();
    if (!this.state.myFolders.length) {
      this.setState({
        visible: false,
      });
      return;
    }
    this.setState({
      loadingChooseFolder: true,
    });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values = {
          ...values,
          _id: this.state.itinerary._id,
        };
        this.apiService.chooseFolder(values).then(async (res) => {
          if (res.status === "success") {
            let itineraries;
            itineraries = loadItem("itineraries");
            let current = itineraries.findIndex((item) => {
              return item._id === this.props.params.idItinerary;
            });
            itineraries[current].belong_to_folder = values.folder;
            saveItem("itineraries", itineraries);
            this.setState({
              loadingChooseFolder: false,
              createFolderMode: false,
              visible: false,
            });
            await this.apiService.nameFolder(values).then((result) => {
              if (result.status === "success") {
                Swal.fire({
                  type: "success",
                  title: `Saved to "${result.folder.name}"`,
                  showConfirmButton: true,
                });
              } else {
                Swal.fire({
                  type: "success",
                  title: `Saved to "Others"`,
                  showConfirmButton: true,
                });
              }
            });
          }
        });
      } else {
        this.setState({
          loadingChooseFolder: false,
        });
      }
    });
  };

  showCreateFolder = () => {
    this.setState({ createFolderMode: true });
  };

  hideCreateFolder = () => {
    this.setState({ createFolderMode: false });
  };

  handleCreateFolder = (e) => {
    e.preventDefault();
    this.setState({
      loadingCreateFolder: true,
    });
    this.props.form.validateFields(["name"], (err, values) => {
      if (!err) {
        values = {
          ...values,
          _id: this.state.itinerary._id,
        };
        this.apiService.createFolder(values).then((res) => {
          if (res.status === "success") {
            let itineraries;
            itineraries = loadItem("itineraries");
            let current = itineraries.findIndex((item) => {
              return item._id === this.props.params.idItinerary;
            });
            itineraries[current].belong_to_folder = res.folder._id;
            saveItem("itineraries", itineraries);
            this.setState({
              loadingCreateFolder: false,
              createFolderMode: false,
              visible: false,
              myFolders: [...this.state.myFolders, res.folder],
            });
            this.props.form.setFieldsValue({
              folder: res.folder._id,
            });
            Swal.fire({
              type: "success",
              title: `Saved to "${values.name}"`,
              showConfirmButton: true,
            });
          }
        });
      } else {
        this.setState({
          loadingCreateFolder: false,
        });
      }
    });
  };

  showOrderCountry = () => {
    this.setState({
      showOrder: true,
      showOrderCity: false,
    });
  };

  hideOrderCountry = () => {
    this.setState({
      showOrder: false,
    });
  };

  showOrderCity = () => {
    this.setState({
      showOrderCity: true,
    });
  };

  hideOrderCity = () => {
    this.setState({
      showOrderCity: false,
    });
  };

  rerenderParentCallback = () => {
    this.setState({
      showFormAddCountry: false,
      showFormEditItinerary: false,
    });
    this.componentDidMount();
    this.child.componentDidMount();
  };

  handleOpenViewPDF = async () => {
    let response = await this.apiService.convertItineraryToPdf(
      this.props.params.idItinerary
    );
    if (response.status === "success") {
      this.setState({
        quotation: response.data,
        showPDF: true,
      });
    }
  };

  closeViewPDF = () => {
    this.setState({
      showPDF: false,
    });
  };

  render() {
    var { selected2 } = this.props;
    const { getFieldDecorator } = this.props.form;

    const { listItineraries } = this.state;

    let filteredListMyPlaces = [];
    if (this.state.searchName) {
      this.state.selected.forEach((item) => {
        if (
          item.data.actual_data.title
            .toLowerCase()
            .search(this.state.searchName.toLowerCase()) >= 0
        ) {
          filteredListMyPlaces.push(item);
        }
      });
    } else filteredListMyPlaces = this.state.selected;

    return (
      <div id="content" className={styles.contentDetail}>
        <ItineraryPdf showPDF={this.state.showPDF} closeViewPDF={this.closeViewPDF} quotation={this.state.quotation} />
        <FormShareByEmail params={this.props.params} />
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={this.state.openFormSaveItinerary ? true : false}
          onClose={this.closeFormSaveItinerary.bind(this)}
          aria-labelledby="form-save-itinerary"
        >
          {!this.state.isBelongToMe ? (
            <DialogContent className={styles.contentDetail}>
              <Row>
                <Col xs={12}>
                  <small className="warning-limit">
                    <WarningFilled /> Choose a name for your new Tyneri (limit
                    to{" "}
                    <span>
                      {this.state.name.length}/{maxCharacters}
                    </span>{" "}
                    characters)
                  </small>
                </Col>
                <Col xs={12}>
                  <Input
                    autoFocus
                    value={this.state.name}
                    type="text"
                    onChange={this.changeItineraryName.bind(this)}
                    name="name"
                    placeholder={
                      "Trying using the included destinations, i.e. Beijing-Thailand-Hongkong"
                    }
                  />
                  {this.state.name.length > maxCharacters ? (
                    <FormFeedback invalid="true" className="d-block">
                      Exceed {maxCharacters} characters
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </DialogContent>
          ) : (
            <DialogContent style={{ textAlign: "center" }}>
              Save tyneri
            </DialogContent>
          )}
          <DialogActions className={styles.contentDetail}>
            <ButtonMaterial
              disabled={this.state.isLoadingSave ? true : false}
              id="send-ButtonMaterial"
              className="btn-primary"
              onClick={this.handleSaveItinerary.bind(this)}
            >
              {this.state.isLoadingSave ? (
                <CircularProgress size={24} className="processing" />
              ) : (
                "OK"
              )}
            </ButtonMaterial>
            <ButtonMaterial
              disabled={this.state.isLoadingSave ? true : false}
              variant="contained"
              onClick={this.closeFormSaveItinerary.bind(this)}
            >
              Cancel
            </ButtonMaterial>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showFormLogin ? true : false}
          onClose={this.closeFormLogin.bind(this)}
          fullWidth={true}
          maxWidth={"xs"}
          aria-labelledby="form-login-social-media content"
        >
          <FormSignInUp closeForm={this.closeFormLogin} />
        </Dialog>

        <Dialog
          open={this.props.showLoad ? true : false}
          fullWidth={true}
          maxWidth={"md"}
          aria-labelledby="form-load"
        >
          <MuiDialogTitle disableTypography className="popup-title">
            <Typography variant="h6">My Library</Typography>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={this.closeLoad.bind(this)}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <ListMyTyneries
            params={this.props.params}
            categories={this.state.categories}
          />
        </Dialog>

        <Dialog
          open={this.state.showFormEditItinerary ? true : false}
          fullWidth={true}
          maxWidth={"md"}
          aria-labelledby="edit-itinerary-form"
          className={styles.contentDetail + " display-unset"}
        >
          <MuiDialogTitle disableTypography className="popup-title">
            <Typography variant="h6">{this.state.name}</Typography>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={this.handleCloseFormEditItinerary.bind(this)}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <EditItineraryForm
            rerenderParentCallback={this.rerenderParentCallback}
            params={this.props.params}
          />
        </Dialog>

        <Dialog
          open={this.state.showFormAddCountry ? true : false}
          fullWidth={true}
          maxWidth={"md"}
          aria-labelledby="form-add-country"
          className={styles.contentDetail + " display-unset"}
        >
          <MuiDialogTitle disableTypography className="popup-title">
            <Typography variant="h6">Add country</Typography>
            <IconButton
              aria-label="close"
              className="btn-close"
              onClick={() => {
                this.setState({ showFormAddCountry: false });
              }}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <FormAddCountry
            rerenderParentCallback={this.rerenderParentCallback}
            params={this.props.params}
          />
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.isWarning ? true : false}
          autoHideDuration={6000}
          onClose={this.onCloseWarning.bind(this)}
        >
          <SnackbarContent
            style={{ backgroundColor: "#ffa000" }}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar">
                <WarningIcon />
                You dragged this event out of chronological order, so we removed
                the time.
                <br />
                You can specify a new time by hitting the edit ButtonMaterial
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={this.onCloseWarning.bind(this)}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>

        {this.state.showWarningHaveNewVersion && (
          <audio className="audio-element">
            <source src={notiMusic}></source>
          </audio>
        )}

        <Dialog open={this.state.showWarningHaveNewVersion ? true : false}>
          <DialogContent style={{ textAlign: "center", fontSize: "1.2rem" }}>
            <div>
              <div>
                <div style={{ display: `flex` }}>
                  <div style={{ flex: `0 0 10%`, alignSelf: `center` }}>
                    <WarningIcon
                      style={{
                        fill: `#EEB711`,
                        width: "unset",
                        height: `unset`,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: `1.2rem`,
                      padding: `0.5rem`,
                      textAlign: "left",
                    }}
                  >
                    <span style={{ fontWeight: `bold` }}>
                      {this.state.newVerItinerary.written_by}
                    </span>{" "}
                    just ADDED new information to this Tyneri at{" "}
                    {this.state.newVerItinerary.last_updated
                      ? format(
                          new Date(this.state.newVerItinerary.last_updated),
                          "HH:mm:ss MMM d, yyyy "
                        )
                      : ""}
                  </div>
                </div>
              </div>
              <div style={{ fontSize: `1rem`, color: `#959595` }}>
                Would you like to see the updated version?
              </div>
            </div>
          </DialogContent>
          <DialogActions
            className={styles.contentDetail}
            style={{ borderTop: "1px solid #DDDDDD" }}
          >
            <ButtonMaterial
              variant="outlined"
              onClick={this.onCloseWarningNewVersion.bind(this)}
            >
              Skip
            </ButtonMaterial>
            <ButtonMaterial
              id="update-now-ButtonMaterial"
              variant="contained"
              onClick={this.handleReloadNewVer.bind(this)}
            >
              UPDATE NOW
            </ButtonMaterial>
          </DialogActions>
        </Dialog>

        <FormQuote
          params={this.props.params}
          newList={this.updateListSelected}
        />

        <Modal
          title="Move to"
          visible={this.state.visible}
          maskClosable={false}
          onCancel={this.handleCancel}
          footer={[
            !this.state.createFolderMode ? (
              <div className={stylesGlobal.sectionFooterSaveToFolder}>
                <div
                  style={{
                    cursor: "pointer",
                    borderBottom: `1px solid #e8e8e8`,
                    paddingBottom: `12px`,
                    width: `100%`,
                    padding: `12px 24px`,
                  }}
                  onClick={this.showCreateFolder}
                >
                  <Avatar
                    shape="square"
                    size="large"
                    style={{
                      color: "#fff",
                      backgroundColor: "#ccc",
                    }}
                  >
                    +
                  </Avatar>{" "}
                  New folder
                </div>
                <div
                  style={{
                    paddingBottom: `12px`,
                    width: `100%`,
                    padding: `12px 24px`,
                    textAlign: "right",
                  }}
                >
                  <Button
                    onClick={this.handleChooseFolder}
                    key="submit"
                    type="primary"
                    className="btn-choose-folder"
                  >
                    Move
                  </Button>
                </div>
              </div>
            ) : (
              <div className={stylesGlobal.sectionFooterSaveToFolder}>
                <div
                  style={{
                    cursor: "pointer",
                    borderBottom: `1px solid #e8e8e8`,
                    paddingBottom: `12px`,
                    width: `100%`,
                    padding: `12px 24px`,
                  }}
                >
                  <Form onSubmit={this.handleCreateFolder}>
                    <Form.Item label="Folder name">
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your folder name!",
                          },
                        ],
                      })(<Input autoFocus placeholder="Folder name" />)}
                    </Form.Item>
                  </Form>
                </div>
                <div
                  style={{
                    paddingBottom: `12px`,
                    width: `100%`,
                    padding: `12px 24px`,
                    textAlign: "right",
                  }}
                >
                  <Button onClick={this.hideCreateFolder} key="back">
                    Cancel
                  </Button>
                  <Button
                    key="submit"
                    loading={this.state.loadingCreateFolder}
                    onClick={this.handleCreateFolder}
                    type="primary"
                  >
                    Create
                  </Button>
                </div>
              </div>
            ),
          ]}
        >
          <Form onSubmit={this.handleChooseFolder}>
            {!!this.state.myFolders.length && (
              <Form.Item name="radio-group">
                {getFieldDecorator("folder", {
                  rules: [
                    {
                      required: true,
                      message: "Please choose folder!",
                    },
                  ],
                })(
                  <Radio.Group>
                    {this.state.myFolders.map((item, key) => (
                      <>
                        <Radio value={item._id} key={key}>
                          <Avatar
                            shape="square"
                            size="large"
                            style={{
                              color: "#f56a00",
                              backgroundColor: "#fde3cf",
                            }}
                          >
                            {item.name.charAt(0).toUpperCase()}
                          </Avatar>{" "}
                          {item.name}
                        </Radio>
                      </>
                    ))}
                  </Radio.Group>
                )}
              </Form.Item>
            )}
          </Form>
        </Modal>

        <FormViewEditPlace
          params={this.props.params}
          updateListMyPlaces={this.updateListMyPlaces}
          newList={this.updateListSelected}
          item={this.state.item}
          items={this.state.items}
          itemsInListMyPlaces={this.state.selected}
        />
        <FormViewEditTransportation params={this.props.params} />
        <div className="container">
          <Row id="fixed-height">
            <Col id="left-column" xs="12" sm={5} md={5} lg="3" xl="3">
              <div id="summary-infor" style={{ background: `#f7f7f7` }}>
                <div
                  style={
                    this.state.cover
                      ? {
                          backgroundImage: `url("${configBaseUrl.baseURL}${this.state.cover}")`,
                        }
                      : {
                          backgroundImage: `url("${NoPhoto}")`,
                        }
                  }
                  className="cover-image"
                >
                  <div onClick={this.handleShowFormEditItinerary.bind(this)}>
                    <span>
                      <TiCamera />
                    </span>
                  </div>
                </div>
                {this.state.budget < this.props.total_amount ? (
                  <div className="alert-buget-over">
                    <Alert
                      message="Your expenses on itinerary is over budget"
                      icon={<WarningFilled />}
                      type="error"
                      showIcon
                    />
                  </div>
                ) : null}
                <div className="tyneri-title">
                  {this.state.name}
                  {this.state.estimated_budget}
                  {this.state.a_number_of_paxs}
                </div>
                {this.state.itinerary && this.state.itinerary.belong_to_user && (
                  <div>
                    <Row>
                      <Col lg="12" xl="12">
                        {this.state.itinerary && (
                          <div className="written-by">
                            {this.state.itinerary.created_at &&
                              moment(
                                new Date(this.state.itinerary.created_at)
                              ).fromNow() + " - "}
                            {this.state.itinerary.reference ? (
                              <>
                                By{" "}
                                <span className="author-name">
                                  {this.state.itinerary.belong_to_user
                                    ? this.state.itinerary.written_by
                                    : "Tyneri"}
                                </span>
                              </>
                            ) : (
                              <>
                                By{" "}
                                <span className="author-name">
                                  {this.state.itinerary.belong_to_user
                                    ? this.state.itinerary.written_by
                                    : "Tyneri"}
                                </span>
                              </>
                            )}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
                <div className="row-menu">
                  <div>
                    <div
                      className="each-menu"
                      onClick={this.handleDiscuss.bind(this)}
                    >
                      <Icon type="file-search" />
                      <span>Preview</span>
                    </div>
                    <div
                      className="each-menu"
                      onClick={this.handleOpenFormShare.bind(this)}
                    >
                      <Icon type="share-alt" />
                      <span>Share</span>
                    </div>
                    <div
                      className="each-menu"
                      onClick={this.handleOpenViewPDF.bind(this)}
                    >
                      <Icon type="file-pdf" />
                      <span>PDF</span>
                    </div>
                    <div
                      className="each-menu"
                      onClick={() => {
                        this.setState({ showFormAddCountry: true });
                      }}
                    >
                      <Icon type="plus" />
                      <span>Add country</span>
                    </div>
                    <div className="each-menu" onClick={this.showOrderCountry}>
                      <Icon type="sort-ascending" />
                      <span>Re-order</span>
                    </div>
                    <div
                      className="each-menu"
                      onClick={this.handleShowFormEditItinerary.bind(this)}
                    >
                      <Icon type="edit" />
                      <span>Edit</span>
                    </div>
                  </div>
                </div>
              </div>
              <Row id="itinerary-detail">
                <Col xs="12">
                  <ItineraryDetails
                    onRef={(ref) => (this.child = ref)}
                    handleShowOrderCountry={this.state.showOrder}
                    reRenderContenDetail={this.reRenderContenDetail}
                    params={this.props.params}
                    location={this.props.location}
                    hideEditDayTitleChangeDate={this.hideEditDayTitleChangeDate}
                    hideOrderCountry={this.hideOrderCountry}
                    showOrderCity={this.showOrderCity}
                    hideOrderCity={this.hideOrderCity}
                  />
                </Col>
              </Row>
              {!this.state.showOrder && !this.state.showOrderCity && (
                <Row id="save-tinery" className="text-center">
                  <Col xs="12" style={{ padding: 0 }}>
                    {this.state.isLoadingSave ? (
                      <ButtonMaterial
                        variant="contained"
                        disabled
                        fullWidth
                        className="btn-loading"
                      >
                        <CircularProgress
                          className="circle-loading"
                          size={20}
                        />
                        SAVING...
                      </ButtonMaterial>
                    ) : (
                      <>
                        <ButtonGroup
                          variant="contained"
                          color="primary"
                          size="small"
                          className="secondary-action"
                          fullWidth
                          aria-label="small outlined ButtonMaterial group"
                        >
                          <ButtonMaterial
                            className="save-itinerary"
                            fullWidth
                            onClick={this.handleOpenFormSaveItinerary.bind(
                              this
                            )}
                          >
                            {this.state.isBelongToMe && this.props.saved ? (
                              <>
                                <SaveAltIcon /> &nbsp;UPDATE CHANGES
                              </>
                            ) : (
                              <>
                                <SaveAltIcon /> &nbsp;SAVE TO MY LIBRARY
                              </>
                            )}
                          </ButtonMaterial>
                          {this.state.isBelongToMe && this.props.saved && (
                            <ButtonMaterial
                              className="btn-save-to-folder"
                              onClick={this.handleSaveToFolder}
                            >
                              <div
                                style={{ color: "white" }}
                                className="section-btn-move-to"
                              >
                                <CreateNewFolderOutlinedIcon />
                                <span>&nbsp;MOVE TO</span>
                              </div>
                            </ButtonMaterial>
                          )}
                        </ButtonGroup>
                      </>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Col
                id="center-column"
                md={7}
                xs="12"
                lg="6"
                sm={7}
                xl="6"
                style={{
                  padding: "0 15px",
                  display: "inline-block",
                }}
              >
                <Dialog
                  open={this.state.editDayTitleChangeDate ? true : false}
                  fullWidth={true}
                  maxWidth={"md"}
                  aria-labelledby="edit-day-infor"
                  className={styles.contentDetail + " display-unset"}
                >
                  <MuiDialogTitle disableTypography className="popup-title">
                    <Typography variant="h6">
                      {this.state.title
                        ? this.state.title
                        : "Provide a description for this day"}
                    </Typography>
                    <IconButton
                      aria-label="close"
                      className="btn-close"
                      onClick={this.handleCloseFormEditDate.bind(this)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </MuiDialogTitle>

                  <EditDayTitleChangeDate
                    monthDayYear={this.state.monthDayYear}
                    title={this.state.title}
                    day={this.state.day}
                    monthYear={this.state.monthYear}
                    length={this.state.lengthItineraryDetail}
                    params={this.props.params}
                    hideEditDayTitleChangeDate={this.hideEditDayTitleChangeDate}
                  />
                </Dialog>
                <AddNewStopForm
                  order={this.state.order}
                  length={this.state.items.length}
                  params={this.props.params}
                  newList={this.updateListSelected}
                  items={this.state.items}
                />
                <div id="date-info">
                  <ViewOption
                    expand={this.state.expand}
                    items={this.state.items}
                    listDates={this.state.listDates}
                    handleExpandCollapse={this.handleExpandCollapse}
                  />
                  <div className="trip-day-header-labels">
                    <div
                      className="trip-day-header-labels-inner t-editable"
                      onClick={this.handleEnableEditTitleChangeDay.bind(this)}
                      data-ember-action="2483"
                    >
                      {/* {this.state.monthYear && (
                            <div className="trip-day-header-date">
                              <div className="trip-day-header-date-month">
                                {this.state.monthYear}
                              </div>
                            </div>
                          )} */}
                      <h1 className="trip-day-header-title">
                        <Badge
                          style={{
                            padding: `0.3rem`,
                            fontSize: `80%`,
                            marginRight: "0.5rem",
                          }}
                          color="primary"
                        >
                          Day {this.state.index + 1}
                        </Badge>
                        <span>
                          {this.state.title
                            ? this.state.title
                            : "Provide a description for this day"}
                        </span>
                        <figure className="_has-date">
                          <i className="fas fa-pencil-alt"></i>
                        </figure>
                      </h1>
                    </div>
                  </div>
                </div>
                {!(
                  !this.props.profile ||
                  (this.props.profile && this.props.profile.accountType < 2)
                ) ? (
                  <ItineraryDetailOption
                    lodging={this.state.lodging}
                    amountMeals={this.state.amountMeals}
                    meal={this.state.meal}
                  />
                ) : null}

                {this.state.description && (
                  <ShowMoreText
                    lines={3}
                    more="more"
                    less="less"
                    anchorClass=""
                    expanded={false}
                  >
                    <div className="overview-itinerary-detail show-more-overview">
                      <div className="trix-content-temp">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </ShowMoreText>
                )}

                {/* {this.state.description &&
                  this.state.items &&
                  !!this.state.items.length && (
                    <Row>
                      <Col xs="12">
                        <div className="break-section">
                          Day {this.state.index + 1} itinerary
                        </div>
                      </Col>
                    </Row>
                  )} */}

                <Timeline
                  lineStyle={
                    this.state.items.length === 0
                      ? { visibility: "hidden" }
                      : {
                          top: "2.5rem",
                          marginLeft: "7.6rem",
                          height: "calc(100% - 11rem)",
                        }
                  }
                  lineColor={`#e1eef5`}
                  className="vertical-straight-list-selected"
                >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        id="droppable"
                        className={
                          this.state.items.length === 0 ? "tutorial" : ""
                        }
                        ref={provided.innerRef}
                        style={getListStyle(
                          snapshot.isDraggingOver,
                          this.state.items.length
                        )}
                      >
                        {this.state.items.length === 0 && (
                          <span style={{ fontSize: "1.5rem" }}>
                            Where to build your detailed itinerary
                          </span>
                        )}
                        {!!this.state.items.length &&
                          this.state.items.map((item, index) => (
                            <Draggable
                              key={item._id}
                              draggableId={`${item._id}-${index}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  id={`${item._id}-${index}`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  className="places-hover"
                                >
                                  <PlacesInListSelected
                                    changeStatusSaved={
                                      this.props.changeStatusSaved
                                    }
                                    index={index}
                                    length={this.state.items.length}
                                    params={this.props.params}
                                    newList={this.updateListSelected}
                                    updateItem={this.updateItem}
                                    updateMyPlaces={this.updateListMyPlaces}
                                    item={item}
                                    items={this.state.items}
                                    itemsInListMyPlaces={this.state.selected}
                                    expand={this.state.expand}
                                    categories={this.state.categories}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Timeline>
                <Row
                  className="row-add"
                  style={{ padding: `0.5rem 0`, alignItems: "center" }}
                >
                  <Col xs="12" md="12" lg="12" xl="12">
                    <div style={{ display: "flex", float: "right" }}>
                      <Tooltip title="Manually add a stop">
                        <Fab
                          className="btn-add"
                          onClick={this.handleOpenFormAddNewStop.bind(this)}
                        >
                          <AddIcon />
                        </Fab>
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md={12} lg="3" xl="3" id="sidebar">
                <Row id="detail-tab-select">
                  {this.state.numTab === 2 ? (
                    <div id="select-my-places">
                      <Form
                        id="form-search"
                        onSubmit={this.onSubmit.bind(this)}
                      >
                        <FormGroup>
                          <Input
                            type="text"
                            value={this.state.searchName}
                            name="searchMyPlaces"
                            id="exampleEmail"
                            placeholder="Search in my places..."
                            onChange={this.handleSearchInMyPlaces.bind(this)}
                          />
                        </FormGroup>
                      </Form>
                      {!this.state.selected3.length &&
                        !this.state.isLoadingPlacesShared && (
                          <div className="t-flex _t-flex-col t-rem-padding t-rem-padding-top-3">
                            <div className="t-svg-xlarge t-primary t-margin-auto t-rem-padding-bottom-fourth t-text-center">
                              <MdPublic />
                            </div>
                            <div className="t-h3 t-text-center t-rem-padding-bottom-fourth">
                              Shared
                            </div>
                            <div className="t-text-center t-color-gray-dark t-rem-padding-bottom-fourth">
                              Nothing to show
                            </div>
                          </div>
                        )}
                      {this.state.isLoadingPlacesShared &&
                        loading.map((item, key) => (
                          <div className={styles.loading} key={key}>
                            <div className="sidebar-idea-container ember-view draggable-item zoom-place">
                              <div className="ember-view component-idea-tile idea-tile-horizontal _can-view-details">
                                <Skeleton
                                  variant="rect"
                                  width={80}
                                  height={85}
                                />
                                <ul className="_c-info">
                                  <React.Fragment>
                                    <Skeleton height={6} />
                                    <Skeleton height={6} width="80%" />
                                  </React.Fragment>
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))}
                      {!!this.state.selected3.length &&
                        !this.state.isLoadingPlacesShared && (
                          <div
                            style={{
                              padding: "0.5rem 1rem",
                              textAlign: "end",
                            }}
                          >
                            <Row>
                              <Col xs="12">
                                <Typography
                                  style={{ textAlign: "start" }}
                                  variant="h6"
                                  gutterBottom
                                >
                                  Shared places
                                </Typography>
                              </Col>
                            </Row>
                          </div>
                        )}
                      <Droppable droppableId="droppable4" isDropDisabled={true}>
                        {(provided, snapshot) => (
                          <div id="droppable4" ref={provided.innerRef}>
                            {this.state.selected3 &&
                              !!this.state.selected3.length &&
                              this.state.selected3.map((item, index) => (
                                <Draggable
                                  key={item._id}
                                  draggableId={item._id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <PlacesInShared
                                        isLoadingFavoritePlaces={
                                          this.state.isLoadingFavoritePlaces
                                        }
                                        updateListMyPlaces={
                                          this.updateListMyPlaces
                                        }
                                        removeMode={this.state.checkboxRemove}
                                        order={this.state.order}
                                        length={this.state.items.length}
                                        newList={this.updateListSelected}
                                        params={this.props.params}
                                        item={item}
                                        items={filteredListMyPlaces}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>

                      {!filteredListMyPlaces.length &&
                        !this.state.isLoadingFavoritePlaces && (
                          <div className="t-flex _t-flex-col t-rem-padding t-rem-padding-top-3">
                            <div className="t-svg-xlarge t-primary t-margin-auto t-rem-padding-bottom-fourth t-text-center">
                              <MdFavorite />
                            </div>
                            <div className="t-h3 t-text-center t-rem-padding-bottom-fourth">
                              My favorite
                            </div>
                            <div className="t-text-center t-color-gray-dark t-rem-padding-bottom-fourth">
                              {this.props.profile
                                ? `Add some your places.`
                                : `You need sign in to see your places`}
                            </div>
                          </div>
                        )}
                      {!!filteredListMyPlaces.length &&
                        !this.state.isLoadingFavoritePlaces && (
                          <div
                            style={{
                              padding: "0.5rem 1rem",
                              textAlign: "end",
                            }}
                          >
                            <Row>
                              <Col xs="12">
                                <Typography
                                  style={{ textAlign: "start" }}
                                  variant="h6"
                                  gutterBottom
                                >
                                  My favorite
                                </Typography>
                                <div className="checkbox-remove">
                                  <span>Select</span>
                                  <Checkbox
                                    checked={
                                      this.state.checkboxRemove ? true : false
                                    }
                                    onChange={this.handleCheck.bind(this)}
                                    className="checkbox"
                                    value="checkedA"
                                    inputProps={{
                                      "aria-label": "primary checkbox",
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      {this.state.isLoadingFavoritePlaces &&
                        loading.map((item, key) => (
                          <div className={styles.loading} key={key}>
                            <div className="sidebar-idea-container ember-view draggable-item zoom-place">
                              <div className="ember-view component-idea-tile idea-tile-horizontal _can-view-details">
                                <Skeleton
                                  variant="rect"
                                  width={80}
                                  height={85}
                                />
                                <ul className="_c-info">
                                  <React.Fragment>
                                    <Skeleton height={6} />
                                    <Skeleton height={6} width="80%" />
                                  </React.Fragment>
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))}
                      <Droppable droppableId="droppable2" isDropDisabled={true}>
                        {(provided, snapshot) => (
                          <div id="droppable2" ref={provided.innerRef}>
                            {filteredListMyPlaces &&
                              !!filteredListMyPlaces.length &&
                              filteredListMyPlaces.map((item, index) => (
                                <Draggable
                                  key={item._id}
                                  draggableId={item._id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <PlacesInMyPlaces
                                        isLoadingFavoritePlaces={
                                          this.state.isLoadingFavoritePlaces
                                        }
                                        updateListMyPlaces={
                                          this.updateListMyPlaces
                                        }
                                        removeMode={this.state.checkboxRemove}
                                        order={this.state.order}
                                        length={this.state.items.length}
                                        newList={this.updateListSelected}
                                        params={this.props.params}
                                        item={item}
                                        items={filteredListMyPlaces}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  ) : this.state.numTab === 1 ? (
                    <div id="select-explore">
                      <div
                        style={{
                          position: "sticky",
                          top: 0,
                          zIndex: 9,
                          background: "#f7f7f7",
                        }}
                      >
                        <div className="section-location-folder">
                          <ChangeLocation />
                          <div
                            className="folder-menu"
                            onClick={this.showLoad.bind(this)}
                          >
                            <i className="far fa-folder-open"></i>
                            <span>&nbsp;Load...</span>
                          </div>
                        </div>
                        <Form
                          id="form-search"
                          onSubmit={this.onSubmit.bind(this)}
                        >
                          <FormGroup>
                            <SearchPlacesNearBy
                              params={this.props.params}
                              itinerary={this.state.itinerary}
                            />
                          </FormGroup>
                        </Form>
                      </div>
                      {((!this.props.listLoad &&
                        !selected2.list &&
                        !this.props.isLoadingExplore) ||
                        (!this.props.listLoad &&
                          selected2.list &&
                          !selected2.list.length &&
                          !this.props.isLoadingExplore)) && (
                        <img
                          src={HelperDragDrop}
                          style={{
                            position: `absolute`,
                            right: `0`,
                            width: `100%`,
                            top: "20%",
                          }}
                        />
                      )}

                      {this.props.isLoadingExplore &&
                        loading.map((item, key) => (
                          <div className={styles.loading} key={key}>
                            <div className="sidebar-idea-container ember-view draggable-item zoom-place">
                              <div className="ember-view component-idea-tile idea-tile-horizontal _can-view-details">
                                <Skeleton
                                  variant="rect"
                                  width={80}
                                  height={85}
                                />
                                <ul className="_c-info">
                                  <React.Fragment>
                                    <Skeleton height={6} />
                                    <Skeleton height={6} width="80%" />
                                  </React.Fragment>
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))}
                      {this.props.listLoad && !!this.props.listLoad.length ? (
                        <Droppable
                          droppableId="droppable2"
                          isDropDisabled={true}
                        >
                          {(provided, snapshot) => (
                            <div id="droppable2" ref={provided.innerRef}>
                              {this.props.listLoad &&
                                !!this.props.listLoad.length &&
                                this.props.listLoad.map((item, index) => (
                                  <Draggable
                                    key={item._id}
                                    draggableId={item._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <PlacesInShared
                                          isLoadingFavoritePlaces={
                                            this.state.isLoadingFavoritePlaces
                                          }
                                          updateListMyPlaces={
                                            this.updateListMyPlaces
                                          }
                                          removeMode={this.state.checkboxRemove}
                                          order={this.state.order}
                                          length={this.state.items.length}
                                          newList={this.updateListSelected}
                                          params={this.props.params}
                                          item={item}
                                          items={filteredListMyPlaces}
                                          categories={this.state.categories}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      ) : (
                        <Droppable
                          droppableId="droppable3"
                          isDropDisabled={true}
                        >
                          {(provided, snapshot) => (
                            <div id="droppable3" ref={provided.innerRef}>
                              {selected2.list &&
                                !!selected2.list.length &&
                                selected2.list.map((item, index) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={`${item._id}-${index}`}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <PlacesInExplore
                                          order={this.state.order}
                                          status={selected2.status}
                                          length={this.state.items.length}
                                          newList={this.updateListSelected}
                                          params={this.props.params}
                                          item={item}
                                          categories={this.state.categories}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      )}
                    </div>
                  ) : null}
                </Row>
              </Col>
            </DragDropContext>
          </Row>
        </div>
      </div>
    );
  }
}

const ContentDetailWithSocket = (props) => {
  return (
    <SocketContext.Consumer>
      {(socket) => <ContentDetail {...props} socket={socket} />}
    </SocketContext.Consumer>
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(ContentDetailWithSocket))
);
