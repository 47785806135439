import axios from '../axios-config';

export default () => {
  let services = {
    save: (data) =>  {
        try {
          return axios.post ("company/save", {
            ...data,
          }).then((res) => {
            return res.data;
          });
        } catch (error) {
          console.log(error);
        }
    },


    uploadLogo: (formData) => {
      return axios.post ('company/uploadLogo', formData, true, {
        headers: {
          "content-type": "multipart/form-data"
        },
      }).then((res) => {
        return res.data
      })
    },

    checkUniqueUrl: (url) => {
      return axios.get('company/checkUniqueUrl?url=' + url).then((res) => {
        return res.data;
      });
    },

    checkDomain: url =>  {
      return axios.get('company/domain').then((res) => {
        return res.data;
      });
    },

    getTyneries:(pageNumber, limit)  =>  {
      return axios.get('company/getTyneries?pageNumber='+pageNumber+'&limit='+limit).then((res) => {
        return res.data
      })
    }
  };

  return services;
};


