import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Icon, Result, Skeleton, Button } from 'antd';

import * as QuotationApi from '../../../api/quotation';
import MoneyHelper from '../../../helper/moneyHelper';
import Image from '../../../functions/image';
import { config } from '../../../config';
import moment from 'moment';
import './index.scss';

const ResultSuccess = () => {
  return (
    <div className="confirm-token">
      <Result
        status="success"
        title="Successfully "
        subTitle="We will contact you soon."
      />
    </div>
  );
};

const ResultReject = () => {
  return (
    <div className="confirm-token">
      <Result
        status="success"
        title="Successfully "
        subTitle="Thanks for your feedback"
      />
    </div>
  );
};

const OtherInformation = ({ quotation }) => {
  return (
    <div className="other-information">
      <h3 className="text-information">Information</h3>
      <div className="summary">
        <h3 className="text-summary">Pricing Summary</h3>
        <Table>
          <Thead>
            <Tr>
              <Th>Title</Th>
              <Th>People</Th>
              <Th>Duration</Th>
              <Th>Budget</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr className="body-row">
              <Td>
                <strong>{quotation.title}</strong>
              </Td>
              <Td>{quotation.people}</Td>
              <Td>
                {quotation.days.length} day
                {quotation.days.length > 1 && 's'}
              </Td>
              <Td>
                {MoneyHelper(quotation.budget).format({
                  currency: quotation.currency,
                  format: '%s%v ' + quotation.currency,
                })}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
      <div className="bottom-content">
        <h3 className="title">Other information</h3>
        <p
          className="description"
          dangerouslySetInnerHTML={{
            __html: quotation.other_information,
          }}
        ></p>
      </div>
    </div>
  );
};

const DayItem = ({
  index,
  day,
  quotation,
  stops,
  onChangeRejectedStop,
  rejectedItem,
}) => {
  return (
    <div className="day-item" key={index}>
      <div className="day-header">
        <span className="day-index">
          Day {index + 1}: {day.name}
          <br />
          <span className="address">{day.address}</span>
        </span>
        <div className="day-info">
          <div className="advance">
            <strong>Lodging: </strong>
            <span>{day.lodging ? day.lodging.title : ' not included'}</span>
          </div>
          <div className="advance">
            <strong>Meals: </strong>
            <span>
              {day.meal.length > 0
                ? day.meal.map((meal) => meal.name + ' ')
                : ' not included'}
            </span>
          </div>
          {day.guide ? (
            <div className="advance">
              <strong>Guide: </strong>
              <span>
                {MoneyHelper(day.guide.budget).format({
                  currency: quotation.currency,
                  format: '%s%v ' + quotation.currency,
                })}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <StopInDay
        day={day}
        stops={stops}
        quotation={quotation}
        onChangeRejectedStop={onChangeRejectedStop}
        rejectedItem={rejectedItem}
      />
    </div>
  );
};

const StopInDay = ({
  day,
  stops,
  quotation,
  onChangeRejectedStop,
  rejectedItem,
}) => {
  const stopInDay = stops.filter(
    (stop) => stop.belong_to_quotation_day === day._id
  );
  const stopsHtml = [];
  stops.forEach((stop, index) => {
    if (stop.belong_to_quotation_day === day._id)
      stopsHtml.push(
        <StopItem
          key={index}
          stop={stop}
          quotation={quotation}
          onChangeRejectedStop={onChangeRejectedStop}
          rejectedItem={rejectedItem}
          index={index}
        />
      );
  });

  if (stopInDay.length > 0)
    return (
      <div className="stops">
        <Table>
          <Thead>
            <Tr>
              <Th className="time" width="10%">
                Time
              </Th>
              <Th width="70%">Activity</Th>
              <Th width="20%" className="cost">
                Cost
              </Th>
            </Tr>
          </Thead>
          <Tbody>{stopsHtml}</Tbody>
        </Table>
      </div>
    );

  return null;
};

const StopItem = ({
  stop,
  quotation,
  onChangeRejectedStop,
  rejectedItem,
  index,
}) => {
  return (
    <Tr className="body-row stop-item">
      <Td className="time">
        <strong>{stop.end_time}</strong>
      </Td>
      <Td className="activity">
        <div className="content">
          <strong className="name">{stop.title}</strong>
          <div className="body">
            {stop.description ? (
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: stop.description,
                }}
              ></p>
            ) : null}
          </div>
        </div>
      </Td>
      <Td className="budget">
        {stop.budget &&
          MoneyHelper(stop.budget).format({
            currency: quotation.currency,
            format: '%s%v ' + quotation.currency,
          })}
        <Button
          onClick={() =>
            onChangeRejectedStop(!rejectedItem[index].rejected, index)
          }
          className="skip-btn"
        >
          {!rejectedItem[index].rejected
            ? 'Skip this stop'
            : 'Unskip this stop'}
        </Button>
      </Td>
    </Tr>
  );
};

function FormConfirm(props) {
  const [loading, setLoading] = useState(true);
  const [quotation, setQuotation] = useState({});
  const [days, setQuotationDay] = useState([]);
  const [stops, setStop] = useState([]);
  const [company, setCompany] = useState([]);
  const [rejectedItem, setRejected] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitReject, setSubmitReject] = useState(false);
  const [quotationExpired, setQuotationExpired] = useState(false);

  useEffect(() => {
    const quotationId = props.quotationId;
    QuotationApi.getConfirmQuotation(quotationId).then((res) => {
      if (!res.error && res.exist) {
        setQuotation(res);
        setQuotationDay(res.days);
        setCompany(res.company);
        setRejectedStopState(res);
        setStop(res.stops);
      } else {
        setQuotationExpired(true);
      }
      setLoading(false);
    });
  }, []);

  function setRejectedStopState(res) {
    let rejectedTemp = [];
    res.stops.forEach((stop, index) => {
      let valuePost = {};
      valuePost.id = stop._id;
      valuePost.belong_to_quotation_day = stop.belong_to_quotation_day;
      valuePost.rejected = stop.rejected ? stop.rejected : false;
      valuePost.name = stop.title;
      rejectedTemp.push(valuePost);
    });
    setRejected(rejectedTemp);
  }

  function onChangeRejectedStop(rejected, index) {
    let rejectedTemp = [...rejectedItem];
    rejectedTemp[index].rejected = rejected;
    setRejected(rejectedTemp);
  }

  function submitRejected() {
    QuotationApi.rejected(props.quotationId).then((res) => {
      if (!res.error) {
        return setSubmitReject(true);
      }
    });
  }

  function submitConfirm() {
    const data = {
      token: props.token,
      stops: rejectedItem,
    };
    QuotationApi.submitConfirm(data).then((res) => {
      if (!res.error) setSubmitSuccess(true);
    });
  }

  if (quotationExpired)
    return (
      <div>
        <h1>This quotation is expired</h1>
      </div>
    );

  if (loading)
    return (
      <div className="view " id="pdf-quotation">
        <div id="body-content">
          <Skeleton title={false} paragraph={{ rows: 1 }} active />
        </div>
      </div>
    );

  if (submitSuccess) return <ResultSuccess />;

  if (submitReject) return <ResultReject />;

  return (
    <div className="form-confirm-container">
      <form
        className="quotation-confirm-form"
        name="confirm-form"
        method="post"
      >
        <div className="view " id="pdf-quotation">
          <div id="body-content">
            <div className="header">
              <div className="user-quotation">
                <div className="user-content">
                  <Icon type="user" />
                  <br />
                  <span> {quotation.name}</span>
                </div>
                <div className="user-content">
                  <Icon type="phone" />
                  <br />
                  <span> {quotation.phone}</span>
                </div>
                <div className="user-content">
                  <Icon type="mail" />
                  <br />
                  <span> {quotation.email}</span>
                </div>
              </div>
              <div className="company-info-container">
                <div className="companyInfo">
                  <p className="headerText">Main office: {company.address}</p>
                  <p className="headerText">{company.phone}</p>
                  <p className="headerText">{company.email}</p>
                </div>
                <Image
                  src={config.baseURL + company.logo}
                  className="companyLogo"
                  alt="company-logo"
                />
              </div>
            </div>
            {quotation.itinerary && (
              <>
                <div className="image-container">
                  <Image
                    src={config.baseURL + quotation.itinerary.cover}
                    className="companyLogo"
                    alt="company-logo"
                  />
                </div>
                <div className="itinerary-description">
                  <h3>{quotation.itinerary.name}</h3>
                  <p>{quotation.itinerary.description}</p>
                </div>
              </>
            )}

            <div className="itinerary-over-view">
              <h3>Overview</h3>
              <div className="days">
                {quotation.days.map((day, index) => (
                  <div className="day-item" key={index}>
                    <div className="day-header">
                      <span className="day-index">Day {index + 1}</span>
                      <span className="start_date">
                        {moment(day.date).format('ll')}
                      </span>
                    </div>
                    <div className="day-description">{day.name}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="pricing-container">
              <h3>Pricing</h3>
              <div className="info-pricing">
                <div className="item">
                  <span className="text"> People </span>
                  <span className="value">{quotation.people} </span>
                </div>
                <div className="item">
                  <span className="text"> Duration </span>
                  <span className="value">
                    {quotation.days.length} day
                    {quotation.days.length > 1 ? 's' : ''}
                  </span>
                </div>
                {quotation.budget !== null && (
                  <div className="item">
                    <span className="text">Budget</span>
                    <span className="value">
                      {MoneyHelper(quotation.budget).format({
                        currency: quotation.currency,
                        format: '%s%v ' + quotation.currency,
                      })}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="detail-container">
              <h3>itinerary</h3>
              <div className="day-item-container">
                {days.map((day, index) => (
                  <DayItem
                    key={index}
                    index={index}
                    day={day}
                    quotation={quotation}
                    stops={stops}
                    onChangeRejectedStop={onChangeRejectedStop}
                    rejectedItem={rejectedItem}
                  />
                ))}
              </div>
            </div>
            <OtherInformation quotation={quotation} />
            <div className="submit-container">
              {quotation.is_new === 1 ? (
                <>
                  <Button type="danger" onClick={() => submitRejected()}>
                    Rejected
                  </Button>
                  <Button type="primary" onClick={() => submitConfirm()}>
                    Accepted
                  </Button>
                </>
              ) : (
                <Button type="primary" onClick={() => submitConfirm()}>
                  Update
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default FormConfirm;
