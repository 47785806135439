import React, { useEffect } from "react";
import { Menu } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  MoneyCollectOutlined,
  FolderOpenOutlined,
  ShoppingFilled,
  ContactsOutlined,
  CalendarOutlined,
  GroupOutlined,
  DashboardFilled
} from "@ant-design/icons";
import {AccountType} from '../../../params/AccountType';
import MenuMain from "../../../components/menu-main";

const MenuModel = (props = {}) => {
  class MenuClass {
    constructor({name, icon, link = "#", proAccount = false}) {
      this.name = name;
      this.icon = icon;
      this.link = link;
      this.proAccount = proAccount;
    }
  }
  return new MenuClass(props);
};
const menuData = [
  MenuModel({
    name: "Dashboard",
    icon: <DashboardFilled/>,
    link: '/dashboard'
  }),
  // MenuModel({
  //   name: "My Tyneri",
  //   icon: <EnvironmentOutlined />,
  //   link: "/my-tyneri"
  // }),
  MenuModel({
    name: "My Library",
    icon: <GroupOutlined />,
    link: "/my-library"
  }),
  MenuModel({
    name: "Calendar",
    icon: <CalendarOutlined />,
    link: "/calendar",
    proAccount: true
  }),
  MenuModel({
    name: "Inquiry",
    icon: <FolderOpenOutlined />,
    link: "/inquiries",
    proAccount: true
  }),
  MenuModel({
    name: "Contacts",
    icon: <ContactsOutlined />,
    link: "/contacts",
    proAccount: true
  }),
  MenuModel({
    name: "Quotations",
    icon: <SettingOutlined />,
    link: "/quotations",
    proAccount: true
  }),
  MenuModel({
    name: "Billing",
    icon: <MoneyCollectOutlined />,
    link: "/billing"
  }),
  MenuModel({
    name: "Profile",
    icon: <UserOutlined />,
    link: "/my-profile"
  }),
];

const MenuAdmin = (props) => {
  const pathName = () => {
    const pathNameSplit = props.location.pathname.split('/');
    if(pathNameSplit.length > 2) {
      return "/"+ pathNameSplit[1]
    } else {
      return props.location.pathname;
    }
  }

  const menuSelected = menuData.find(
    (menu) =>  menu.link === pathName()
  );
  const menuSelectedId = menuData.indexOf(menuSelected).toString();
  const user = props.profile !== null ? props.profile : {};
  const checkShowMenuProAccount =  (menu) => { return menu.proAccount && user.accountType !== AccountType.Pro };

  useEffect(() => {
    if(menuData[menuSelectedId])
      props.setTitle(menuData[menuSelectedId].name)
  }, []);


  return (
    <Menu
      mode="inline"
      theme="dark"
      className="menu-custom"
      defaultSelectedKeys={[menuSelectedId]}
      onClick={({ item, key, keyPath, selectedKeys, domEvent }) => {
        props.history.push({
          pathname: menuData[key].link,
          state: {
            title: menuData[key].name
          }
        });
      }}>
      <MenuMain mode="horizontal" isAdmin={true} />
      {menuData.map((menu, index) => {
        return (
          <Menu.Item key={index}
                     disabled={ checkShowMenuProAccount(menu) }>
            {menu.icon}
            <span>{menu.name}</span>
            {
              checkShowMenuProAccount(menu)  ?
              <span className="block" ><ShoppingFilled/></span>:
              null
            }
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default MenuAdmin;
